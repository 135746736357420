import { PaginationProps } from 'antd';
import {
  DEFAULT_OFFSET,
  DEFAULT_PAGE,
  DEFAULT_PAGINATION_OFFSET,
  PAGING_OFFSET_ALL,
} from 'constants/pagination';
import { useFlagsupContext, useProjectContext } from 'contexts';
import trackSubmissionList from 'helpers/tracker/trackSubmissionList';
import {
  IGetTreesParams,
  ISnapshotTree,
  ISubmission,
  ISubmissionAfterCAR2355,
  ISubmissionFilters,
} from 'interfaces';
import queryString from 'query-string';
import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { submissionServices } from 'services';
import { pick, isNil } from 'lodash';
import { ID_DEFAULT } from 'constants/common';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import {
  setGettingTrees,
  setPagination,
  setTrees,
} from 'redux/features/submission';
import { TREE_LABEL_DEFAULT } from 'constants/submission';

const { trackPageView } = trackSubmissionList;

const DEFAULT_PAGE_SIZE = 50;

const useSubmissionData = () => {
  const { projectId } = useProjectContext();
  const { featureFlagsData } = useFlagsupContext();
  const location = useLocation();
  const trackOffset = useRef<{ [key in number]: number }>({
    1: DEFAULT_OFFSET,
  });
  const [submissions, setSubmissions] = useState<ISubmission[]>([]);
  const [submissionsAfterCAR2355, setSubmissionsAfterCAR2355] = useState<
    ISubmissionAfterCAR2355[]
  >([]);
  const [filters, setFilters] = useState<ISubmissionFilters>({
    ...pick(queryString.parse(location.search) as Partial<ISubmissionFilters>, [
      'plotId',
      'polygonId',
      'submissionStatus',
    ]),
    offset: DEFAULT_OFFSET,
    limit: DEFAULT_PAGE_SIZE,
    projectId,
  });
  const [fetching, setFetching] = useState(false);
  const [offSet, setOffset] = useState(DEFAULT_OFFSET);
  const [_pagination, setPagination] = useState<PaginationProps>({
    showSizeChanger: true,
    pageSize: DEFAULT_PAGE_SIZE,
    current: 1,
  });

  const isCAR2355Enabled = featureFlagsData.CAR_2355?.enabled;

  const getSubmissions = async (params: ISubmissionFilters) => {
    if (params.submissionStatus === null) params.submissionStatus = undefined;
    try {
      setFetching(true);
      let res;
      if (isCAR2355Enabled) {
        res = await submissionServices.getSubmissionsTekbone(params);
        setSubmissionsAfterCAR2355(res.submissions);
      } else {
        res = await submissionServices.getSubmissions(params);
        setSubmissions(res.submissions);
      }
      const { offset: offsetRes, total } = res.pagination;
      setOffset(offsetRes);
      trackOffset.current[(_pagination.current || 0) + 1] = offsetRes;
      setPagination({
        ..._pagination,
        total: total,
      });
    } finally {
      setFetching(false);
    }
  };

  const handleTableChange = (pager: PaginationProps) => {
    const { current, pageSize } = pager;
    let newOffset = DEFAULT_OFFSET;
    if (pageSize === _pagination.pageSize!) {
      setPagination({
        ..._pagination,
        current: current,
        pageSize: pageSize,
      });
      if (current! > _pagination.current!) {
        newOffset = offSet;
      } else {
        newOffset = trackOffset.current[current!] || 0;
      }
    } else {
      setPagination({
        ..._pagination,
        current: DEFAULT_PAGE,
        pageSize: pageSize,
      });
    }
    const newQueryParams = {
      ...filters,
      offset: newOffset,
      limit: pageSize || DEFAULT_PAGE_SIZE,
    };
    setFilters(newQueryParams);
    trackPageView(current);
  };

  useEffect(() => {
    getSubmissions(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return {
    submissionsAfterCAR2355,
    submissions,
    filters,
    setFilters,
    fetching,
    _pagination,
    handleTableChange,
    setSubmissions,
    setSubmissionsAfterCAR2355,
  };
};

const useSnapshotTree = () => {
  const [snapshotTree, setSnapshotTree] = useState<ISnapshotTree>();
  const [loading, setLoading] = useState(false);

  const { projectId } = useProjectContext();

  const getSnapshotTree = async (treeId: number, snapshotTreeId: number) => {
    setLoading(true);
    try {
      const snapshotTree = await submissionServices.getSnapshotTree(
        treeId,
        snapshotTreeId,
        projectId
      );
      setSnapshotTree(snapshotTree);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return { getSnapshotTree, snapshotTree, loading };
};

const useSnapshotTrees = () => {
  const [loading, setLoading] = useState(false);

  const { projectId } = useProjectContext();

  const getSnapshotTrees = async (treeId: number) => {
    try {
      setLoading(true);
      const { snapshotTrees } = await submissionServices.getSnapshotTrees(
        treeId,
        {
          projectId,
          ...PAGING_OFFSET_ALL,
        }
      );
      return snapshotTrees;
    } catch (e) {
      return [];
    } finally {
      setLoading(false);
    }
  };

  return { getSnapshotTrees, loading };
};

const useTrees = () => {
  const { projectId } = useProjectContext();
  const dispatch: AppDispatch = useDispatch();

  const location = useLocation();
  const queryParams = pick(
    queryString.parse(location.search) as Partial<IGetTreesParams>,
    ['plotId', 'approvedStatus']
  );

  const [params, setParams] = useState<IGetTreesParams>({
    plotId: queryParams.plotId ? +queryParams.plotId : ID_DEFAULT,
    approvedStatus: queryParams.approvedStatus,
    ...DEFAULT_PAGINATION_OFFSET,
    projectId,
  });

  const getTrees = async (params: IGetTreesParams) => {
    try {
      dispatch(setGettingTrees(true));
      const { trees, pagination } = await submissionServices.getTrees(params);
      // show expand icon
      dispatch(
        setTrees(
          (trees || []).map(tree =>
            !isNil(tree?.totalSnapshotOfTree) && tree.totalSnapshotOfTree < 2
              ? { ...tree, treeLabel: tree.treeLabel || TREE_LABEL_DEFAULT }
              : {
                  ...tree,
                  treeLabel: tree.treeLabel || TREE_LABEL_DEFAULT,
                  children: [],
                }
          )
        )
      );

      dispatch(setPagination(pagination));
    } finally {
      dispatch(setGettingTrees(false));
    }
  };

  useEffect(() => {
    if (params.plotId !== ID_DEFAULT) getTrees(params);
  }, [params]);

  return {
    params,
    setParams,
  };
};

export default {
  useSubmissionData,
  useSnapshotTree,
  useSnapshotTrees,
  useTrees,
};
