import React from 'react';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { MarkerCluster, divIcon, point } from 'leaflet';
import './style/cluster.css';

interface IProjectClusterLeaflet {
  children: React.ReactNode;
}

const MAX_CLUSTER_RADIUS = 40;
const DISABLE_CLUSTER_ZOOM = 15;

const createClusterCustomIcon = function(cluster: MarkerCluster) {
  return divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: 'custom-marker-cluster',
    iconSize: point(28, 28, true),
  });
};

const ProjectClusterLeaflet = (props: IProjectClusterLeaflet) => {
  return (
    <MarkerClusterGroup
      chunkedLoading
      disableClusteringAtZoom={DISABLE_CLUSTER_ZOOM}
      polygonOptions={{ stroke: false, color: 'transparent', opacity: 0 }}
      maxClusterRadius={MAX_CLUSTER_RADIUS}
      zoomToBoundsOnClick
      spiderfyOnMaxZoom={false}
      iconCreateFunction={createClusterCustomIcon}
    >
      {props.children}
    </MarkerClusterGroup>
  );
};

export default ProjectClusterLeaflet;
