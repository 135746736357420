import localStorageConstants from 'constants/localStorage';
import localizationConstants from 'constants/localization';
const { LOCALIZATION } = localStorageConstants;
const {
  TREE_SPECIES_RESOURCES,
  TREE_SPECIES_SCIENTIFIC_RESOURCES,
  REGIONS,
} = localizationConstants;

const getCurrentLanguage = () => {
  const language = localStorage.getItem(LOCALIZATION) || REGIONS.en.key;
  return language;
};

const changeLanguage = (language: string) => {
  if (language === getCurrentLanguage()) return;
  localStorage.setItem(LOCALIZATION, language);
  window.location.reload();
};

const prettifyCodeName = (codeName: string): string => {
  const words = codeName.split('_');
  const capitalizedWords = words.map(
    word => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return capitalizedWords.join(' ');
};

export const translateTreeSpecies = (codeName: string) => {
  const currentLanguage = getCurrentLanguage();
  const scientificName =
    TREE_SPECIES_SCIENTIFIC_RESOURCES[codeName as keyof Object];
  const commonName =
    TREE_SPECIES_RESOURCES[currentLanguage as keyof Object][
      codeName as keyof Object
    ] || prettifyCodeName(codeName);

  // If commonName is not defined, or commonName matches scientific name, return only scientific name
  return !commonName || scientificName === commonName
    ? scientificName
    : `${commonName} (${scientificName})`;
};

export default {
  getCurrentLanguage,
  changeLanguage,
};
