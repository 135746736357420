import { MutableRefObject, useEffect, useRef } from 'react';
import axios, { Canceler, CancelToken, CancelTokenSource } from 'axios';

type ControllerRef = MutableRefObject<AbortController | null>;

type UseAbortController = {
  controller: ControllerRef;
  newAbortSignal: () => AbortSignal;
  cancelPreviousRequest: () => void;
  isCancel: (error: any) => boolean;
  newCancelToken: () => CancelToken;
};

export const useAbortController = (): UseAbortController => {
  const controller: ControllerRef = useRef(null);
  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);
  const cancelTokenRef = useRef<Canceler | null>(null);

  const { isCancel } = axios;

  const newAbortSignal = (): AbortSignal => {
    controller.current = new AbortController();
    return controller.current.signal;
  };

  const cancelPreviousRequest = (): void => {
    if (controller.current) controller.current.abort();
  };

  const newCancelToken = () => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current('Creating new cancel token');
    }
    const newCancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = newCancelTokenSource;
    cancelTokenRef.current = newCancelTokenSource.cancel;
    return newCancelTokenSource.token;
  };

  useEffect(() => cancelPreviousRequest, []);

  useEffect(() => {
    return () => {
      if (cancelTokenRef.current) {
        cancelTokenRef.current('Operation canceled by the user.');
      }
    };
  }, []);

  return {
    controller,
    newAbortSignal,
    cancelPreviousRequest,
    isCancel,
    newCancelToken,
  };
};
