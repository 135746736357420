import React, { useEffect, useState } from 'react';
import { Descriptions, Divider, Empty, Skeleton } from 'antd';
import { t } from 'helpers/i18n';
import { convertNumberFormat } from 'helpers/common';
import { dateTimeHelpers } from 'helpers';
import { ActivityDetail, ActivityFund } from 'interfaces';
import { activityServices } from 'services';
import { useProjectContext } from 'contexts';
import FinancialPlanComment from './FinancialPlanComment';
import classNames from 'classnames';

interface FinancialPlanOverviewProps {
  activityId: number;
}

const FinancialPlanOverview: React.FC<FinancialPlanOverviewProps> = ({
  activityId,
}) => {
  const [activity, setActivity] = useState<ActivityDetail>();
  const [loading, setLoading] = useState<boolean>(true);
  const { projectId } = useProjectContext();

  const getActivityDetail = async () => {
    try {
      setLoading(true);
      let data = await activityServices.getActivityDetail({
        projectId,
        activityId,
      });
      // Only shows funds that are not deleted
      data.activityFunds = data.activityFunds.filter(
        fund => fund.status !== 'DELETED'
      );
      setActivity(data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getActivityDetail();
  }, [activityId]);

  const descriptionCommonProps = {
    column: 1,
    contentStyle: { justifyContent: 'end' },
  };

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : !activity ? (
        <Empty />
      ) : (
        <>
          <Descriptions
            {...descriptionCommonProps}
            title={activity.activityName}
          >
            {!!activity.activityDescription && (
              <Descriptions.Item contentStyle={{ justifyContent: 'start' }}>
                {activity.activityDescription}
              </Descriptions.Item>
            )}
            <Descriptions.Item label={t('PeopleProposedAmount')}>
              {convertNumberFormat(activity.proposedAmount)} VND
            </Descriptions.Item>
          </Descriptions>
          <Divider className="mt-half" />
          <Descriptions {...descriptionCommonProps}>
            <Descriptions.Item label={t('VoteMeetingDate')}>
              {dateTimeHelpers.formatUnixDateWithTimeRange(
                activity.meetingDate,
                activity.meetingStartTime,
                activity.meetingEndTime
              )}
            </Descriptions.Item>
            <Descriptions.Item label={t('VoteResult')}>
              {parseFloat(Number(activity.percentPeopleAgree).toFixed(1))}%
            </Descriptions.Item>
            <Descriptions.Item label={t('ProceedDate')}>
              {dateTimeHelpers.formatUnixDateRange(
                activity.fromTime,
                activity.toTime
              )}
            </Descriptions.Item>
          </Descriptions>
          {!!activity.activityFunds.length && (
            <>
              <Divider className="mt-half" />
              <Descriptions {...descriptionCommonProps}>
                {activity.activityFunds.map((fund: ActivityFund) => (
                  <Descriptions.Item
                    label={fund.creatorOrganizationName}
                    key={fund.activityFundId}
                  >
                    {convertNumberFormat(fund.planAmount)} VND
                  </Descriptions.Item>
                ))}
                <Descriptions.Item className="text-bold">
                  {t('TotalAmount')}:{' '}
                  {convertNumberFormat(activity.totalFundAmount)} VND
                </Descriptions.Item>
              </Descriptions>
            </>
          )}
        </>
      )}
      <div className={classNames({ 'd-none': loading })}>
        <Divider className="mt-half mb-base" />
        <FinancialPlanComment activityId={activityId} />
      </div>
    </>
  );
};

export default FinancialPlanOverview;
