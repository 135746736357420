/** Third party libs * */
import React from 'react';
import { Form, FormInstance, FormItemProps } from 'antd';
import { RuleObject } from 'antd/lib/form';

/** Local libs * */
import { t } from 'helpers/i18n';
import { IFormAnswer, IFormSubmission } from 'interfaces';
import { isEmpty } from 'lodash';

/** Components * */

/** Styles * */

/** Interfaces, enum... * */
export type Mode = 'CREATE' | 'EDIT';
export interface BaseItemProps extends FormItemProps {
  item: IFormAnswer;
  form: FormInstance<any>;
  disabled?: boolean;
  mode: Mode;
  formSubmission?: IFormSubmission;
  validator?: (rule: RuleObject, value: any) => Promise<any>;
}

/** Variables * */

/** ------------------------- * */
const BaseItem: React.FC<BaseItemProps> = ({
  item,
  validator,
  children,
  ...formItemProps
}) => {
  /** States * */

  /** Hooks * */

  /** Variables * */

  /** Effects * */

  /** Functions, Events, Actions... * */

  /** Elements * */
  return (
    <Form.Item
      label={
        item.fieldLabel ? (
          <b dangerouslySetInnerHTML={{ __html: item.fieldLabel }} />
        ) : (
          undefined
        )
      }
      required={item.isRequired}
      colon={false}
      style={{
        marginBottom: 12,
      }}
      name={item.fieldName}
      rules={[
        {
          validator: (rule, value) => {
            if ((!value || isEmpty(value)) && item.isRequired)
              return Promise.reject(t('NotBeEmpty'));

            if (value && item.regex) {
              const regex = new RegExp(item.regex);
              if (!regex.test(value)) return Promise.reject(t('InvalidData'));
            }

            if (validator) return validator(rule, value);

            return Promise.resolve();
          },
        },
      ]}
      {...formItemProps}
    >
      {children}
    </Form.Item>
  );
};

export { BaseItem };
export default BaseItem;
