import { Steps } from 'antd';
import AppContainer from 'containers/AppLayout/AppContainer';
import { t } from 'helpers/i18n';
import React, { useState } from 'react';

const DEFAULT_STEP = 1;
const forecastingMilestones = [
  {
    title: '0 year',
    disabled: true,
  },
  {
    title: '4 years',
    videoSrc:
      'https://files.tekoapis.com/files/6ff369f7-a43f-4473-bd2c-922c3ab6a8b9',
  },
  {
    title: '6 years',
    videoSrc:
      'https://files.tekoapis.com/files/e810fa6f-1efa-446e-ab80-6fdab589e36a',
  },
  {
    title: '9 years',
    videoSrc:
      'https://files.tekoapis.com/files/42966890-af93-43a9-b61f-7278096606a6',
  },
];

const ForecastingVideo: React.FC = () => {
  const [step, setStep] = useState<number>(DEFAULT_STEP);
  const onStepChanges = (value: number) => {
    setStep(value);
  };

  return (
    <AppContainer title={t('ForecastingVideo')}>
      <div className="h-100 d-flex align-items-center flex-column">
        {forecastingMilestones[step].videoSrc && (
          <video
            key={step}
            style={{ objectFit: 'cover', maxWidth: 1200, width: '100%' }}
            preload="auto"
            height="85%"
            width="auto"
            controls
            autoPlay
          >
            <source
              src={forecastingMilestones[step].videoSrc}
              type="video/mp4"
            />
          </video>
        )}
        <Steps
          style={{ marginTop: 48, maxWidth: 1200 }}
          current={step}
          progressDot
          size="default"
          onChange={onStepChanges}
          items={forecastingMilestones}
        />
      </div>
    </AppContainer>
  );
};

export default ForecastingVideo;
