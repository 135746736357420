import { requestServices } from 'services';
import {
  ICreateCommentBody,
  ICreateCommentResponse,
  IGetCommentsParams,
  IGetCommentsResponse,
} from 'interfaces';

const { projectManagerClient } = requestServices;

const getComments = (
  params: IGetCommentsParams
): Promise<IGetCommentsResponse> => {
  return projectManagerClient.get('/api/v1/fpic-form-submissions/comments', {
    params,
  });
};

const createComment = (
  body: ICreateCommentBody
): Promise<ICreateCommentResponse> => {
  return projectManagerClient.post(
    '/api/v1/fpic-form-submissions/comments',
    body
  );
};

export default { getComments, createComment };
