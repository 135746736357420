import { Result, Spin } from 'antd';
import ProjectDetailTable from 'components/ProjectList/ProjectDetail/ProjectDetailTable';
import AppContainer from 'containers/AppLayout/AppContainer';
import { useQuery } from '@tanstack/react-query';
import { commonConstants } from 'constants/index';
import { projectServices } from 'services';
import { getData } from 'helpers/request';
import { useProjectContext } from 'contexts';

const { QUERY_KEYS } = commonConstants;

/**
 * @deprecated
 */
const ProjectDetail = () => {
  const { projectId } = useProjectContext();
  const { data: project, error, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.PROJECT_DETAIL, projectId],
    queryFn: async () =>
      projectServices.getProjectById(Number(projectId)).then(getData),
    retry: false,
  });

  return (
    <AppContainer>
      {!error ?? (
        <Result status="500" title="500" subTitle="Something went wrong" />
      )}
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spin />
        </div>
      ) : null}
      {!error && project ? <ProjectDetailTable project={project} /> : null}
    </AppContainer>
  );
};

export default ProjectDetail;
