import { Col, Row } from 'antd';
import FileProcessHistory, {
  FileProcessingHistoryRef,
} from 'components/FileUploadAndHistory/FileProcessHistory';
import FileUpload from 'components/FileUploadAndHistory/FileUpload';
import { ProcessingFileType } from 'interfaces';
import { useRef } from 'react';

interface FileUploadAndHistoryProps {
  processingFileType: ProcessingFileType;
  customUploadRequest: (formData: FormData) => Promise<void>;
}

const FileUploadAndHistory: React.FC<FileUploadAndHistoryProps> = ({
  processingFileType,
  customUploadRequest,
}) => {
  const fileProcessHistoryRef = useRef<FileProcessingHistoryRef>(null);

  const refreshHistory = () => {
    fileProcessHistoryRef?.current?.refresh();
  };

  return (
    <Row>
      <Col xs={{ span: 24, order: 2 }} lg={{ span: 16, order: 1 }}>
        <FileProcessHistory
          processingFileType={processingFileType}
          ref={fileProcessHistoryRef}
        />
      </Col>
      <Col xs={{ span: 24, order: 1 }} lg={{ span: 8, order: 2 }}>
        <FileUpload
          fileType={processingFileType}
          customUploadRequest={customUploadRequest}
          onFinishUpload={refreshHistory}
        />
      </Col>
    </Row>
  );
};

export default FileUploadAndHistory;
