import React from 'react';
import FinancialPlanOverview from './FinancialPlanOverview';
import { Tabs, Typography } from 'antd';
import { t } from 'helpers/i18n';
import FinancialPlanActivityLog from './FinancialPlanActivityLog';
import FinancialPlanExpenseReceipts from './FinancialPlanExpenseReceipts';

interface FinancialPlanDetailProps {
  activityId: number;
}

const FinancialPlanDetail: React.FC<FinancialPlanDetailProps> = ({
  activityId,
}) => {
  const tabItems = [
    {
      label: t('Overview'),
      key: 'overview',
      children: <FinancialPlanOverview activityId={activityId} />,
    },
    {
      label: t('ExpenseReceipts'),
      key: 'expenseReceipts',
      children: <FinancialPlanExpenseReceipts activityId={activityId} />,
    },
    {
      label: t('ActivityLogs'),
      key: 'activityLog',
      children: <FinancialPlanActivityLog activityId={activityId} />,
    },
  ];

  return (
    <>
      <Typography.Title level={4}>{t('FinancialPlanDetail')}</Typography.Title>
      <Tabs items={tabItems} className="content-scrollable" />
    </>
  );
};

export default FinancialPlanDetail;
