import React, { useState } from 'react';
import { Button, Result } from 'antd';
import { t } from 'helpers/i18n';
import { LogoutButton } from 'components/shared/Button';
import { AppstoreAddOutlined } from '@ant-design/icons';
import { ProjectUpsertionDrawer } from 'components/ProjectList/ProjectModal/ProjectUpsertionDrawer';
import { userHooks } from 'hooks';
import { resourceConstants } from 'constants/index';

const { APPS, RESOURCES, ACTIONS } = resourceConstants;

const UnassignedProjectPage: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { hasPermission } = userHooks.usePermission();
  const canCreateProject = hasPermission(
    APPS.PROJECT_MANAGER,
    RESOURCES.PROJECTS,
    ACTIONS.CREATE
  );

  return (
    <>
      <Result
        className="app-result-page"
        status="403"
        title={t('UnassignedProject')}
        extra={
          <>
            {canCreateProject && (
              <Button
                className="mr-half"
                type="primary"
                icon={<AppstoreAddOutlined />}
                onClick={() => setOpen(true)}
              >
                {t('AddNewProject')}
              </Button>
            )}
            <LogoutButton />
          </>
        }
      />
      <ProjectUpsertionDrawer isEdit={false} open={open} setOpen={setOpen} />
    </>
  );
};

export default UnassignedProjectPage;
