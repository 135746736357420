import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Table, Typography } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import ContentBlock from 'components/shared/ContentBlock';
import { DEFAULT_OPTION, PAGINATION_TOTAL_DEFAULT } from 'constants/pagination';
import AppContainer from 'containers/AppLayout/AppContainer';
import { dateTimeHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { useEffect, useState } from 'react';
import { userManagementServices } from 'services';
import {
  IGetListOperationRolesParams,
  IPaginationResponse,
  OperationRole,
} from 'interfaces';
import { Link } from 'react-router-dom';
import { Store } from 'antd/es/form/interface';
import { MyProjectSelect } from 'components/shared/Select';

const columns: ColumnsType<OperationRole> = [
  {
    title: t('RoleGroupName'),
    dataIndex: 'name',
  },
  {
    title: t('Project'),
    dataIndex: 'projectNames',
    render: (projectNames: string[]) => projectNames.join(', '),
  },
  {
    title: t('Description'),
    dataIndex: 'description',
  },
  {
    title: t('Updated'),
    render: (_: undefined, rowData) => (
      <div>
        <div>{dateTimeHelpers.formatUnixTimestamp(rowData.updatedAt)}</div>
        <div>
          <Typography.Text type="secondary">
            {rowData.updatedBy}
          </Typography.Text>
        </div>
      </div>
    ),
  },
  {
    title: t('Action'),
    render: (_, rowData) => (
      <Link to={`/accounts/role-groups/${rowData.id}`}>
        <Button icon={<EditOutlined />} type="text" />
      </Link>
    ),
  },
];

const RoleGroupManagement = () => {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<
    Pick<IGetListOperationRolesParams, 'keyword' | 'projectIds'>
  >();
  const [roleGroups, setRoleGroups] = useState<OperationRole[]>([]);
  const [pagination, setPagination] = useState<IPaginationResponse>(
    PAGINATION_TOTAL_DEFAULT
  );

  useEffect(() => {
    onSearch();
  }, []);

  const getListOperationRoles = async (
    params: IGetListOperationRolesParams
  ) => {
    setLoading(true);
    try {
      const {
        operationRoles,
        pagination: responsePagination,
      } = await userManagementServices.getListOperationRoles(params);
      setRoleGroups(operationRoles);
      setPagination(responsePagination);
    } finally {
      setLoading(false);
    }
  };

  const onSearch = async (values?: Store) => {
    const changedFilters = { ...filters, ...values };
    setFilters(changedFilters);
    getListOperationRoles({ ...changedFilters, ...DEFAULT_OPTION });
  };

  const onChangePagination = (changedPagination: TablePaginationConfig) => {
    getListOperationRoles({
      ...filters,
      page: changedPagination.current || pagination.page,
      pageSize: changedPagination.pageSize || pagination.pageSize,
    });
  };

  return (
    <AppContainer
      title={t('RoleGroupManagement')}
      classNames={{ head: 'd-flex align-items-baseline' }}
      head={
        <Link to="/accounts/role-groups/create">
          <Button icon={<PlusOutlined />}>{t('Create')}</Button>
        </Link>
      }
    >
      <ContentBlock>
        <Form onFinish={onSearch}>
          <Row gutter={16} className="mb-base">
            <Col flex="auto">
              <Form.Item
                name="keyword"
                className="mb-0"
                rules={[
                  {
                    type: 'string',
                    min: 3,
                    message: t('PleaseInputAtLeast3Characters'),
                  },
                ]}
              >
                <Input allowClear placeholder={t('SearchRoleGroupName')} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item noStyle name="projectIds">
                <MyProjectSelect className="w-100" allowClear />
              </Form.Item>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" disabled={loading}>
                {t('Search')}
              </Button>
            </Col>
          </Row>
        </Form>
        <Table
          rowKey="id"
          tableLayout="auto"
          columns={columns}
          loading={loading}
          dataSource={roleGroups}
          pagination={{
            current: pagination.page,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showTotal: total => `${t('TotalRecords')}:  ${total}`,
          }}
          onChange={onChangePagination}
        />
      </ContentBlock>
    </AppContainer>
  );
};

export default RoleGroupManagement;
