import { IPlot } from 'interfaces';
import { createContext, Dispatch, SetStateAction } from 'react';
import { PAGINATION } from 'constants/pagination';
import { DrawState } from 'constants/map';

interface IProjectState {
  offset: number;
  limit: number;
}

export const usePolygonStore = createContext<IProjectState>({
  offset: 0,
  limit: PAGINATION.PAGE_SIZE,
});

export const CurrentSelectedPolygon = createContext<{
  currentPolygonId: number;
  setCurrentPolygonId: Dispatch<number>;
  errorPolygonIds?: number[];
  setErrorPolygonIds?: Dispatch<SetStateAction<number[]>>;
  drawState?: DrawState;
  setDrawState?: Dispatch<React.SetStateAction<DrawState>>;
}>({
  currentPolygonId: -1,
  setCurrentPolygonId: () => {},
  errorPolygonIds: [],
  setErrorPolygonIds: () => {},
  drawState: DrawState.NONE,
  setDrawState: () => {},
});

export const CurrentSelectedPlot = createContext<{
  currentPlotId: number;
  setCurrentPlotId: Dispatch<SetStateAction<number>>;
}>({
  currentPlotId: -1,
  setCurrentPlotId: () => {},
});

export const CurrentPlots = createContext<{
  plots: IPlot[];
  setPlots: Dispatch<SetStateAction<IPlot[]>>;
  plotsLoading: boolean;
  setPlotsLoading: Dispatch<SetStateAction<boolean>>;
}>({
  plots: [],
  setPlots: () => {},
  plotsLoading: false,
  setPlotsLoading: () => {},
});
