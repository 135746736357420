/** Third party libs **/
import { Col, Drawer, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

/** Local libs **/
import FarmEligibilityFilter from './FarmEligibilityFilter';
import FarmList from './FarmList';
import {
  ICurrentPlacemark,
  IDrawPolygonRef,
  INearbyPolygon,
  IOverlappedFarm,
  IPlacemark,
} from 'interfaces';
import FarmInfo from './FarmInfo';
import { ISurveyForm } from 'interfaces/survey';
import FarmDiscussionHistory from './FarmDiscussionHistory';
import SurveySubmission from './SurveySubmission';
import FarmMap from './FarmMap';
import { IMapPolygon } from 'components/shared/Map';
import ContentBlock from 'components/shared/ContentBlock';
import { farmHooks } from 'hooks';
import { CurrentSelectedFarm } from '../Context';
import { DrawState } from 'constants/map';

/** Styles **/
import './FarmEligibility.scss';
import FarmSurveyReport from './SurveySubmission/FarmSurveyReport';
import { useFlagsupContext } from 'contexts';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { FarmDetailContextProvider } from '../Context/FarmDetailContext';

/** Interfaces, enum... **/
interface IFarmEgibilityProps {}

/** Variables **/

/** ------------------------- **/
/** FarmEgibility Component */
/** ------------------------- **/
const FarmEligibility: React.FC<IFarmEgibilityProps> = () => {
  /** States **/
  const [showFarmList, setShowFarmList] = useState<boolean>(true);
  const [showDiscussionHistory, setShowDiscussionHistory] = useState<boolean>(
    false
  );
  const [selectedFarmId, setSelectedFarmId] = useState<number>();
  const [selectedSurveyForm, setSelectedSurveyForm] = useState<ISurveyForm>();
  const [polygon, setPolygon] = useState<IMapPolygon>();
  const [drawState, setDrawState] = useState<DrawState>(DrawState.NONE);
  const [triggerReloadComment, setTriggerReloadComment] = useState(false);
  const [currentSurveyLocation, setCurrentSurveyLocation] = useState<
    ICurrentPlacemark
  >();
  const [showFarmInfo, setShowFarmInfo] = useState<boolean>(false);
  const [showSurveyReport, setShowSurveyReport] = useState<boolean>(false);
  const [currentFarmArea, setCurrentFarmArea] = useState<number>(0);
  const [listPlacemark, setListPlacemark] = useState<IPlacemark[]>([]);
  const [listFormSurvey, setListFormSurvey] = useState<ISurveyForm[]>([]);
  const [listNearbyFarm, setListNearbyFarm] = useState<INearbyPolygon[]>([]);
  const [overlapPolygon, setOverlapPolygon] = useState<IOverlappedFarm[]>([]);
  const [initalized, setInitalized] = useState(false);

  /** Hooks **/
  const {
    loading,
    loadMore,
    farms,
    refresh,
    params: { farmStatus },
    setParams,
    showLoadMore,
  } = farmHooks.useFarms();
  const drawRef = useRef<IDrawPolygonRef>({
    getDrawPolygon: () => [],
    setInitialPolygon: () => {},
    stopDrawing: () => {},
    clear: () => {},
    refresh: () => {},
    edit: () => {},
    setPolygonViewOnly: () => {},
  });
  const { featureFlagsData } = useFlagsupContext();
  const isCAR2399Enabled = featureFlagsData.CAR_2399?.enabled;
  const { search } = useLocation();

  /** Variables **/
  const query = queryString.parse(search) as any;

  /** Functions, Events, Actions... **/

  /** Effects **/
  useEffect(() => {
    setDrawState(DrawState.NONE);
    setPolygon(undefined);
    setListPlacemark([]);
  }, [selectedFarmId]);

  useEffect(() => {
    if (query.farmlandId && query.isEdit === 'true' && !initalized) {
      setSelectedFarmId(Number(query.farmlandId));
      setShowFarmList(false);
      setShowFarmInfo(true);
      setInitalized(true);
    }
  }, [query]);

  /** Elements **/
  return (
    <div className="farm-eligibility-wrapper">
      <CurrentSelectedFarm.Provider
        value={{
          drawState,
          setDrawState,
          drawRef,
          setTriggerReloadComment,
          currentSurveyLocation,
          setCurrentSurveyLocation,
          selectedFarmId,
          setSelectedFarmId,
          setShowDiscussionHistory,
          setSelectedSurveyForm,
          selectedSurveyForm,
          showDiscussionHistory,
          showFarmList,
          setShowFarmList,
          showFarmInfo,
          setShowFarmInfo,
          showSurveyReport,
          setShowSurveyReport,
          currentFarmArea,
          setCurrentFarmArea,
          listPlacemark,
          setListPlacemark,
          listFormSurvey,
          setListFormSurvey,
          listNearbyFarm,
          setListNearbyFarm,
          overlapPolygon,
          setOverlapPolygon,
          setParams,
        }}
      >
        <Row gutter={2}>
          <Col span={24}>
            <FarmEligibilityFilter
              setKeyword={(keyword?: string) =>
                setParams(prev => ({ ...prev, keyword, page: 1 }))
              }
              refresh={refresh}
            />
          </Col>
        </Row>
        <ContentBlock className="position-relative">
          <div className="map-layer">
            <FarmMap polygon={polygon} />
          </div>

          <div className="enrolment-wrapper">
            <Drawer
              open={showFarmList}
              placement="left"
              getContainer={false}
              extra={undefined}
              maskClosable={false}
              mask={false}
              styles={{
                header: { borderBottom: 'none', padding: 12 },
                body: { padding: 0, borderRadius: 8 },
              }}
              style={{ borderRadius: 8, height: 800 }}
              className="farm-list"
              closable={false}
              footer={undefined}
            >
              <FarmList
                listFarmType={farmStatus}
                setListFarmType={type =>
                  setParams(prev => ({ ...prev, farmStatus: type, page: 1 }))
                }
                loading={loading}
                loadMore={loadMore}
                farms={farms}
                showLoadMore={showLoadMore}
              />
            </Drawer>
            <FarmDetailContextProvider>
              <Drawer
                placement="left"
                open={showFarmInfo}
                mask={false}
                getContainer={false}
                rootStyle={{
                  left: showFarmList ? 380 : 0,
                }}
                extra={undefined}
                styles={{
                  header: { borderBottom: 'none' },
                  body: { padding: 0 },
                }}
                style={{ borderRadius: 8, height: 800 }}
                closable={false}
              >
                <FarmInfo
                  setPolygon={setPolygon}
                  refresh={refresh}
                  listFarmType={farmStatus}
                  setListFarmType={type =>
                    setParams(prev => ({ ...prev, farmStatus: type, page: 1 }))
                  }
                />
              </Drawer>

              <Drawer
                placement="left"
                open={!!selectedSurveyForm}
                mask={false}
                getContainer={false}
                zIndex={998}
                extra={undefined}
                styles={{
                  header: { borderBottom: 'none' },
                  body: { padding: 0 },
                }}
                style={{ borderRadius: 8, height: 800 }}
                closable={false}
                destroyOnClose
              >
                <SurveySubmission
                  selectedSurveyForm={selectedSurveyForm}
                  onClose={() => {
                    setSelectedSurveyForm(undefined);
                    setShowFarmInfo(true);
                  }}
                />
              </Drawer>
            </FarmDetailContextProvider>
            <Drawer
              placement="left"
              open={showDiscussionHistory}
              mask={false}
              getContainer={false}
              zIndex={998}
              extra={undefined}
              styles={{
                header: { borderBottom: 'none' },
                body: { padding: 0 },
              }}
              style={{ borderRadius: 8, height: 800 }}
              closable={false}
              destroyOnClose
            >
              <FarmDiscussionHistory
                selectedFarmId={selectedFarmId}
                setShowDiscussionHistory={setShowDiscussionHistory}
                showDiscussionHistory={showDiscussionHistory}
                triggerReloadComment={triggerReloadComment}
              />
            </Drawer>
          </div>
          {showSurveyReport && isCAR2399Enabled && <FarmSurveyReport />}
        </ContentBlock>
      </CurrentSelectedFarm.Provider>
    </div>
  );
};

export { FarmEligibility };
export default FarmEligibility;
