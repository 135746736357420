import { Spin } from 'antd';
import { ID_DEFAULT } from 'constants/common';
import { useProjectDetailV2Context } from 'containers/Project/ProjectDetailMapV2/context';
import { mapHelpers } from 'helpers';
import { plotHooks } from 'hooks';
import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { selectShowingSamplingPlotTypes } from 'redux/features/samplingPlot';
import SamplingPlotPoint from './SamplingPlotPoint';

interface ISamplingPlotProps {
  mapRef: React.RefObject<HTMLDivElement>;
}

const { getSearchBoxObjectFromMap, isValidLocation } = mapHelpers;

const SamplingPlot: React.FC<ISamplingPlotProps> = ({ mapRef }) => {
  const showingSamplingPlotsTypes = useSelector(selectShowingSamplingPlotTypes);
  const map = useMap();

  const { plots, loading, getPlots } = plotHooks.usePlots();
  const { currentPolygonId } = useProjectDetailV2Context();

  useEffect(() => {
    if (
      Array.isArray(showingSamplingPlotsTypes) &&
      showingSamplingPlotsTypes.length > 0
    ) {
      const mapCornerCoordinates = getSearchBoxObjectFromMap(map);
      getPlots({
        ...mapCornerCoordinates,
        ...(currentPolygonId !== ID_DEFAULT
          ? { polygonId: currentPolygonId }
          : {}),
        samplingModuleIds: showingSamplingPlotsTypes,
      });
    }
  }, [showingSamplingPlotsTypes]);

  /** Elements **/
  return (
    <Spin spinning={loading}>
      {Array.isArray(plots) &&
        plots.map(plot => (
          <>
            {isValidLocation(plot) && (
              <SamplingPlotPoint plot={plot} mapRef={mapRef} />
            )}
          </>
        ))}
    </Spin>
  );
};

export { SamplingPlot };
export default SamplingPlot;
