import { resourceConstants } from 'constants/index';
import { permission } from './common';

const { APPS, ACTIONS } = resourceConstants;

const getPermission = (app: string, resource: string, action?: string) => {
  return [app, resource, action].filter(x => !!x).join(':');
};

const tekboneReadPermission = (resource: string) =>
  permission(APPS.PROJECT_MANAGER_TEKBONE, resource, ACTIONS.READ);

const tekboneUpdatePermission = (resource: string) =>
  permission(APPS.PROJECT_MANAGER_TEKBONE, resource, ACTIONS.UPDATE);

export default {
  getPermission,
  tekboneReadPermission,
  tekboneUpdatePermission,
};
