import { EditOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { useDisclosure } from 'components/shared/Modal/useDisclosure';
import { commonConstants } from 'constants/index';
import { COLOR } from 'constants/styles/color';
import { t } from 'helpers/i18n';
import { userHooks } from 'hooks';
import ProjectPlotMeasureForm from './ProjectPlotMeasureForm';

interface IProjectPlotMeasureField {
  samplingPlots?: number;
  suggestGeneratePlots: number;
  polygonId: number;
  plotsWithData: number;
  mutatePolygon: Function;
}

const { useUserPermissions } = userHooks;

const { IS_AJA } = commonConstants;

const ProjectPlotMeasureField = (props: IProjectPlotMeasureField) => {
  const { canUpdateProject } = useUserPermissions();
  const allowEdit = !IS_AJA || canUpdateProject;
  const { isOpen: isEditing, onClose, onOpen } = useDisclosure();

  return (
    <>
      {!isEditing && !!props.samplingPlots && (
        <Space style={{ float: 'right' }}>
          <Typography.Text>{props.samplingPlots}</Typography.Text>
          <Button
            ghost
            icon={<EditOutlined />}
            size="small"
            style={{ color: COLOR.SUPPORT, zIndex: 99 }}
            onClick={e => {
              e.stopPropagation();
              onOpen();
            }}
            data-testid="btn-edit"
            disabled={!allowEdit}
          />
        </Space>
      )}
      {!isEditing && !props.samplingPlots && (
        <Button
          type="link"
          style={{
            color: COLOR.SUPPORT,
            textAlign: 'right',
            paddingRight: 0,
            zIndex: 99,
            whiteSpace: 'normal',
          }}
          onClick={e => {
            e.stopPropagation();
            onOpen();
          }}
          disabled={!allowEdit}
        >
          {t('UseSuggestionBySystem')}
        </Button>
      )}
      {isEditing && (
        <ProjectPlotMeasureForm
          {...props}
          isEditing={isEditing}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default ProjectPlotMeasureField;
