import { Row } from 'antd';
import React from 'react';
import './ContractRules.scss';
import { IContract } from 'interfaces';
import { GENDER_OPTIONS } from 'constants/common';

const ContractRules: React.FC<{ contract: IContract }> = ({ contract }) => {
  return (
    <Row gutter={[16, 16]} className="rule-wrap">
      <h4>FARMER PARTICIPATION AGREEMENT</h4>{' '}
      <p>
        <b>
          <u>AJA Ghana Limited (“AJA”)</u>
        </b>{' '}
        <i>
          is undertaking a landscape restoration project in the Kwahu East and
          Kwahu South Districts. The Project aims to support farmers to plant
          shade trees, plantain and tree crops as a way to “restore” or
          “reforest” the degraded lands. The Project seeks the participation or
          farmers in the landscape, who will gain the benefits described below
          in return for the undertakings and committing to the practices
          described below. Please confirm your agreement to join and support the
          Project by completing this form.
        </i>
      </p>
      <p className="table-view">
        <div>
          <b>Farmer’s Name:</b>&nbsp;{contract.farmerName}
          <br />
          <br />
        </div>
        <div>
          Farmer’s National ID N° (GhanaCard or other ID):&nbsp;
          {contract.farmerNationalIdNumber}
          <br />
          <br />
          <br />
        </div>
        <div>
          Farmer’s gender:&nbsp;
          {contract.farmerGender
            ? GENDER_OPTIONS[contract.farmerGender]?.label
            : ''}
          <br />
          <br />
          <br />
        </div>
        <div>
          Community / Residence / Address:&nbsp;{contract.communityName}
          <br />
          <br />
          <br />
        </div>
        <div>
          Contact number:&nbsp;{contract.phoneNumber}
          <br />
          <br />
          <br />
        </div>
      </p>
      Note: other data captured in the onboarding process
      <h5>1.&emsp;Understanding:</h5>
      <p>
        I understand the purpose of the Project and confirm that the benefits,
        responsibilities, and duties of participating in the Project have been
        fully explained to me in both English and Twi (or such other language as
        I have requested) in a manner which I fully understand and agree with.
      </p>
      <h5>2.&emsp;Consent:</h5>
      <p>
        I willingly consent and agree to the following:{' '}
        <ul>
          <li>
            To plant plantain, shade trees, and tree crops (if selected) like
            cocoa, coffee, or cola under the Project as directed by AJA
            representatives.
          </li>
          <li>
            To work according to the principles, goals and rules of the Project,
            and in alignment with the CREMA rules that are applicable where I
            reside, which will be drafted during the CREMA development process
            this year.
          </li>
          <li>
            To permit AJA and its designees to collect, have access to, and
            process my personal and farmland data, as recorded herein and as
            otherwise collected by AJA with respect to the Project, including
            the mapping of my farm with a GPS system and to create maps based
            upon these data.
          </li>
          <li>
            To cooperate with AJA in connection with the requirements of the
            carbon verification process and steps for the Project.
          </li>
          <li>
            I also allow AJA to record my data and use it for monitoring and
            reporting purposes.
          </li>
        </ul>
      </p>
      <h5>3.&emsp;Benefits for Farmers in Good standing under the Project:</h5>
      <div>I understand that:</div>
      <p className="sub-list">
        <span>A.</span> AJA will provide me, free of charge:
      </p>
      <p className="sub-list">
        <ul className="circle-mark">
          <li>
            An agreed number of plantain suckers/ha in year 1 of the Project.
          </li>
          <li>
            An agreed number of shade tree seedlings/ha in years [2 and 3] of
            the Project
          </li>
          <li>
            Other suckers and seedlings from time to time as may be determined
            by AJA.
          </li>
          <li>
            Assistance and guidance in planting and management of the above.
          </li>
        </ul>
      </p>
      <p className="sub-list">
        <span>B.</span>
        <div>
          These seedlings may be cola trees, kombo nut trees and other
          non-timber forest product or botanicals (“NTFP”), and that the produce
          of the above plantings will belong to, and may be sold by me, and that
          I may retain the proceeds of such sales.
        </div>
      </p>
      <p className="sub-list">
        <span>C.</span>
        During the course of the Project (i.e., after year 2-3) if I opt to grow
        cocoa/coffee/other on my farm, or require support for improving my
        existing cocoa/coffee/other crops, then I may enter into a specific
        agreement to this effect with AJA.
      </p>
      <p className="sub-list">
        <span>D.</span>
        The community within which my Farm is located will be eligible to
        receive carbon benefits assuming all participating farmers follow the
        agreed farm management and other related requirements of the Project.
        Such carbon benefits may also directly benefit me.
      </p>
      <h5>4.&emsp;Farmer’s responsibilities under the Project:</h5>
      <p>
        I understand that to be in good standing under the project, I will
        undertake to:
        <ul>
          <li>Follow the rules of the CREMA and the Project management.</li>
          <li>
            Actively work to establish and manage a productive farm that has a
            high shade canopy of native tree species.
          </li>
          <li>
            Not to light fires or burn any crops, existing vegetation, or trees
            on the farm, and to actively work with the Project and the CREMA to
            prevent bush fires coming into my farm.
          </li>
          <li>
            To nurture and protect all naturally regenerated and planted trees
            on the farm so that carbon dioxide is sequestered and project
            benefits can be realized.
          </li>
          <li>
            To promptly share any farm challenges which may result in the loss
            of shade trees, to AJA field officers or coordinators, and to work
            with AJA team to find a solution.
          </li>
          <li>
            To maintain the shade canopy of native trees that provide carbon
            sequestration.
          </li>
          <li>
            Not to cut or allow the cutting of any trees in the farm which may
            result in the loss of carbon sequestration. In the event that trees
            on the farm are cut, intentionally killed (e.g., by burning,
            ring-barking, poison, etc…) or are damaged through neglect or
            abandonment of the farm, I understand that I may forfeit any claim
            to individual carbon benefit sharing or further benefits under the
            Project.
          </li>
          <li>
            Support the Project in subsequent years by supplying to AJA (or
            allowing AJA to collect from the farm) 300 plantain suckers/ha/year,
            for AJA to use to start new plantings with other farmers.
          </li>
          <li>
            AJA has the first-right to offer me a purchase price for: (a) the
            plantain and (b) the NTFP, grown by me before sale to any other
            entity / person.
          </li>
        </ul>
      </p>
      <h5>5.&emsp;Personal Data:</h5>
      <p>
        <ul>
          <li>
            I understand that AJA will use, process and protect my personal data
            (and other information I provide) in compliance with the Ghana Data
            Protection Act 2012 (the ‘Act’), and I expressly consent to such use
            and processing.
          </li>
          <li>
            I have the right to withdraw my consent as provided in the Act,
            although this may impact my participation in the Project.
          </li>
        </ul>
      </p>
      <h5>6.&emsp;Additional Provisions:</h5>
      <p>
        <ul>
          <li>
            I have the right to escalate any issues I have to AJA and to appeal
            decisions made by AJA management relevant to my participation in the
            Project, by using the whistleblowing and grievance procedures which
            can be found on AJA’s website.
          </li>
          <li>
            This agreement applies for the full duration of the Project,
            although I may opt out of my participation in the Project at any
            time upon 3 months written notice to AJA.
          </li>
        </ul>
      </p>
    </Row>
  );
};

export default ContractRules;
