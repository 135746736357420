import React, { useContext } from 'react';
import { useMapEvents } from 'react-leaflet';
import { IMapPolygon, IPolygon, MapPolygons } from 'components/shared/Map';
import { CurrentSelectedPolygon } from 'components/ProjectList/ProjectModal/context';
import { DrawState } from 'constants/map';

interface FormationMapWrapperProps {
  polygons: IMapPolygon[];
  preventOnClick?: boolean;
}

const FormationMapWrapper: React.FC<FormationMapWrapperProps> = ({
  polygons,
  preventOnClick,
}) => {
  const map = useMapEvents({});
  const {
    currentPolygonId,
    setCurrentPolygonId,
    drawState,
    errorPolygonIds,
  } = useContext(CurrentSelectedPolygon);

  const onPolygonClick = (polygon: IPolygon, curPolygonInstance: any) => {
    if (drawState === DrawState.DRAW || drawState === DrawState.EDIT) return;
    const bounds = curPolygonInstance.getBounds();
    const position = bounds.getCenter();
    if (position) {
      map.fitBounds(bounds, { animate: true, duration: 2 });
    }
    setCurrentPolygonId(polygon.id || -1);
  };

  return (
    <MapPolygons
      activePolygonIds={[currentPolygonId]}
      errorPolygonIds={errorPolygonIds}
      onPolygonClick={!preventOnClick ? onPolygonClick : undefined}
      polygons={polygons}
    />
  );
};

export default FormationMapWrapper;
