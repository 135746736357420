import { Badge, Button, Divider, Drawer, Space, Spin } from 'antd';
import React, { useEffect } from 'react';
import {
  FolderOutlined,
  FundOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { t } from 'helpers/i18n';
import { communityHooks } from 'hooks';
import { ID_DEFAULT } from 'constants/common';
import './CommunityOverview.scss';
import { useStoreContext } from 'contexts';
import { WORLDBANK_USER_ROLES } from 'constants/community';

interface ICommunityOverviewProps {
  communityName: string;
  communityId: number;
  onClose: () => void;
}

const { FOREST_OWNER } = WORLDBANK_USER_ROLES;

const CommunityOverview: React.FC<ICommunityOverviewProps> = ({
  communityName,
  communityId,
  onClose,
}) => {
  const {
    communityOverview,
    loading,
    getCommunityOverview,
  } = communityHooks.useCommunityOverview();
  const history = useHistory();
  const { currentUser } = useStoreContext();
  const { roles = [] } = currentUser || {};
  const isForestOwner = roles.includes(FOREST_OWNER);

  useEffect(() => {
    if (communityId !== ID_DEFAULT) getCommunityOverview(communityId);
  }, [communityId]);

  return (
    <Drawer
      title={communityName}
      placement="left"
      closable={true}
      destroyOnClose={true}
      maskClosable={false}
      onClose={onClose}
      open={communityId !== ID_DEFAULT}
      getContainer={false}
      mask={false}
      styles={{
        header: { borderBottom: 'none', padding: 12 },
        body: { padding: 12 },
      }}
      style={{ borderRadius: 8 }}
      className="comunity-overview"
    >
      {loading ? (
        <Spin spinning={loading} className="w-100" />
      ) : (
        <Space direction="vertical" className="w-100">
          <Button
            type="link"
            onClick={() => history.push(`/funds?communityId=${communityId}`)}
            className="w-100 text-left p-0 d-flex align-items-center"
          >
            <FolderOutlined style={{ fontSize: 18 }} />
            {t('Funds')}
            <Badge
              count={communityOverview?.totalFunds}
              showZero
              className="ml-half"
            />
          </Button>

          {!isForestOwner && (
            <>
              <Divider className="m-0" />
              <Button
                type="link"
                onClick={() =>
                  history.push(
                    `/community/meeting-records?communityId=${communityId}`
                  )
                }
                className="w-100 text-left p-0 d-flex align-items-center"
              >
                <ProfileOutlined style={{ fontSize: 18 }} />
                {t('MeetingRecord')}
                <Badge
                  count={communityOverview?.totalMeetingMinutes}
                  showZero
                  className="ml-half"
                />
              </Button>
            </>
          )}
          <Divider className="m-0" />
          <Button
            type="link"
            onClick={() =>
              history.push(
                `/financial-plan?inReview=true&organizationId=${communityId}&parentOrganizationId=${communityOverview?.parentOrganizationId}`
              )
            }
            className="w-100 text-left p-0 d-flex align-items-center"
          >
            <FundOutlined style={{ fontSize: 18 }} />
            {t('FinancialPlan')}
          </Button>
        </Space>
      )}
    </Drawer>
  );
};

export { CommunityOverview };
