import { GOOGLE_DRIVE_ONLINE_VIEWER } from 'constants/file';
import IFrameContainer from 'containers/shared/IFrameContainer';
import InProgressPage from 'containers/shared/InProgressPage';
import { useProjectContext } from 'contexts';
import { RouteComponentProps } from 'react-router-dom';

export interface IFrameContainerProps extends RouteComponentProps {
  iFrameSrc: string;
}

export const PROJECTS = window.appConfig?.projects || {};

const LandPermit: React.FC<IFrameContainerProps> = props => {
  const { projectId } = useProjectContext();
  const iframeUrl = {
    [PROJECTS.GreenKonservasi]:
      'https://files.tekoapis.com/files/97e5a36b-0e21-484e-a01a-9f91cd9ebf46',
    [PROJECTS.HutaniBerkah]:
      'https://files.tekoapis.com/files/613d6603-cbd2-4a83-88cd-9fe96b5b3eab',
  };

  return iframeUrl[projectId] ? (
    <IFrameContainer
      {...props}
      iFrameSrc={GOOGLE_DRIVE_ONLINE_VIEWER(iframeUrl[projectId])}
    />
  ) : (
    <InProgressPage />
  );
};

export default LandPermit;
