/** Third party libs * */
import React, { useEffect } from 'react';
import { Input, Select } from 'antd';

/** Local libs * */
import { t } from 'helpers/i18n';

/** Components * */
import { BaseItemProps, BaseItem } from '../BaseItem';

/** Styles * */

/** Interfaces, enum... * */
interface DropdownProps extends BaseItemProps {
  readOnly?: boolean;
}

/** Variables * */

/** ------------------------- * */
const Dropdown: React.FC<DropdownProps> = ({
  item,
  disabled,
  form,
  readOnly,
  ...props
}) => {
  /** States * */

  /** Hooks * */

  /** Variables * */
  const options = item.suggestionValues || [];

  /** Effects * */
  useEffect(() => {
    if (item.fieldName) {
      if (!(readOnly || disabled))
        form.setFieldValue(item.fieldName, item.value);
      else
        form.setFieldValue(
          item.fieldName,
          options.find(o => o.value === item.value)?.label
        );
    }
  }, [item.value]);

  /** Functions, Events, Actions... * */

  /** Elements * */
  return (
    <BaseItem item={item} disabled={disabled} form={form} {...props}>
      {readOnly || disabled ? (
        <Input
          readOnly
          title={options.find(o => o.value === item.value)?.label ?? ''}
          style={{ backgroundColor: '#0000000a' }}
          disabled={disabled}
        />
      ) : (
        <Select
          style={{ width: '100%' }}
          placeholder={t('SelectValue')}
          options={options}
          showSearch={true}
          aria-readonly={readOnly}
        />
      )}
    </BaseItem>
  );
};

export default Dropdown;
