/** Third party libs * */
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { DatePicker as AntdDatePicker, TimePicker } from 'antd';

/** Local libs * */
import { BaseItemProps, BaseItem } from '../BaseItem';

/** Components * */

/** Styles * */

/** Interfaces, enum... * */
interface DatePickerProps extends BaseItemProps {
  timePicker?: boolean;
}

/** Variables * */

/** ------------------------- * */
const DatePicker: React.FC<DatePickerProps> = ({
  item,
  form,
  disabled,
  timePicker,
  ...props
}) => {
  /** States * */

  /** Hooks * */

  /** Variables * */

  /** Effects * */
  useEffect(() => {
    if (item.fieldName) {
      let value;
      try {
        value = dayjs.unix(Number(item.value));
        value = item.value && value.isValid() ? value : undefined;
      } finally {
        form.setFieldValue(item.fieldName, value);
      }
    }
  }, [item.value]);

  /** Functions, Events, Actions... * */

  /** Elements * */
  return (
    <BaseItem item={item} disabled={disabled} form={form} {...props}>
      {timePicker ? (
        <TimePicker
          style={{ width: '100%' }}
          format={'HH:mm'}
          allowClear={!item.isRequired}
          disabled={disabled}
        />
      ) : (
        <AntdDatePicker
          style={{ width: '100%' }}
          format={'DD/MM/YYYY'}
          allowClear={!item.isRequired}
          disabled={disabled}
        />
      )}
    </BaseItem>
  );
};

export default DatePicker;
