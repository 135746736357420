import { Card, Col, Empty, Input, InputRef, Row } from 'antd';
import { t } from 'helpers/i18n';
import React, { useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { PhoneOutlined, TeamOutlined, FlagOutlined } from '@ant-design/icons';
import { CardList } from 'components/shared/CardList';
import { contractHooks } from 'hooks';
import './Contracts.scss';
import { ContractDetail } from './Detail';
import { convertNumberFormat } from 'helpers/common';
import ContentBlock from 'components/shared/ContentBlock';

const Contracts: React.FC = () => {
  const {
    contracts,
    selectedContract,
    setSelectedContract,
    onChangeKeyword,
    loading: loadingContracts,
    getMoreContracts,
  } = contractHooks.useContracts();
  const inputRef = useRef<InputRef>(null);

  const standardizedContracts = contracts.map(contract => {
    return {
      id: contract.contractId,
      title: contract.farmerName,
      properties: [
        {
          value: contract.phoneNumber,
          icon: PhoneOutlined,
        },
        {
          value:
            !!contract.farmArea &&
            `Area: ${convertNumberFormat(contract.farmArea)} ha`,
          icon: FlagOutlined,
        },
        {
          value: contract.communityName,
          icon: TeamOutlined,
        },
      ],
    };
  });

  return (
    <Row gutter={[16, 16]}>
      <Col xs={10} xl={8} xxl={7}>
        <Input
          ref={inputRef}
          onPressEnter={(event: any) => {
            const keyword = event.target?.value;
            onChangeKeyword(keyword);
          }}
          placeholder={t('FarmerSearch')}
          suffix={
            <SearchOutlined
              onClick={() => {
                const keyword = inputRef.current?.input?.value;
                onChangeKeyword(keyword);
              }}
            />
          }
        />
      </Col>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={10} xl={8} xxl={7}>
            <ContentBlock>
              <CardList
                loading={loadingContracts}
                items={standardizedContracts}
                onLoadMore={getMoreContracts}
                className={'contract-container'}
                curItemId={selectedContract?.contractId}
                onChange={contract => {
                  const selectedContractId = contract.id;
                  const selectedContract = contracts.find(
                    contract => contract.contractId === selectedContractId
                  );
                  if (selectedContract) setSelectedContract(selectedContract);
                }}
              />
            </ContentBlock>
          </Col>
          <Col xs={14} xl={16} xxl={17}>
            {selectedContract ? (
              <ContractDetail contract={selectedContract} />
            ) : (
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Card className="contract-container d-flex align align-items-center justify-content-center">
                    <Empty />
                  </Card>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export { Contracts };
export default Contracts;
