import { t } from 'helpers/i18n';
import { IPlot } from 'interfaces';
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { CurrentSelectedPlot } from '../context';
import PlotCustomInfoBox from './PlotCustomInfoBox';
import { Marker, Popup } from 'react-leaflet';
import styled from 'styled-components';
import { LeafletMarkerIcon } from 'components/shared/Map';
import { plotHooks } from 'hooks';
import { Empty, Space, Spin } from 'antd';
import { useFlagsupContext } from 'contexts';

interface IProjectCustomMarker {
  plot: IPlot;
  plotId: number;
  polygonId: number;
  projectId?: number;
  hasData: boolean;
}

const StyledPopup = styled(Popup)`
  .leaflet-popup-close-button {
    top: 8px !important ;
    right: 8px !important;
  }
  .leaflet-popup-content {
    width: max-content !important;
  }
`;

const ProjectCustomMarker = ({
  hasData,
  plotId,
  plot,
  polygonId,
}: IProjectCustomMarker) => {
  const [isOpen, setIsOpen] = useState(false);
  const { currentPlotId, setCurrentPlotId } = useContext(CurrentSelectedPlot);
  const { plotDetails, gettingDetails, getPlotDetails } = plotHooks.usePlots();
  const { featureFlagsData } = useFlagsupContext();
  const isCAR418Enabled = featureFlagsData.CAR_418?.enabled;

  const leafletMarkerIcon = useMemo(() => {
    if (isOpen) return LeafletMarkerIcon.ACTIVE;
    return hasData
      ? LeafletMarkerIcon.DEFAULT.HAS_DATA
      : LeafletMarkerIcon.DEFAULT.NO_DATA;
  }, [isOpen]);

  useLayoutEffect(() => {
    if (currentPlotId !== plotId) onCloseInfoBox();
  }, [currentPlotId]);

  useEffect(() => {
    if (isOpen && isCAR418Enabled) {
      getPlotDetails(plotId, { polygonId });
    }
  }, [isOpen]);

  const onOpenInfoBox = () => {
    setCurrentPlotId(plotId);
    setIsOpen(true);
  };

  const onCloseInfoBox = () => {
    setIsOpen(false);
  };

  return (
    <Marker
      position={{ lat: plot.latitude, lng: plot.longitude }}
      title={`${t('Plot')} ${plot.plotName || plotId}`}
      icon={leafletMarkerIcon}
      eventHandlers={{
        popupopen: () => {
          onOpenInfoBox();
        },
        popupclose: () => {
          onCloseInfoBox();
        },
      }}
    >
      <StyledPopup>
        {isCAR418Enabled ? (
          gettingDetails ? (
            <Space className="p-base">
              <Spin />
            </Space>
          ) : plotDetails ? (
            <PlotCustomInfoBox
              plot={plotDetails}
              polygonId={polygonId}
              hasData={hasData}
            />
          ) : (
            <Space>
              <Empty />
            </Space>
          )
        ) : (
          <PlotCustomInfoBox
            plot={plot}
            polygonId={polygonId}
            hasData={hasData}
          />
        )}
      </StyledPopup>
    </Marker>
  );
};

export default ProjectCustomMarker;
