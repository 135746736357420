import React from 'react';
import Container from 'components/FPIC/shared/Container';
import ListWrap from 'components/FPIC/List';

/** ------------------------- * */
const List: React.FC<{ name: string; hideTitle?: boolean }> = ({
  name,
  hideTitle,
}) => {
  return (
    <Container title={!hideTitle ? name : ''}>
      <ListWrap />
    </Container>
  );
};

export default List;
