import { IPaginationParams, IPaginationResponse } from './common';

export enum ProcessingFileType {
  IMPORT_USER = 'IMPORT_USER',
  IMPORT_FUND = 'IMPORT_FUND',
  IMPORT_SAMPLING_PLOT = 'IMPORT_SAMPLING_PLOT',
}

export interface IGetListProcessingFilesParams extends IPaginationParams {
  type: ProcessingFileType;
  isMyUploaded?: boolean;
}

export interface IGetListProcessingFilesResponse {
  processFiles: IProcessingFile[];
  pagination: IPaginationResponse;
}

export interface IProcessingFile {
  clientId: number;
  processFileId: number;
  fileDisplayName: string;
  fileUrl: string;
  resultFileUrl: string;
  status: 'INIT' | 'PROCESSING' | 'FAILED' | 'FINISHED';
  statsTotalRow: number;
  statsTotalSuccess: number;
  statsTotalProcessed: number;
  errorDisplay: string;
  createdAt: number;
  createdBy: string;
  finishedAt: number;
}

export interface IDownloadTemplateParams {
  type: ProcessingFileType;
  projectId: number;
}
