import { SearchOutlined } from '@ant-design/icons';
import { Card, Col, Form, Input, Row } from 'antd';
import React from 'react';
import { ID_DEFAULT } from 'constants/common';
import './CommunitiesFilter.scss';

interface ICommunitiesFilterProps {
  setKeyword: (value: string) => void;
  setCurrentCommunity: React.Dispatch<
    React.SetStateAction<{
      communityId: number;
      communityName: string;
    }>
  >;
}

const CommunitiesFilter: React.FC<ICommunitiesFilterProps> = ({
  setKeyword,
  setCurrentCommunity,
}) => {
  const [form] = Form.useForm();
  const keyword = Form.useWatch('keyword', form);

  const onClickSearch = () => {
    setKeyword(keyword);
    setCurrentCommunity({
      communityId: ID_DEFAULT,
      communityName: '',
    });
  };

  return (
    <div className="communities-filter">
      <Card className="w-100 p-0">
        <Form form={form}>
          <Row>
            <Col xxl={10} xs={14}>
              <Form.Item name={'keyword'} label={''}>
                <Input
                  placeholder="Search"
                  suffix={<SearchOutlined onClick={onClickSearch} />}
                  onPressEnter={onClickSearch}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export { CommunitiesFilter };
