import {
  IPaginationParams,
  IPaginationResponse,
  IPaginationOffsetParams,
  IPaginationOffsetResponse,
} from './common';

export enum ActivityStatus {
  IN_REVIEW = 'IN_REVIEW',
  NEED_TO_UPDATE = 'NEED_TO_UPDATE',
  APPROVED = 'APPROVED',
  INIT = 'INIT',
  REJECTED = 'REJECTED',
  MEETING = 'MEETING',
}

export interface IActivitiesStatus {
  total: number;
  [ActivityStatus.IN_REVIEW]: number;
  [ActivityStatus.NEED_TO_UPDATE]: number;
  [ActivityStatus.APPROVED]: number;
}

export interface IActivity {
  activityId: number;
  activityName: string;
  categoryName: string;
  proposeCategoryName: string;
  categoryId: number;
  proposedAmount: number;
  description: string;
  totalLike: number;
  totalComment: number;
  isAllowUpdate: boolean;
  status: ActivityStatus;
  updatedAt: number;
  subCategoryId: number;
}

export interface IGetListActivitiesParams extends IPaginationParams {
  projectId: number;
  categoryId?: number;
  organizationId?: number;
  statuses?: string;
  year?: number;
}

export interface IGetListActivitiesResponse {
  activities: IActivity[];
  pagination: IPaginationResponse;
}

export interface IActivityCategory {
  id: number;
  name: string;
  code: string;
}

export interface IGetActivityCategoriesResponse {
  categories: IActivityCategory[];
}

export interface IGetCommunityDashboardFundingParams {
  projectId: number;
  communityId: number;
}

export interface IGetCommunityDashboardFundingResponse {
  communityId: number;
  communityName: string;
  totalPlannedAmount: number;
  totalAgreedAmount: number;
}

export interface ActivityDetail {
  activityId: number;
  activityName: string;
  activityDescription: string;
  proposedAmount: number;
  meetingDate: number;
  meetingStartTime: number;
  meetingEndTime: number;
  percentPeopleAgree: number;
  fromTime: number;
  toTime: number;
  totalFundAmount: number;
  activityFunds: ActivityFund[];
}

export interface ActivityFund {
  creatorOrganizationName: string;
  activityFundId: number;
  planAmount: number;
  status: 'IN_REVIEW' | 'APPROVED' | 'NEED_TO_UPDATE' | 'DELETED';
}

export interface IGetActivityDetailParams {
  projectId: number;
  activityId: number;
}

export interface IGetOrgActivityLogsParams {
  offset?: string;
  limit: number;
  projectId: number;
  activityId: number;
}

export interface IGetActivityExpenseReceiptsParams
  extends IPaginationOffsetParams {
  projectId: number;
  activityId: number;
}

export interface OrganizationActivityLog {
  id: number;
  address: string;
  imageFiles: string[];
  recordingFiles: string[];
  description: string;
  createdByUsername: string;
  createdAt: number;
  type: 'ApproveActivity' | 'RejectActivity' | 'CreateActivity';
}

export interface IGetOrgActivityLogsResponse {
  organizationActivityLogs: OrganizationActivityLog[];
  pagination: { offset: string };
}

export type PaymentMethod = 'CASH' | 'BANK_TRANSFER';

export interface ActivityExpenseReceipt {
  id: number;
  amount: number;
  paymentMethod: 'CASH' | 'BANK_TRANSFER';
  receiptDate: number; //unix timestamp
  note: string;
  imageFiles: string[];
}

export interface IGetActivityExpenseReceiptsResponse {
  communityFundAmount: number;
  totalExpenseAmount: number;
  activityExpenseReceipts: ActivityExpenseReceipt[];
  pagination: IPaginationOffsetResponse;
}

export interface IApproveActivityPayload {
  activityId: number;
  projectId: number;
}

export interface IRejectActivityPayload {
  activityId: number;
  projectId: number;
  feedback?: string;
}

export interface IGetActivityLikesAndCommentsParams extends IPaginationParams {
  activityId: number;
}

export interface ActivityComment {
  activityId: number;
  commentId: number;
  content: string;
  createdAt: number;
}

export interface IGetActivityLikesAndCommentsResponse {
  totalLike: number;
  comments: ActivityComment[];
  pagination: IPaginationResponse;
}

export interface ILikeOrCommentActivityParams {
  activityId: number;
  type: 'LIKE' | 'COMMENT';
  content?: string;
}

export interface IDownloadCommunityFinancialPlanParams {
  projectId: number;
  communityId: number;
  financialYear: number;
}
