import trackerUtils from './trackerUtils';

const { CLICK_DATA, getPayload } = trackerUtils;

type ScreenNameOptions = 'submissionList' | 'submissionDetail';

const trackCarbonExplaination = (screenName: ScreenNameOptions) => {
  window.track('manualTrackInteractionContent', {
    ...CLICK_DATA,
    screenName: screenName,
    regionName: 'carbonContent',
    contentName: 'carbonExplaination',
    target: screenName,
    payload: getPayload('submissionDetail', 0),
  });
};

export default {
  trackCarbonExplaination,
};
