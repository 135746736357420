import React, { useState } from 'react';
import AppContainer from 'containers/AppLayout/AppContainer';
import {
  MeetingRecordsFilter,
  MeetingRecordsList,
} from 'components/MeetingRecords';
import { meetingRecordHooks } from 'hooks';
import { CurrentMeetingRecordContext } from 'components/MeetingRecords/context';

const MeetingRecords: React.FC = () => {
  const [currentCommunityName, setCurrentCommunityName] = useState('');
  const {
    getMeetingMinutes,
    loading,
    meetingMinutes,
  } = meetingRecordHooks.useMeetingMinutes();

  return (
    <AppContainer>
      <CurrentMeetingRecordContext.Provider
        value={{ currentCommunityName, setCurrentCommunityName }}
      >
        <MeetingRecordsFilter getMeetingMinutes={getMeetingMinutes} />
        <MeetingRecordsList meetingMinutes={meetingMinutes} loading={loading} />
      </CurrentMeetingRecordContext.Provider>
    </AppContainer>
  );
};

export default MeetingRecords;
