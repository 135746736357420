import { Button, Modal } from 'antd';
import { useDisclosureProps } from 'components/shared/Modal/useDisclosure';
import { useProjectContext } from 'contexts';
import { t } from 'helpers/i18n';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface CreateBoundariesConfirmModalProps {
  modal: useDisclosureProps;
}

/**
 * Currently, this component only used for base web because it has permission to create new project
 * So history.push use the path of base web.
 */
const CreateBoundariesConfirmModal: React.FC<CreateBoundariesConfirmModalProps> = ({
  modal,
}) => {
  const { getProjects } = useProjectContext();
  const history = useHistory();
  const commonAction = async () => {
    modal.onClose();
    await getProjects();
  };
  const onOk = async () => {
    await commonAction();
    history.push('/project-boundaries?addBoundary=true');
  };
  const onCancel = async () => {
    await commonAction();
    history.push('/project-boundaries');
  };
  return (
    <Modal
      open={modal.isOpen}
      centered
      closable={false}
      width={400}
      zIndex={2001}
      title={
        <div className="text-center">{t('DoYouWantToCreateProjectArea')}</div>
      }
      footer={
        <div className="text-center">
          <Button
            key="back"
            onClick={onCancel}
            className="mr-half neutral-button"
          >
            {t('Cancel')}
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            onClick={onOk}
            data-testid="btn-submit"
            className="primary-base-button"
          >
            {t('OK')}
          </Button>
        </div>
      }
    />
  );
};

export { CreateBoundariesConfirmModal };
