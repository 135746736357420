import IFrameContainer from 'containers/shared/IFrameContainer';
import { reportHooks } from 'hooks';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

export interface IFrameContainerProps extends RouteComponentProps {
  iFrameSrc: string;
}

const MillsReports: React.FC<IFrameContainerProps> = props => {
  const { generateMillsReports } = reportHooks.useAjaReport();
  return <IFrameContainer {...props} iFrameSrc={generateMillsReports()} />;
};

export default MillsReports;
