import { getData } from 'helpers/request';
import {
  IBaseApiResponse,
  IGetDiscussionHistoryParams,
  IGetDiscussionHistoryResponse,
  IUpsertFarmLandCommentParams,
} from 'interfaces';
import { requestServices } from 'services';

const { projectManagerClient } = requestServices;

const getDiscussionHistory = async (
  params: IGetDiscussionHistoryParams
): Promise<IGetDiscussionHistoryResponse> => {
  return await projectManagerClient
    .get('/tekbone/api/v1/farm-land/comments', {
      params,
    })
    .then(getData);
};

const commentFarmLand = async (
  params: IUpsertFarmLandCommentParams
): Promise<IBaseApiResponse> => {
  return projectManagerClient.post(
    '/tekbone/api/v1/farm-land/comments',
    params
  );
};

export default {
  getDiscussionHistory,
  commentFarmLand,
};
