import {
  IApiResponse,
  IGetNotificationsParams,
  IGetNotificationData,
  IRegisterTokenBody,
  IUnregisterTokenBody,
  IMarkReadBody,
  IMarkReadAllBody,
  IUnreadCountParams,
} from 'interfaces';
import { requestServices } from 'services';

const { stnServiceClient } = requestServices;

const getNotifications = (
  params?: IGetNotificationsParams
): Promise<IApiResponse<IGetNotificationData>> => {
  return stnServiceClient.get('/api/notifications', {
    params,
  });
};

const registerToken = (body: IRegisterTokenBody): Promise<IApiResponse<{}>> => {
  return stnServiceClient.post('/api/notification/register-token', body);
};
const unregisterToken = (
  body: IUnregisterTokenBody
): Promise<IApiResponse<{}>> => {
  return stnServiceClient.post('/api/notification/unregister-token', body);
};

const markAsRead = (body: IMarkReadBody): Promise<IApiResponse<{}>> => {
  return stnServiceClient.put(`/api/notification/${body.msgId}/mark-read`, {
    token: body.token,
  });
};

const markReadAll = (body: IMarkReadAllBody): Promise<IApiResponse<{}>> => {
  return stnServiceClient.put(`/api/notification/mark-read-all`, body);
};

const countUnRead = (
  params: IUnreadCountParams
): Promise<IApiResponse<{ count: number }>> => {
  return stnServiceClient.get('/api/notification/count-unread', {
    params,
  });
};

export default {
  getNotifications,
  registerToken,
  unregisterToken,
  markAsRead,
  markReadAll,
  countUnRead,
};
