import { PlusOutlined } from '@ant-design/icons';
import { GetProp, Upload, UploadFile, UploadProps, notification } from 'antd';
import { RcFile } from 'antd/es/upload';
import { FSImage } from 'components/shared/FSImage';
import { IMAGE_FILE_TYPES } from 'constants/file';
import { fileHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import commonHooks from 'hooks/common';
import uploadHooks from 'hooks/upload';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
const IMAGE_UPLOAD_THUMBNAIL_SIZE = 84;

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

const getThumbnailUrl = (url: string) =>
  `${url}=s${IMAGE_UPLOAD_THUMBNAIL_SIZE}-c`;

interface ImageUploadProps extends Omit<UploadProps, 'onChange' | 'value'> {
  value?: string[];
  onChange?: (urls: string[]) => void;
  onUploadStateChange?: (isUploading: boolean) => void;
  maxSize?: number;
  isPrivate?: boolean;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  value = [],
  onChange,
  onUploadStateChange,
  maxSize,
  isPrivate,
  ...uploadProps
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>(
    value.map(
      url =>
        ({
          url,
          status: 'done',
          thumbUrl: getThumbnailUrl(url),
        } as UploadFile)
    )
  );
  const { uploadFile, uploading } = uploadHooks.useUploadFile();
  const prevValue = commonHooks.usePrevious(value);

  useEffect(() => {
    onUploadStateChange?.(uploading);
  }, [uploading]);

  useEffect(() => {
    if (isEqual(value, prevValue)) return;

    const newValueList = value.map(
      url =>
        ({ url, thumbUrl: getThumbnailUrl(url), status: 'done' } as UploadFile)
    );
    if (!isEqual(newValueList, fileList)) {
      setFileList(newValueList);
    }
  }, [value]);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const uploadImage = async ({ file }: any) => {
    const uploadingFile = { ...file, status: 'uploading' };
    let newValue = [...value];
    const isMaxCountReached =
      uploadProps.maxCount && fileList.length >= uploadProps.maxCount;

    // If max count is reached, remove the first file from the list
    setFileList(prevFileList => [
      ...(isMaxCountReached ? prevFileList.slice(1) : prevFileList),
      uploadingFile,
    ]);

    const convertedFile = await fileHelpers.convertImageFile(file);
    const url = await uploadFile(convertedFile, {
      type: 'image',
      isPrivate,
    });

    if (isMaxCountReached) {
      newValue = newValue.slice(1);
      onChange?.(newValue);
    }

    if (url) {
      const uploadedFile = {
        ...uploadingFile,
        status: 'done',
        url,
        thumbUrl: getThumbnailUrl(url),
      };
      // Replace the uploading file with the uploaded file
      setFileList(prevFileList => [...prevFileList.slice(0, -1), uploadedFile]);
      onChange?.([...newValue, url]);
    } else {
      setFileList(fileList.slice(0, -1));
    }
  };

  const onRemove = (deletedFile: any) => {
    setFileList(fileList.filter(file => file.url !== deletedFile.url));
    onChange?.(value.filter(url => url !== deletedFile.url));
  };

  const validateFile = (file: RcFile) => {
    const result = fileHelpers.validateFileTypeAndFileSize(
      file,
      IMAGE_FILE_TYPES,
      maxSize
    );

    if (!result.status) {
      notification.error({
        message: result.message,
      });
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  return (
    <>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        accept={IMAGE_FILE_TYPES.map(type => `.${type}`).join(',')}
        customRequest={uploadImage}
        onRemove={onRemove}
        beforeUpload={validateFile}
        itemRender={(node, file) =>
          file.status === 'done' && isPrivate ? (
            <FSImage src={file.url} width="100%" height="100%" />
          ) : (
            node
          )
        }
        {...uploadProps}
      >
        <button
          style={{ border: 0, background: 'none' }}
          type="button"
          className="clickable"
        >
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>{t('Upload')}</div>
        </button>
      </Upload>
      {previewImage && (
        <FSImage
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: visible => setPreviewOpen(visible),
            afterOpenChange: visible => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </>
  );
};

export { ImageUpload };
