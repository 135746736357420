import { Spin } from 'antd';
import AppContainer from 'containers/AppLayout/AppContainer';
import React from 'react';
import { useFpicContext } from '../context';
import Header from '../shared/Header';
import './Container.scss';
import Provider from './Provider';
import { t } from 'helpers/i18n';

export interface ContainerProps {
  title?: React.ReactNode;
  disabledProject?: boolean;
  disabledTemplate?: boolean;
  showSelectTemplate?: boolean;
  children?: React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({
  title,
  disabledProject,
  disabledTemplate,
  showSelectTemplate,
  children,
}) => {
  const TemplateCheckingWrapper: React.FC<any> = ({ children: child }) => {
    const { templates } = useFpicContext();

    return templates === undefined ? (
      <Spin className="w-100" />
    ) : templates.length ? (
      child
    ) : (
      <>{t('ProjectHasNoTemplate')}</>
    );
  };

  return (
    <Provider>
      <AppContainer
        title={''}
        head={
          <Header
            title={title}
            disabledProject={disabledProject}
            disabledTemplate={disabledTemplate}
            showSelectTemplate={showSelectTemplate}
          />
        }
        className={'fpic-container-body'}
      >
        <TemplateCheckingWrapper> {children}</TemplateCheckingWrapper>
      </AppContainer>
    </Provider>
  );
};

export default Container;
