/** Third party libs **/
import React from 'react';

/** Local libs **/
import { FarmerEnrolment as FarmerEnrolmentPage } from 'components/FarmerManagement/FarmerEnrolment';
/** Styles **/

/** Interfaces, enum... **/

/** Variables **/

/** ------------------------- **/
const FarmerEnrolment: React.FC = () => {
  /** States **/

  /** Hooks **/

  /** Variables **/

  /** Functions, Events, Actions... **/

  /** Effects **/

  /** Elements **/
  return <FarmerEnrolmentPage />;
};

export default FarmerEnrolment;
export { FarmerEnrolment };
