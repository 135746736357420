export const HIDE_ERROR_URLS = [
  '/api/v1/basemap',
  '/api/v1/user-appointment',
  '/api/v1/projects',
  '/tekbone/api/v1/get-basemap-labels',
  '/tekbone/api/v1/get-basemap-polygons',
  '/tekbone/api/v1/search-polygons',
  '/tekbone/api/v1/cremas',
  { url: '/tekbone/api/v1/cremas/crmc-member', method: 'post' },
  { url: '/tekbone/api/v1/cremas/crmc-member', method: 'delete' },
];

const TICKET_DOMAIN = 'https://aja-feedback.karbonmap.com';

export default {
  HIDE_ERROR_URLS,
  TICKET_DOMAIN,
};
