import { Spin } from 'antd';

const MapLoading: React.FC<{ loading: boolean }> = ({ loading }) => {
  return loading ? (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
      }}
    >
      <Spin />
    </div>
  ) : null;
};

export { MapLoading };
