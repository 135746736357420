import { Space, Typography, Button } from 'antd';
import { COLOR } from 'constants/styles/color';
import { SUBMISSION_STATUS } from 'constants/submission';
import { t } from 'helpers/i18n';
import { IPlot } from 'interfaces';
import { Link } from 'react-router-dom';
import { compassDegreeTo8thDirection } from 'helpers/compass';
import { convertDateFormat } from 'helpers/common';
import { commonConstants } from 'constants/index';
import { useFlagsupContext } from 'contexts';

const { IS_AJA } = commonConstants;
interface IPlotInfoBox {
  plot: IPlot;
  polygonId?: number;
  hasData: boolean;
}

const PROJECT_TITLE_COLOR = '#588651';

const PlotCustomInfoBox = ({ plot, polygonId, hasData }: IPlotInfoBox) => {
  const { featureFlagsData } = useFlagsupContext();
  const isCAR2356Enabled = featureFlagsData.CAR_2356?.enabled;
  const { plotName, plotId } = plot;

  const infoBox = [
    {
      label: t('CreatedDateTime'),
      data: convertDateFormat(plot.createdAt),
      testId: 'info-created-datetime',
    },
    {
      label: t('CenterLocation'),
      data: `Lat: ${plot.latitude}, Long: ${plot.longitude}`,
      testId: 'info-center-location',
    },

    {
      label: t('GPSAccuracy'),
      data: plot.accuracy ? `${plot.accuracy}m` : t('Unspecified'),
      testId: 'info-gps-accuracy',
    },
    {
      label: t('GPSCompass'),
      data: plot.compass
        ? `${plot.compass}° ${compassDegreeTo8thDirection(plot.compass)}`
        : t('Unspecified'),
      testId: 'info-gps-compass',
    },
    {
      label: t('TotalSelectedSubmission'),
      data: plot.selectedSubmissions,
      testId: 'info-selected-submission',
    },
  ];

  return (
    <Space direction="vertical">
      <Typography.Text
        strong
        style={{ color: PROJECT_TITLE_COLOR }}
        ellipsis
        data-testid="plot-name"
      >
        {`${t('Plot')} ${plotName || plotId}`}
      </Typography.Text>
      {infoBox.map((info, id) => (
        <Space key={id}>
          <Typography.Text strong data-testid={`${info.testId}-label`}>
            {info.label}
            {' : '}
          </Typography.Text>
          <Typography.Text data-testid={`${info.testId}-value`}>
            {info.data}
          </Typography.Text>
        </Space>
      ))}
      {hasData && !IS_AJA && (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Link
            to={
              isCAR2356Enabled
                ? `/submissions?approvedStatus=${SUBMISSION_STATUS.selected.toUpperCase()}&plotName=${plotName}&plotId=${plotId}`
                : `/submissions?submissionStatus=${SUBMISSION_STATUS.selected}&plotId=${plot.plotId}&polygonId=${polygonId}`
            }
          >
            <Button
              type="text"
              style={{ color: COLOR.PRIMARY }}
              data-testid="plot-button"
            >
              {t('Detail')}
            </Button>
          </Link>
        </div>
      )}
    </Space>
  );
};

export default PlotCustomInfoBox;
