import AppContainer from 'containers/AppLayout/AppContainer';
import { localizationHelpers } from 'helpers';
import { commonHooks } from 'hooks';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

const { getCurrentLanguage } = localizationHelpers;

export interface IFrameContainerProps extends RouteComponentProps {
  iFrameSrc: string;
  localization?: string;
}
const { useIam } = commonHooks;

const IFrameContainer: React.FC<IFrameContainerProps> = props => {
  const { iFrameSrc, localization } = props;
  const { getProjectInfo } = useIam();
  const { clientId, oauthDomain } = getProjectInfo();

  /**
   * Create this object to pass values from container to iframe (if needed)
   * In the iframe you can call: JSON.parse(window.name) to get its values
   * Eg: pass the localization to sync the language between container and iframe
   */
  const iframeNameObj = {
    localization: localization || getCurrentLanguage(),
    parentOrigin: window.location.origin,
    clientId: clientId,
    oauthDomain: oauthDomain,
  };

  return (
    <AppContainer title={null} style={{ height: '100%' }}>
      <iframe
        name={JSON.stringify(iframeNameObj)}
        src={iFrameSrc}
        title="iframe"
        style={{ width: '100%', height: '100%', border: 'none' }}
        // Add permission to allow iframe to write to clipboard
        // Follow this: https://stackoverflow.com/questions/61401384/can-text-within-an-iframe-be-copied-to-clipboard
        allow="clipboard-write"
      />
    </AppContainer>
  );
};

export default IFrameContainer;
