import { Button, Col, Row, Space, Table, Tooltip, Typography } from 'antd';
import ContentBlock from 'components/shared/ContentBlock';
import { t } from 'helpers/i18n';
import { useState } from 'react';
import { CalculatorOutlined } from '@ant-design/icons';
import { IPaginationOffsetParams, IProjectDetails } from 'interfaces';
import { IPolygon } from 'interfaces/polygon';
import { ColumnsType } from 'antd/lib/table';
import {
  DEFAULT_OFFSET,
  DEFAULT_PAGE_SIZE,
  PAGINATION,
} from 'constants/pagination';
import trackProjectDetail from 'helpers/tracker/trackProjectDetail';
import { convertNumberFormat } from 'helpers/common';
import { paginationHelpers } from 'helpers';
import { projectHooks } from 'hooks';

interface ProjectDetailTableProps {
  project: IProjectDetails;
}

const {
  trackProjectDetailAction,
  PROJECT_DETAIL_TRACKING_DATA,
} = trackProjectDetail;
const { getPageFromLimitOffset, getOffset } = paginationHelpers;
const { useProjectPolygons } = projectHooks;

const ProjectDetailTable = (props: ProjectDetailTableProps) => {
  const [params, setParams] = useState<IPaginationOffsetParams>({
    limit: DEFAULT_PAGE_SIZE,
    offset: DEFAULT_OFFSET,
  });

  const { loading, pagination, polygons } = useProjectPolygons(
    props.project.projectId,
    params
  );

  const columns: ColumnsType<IPolygon> = [
    {
      title: t('PolygonName'),
      dataIndex: 'polygonName',
      width: 220,
      render: value => value || '---',
      ellipsis: true,
    },
    {
      title: `${t('AreaHa')}`,
      dataIndex: 'area',
      width: 150,
      render: (_, record) => convertNumberFormat(record.area),
    },
    {
      title: t('SamplingPlots'),
      width: 180,
      render: (_, record) => (
        <p data-testid="sampling-plots">
          {convertNumberFormat(record.plotsCount || 0)}
          {' / '}
          {convertNumberFormat(record.samplingPlots || 0)}
        </p>
      ),
    },
    {
      title: `${t('SelectedSubmissions')}`,
      dataIndex: 'selectedSubmissions',
      width: 180,
      render: value => convertNumberFormat(value),
    },
    {
      title: `${t('WaitingSubmissions')}`,
      dataIndex: 'waitingSubmissions',
      width: 180,
      render: value => convertNumberFormat(value),
    },
    {
      title: `${t('AverageCarbon')}`,
      dataIndex: 'avgCarbon',
      render: (_, record: IPolygon) => (
        <p>
          {convertNumberFormat(
            record.totalCarbonPerYear / (record.area * 1000)
          )}
        </p>
      ),
    },
    {
      title: `${t('TotalCarbonPerYear')}`,
      dataIndex: 'totalCarbonPerYear',
      render: (_, record: IPolygon) => (
        <p>{convertNumberFormat(record.totalCarbonPerYear / 1000)}</p>
      ),
    },
    {
      title: `${t('StandardDeviation')}`,
      dataIndex: 'totalCarbonStandardDeviation',
      render: (_, record: IPolygon) => (
        <p>{convertNumberFormat(record.totalCarbonStandardDeviation / 1000)}</p>
      ),
    },
  ];
  return (
    <ContentBlock className="mb-base">
      <Row align="middle">
        <Col lg={14} md={24} className="mb-base">
          <Space align="center">
            <Typography.Title className="mb-0" level={4}>
              {t('CalculateCarbonStockProject')}
            </Typography.Title>
            <Tooltip
              onOpenChange={(open: boolean) => {
                if (open)
                  trackProjectDetailAction(
                    PROJECT_DETAIL_TRACKING_DATA.formula,
                    PROJECT_DETAIL_TRACKING_DATA.formula.contentName,
                    true
                  );
              }}
              color={'white'}
              placement="rightTop"
              title={
                <div style={{ maxWidth: 500 }}>
                  <Typography.Title className="mb-0" level={5}>
                    {t('FormulaExplanation')}
                  </Typography.Title>
                  <Typography.Paragraph>
                    {props.project.formularExplanation}
                  </Typography.Paragraph>
                </div>
              }
            >
              <Button
                type="link"
                style={{
                  fontSize: '1rem',
                  padding: '0 0 0 16px',
                }}
              >
                <CalculatorOutlined
                  style={{
                    fontSize: '150%',
                  }}
                />
                <span>{t('FormulaExplanation')}</span>
              </Button>
            </Tooltip>
          </Space>
        </Col>
        <Col lg={10} md={24} className="mb-base text-right">
          <Space align="center" style={{ gap: '3.5rem' }}>
            <Typography.Title className="mb-0" level={4}>
              {t('TotalPerYear')}
            </Typography.Title>
            <Typography.Title className="mb-0" level={4}>
              {convertNumberFormat(props.project.totalCarbonPerYear / 1000)}
            </Typography.Title>
            <Typography.Title className="mb-0" level={4}>
              +/-{' '}
              {convertNumberFormat(
                props.project.totalCarbonStandardDeviation / 1000
              )}
            </Typography.Title>
          </Space>
        </Col>
      </Row>
      <Table
        bordered
        columns={columns}
        scroll={{ x: 1400 }}
        sticky={{ offsetHeader: 0 }}
        loading={loading}
        dataSource={polygons.filter(polygon => {
          return polygon.polygonId !== -1;
        })}
        pagination={{
          total: pagination?.total || 0,
          defaultPageSize: PAGINATION.PAGE_SIZE,
          pageSizeOptions: PAGINATION.PAGE_SIZE_OPTIONS,
          pageSize: params.limit,
          showSizeChanger: true,
          current: getPageFromLimitOffset(params.offset, params.limit),
          onChange: (page, pageSize) => {
            const $offset = getOffset(page, pageSize);
            if ($offset !== params.offset) {
              setParams(prev => ({ ...prev, offset: $offset }));
            }
          },
          onShowSizeChange: (_, pageSizeSelect) => {
            setParams({
              offset: DEFAULT_OFFSET,
              limit: pageSizeSelect,
            });
          },
        }}
      />
    </ContentBlock>
  );
};

export default ProjectDetailTable;
