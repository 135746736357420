import { MIN_ZOOM_SHOW_PLOTS } from 'constants/map';
import { IPlot } from 'interfaces';
import React from 'react';
import { useMap } from 'react-leaflet';
import ProjectCustomMarker from './ProjectCustomMarker';
import { useFlagsupContext } from 'contexts';

interface IProjectPlots {
  plots: IPlot[];
}

const ProjectPlots = (props: IProjectPlots) => {
  const map = useMap();
  const { featureFlagsData } = useFlagsupContext();
  const isCAR418Enabled = featureFlagsData.CAR_418?.enabled;
  const isShowPlots = isCAR418Enabled
    ? true
    : map.getZoom() && map.getZoom() >= MIN_ZOOM_SHOW_PLOTS;
  return (
    <>
      {isShowPlots &&
        props.plots.map(plot => (
          <ProjectCustomMarker
            plot={plot}
            plotId={plot.plotId}
            polygonId={plot.polygonId}
            hasData={plot.hasData}
            key={plot.plotId}
          />
        ))}
    </>
  );
};

export default ProjectPlots;
