/** Third party libs * */
import React, { useEffect, useState } from 'react';
import { Button, Empty } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

/** Local libs * */
import { IObjectValue } from 'interfaces';

/** Components * */
import { BaseItemProps, BaseItem } from '../BaseItem';
import OCRAndTranscribe, { OCRAndTranscribeItem } from '../../OCRAndTranscribe';
import { Upload as MyUpload, UploadFile } from 'components/shared/Upload';
import { FSImage } from 'components/shared/FSImage';

/** Styles * */
import './ImageFile.scss';
import { v4 } from 'uuid';

/** Interfaces, enum... * */
interface IImage extends Partial<IObjectValue> {
  id: string;
  url: string;
}
interface ImageFileProps extends BaseItemProps {
  transformUrl?: (url: string) => string;
}

/** Variables * */
const FILE_ACCEPT = '.jpg,.png,.jpeg,.HEIC';
const MAX_SIZE = 100;

/** ------------------------- * */
const ImageFile: React.FC<ImageFileProps> = ({
  item,
  disabled,
  form,
  transformUrl,
  ...props
}) => {
  /** States * */
  const [ocrOpen, setOcrOpen] = useState(false);
  const [images, setImages] = useState<IImage[]>([]);
  const [imageSelected, setImageSelected] = useState<
    OCRAndTranscribeItem | undefined
  >();

  /** Hooks * */

  /** Variables * */

  const onOpenOCR = (item: IImage) => {
    setImageSelected({
      id: item.analyzedDataId,
      url: item.url,
      isAnalyzed: item.isAnalyzed,
    });
    setOcrOpen(true);
  };

  /** Effects * */

  useEffect(() => {
    if (item.objectValues && item.objectValues.length) {
      if (item.fieldName) {
        form.setFieldValue(
          item.fieldName,
          JSON.stringify(item.objectValues.map(item => item.analyzedDataUrl))
        );
      }

      setImages(
        item.objectValues.map(item => ({
          ...item,
          id: v4(),
          url: item.analyzedDataUrl.trim(),
        }))
      );
    } else {
      if (item.fieldName) {
        form.setFieldValue(item.fieldName, item.value);
      }

      if (item.value) {
        try {
          const values = JSON.parse(item.value);
          setImages(
            (values || [])
              .map((value: string) => ({
                id: v4(),
                url: value.trim(),
              }))
              .filter((item: any) => !item.url.startsWith('/data/user/'))
          );
        } catch (e) {}
      }
    }
  }, [item.value, item.objectValues]);

  /** Functions, Events, Actions... * */
  const updateDataForm = (images: IImage[]) => {
    if (item.fieldName)
      form.setFieldValue(
        item.fieldName,
        JSON.stringify(images.map(item => item.url))
      );
  };

  const onDeleteImage = (id: string) => {
    const values = [...images].filter(item => item.id !== id);
    setImages(values);
    updateDataForm(values);
  };

  const onUploadSuccess = (files: UploadFile[]) => {
    const list: IImage[] = files.reduce((list, file) => {
      if (file.fileUrl) {
        list.push({
          id: file.uid,
          url: file.fileUrl,
        });
      }

      return list;
    }, [] as IImage[]);
    setImages(imgs => {
      return [...imgs, ...list];
    });

    updateDataForm([...images, ...list]);
  };

  /** Elements * */
  return (
    <BaseItem item={item} disabled={disabled} form={form} {...props}>
      <span>
        {!disabled && (
          <MyUpload
            accept={FILE_ACCEPT}
            maxSize={MAX_SIZE}
            disabled={disabled}
            onSuccess={onUploadSuccess}
          />
        )}

        {images.length > 0 ? (
          <div
            style={{
              marginTop: 12,
              display: 'flex',
              overflowX: 'auto',
              paddingBottom: 34,
            }}
          >
            {images.map((image, index) => (
              <div key={index} className={'image-item-wrap'}>
                <FSImage
                  src={transformUrl ? transformUrl(image.url) : image.url}
                  width={100}
                  className={'image-item'}
                  alt={image.url}
                  onClick={() => onOpenOCR(image)}
                  preview={{ src: image.url }}
                  resizePreview={{ size: 800 }}
                />
                {!disabled && (
                  <Button
                    size={'small'}
                    type={'primary'}
                    danger={true}
                    ghost={true}
                    icon={<DeleteOutlined />}
                    className={'image-item-delete'}
                    onClick={() => onDeleteImage(image.id)}
                  />
                )}
              </div>
            ))}
          </div>
        ) : (
          <Empty />
        )}
        <OCRAndTranscribe
          open={ocrOpen}
          type={'ocr'}
          formSubmission={props.formSubmission}
          mode={props.mode}
          setOpen={setOcrOpen}
          item={imageSelected}
        />
      </span>
    </BaseItem>
  );
};

export default ImageFile;
