import { Select, SelectProps } from 'antd';
import { PAGING_OFFSET_ALL } from 'constants/pagination';
import { useProjectContext } from 'contexts';
import { ICommunity, IGetCremaCommunitiesParams } from 'interfaces';
import { useEffect, useState } from 'react';
import { communityServices } from 'services';

const { getListCremaCommunities } = communityServices;

interface CommunitySelectProps extends SelectProps {
  cremaId?: number;
}

const CommunitySelect: React.FC<CommunitySelectProps> = ({
  cremaId,
  ...selectProps
}) => {
  const [communities, setCommunities] = useState<ICommunity[]>([]);
  const [loading, setLoading] = useState(false);
  const { projectId } = useProjectContext();

  const getData = async (params?: Partial<IGetCremaCommunitiesParams>) => {
    try {
      setLoading(true);
      const data = await getListCremaCommunities({
        ...params,
        projectId,
        ...PAGING_OFFSET_ALL,
      });
      setCommunities(data.communities);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData({ cremaId });
  }, [cremaId]);

  return (
    <Select
      allowClear
      showSearch
      loading={loading}
      fieldNames={{ label: 'communityName', value: 'communityId' }}
      options={communities}
      filterOption={(keyword, option) => {
        return option?.communityName
          .toLowerCase()
          .includes(keyword.toLowerCase());
      }}
      {...selectProps}
    />
  );
};

export { CommunitySelect };
