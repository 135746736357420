import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  List,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import { t } from 'helpers/i18n';
import React, { useState } from 'react';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  DoubleLeftOutlined,
  LoadingOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons';
import { Store } from 'antd/es/form/interface';
import { dateTimeHelpers } from 'helpers';
import { SortDirection } from 'interfaces';
import './Comment.scss';
import ContentBlock from '../ContentBlock';

interface CommentProps {
  disabled?: boolean;
  comments: {
    id: number;
    createdAt: number;
    commenter: string;
    content: string;
  }[];
  fetchDataloading: boolean;
  canLoadMore: boolean;
  sortDirection?: SortDirection;
  onSort?: (direction: SortDirection) => void;
  onRefreshData?: () => Promise<void> | void;
  onLoadMore: () => Promise<void> | void;
  onSendComment: (content: string) => Promise<void>;
}

const { Title } = Typography;

const Comment: React.FC<CommentProps> = props => {
  const [form] = Form.useForm();
  const [sendFeedbackLoading, setSendFeedbackLoading] = useState(false);
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');

  const {
    disabled,
    comments,
    fetchDataloading,
    canLoadMore,
    onSort,
    onRefreshData,
    onLoadMore,
    onSendComment,
  } = props;

  const onFinish = async ({ content }: Store) => {
    try {
      setSendFeedbackLoading(true);
      await onSendComment(content);
      form.resetFields();
      onRefreshData?.();
    } finally {
      setSendFeedbackLoading(false);
    }
  };

  const onChangeSortDirection = () => {
    const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newSortDirection);
    onSort?.(newSortDirection);
  };

  return (
    <div className="comment-wrap d-flex flex-column h-100">
      {!disabled && (
        <ContentBlock className="mb-half" styles={{ body: { padding: 12 } }}>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item name="content" className="mb-base">
              <Input.TextArea
                autoSize={{ minRows: 5, maxRows: 5 }}
                placeholder={t('AddYourFeedback')}
              />
            </Form.Item>
            <Form.Item className="mb-0" dependencies={['content']}>
              {({ getFieldValue }) => (
                <Space align="center" className="w-100 justify-content-center">
                  <Button onClick={() => form.resetFields()} size="small">
                    {t('Cancel')}
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={sendFeedbackLoading}
                    disabled={!!!getFieldValue('content')}
                    size="small"
                  >
                    {t('SendFeedback')}
                  </Button>
                </Space>
              )}
            </Form.Item>
          </Form>
        </ContentBlock>
      )}
      <div className="discussion-list">
        <Flex
          className="discussion-list-header"
          style={{ padding: '8px 0' }}
          justify="space-between"
          align="baseline"
        >
          <Title level={5} className="mb-0 pl-base" style={{ color: '#fff' }}>
            {t('Discussion')}
          </Title>
          {!!onSort && (
            <Button
              type="text"
              icon={
                sortDirection === 'asc' ? (
                  <SortAscendingOutlined />
                ) : (
                  <SortDescendingOutlined />
                )
              }
              onClick={onChangeSortDirection}
            />
          )}
        </Flex>
        <List
          itemLayout="horizontal"
          dataSource={comments}
          loading={fetchDataloading}
          className="discussion-item"
          renderItem={comment => (
            <>
              <Row style={{ background: '#F5F5F5' }} className="p-half">
                <Col span={12}>
                  <Typography.Text>
                    {dateTimeHelpers.formatUnixTimestamp(comment.createdAt)}
                  </Typography.Text>
                </Col>
                <Col span={12} className="d-flex justify-content-end">
                  {comment.commenter}
                </Col>
              </Row>
              <Divider className="m-0" />
              <Row className="p-half comment-detail">
                <Typography.Paragraph
                  className="pt-half"
                  ellipsis={{
                    rows: 5,
                    expandable: 'collapsible',
                    symbol: expanded =>
                      expanded ? <CaretUpOutlined /> : <CaretDownOutlined />,
                  }}
                >
                  {comment.content}
                </Typography.Paragraph>
              </Row>
            </>
          )}
          loadMore={
            canLoadMore && (
              <Row justify="center" className="load-more">
                <Button type="link" onClick={onLoadMore}>
                  {t('LoadMoreFeedback')}
                  {fetchDataloading ? (
                    <Spin
                      spinning={fetchDataloading}
                      indicator={<LoadingOutlined />}
                      size="small"
                      className="ml-half"
                    />
                  ) : (
                    <DoubleLeftOutlined rotate={-90} />
                  )}
                </Button>
              </Row>
            )
          }
        />
      </div>
    </div>
  );
};

export { Comment };
