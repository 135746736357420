import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Button, Modal, Space, Table, Typography, notification } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import classNames from 'classnames';
import { buttonStyle } from 'components/shared/Button';
import { useDisclosure } from 'components/shared/Modal/useDisclosure';
import { ID_DEFAULT } from 'constants/common';
import { commonConstants } from 'constants/index';
import { DEFAULT_OFFSET, PAGINATION } from 'constants/pagination';
import { COLOR } from 'constants/styles/color';
import { paginationHelpers } from 'helpers';
import { convertNumberFormat } from 'helpers/common';
import { t } from 'helpers/i18n';
import { getDefaultGeneratePlotsValue } from 'helpers/plots';
import trackProjectMap from 'helpers/tracker/trackProjectMap';
import { userHooks } from 'hooks';
import { IPaginationOffsetParams } from 'interfaces';
import { IPolygon } from 'interfaces/polygon';
import { useEffect, useState } from 'react';
import { polygonServices, projectServices } from 'services';
import ProjectPolygonLeafletMap from './Leaflet/ProjectPolygonLeafletMap';
import './PolygonTable.css';
import ProjectPlotMeasureField from './ProjectPlotMeasureField';
import ProjectPolygonRejectModal from './ProjectPolygonRejectModal';
import { CurrentSelectedPolygon } from './context';

interface ProjectPolygonTableProps {
  projectId: number;
  projectTitle: string;
  shouldRerender: number; // nearly the same as key, parent component use this to trigger rerender
}
type LoadingType = 'reject' | number;

const { useUserPermissions } = userHooks;

const { IS_AJA } = commonConstants;

const { getPageFromLimitOffset, getOffset } = paginationHelpers;
const { trackProjectMapAction, PROJECT_MAP_TRACKING_DATA } = trackProjectMap;

const ProjectPolygonTable: React.FC<ProjectPolygonTableProps> = ({
  projectId,
  shouldRerender,
}) => {
  const { canApproveProject } = useUserPermissions();
  const [api, contextHolder] = notification.useNotification();
  const [deletePolygon, setDeletePolygon] = useState<IPolygon>();
  const [currentPolygonId, setCurrentPolygonId] = useState<number>(-1);
  const [rejectPolygonOpen, setRejectPolygonOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<LoadingType | undefined>(undefined);
  const [isValidating, setIsValidating] = useState(false);
  const [polygons, setPolygons] = useState<IPolygon[]>([]);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState<IPaginationOffsetParams>({
    limit: PAGINATION.PAGE_SIZE,
    offset: DEFAULT_OFFSET,
  });
  const { limit, offset } = params;

  const refresh = () => {
    setParams({ ...params });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsValidating(true);
        const {
          polygons,
          pagination,
        } = await projectServices.getProjectPolygons(projectId, params);
        setPolygons(polygons);
        if (pagination.total !== total) setTotal(pagination.total);
      } finally {
        setIsValidating(false);
      }
    };

    fetchData();
  }, [params]);

  useEffect(() => {
    setCurrentPolygonId(ID_DEFAULT);
    setParams(prev => ({ ...prev, offset: DEFAULT_OFFSET }));
  }, [shouldRerender]);

  const deletePolygonModal = useDisclosure();

  const onDelete = async (polygonId: number) => {
    try {
      const res = await polygonServices.deletePolygon(polygonId);
      if (res?.code === 0) {
        api.success({
          message: t('DeletePolygonSuccessfully'),
          duration: 2,
        });
        deletePolygonModal.onClose();

        refresh();
      } else throw new Error();
    } catch (_err) {
      // already notified
    }
  };

  const polygonAction = async (
    type: LoadingType,
    polygonId: number,
    state: boolean,
    reason?: string
  ) => {
    setLoading(type);
    try {
      const result = await polygonServices.selectPolygon({
        projectId,
        polygonId,
        isSelected: state,
        rejectReason: reason,
      });
      if (result?.code === 0 && type === polygonId) {
        api.success({
          message: t('LandHasBeenApprovedSuccessfully'),
          duration: 2,
        });
      }
    } finally {
      setLoading(undefined);
      refresh();
    }
  };

  const onApprove = (polygonId: number) => {
    trackProjectMapAction(PROJECT_MAP_TRACKING_DATA.approvePolygon);
    polygonAction(polygonId, polygonId, true).then();
  };

  const onReject = (polygonId: number) => {
    setRejectPolygonOpen(true);
    setCurrentPolygonId(polygonId);
    trackProjectMapAction(PROJECT_MAP_TRACKING_DATA.rejectPolygon);
  };

  const onRejected = (reason: string) => {
    polygonAction('reject', currentPolygonId, false, reason).then();
  };

  const getColumns = () => {
    const cols: ColumnsType<IPolygon> = [
      {
        title: t('PolygonId'),
        dataIndex: 'polygonId',
        render: text => <p>{text}</p>,
        width: 80,
      },
      {
        title: `${t('Area(Ha)')}`,
        dataIndex: 'area',
        render: (_, record) => <p>{convertNumberFormat(record.area)}</p>,
        width: 120,
      },
      {
        title: `${t('PlotsWithData')}`,
        dataIndex: 'plotsCount',
        render: (_, record) => <p>{record.plotsCount}</p>,
        width: 100,
      },
      {
        title: `${t('NeedToBeMeasured')}`,
        width: 150,
        render: (_, record) => (
          <ProjectPlotMeasureField
            suggestGeneratePlots={getDefaultGeneratePlotsValue(
              record.plotsCount || 0,
              record.area
            )}
            mutatePolygon={refresh}
            samplingPlots={record.samplingPlots}
            polygonId={record.polygonId}
            plotsWithData={record.plotsCount || 0}
          />
        ),
        align: 'center',
      },
    ];

    if (!IS_AJA || canApproveProject)
      cols.push({
        title: t('Action'),
        key: 'Action',
        align: 'center',
        width: 90,
        render: (_, record) => {
          if (record.isSelected === null)
            return (
              <Space>
                <Button
                  size={'small'}
                  type={'primary'}
                  ghost={true}
                  style={{
                    color: COLOR.PRIMARY,
                    borderColor: COLOR.PRIMARY,
                  }}
                  icon={<CheckOutlined />}
                  loading={loading === record.polygonId}
                  onClick={e => {
                    e.stopPropagation();
                    onApprove(record.polygonId);
                  }}
                />
                <Button
                  size={'small'}
                  type={'primary'}
                  ghost={true}
                  style={{
                    color: COLOR.ERROR,
                    borderColor: COLOR.ERROR,
                  }}
                  icon={<CloseOutlined />}
                  loading={loading === 'reject'}
                  onClick={e => {
                    e.stopPropagation();
                    onReject(record.polygonId);
                  }}
                />
                {record.isDeletable && (
                  <div
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <DeleteOutlined
                      onClick={() => {
                        trackProjectMapAction(
                          PROJECT_MAP_TRACKING_DATA.deletePolygon
                        );
                        setDeletePolygon(record);
                        deletePolygonModal.onOpen();
                      }}
                      style={{
                        color: COLOR.ERROR,
                        fontSize: '20px',
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                )}
              </Space>
            );

          return <></>;
        },
      });

    return cols;
  };

  return (
    <CurrentSelectedPolygon.Provider
      value={{ currentPolygonId, setCurrentPolygonId }}
    >
      <div>
        <ProjectPolygonLeafletMap
          polygons={polygons || []}
          // @TODO: remove this to prevent spam get base map API
          isPolygonLoading={isValidating}
          projectId={projectId}
        />
        {contextHolder}
        {total > 0 && (
          <Space align="baseline" style={{ marginBottom: '10px', gap: '10px' }}>
            <Typography.Title level={5}>
              {t('TotalPolygon')} : {total}
            </Typography.Title>
          </Space>
        )}
        <Table
          bordered
          columns={getColumns()}
          dataSource={polygons.filter(poly => {
            return poly.polygonId !== -1;
          })}
          loading={isValidating}
          scroll={{ y: 300 }}
          rowKey={'polygonId'}
          onRow={record => {
            return {
              onClick: () => {
                if (currentPolygonId === record.polygonId) {
                  setCurrentPolygonId(-1);
                } else {
                  setCurrentPolygonId(record.polygonId);
                }
              },
            };
          }}
          rowClassName={record =>
            classNames(
              { 'active-row': record.polygonId === currentPolygonId },
              'custom-row'
            )
          }
          pagination={{
            total,
            defaultPageSize: PAGINATION.PAGE_SIZE,
            pageSizeOptions: PAGINATION.PAGE_SIZE_OPTIONS,
            showSizeChanger: true,
            pageSize: limit,
            current: getPageFromLimitOffset(offset, limit),
            onChange: (page, pageSize) => {
              const $offset = getOffset(page, pageSize);
              if ($offset !== offset) {
                setParams(prev => ({ ...prev, offset: $offset }));
              }
            },
            onShowSizeChange: (_, pageSizeSelect) => {
              setParams({
                offset: DEFAULT_OFFSET,
                limit: pageSizeSelect,
              });
            },
          }}
        />
        <ProjectPolygonRejectModal
          open={rejectPolygonOpen}
          setOpen={setRejectPolygonOpen}
          onSubmit={onRejected}
        />
        {!deletePolygon || (
          <Modal
            open={deletePolygonModal.isOpen}
            onCancel={() => {
              deletePolygonModal.onClose();
              setDeletePolygon(undefined);
            }}
            title={t('DeletePolygon')}
            footer={
              <div style={{ textAlign: 'center' }}>
                <Button
                  key="back"
                  onClick={deletePolygonModal.onClose}
                  style={{
                    ...buttonStyle,
                    height: '100%',
                    width: '100px',
                    backgroundColor: COLOR.NEUTRAL,
                  }}
                >
                  {t('Cancel')}
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{
                    ...buttonStyle,
                    height: '100%',
                    width: '100px',
                    backgroundColor: COLOR.ERROR,
                  }}
                  onClick={() => onDelete(deletePolygon.polygonId)}
                >
                  {t('OK')}
                </Button>
              </div>
            }
          >
            <div style={{ textAlign: 'center', fontSize: '1rem' }}>
              <span style={{ fontWeight: '500', color: COLOR.ERROR }}>
                {`${t('DeletePolygon')}: `}
              </span>
              <span style={{ fontWeight: '700' }}>
                Polygon {deletePolygon.polygonId} ?
              </span>
            </div>
          </Modal>
        )}
      </div>
    </CurrentSelectedPolygon.Provider>
  );
};

export default ProjectPolygonTable;
