import { t } from 'helpers/i18n';
import { AllowLayersObject } from './interfaces';
import SMap from './MapLayers/images/SMap.png';
import RMap from './MapLayers/images/RMap.png';
import LMap from './MapLayers/images/LMap.png';
import CMap from './MapLayers/images/CMap.png';
import ForestLoss from './MapLayers/images/TreeLoss.jpg';
import IPCC from './MapLayers/images/IPCC.jpg';
import ARREligible from './MapLayers/images/ARREligible.jpg';
import Peatland from './MapLayers/images/LMap.png';
import { IPolygonTekbone } from 'interfaces';
import { ID_DEFAULT } from 'constants/common';

export const MAPS: AllowLayersObject = {
  Default: {
    name: t('Default'),
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    base: true,
    checked: true,
    icon: RMap,
    type: 'Default',
  },
  Satellite: {
    name: t('Satellite'),
    base: false,
    attribution:
      '&copy; <a href="Esri &mdash">Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community</a> contributors',
    url: 'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    icon: SMap,
    type: 'Satellite',
  },
  LandCover: {
    name: t('LandCover'),
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    base: true,
    checked: true,
    icon: LMap,
    type: 'LandCover',
  },
  CustomClassification: {
    name: t('CustomClassification'),
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    base: true,
    checked: true,
    icon: CMap,
    type: 'CustomClassification',
  },
  IPCC2006LandCover: {
    name: t('IPCC2006LandCoverMap'),
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    base: true,
    checked: true,
    icon: IPCC,
    type: 'IPCC2006LandCover',
  },
  ARREligible: {
    name: t('ARREligibleArea'),
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    base: true,
    checked: true,
    icon: ARREligible,
    type: 'ARREligible',
  },
  ForestLoss: {
    name: t('ForestLoss'),
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    base: true,
    checked: true,
    icon: ForestLoss,
    type: 'ForestLoss',
  },
  Peatland: {
    name: t('Peatland'),
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    base: true,
    checked: true,
    icon: Peatland,
    type: 'Peatland',
  },
};

export const ZOOM_OPTIONS = {
  MAX_NATIVE_ZOOM: 19,
  MAX_ZOOM: 30,
};

export const LOCATION_PEAKVIEW = {
  latitude: 21.019322132927282,
  longitude: 105.82364916801454,
};

export const DEFAULT_POLYGON: IPolygonTekbone = {
  polygonId: ID_DEFAULT,
  polygonMap: [
    {
      latitude: 68.346647,
      longitude: 24.787793,
    },
    {
      latitude: -34.394863,
      longitude: 20.041506,
    },
    {
      latitude: -42.590872,
      longitude: 175.64087,
    },
    {
      latitude: 65.564499,
      longitude: -173.592528,
    },
    {
      latitude: 0,
      longitude: 0,
    },
  ],
  polygonTypeId: 0,
  zIndex: 0,
  centerPoint: {
    latitude: undefined,
    longitude: undefined,
  },
  createdAt: 0,
  area: 0,
  eligibleArea: 0,
};

export const DEFAULT_CENTER_POINT: [number, number] = [8.4095, 115.1889];

export const DEFAULT_ZOOM = 3;

export type LegendPlacement = 'left' | 'right';

export const KM_IN_M = 1000;
