import { ROUTES } from 'constants/routes';
import request from 'services/apis/request';
import { ITreeSpeciesResponse } from 'interfaces/species';
import { useFetchWithCache } from './api';
import { VolunteerCacheStorage } from 'utils/cacheStorage';
import { CACHE_STORAGE_TTL } from 'constants/cacheStorage';

const { projectManagerClient } = request;

const TREE_SPECIES_CACHE_KEY = 'cache_tree_species';

const useTreeSpecies = () => {
  const { data } = useFetchWithCache<ITreeSpeciesResponse>(
    projectManagerClient,
    VolunteerCacheStorage,
    ROUTES.TREE_SPECIES.GET_ALL_TREE_SPECIES,
    {
      cacheKey: TREE_SPECIES_CACHE_KEY,
      TTL: CACHE_STORAGE_TTL,
      method: 'GET',
    }
  );
  return {
    treeSpeciesData: data ? data.data.species : [],
  };
};

export default { useTreeSpecies };
