import { createContext, useContext } from 'react';
import { IUserDetailsInfo } from 'teko-oauth2';
import { currentUser } from 'services/mocks/user';
import { ID_DEFAULT } from 'constants/common';
import { IUserProject } from 'interfaces';

export interface StoreContextType {
  currentUser: IUserDetailsInfo;
  firebaseToken: string;
}

export const StoreContext = createContext<StoreContextType>({
  currentUser,
  firebaseToken: '',
});

export const useStoreContext = () => useContext(StoreContext);

export interface ProjectContextType {
  projectId: number;
  setProjectId: (projectId: number) => void;
  currentProject?: IUserProject;
  setCurrentProject: (project: IUserProject) => void;
  projects: IUserProject[];
  getProjects: () => Promise<void>;
  gettingProjects: boolean;
}

export const ProjectContext = createContext<ProjectContextType>({
  projects: [],
  projectId: ID_DEFAULT,
  currentProject: undefined,
  gettingProjects: true,
  setCurrentProject: () => {},
  setProjectId: () => {},
  getProjects: () => {
    return Promise.resolve();
  },
});

export const useProjectContext = () => useContext(ProjectContext);

export * from './ConfigFlagsupProvider';
