import { IPolygon } from 'interfaces/polygon';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { MapContainer, useMap, useMapEvents } from 'react-leaflet';
import './style/leaflet.css';
import {
  CurrentPlots,
  CurrentSelectedPlot,
  CurrentSelectedPolygon,
} from '../context';
import { useOnClickOutside } from 'usehooks-ts';
import ProjectClusterLeaflet from './ProjectClusterLeaflet';
import { IPlot } from 'interfaces';
import { Spin } from 'antd';
import ProjectPlots from './ProjectPlots';
import { MIN_ZOOM_SHOW_PLOTS } from 'constants/map';
import { useAbortController } from 'hooks/axios';
import { MapLayers, MapPolygons, LayerType } from 'components/shared/Map';
import { projectServices } from 'services';
import { commonConstants } from 'constants/index';
import BaseMapPolygons from 'components/shared/Map/BaseMapPolygons';
import { mapHelpers } from 'helpers';

interface IProjectPolygonLeafletMap {
  polygons: IPolygon[];
  isPolygonLoading: boolean;
  projectId: number;
  className?: string;
}

const LeafletContainer = styled.div`
  height: 600px;
  width: 100%;
  margin-bottom: 20px;
  .leaflet-container {
    width: 100%;
    height: 600px;
  }
  .leaflet-div-icon {
    background: none;
    border: none;
  }
`;

const { IS_DEMO } = commonConstants;
const { getSearchBoxObjectFromMap, isLngInvalid } = mapHelpers;

interface ISetBoundsComponentProps {
  polygon: IPolygon[];
  mapRef: any;
  currentPolygonId: number;
  projectId: number;
}

const SetBoundsComponent: React.FC<ISetBoundsComponentProps> = ({
  polygon,
  mapRef,
  currentPolygonId,
  projectId,
}) => {
  const map = useMap();
  const { setCurrentPlotId } = useContext(CurrentSelectedPlot);

  const { cancelPreviousRequest, newAbortSignal } = useAbortController();
  const { setPlotsLoading, setPlots } = useContext(CurrentPlots);

  const onUpdatePlots = async () => {
    const mapCornerCoordinates = getSearchBoxObjectFromMap(map);
    const needToFetch = map.getZoom() && map.getZoom() >= MIN_ZOOM_SHOW_PLOTS;
    if (!needToFetch) {
      setPlots([]);
    } else if (
      mapCornerCoordinates &&
      !isLngInvalid(mapCornerCoordinates?.searchLongitudeMax) &&
      !isLngInvalid(mapCornerCoordinates?.searchLongitudeMin)
    ) {
      try {
        setPlotsLoading(true);
        const { plots } = await projectServices.getProjectPlotsByCoors(
          {
            projectId,
            ...mapCornerCoordinates,
          },
          { signal: newAbortSignal() }
        );
        setPlots(plots);
      } catch {
        // ignore cancelled error
      } finally {
        setPlotsLoading(false);
      }
    }
  };

  useMapEvents({
    zoomstart: () => {
      cancelPreviousRequest();
    },
    dragstart: () => {
      cancelPreviousRequest();
    },
    zoomend: () => {
      onUpdatePlots();
    },
    dragend: () => {
      onUpdatePlots();
    },
  });

  const onClickOutside = () => {
    setCurrentPlotId(-1);
    map.closePopup();
  };

  useOnClickOutside(mapRef, onClickOutside);

  useEffect(() => {
    if (!polygon) return;
    const bounds = [] as any[];
    var polygons = polygon;
    if (currentPolygonId !== -1)
      polygons = polygons.filter(
        polygon => polygon.polygonId === currentPolygonId
      );
    polygons.forEach(polygon =>
      polygon.polygonMap?.forEach(coordinate =>
        bounds.push([coordinate.latitude, coordinate.longitude])
      )
    );
    if (bounds.length) map.fitBounds(bounds);
  }, [polygon, currentPolygonId]);
  return null;
};

/**
 * @deprecated since CAR-418.
 * Use `ProjectDetailMapV2 ` instead.
 */

const ProjectPolygonLeafletMap = (props: IProjectPolygonLeafletMap) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [currentPlotId, setCurrentPlotId] = useState<number>(-1);
  const [plots, setPlots] = useState<IPlot[]>([]);
  const [plotsLoading, setPlotsLoading] = useState(false);
  const [selectedLayerTypes, setSelectedLayerTypes] = useState<LayerType[]>([
    'Default',
  ]);
  const { currentPolygonId } = useContext(CurrentSelectedPolygon);

  const basemapLayerType = mapHelpers.getBaseMapLayerType(selectedLayerTypes);

  useEffect(() => {
    window.dispatchEvent(new Event('resize')); // NOTE: this is a workaround for leaflet correctly renders on webpage
  }, []);

  if (props?.polygons?.length === 0) return null;

  return (
    <CurrentPlots.Provider
      value={{
        plots,
        setPlots,
        plotsLoading,
        setPlotsLoading,
      }}
    >
      <CurrentSelectedPlot.Provider value={{ currentPlotId, setCurrentPlotId }}>
        <LeafletContainer
          ref={mapRef}
          data-testid="leaflet-map"
          className={props.className}
        >
          <MapContainer
            scrollWheelZoom
            fadeAnimation={true}
            markerZoomAnimation={true}
          >
            <MapLayers
              mapRef={mapRef}
              allowTypes={
                IS_DEMO
                  ? [
                      'Default',
                      'Satellite',
                      'LandCover',
                      'CustomClassification',
                    ]
                  : ['Default', 'Satellite', 'LandCover']
              }
              selectedLayerTypes={selectedLayerTypes}
              setSelectedLayerTypes={setSelectedLayerTypes}
            />
            <SetBoundsComponent
              currentPolygonId={currentPolygonId}
              polygon={props.polygons}
              mapRef={mapRef}
              projectId={props.projectId}
            />
            {plotsLoading && (
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 1999,
                }}
              >
                <Spin />
              </div>
            )}
            <ProjectClusterLeaflet>
              <ProjectPlots plots={plots} />
            </ProjectClusterLeaflet>
            {props.polygons.length > 0 && (
              <MapPolygons
                activePolygonIds={[currentPolygonId]}
                polygons={props.polygons.map(polygon => ({
                  id: polygon.polygonId,
                  polygonMap: polygon.polygonMap,
                  color: 'rgba(0,0,5,0.4)',
                  activeOptions: {
                    color: '#EB564A',
                    dashArray: '2 6',
                  },
                }))}
              />
            )}
            {basemapLayerType && (
              <BaseMapPolygons basemapLayerType={basemapLayerType} />
            )}
          </MapContainer>
        </LeafletContainer>
      </CurrentSelectedPlot.Provider>
    </CurrentPlots.Provider>
  );
};

export default ProjectPolygonLeafletMap;
