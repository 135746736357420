import { Tag, TagProps } from 'antd';
import { COLOR } from 'constants/styles/color';
import { t } from 'helpers/i18n';

interface IStateTag {
  waiting: string;
  selected: string;
  rejected: string;
}
const TAG_OUTLINE_COLOR_BY_STATUS: IStateTag = {
  waiting: 'geekblue',
  selected: 'green',
  rejected: 'volcano',
};

const TAG_SOLID_COLOR_BY_STATUS: IStateTag = {
  waiting: COLOR.SUPPORT,
  selected: COLOR.PRIMARY,
  rejected: COLOR.ERROR,
};

const fontSize = {
  large: '16px',
  medium: '14px',
  small: '12px',
};

const titleValues: IStateTag = {
  waiting: t('Waiting'),
  selected: t('Selected'),
  rejected: t('Rejected'),
};

export interface TagStatusProps extends TagProps {
  isSelected?: boolean;
  type?: 'outline' | 'solid';
  size?: 'large' | 'medium' | 'small';
}

const mapValues = ({
  value,
  isSelected,
}: {
  value: IStateTag;
  isSelected?: boolean;
}) => {
  if (isSelected === undefined || isSelected === null) return value.waiting;
  else if (isSelected === true) return value.selected;
  return value.rejected;
};

const TagStatus = (props: TagStatusProps) => {
  const { children, isSelected, size, type, ...restTag } = props;
  const { style, ...rest } = restTag;
  const mapFontSize = (size?: 'large' | 'medium' | 'small') => {
    if (!size) return fontSize['medium'];
    return fontSize[size];
  };

  const statusColor =
    type === 'solid'
      ? mapValues({ value: TAG_SOLID_COLOR_BY_STATUS, isSelected })
      : mapValues({ value: TAG_OUTLINE_COLOR_BY_STATUS, isSelected });

  return (
    <Tag
      color={statusColor}
      style={{ fontSize: mapFontSize(size), ...style }}
      {...rest}
    >
      {children || mapValues({ value: titleValues, isSelected })}
    </Tag>
  );
};
export default TagStatus;
