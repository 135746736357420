/** Third party libs **/
import { Button, Col, Form, FormInstance, Input, Row } from 'antd';
import React from 'react';
import StaffSelect from './StaffSelect';

/** Local libs **/
import ContentBlock from 'components/shared/ContentBlock';
import { t } from 'helpers/i18n';

/** Styles **/

/** Interfaces, enum... **/
interface IFarmerFilterProps {
  form: FormInstance;
  onSubmit: () => void;
  onReset: () => void;
  projectId: number;
}

/** Variables **/

/** ------------------------- **/
const FarmerFilter: React.FC<IFarmerFilterProps> = ({
  form,
  onSubmit,
  onReset,
  projectId,
}) => {
  /** Elements **/
  return (
    <ContentBlock>
      <Form form={form} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col span={7}>
            <Form.Item name="keyword" className="mb-0">
              <Input placeholder={t('FarmerSearch')} allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="participantIds" className="ml-half mb-0 w-100">
              <StaffSelect projectId={projectId} allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Button className="ml-half" onClick={onReset}>
              {t('Reset')}
            </Button>
            <Button htmlType="submit" type="primary" className="ml-half">
              {t('Search')}
            </Button>
          </Col>
        </Row>
      </Form>
    </ContentBlock>
  );
};

export { FarmerFilter };
export default FarmerFilter;
