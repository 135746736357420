// TODO: Add unitt tests for tracking service
import trackerUtils from './trackerUtils';

const { CLICK_DATA, getPayload } = trackerUtils;

const EDIT_BUTTON_INDEX = 0;
const INPUT_INDEX = 1;
const CANCEL_BUTTON_INDEX = 2;
const SAVE_BUTTON_INDEX = 3;

const SAVE_BUTTON_VALUE = 'SAVE';
const CANCEL_BUTTON_VALUE = 'CANCEL';

const NAME_TO_EVENT: Object = {
  species: 'TreeSpecies',
  treeAge: 'TreeAge',
  circum: 'TrunkCirumfere',
};

const trackEditingField = (
  contentName: string,
  regionName: string,
  index: number
) => {
  window.track('manualTrackInteractionContent', {
    ...CLICK_DATA,
    screenName: 'submissionDetail',
    regionName: regionName,
    contentName: contentName,
    target: 'submissionDetail',
    payload: getPayload('submissionDetail', index),
  });
};

export default {
  trackEditingField,
  EDIT_BUTTON_INDEX,
  INPUT_INDEX,
  CANCEL_BUTTON_INDEX,
  SAVE_BUTTON_INDEX,
  SAVE_BUTTON_VALUE,
  CANCEL_BUTTON_VALUE,
  NAME_TO_EVENT,
};
