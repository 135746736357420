import { Button, Divider, Flex, Form, Select, Typography } from 'antd';
import { t } from 'helpers/i18n';
import React from 'react';
import RiskLevelPicker from './RiskLevelPicker';
import { convertNumberFormat } from 'helpers/common';
import IncrementalPlantingAreaTable from './IncrementalPlantingAreaTable';
import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';
import sumBy from 'lodash/sumBy';
import { IncPlantingAreaByYear } from 'interfaces';
import dayjs from 'dayjs';
import { YearRangePicker } from 'components/shared/DatePicker.js';

interface GeneralInfoFormProps {
  loading: boolean;
  onBack: () => void;
}

const MIN_RANGE_YEAR = 20;

const GeneralInfoForm: React.FC<GeneralInfoFormProps> = ({
  onBack,
  loading,
}) => {
  // Watch form fields for changes
  const totalEligibleArea = Form.useWatch('totalEligibleArea', {
    preserve: true,
  });
  const incPlantingAreaByYear: IncPlantingAreaByYear[] = Form.useWatch(
    'incPlantingAreaByYear'
  );
  const projectDate = Form.useWatch('projectDate');

  // Calculate total incremental planting area
  const totalIncrementalPlantingArea = sumBy(
    incPlantingAreaByYear,
    item => ((item.incPlantingAreaPercentage || 0) * totalEligibleArea) / 100
  );

  return (
    <div className="h-100 d-flex flex-column overflow-hidden flex-nowrap">
      <div style={{ overflow: 'auto' }}>
        <Typography.Title level={5}>{t('General')}</Typography.Title>
        <Form.Item
          label={t('ProjectActivities')}
          name="projectActivities"
          initialValue="ARR"
          required
          className="mb-base"
        >
          <Select options={[{ label: t('ARR'), value: 'ARR' }]} />
        </Form.Item>
        <Form.Item
          label={t('StartYearEndYear')}
          name="projectDate"
          rules={[
            {
              required: true,
              message: t('RequiredMessage'),
            },
            {
              validator: (_, dates) => {
                if (
                  dates &&
                  dayjs(dates[1]).diff(dates[0], 'y') < MIN_RANGE_YEAR - 1
                )
                  return Promise.reject(
                    t('MiniumRangeYear', { min: MIN_RANGE_YEAR })
                  );
                return Promise.resolve();
              },
            },
          ]}
          help={t('MiniumRangeYear', { min: MIN_RANGE_YEAR })}
        >
          <YearRangePicker minRange={MIN_RANGE_YEAR} />
        </Form.Item>
        <Divider />
        <Typography.Title level={5}>{t('RiskLevel')}</Typography.Title>
        <Form.Item
          name="riskLevel"
          rules={[
            {
              validator: (_, metrics) => {
                // Check if any of the risk metrics is null
                return Object.values(metrics).some(isNull)
                  ? Promise.reject()
                  : Promise.resolve();
              },
              message: t('PleaseInputYourCustomRiskLevel'),
            },
            {
              validator: (_, metrics) => {
                return Object.values(metrics)
                  .filter(isNumber)
                  .some(value => value > 100 || value < 0)
                  ? Promise.reject()
                  : Promise.resolve();
              },
              message: t('ValueMustBetween', { min: 0, max: 100 }),
            },
          ]}
        >
          <RiskLevelPicker />
        </Form.Item>
        <Divider />
        <Typography.Title level={5}>
          {t('IncrementalPlantingAreaByYear')}
        </Typography.Title>
        <Form.Item
          name="incPlantingAreaByYear"
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              validator: (_, value) => {
                return value.some(
                  (item: IncPlantingAreaByYear) =>
                    item.incPlantingAreaPercentage > 0
                )
                  ? Promise.resolve()
                  : Promise.reject();
              },
              validateTrigger: 'onBlur',
              message: t('RequiredAtLeastOneYear'),
            },
            {
              validator: () => {
                return totalIncrementalPlantingArea > totalEligibleArea
                  ? Promise.reject()
                  : Promise.resolve();
              },
              validateTrigger: 'onChange',
              message: t('TotalPercentMustNotExceed100'),
            },
          ]}
        >
          <IncrementalPlantingAreaTable
            totalEligibleArea={totalEligibleArea}
            projectDate={projectDate}
          />
        </Form.Item>

        <Typography.Text strong className="mt-base d-block">
          {t('TotalEligibleArea')}:{' '}
          <span style={{ color: 'dodgerblue' }}>
            {convertNumberFormat(totalEligibleArea || 0)}{' '}
          </span>
          ha
        </Typography.Text>
        <Typography.Text strong className="d-block">
          {t('TotalIncrementalPlantingArea')}:{' '}
          <span style={{ color: 'dodgerblue' }}>
            {convertNumberFormat(totalIncrementalPlantingArea)}{' '}
          </span>
          ha
        </Typography.Text>
      </div>
      <Flex className="mt-base" style={{ justifyContent: 'end' }}>
        <Button
          onClick={() => onBack()}
          block
          className="flex-1 mr-half"
          disabled={loading}
        >
          {t('Back')}
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          block
          style={{ flex: 2 }}
          loading={loading}
        >
          {t('GenerateReport')}
        </Button>
      </Flex>
    </div>
  );
};

export default GeneralInfoForm;
