/** Third party libs **/
import { Card, Space, Typography } from 'antd';
import classNames from 'classnames';
import React from 'react';

/** Local libs **/

/** Styles **/
import './CardItem.scss';

/** Interfaces, enum... **/
interface ICardItemProps {
  onClick: () => void;
  properties: any[];
  isSelected?: boolean;
  title?: string;
}

/** Variables **/
const { Text } = Typography;

/** ------------------------- **/
const CardItem: React.FC<ICardItemProps> = ({
  onClick,
  properties,
  isSelected,
  title,
}) => {
  /** States **/

  /** Hooks **/

  /** Variables **/
  const iconProps = {
    style: { color: '#00AA5B', marginRight: 8 },
  };

  /** Functions, Events, Actions... **/

  /** Effects **/

  /** Elements **/
  return (
    <Card
      className={classNames('card-wrapper', 'w-100', isSelected && 'selected')}
      onClick={onClick}
    >
      <Space className="d-flex justify-content-between">
        <div>
          <Text strong className="d-block">
            {title}
          </Text>
          {properties.map(
            ({ icon: Icon, ...property }, index) =>
              !!property.value && (
                <Text className="d-block" key={index}>
                  <Icon {...iconProps} />
                  {property.value}
                </Text>
              )
          )}
        </div>
      </Space>
    </Card>
  );
};

export { CardItem };
export default CardItem;
