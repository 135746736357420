import { useQuery } from '@tanstack/react-query';
import { Select, SelectProps } from 'antd';
import { commonConstants } from 'constants/index';
import { PAGING_OFFSET_ALL } from 'constants/pagination';
import { useProjectContext } from 'contexts';
import { cremaServices } from 'services';

const { QUERY_KEYS } = commonConstants;

const CremaSelect: React.FC<SelectProps> = selectProps => {
  const { projectId } = useProjectContext();

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.CREMA, projectId],
    queryFn: async () =>
      await cremaServices
        .getCremas({ projectId, ...PAGING_OFFSET_ALL })
        .then(res => res.cremas),
  });

  return (
    <Select
      allowClear
      showSearch
      loading={isLoading}
      fieldNames={{ label: 'cremaName', value: 'cremaId' }}
      options={data}
      {...selectProps}
    />
  );
};

export { CremaSelect };
