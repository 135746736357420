import { Form, Select } from 'antd';
import { useProjectContext } from 'contexts';
import React, { useEffect } from 'react';
import { useFpicContext } from '../context';
import { t } from 'helpers/i18n';
import { useFPicFormTemplate } from '../hooks/fpic';

interface TemplateSelectProps {
  templateFormId: number;
}

const TemplateSelect: React.FC<TemplateSelectProps> = ({ templateFormId }) => {
  /** Hooks * */
  const { setTemplate, setTemplates } = useFpicContext();
  const { projectId } = useProjectContext();
  const { templates, loading: loadingTemplate } = useFPicFormTemplate(
    projectId,
    setTemplates,
    undefined
  );

  const getTemplateForm = (id: number) =>
    templates?.find(item => item.templateFormId === id);

  /** Effects * */
  useEffect(() => {
    if (templates?.length) {
      const temp = templateFormId
        ? getTemplateForm(templateFormId) || templates[0]
        : templates[0];
      setTemplate?.(temp);
    }
  }, [templateFormId, JSON.stringify(templates)]);

  return (
    <Form.Item label={<b>{t('Template')}</b>}>
      <Select
        value={loadingTemplate ? '' : templateFormId}
        loading={loadingTemplate}
        disabled={true}
        placeholder={t('Template')}
        options={templates?.map(template => {
          return {
            value: template.templateFormId,
            label: template.templateFormTitle,
          };
        })}
      />
    </Form.Item>
  );
};

export default TemplateSelect;
