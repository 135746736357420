import { useEffect } from 'react';

// We should and obj bind instance here , otherwise the ctrl + f5 will use this of window ,more specific https://trekinbami.medium.com/its-not-magic-using-bind-in-javascript-18834e95cd8e
export const useCtrlF5PressEvent = (cb: Function, objBindInstance?: Object) => {
  useEffect(() => {
    function onPressCtrlF5(event: KeyboardEvent) {
      if (event.ctrlKey && event.key === 'F5') {
        cb.bind(objBindInstance)();
      }
    }
    window.addEventListener('keydown', onPressCtrlF5);
    return () => {
      window.removeEventListener('keydown', onPressCtrlF5);
    };
  }, []);
};
