export const defaultRoutes = [{ path: '/' }];

export const orgChartRoutes = [
  //
  {
    path: '/orgchart',
    children: [
      '/orgchart/user-profiles',
      '/orgchart/teams',
      '/orgchart/departments',
      '/orgchart/report-line',
    ],
  },
  { path: '/orgchart/user-profiles' },
  { path: '/orgchart/teams' },
  { path: '/orgchart/departments' },
  { path: '/orgchart/report-line' },
];

export const accountManagementRoutes = [
  //
  {
    path: '/accounts',
    children: ['/accounts/main', '/accounts/role-groups'],
  },
  { path: '/accounts/main' },
  { path: '/accounts/role-groups' },
  { path: '/accounts/role-groups/create' },
  { path: '/accounts/role-groups/:id' },
];
