import { useProjectContext } from 'contexts';
import { IFarmSurveyReport } from 'interfaces';
import { useState } from 'react';
import { surveyServices } from 'services';

const useSurveyReport = () => {
  const [loading, setLoading] = useState(false);
  const [surveyReport, setSurveyReport] = useState<IFarmSurveyReport>();
  const { projectId } = useProjectContext();

  const getSurveyReport = async (farmId: number) => {
    try {
      setLoading(true);
      const res = await surveyServices.getFarmlandSurveyReport({
        projectId,
        farmId,
      });
      setSurveyReport(res);
    } finally {
      setLoading(false);
    }
  };

  return {
    surveyReport,
    loading,
    getSurveyReport,
  };
};

export default {
  useSurveyReport,
};
