import { Tabs } from 'antd';
import React from 'react';
import { t } from 'helpers/i18n';
import AppContainer from 'containers/AppLayout/AppContainer';
import Contracts from './Contracts';
import PreFarmerEnrolment from './PreFarmerEnrolment';
import { FarmEligibility } from './FarmEligibility';

const ENROLLMENT_KEYS = {
  PreEnrolment: 'PreEnrolment',
  FarmEligible: 'FarmEligible',
  Contracts: 'Contracts',
};

const ENROLLMENT_ELEMENTS = {
  [ENROLLMENT_KEYS.PreEnrolment]: {
    key: ENROLLMENT_KEYS.PreEnrolment,
    label: t('PreEnrollment'),
  },
  [ENROLLMENT_KEYS.FarmEligible]: {
    key: ENROLLMENT_KEYS.FarmEligible,
    label: t('FarmEligible'),
  },
  [ENROLLMENT_KEYS.Contracts]: {
    key: ENROLLMENT_KEYS.Contracts,
    label: t('Agreements'),
  },
};

const FarmerEnrolment: React.FC = () => {
  const items = [
    {
      ...ENROLLMENT_ELEMENTS.PreEnrolment,
      children: <PreFarmerEnrolment />,
    },
    {
      ...ENROLLMENT_ELEMENTS.FarmEligible,
      children: <FarmEligibility />,
    },
    {
      // Contract = Agreement
      ...ENROLLMENT_ELEMENTS.Contracts,
      children: <Contracts />,
    },
  ];

  return (
    <AppContainer title="">
      <Tabs
        defaultActiveKey={ENROLLMENT_ELEMENTS.PreEnrolment.key}
        items={items}
        className="aja-tabs"
      />
    </AppContainer>
  );
};

export { FarmerEnrolment };
export default FarmerEnrolment;
