import localizationConstants from 'constants/localization';
import { ICountry, ITreeSpecies } from 'interfaces';
import localization from './localization';
const {
  TREE_SPECIES_RESOURCES,
  TREE_SPECIES_SCIENTIFIC_RESOURCES,
} = localizationConstants;

const currentLanguage = localization.getCurrentLanguage() as ICountry;

/**
 * Function for convert tree name to search tree name item for mutiple language
 * @param {string} commonName common name of that tree
 * @param {string} codeName code of that tree
 */
const convertSpeciesDisplay = (commonName: string, codeName: string) => {
  const scientificName =
    TREE_SPECIES_SCIENTIFIC_RESOURCES[codeName as keyof Object] || codeName;

  return !commonName || scientificName === commonName
    ? scientificName
    : `${commonName} (${scientificName})`;
};

export const TREE_SPECIES_OPTIONS = Object.entries(
  TREE_SPECIES_RESOURCES[currentLanguage as keyof Object]
).map(([speciesCode, speciesName]) => ({
  label: convertSpeciesDisplay(speciesName, speciesCode),
  value: speciesCode,
}));

export const findSpeciesId = (
  treeSpecies: ITreeSpecies[],
  treeCodename: string
) => {
  const tree = treeSpecies.find(
    tree => tree.treeSpeciesCodeName === treeCodename
  );
  return tree?.treeSpeciesId;
};
