import PermissionsTable from './PermissionsTable';
import React from 'react';
import { Col, Form, FormProps, Input, Row } from 'antd';
import { t } from 'helpers/i18n';
import { ProjectCommunitySelect } from 'components/shared/Select';
import { MyProjectSelect } from 'components/shared/Select';
import './UpsertRoleGroupForm.scss';

interface IUpsertRoleGroupFormProps extends FormProps {
  projects?: { id: number; name: string }[];
  communities?: { id: number; name: string }[];
}

const UpsertRoleGroupForm: React.FC<IUpsertRoleGroupFormProps> = ({
  projects,
  communities,
  ...formProps
}) => {
  const projectIds = Form.useWatch('projectIds', formProps.form);

  return (
    <Form layout="vertical" className="upsert-role-group-form" {...formProps}>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item
            label={t('RoleGroupName')}
            name="operationRoleName"
            rules={[
              {
                required: true,
                whitespace: true,
                message: t('PleaseEnterRoleGroupName'),
              },
            ]}
          >
            <Input placeholder={t('Name')} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t('Description')} name="description">
            <Input.TextArea
              placeholder={t('Description')}
              autoSize={{ minRows: 1 }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item label={t('Project')} name="projectIds" required>
            <MyProjectSelect mode="multiple" projects={projects} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t('Community')} name="communityIds">
            <ProjectCommunitySelect
              projectIds={projectIds}
              communities={communities}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name={'permissionIds'}>
        <PermissionsTable />
      </Form.Item>
    </Form>
  );
};

export default UpsertRoleGroupForm;
