import { useEffect, useMemo, useRef, useState } from 'react';
import { mapServices } from 'services';
import { IGetBasemapParams, IGetBasemapResponse, IPOI } from '../interfaces';
import { BASEMAP_TYPE } from 'constants/map';
import { useProjectContext } from 'contexts';
import { ID_DEFAULT } from 'constants/common';
import { useMapContext } from 'contexts/map';
import commonHooks from './common';
import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import { useQuery } from '@tanstack/react-query';
import { commonConstants } from 'constants/index';

const { QUERY_KEYS } = commonConstants;

/**
 * @deprecated since May 2024.
 * Use `useBasemapTekbone ` instead.
 */
const useBasemap = (projectId: number, polygonType?: string) => {
  const [loading, setLoading] = useState(false);
  const [basemap, setBasemap] = useState<IGetBasemapResponse | undefined>(
    undefined
  );

  const fetch = async () => {
    if (projectId === ID_DEFAULT) return;

    try {
      setLoading(true);
      const result = await mapServices.getBasemap({
        projectId,
        basemapType: BASEMAP_TYPE.LAND_COVER,
        polygonType,
      });

      setBasemap(result.data?.data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch().then();
  }, [projectId]);

  return {
    loading,
    basemap,
    fetch,
  };
};

const useBasemapTekbone = (basemapType: BASEMAP_TYPE, polygonType?: string) => {
  const { projectId } = useProjectContext();
  const [loading, setLoading] = useState(false);
  const [basemap, setBasemap] = useState<IGetBasemapResponse | undefined>(
    undefined
  );

  const getBasemap = async (
    payload: Omit<IGetBasemapParams, 'projectId'>,
    setState = true
  ) => {
    if (projectId === ID_DEFAULT) return;
    try {
      setLoading(true);
      const result = await mapServices.getBasemapTekbone({
        ...(payload || {}),
        projectId,
        polygonType,
      });

      if (setState) {
        setBasemap(result);
      }
      return result;
    } catch {
      setBasemap(undefined);
    } finally {
      setLoading(false);
    }
  };

  const { data } = useQuery({
    queryKey: [QUERY_KEYS.BASEMAP_POLYGONS, projectId, basemapType],
    queryFn: () =>
      getBasemap(
        {
          basemapType,
        },
        false
      ),
  });

  useEffect(() => {
    if (data) {
      setBasemap(data);
    }
  }, [data]);

  return {
    loading,
    basemap,
    getBasemap,
  };
};

interface useMarkerHighlightOptions {
  focus?: boolean;
  highlightDependencies?: any[];
}

const useMarkerHighlight = (
  poiIds: IPOI['poiId'] | IPOI['poiId'][],
  options?: useMarkerHighlightOptions
) => {
  const { setPOI, getPOI } = useMapContext();

  const watchPoiIds = useMemo(
    () => (Array.isArray(poiIds) ? poiIds : [poiIds]).filter(Boolean),
    [poiIds]
  );

  const prevPoiIds: IPOI['poiId'][] = commonHooks.usePrevious(watchPoiIds);

  const watchPoiIdsRef = useRef(watchPoiIds);

  useEffect(() => {
    watchPoiIdsRef.current = watchPoiIds;

    if (isEqual(watchPoiIds, prevPoiIds)) return;

    // Unhighlight removed POIs if exist
    const removedPoiIds = difference(prevPoiIds, watchPoiIds);
    setPOI(removedPoiIds.map(poiId => ({ poiId, isHighlighted: false })));

    // Highlight added POIs if exist
    const addedPoiIds = difference(watchPoiIds, prevPoiIds);
    setPOI(addedPoiIds.map(poiId => ({ poiId, isHighlighted: true })));

    // Focus on the last added POI
    if (options?.focus && !!addedPoiIds.length) {
      const poi = getPOI?.(watchPoiIds[watchPoiIds.length - 1]);
      poi?.focus?.();
    }
  }, [watchPoiIds]);

  useEffect(() => {
    if (!options?.highlightDependencies?.length) return;

    const shouldHighlight = options?.highlightDependencies?.reduce(
      (acc, current) => acc && current,
      true
    );
    setPOI(
      watchPoiIds.map(poiId => ({ poiId, isHighlighted: shouldHighlight }))
    );
  }, options?.highlightDependencies);

  useEffect(() => {
    return () => {
      // Unhighlight all POIs when unmount
      setPOI(
        watchPoiIdsRef.current.map(poiId => ({ poiId, isHighlighted: false }))
      );
    };
  }, []);
};

export default { useBasemap, useBasemapTekbone, useMarkerHighlight };
