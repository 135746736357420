import {
  Card,
  Col,
  Row,
  // Typography
} from 'antd';
// import { t } from 'helpers/i18n';
import {
  IContract,
  //  IItem
} from 'interfaces';
import React from 'react';
import ContractRules from './ContractRules';

interface ContractDocProps {
  contract: IContract;
}

// const { Text } = Typography;

const ContractDoc: React.FC<ContractDocProps> = ({ contract }) => {
  // const farmerInfos: IItem[] = [
  //   {
  //     label: t('FarmerName'),
  //     value: contract.farmerName,
  //   },
  //   {
  //     label: t('FarmerAddress'),
  //     value: contract.farmerAddress,
  //   },
  //   {
  //     label: t('FarmerNationalIDNumber'),
  //     value: contract.farmerNationalIdNumber,
  //   },
  // ];

  return (
    <Card className="contract-rules">
      <Row gutter={[16, 16]}>
        {/* <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Text strong className="text-center d-block mb-half">
                {t('Party')} A:
              </Text>
              {farmerInfos.map((farmerInfo, index) => {
                return (
                  <div key={index}>
                    <Row gutter={[16, 16]}>
                      <Col span={10}>
                        <Text strong style={{ marginRight: 16 }}>
                          {farmerInfo.label}:
                        </Text>
                      </Col>
                      <Col span={14}>
                        <Text>{farmerInfo.value || ''}</Text>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </Col>
            <Col span={12} className="text-center">
              <Text strong className="d-block mb-half">
                {t('Party')} B:
              </Text>
              <Text className="d-block">AJA Climate Solutions</Text>
            </Col>
          </Row>
        </Col> */}
        <Col span={24}>
          <ContractRules contract={contract} />
        </Col>
      </Row>
    </Card>
  );
};

export default ContractDoc;
