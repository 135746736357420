import { Card, Col, Row } from 'antd';
import { t } from 'helpers/i18n';
import { IContract, IItem } from 'interfaces';
import React from 'react';
import {
  ClockCircleOutlined,
  MobileOutlined,
  UserAddOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import './Detail.scss';
import ContractDoc from './ContractDoc';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { DATE_SLASH_FORMAT } from 'constants/dateTime';
import FarmerDetailModal from './FarmerDetailModal';
import { convertNumberFormat } from 'helpers/common';

interface DetailProps {
  contract: IContract;
}

const iconProps = {
  style: { color: '#00AA5B', marginRight: 8 },
};

const Detail: React.FC<DetailProps> = ({ contract }) => {
  const [showFarmerDetail, setShowFarmerDetail] = React.useState(false);

  const commonInfos: IItem[] = [
    {
      label: t('Submitted'),
      value: contract.submittedAt
        ? dayjs.unix(contract.submittedAt).format(DATE_SLASH_FORMAT)
        : '',
      icon: <ClockCircleOutlined {...iconProps} />,
    },
    {
      label: t('Area'),
      value: contract.farmArea
        ? `${convertNumberFormat(contract.farmArea)} ha`
        : 'N/A',

      icon: <MobileOutlined {...iconProps} />,
    },
    {
      label: t('Farmer'),
      value: contract.farmerName,
      icon: <UserAddOutlined {...iconProps} />,
      onClick: () => {
        if (contract.farmerId) setShowFarmerDetail(true);
      },
    },
    {
      label: t('Community'),
      value: contract.communityName,
      icon: <TeamOutlined {...iconProps} />,
    },
  ];

  return (
    <Row gutter={[16, 16]} className="contract-detail">
      <Col span={24}>
        <Card title={t('AgreementDetail')}>
          <div className="common-infos">
            {commonInfos.map((commonInfo, index) => {
              return (
                <div
                  key={index}
                  onClick={commonInfo.onClick}
                  className={classNames({ clickable: commonInfo.onClick })}
                >
                  <div>
                    <span>{commonInfo.icon}</span>
                    <span>{commonInfo.label}</span>
                  </div>
                  <div>{commonInfo.value || '\u00A0'}</div>
                </div>
              );
            })}
          </div>
        </Card>
      </Col>
      <Col span={24}>
        <ContractDoc contract={contract} />
      </Col>
      {contract.farmerId && (
        <FarmerDetailModal
          open={showFarmerDetail}
          farmerId={contract.farmerId}
          setShowFarmerDetail={setShowFarmerDetail}
        />
      )}
    </Row>
  );
};

export default Detail;
