import {
  Alert,
  Button,
  Card,
  DatePicker,
  Form,
  FormInstance,
  TimePicker,
  Typography,
  notification,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';

/** Local libs **/
import {
  IFarmerProfile,
  IUpsertUserAppointment,
  IUserAppointment,
} from 'interfaces';
import { t } from 'helpers/i18n';
import StaffSelect from '../FarmerFilter/StaffSelect';
import { userAppointmentHooks, userHooks } from 'hooks';
import { DATE_SLASH_FORMAT, TIME_FORMAT_LITE } from 'constants/dateTime';
import { LIST_FARMER_TYPE_CONSTANT } from 'constants/farmer';
import { PreEnrollmentContext } from '../../Context';

/** Styles **/
import './MakeAppointment.scss';

/** Interfaces, enum... **/
interface IMakeAppointmentProps {
  selectedFarmer?: IFarmerProfile;
  selectedAppointment?: IUserAppointment;
  listFarmerType?: string;
  makeAppointmentForm: FormInstance;
  projectId: number;
  setListFarmerType: React.Dispatch<React.SetStateAction<string>>;
}

/** Variables **/
const { useUserPermissions } = userHooks;

/** ------------------------- **/
/** MakeAppointment Component */
/** ------------------------- **/
const MakeAppointment: React.FC<IMakeAppointmentProps> = ({
  selectedFarmer,
  selectedAppointment,
  listFarmerType,
  makeAppointmentForm,
  projectId,
  setListFarmerType,
}) => {
  /** States **/
  const [isAllowedOverlapSchedule, setIsAllowedOverlapSchedule] = useState(
    false
  );

  /** Hooks **/
  const {
    upsertUserAppointment,
    loading,
  } = userAppointmentHooks.useUserAppointment(projectId);
  const {
    refreshFarmer,
    refreshUserAppointment,
    keyword,
    participantIds: participants,
  } = useContext(PreEnrollmentContext);
  const { canUpdateFarmerEnrollment } = useUserPermissions();

  /** Variables **/

  /** Functions, Events, Actions... **/
  const range = (start: number, end: number) => {
    return Array.from({ length: end }, (_, index) => index + start);
  };

  const convertDateAndTime = (date: dayjs.Dayjs, time: dayjs.Dayjs): number => {
    return (
      dayjs(date)
        .startOf('date')
        .unix() +
      dayjs(time).second() +
      dayjs(time).minute() * 60 +
      dayjs(time).hour() * 3600
    );
  };

  const disabledDate = (current: dayjs.Dayjs) => {
    return current && current < dayjs().startOf('date');
  };

  const disabledStartTime = () => {
    return {
      disabledHours: () =>
        !makeAppointmentForm?.getFieldValue('endTime')
          ? []
          : range(0, 24).splice(
              makeAppointmentForm?.getFieldValue('endTime')?.hour(),
              24
            ),
      disabledMinutes: (selectedHour: number) =>
        selectedHour < makeAppointmentForm?.getFieldValue('endTime')?.hour()
          ? []
          : range(makeAppointmentForm?.getFieldValue('endTime')?.minute(), 60),
    };
  };

  const disabledEndTime = () => {
    return {
      disabledHours: () =>
        range(0, 24).splice(
          0,
          makeAppointmentForm?.getFieldValue('startTime')?.hour()
        ),
      disabledMinutes: (selectedHour: number) =>
        selectedHour > makeAppointmentForm?.getFieldValue('startTime')?.hour()
          ? []
          : range(0, makeAppointmentForm?.getFieldValue('startTime')?.minute()),
    };
  };

  const onSubmitAppointment = async (values: any) => {
    if (
      !selectedAppointment &&
      listFarmerType === LIST_FARMER_TYPE_CONSTANT.APPOINTMENT
    ) {
      notification.error({
        message: 'Error',
        description: t('PleaseSelectFarmerOrAppointment'),
      });
    } else if (
      !selectedFarmer &&
      listFarmerType === LIST_FARMER_TYPE_CONSTANT.NEED_APPOINTMENT
    ) {
      notification.error({
        message: 'Error',
        description: t('PleaseSelectFarmerOrAppointment'),
      });
    } else {
      const params: IUpsertUserAppointment = {
        appointmentId: selectedAppointment?.id,
        participantIds: values.participantIds,
        startTime: convertDateAndTime(values.startDate, values.startTime),
        endTime: convertDateAndTime(values.startDate, values.endTime),
        projectId,
        appointeeId:
          selectedAppointment?.appointeeId ||
          (selectedFarmer && selectedFarmer.farmerId),
        isAllowedOverlapSchedule,
      };

      const result = await upsertUserAppointment(params);
      if (result.status === 200) {
        notification.success({
          message: 'Success',
          description: 'Update user appointment successfully',
        });
        if (listFarmerType === LIST_FARMER_TYPE_CONSTANT.NEED_APPOINTMENT) {
          makeAppointmentForm.resetFields();
          setListFarmerType(LIST_FARMER_TYPE_CONSTANT.APPOINTMENT);
        }
        refreshFarmer(keyword, participants);
        refreshUserAppointment(keyword, participants);
        setIsAllowedOverlapSchedule(false);
      } else if (result.status === 423) {
        // status 423 is error code when schedule overlaped
        setIsAllowedOverlapSchedule(true);
      }
    }
  };

  /** Effects **/
  useEffect(() => {
    if (
      listFarmerType === LIST_FARMER_TYPE_CONSTANT.APPOINTMENT &&
      selectedAppointment
    ) {
      makeAppointmentForm.setFieldsValue({
        appointmentId: selectedAppointment?.id,
        participantIds: selectedAppointment?.participants?.map(
          participant => participant.participantId
        ),
        startDate: dayjs.unix(selectedAppointment?.startTime),
        startTime: dayjs.unix(selectedAppointment?.startTime),
        endTime: dayjs.unix(selectedAppointment?.endTime),
      });
    } else if (selectedAppointment === undefined) {
      makeAppointmentForm.resetFields();
    }
  }, [selectedAppointment]);

  useEffect(() => {
    setIsAllowedOverlapSchedule(false);
  }, [listFarmerType, selectedFarmer, selectedAppointment]);

  /** Elements **/
  return canUpdateFarmerEnrollment ? (
    <Card
      style={{
        height: 700,
        overflowY: 'auto',
        borderRadius: '4px',
      }}
      title={t('MakeAppointment')}
    >
      {/* Only show make appointment when selected farmer or appointment */}
      {(listFarmerType === LIST_FARMER_TYPE_CONSTANT.APPOINTMENT &&
        selectedAppointment) ||
      (listFarmerType === LIST_FARMER_TYPE_CONSTANT.NEED_APPOINTMENT &&
        selectedFarmer) ? (
        <Form
          form={makeAppointmentForm}
          onFinish={onSubmitAppointment}
          layout="vertical"
        >
          <Form.Item
            name={'participantIds'}
            label={t('Staff')}
            rules={[{ required: true }]}
          >
            <StaffSelect projectId={projectId} />
          </Form.Item>
          <Form.Item
            name={'startDate'}
            label={t('Date')}
            rules={[{ required: true }]}
          >
            <DatePicker
              className="w-100"
              format={DATE_SLASH_FORMAT}
              disabledDate={disabledDate}
            />
          </Form.Item>
          <Form.Item
            name={'startTime'}
            label={t('StartTime')}
            rules={[{ required: true }]}
          >
            <TimePicker
              className="w-100"
              format={TIME_FORMAT_LITE}
              disabledTime={disabledStartTime}
            />
          </Form.Item>
          <Form.Item
            name={'endTime'}
            label={t('EndTime')}
            rules={[{ required: true }]}
          >
            <TimePicker
              className="w-100"
              format={TIME_FORMAT_LITE}
              disabledTime={disabledEndTime}
            />
          </Form.Item>
          {isAllowedOverlapSchedule && (
            <Alert
              type="warning"
              showIcon
              message={t('WarningScheduleOverlap')}
              className="mb-base"
            />
          )}
          <Form.Item className="d-flex justify-content-center">
            <Button
              htmlType="submit"
              className="submitButton"
              type="primary"
              disabled={loading}
            >
              {t('Save')}
            </Button>
          </Form.Item>
        </Form>
      ) : (
        // Show please select farmer or appointment based on list type
        <>
          {listFarmerType === LIST_FARMER_TYPE_CONSTANT.APPOINTMENT ? (
            <Typography.Text>{t('PleaseSelectAppointment')}</Typography.Text>
          ) : (
            <Typography.Text>{t('PleaseSelectFarmer')}</Typography.Text>
          )}
        </>
      )}
    </Card>
  ) : (
    <>{t('YouDontHavePermission')}</>
  );
};

export { MakeAppointment };
export default MakeAppointment;
