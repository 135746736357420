import { ConfigProvider } from 'antd';
import { localizationConstants } from 'constants/index';
import { localizationHelpers } from 'helpers';
import { getTheme } from 'helpers/i18n';
import i18n from 'i18n';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import './firebase';
import FlagsupProvider from 'contexts/ConfigFlagsupProvider';
import { store } from 'redux/store';
import { Provider } from 'react-redux';

const { REGIONS } = localizationConstants;
const { getCurrentLanguage } = localizationHelpers;

import(`./styles/${window.appConfig?.appName}.scss`).catch(() => {});

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <ConfigProvider
        locale={REGIONS[getCurrentLanguage()].antdLocale}
        theme={getTheme()}
      >
        <FlagsupProvider>
          <App />
        </FlagsupProvider>
      </ConfigProvider>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);
