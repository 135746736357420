import { Flex, Form, notification, Steps } from 'antd';
import { t } from 'helpers/i18n';
import React, { useState } from 'react';
import CarbonPolygonForm from './CarbonPolygonForm';
import GeneralInfoForm from './GeneralInfoForm';
import classNames from 'classnames';
import { Store } from 'antd/es/form/interface';
import { useProjectContext } from 'contexts';
import { reportServices } from 'services';
import { useHistory } from 'react-router-dom';

interface FeasibilityStudyReportFormProps {
  onSuccess: () => void;
}
const WAIT_TIME_BEFORE_REDIRECT = 1000;

const FeasibilityStudyReportForm: React.FC<FeasibilityStudyReportFormProps> = ({
  onSuccess,
}) => {
  const { projectId } = useProjectContext();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const items = [
    {
      title: t('CarbonPolygon'),
      content: (
        <CarbonPolygonForm onNext={() => setActiveStep(activeStep + 1)} />
      ),
      className: 'w-100',
    },
    {
      title: t('GeneralInfo'),
      content: (
        <GeneralInfoForm
          onBack={() => setActiveStep(activeStep - 1)}
          loading={loading}
        />
      ),
    },
  ];

  const onGenerateCarbonPotentialReport = async (values: Store) => {
    const payload = {
      projectId,
      startDate: values.projectDate[0].unix(),
      endDate: values.projectDate[1].unix(),
      reportAttributes: {
        riskLevel: values.riskLevel,
        incPlantingAreaByYear: values.incPlantingAreaByYear,
        polygonIds: values.polygonIds,
      },
    };
    try {
      setLoading(true);
      await reportServices.generateAREACarbonPotentialReport(payload);
      onSuccess();
      notification.success({
        duration: 1,
        message: t('GenerateReportSuccessfully'),
        closable: false,
      });
      setTimeout(() => {
        history.push('/carbon-potential-report');
      }, WAIT_TIME_BEFORE_REDIRECT);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex vertical className="h-100 overflow-hidden">
      <Steps items={items} current={activeStep} size="small" />
      <Form
        layout="vertical"
        onFinish={onGenerateCarbonPotentialReport}
        scrollToFirstError
        className="d-flex flex-column h-100 overflow-hidden"
        disabled={loading}
      >
        {items.map((item, index) => (
          <div
            key={index}
            className={classNames([
              { 'd-none': activeStep !== index },
              'mt-base',
              'h-100',
              'overflow-hidden',
            ])}
          >
            {item.content}
          </div>
        ))}
      </Form>
    </Flex>
  );
};

export default FeasibilityStudyReportForm;
