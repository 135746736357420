import { commonConstants } from 'constants/index';
import { AnyObject, IPermission } from 'interfaces';
import latinize from 'latinize';
import _, { flatten } from 'lodash';

const { APPS } = commonConstants;

export const getBrowserLanguage = () => {
  return navigator.language || 'en';
};

export const permission = (
  app: IPermission['app'],
  resource: IPermission['resource'],
  action?: IPermission['action']
) => ({
  app,
  resource,
  action,
});

const getWindowDimensions = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

export const convertDateFormat = (
  date: string,
  currentLanguage = getBrowserLanguage()
) => {
  const convertDate = new Date(date);
  return convertDate.toLocaleString(currentLanguage);
};

// Handle remove accent for all language
const toLowerCaseNonAccent = (str: string) => latinize(str).toLowerCase();

// we should pass currentLanguage here because when write test with module, the previous CURRENT_BROWSER_LANGUAGE will never update even we mock getBrowserLanguage(it mean the module already init ,but i will not trigger function again)
export const convertNumberFormat = (
  value: number,
  fixedNumber = 2,
  currentLanguage = getBrowserLanguage()
) => {
  return new Intl.NumberFormat(currentLanguage).format(
    +value.toFixed(fixedNumber)
  );
};

export const filterSelect = (
  searchValue: string,
  option?: { label?: any; value?: any }
) => {
  const optionValue = option?.label ?? '';
  const searchValueLowercase = searchValue.toLowerCase();

  return toLowerCaseNonAccent(optionValue).includes(
    toLowerCaseNonAccent(searchValueLowercase)
  );
};

export const upperCaseFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const replaceHistoryWithoutReload = (path: string) => {
  window.history.replaceState(null, '', path);
};

export const getMetaData = () => {
  const app = window.appConfig.appName;
  const host = window.location.origin;
  let data = {
    faviconUrl: `${host}/favicon/base.png`,
    title: 'Carbon MRV Web Admin',
    logo: 'common.png',
    supportLangs: ['en'],
  };

  switch (app) {
    case APPS.AJA:
      data = {
        faviconUrl: `${host}/favicon/aja.png`,
        title: 'AJA Karbonmap Web Admin',
        logo: 'aja.png',
        supportLangs: ['en'],
      };
      break;
    case APPS.WB:
      data = {
        faviconUrl: `${host}/favicon/wb.png`,
        title: 'HUE FPDF',
        logo: 'wb.png',
        supportLangs: ['vi', 'en'],
      };
      break;
    case APPS.WA:
      data = {
        faviconUrl: `${host}/favicon/wa.png`,
        title: 'Wild Asia Karbonmap Web Admin',
        logo: 'wa.png',
        supportLangs: ['en'],
      };
      break;
    case APPS.ACC:
      data = {
        faviconUrl: `${host}/favicon/acc.png`,
        title: 'TerraTribe Karbonmap Web Admin',
        logo: 'acc.png',
        supportLangs: ['en', 'id'],
      };
      break;
    case APPS.BASE:
      data = {
        ...data,
        supportLangs: ['vi', 'en', 'id', 'km', 'fr'],
      };
      break;
  }

  return data;
};

export const hasValue = (value: any) => {
  return !!flatten([value]).join();
};

export const cleanObject = (obj: AnyObject): AnyObject => {
  return _.transform(
    obj,
    (result: AnyObject, value: any, key: string) => {
      if (_.isArray(value)) {
        value = value
          .filter(v => !_.isNull(v) && !_.isUndefined(v))
          .map(v => (_.isObject(v) ? cleanObject(v) : v));
      } else if (_.isObject(value)) {
        value = cleanObject(value);
      }

      if (
        !_.isNull(value) &&
        !_.isUndefined(value) &&
        (!_.isArray(value) || !_.isEmpty(value))
      ) {
        result[key] = value;
      }
    },
    {}
  );
};

export const convertNumberLocale = (numberString: string) => {
  const noThousandsSeparator = numberString.replaceAll(',', '').toString();
  return noThousandsSeparator;
};

export default {
  getWindowDimensions,
  convertDateFormat,
  toLowerCaseNonAccent,
  convertNumberFormat,
  getBrowserLanguage,
  filterSelect,
  upperCaseFirstLetter,
  replaceHistoryWithoutReload,
  hasValue,
  cleanObject,
  convertNumberLocale,
};
