import { divIcon } from 'leaflet';

export const LeafletMarkerIcon = {
  DEFAULT: {
    HAS_DATA: divIcon({
      iconAnchor: [8, 16],
      popupAnchor: [0, -16],
      html: `<span class="custom-marker custom-marker-default-has-data" />`,
    }),
    NO_DATA: divIcon({
      iconAnchor: [8, 16],
      popupAnchor: [0, -16],
      html: `<span class="custom-marker custom-marker-default-no-data" />`,
    }),
  },
  ACTIVE: divIcon({
    iconAnchor: [8, 16],
    popupAnchor: [0, -16],
    html: `<span class="custom-marker custom-marker-active"  />`,
  }),
  HIGHLIGHT: divIcon({
    iconAnchor: [8, 16],
    popupAnchor: [0, -16],
    html: `<span class="custom-marker custom-marker-highlight" />`,
  }),
};
