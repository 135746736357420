import { Button, Card, Flex } from 'antd';
import { useProjectContext } from 'contexts';
import { t } from 'helpers/i18n';
import { IARRCarbonPotentialReport } from 'interfaces';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { reportServices } from 'services';
import CarbonPotentialReportTable from './CarbonPotentialReportTable';
import './CarbonPotentialReport.scss';
import { userHooks } from 'hooks';

const { useUserPermissions } = userHooks;

const CarbonPotentialReport = () => {
  const history = useHistory();
  const { projectId } = useProjectContext();
  const [carbonPotentialReport, setCarbonPotentialReport] = useState<
    IARRCarbonPotentialReport
  >();
  const [loading, setLoading] = useState(false);
  const { canGenerateCarbonPotentialReport } = useUserPermissions();

  useEffect(() => {
    getCarbonPotentialReport();
  }, []);

  const getCarbonPotentialReport = async () => {
    try {
      setLoading(true);
      const carbonPotentialReport = await reportServices.getARRCarbonPotentialReport(
        projectId
      );
      setCarbonPotentialReport(carbonPotentialReport);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      className="carbon-potential-report"
      classNames={{
        body: 'p-base',
      }}
    >
      <Flex justify="end" className="mb-base">
        {canGenerateCarbonPotentialReport && (
          <Button
            type="primary"
            onClick={() =>
              history.push(`/project-boundaries?generateReport=true`)
            }
          >
            {t('GenerateNewReport')}
          </Button>
        )}
      </Flex>
      <CarbonPotentialReportTable
        loading={loading}
        carbonPotentialReport={carbonPotentialReport}
      />
    </Card>
  );
};

export default CarbonPotentialReport;
