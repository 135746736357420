/** Third party libs * */
import React from 'react';
import { Card } from 'antd';

/** Local libs * */

/** Components * */

/** Styles * */

/** ------------------------- * */
/** Interfaces, enum... * */
interface CardItemProps {
  className?: string;
  style?: object;
  bodyStyle?: object;
}

/** Variables * */

/** ------------------------- * */
const CardItem: React.FC<CardItemProps> = ({
  style,
  className,
  bodyStyle,
  children,
}) => {
  /** States * */

  /** Hooks * */

  /** Variables * */

  /** Effects * */

  /** Functions, Events, Actions... * */

  /** Elements * */
  return (
    <Card
      bordered={false}
      hoverable={false}
      style={style}
      className={className}
      styles={{
        body: {
          padding: 16,
          ...bodyStyle,
        },
      }}
    >
      {children}
    </Card>
  );
};

export { CardItem };
export default CardItem;
