/** Third party libs **/
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Space, Typography } from 'antd';

/** Local libs **/

/** Styles **/
import './AlertCard.scss';
import { v4 } from 'uuid';

/** Interfaces, enum... **/
interface IAlertCardProps {
  warnings?: string[];
}

/** Variables **/
const { Text } = Typography;

/** ------------------------- **/
/** AlertCard Component */
/** ------------------------- **/
const AlertCard: React.FC<IAlertCardProps> = ({ warnings }) => {
  /** States **/

  /** Hooks **/

  /** Variables **/

  /** Functions, Events, Actions... **/

  /** Effects **/

  /** Elements **/
  return (
    <Card
      className="alert-card"
      styles={{
        body: {
          paddingTop: 6,
          display: 'flex',
        },
      }}
    >
      <div className="icon-warpper d-flex align-items-center justify-content-center">
        <InfoCircleOutlined className="alert-icon d-block" />
      </div>
      <Space direction="vertical" className="warning-warpper">
        {warnings?.map(warning => (
          <div key={v4()} className="d-flex align-items-center">
            <div className="dot" />
            <Text>{warning}</Text>
          </div>
        ))}
      </Space>
    </Card>
  );
};

export { AlertCard };
export default AlertCard;
