import classNames from 'classnames';
import { CurrentSelectedPolygon } from 'components/ProjectList/ProjectModal/context';
import {
  DrawingPolygon,
  IMapPolygon,
  LayerType,
  MapLayers,
  MapPolygons,
  SetBoundsComponent,
} from 'components/shared/Map';
import SmallMarker from 'components/shared/Map/SmallMarker/SmallMarker';
import { DEFAULT_z_INDEX_POLYGON, DrawState } from 'constants/map';
import { ICrema, IPOI, IPolygonTekbone } from 'interfaces';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { MapContainer, Pane, useMap } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import styled from 'styled-components';
import './FormationMap.scss';
import FormationMapWrapper from './FormationMapWrapper';
import { useMapContext } from 'contexts/map';
import { MapLoading } from 'components/shared/Map/MapLoading';
import { useCremaFormationContext } from 'contexts/cremaFormation';
import { POLYGON_TYPES } from 'constants/project';

const LeafletContainer = styled.div`
  height: 100%;
  width: 100%;
  margin-bottom: 20px;
  .leaflet-container {
    width: 100%;
    height: 100%;
  }
  .leaflet-div-icon {
    background: none;
    border: none;
  }
`;

interface IFormationMapProps {
  cremas: ICrema[];
  loading: boolean;
  preventOpenCrema?: boolean;
}

const FormationMap: React.FC<IFormationMapProps> = ({
  cremas,
  loading,
  preventOpenCrema,
  children,
}) => {
  const [selectedLayerTypes, setSelectedLayerTypes] = useState<LayerType[]>([
    'Default',
  ]);
  const mapRef = useRef<HTMLDivElement>(null);
  const { currentPolygonId, drawState, setDrawState } = useContext(
    CurrentSelectedPolygon
  );
  const { markers, clusterMarkers, drawRef } = useMapContext();
  const {
    polygons,
    refetchPolygons,
    polygonsLoading,
  } = useCremaFormationContext();

  const ProjectBoundaryTypeId = POLYGON_TYPES.PROJECT_BOUNDARY.id;
  const InterventionAreaTypeId = POLYGON_TYPES.INTERVENTION_AREA.id;

  const { projectBoundaryPolygons, interventionAreaPolygons } = polygons.reduce(
    (polygonTypes, polygon) => {
      if (polygon.polygonTypeId === ProjectBoundaryTypeId) {
        polygonTypes.projectBoundaryPolygons.push(polygon);
      }
      if (polygon.polygonTypeId === InterventionAreaTypeId) {
        polygonTypes.interventionAreaPolygons.push(polygon);
      }
      return polygonTypes;
    },
    { projectBoundaryPolygons: [], interventionAreaPolygons: [] } as {
      projectBoundaryPolygons: IPolygonTekbone[];
      interventionAreaPolygons: IPolygonTekbone[];
    }
  );

  const projectBoundaries = useMemo(
    () =>
      projectBoundaryPolygons.map(polygon => ({
        id: polygon.polygonId,
        polygonMap: polygon.polygonMap,
        multiplePolygonMap: polygon.multiplePolygonMap,
        color: polygon.hexColorCode,
        label: polygon.polygonName,
      })),
    [polygons]
  );

  const interventionAreas = useMemo(
    () =>
      interventionAreaPolygons.map(polygon => ({
        id: polygon.polygonId,
        polygonMap: polygon.polygonMap,
        multiplePolygonMap: polygon.multiplePolygonMap,
        color: polygon.hexColorCode,
        label: polygon.polygonName,
      })),
    [polygons]
  );

  const cremasToPolygons: IMapPolygon[] = useMemo(
    () =>
      cremas.map(crema => {
        return {
          id: crema.cremaId,
          polygonMap: crema.cremaPolygonMap,
          color: crema.cremaPolygonHexColorCode,
          label: crema.cremaName,
        } as IMapPolygon;
      }),
    [cremas]
  );

  const boundPolygons = useMemo(() => {
    return [...cremasToPolygons, ...projectBoundaries];
  }, [cremasToPolygons, projectBoundaries]);

  useEffect(() => {
    refetchPolygons();
    window.dispatchEvent(new Event('resize')); // NOTE: this is a workaround for leaflet correctly renders on webpage
  }, []);

  return (
    <LeafletContainer
      ref={mapRef}
      className="leaflet-wrapper"
      data-testid="formation-leaflet-map"
    >
      <MapContainer
        scrollWheelZoom
        fadeAnimation={true}
        markerZoomAnimation={true}
        zoomControl={false}
      >
        <MapController />
        <MapLayers
          mapRef={mapRef}
          allowTypes={['Default', 'Satellite']}
          selectedLayerTypes={selectedLayerTypes}
          setSelectedLayerTypes={setSelectedLayerTypes}
        />

        <SetBoundsComponent
          currentPolygonId={currentPolygonId}
          polygons={boundPolygons}
          mapRef={mapRef}
        />

        <MapLoading loading={loading || polygonsLoading} />

        {/* Project Boundary  */}
        <Pane
          name="Boundary"
          style={{ zIndex: DEFAULT_z_INDEX_POLYGON }}
          key={
            drawState === DrawState.DRAW || drawState === DrawState.EDIT ? 1 : 0
          }
        >
          {projectBoundaries.length > 0 && (
            <MapPolygons
              activePolygonIds={[]}
              polygons={projectBoundaries}
              className={classNames({
                'project-boundary': true,
                'draw-polygon':
                  drawState === DrawState.DRAW || drawState === DrawState.EDIT,
              })}
            />
          )}
        </Pane>

        {/* Intervention Area  */}
        <Pane
          name="Intervention"
          style={{ zIndex: DEFAULT_z_INDEX_POLYGON + 1 }}
        >
          {interventionAreas.length > 0 && (
            <MapPolygons activePolygonIds={[]} polygons={interventionAreas} />
          )}
        </Pane>

        {/* Crema Layer */}
        <Pane name="Crema" style={{ zIndex: DEFAULT_z_INDEX_POLYGON + 4 }}>
          {cremas.length > 0 && (
            <FormationMapWrapper
              polygons={cremasToPolygons}
              preventOnClick={preventOpenCrema}
            />
          )}
        </Pane>

        {/* Communities Layer */}
        <Pane name="POI" style={{ zIndex: DEFAULT_z_INDEX_POLYGON + 5 }}>
          <MarkerClusterGroup
            chunkedLoading
            polygonOptions={{
              stroke: false,
              color: 'transparent',
              opacity: 0,
            }}
            zoomToBoundsOnClick
            spiderfyOnMaxZoom={false}
          >
            {Object.values(clusterMarkers).map((marker: IPOI) => (
              <SmallMarker
                key={marker.poiId}
                poi={marker}
                onDbClick={marker?.onDbClick}
                alwaysShowLabel
                defaultHideIcon
              />
            ))}
          </MarkerClusterGroup>
          {Object.values(markers).map((marker: IPOI) => (
            <SmallMarker
              key={marker.poiId}
              poi={marker}
              alwaysShowLabel
              defaultHideIcon
            />
          ))}
        </Pane>

        <DrawingPolygon
          drawRef={drawRef}
          drawState={drawState}
          setDrawState={setDrawState}
        />

        {children}
      </MapContainer>
    </LeafletContainer>
  );
};

function MapController() {
  const { setMap } = useMapContext();

  const map = useMap();

  useEffect(() => {
    setMap(map);
  }, [map]);

  return null;
}

export default FormationMap;
