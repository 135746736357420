import {
  IGetListOperationRolesData,
  IGetListOperationRolesParams,
  IGetListAccountsParams,
  IGetListAccountsResponse,
  IGetUserListReponseData,
  IOrganizationRole,
  IUpdateAccountStatusPayload,
  ICreateOperationRolePayload,
  IGetOperationPermissionsResponse,
  ICreateOperationRoleResponse,
  IUpdateOperationRolePayload,
  IUpdateOperationRoleResponse,
  IGetOperationRoleResponse,
  ICreateAccountPayload,
  ICreateAccountResponse,
  IUpdateAccountPayload,
  IUpdateAccountResponse,
  IGetAccountDetailsResponse,
  IGetAccountDetailsParams,
} from 'interfaces';
import { getData } from 'helpers/request';
import { IGetUserListParams } from 'interfaces';
import { requestServices } from 'services';
import qs from 'qs';

const { projectManagerClient } = requestServices;

const PREFIX_API_URL = '/tekbone/api/v1';

const getUserList = (
  params?: IGetUserListParams
): Promise<IGetUserListReponseData> =>
  projectManagerClient.get(`${PREFIX_API_URL}/users`, { params }).then(getData);

const getOrganizationRoles = (): Promise<IOrganizationRole[]> =>
  projectManagerClient
    .get(`${PREFIX_API_URL}/organization-roles`)
    .then(getData);

const importUsers = (formData: FormData): Promise<void> =>
  projectManagerClient.post(`${PREFIX_API_URL}/users/import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

const updateAccountStatus = (
  payload: IUpdateAccountStatusPayload
): Promise<void> =>
  projectManagerClient.post(
    `${PREFIX_API_URL}/users/${payload.userId}/update-account-status`,
    payload
  );

const getListOperationRoles = (
  params: IGetListOperationRolesParams
): Promise<IGetListOperationRolesData> =>
  projectManagerClient
    .get(`${PREFIX_API_URL}/operation/roles`, { params })
    .then(getData);

const getListAccounts = (
  params: IGetListAccountsParams
): Promise<IGetListAccountsResponse> =>
  projectManagerClient
    .get(`${PREFIX_API_URL}/accounts`, {
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    })
    .then(getData);

const getListOperationPermissions = async (): Promise<IGetOperationPermissionsResponse> =>
  projectManagerClient
    .get(`${PREFIX_API_URL}/operation/permissions`)
    .then(getData);

const createOperationRole = async (
  payload: ICreateOperationRolePayload
): Promise<ICreateOperationRoleResponse> =>
  projectManagerClient.post(`${PREFIX_API_URL}/operation/roles`, payload);

const updateOperationRole = async (
  payload: IUpdateOperationRolePayload
): Promise<IUpdateOperationRoleResponse> =>
  projectManagerClient.put(
    `${PREFIX_API_URL}/operation/roles/${payload.roleId}`,
    payload
  );

const getOperationRole = async (
  roleId: number
): Promise<IGetOperationRoleResponse> =>
  projectManagerClient
    .get(`${PREFIX_API_URL}/operation/roles/${roleId}`)
    .then(getData);

const createAccount = (
  payload: ICreateAccountPayload
): Promise<ICreateAccountResponse> =>
  projectManagerClient
    .post(`${PREFIX_API_URL}/accounts`, payload)
    .then(getData);

const updateAccount = (
  payload: IUpdateAccountPayload
): Promise<IUpdateAccountResponse> =>
  projectManagerClient
    .put(`${PREFIX_API_URL}/accounts/${payload.accountId}`, payload)
    .then(getData);

const getAccountDetails = (
  params: IGetAccountDetailsParams
): Promise<IGetAccountDetailsResponse> =>
  projectManagerClient
    .get(`${PREFIX_API_URL}/accounts/${params.accountId}`)
    .then(getData);

export default {
  getUserList,
  getOrganizationRoles,
  importUsers,
  updateAccountStatus,
  getListOperationRoles,
  getListOperationPermissions,
  createOperationRole,
  updateOperationRole,
  getOperationRole,
  getListAccounts,
  createAccount,
  updateAccount,
  getAccountDetails,
};
