import { CalendarOutlined, TagOutlined } from '@ant-design/icons';
import { Button, Card, Drawer, DrawerProps, Empty } from 'antd';
import { polygonHooks } from 'hooks';
import { IPolygonTekbone } from 'interfaces';
import { keyBy } from 'lodash';
import { useMemo } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { DATE_TIME_SLASH_FORMAT_DASH } from 'constants/dateTime';
import { ReactComponent as FoldedPaper } from 'assets/svgs/FoldedPaper.svg';
import { COLOR } from 'constants/styles/color';
import { convertNumberFormat } from 'helpers/common';
import { ID_DEFAULT } from 'constants/common';
import { useProjectDetailV2Context } from 'containers/Project/ProjectDetailMapV2/context';
import { t } from 'helpers/i18n';

const { usePolygonTypes } = polygonHooks;

interface PolygonListDrawerProps extends DrawerProps {
  polygons: IPolygonTekbone[];
}

const PolygonListDrawer: React.FC<PolygonListDrawerProps> = ({
  polygons,
  ...props
}) => {
  const { polygonTypes } = usePolygonTypes();
  const {
    setStatusAfterMapChanges,
    currentPolygonId,
    setCurrentPolygonId,
    canLoadMore,
    loadMore,
    gettingPolygons,
  } = useProjectDetailV2Context();
  const poygonTypesMap = useMemo(() => keyBy(polygonTypes, 'polygonTypeId'), [
    polygonTypes,
  ]);

  const cachedPolygons: IPolygonTekbone[] = useMemo(
    () => polygons.filter(p => p.polygonId !== ID_DEFAULT),
    [polygons]
  );

  return (
    <Drawer
      placement="left"
      getContainer={false}
      closeIcon
      width={280}
      mask={false}
      rootStyle={{ position: 'absolute' }}
      classNames={{
        header: 'drawer-header',
        body: 'drawer-body',
      }}
      styles={{ header: { borderBottom: 'none' } }}
      {...props}
    >
      {cachedPolygons.length > 0 ? (
        <>
          {cachedPolygons.map(p => (
            <Card
              key={p.polygonId}
              styles={{ body: { padding: 16 } }}
              style={{ marginBottom: 8, marginTop: 4 }}
              className={classNames({
                'selected-polygon-card': currentPolygonId === p.polygonId,
              })}
              hoverable
              onClick={() => {
                setStatusAfterMapChanges({ callAPI: false, isCenter: true });
                setCurrentPolygonId(p.polygonId);
              }}
            >
              <b>{p.polygonName}</b>
              <div className="d-flex align-items-center mt-half">
                <FoldedPaper
                  style={{
                    width: 14,
                    height: 14,
                    filter:
                      'invert(57%) sepia(45%) saturate(358%) hue-rotate(66deg) brightness(102%) contrast(82%)',
                  }}
                  className="d-block mr-half"
                />
                {convertNumberFormat(p.area)} Ha
              </div>
              <div className="mt-quarter">
                <TagOutlined
                  style={{
                    transform: 'rotateY(180deg)',
                    color: COLOR.PRIMARY,
                  }}
                  className="mr-half"
                />
                {poygonTypesMap[p.polygonTypeId]?.polygonTypeName || ''}
              </div>
              <div className="mt-quarter">
                <CalendarOutlined
                  style={{ color: COLOR.PRIMARY }}
                  className="mr-half"
                />
                {dayjs.unix(p.createdAt).format(DATE_TIME_SLASH_FORMAT_DASH)}
              </div>
            </Card>
          ))}
          {canLoadMore && (
            <Button
              type="link"
              onClick={loadMore}
              className="w-100"
              loading={gettingPolygons}
            >
              {t('LoadMore')}
            </Button>
          )}
        </>
      ) : (
        <Empty />
      )}
    </Drawer>
  );
};

export { PolygonListDrawer };
