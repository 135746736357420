import { ID_DEFAULT } from 'constants/common';
import { LeafletEventHandlerFnMap, LeafletMouseEvent, Map } from 'leaflet';
import { useEffect } from 'react';
import { useMap, useMapEvents } from 'react-leaflet';
import { useOnClickOutside } from 'usehooks-ts';
import { IMapPolygon } from '../MapPolygons';
import { DEFAULT_CENTER_POINT, DEFAULT_ZOOM } from '../constants';
import { IPolygonMap } from 'interfaces';

interface ISetBoundsComponent {
  polygons: IMapPolygon[];
  mapRef: React.RefObject<HTMLDivElement>;
  currentPolygonId: number;
  isCenteredWhenPolygonChanges?: boolean;
  onClickEvent?: (e: LeafletMouseEvent) => void;
  eventHandlers?: (map: Map) => LeafletEventHandlerFnMap;
}

const SetBoundsComponent: React.FC<ISetBoundsComponent> = ({
  polygons,
  mapRef,
  currentPolygonId,
  isCenteredWhenPolygonChanges = true,
  eventHandlers,
}) => {
  const map = useMap();

  useMapEvents({
    ...eventHandlers?.(map),
  });

  const onClickOutside = () => {
    map.closePopup();
  };

  const centerPolygons = () => {
    if (!polygons.length) return;
    const bounds = [] as any[];
    let filteredPolygons = polygons;
    if (currentPolygonId !== ID_DEFAULT)
      filteredPolygons = polygons.filter(
        polygon => polygon.id === currentPolygonId
      );
    filteredPolygons.forEach(polygon => {
      let coordinates: IPolygonMap[] = [];
      if (polygon.multiplePolygonMap?.length) {
        coordinates = polygon.multiplePolygonMap
          .map(polygon => polygon.polygonMap)
          .flat(2);
      }
      if (polygon.polygonMap?.length) {
        coordinates = polygon.polygonMap;
      }
      coordinates.forEach(coordinate =>
        bounds.push([coordinate.latitude, coordinate.longitude])
      );
    });
    // First, center all polygons, then center currentPolygon
    if (bounds.length) map.fitBounds(bounds);
  };

  useOnClickOutside(mapRef, onClickOutside);

  useEffect(() => {
    if (isCenteredWhenPolygonChanges) centerPolygons();
  }, [currentPolygonId]);

  useEffect(() => {
    if (isCenteredWhenPolygonChanges) centerPolygons();
  }, [polygons]);

  useEffect(() => {
    if (!polygons.length) map.setView(DEFAULT_CENTER_POINT, DEFAULT_ZOOM);
  }, []);

  return <></>;
};

export { SetBoundsComponent };
