export const SERVER_ROUTES = {
  PROJECT: {
    GET_ALL_PROJECT: '/api/v1/projects',
    CREATE_PROJECT: '/api/v1/projects',
    DELETE_PROJECT: '/api/v1/projects/:projectId',
    GET_PROJECT_DETAIL: '/api/v1/projects/:projectId',
    SELECT_SUBMISSION: '/api/v1/submissions/select',
  },
  POLYGON: {
    GET_ALL_POLYGON: '/api/v1/projects/:projectId/polygons',
    CREATE_POLYGON: '/api/v1/projects/:projectId/polygons',
    EXPORT_POLYGON: '/api/v1/projects/:projectId/polygons/export',
    DELETE_POLYGON: '/api/v1/polygons/:projectId',
  },
  SUBMISSION: {
    UPDATE_SUBMISSION: '/api/v1/submission',
    EXPORT_SUBMISSION: '/api/v1/submissions/export',
  },
  PLOTS: {
    GENERATE_SAMPLING_PLOTS: '/api/v1/plots/auto-generate',
  },
};
