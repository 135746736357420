import { Select, SelectProps } from 'antd';
import { filterSelect } from 'helpers/common';
import { tSelectPlaceholder } from 'helpers/i18n';
import { communityHooks } from 'hooks';
import { useEffect } from 'react';

const { useCommunities } = communityHooks;

/**
 *
 * @param props Antd SelectProps
 * @returns Antd Select component with options are communities that user has permission to access
 */
const UserProvinceCommunitySelect: React.FC<SelectProps<number>> = props => {
  const { communities, loading, getListCommunities } = useCommunities();

  useEffect(() => {
    getListCommunities();
  }, []);

  return (
    <Select
      allowClear
      showSearch
      placeholder={tSelectPlaceholder('Community')}
      options={communities.map(item => ({
        label: item.communityName,
        value: item.communityId,
      }))}
      loading={loading}
      filterOption={filterSelect}
      {...props}
    />
  );
};

export default UserProvinceCommunitySelect;
