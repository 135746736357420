import { ROUTES } from 'constants/routes';
import { getData, getResult } from 'helpers/request';
import {
  IGetListActivitiesParams,
  IGetListActivitiesResponse,
  IGetActivityCategoriesResponse,
  IGetCommunityDashboardFundingParams,
  IGetCommunityDashboardFundingResponse,
  IGetActivityDetailParams,
  ActivityDetail,
  IGetOrgActivityLogsParams,
  IGetOrgActivityLogsResponse,
  IGetActivityExpenseReceiptsParams,
  IGetActivityExpenseReceiptsResponse,
  IApproveActivityPayload,
  IRejectActivityPayload,
  IGetActivityLikesAndCommentsParams,
  IGetActivityLikesAndCommentsResponse,
  ILikeOrCommentActivityParams,
  IDownloadCommunityFinancialPlanParams,
} from 'interfaces';
import { requestServices } from 'services';

const { projectManagerClient } = requestServices;
const prefixUrl = '/tekbone/api/v1';

const getListActivities = (
  params: IGetListActivitiesParams
): Promise<IGetListActivitiesResponse> =>
  projectManagerClient
    .get(`${prefixUrl}/get-activities`, { params })
    .then(getData);

const getListActivityCategories = (): Promise<IGetActivityCategoriesResponse> =>
  projectManagerClient
    .get(`${prefixUrl}/get-list-categories?type=ACTIVITY`)
    .then(getData);

const getCommunityDashboardFunding = (
  params: IGetCommunityDashboardFundingParams
): Promise<IGetCommunityDashboardFundingResponse> =>
  projectManagerClient
    .get(`${prefixUrl}/communities/dashboard-funding`, { params })
    .then(getData);

const getActivityDetail = (
  params: IGetActivityDetailParams
): Promise<ActivityDetail> =>
  projectManagerClient
    .get(ROUTES.ACTIVITY.DETAIL, {
      params,
    })
    .then(getData);

const getOrgActivityLogs = async (
  params: IGetOrgActivityLogsParams
): Promise<IGetOrgActivityLogsResponse> =>
  projectManagerClient
    .get(ROUTES.ACTIVITY.ORG_ACTIVITY_LOGS, { params })
    .then(getData);

const getAcitivityExpenseReceipts = async (
  params: IGetActivityExpenseReceiptsParams
): Promise<IGetActivityExpenseReceiptsResponse> =>
  projectManagerClient
    .get(ROUTES.ACTIVITY.EXPENSE_RECEIPTS, { params })
    .then(getData);

const approveActivity = (payload: IApproveActivityPayload) =>
  projectManagerClient.put(ROUTES.ACTIVITY.APPROVE(payload.activityId), null, {
    params: { projectId: payload.projectId },
  });

const rejectActivity = (payload: IRejectActivityPayload) =>
  projectManagerClient.put(
    ROUTES.ACTIVITY.REJECT(payload.activityId),
    { feedback: payload?.feedback },
    { params: { projectId: payload.projectId } }
  );

const approveAllActivities = (payload: {
  projectId: number;
  organizationId: number;
}) =>
  projectManagerClient.put(
    ROUTES.ACTIVITY.APPROVE_ALL(payload.organizationId),
    null,
    {
      params: payload,
    }
  );

const getActivityLikesAndComments = (
  params: IGetActivityLikesAndCommentsParams
): Promise<IGetActivityLikesAndCommentsResponse> =>
  projectManagerClient
    .get(ROUTES.ACTIVITY.LIST_LIKES_COMMENTS, { params })
    .then(getData);

const likeOrCommentActivity = (
  params: ILikeOrCommentActivityParams
): Promise<void> =>
  projectManagerClient.post(ROUTES.ACTIVITY.LIKE_COMMENT, params);

const downloadCommunityFinancialPlan = async (
  params: IDownloadCommunityFinancialPlanParams
): Promise<Blob> =>
  projectManagerClient
    .get(
      `${prefixUrl}/communities/${params.communityId}/download-financial-plan`,
      {
        params,
        responseType: 'blob',
      }
    )
    .then(getResult);

export default {
  getListActivities,
  getListActivityCategories,
  getCommunityDashboardFunding,
  getActivityDetail,
  getOrgActivityLogs,
  getAcitivityExpenseReceipts,
  approveActivity,
  rejectActivity,
  approveAllActivities,
  getActivityLikesAndComments,
  likeOrCommentActivity,
  downloadCommunityFinancialPlan,
};
