import React, { useEffect, useMemo } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { Spin } from 'antd';
import { firebaseHooks, userHooks } from 'hooks';
import { userServices } from 'services';
import { IRoute } from 'interfaces';
import { StoreContext, StoreContextType, useFlagsupContext } from 'contexts';
import { currentUser as currentUserMock } from 'services/mocks/user';
import ProjectProvider from './ProjectProvider';
import UnassignedProjectPage from 'containers/shared/UnassignedProjectPage';

const { firebase } = window.config;
const { merchantId: appMerchantId } = window.appConfig;

const PrivateRoute = ({
  component: Component,
  ...rest
}: Omit<IRoute, 'name'>) => {
  const { featureFlagsData } = useFlagsupContext();

  useEffect(() => {
    // Tracking - Set userId when logged in
    track('setUserId', userServices.getUserInfo()?.sub);
  }, []);

  const { token } = firebaseHooks.useFirebase({
    vapidKey: firebase.vapidKey,
  });
  const { currentUser } = userHooks.useUserInfo();
  const history = useHistory();

  const storeContextValue: StoreContextType = useMemo(
    () => ({
      currentUser: currentUser || currentUserMock,
      firebaseToken: token,
    }),
    [currentUser, token]
  );
  const isCAR2398Enabled = featureFlagsData.CAR_2398?.enabled;

  // Check if user is logged in or not
  if (!userServices.isLoggedIn()) {
    userServices.login();
    return null;
  }

  // Show spin when fetching required global data
  if (!currentUser) {
    return <Spin className="app-spin" />;
  }

  const currentMerchantId = currentUser.meta_data?.merchant_id;
  const wrongMerchantId =
    isCAR2398Enabled && !!appMerchantId && appMerchantId !== currentMerchantId;
  if (wrongMerchantId) {
    history.push('/403');
  }

  return (
    <StoreContext.Provider value={storeContextValue}>
      <ProjectProvider>
        <Route exact path="/unassigned" component={UnassignedProjectPage} />
        <Route {...rest} render={routeProps => <Component {...routeProps} />} />
      </ProjectProvider>
    </StoreContext.Provider>
  );
};

export default PrivateRoute;
