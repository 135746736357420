import { Alert, Divider, Flex, Form, Radio, Select } from 'antd';
import { t } from 'helpers/i18n';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectProject, selectSamplingModules } from 'redux/features/project';

interface SamplingModuleSelectProps {
  showUploadOption: boolean;
}

export type UploadType = 'OVERWRITE' | 'ADDITIONAL';
const UPLOAD_OPTIONS: { label: string; value: UploadType }[] = [
  {
    label: t('Additional'),
    value: 'ADDITIONAL',
  },
  {
    label: t('Overwrite'),
    value: 'OVERWRITE',
  },
];

const SamplingModuleSelect: React.FC<SamplingModuleSelectProps> = ({
  showUploadOption = false,
}) => {
  const project = useSelector(selectProject);
  const samplingModules = useSelector(selectSamplingModules);

  return (
    <>
      <Flex gap={16}>
        <Form.Item
          name="samplingModuleId"
          label={t('Module')}
          className="flex-1"
          rules={[{ required: true }]}
        >
          <Select
            options={project?.samplingModuleTypes || []}
            fieldNames={{ label: 'typeName', value: 'id' }}
            placeholder={t('SelectSamplingModule')}
          />
        </Form.Item>
        {showUploadOption && (
          <Form.Item name="uploadOption" label=" ">
            <Radio.Group>
              {UPLOAD_OPTIONS.map(option => (
                <Radio value={option.value} key={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        )}
      </Flex>
      <Form.Item noStyle dependencies={['uploadOption']}>
        {({ getFieldValue }) => {
          const uploadOption: UploadType = getFieldValue('uploadOption');
          return (
            (!showUploadOption || uploadOption === 'OVERWRITE') && (
              <Form.Item>
                <Alert
                  message={t('PlotSetUploadOptionAlert')}
                  type="info"
                  showIcon
                />
              </Form.Item>
            )
          );
        }}
      </Form.Item>
      <Form.Item noStyle dependencies={['samplingModuleId']}>
        {({ getFieldValue }) => {
          const samplingModuleId = getFieldValue('samplingModuleId');
          const isModuleHasData = samplingModules.find(
            samplingModule => samplingModule.id === samplingModuleId
          )?.activePlotSet.hasSubmissionData;

          return (
            isModuleHasData && (
              <Form.Item>
                <Alert
                  message={t('CurrentPlotSetContainApprovedSurveySubmission')}
                  type="warning"
                  showIcon
                />
              </Form.Item>
            )
          );
        }}
      </Form.Item>
      <Divider className="mt-0" />
    </>
  );
};

export default SamplingModuleSelect;
