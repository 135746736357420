/** Third party libs * */
import React, { useEffect, useState } from 'react';
import { Image, Skeleton } from 'antd';
import { v4 } from 'uuid';

/** Local libs * */
import { t } from 'helpers/i18n';
import { BaseItem, BaseItemProps } from '../BaseItem';
import { FSImage } from 'components/shared/FSImage';

/** Components * */

/** Styles * */

/** Interfaces, enum... * */
interface CameraProps extends BaseItemProps {
  transformUrl?: (url: string) => string;
}

/** Variables * */

/** ------------------------- * */
const Camera: React.FC<CameraProps> = ({
  item,
  disabled,
  form,
  transformUrl,
  ...props
}) => {
  /** States * */
  const [images, setImages] = useState<string[]>([]);

  /** Hooks * */

  /** Variables * */

  /** Functions, Events, Actions... * */

  /** Effects * */
  useEffect(() => {
    if (item.fieldName) {
      form.setFieldValue(item.fieldName, item.value);
    }
  }, [item.value]);

  useEffect(() => {
    if (item.fieldName) {
      form.setFieldValue(item.fieldName, item.value);
    }

    if (item.value) {
      try {
        const values = JSON.parse(item.value);
        if (Array.isArray(values)) {
          setImages(
            values.filter(item => typeof item === 'string' && item !== '[]')
          );
        }
      } catch (e) {}
    }
  }, [item.value, item.fieldName]);

  /** Elements * */
  return (
    <BaseItem
      item={item}
      disabled={disabled}
      form={form}
      validator={(_, value) => {
        if (item.isRequired && (!value || !value.trim())) {
          return Promise.reject(t('NotBeEmpty'));
        }

        return Promise.resolve();
      }}
      {...props}
    >
      <Image.PreviewGroup
        preview={{
          imageRender: node => {
            return (
              <FSImage
                src={node.props?.src}
                style={{
                  maxWidth: 800,
                  maxHeight: 800,
                  width: 'auto',
                  height: 'auto',
                }}
                preview={false}
              />
            );
          },
          toolbarRender: () => <></>,
        }}
      >
        {images.map(image => (
          <span
            key={v4()}
            style={{
              marginRight: 8,
              marginBottom: 8,
              display: 'inline-block',
            }}
          >
            <FSImage
              width={100}
              height={100}
              src={transformUrl ? transformUrl(image) : image}
              placeholder={<Skeleton.Image active />}
              // show original image on preview
              preview={{ src: image }}
              resizePreview={{ size: 800 }}
            />
          </span>
        ))}
      </Image.PreviewGroup>
    </BaseItem>
  );
};

export default Camera;
