import { getData } from 'helpers/request';
import { requestServices } from 'services';

const { projectManagerClient } = requestServices;
const getLegends = (params: { projectId: number; basemapType: string }) => {
  return projectManagerClient
    .get('/tekbone/api/v1/get-basemap-labels', {
      params,
    })
    .then(getData);
};

export default {
  getLegends,
};
