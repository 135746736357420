import { Comment } from 'components/shared/FormSubmission/Comment';
import { PAGING_PAGE_SIZE_5 } from 'constants/pagination';
import { SortDirection } from 'interfaces';
import {
  ICremaMeetingNoteComment,
  IGetCremaMeetingNoteCommentsParams,
} from 'interfaces/cremas';
import React, { useEffect, useState } from 'react';
import { cremaServices } from 'services';
import dayjs from 'dayjs';

interface MeetingRecordCommentProps {
  submissionId: number;
  disabled?: boolean;
}

const DEFAULT_PARAMS = {
  ...PAGING_PAGE_SIZE_5,
  orderDirection: 'desc' as SortDirection,
};

const MeetingRecordComment: React.FC<MeetingRecordCommentProps> = ({
  submissionId,
  disabled,
}) => {
  const [fetchDataLoading, setFetchDataLoading] = useState(false);
  const [comments, setComments] = useState<ICremaMeetingNoteComment[]>([]);
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const [currentParams, setCurrentParams] = useState<
    Omit<IGetCremaMeetingNoteCommentsParams, 'submissionId'>
  >(DEFAULT_PARAMS);

  const sendComment = async (content: string) => {
    await cremaServices.createCremaMeetingNoteComment({
      submissionId,
      content,
    });
  };

  const getComments = async () => {
    try {
      setFetchDataLoading(true);
      const {
        comments: responseComment,
        pagination,
      } = await cremaServices.getCremaMeetingNoteComments({
        ...currentParams,
        submissionId,
      });
      const isLoadMore = currentParams.page > 1;
      const loadedComments = [
        ...(isLoadMore ? comments : []),
        ...(responseComment || []),
      ];
      setComments(loadedComments);
      const canLoadMore =
        !!loadedComments.length && loadedComments.length < pagination.total;
      setShouldLoadMore(canLoadMore);
    } finally {
      setFetchDataLoading(false);
    }
  };

  const loadMore = () => {
    setCurrentParams({
      ...currentParams,
      page: currentParams.page + 1,
      pageSize: PAGING_PAGE_SIZE_5.pageSize,
    });
  };

  const onSort = (direction: SortDirection) => {
    setCurrentParams({
      ...currentParams,
      ...PAGING_PAGE_SIZE_5,
      orderDirection: direction,
    });
  };

  const onRefresh = () => {
    setCurrentParams({
      ...currentParams,
      ...DEFAULT_PARAMS,
    });
  };

  useEffect(() => {
    onRefresh();
  }, [submissionId]);

  useEffect(() => {
    getComments();
  }, [currentParams]);

  return (
    <Comment
      comments={comments.map(comment => ({
        id: comment.id,
        content: comment.content,
        commenter: comment.userEmail,
        createdAt: dayjs(comment.createdAt).unix(), //convert timestamp milliseconds to seconds
      }))}
      disabled={disabled}
      fetchDataloading={fetchDataLoading}
      onRefreshData={onRefresh}
      onSendComment={sendComment}
      onLoadMore={loadMore}
      onSort={onSort}
      canLoadMore={shouldLoadMore}
    />
  );
};

export default MeetingRecordComment;
