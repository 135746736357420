import { requestServices } from 'services';
import { IGetContractsResponse, IGetContractsParams } from 'interfaces';
import { getData } from 'helpers/request';

const { projectManagerClient } = requestServices;

const getContracts = (
  data: IGetContractsParams
): Promise<IGetContractsResponse> => {
  return projectManagerClient
    .post('/tekbone/api/v1/get-list-contract', data)
    .then(getData);
};

export default { getContracts };
