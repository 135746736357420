import { requestServices } from 'services';
import {
  IApiResponse,
  IDownloadTemplateParams,
  IGetListProcessingFilesParams,
  IGetListProcessingFilesResponse,
  IUploadFileResponse,
  UploadFileType,
} from 'interfaces';
import axios from 'axios';
import queryString from 'query-string';
import { getData, getResult } from 'helpers/request';

const { fileServiceClient, projectManagerClient } = requestServices;

const uploadFile = (
  uploadType: UploadFileType,
  formData: FormData
): Promise<IUploadFileResponse> => {
  return fileServiceClient.post(`/upload/${uploadType}`, formData);
};

const getDirectUrl = async (
  file: File
): Promise<IApiResponse<{
  url?: string;
  fileUrl?: string;
  maxSize?: number;
}>> => {
  return await fileServiceClient.get(`/get-direct-url`, {
    params: {
      urlType: 'upload',
      contentType: file.type,
      isPrivate: true,
      fileName: file.name,
    },
  });
};

const getGcsLocation = async (
  gcsUrl: string,
  contentType: string,
  maxSize: number
): Promise<string | undefined> => {
  const result = await axios.post(
    gcsUrl,
    {},
    {
      headers: {
        'Content-type': contentType,
        'x-goog-resumable': 'start',
        'x-goog-content-length-range': `0,${maxSize}`,
      },
    }
  );

  return result?.headers?.location;
};

const getGoogleLocationHost = async (
  file: File
): Promise<{
  location?: string;
  url?: string;
}> => {
  const addResumable = (url: string) => {
    const val = queryString.parseUrl(url);
    const baseUrl = val.url;
    const query = val.query;
    return queryString.stringifyUrl({
      url: baseUrl,
      query: {
        ...query,
        // uploadType: 'resumable',
      },
    });
  };

  const result = await fileServiceClient.get(`/get-direct-url`, {
    params: {
      urlType: 'upload',
      contentType: file.type,
      isPrivate: true,
      fileName: file.name,
    },
  });

  const data = result?.data?.data;

  const url = data?.url;
  const maxSize = data?.maxSize;

  if (url) {
    const gResult = await axios.post(
      addResumable(url),
      {},
      {
        headers: {
          'Content-type': file.type,
          'x-goog-resumable': 'start',
          'x-goog-content-length-range': `0,${maxSize}`,
        },
      }
    );

    const location = gResult?.headers?.location;

    if (location) {
      return {
        location: addResumable(location),
        url: data?.fileUrl,
      };
    }

    return {
      location: undefined,
      url: data?.fileUrl,
    };
  }

  return {
    location: undefined,
    url: data?.fileUrl,
  };
};

const uploadGoogleHost = async (file: File, location: string) => {
  await axios.put(location, file);
};

const getResumeUploadStatus = async (location: string) => {
  let config = {
    method: 'put',
    maxBodyLength: Infinity,
    url: location,
    headers: {
      'Content-Range': `bytes */*`,
    },
  };

  return axios.request(config);
};

const resumeUploadFile = (
  file: any,
  lastByte: number,
  fileSize: number,
  location: string
) => {
  return axios.put(location, file, {
    headers: {
      // 'Content-Length': fileSize - lastByte - 1,
      'Content-Range': `bytes ${lastByte + 1}-${fileSize - 1}/${fileSize}`,
    },
  });
};

const getListProcessingFiles = async (
  params: IGetListProcessingFilesParams
): Promise<IGetListProcessingFilesResponse> => {
  return projectManagerClient
    .get(`/tekbone/api/v1/file/processing-files`, { params })
    .then(getData);
};

const downloadTemplate = (params: IDownloadTemplateParams): Promise<Blob> =>
  projectManagerClient
    .get('/tekbone/api/v1/download-template', {
      params,
      responseType: 'blob',
    })
    .then(getResult);

export default {
  getDirectUrl,
  uploadFile,
  getGcsLocation,
  resumeUploadFile,
  uploadGoogleHost,
  getGoogleLocationHost,
  getResumeUploadStatus,
  getListProcessingFiles,
  downloadTemplate,
};
