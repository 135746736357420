import { useEffect, useState } from 'react';
import { IProjectDetails } from 'interfaces';
import { projectServices } from 'services';
import { ID_DEFAULT } from 'constants/common';

const useProjectDetail = (projectId?: number) => {
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState<IProjectDetails | undefined>(
    undefined
  );

  const getProject = async (id?: number) => {
    const currentId = id ?? projectId;

    if (!currentId) return;

    setLoading(true);
    try {
      const result = await projectServices.getProjectById(currentId);

      setProject(result?.data?.data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (projectId !== ID_DEFAULT) {
      getProject().then();
    }
  }, [projectId]);

  return {
    project,
    loading,
  };
};

const usePolygon = () => {
  const [loading, setLoading] = useState(false);

  const createPolygon = async (id: number, formData: FormData) => {
    setLoading(true);
    let result;

    try {
      result = await projectServices.createPolygon(id, formData);
    } finally {
      setLoading(false);
    }

    return result;
  };

  return { loading, createPolygon };
};

export { usePolygon, useProjectDetail };
