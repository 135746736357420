import { Button, Card, Flex, Form, notification } from 'antd';
import { UpsertRoleGroupForm } from 'components/RoleGroupManagement';
import AppContainer from 'containers/AppLayout/AppContainer';
import { t } from 'helpers/i18n';
import { ICreateOperationRolePayload } from 'interfaces';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { userManagementServices } from 'services';

const CreateRoleGroup: React.FC = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleCreate = async (values: ICreateOperationRolePayload) => {
    try {
      setLoading(true);
      await userManagementServices.createOperationRole(values);
      notification.success({
        message: t('CreateRoleGroupSuccessfully'),
      });
      form.resetFields();
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppContainer>
      <Card classNames={{ body: 'p-base' }}>
        <Flex justify="end" gap={16}>
          <Button onClick={() => history.push(`/accounts/role-groups`)}>
            {t('Back')}
          </Button>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            {t('Save')}
          </Button>
        </Flex>
        <UpsertRoleGroupForm form={form} onFinish={handleCreate} />
      </Card>
    </AppContainer>
  );
};

export default CreateRoleGroup;
