/** Third party libs **/
import { Button, Col, Form, Input, Row } from 'antd';
import { t } from 'helpers/i18n';
import React, { useContext } from 'react';

/** Local libs **/
import ContentBlock from 'components/shared/ContentBlock';
import { CurrentSelectedFarm } from '../../Context';

/** Styles **/

/** Interfaces, enum... **/
interface IFarmEgibilityFilterProps {
  setKeyword: (keyword?: string) => void;
  refresh: () => void;
}

/** Variables **/

/** ------------------------- **/
/** FarmEgibilityFilter Component */
/** ------------------------- **/
const FarmEgibilityFilter: React.FC<IFarmEgibilityFilterProps> = ({
  setKeyword,
  refresh,
}) => {
  /** States **/

  /** Hooks **/
  const [form] = Form.useForm();
  const { setSelectedFarmId, setShowFarmInfo, setShowFarmList } = useContext(
    CurrentSelectedFarm
  );

  /** Variables **/

  /** Functions, Events, Actions... **/
  const onSubmit = (values: any) => {
    setKeyword(values.keyword);
    setSelectedFarmId(undefined);
  };

  const onReset = () => {
    form.resetFields();
    setSelectedFarmId(undefined);
    setShowFarmInfo(false);
    setShowFarmList(true);
    refresh();
  };

  /** Effects **/

  /** Elements **/
  return (
    <ContentBlock>
      <Form form={form} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col span={7}>
            <Form.Item name="keyword" className="mb-0">
              <Input placeholder={t('FarmerSearch')} allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Button className="ml-half" onClick={onReset}>
              {t('Reset')}
            </Button>
            <Button htmlType="submit" type="primary" className="ml-half">
              {t('Search')}
            </Button>
          </Col>
        </Row>
      </Form>
    </ContentBlock>
  );
};

export { FarmEgibilityFilter };
export default FarmEgibilityFilter;
