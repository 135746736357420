import SubmissionExport from 'components/Submission/SubmissionExport/SubmissionExport';
import SubmissionFilter from 'components/Submission/SubmissionFilter';
import SubmissionFormula from 'components/Submission/SubmissionFormula/SubmissionFormula';
import SubmissionInfo from 'components/Submission/SubmissionInfo';
import ContentBlock from 'components/shared/ContentBlock';
import { commonConstants } from 'constants/index';
import AppContainer from 'containers/AppLayout/AppContainer';
import { t } from 'helpers/i18n';
import trackCarbonContent from 'helpers/tracker/trackCarbonContent';
import trackSubmissionFilter from 'helpers/tracker/trackSubmissionFilter';
import { submissionHooks, treeHooks } from 'hooks';
import { ISubmission, ISubmissionAfterCAR2355 } from 'interfaces';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSubmissionId } from 'redux/features/submission';
import { AppDispatch } from 'redux/store';
import { TreeSpeciesContext } from '../context/useTreeSpecies';
import SubmissionTable from './SubmissionTable';

const { useSubmissionData } = submissionHooks;
const { useTreeSpecies: useSpecies } = treeHooks;
const { trackViewTree } = trackSubmissionFilter;
const { trackCarbonExplaination } = trackCarbonContent;
const { APPS } = commonConstants;
const LABEL = {
  [APPS.ACC]: 'GroundTruthDataManagement',
};

const SubmissionList: React.FC = () => {
  const {
    submissions,
    submissionsAfterCAR2355,
    filters,
    setFilters,
    fetching,
    _pagination,
    handleTableChange,
    setSubmissions,
    setSubmissionsAfterCAR2355,
  } = useSubmissionData();

  const dispatch: AppDispatch = useDispatch();
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [currentSubmission, setInfo] = useState<ISubmission>();
  const [currentSubmissionAfterCAR2355, setInfoAfterCAR2355] = useState<
    ISubmissionAfterCAR2355
  >();
  const [currentIndex, setIndex] = useState<number>(-1);
  const { treeSpeciesData: treeSpecies } = useSpecies();

  const showInfoModal = () => {
    setIsInfoModalOpen(true);
  };
  const onClickTreeIdBeforeCAR2355 = (record: ISubmission, index: number) => {
    setInfo(record);
    trackViewTree(record.submission.submissionId, index);
    setIndex(index);
    showInfoModal();
  };
  const onClickTreeIdAfterCAR2355 = (
    record: ISubmissionAfterCAR2355,
    index: number
  ) => {
    setInfoAfterCAR2355(record);
    trackViewTree(record.submissionId, index);
    setIndex(index);
    showInfoModal();
  };
  const onClickCarbonContent = (submissionId: number) => {
    dispatch(setSubmissionId(submissionId));
    trackCarbonExplaination('submissionList');
  };

  return (
    <AppContainer title={t(LABEL[window.appConfig.appName] || '')}>
      <TreeSpeciesContext.Provider value={{ treeSpecies }}>
        {!isInfoModalOpen || (
          <SubmissionInfo
            submissions={submissions}
            filters={filters}
            isInfoModalOpen={isInfoModalOpen}
            setIsInfoModalOpen={setIsInfoModalOpen}
            currentIndex={currentIndex}
            setIndex={setIndex}
            currentSubmission={currentSubmission!}
            setInfo={setInfo}
            setSubmissions={setSubmissions}
            submissionsAfterCAR2355={submissionsAfterCAR2355}
            setSubmissionsAfterCAR2355={setSubmissionsAfterCAR2355}
            setInfoAfterCAR2355={setInfoAfterCAR2355}
            currentSubmissionAfterCAR2355={currentSubmissionAfterCAR2355!}
          />
        )}
        <ContentBlock className="mb-base">
          <SubmissionFilter filters={filters} setFilters={setFilters} />
        </ContentBlock>
        <ContentBlock>
          <SubmissionTable
            loading={fetching}
            pagination={_pagination}
            submissions={submissions}
            submissionsAfterCAR2355={submissionsAfterCAR2355}
            handleTableChange={handleTableChange}
            onClickTreeIdBeforeCAR2355={onClickTreeIdBeforeCAR2355}
            onClickTreeIdAfterCAR2355={onClickTreeIdAfterCAR2355}
            onClickCarbonContent={onClickCarbonContent}
          />
          <SubmissionExport
            filter={filters}
            dataLength={submissions.length || submissionsAfterCAR2355.length}
            isFetching={fetching}
          />
        </ContentBlock>
        <SubmissionFormula />
      </TreeSpeciesContext.Provider>
    </AppContainer>
  );
};

export default SubmissionList;
