import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Space,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { t } from 'helpers/i18n';
import { COLOR } from 'constants/styles/color';
import { CloudUploadOutlined } from '@ant-design/icons';
import { uploadHelpers } from 'helpers';
import trackProjectMap from 'helpers/tracker/trackProjectMap';
import { usePolygon } from './hooks';
import { ID_DEFAULT } from 'constants/common';

interface ProjectPolygonUploadProps {
  children?: React.ReactNode;
  projectId: number;
  shouldRerenderPolygonTable?: number;
  setShouldRerenderPolygonTable?: (_: number) => void;
}

const POLYGONS_UPLOAD_FILE_FIELD_NAME = 'kmlFile';
const { trackProjectMapAction, PROJECT_MAP_TRACKING_DATA } = trackProjectMap;

const ProjectPolygonUpload: React.FC<ProjectPolygonUploadProps> = ({
  projectId,
  shouldRerenderPolygonTable,
  setShouldRerenderPolygonTable,
}) => {
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [file, setFile] = useState<UploadFile>();
  const [error, setError] = useState<string>();

  const { loading, createPolygon } = usePolygon();

  const uploadProps: UploadProps = {
    accept: '.kml',
    beforeUpload: async file => {
      trackProjectMapAction(PROJECT_MAP_TRACKING_DATA.choseFileToUpload);
      const state = await uploadHelpers.beforeUploadKml(file);
      if (state) setFile(file);
      return state;
    },
    showUploadList: false,
  };

  const onUpload = async () => {
    trackProjectMapAction(PROJECT_MAP_TRACKING_DATA.uploadPolygon);

    if (projectId === ID_DEFAULT) return;

    try {
      const formData = new FormData();
      formData.append(POLYGONS_UPLOAD_FILE_FIELD_NAME, file as any, file?.name);
      const res = await createPolygon(projectId, formData as any);
      if (res) {
        api.success({ message: t('ImportPolygonSuccessfully'), duration: 2 });
        setShouldRerenderPolygonTable?.((shouldRerenderPolygonTable ?? 0) + 1);
        setFile(undefined);
      }
      return res;
    } catch (_error) {
      setFile(undefined);
      // Notifies already
    }
  };

  useEffect(() => {
    setError(undefined);
  }, []);

  return (
    <>
      {contextHolder}
      <Row>
        <Col xs={24} sm={24} md={18} lg={14}>
          <Form
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            labelAlign="left"
          >
            <Form.Item
              name={`${POLYGONS_UPLOAD_FILE_FIELD_NAME}`}
              label={t('UploadNewPolygon')}
              extra={
                error && error.length ? (
                  <div
                    style={{ marginTop: '5px', color: COLOR.ERROR }}
                    data-testid="error-polygon-message"
                  >
                    {error}
                  </div>
                ) : null
              }
              style={{ marginBottom: '12px' }}
            >
              <Space.Compact style={{ width: '100%' }}>
                <Upload {...uploadProps} data-testid="upload-kml">
                  <Input
                    readOnly
                    value={file?.name}
                    style={{ cursor: 'pointer' }}
                  />
                </Upload>
                <Button
                  data-testid="ButtonUpload"
                  type="primary"
                  onClick={onUpload}
                  loading={loading}
                  disabled={!!(error || !file)}
                >
                  <CloudUploadOutlined />
                  <span style={{ marginLeft: '5px' }}>{t('Upload')}</span>
                </Button>
              </Space.Compact>
            </Form.Item>
            <Form.Item
              label={' '}
              colon={false}
              style={{ marginBottom: '12px' }}
            >
              <Typography.Paragraph italic style={{ marginBottom: 0 }}>
                {t('FileTypeKmlMaxSize10Mb')}
              </Typography.Paragraph>
              <Typography.Paragraph
                italic
                style={{ wordBreak: 'break-word', marginBottom: 0 }}
              >
                <span>{t('Note')}</span>{' '}
                <span>{t('NoteThePolygonsMustNotOverlap')}</span>{' '}
                <span>{t('NotePolygonsActualAreaMayDiffer')}</span>
              </Typography.Paragraph>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ProjectPolygonUpload;
