import { useState } from 'react';

export interface useDisclosureProps {
  onClose: () => any;
  onOpen: () => any;
  isOpen: boolean;
}

export const useDisclosure = (): useDisclosureProps => {
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);
  return { isOpen, onOpen, onClose };
};
