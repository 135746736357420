import React, { Dispatch, SetStateAction } from 'react';
import './MapLegends.scss';
import { t } from 'helpers/i18n';
import { Card, Collapse, Flex, Switch } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ILegend } from 'interfaces';
import { formatStringNumber } from 'helpers/string';
import classNames from 'classnames';
import { LegendPlacement } from '../constants';
import { useFlagsupContext } from 'contexts';

interface MapLegendsProps {
  children?: React.ReactNode;
  title?: string;
  data?: ILegend[];
  placement?: LegendPlacement;
  showARREligibleSwitch?: boolean;
  viewARREligible?: boolean;
  setViewARREligible?: Dispatch<SetStateAction<boolean>>;
}

const LEGEND_KEY = 1;

const MapLegends: React.FC<MapLegendsProps> = ({
  title,
  data,
  placement = 'left',
  showARREligibleSwitch,
  viewARREligible,
  setViewARREligible,
}) => {
  const { featureFlagsData } = useFlagsupContext();
  const isCAR2619Enabled = featureFlagsData.CAR_2619?.enabled;

  const items = [
    {
      label: (
        <>
          {title}
          {!isCAR2619Enabled && showARREligibleSwitch && (
            <Card
              className={classNames('map-legends-arr-switch', {
                'align-right': placement === 'right',
              })}
              onClick={e => {
                e.stopPropagation();
              }}
              bordered={false}
            >
              <Flex gap={8} align="center">
                <span>{t('ARREligible')}</span>
                <Switch
                  checked={viewARREligible}
                  onChange={setViewARREligible}
                  size="small"
                />
              </Flex>
            </Card>
          )}
        </>
      ),
      key: LEGEND_KEY,
      children: (
        <>
          <div className={'map-legends-item map-legends-item-title'}>
            <div className={'map-legends-item-left'}>{title}</div>
            <div className={'map-legends-item-ha'}>{t('TotalArea(Ha)')}</div>
          </div>
          {data?.map((legend, index) => (
            <div className={'map-legends-item'} key={index}>
              <div className={'map-legends-item-left'}>
                <div
                  className={'map-legends-item-color'}
                  style={{ backgroundColor: legend.colorCode }}
                />
                <div className={'map-legends-item-label'}>{legend.label}</div>
              </div>
              <div className={'map-legends-item-ha'}>
                {formatStringNumber(parseFloat(legend.area ?? '0'))}
              </div>
            </div>
          ))}
        </>
      ),
    },
  ];

  return (
    <div
      className={classNames('map-legends', {
        'align-right': placement === 'right',
      })}
    >
      <Collapse
        expandIcon={({ isActive }) => (
          <DownOutlined rotate={isActive ? -180 : 0} />
        )}
        defaultActiveKey={[LEGEND_KEY]}
        expandIconPosition="end"
        items={items}
      />
    </div>
  );
};

export { MapLegends };
