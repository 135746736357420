import { CommentOutlined, LikeOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Form, Input, List, Space, Typography } from 'antd';
import { Store } from 'antd/es/form/interface';
import classNames from 'classnames';
import { PAGING_PAGE_SIZE_ALL } from 'constants/pagination';
import { dateTimeHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { ActivityComment } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { activityServices } from 'services';

interface FinancialPlanCommentProps {
  activityId: number;
}

const FinancialPlanComment: React.FC<FinancialPlanCommentProps> = ({
  activityId,
}) => {
  const [totalLikes, setTotalLikes] = useState<number>(0);
  const [comments, setComments] = useState<ActivityComment[]>([]);
  const [showCommentBlock, setShowCommentBlock] = useState<boolean>(false);
  const [isSubmittingComment, setIsSubmittingComment] = useState<boolean>(
    false
  );
  const [hasLiked, setHasLiked] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchLikesAndComments();
  }, []);

  const fetchLikesAndComments = async () => {
    const {
      totalLike,
      comments,
    } = await activityServices.getActivityLikesAndComments({
      activityId,
      ...PAGING_PAGE_SIZE_ALL,
    });
    setTotalLikes(totalLike);
    setComments(comments);
  };

  const handleCommentSubmit = async ({ comment: newComment }: Store) => {
    try {
      setIsSubmittingComment(true);
      const comment = {
        activityId,
        content: newComment.trim(),
      };
      await activityServices.likeOrCommentActivity({
        ...comment,
        type: 'COMMENT',
      });
      fetchLikesAndComments();
      form.resetFields();
    } finally {
      setIsSubmittingComment(false);
    }
  };

  const toggleCommentBlock = () => {
    setShowCommentBlock(!showCommentBlock);
  };

  const handleLike = async () => {
    activityServices.likeOrCommentActivity({ activityId, type: 'LIKE' });
    setTotalLikes(prevTotalLikes => prevTotalLikes + 1);
    setHasLiked(true);
  };

  return (
    <>
      <Space className="mb-half">
        <Button
          icon={<LikeOutlined />}
          type="text"
          onClick={() => handleLike()}
          className={classNames({ 'text-link': hasLiked })}
        >
          {!!totalLikes && <span>{totalLikes}</span>}
        </Button>
        <Button
          icon={<CommentOutlined />}
          onClick={toggleCommentBlock}
          type="text"
        >
          {t('Comment')}
        </Button>
      </Space>
      {showCommentBlock && (
        <Form
          onFinish={handleCommentSubmit}
          form={form}
          className="mb-half"
          disabled={isSubmittingComment}
        >
          <Form.Item name="comment" noStyle>
            <Input.TextArea placeholder={t('PleaseEnterComment')} />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {() => (
              <div className="text-right">
                <Button
                  disabled={!form.getFieldValue('comment')?.trim()}
                  htmlType="submit"
                  type="primary"
                  icon={<SendOutlined />}
                  loading={isSubmittingComment}
                  className="mt-half"
                >
                  {t('Send')}
                </Button>
              </div>
            )}
          </Form.Item>
        </Form>
      )}
      {!!comments.length && (
        <List
          itemLayout="vertical"
          dataSource={comments}
          renderItem={item => (
            <List.Item>
              <Typography.Text type="secondary" className="text-small">
                {dateTimeHelpers.formatUnixTimestamp(item.createdAt)}
              </Typography.Text>
              <div>{item.content}</div>
            </List.Item>
          )}
        />
      )}
    </>
  );
};

export default FinancialPlanComment;
