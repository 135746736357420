/** Third party libs * */
import React, { useEffect, useState } from 'react';
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  useMapEvents,
} from 'react-leaflet';

/** Local libs * */

/** Components * */
import { BaseItem, BaseItemProps } from '../BaseItem';
import { ConfigProvider, Form } from 'antd';

/** Styles * */

/** Interfaces, enum... * */

interface LocationProps extends Omit<BaseItemProps, 'form'> {}
interface IPosition {
  latitude: number;
  longitude: number;
}

/** Variables * */
const LEAFLET = {
  ATTRIBUTION:
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  URL: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
};
const ZOOM_OPTIONS = {
  MAX_NATIVE_ZOOM: 19,
  MAX_ZOOM: 30,
};
const LOCATION_PEAKVIEW = {
  latitude: 21.019322132927282,
  longitude: 105.82364916801454,
};

/** ------------------------- * */
const Location: React.FC<LocationProps> = ({
  item,
  mode,
  disabled,
  children,
  ...props
}) => {
  /** States * */
  const [position, setPosition] = useState<IPosition | undefined>();

  /** Hooks * */
  const { componentDisabled } = ConfigProvider.useConfig();
  const form = Form.useFormInstance();

  /** Variables * */
  const showMarker = mode === 'CREATE' || !!position;

  /** Effects * */
  useEffect(() => {
    setPosition(LOCATION_PEAKVIEW);
    if (item.fieldName) {
      form.setFieldValue(
        item.fieldName,
        item.value || JSON.stringify(LOCATION_PEAKVIEW)
      );
      try {
        if (item.value) {
          const obj = JSON.parse(item.value);
          if (obj.latitude && obj.longitude) {
            setPosition(obj);
          }
        } else {
        }
      } catch (e) {
      } finally {
      }
    }
  }, [item.value]);

  /** Functions, Events, Actions... * */
  const updatePosition = (position: IPosition) => {
    setPosition(position);

    if (item.fieldName)
      form.setFieldValue(item.fieldName, JSON.stringify(position));
  };

  const RecenterAutomatically = (position: Partial<IPosition>) => {
    const map = useMap();

    useMapEvents({
      click: e => {
        if (disabled || componentDisabled) return;

        updatePosition({
          latitude: e.latlng.lat,
          longitude: e.latlng.lng,
        });
      },
    });

    useEffect(() => {
      if (position.latitude && position.longitude) {
        map.setView([position.latitude, position.longitude]);
      }
    }, [position.latitude, position.longitude]);

    return null;
  };

  /** Elements * */
  return (
    <BaseItem
      item={item}
      form={form}
      disabled={disabled}
      mode={mode}
      {...props}
    >
      <div>
        {children}
        {position && (
          <MapContainer
            center={[position.latitude, position.longitude]}
            style={{ height: 300 }}
            zoom={13}
            scrollWheelZoom={false}
            fadeAnimation={true}
            markerZoomAnimation={true}
          >
            <TileLayer
              attribution={LEAFLET.ATTRIBUTION}
              url={LEAFLET.URL}
              maxNativeZoom={ZOOM_OPTIONS.MAX_NATIVE_ZOOM}
              maxZoom={ZOOM_OPTIONS.MAX_ZOOM}
            />
            {showMarker && (
              <Marker position={[position.latitude, position.longitude]} />
            )}
            <RecenterAutomatically
              latitude={position.latitude}
              longitude={position.longitude}
            />
          </MapContainer>
        )}
      </div>
    </BaseItem>
  );
};

export default Location;
