import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constants/pagination';
import { IContract, IGetContractsParams } from 'interfaces';
import { useEffect, useState } from 'react';
import { contractServices } from 'services';
import { isEmpty } from 'lodash';
import { useThrottledCallback } from 'use-debounce';
import { useProjectContext } from 'contexts';

const useContracts = () => {
  const { projectId } = useProjectContext();
  const DEFAULT_PARAMS = {
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    projectId,
  };
  const [contracts, setContracts] = useState<IContract[]>([]);
  const [params, setParams] = useState<IGetContractsParams>(DEFAULT_PARAMS);
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoadedAll, setIsLoadedAll] = useState<boolean>(false);
  const [selectedContract, setSelectedContract] = useState<IContract>();

  const onChangeKeyword = (keyword?: string) => {
    setContracts([]);
    setParams({
      ...DEFAULT_PARAMS,
      keyword: isEmpty(keyword) ? undefined : keyword,
    });
  };

  const getContracts = useThrottledCallback(async () => {
    try {
      setLoading(true);
      const {
        contracts: newContracts,
        pagination,
      } = await contractServices.getContracts({
        ...params,
      });
      setContracts(contracts => [...contracts, ...(newContracts || [])]);
      if (pagination.page === DEFAULT_PAGE) {
        setSelectedContract(newContracts?.[0]);
      }
      setIsLoadedAll(
        Number(pagination.page) * Number(pagination.pageSize) >=
          Number(pagination.total)
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, 1000);

  const getMoreContracts = async () => {
    if (!loading && !isLoadedAll) {
      setParams({ ...params, page: params.page + 1 });
    }
  };

  useEffect(() => {
    getContracts();
  }, [params]);

  return {
    contracts,
    loading,
    selectedContract,
    onChangeKeyword,
    getMoreContracts,
    setSelectedContract,
  };
};

export default {
  useContracts,
};
