import {
  CarryOutOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  FileAddOutlined,
  GroupOutlined,
  IdcardOutlined,
  MailOutlined,
  SolutionOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { resourceConstants } from 'constants/index';
import Community from 'containers/CREMA/Community';
import CREMAFormation from 'containers/CREMA/Formation';
import CremaMeetingRecord from 'containers/CREMA/Formation/MeetingRecord';
import FPICCreate from 'containers/FPIC/Create';
import FPICList from 'containers/FPIC/List';
import FarmLandsManagement from 'containers/FarmerManagement/FarmLandsManagement';
import FarmerEnrolment from 'containers/FarmerManagement/FarmerEnrolment';
import ProjectDetailMapV2 from 'containers/Project/ProjectDetailMapV2';
import ProjectMap from 'containers/Project/ProjectMap';
import { QuarterlyReportContainer } from 'containers/Report/QuarteryReports';
import { roleHelpers } from 'helpers';
import { permission } from 'helpers/common';
import { t } from 'helpers/i18n';
import { withFeatureFlag } from 'hocs/withFeatureFlag';
import { lazy } from 'react';
import { accountManagementRoutes, orgChartRoutes } from './common';
import Dashboards from 'containers/Report/Dashboards';

/**
 * @TODO: Refactor report to 1 file and using Compound Components
 */
const FarmerManagementReport = lazy(() =>
  import('containers/Report/FarmerManagementReport')
);
const FpicManagementReport = lazy(() =>
  import('containers/Report/FpicManagementReport')
);
const FpicDashboardReport = lazy(() =>
  import('containers/Report/FpicDashboardReport')
);
const CremaKpiReport = lazy(() => import('containers/Report/CremaKpiReport'));
const PlantingKpiReport = lazy(() =>
  import('containers/Report/PlantingKpiReport')
);

const TicketDashboards = lazy(() =>
  import('containers/Tickets/TicketDashboards')
);
const CreateTicket = lazy(() => import('containers/Tickets/CreateTicket'));
const IssueTypes = lazy(() => import('containers/Tickets/IssueTypes'));
const TicketList = lazy(() => import('containers/Tickets/TicketList'));
const FeasibilityStudy = lazy(() =>
  import('containers/Report/FeasibilityStudy')
);
const CarbonPotentialReport = lazy(() =>
  import('containers/Report/FeasibilityStudy/CarbonPotentialReport')
);
const ProjectConceptDocument = lazy(() =>
  import('containers/Report/ProjectConceptDocument')
);
const ProjectConceptPresentation = lazy(() =>
  import('containers/Report/ProjectConceptPresentation')
);
const CarbonAssessment = lazy(() =>
  import('containers/Report/CarbonAssessment')
);
const Biodiversity = lazy(() => import('containers/Report/Biodiversity'));
const InProgressPage = lazy(() => import('containers/shared/InProgressPage'));
const SubmissionList = lazy(() =>
  import('containers/Submission/SubmissionList')
);
const SubmissionListV2 = lazy(() =>
  import('containers/Submission/SubmissionListV2')
);
const ConsultantReportPageContainer = lazy(() =>
  import('containers/Report/FeasibilityStudy/ConsultantReportContainer')
);
const ConsultantReportPage = lazy(() =>
  import('containers/Report/FeasibilityStudy/ConsultantReport')
);
const InterventionI3A1 = lazy(() =>
  import('containers/Report/FeasibilityStudy/InterventionI3A1')
);
const FirePlanAndTraining = lazy(() =>
  import('containers/Report/FeasibilityStudy/FirePlanAndTraining')
);
const FarmerManagementConsultantReport = lazy(() =>
  import(
    'containers/FarmerManagement/ConsultantReport/ConsultantReportContainer'
  )
);
const EligibleIneligibleCatalogue = lazy(() =>
  import(
    'containers/FarmerManagement/ConsultantReport/EligibleIneligleCatalogue'
  )
);
const QuarterlyReport = lazy(() =>
  import('containers/Report/QuarteryReports/QuarterlyReport')
);
const FarmEligibleReview = lazy(() => import('containers/FarmEligibleReview'));

const { APPS, RESOURCES, ACTIONS } = resourceConstants;
const { PROJECT_MANAGER_TEKBONE, SUPERSET_PUBLIC } = APPS;
const {
  CARBON_POTENTIAL_REPORT,
  FS_REPORT,
  CONSULTANT_REPORT,
  PROJECT_BOUNDARIES,
  CARBON_ASSESSMENT,
  RISK_ASSESSMENT,
  CONTROL_PLOTS,
  CARBON_BASELINE,
  PERFORMANCE_BENCHMARK,
  VERRA_PDD,
  FPIC_MANAGEMENT,
  MEETING_RECORDS,
  TEMPLATE_MANAGEMENT,
  FPIC_DASHBOARDS,
  CREMA_FORMATION,
  CREMA_OPERATION,
  CREMA_REPORTS,
  FARMER_MANAGEMENT,
  FARMER_ENROLLMENT,
  FARM_LAND_MANAGEMENT,
  FARM_ELIGIBLE_REVIEW,
  FARMER_PROFILES,
  LPR,
  BENEFIT_MANAGEMENT,
  PAYMENT_SERVICES,
  FINANCIAL_REPORTING,
  CARBON,
  BIODIVERSITY,
  ME_COMMUNITY,
  LANDSCAPE_GOVERNANCE,
  ME_REPORTS,
  TICKETS,
  VIEW_PLANTING_KPI,
  SUBMISSION,
  FIRE_PLAN_AND_TRAINING,
  QUARTERLY_REPORTS,
  FARMER_MANAGEMENT_CONSULTANT_REPORT,
  DASHBOARD,
} = RESOURCES;
const { READ, UPDATE } = ACTIONS;
const { tekboneReadPermission } = roleHelpers;

// Route configs for AJA
export const ajaRoutes = [
  {
    exact: true,
    path: '/dashboards',
    name: t('Dashboards'),
    icon: DashboardOutlined,
    component: Dashboards,
    permissions: [tekboneReadPermission(DASHBOARD)],
  },
  {
    exact: true,
    path: '/feasibility-study',
    name: t('FeasibilityStudy'),
    icon: FileAddOutlined,
    permissions: [
      tekboneReadPermission(FS_REPORT),
      tekboneReadPermission(CONSULTANT_REPORT),
      tekboneReadPermission(CARBON_POTENTIAL_REPORT),
    ],
  },
  {
    exact: true,
    path: '/carbon-potential-report',
    name: t('CarbonPotentialReport'),
    component: CarbonPotentialReport,
    permissions: [tekboneReadPermission(CARBON_POTENTIAL_REPORT)],
  },
  {
    exact: true,
    path: '/feasibility-study-report',
    name: t('FeasibilityStudyReport'),
    component: FeasibilityStudy,
    permissions: [tekboneReadPermission(FS_REPORT)],
  },
  {
    exact: true,
    path: '/consultant-reports',
    name: t('ConsultantReports'),
    component: ConsultantReportPageContainer,
    permissions: [
      tekboneReadPermission(CONSULTANT_REPORT),
      tekboneReadPermission(FIRE_PLAN_AND_TRAINING),
    ],
  },
  {
    exact: true,
    path: '/consultant-reports/luluc',
    name: t('LULUC'),
    component: ConsultantReportPage,
    permissions: [tekboneReadPermission(CONSULTANT_REPORT)],
  },
  {
    exact: true,
    path: '/consultant-reports/intervention-i3a1',
    name: t('InterventionI3A1'),
    component: InterventionI3A1,
    permissions: [tekboneReadPermission(CONSULTANT_REPORT)],
  },
  {
    exact: true,
    path: '/consultant-reports/fire-plan',
    name: t('FirePlan'),
    component: FirePlanAndTraining,
    permissions: [tekboneReadPermission(CONSULTANT_REPORT)],
  },
  {
    exact: true,
    path: '/project-concept-document',
    name: t('ProjectConceptDocument'),
    component: ProjectConceptDocument,
  },
  {
    exact: true,
    path: '/project-concept-presentation',
    name: t('ProjectConceptPresentation'),
    component: ProjectConceptPresentation,
  },
  {
    exact: true,
    path: '/project-design',
    name: t('ProjectDesign'),
    icon: MailOutlined,
    permissions: [
      tekboneReadPermission(PROJECT_BOUNDARIES),
      tekboneReadPermission(CARBON_ASSESSMENT),
      tekboneReadPermission(RISK_ASSESSMENT),
      tekboneReadPermission(CONTROL_PLOTS),
      tekboneReadPermission(CARBON_BASELINE),
      tekboneReadPermission(PERFORMANCE_BENCHMARK),
      tekboneReadPermission(VERRA_PDD),
    ],
  },
  {
    exact: true,
    path: '/project-boundaries',
    name: t('ProjectBoundaries'),
    component: withFeatureFlag(ProjectMap, ProjectDetailMapV2, 'CAR_418'),
    permissions: [tekboneReadPermission(PROJECT_BOUNDARIES)],
  },
  {
    exact: true,
    path: '/carbon-assessment',
    name: t('CarbonAssessment'),
    component: CarbonAssessment,
    permissions: [tekboneReadPermission(CARBON_ASSESSMENT)],
  },
  {
    exact: true,
    path: '/risk-assessment',
    name: t('RiskAssessment'),
    component: InProgressPage,
    permissions: [tekboneReadPermission(RISK_ASSESSMENT)],
  },
  {
    exact: true,
    path: '/control-plots',
    name: t('ControlPlots'),
    component: InProgressPage,
    permissions: [tekboneReadPermission(CONTROL_PLOTS)],
  },
  {
    exact: true,
    path: '/carbon-baseline',
    name: t('CarbonBaseline'),
    component: InProgressPage,
    permissions: [tekboneReadPermission(CARBON_BASELINE)],
  },
  {
    exact: true,
    path: '/performance-benchmark',
    name: t('PerformanceBenchmark'),
    component: InProgressPage,
    permissions: [tekboneReadPermission(PERFORMANCE_BENCHMARK)],
  },
  {
    exact: true,
    path: '/verra-pdd',
    name: t('VerraPdd'),
    component: InProgressPage,
    permissions: [tekboneReadPermission(VERRA_PDD)],
  },
  {
    exact: true,
    path: '/fpic-management',
    name: t('FpicManagement'),
    icon: IdcardOutlined,
    redirectTo: '/fpic-management/main',
    permissions: [
      tekboneReadPermission(FPIC_MANAGEMENT),
      tekboneReadPermission(MEETING_RECORDS),
      tekboneReadPermission(TEMPLATE_MANAGEMENT),
      tekboneReadPermission(FPIC_DASHBOARDS),
    ],
  },
  {
    exact: true,
    path: '/fpic-management/main',
    name: t('Report'),
    component: FpicManagementReport,
    permissions: [tekboneReadPermission(FPIC_MANAGEMENT)],
  },
  {
    exact: true,
    path: '/fpic-management/meeting-records',
    name: t('MeetingRecords'),
    component: FPICList,
    hideTitle: true,
    permissions: [tekboneReadPermission(MEETING_RECORDS)],
  },
  {
    exact: true,
    path: '/fpic-management/meeting-records/create',
    name: t('CreateForm'),
    component: FPICCreate,
    permissions: [permission(PROJECT_MANAGER_TEKBONE, MEETING_RECORDS, UPDATE)],
  },
  {
    exact: true,
    path: '/fpic-management/template-management',
    name: t('TemplateManagement'),
    component: InProgressPage,
    permissions: [tekboneReadPermission(TEMPLATE_MANAGEMENT)],
  },
  {
    exact: true,
    path: '/fpic-management/fpic-dashboards',
    name: t('FpicDashboards'),
    component: FpicDashboardReport,
    permissions: [tekboneReadPermission(FPIC_DASHBOARDS)],
  },
  {
    exact: true,
    path: '/crema',
    name: t('Crema'),
    icon: GroupOutlined,
  },
  {
    exact: true,
    path: '/crema-formation',
    component: CREMAFormation,
    name: t('CremaFormation'),
    permissions: [tekboneReadPermission(CREMA_FORMATION)],
    hideBreadcrumb: true,
  },
  {
    exact: true,
    path: '/crema-formation/meeting-records',
    component: CremaMeetingRecord,
    name: t('MeetingRecords'),
    permissions: [tekboneReadPermission(CREMA_FORMATION)],
  },
  {
    exact: true,
    path: '/crema/crema-operation',
    name: t('CremaOperation'),
    component: InProgressPage,
    permissions: [tekboneReadPermission(CREMA_OPERATION)],
  },
  {
    exact: true,
    path: '/crema/crema-kpi-report',
    name: t('CremaKPIReport'),
    component: CremaKpiReport,
    permissions: [tekboneReadPermission(CREMA_REPORTS)],
  },
  {
    exact: true,
    path: '/farmer-management',
    name: t('FarmerManagement'),
    icon: TeamOutlined,
    redirectTo: '/farmer-management/main',
    permissions: [
      tekboneReadPermission(FARMER_MANAGEMENT),
      tekboneReadPermission(FARMER_ENROLLMENT),
      tekboneReadPermission(FARM_LAND_MANAGEMENT),
      tekboneReadPermission(FARMER_PROFILES),
      tekboneReadPermission(LPR),
    ],
  },
  {
    exact: true,
    path: '/farmer-management/main',
    name: t('Report'),
    component: FarmerManagementReport,
    permissions: [tekboneReadPermission(FARMER_MANAGEMENT)],
  },
  {
    exact: true,
    path: '/farmer-management/farmer-enrollment',
    name: t('FarmerEnrollment'),
    component: FarmerEnrolment,
    permissions: [tekboneReadPermission(FARMER_ENROLLMENT)],
  },
  {
    exact: true,
    path: '/farmer-management/farm-land-management',
    name: t('FarmLandManagement'),
    component: FarmLandsManagement,
    permissions: [tekboneReadPermission(FARM_LAND_MANAGEMENT)],
  },
  {
    exact: true,
    path: '/farmer-management/farm-eligible-review',
    name: t('FarmEligibleReview'),
    component: FarmEligibleReview,
    permissions: [tekboneReadPermission(FARM_ELIGIBLE_REVIEW)],
  },
  {
    exact: true,
    path: '/farmer-management/farmer-profiles',
    name: t('FarmerProfiles'),
    component: InProgressPage,
    permissions: [tekboneReadPermission(FARMER_PROFILES)],
  },
  {
    exact: true,
    path: '/farmer-management/longitudinal-planting-records',
    name: t('LongitudinalPlantingRecords'),
    component: InProgressPage,
    permissions: [tekboneReadPermission(LPR)],
  },
  {
    exact: true,
    path: '/farmer-management/consultant-report',
    name: t('ConsultantReports'),
    component: FarmerManagementConsultantReport,
    permissions: [tekboneReadPermission(FARMER_MANAGEMENT_CONSULTANT_REPORT)],
  },
  {
    exact: true,
    path: '/farmer-management/consultant-report/eligible-ineligible-catalogue',
    name: t('ConsultantReports'),
    component: EligibleIneligibleCatalogue,
    permissions: [tekboneReadPermission(FARMER_MANAGEMENT_CONSULTANT_REPORT)],
  },
  {
    exact: true,
    path: '/benefit',
    name: t('BenefitManagement'),
    icon: CreditCardOutlined,
    permissions: [
      tekboneReadPermission(BENEFIT_MANAGEMENT),
      tekboneReadPermission(PAYMENT_SERVICES),
      tekboneReadPermission(FINANCIAL_REPORTING),
    ],
  },
  {
    exact: true,
    path: '/benefit-management',
    name: t('BenefitManagement'),
    component: InProgressPage,
    permissions: [tekboneReadPermission(BENEFIT_MANAGEMENT)],
  },
  {
    exact: true,
    path: '/payment-services',
    name: t('PaymentServices'),
    component: InProgressPage,
    permissions: [tekboneReadPermission(PAYMENT_SERVICES)],
  },
  {
    exact: true,
    path: '/financial-reporting',
    name: t('FinancialReporting'),
    component: InProgressPage,
    permissions: [tekboneReadPermission(FINANCIAL_REPORTING)],
  },
  {
    exact: true,
    path: '/monitoring-evaluation',
    name: t('MonitoringEvaluation'),
    icon: CarryOutOutlined,
    permissions: [
      tekboneReadPermission(CARBON),
      tekboneReadPermission(BIODIVERSITY),
      tekboneReadPermission(ME_COMMUNITY),
      tekboneReadPermission(LANDSCAPE_GOVERNANCE),
      tekboneReadPermission(ME_REPORTS),
      tekboneReadPermission(QUARTERLY_REPORTS),
    ],
  },
  {
    exact: true,
    path: '/carbon',
    name: t('Carbon'),
    component: InProgressPage,
    permissions: [tekboneReadPermission(CARBON)],
  },
  {
    exact: true,
    path: '/submissions',
    name: t('TreeSubmissions'),
    component: withFeatureFlag(SubmissionList, SubmissionListV2, 'CAR_2356'),
    permissions: [tekboneReadPermission(SUBMISSION)],
  },
  {
    exact: true,
    path: '/biodiversity',
    name: t('Biodiversity'),
    component: Biodiversity,
    permissions: [tekboneReadPermission(BIODIVERSITY)],
  },
  {
    exact: true,
    path: '/community',
    name: t('Community'),
    component: withFeatureFlag(InProgressPage, Community, 'CAR_2311'),
    permissions: [tekboneReadPermission(ME_COMMUNITY)],
  },
  {
    exact: true,
    path: '/landscape-governance',
    name: t('LandscapeGovernance'),
    component: InProgressPage,
    permissions: [tekboneReadPermission(LANDSCAPE_GOVERNANCE)],
  },
  {
    exact: true,
    path: '/me-reports',
    name: t('MeReports'),
    component: InProgressPage,
    permissions: [tekboneReadPermission(ME_REPORTS)],
  },
  {
    exact: true,
    path: '/quarterly-reports',
    name: t('QuarterlyReports'),
    component: QuarterlyReportContainer,
    permissions: [tekboneReadPermission(QUARTERLY_REPORTS)],
  },
  {
    exact: true,
    path: '/quarterly-reports/:quarter',
    name: t('Report'),
    useParamName: 'quarter',
    component: QuarterlyReport,
    permissions: [tekboneReadPermission(QUARTERLY_REPORTS)],
  },
  {
    exact: true,
    path: '/planting-kpi',
    name: t('PlantingKPIReport'),
    component: PlantingKpiReport,
    permissions: [permission(SUPERSET_PUBLIC, VIEW_PLANTING_KPI, READ)],
  },
  {
    exact: true,
    path: '/feedback-management',
    name: t('FeedbackManagement'),
    icon: SolutionOutlined,
    permissions: [tekboneReadPermission(TICKETS)],
  },
  {
    exact: true,
    path: '/feedback-management/dashboards',
    name: t('Dashboards'),
    component: TicketDashboards,
    permissions: [tekboneReadPermission(TICKETS)],
  },
  {
    exact: true,
    path: '/feedback-management/tickets',
    name: t('Tickets'),
    permissions: [tekboneReadPermission(TICKETS)],
  },
  {
    exact: true,
    path: '/feedback-management/tickets/list',
    name: t('TicketList'),
    component: TicketList,
    permissions: [tekboneReadPermission(TICKETS)],
  },
  {
    exact: true,
    path: '/feedback-management/tickets/create',
    name: t('CreateTicket'),
    component: CreateTicket,
    permissions: [tekboneReadPermission(TICKETS)],
  },
  {
    exact: true,
    path: '/feedback-management/tickets/issue-types',
    name: t('IssueTypes'),
    component: IssueTypes,
    permissions: [tekboneReadPermission(TICKETS)],
  },
  ...orgChartRoutes,
  ...accountManagementRoutes,
];
