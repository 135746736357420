import { ILatLng } from 'interfaces';
import React from 'react';
import { useMapEvents } from 'react-leaflet';
import { useDispatch } from 'react-redux';
import { addSamplingPlot } from 'redux/features/samplingPlot';
import { AppDispatch } from 'redux/store';

interface IManualAddSamplingMapEventsProps {}

const ManualAddSamplingMapEvents: React.FC<IManualAddSamplingMapEventsProps> = ({}) => {
  const dispatch: AppDispatch = useDispatch();

  useMapEvents({
    click: event => {
      const { lat, lng } = event.latlng;
      dispatch(addSamplingPlot({ lat, lng } as ILatLng));
    },
  });
  return <></>;
};

export default ManualAddSamplingMapEvents;
