import { Select, SelectProps } from 'antd';
import { ID_DEFAULT } from 'constants/common';
import { filterSelect } from 'helpers/common';
import { t, tSelectPlaceholder } from 'helpers/i18n';
import { activityHooks } from 'hooks';
import { useMemo } from 'react';

const { useActivityCategories } = activityHooks;

const ActivityCategorySelect: React.FC<SelectProps<number>> = props => {
  const { activityCategories, loading } = useActivityCategories();
  const categoryOptions = useMemo(() => {
    const options = [{ label: t('All'), value: ID_DEFAULT }];
    activityCategories.forEach(item =>
      options.push({
        label: item.name,
        value: item.id,
      })
    );

    return options;
  }, [activityCategories]);

  return (
    <Select
      showSearch
      defaultValue={ID_DEFAULT}
      options={categoryOptions}
      loading={loading}
      filterOption={filterSelect}
      placeholder={tSelectPlaceholder('ActivityCategories')}
      {...props}
    />
  );
};

export default ActivityCategorySelect;
