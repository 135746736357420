import { Card, List } from 'antd';
import React from 'react';
import { IMeetingMinutes } from 'interfaces';
import { MeetingRecordsItem } from './MeetingRecordsItem';
import './MeetingRecordsList.scss';

interface IMeetingRecordsListProps {
  meetingMinutes: IMeetingMinutes[];
  loading: boolean;
}

const MeetingRecordsList: React.FC<IMeetingRecordsListProps> = ({
  meetingMinutes,
  loading,
}) => {
  return (
    <Card className="mt-half meeting-records-list">
      <List
        dataSource={meetingMinutes}
        renderItem={meetingMinute => (
          <MeetingRecordsItem meetingMinute={meetingMinute} />
        )}
        loading={loading}
      />
    </Card>
  );
};

export { MeetingRecordsList };
