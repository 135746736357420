import React, { Dispatch, useEffect, useState } from 'react';
import { Checkbox, Table, TableColumnsType } from 'antd';
import { t } from 'helpers/i18n';
import { userManagementServices } from 'services';
import {
  IOperationObject,
  IOperationPermission,
  OperationPermissionType,
} from 'interfaces';

interface PermissionsTableProps {
  value?: number[];
  onChange?: Dispatch<number[]>;
}

const PermissionsTable: React.FC<PermissionsTableProps> = ({
  value,
  onChange,
}) => {
  const [operationObjects, setOperationObjects] = useState<IOperationObject[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getOperationPermissions = async () => {
      try {
        setLoading(true);
        const data = await userManagementServices.getListOperationPermissions();
        setOperationObjects(data.operationObjects);
      } finally {
        setLoading(false);
      }
    };

    getOperationPermissions();
  }, []);

  const renderCheckbox = (
    permissions: IOperationPermission[],
    type: OperationPermissionType
  ) => {
    const permission = permissions.find(permission => permission.type === type);
    return (
      <Checkbox
        disabled={!permission}
        checked={permission && value?.includes(permission?.id)}
        onChange={e => {
          if (!permission) return;
          if (e.target.checked) {
            onChange?.(value ? [...value, permission.id] : [permission.id]);
          } else {
            onChange?.(value ? value?.filter(id => id !== permission.id) : []);
          }
        }}
      />
    );
  };

  const handleSelectAll = (record: IOperationObject, checked: boolean) => {
    const permissionIds = record.permissions.map(permission => permission.id);
    if (checked) {
      const addValue = permissionIds.filter(id => !value?.includes(id));
      onChange?.(value ? [...value, ...addValue] : addValue);
    } else {
      const newValue = value?.filter(id => !permissionIds.includes(id));
      onChange?.(newValue || []);
    }
  };

  const columns: TableColumnsType<IOperationObject> = [
    {
      title: t('Object'),
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: t('Permissions'),
      children: [
        {
          title: t('SelectAll'),
          key: 'select-all',
          render: (_, record) => (
            <Checkbox
              disabled={!record.permissions.length}
              checked={
                !!record.permissions.length &&
                record.permissions.every(permission =>
                  value?.includes(permission.id)
                )
              }
              onChange={e => handleSelectAll(record, e.target.checked)}
            />
          ),
          className: 'text-center',
        },
        {
          title: t('Read'),
          key: 'read',
          dataIndex: 'permissions',
          render: permissions => renderCheckbox(permissions, 'READ'),
          className: 'text-center',
        },
        {
          title: t('Create'),
          key: 'create',
          dataIndex: 'permissions',
          render: permissions => renderCheckbox(permissions, 'CREATE'),
          className: 'text-center',
        },
        {
          title: t('EditOwn'),
          key: 'edit-own',
          dataIndex: 'permissions',
          render: permissions => renderCheckbox(permissions, 'EDIT_OWN'),
          className: 'text-center',
        },
        {
          title: t('EditAll'),
          key: 'edit-all',
          dataIndex: 'permissions',
          render: permissions => renderCheckbox(permissions, 'EDIT_ALL'),
          className: 'text-center',
        },
        {
          title: t('DeleteOwn'),
          key: 'delete-own',
          dataIndex: 'permissions',
          render: permissions => renderCheckbox(permissions, 'DELETE_OWN'),
          className: 'text-center',
        },
        {
          title: t('DeleteAll'),
          key: 'delete-all',
          dataIndex: 'permissions',
          render: permissions => renderCheckbox(permissions, 'DELETE_ALL'),
          className: 'text-center',
        },
        {
          title: t('ApproveReject'),
          key: 'approval',
          dataIndex: 'permissions',
          render: permissions => renderCheckbox(permissions, 'APPROVAL'),
          className: 'text-center',
        },
        {
          title: t('Download'),
          key: 'download',
          dataIndex: 'permissions',
          render: permissions => renderCheckbox(permissions, 'DOWNLOAD'),
          className: 'text-center',
        },
      ],
    },
  ];

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={operationObjects}
      bordered
      pagination={false}
      rowKey="id"
    />
  );
};

export default PermissionsTable;
