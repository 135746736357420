export const CREMA_PAGINATION_DEFAULT = {
  page: 1,
  pageSize: 5,
  total: 0,
};

export const PAGE_SIZE_ALL = 1000;

export const DOCUMENTATION_FILE_SIZE = 100;

export const DOCUMENTATION_FILE_TYPES = [
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'csv',
];

export const IS_CEC_MEMBER_CODE = 40901;
