// Trigger CI/CD
import {
  QueryClient,
  QueryClientConfig,
  QueryClientProvider,
} from '@tanstack/react-query';
import { Spin } from 'antd';
import PrivateRoute from 'components/shared/PrivateRoute';
import { commonConstants } from 'constants/index';
import AppLayout from 'containers/AppLayout';
import Page403 from 'containers/shared/Page403';
import Page404 from 'containers/shared/Page404';
import Page500 from 'containers/shared/Page500';
import { browserHistory } from 'helpers';
import { getMetaData } from 'helpers/common';
import { useCtrlF5PressEvent } from 'hooks/keyboard';
import React, { useEffect, useState } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import ReactTracker from 'react-tracker-teko';
import TekoID from 'teko-oauth2';
import { VolunteerCacheStorage } from 'utils/cacheStorage';
import { commonHooks } from 'hooks';
import './App.css';
import './App.less';
import './App.scss';

const { tracker } = window.config;
const { trackerAppId } = window.appConfig;
const { IAM_SCOPES } = commonConstants;
const { useIam } = commonHooks;

const reactTracker = new ReactTracker({
  host: (trackerAppId && tracker.host) || '',
  urlServeJsFile: (trackerAppId && tracker.jsFile) || '',
  appId: trackerAppId || '',
});
const defaultQueryConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 30,
      retry: false,
    },
  },
};

const App: React.FC = () => {
  const queryClient = new QueryClient(defaultQueryConfig);
  const [loading, setLoading] = useState(true);
  const { getProjectInfo } = useIam();

  useCtrlF5PressEvent(
    VolunteerCacheStorage.deleteAllCache,
    VolunteerCacheStorage
  );

  useEffect(() => {
    const { clientId, oauthDomain } = getProjectInfo();

    TekoID.init({
      clientId,
      scopes: IAM_SCOPES,
      oauthDomain,
    }).then(() => {
      setLoading(false);
    });
  }, []);

  // Apply favicon & web title base on domain
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'icon';
    document.head.appendChild(link);
    const { faviconUrl, title } = getMetaData();

    link.href = faviconUrl;
    document.title = title;
  }, []);

  if (loading) return <Spin className="app-spin" />;

  return (
    <QueryClientProvider client={queryClient}>
      <Router history={reactTracker.connectToHistory(browserHistory)}>
        <Switch>
          <Route exact path="/403" component={Page403} />
          <Route exact path="/404" component={Page404} />
          <Route exact path="/500" component={Page500} />
          <PrivateRoute path="/" component={AppLayout} />
        </Switch>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
