import React, { useEffect, useState } from 'react';
import {
  Space,
  Upload as UploadAntd,
  UploadFile as UploadFileAntd,
  UploadProps as UploadPropsAntd,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { t } from 'helpers/i18n';
import { beforeUpload } from './helpers';
import { v4 } from 'uuid';
import GCSUpload, { UploadFile } from './GCSUpload';
import { trackFPICUploadFile } from 'helpers/tracker/trackFPIC';
import { fileHelpers } from 'helpers';

interface UploadProps {
  children?: React.ReactNode;
  accept?: string;
  fileAcceptDisplay?: string;
  maxSize?: number;
  multiple?: boolean;
  disabled?: boolean;
  onSuccess?: (files: UploadFile[]) => void;
}

const { Dragger } = UploadAntd;
const tracker = trackFPICUploadFile();

const Upload: React.FC<UploadProps> = ({
  children,
  accept,
  maxSize = 100,
  disabled = false,
  fileAcceptDisplay,
  onSuccess,
}) => {
  const [fileList, setFileList] = React.useState<UploadFileAntd[]>([]);
  const [uploadFileList, setUploadFileList] = useState<UploadFile[]>([]);

  const uploadProps: UploadPropsAntd = {
    showUploadList: true,
    multiple: false,
    accept,
    disabled,
    fileList: fileList.filter(file => file.status !== 'done'),
    beforeUpload: async rcFile => {
      const uid = v4();
      const file = await fileHelpers.convertImageFile(rcFile);

      tracker.onEndFileSelect(file);

      const uploadFile: UploadFileAntd = {
        uid,
        name: file.name,
        percent: 0,
        size: file.size,
        originFileObj: {
          ...file,
          uid,
          lastModifiedDate: new Date(),
        },
      };

      const list = [...fileList, uploadFile];
      setFileList(list);
      setUploadFileList([
        ...uploadFileList,
        fileHelpers.convertAntdFileToUploadFile(uploadFile, file),
      ]);

      return beforeUpload(file, {
        size: maxSize,
        accept,
      });
    },
    customRequest: () => {},
    onRemove: file => {
      setFileList(fileList => {
        return [...fileList.filter(item => item.uid !== file.uid)];
      });
      setUploadFileList(uploadFileList => {
        return [...uploadFileList.filter(item => item.uid !== file.uid)];
      });
    },
    onDrop: () => {
      tracker.onDrop();
    },
  };

  const updateUploadFileList = (files: UploadFile[]) => {
    const { doneFiles, pendingFiles } = files.reduce(
      (data, file) => {
        if (
          file.status &&
          (file.status === 'Uploaded' ||
            (file.status === 'Closed' && file.type !== 'error'))
        ) {
          data.doneFiles.push(file);
        } else if (
          !file.status ||
          !['Uploaded', 'Closed'].includes(file.status)
        ) {
          data.pendingFiles.push(file);
        }

        return data;
      },
      {
        doneFiles: [],
        pendingFiles: [],
      } as {
        doneFiles: UploadFile[];
        pendingFiles: UploadFile[];
      }
    );

    if (doneFiles.length) {
      onSuccess?.(doneFiles);
    }

    setUploadFileList(pendingFiles);

    setFileList(
      fileList.map(file => {
        const findFile = files.find(fileUpload => fileUpload.uid === file.uid);

        if (findFile) {
          if (findFile.status === 'Closed') {
            file.status = 'error';
          } else {
            file.percent = findFile.percent;

            file.status =
              findFile.status === 'Uploaded'
                ? 'done'
                : findFile.status === 'Uploading'
                ? 'uploading'
                : file.status;
          }
        }

        return file;
      })
    );
  };

  useEffect(() => {
    tracker.init();
    setFileList([]);
  }, []);

  if (disabled) return <></>;

  return (
    <>
      <span
        onClick={() => {
          tracker.onClick();
        }}
      >
        <Dragger {...uploadProps}>
          {children ? (
            children
          ) : (
            <>
              <Space className="ant-upload-text">
                <UploadOutlined />
                {t('ClickOrDragFileToThisAreaToUpload')}
              </Space>
              <p className="ant-upload-hint">
                {t('SupportForFiles', {
                  accept: fileAcceptDisplay || accept || '...',
                  size: maxSize,
                })}
              </p>
            </>
          )}
        </Dragger>
      </span>
      <GCSUpload
        tracker={tracker}
        files={uploadFileList}
        setFiles={updateUploadFileList}
      />
    </>
  );
};

export { Upload };
export default Upload;
