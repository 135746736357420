import React from 'react';
import Detail from '../Detail/Detail';
import { Mode } from '../Detail/FormItem/BaseItem';
import { IFPicFormSubmissionsGetParams, IFormSubmission } from 'interfaces';

interface DetailContentV2Props {
  mode: Mode;
  formSubmissionId: number;
  currentFormSubmission: IFormSubmission;
  updateFilter: (params: Partial<IFPicFormSubmissionsGetParams>) => void;
}

const DetailContentV2: React.FC<DetailContentV2Props> = ({
  mode,
  formSubmissionId,
  currentFormSubmission,
  updateFilter,
}) => {
  return (
    <Detail
      mode={mode}
      formSubmissionId={formSubmissionId}
      currentFormSubmission={currentFormSubmission}
      className="meeting-record-details"
      updateFilter={updateFilter}
    />
  );
};

export default DetailContentV2;
