import { IPlot, IPlotListPolygon } from 'interfaces';
import { t } from 'helpers/i18n';

export const DEFAULT_FILTER_VALUE = -1;
export const plotsWithAll = (plots: IPlot[]): IPlot[] => {
  return [
    ...plots,
    {
      plotId: DEFAULT_FILTER_VALUE,
      plotName: t('All'),
    } as IPlot,
  ];
};

export const polygonsWithAll = (
  polygons: IPlotListPolygon[]
): IPlotListPolygon[] => {
  return [
    ...polygons,
    {
      polygonId: DEFAULT_FILTER_VALUE,
    } as IPlotListPolygon,
  ];
};

export const SUBMISSION_STATUS_FILTERS = [
  {
    value: 'submitted',
    label: t('Waiting'),
  },
  {
    value: 'selected',
    label: t('Selected'),
  },
  {
    value: 'rejected',
    label: t('Rejected'),
  },
  {
    value: null,
    label: t('All'),
  },
];
