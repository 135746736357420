import { withFeatureFlag } from 'hocs';
import { ClusterOutlined, UserOutlined } from '@ant-design/icons';
import { t } from 'helpers/i18n';
import { lazy } from 'react';
import { resourceConstants } from 'constants/index';
import {
  CreateRoleGroup,
  EditRoleGroup,
  RoleGroupManagement,
} from 'containers/AccountManagement';
import InProgressPage from 'containers/shared/InProgressPage';
import AccountsManagement from 'containers/AccountManagement/AccountsManagement';
import { roleHelpers } from 'helpers';

const OrgchartIframe = lazy(() =>
  import('containers/HrManagement/OrgchartIframe')
);

const { RESOURCES } = resourceConstants;
const { HUMAN_RESOURCE_MANAGEMENT, ACCOUNT_MANAGEMENT } = RESOURCES;
const { tekboneReadPermission } = roleHelpers;

const { hrOrgchart } = window.config.iframeEndpoint;

export const orgChartRoutes = [
  {
    exact: true,
    path: '/orgchart',
    name: t('HROrgchart.HRManagement'),
    icon: ClusterOutlined,
    permissions: [tekboneReadPermission(HUMAN_RESOURCE_MANAGEMENT)],
  },
  {
    exact: true,
    path: '/orgchart/user-profiles',
    name: t('HROrgchart.ManageEmployees'),
    component: OrgchartIframe,
    iFrameSrc: `${hrOrgchart}/management/user-profiles`,
    permissions: [tekboneReadPermission(HUMAN_RESOURCE_MANAGEMENT)],
  },
  {
    exact: true,
    path: '/orgchart/teams',
    name: t('HROrgchart.ManageTeams'),
    component: OrgchartIframe,
    iFrameSrc: `${hrOrgchart}/management/teams`,
    permissions: [tekboneReadPermission(HUMAN_RESOURCE_MANAGEMENT)],
  },
  {
    exact: true,
    path: '/orgchart/departments',
    name: t('HROrgchart.ManageDepartments'),
    component: OrgchartIframe,
    iFrameSrc: `${hrOrgchart}/management/departments`,
    permissions: [tekboneReadPermission(HUMAN_RESOURCE_MANAGEMENT)],
  },
  {
    exact: true,
    path: '/orgchart/report-line',
    name: 'Report Line',
    component: OrgchartIframe,
    iFrameSrc: `${hrOrgchart}/management/report-line`,
    permissions: [tekboneReadPermission(HUMAN_RESOURCE_MANAGEMENT)],
  },
];

export const accountManagementRoutes = [
  {
    exact: true,
    path: '/accounts',
    name: t('AccountManagement'),
    icon: UserOutlined,
    permissions: [tekboneReadPermission(ACCOUNT_MANAGEMENT)],
  },
  {
    exact: true,
    path: '/accounts/main',
    name: t('AccountManagement'),
    component: withFeatureFlag(InProgressPage, AccountsManagement, 'CAR_1514'),
    permissions: [tekboneReadPermission(ACCOUNT_MANAGEMENT)],
    hideProjectSelect: true,
  },
  {
    exact: true,
    path: '/accounts/role-groups',
    name: t('RoleGroupManagement'),
    component: withFeatureFlag(InProgressPage, RoleGroupManagement, 'CAR_1514'),
    permissions: [tekboneReadPermission(ACCOUNT_MANAGEMENT)],
    hideProjectSelect: true,
  },
  {
    exact: true,
    path: '/accounts/role-groups/:id',
    name: t('RoleGroupDetail'),
    component: withFeatureFlag(InProgressPage, EditRoleGroup, 'CAR_1514'),
    permissions: [tekboneReadPermission(ACCOUNT_MANAGEMENT)],
    hideProjectSelect: true,
  },
  {
    exact: true,
    path: '/accounts/role-groups/create',
    name: t('CreateRoleGroup'),
    component: withFeatureFlag(InProgressPage, CreateRoleGroup, 'CAR_1514'),
    permissions: [tekboneReadPermission(ACCOUNT_MANAGEMENT)],
    hideProjectSelect: true,
  },
];
