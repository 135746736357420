import { Card, Space, Typography } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { VN_DATE_SLASH_FORMAT } from 'constants/dateTime';
import { IDiscussion } from 'interfaces';
import React from 'react';
import dayjs from 'dayjs';
import './FarmCommentCard.scss';

interface IFarmCommentCardProps {
  comment: IDiscussion;
}

const { Text, Paragraph } = Typography;

const FarmCommentCard: React.FC<IFarmCommentCardProps> = ({ comment }) => {
  return (
    <Card className="commentContent">
      <Space direction="vertical">
        <Text className="commentAuthor">{comment.createdBy}</Text>
        <Text className="commentText">
          {comment.createdAt &&
            dayjs(comment.createdAt).format(VN_DATE_SLASH_FORMAT)}
        </Text>
        <div className="text-justify">
          <Paragraph
            className="pt-half"
            ellipsis={{
              rows: 5,
              expandable: 'collapsible',
              symbol: expanded =>
                expanded ? <CaretUpOutlined /> : <CaretDownOutlined />,
            }}
          >
            {comment.content}
          </Paragraph>
        </div>
      </Space>
    </Card>
  );
};

export { FarmCommentCard };
export default FarmCommentCard;
