import { convertNumberFormat } from './common';

const getMPI = (year: number) => {
  // hardcode Indonesia data for demo
  // MPI = H * A
  // Mock MPI = 0.55
  const H = 16000 / (6000 * 3.8);
  let A = 0.55 / H;
  // Have water
  if (year >= 2020) A -= 1 / 18;
  // Have electricity
  if (year >= 2022) A -= 1 / 18;
  // Have road
  if (year >= 2023) A -= 1 / 18;
  return convertNumberFormat(A * H);
};

export default {
  getMPI,
};
