import { Button, Col, Form, Row } from 'antd';
import {
  FundCategoySelect,
  UserProvinceCommunitySelect,
} from 'components/shared/Select';
import { t } from 'helpers/i18n';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface IFundFilterForm {
  organizationId?: number;
  categoryId?: number;
}

interface IFundFilter {
  onSearch: (values: IFundFilterForm) => void;
}

const FundFilter: React.FC<IFundFilter> = ({ onSearch }) => {
  const [form] = Form.useForm<IFundFilterForm>();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const communityId = query.get('communityId');

  useEffect(() => {
    if (communityId) {
      form.setFieldsValue({ organizationId: +communityId });
    }
  }, [communityId]);

  return (
    <Form form={form} layout="vertical" onFinish={onSearch}>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item label={t('Community')} name="organizationId">
            <UserProvinceCommunitySelect className="w-100" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t('FundCategories')} name="categoryId">
            <FundCategoySelect className="w-100" />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={'end'}>
        <Button htmlType="submit" type="primary">
          {t('Search')}
        </Button>
      </Row>
    </Form>
  );
};

export default FundFilter;
