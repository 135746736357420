import { Row, Col, Image, Empty, Typography, Space } from 'antd';
import { COLOR } from 'constants/styles/color';
import { t } from 'helpers/i18n';
import { CSSProperties } from 'react';
import EmptyImageIcon from 'assets/svgs/EmptyImageIcon';

const maskStyle: CSSProperties = {
  background: 'rgba(0,0,0,0.8)',
};

export const renderImages = (treeImages: string[] | undefined) => {
  if (!treeImages) return null;
  var cols = [];
  var imgs = [];
  for (var i = 0; i < treeImages.length; i += 2) {
    cols.push(
      <>
        <Col
          className="gutter-row"
          style={{
            textAlign: 'center',
            height: 490,
          }}
          key={i}
        >
          <Image
            src={treeImages[i]}
            key={treeImages[i]}
            height={'100%'}
            width={'100%'}
          />
        </Col>
        {i + 1 < treeImages.length && (
          <Col
            className="gutter-row"
            style={{
              textAlign: 'center',
              marginBottom: '10px',
              height: '550px',
            }}
          >
            <Image
              src={treeImages[i + 1]}
              key={treeImages[i + 1]}
              height={'100%'}
              width={'100%'}
            />
          </Col>
        )}
      </>
    );
    imgs.push(<Image src={treeImages[i]} key={treeImages[i]} />);
  }
  return (
    <>
      <Image.PreviewGroup preview={{ styles: { mask: maskStyle } }}>
        {treeImages.length ? (
          <Row gutter={[16, 16]} justify="center">
            {cols}
          </Row>
        ) : (
          <Space
            align="center"
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Empty
              image={
                <EmptyImageIcon
                  height={'128px'}
                  width={'128px'}
                  color={COLOR.PRIMARY}
                />
              }
              description={
                <Typography.Text
                  color={COLOR.NEUTRAL}
                  style={{ fontSize: '1.6rem' }}
                >
                  {t('NoImageAvailable')}
                </Typography.Text>
              }
            />
          </Space>
        )}
      </Image.PreviewGroup>
    </>
  );
};
