import { ConfigProviderProps } from 'antd/lib/config-provider';
import i18n from 'i18n';

export const t = (text: string, options?: object) => i18n.t(text, options);

export const tSelectPlaceholder = (text: string, options?: object) =>
  i18n.t('PleaseSelectField', {
    field: t(text, options).toLowerCase(),
    ...options,
  });

export const tInputPlaceholder = (text: string, options?: object) =>
  i18n.t('PleaseInputField', {
    field: t(text, options).toLowerCase(),
    ...options,
  });

export const getTheme = (): ConfigProviderProps['theme'] => {
  const themeName = window.appConfig.appName;

  switch (themeName) {
    case 'aja-app': {
      return {
        token: {
          colorLinkActive: '#B46617B3',
          colorLink: '#B46617',
        },
        components: {
          Button: {
            colorPrimary: '#B46617',
            colorPrimaryHover: '#B46617',
            colorText: '#B46617',
            colorBorder: '#B46617',
            colorLink: '#B46617',
            colorPrimaryActive: '#B46617',
            colorLinkHover: '#B46617',
            colorLinkActive: '#B46617',
          },
          Checkbox: {
            colorPrimary: '#B46617',
            colorPrimaryHover: '#B46617',
            colorPrimaryActive: '#B46617',
            colorLink: '#B46617',
            colorLinkHover: '#B46617',
            colorLinkActive: '#B46617',
          },
          Radio: {
            colorPrimary: '#B46617',
            colorPrimaryHover: '#B46617',
            colorPrimaryActive: '#B46617',
            colorLink: '#B46617',
            colorLinkHover: '#B46617',
            colorLinkActive: '#B46617',
          },
          Switch: {
            colorPrimary: '#B46617',
            colorPrimaryHover: '#B46617',
            colorText: '#B46617',
            colorBorder: '#B46617',
            colorPrimaryActive: '#B46617',
          },
          Spin: {
            colorPrimary: '#B46617',
          },
        },
      };
    }
    case 'base-app': {
      return {
        token: {
          colorLinkActive: '#75AB6DB3',
          colorLink: '#75AB6D',
        },
        components: {
          Button: {
            colorPrimary: '#75AB6D',
            colorPrimaryHover: '#75AB6DB3',
            colorPrimaryActive: '#75AB6D',
            colorLinkActive: '#75AB6DB3',
            colorLink: '#75AB6D',
            colorLinkHover: '#75AB6DB3',
            defaultActiveColor: '#75AB6D',
            defaultActiveBorderColor: '#75AB6D',
            defaultBorderColor: '#75AB6D',
          },
          Checkbox: {
            colorPrimary: '#75AB6D',
            colorPrimaryHover: '#75AB6DB3',
            colorPrimaryActive: '#75AB6DB3',
            colorLinkActive: '#75AB6DB3',
            colorLink: '#75AB6D',
            colorLinkHover: '#75AB6DB3',
          },
          Radio: {
            colorPrimary: '#75AB6D',
            colorPrimaryHover: '#75AB6DB3',
            colorPrimaryActive: '#75AB6DB3',
            colorLinkActive: '#75AB6DB3',
            colorLink: '#75AB6D',
            colorLinkHover: '#75AB6DB3',
          },
          Switch: {
            colorPrimary: '#75AB6D',
            colorPrimaryHover: '#75AB6DB3',
            colorPrimaryActive: '#75AB6DB3',
          },
          Spin: {
            colorPrimary: '#75AB6D',
          },
        },
      };
    }
    case 'acc-app': {
      return {
        token: {
          colorLinkActive: '#03624C',
          colorLink: '#03624C',
        },
        components: {
          Button: {
            colorPrimary: '#03624C',
            colorPrimaryHover: '#03624C',
            colorPrimaryActive: '#03624C',
            colorLinkActive: '#03624C',
            colorLink: '#03624C',
            colorLinkHover: '#03624C',
            defaultActiveColor: '#03624C',
            defaultActiveBorderColor: '#03624C',
            defaultBorderColor: '#03624C',
          },
          Checkbox: {
            colorPrimary: '#03624C',
            colorPrimaryHover: '#03624C',
            colorPrimaryActive: '#03624C',
            colorLinkActive: '#03624C',
            colorLink: '#03624C',
            colorLinkHover: '#03624C',
          },
          Radio: {
            colorPrimary: '#03624C',
            colorPrimaryHover: '#03624C',
            colorPrimaryActive: '#03624C',
            colorLinkActive: '#03624C',
            colorLink: '#03624C',
            colorLinkHover: '#03624C',
          },
          Switch: {
            colorPrimary: '#03624C',
            colorPrimaryHover: '#03624C',
            colorPrimaryActive: '#03624C',
          },
          Spin: {
            colorPrimary: '#03624C',
          },
        },
      };
    }
    default:
      return undefined;
  }
};
