import { Select, SelectProps } from 'antd';
import { ProjectContext } from 'contexts';
import { filterSelect } from 'helpers/common';
import { tSelectPlaceholder } from 'helpers/i18n';
import { ICECCandidate, ICECMember } from 'interfaces';
import { useContext, useEffect, useMemo, useState } from 'react';
import { cremaServices } from 'services';
import { memoize } from 'lodash';

interface ICECCandidatesSelect extends SelectProps<number> {
  cremaId: number;
  CECMembers: ICECMember[];
}

const CECCandidatesSelect: React.FC<ICECCandidatesSelect> = ({
  cremaId,
  CECMembers,
  ...props
}) => {
  const { projectId } = useContext(ProjectContext);
  const [loading, setLoading] = useState(false);
  const [CECCandidates, setCECCandidates] = useState<ICECCandidate[]>([]);

  const cachedFetch = memoize(cId =>
    cremaServices.getCecCandidates({
      cremaIds: [cId],
      projectId,
      page: 1,
      pageSize: 1000,
    })
  );

  useEffect(() => {
    const fetchCECCandidates = async () => {
      try {
        setLoading(true);
        const { cecCandidates } = await cachedFetch(cremaId);
        setCECCandidates(cecCandidates);
      } finally {
        setLoading(false);
      }
    };

    fetchCECCandidates();
  }, [cremaId, CECMembers]);

  const options = useMemo(() => {
    if (!CECCandidates?.length) return [];
    return CECCandidates.map(item => ({
      label: `${item.candidateName} - ${item.candidateCommunityName}`,
      value: item.candidateId,
      data: item,
    }));
  }, [CECCandidates]);

  return (
    <Select
      allowClear
      showSearch
      placeholder={tSelectPlaceholder('Member')}
      options={options}
      loading={loading}
      filterOption={filterSelect}
      {...props}
    />
  );
};

export default CECCandidatesSelect;
