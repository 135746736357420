import { UploadFile, UploadType } from './interfaces';
import { useEffect, useState } from 'react';
import { fileServices } from 'services';
import { fileHelpers } from 'helpers';
import { trackFPICUploadFile } from 'helpers/tracker/trackFPIC';
import { t } from 'helpers/i18n';

const bytesRangeRegex = new RegExp(/bytes=(\d*)-(\d*)/);

const useGCSUpload = (
  uploadFile: UploadFile,
  callback?: (
    type: UploadType,
    data?: {
      file?: UploadFile;
      error?: any;
    }
  ) => void,
  tracker?: ReturnType<typeof trackFPICUploadFile>
) => {
  const uploadStatus = uploadFile.status;
  const originFile = uploadFile.originFile;

  const [errorUpload, setErrorUpload] = useState<UploadFile | undefined>(
    undefined
  );
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const getUploadUrl = async (): Promise<{
    fileUrl?: string;
    gcsUrl?: string;
    maxSize?: number;
  }> => {
    if (!originFile) return {};

    try {
      const result = await fileServices.getDirectUrl(originFile);

      const { fileUrl, url, maxSize } = result?.data?.data || {};

      return { fileUrl, maxSize, gcsUrl: url };
    } catch (error) {
      throw error;
    }
  };

  const getGcsLocation = (gcsUrl: string, maxSize: number) => {
    if (!originFile) return;

    try {
      return fileServices.getGcsLocation(gcsUrl, originFile.type, maxSize);
    } catch (error) {
      throw error;
    }
  };

  const uploadFileToGCS = async (file: File, gcsLocation: string) => {
    try {
      await fileServices.uploadGoogleHost(file, gcsLocation);
    } catch (error) {
      throw error;
    }
  };

  const checkGCSUploadStatus = async (gscLocation: string) => {
    try {
      await fileServices.getResumeUploadStatus(gscLocation);
    } catch (e) {
      if ((e as any).code === 'ERR_NETWORK') {
        throw e;
      } else if ((e as any).response?.status === 308) {
        const headers = (e as any).response?.headers;
        if (headers.range) {
          return headers.range;
        } else {
          throw e;
        }
      } else {
        throw e;
      }
    }
  };

  const resumeUpload = async (
    file: File,
    gcsLocation: string,
    lastByte: number,
    fileSize: number,
    count = 0
  ) => {
    if (navigator.onLine) {
      try {
        await fileServices.resumeUploadFile(
          file,
          lastByte,
          fileSize,
          gcsLocation
        );

        callback?.('success', {
          file: {
            ...uploadFile,
            status: 'Uploaded',
            percent: 100,
          },
        });
      } catch (e) {
        if ((e as any).code === 'ERR_NETWORK') {
          if (count >= 5) {
            return;
          }

          setTimeout(async () => {
            await resumeUpload(
              file,
              gcsLocation,
              lastByte,
              fileSize,
              count + 1
            );
          }, 2000);
        }
      }
    } else {
      checkOnline();
    }
  };

  const upload = async () => {
    if (!originFile) return;

    tracker?.onStartUpload(originFile);
    let currentUploadFile: UploadFile = {
      ...uploadFile,
      status: 'Uploading',
      type: 'getDirectUrl',
    };
    try {
      callback?.('getDirectUrl', {
        file: currentUploadFile,
      });

      const { fileUrl, gcsUrl, maxSize } = await getUploadUrl();

      currentUploadFile = {
        ...uploadFile,
        status: 'Uploading',
        type: 'getDirectUrlResult',
        fileUrl,
        gcsUrl,
      };
      callback?.('getDirectUrlResult', {
        file: currentUploadFile,
      });

      if (!gcsUrl || !maxSize) {
        tracker?.onErrorUpload(originFile, t('UnableToGetGcsUrlOrMaxSize'));
        callback?.('closed', {
          error: t('UnableToGetGcsUrlOrMaxSize'),
          file: {
            ...uploadFile,
            status: 'Closed',
            type: 'closed',
          },
        });
        return undefined;
      }
      currentUploadFile = {
        ...uploadFile,
        status: 'Uploading',
        type: 'getGcsLocation',
        fileUrl,
        gcsUrl,
      };
      callback?.('getGcsLocation', {
        file: currentUploadFile,
      });

      const gcsLocation = await getGcsLocation(gcsUrl, maxSize);

      if (!gcsLocation) {
        tracker?.onErrorUpload(originFile, t('UnableToGetGcsLocation'));
        callback?.('closed', {
          error: t('UnableToGetGcsLocation'),
          file: {
            ...uploadFile,
            status: 'Closed',
            type: 'closed',
          },
        });
        return undefined;
      }

      currentUploadFile = {
        ...uploadFile,
        status: 'Uploading',
        type: 'getGcsLocationResult',
        fileUrl,
        gcsUrl,
        gcsLocation,
      };
      callback?.('getGcsLocationResult', {
        file: currentUploadFile,
      });

      await uploadFileToGCS(originFile, gcsLocation);

      currentUploadFile = {
        ...uploadFile,
        status: 'Uploaded',
        fileUrl,
        gcsUrl,
        type: 'success',
        percent: 100,
      };
      callback?.('success', {
        file: currentUploadFile,
      });
      setErrorUpload(undefined);
      tracker?.onSuccessUpload(originFile, fileUrl);

      return fileUrl;
    } catch (error) {
      currentUploadFile.status = 'Error';
      tracker?.onErrorUpload(originFile, (error as any).toString());

      setErrorUpload({
        ...currentUploadFile,
        type: 'error',
      });
      callback?.('error', {
        error,
        file: {
          ...uploadFile,
          status: 'Error',
        },
      });
    }
  };

  const retryUpload = async () => {
    if (
      !isOnline ||
      !errorUpload ||
      uploadStatus !== 'Error' ||
      !errorUpload.originFile ||
      !errorUpload.gcsLocation
    )
      return;

    try {
      const bytesRange = await checkGCSUploadStatus(errorUpload.gcsLocation);

      if (!bytesRange) {
        setErrorUpload(undefined);

        callback?.('closed', {
          error: undefined,
          file: {
            ...uploadFile,
            status: 'Closed',
            type: 'closed',
          },
        });
        return;
      }

      const result = bytesRange.match(bytesRangeRegex);
      const lastByte = result?.[2] ? Number(result?.[2]) : undefined;

      if (!lastByte) return;

      const originFile = errorUpload.originFile;
      const gcsLocation = errorUpload.gcsLocation;
      const fileChunk = fileHelpers.createFileChunks(
        originFile,
        lastByte + 1,
        originFile.size
      );

      await resumeUpload(fileChunk, gcsLocation, lastByte, originFile.size);
    } catch (e) {
      setErrorUpload(undefined);
      callback?.('closed', {
        error: undefined,
        file: {
          ...uploadFile,
          status: 'Closed',
          type: 'error',
        },
      });
    }
  };

  const checkOnline = () => {
    setTimeout(() => {
      const isOnline = navigator.onLine;
      setIsOnline(isOnline);

      if (!isOnline) checkOnline();
    }, 2000);
  };

  useEffect(() => {
    if (!uploadStatus) {
      upload().then();
    }
  }, [uploadFile]);

  useEffect(() => {
    if (
      errorUpload &&
      uploadStatus === 'Error' &&
      errorUpload.gcsLocation &&
      errorUpload.originFile
    ) {
      checkOnline();
    }
  }, [errorUpload]);

  useEffect(() => {
    if (isOnline) {
      retryUpload().then();
    }
  }, [isOnline]);
};

export { useGCSUpload };
