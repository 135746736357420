import {
  Button,
  Form,
  Row,
  Spin,
  Typography,
  Upload,
  UploadProps,
  notification,
} from 'antd';
import ContentBlock from 'components/shared/ContentBlock';
import { t } from 'helpers/i18n';
import {
  DownloadOutlined,
  InboxOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { DispatchWithoutAction, useState } from 'react';
import { Store } from 'antd/es/form/interface';
import { fileServices } from 'services';
import { ProcessingFileType } from 'interfaces';
import { useProjectContext } from 'contexts';
import { fileHelpers } from 'helpers';
import { EXCEL_TYPES, FILE_TEMPLATES } from 'constants/file';

interface FileUploadProps extends UploadProps {
  fileType: ProcessingFileType;
  customUploadRequest: (formData: FormData) => Promise<void>;
  onFinishUpload?: DispatchWithoutAction;
}

const FileUpload: React.FC<FileUploadProps> = ({
  fileType,
  customUploadRequest,
  onFinishUpload,
}) => {
  const [loading, setLoading] = useState(false);
  const [downloadTemplateLoading, setDownloadTemplateLoading] = useState(false);
  const [form] = Form.useForm();
  const { projectId } = useProjectContext();

  const uploadFile = async ({ files }: Store) => {
    try {
      setLoading(true);
      let formData = new FormData();
      formData.append('projectId', projectId.toString());
      formData.append('file', files[0].originFileObj);
      await customUploadRequest(formData);
      form.resetFields();
      notification.success({ message: t('UploadFileSuccessfully') });
      onFinishUpload?.();
    } finally {
      setLoading(false);
    }
  };

  const downloadTemplate = async () => {
    try {
      setDownloadTemplateLoading(true);
      const file = await fileServices.downloadTemplate({
        type: fileType,
        projectId,
      });
      fileHelpers.downloadFile(
        file,
        FILE_TEMPLATES[fileType]?.filename || 'Template.xlsx'
      );
    } finally {
      setDownloadTemplateLoading(false);
    }
  };

  return (
    <ContentBlock title={t('UploadFile')} className="mx-half mb-half">
      <Row className="mb-base">
        <Typography.Link
          onClick={downloadTemplate}
          disabled={downloadTemplateLoading}
          className="d-flex align-items-center"
        >
          {downloadTemplateLoading ? (
            <Spin size="small" indicator={<LoadingOutlined />} />
          ) : (
            <DownloadOutlined />
          )}
          &nbsp;
          {t('DownloadTemplateHere')}
        </Typography.Link>
      </Row>
      <Form form={form} onFinish={uploadFile}>
        <Form.Item
          name="files"
          valuePropName="fileList"
          getValueFromEvent={({ fileList }) => fileList}
          className="w-100"
        >
          <Upload.Dragger
            maxCount={1}
            beforeUpload={file => {
              const lastDotIndex = file.name.lastIndexOf('.');
              if (
                lastDotIndex === -1 ||
                !EXCEL_TYPES.includes(file.name.slice(lastDotIndex))
              )
                return Upload.LIST_IGNORE;
              return false;
            }} //Prevent upload on drag
            disabled={loading}
            accept={EXCEL_TYPES.join(',')}
          >
            <div className="mx-half my-half">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t('DragAndDropFileHere')}</p>
              <p className="ant-upload-hint">
                {t('UploadHint', {
                  fileTypes: EXCEL_TYPES.join(', '),
                  maxSize: '10MB',
                })}
              </p>
            </div>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item shouldUpdate>
          {({ getFieldValue }) => (
            <Button
              type="primary"
              htmlType="submit"
              className="w-100"
              disabled={!!!getFieldValue('files')?.length}
              loading={loading}
            >
              {t('Upload')}
            </Button>
          )}
        </Form.Item>
      </Form>
    </ContentBlock>
  );
};

export default FileUpload;
