import { IFormSubmission, IFPicFormSubmissionUpsertBody } from 'interfaces';
import { useState, useEffect } from 'react';
import { fpicServices } from 'services';

const useFormSubmission = (
  projectId: number,
  formSubmissionId?: number,
  shouldGetDetail?: boolean,
  useTekbone?: boolean
) => {
  const [filling, setFilling] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formSubmission, setFormSubmission] = useState<
    IFormSubmission | undefined
  >(undefined);

  const upsertForm = async (body: IFPicFormSubmissionUpsertBody) => {
    let result;
    try {
      setLoading(true);
      result = await (useTekbone
        ? fpicServices.upsertFPicFormSubmissionTekbone
        : fpicServices.upsertFPicFormSubmission)(body);
    } catch (e) {
    } finally {
      setLoading(false);
    }

    return result?.data?.data?.formSubmissionId;
  };

  const getFormSubmissionDetail = async () => {
    try {
      setFetching(true);
      const result = await (useTekbone
        ? fpicServices.getFPicFormSubmissionsTekbone
        : fpicServices.getFPicFormSubmissions)({
        page: 1,
        pageSize: 1,
        projectId,
        formSubmissionId,
      });
      const formSubmissions = result?.data?.data?.formSubmissions;
      setFormSubmission(
        formSubmissions?.length ? formSubmissions[0] : undefined
      );
    } catch (e) {
    } finally {
      setFetching(false);
    }
  };

  const autoFillForm = async (formSubmissionId: number) => {
    try {
      setFilling(true);
      const result = await fpicServices.autoFill(formSubmissionId);
      return !!result.data;
    } catch (e) {
    } finally {
      setFilling(false);
    }
  };

  useEffect(() => {
    if (formSubmissionId && shouldGetDetail) {
      getFormSubmissionDetail().then();
    }
  }, [formSubmissionId]);

  return {
    loading,
    filling,
    fetching,
    formSubmission,
    upsertForm,
    setLoading,
    autoFillForm,
  };
};

export { useFormSubmission };
