import {
  Button,
  Checkbox,
  Col,
  Form,
  Row,
  Select,
  Skeleton,
  Typography,
} from 'antd';
import { t } from 'helpers/i18n';
import { polygonHooks } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import './CarbonPolygonForm.scss';
import { IGetPolygonTekboneParams } from 'interfaces';
import { useProjectContext } from 'contexts';
import { PAGING_OFFSET_ALL } from 'constants/pagination';

const { usePolygonTypes, usePolygons } = polygonHooks;

interface CarbonPolygonFormProps {
  onNext: () => void;
}

const CarbonPolygonForm: React.FC<CarbonPolygonFormProps> = ({ onNext }) => {
  const { projectId } = useProjectContext();
  const form = Form.useFormInstance();
  const [params, setParams] = useState<IGetPolygonTekboneParams>({
    overlapStatus: 'NOT_OVERLAPPED',
    projectId,
    ...PAGING_OFFSET_ALL,
  });

  const selectedPolygons = Form.useWatch('polygonIds', {
    form,
    preserve: true,
  });
  const polygonTypeId = Form.useWatch('polygonTypeId', {
    form,
  });

  const { polygonTypes, loading } = usePolygonTypes();
  const { polygons, loading: polygonsLoading } = usePolygons(params, {
    fetchOnMount: false,
  });

  const defaultPolygonType = polygonTypes?.find(type => type.isProjectAreaType);

  const polygonOptions = useMemo(() => {
    return polygons.map(polygon => ({
      label: polygon.polygonName,
      value: polygon.polygonId,
    }));
  }, [polygons]);

  useEffect(() => {
    const totalEligibleArea = polygons
      .filter(polygon => selectedPolygons.includes(polygon.polygonId))
      .reduce((acc, polygon) => acc + (polygon.eligibleArea || 0), 0);

    form.setFieldsValue({ totalEligibleArea });
  }, [selectedPolygons]);

  useEffect(() => {
    form.setFieldsValue({
      polygonIds: polygons.map(polygon => polygon.polygonId),
    });
  }, [polygonOptions]);

  useEffect(() => {
    setParams(params => ({
      ...params,
      polygonTypeIds: [polygonTypeId],
    }));
  }, [polygonTypeId]);

  return (
    <div className="w-100 h-100 d-flex flex-column">
      <Form.Item
        name="polygonTypeId"
        label={t('PolygonType')}
        rules={[{ required: true }]}
        style={{ flexShrink: 0 }}
        initialValue={defaultPolygonType?.polygonTypeId}
        required
      >
        <Select
          options={defaultPolygonType ? [defaultPolygonType] : []}
          fieldNames={{ label: 'polygonTypeName', value: 'polygonTypeId' }}
          placeholder={t('SelectPolygonType')}
          loading={loading}
        />
      </Form.Item>
      <Form.Item
        label={t('CarbonCalculatedPolygons')}
        required
        className="flex-1 content-scrollable overflow-hidden"
      >
        {polygonsLoading ? (
          <Skeleton loading={polygonsLoading} active />
        ) : polygonOptions.length ? (
          <>
            <Form.Item
              name="polygonIds"
              noStyle
              rules={[{ required: true }]}
              className="mb-half"
            >
              <Checkbox.Group>
                <Row>
                  {polygonOptions?.map(polygon => (
                    <Col key={polygon.value} span={24}>
                      <Checkbox value={polygon.value}>{polygon.label}</Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
            <Typography.Text strong className="mt-half">
              {t('TotalSelected')}: {selectedPolygons.length}
            </Typography.Text>
          </>
        ) : (
          <Typography.Text type="secondary" italic>
            {t('NoPolygonFound')}
          </Typography.Text>
        )}
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Button
            type="primary"
            htmlType="button"
            disabled={!getFieldValue('polygonIds')?.length}
            onClick={onNext}
          >
            {t('Next')}
          </Button>
        )}
      </Form.Item>
    </div>
  );
};

export default CarbonPolygonForm;
