import React, { useEffect, useState } from 'react';
import { Button, Divider, Input, Select, Space } from 'antd';
import { t, tInputPlaceholder } from 'helpers/i18n';
import { BaseItemProps, BaseItem } from '../BaseItem';
import { PlusOutlined } from '@ant-design/icons';

interface CustomizableDropdownProps extends BaseItemProps {
  readOnly?: boolean;
}

const CustomizableDropdown: React.FC<CustomizableDropdownProps> = ({
  item,
  disabled,
  form,
  readOnly = false,
  ...props
}) => {
  const [textValue, setTextValue] = useState('');
  const [items, setItems] = useState<{ label: string; value: string }[]>([]);

  const onChangeTextValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextValue(event.target.value);
  };

  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    setItems(prev => [
      ...prev,
      {
        value: `${items.length}. ${textValue}`,
        label: `${items.length}. ${textValue}`,
      },
    ]);
    setTextValue('');
    form.setFieldValue(item.fieldName, `${items.length}.${textValue}`);
  };

  useEffect(() => {
    if (item.fieldName) {
      form.setFieldValue(item.fieldName, item.value);
    }
    const options = Array.isArray(item.suggestionValues)
      ? item.suggestionValues
      : [];
    setItems(options);
  }, [item.value]);

  return (
    <BaseItem item={item} disabled={disabled} form={form} {...props}>
      {readOnly || disabled ? (
        <Input
          readOnly
          value={items ? items.find(o => o.value === item.value)?.label : ''}
          style={{ backgroundColor: '#0000000a' }}
        />
      ) : (
        <Select
          style={{ width: '100%' }}
          placeholder={t('SelectValue')}
          options={items}
          showSearch={true}
          dropdownRender={menu => (
            <>
              {menu}
              <Divider />
              <Space>
                <Input
                  placeholder={tInputPlaceholder('Item')}
                  value={textValue}
                  onChange={onChangeTextValue}
                  onKeyDown={e => e.stopPropagation()}
                />
                <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                  {t('AddItem')}
                </Button>
              </Space>
            </>
          )}
        />
      )}
    </BaseItem>
  );
};

export default CustomizableDropdown;
