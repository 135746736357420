import { AxiosRequestConfig } from 'axios';
import { ROUTES } from 'constants/routes';
import { getData, getResult } from 'helpers/request';
import {
  IDeletePolygonResponse,
  IGetPolygonTekboneParams,
  IPolygonDetails,
  IPolygonLimitOffsetResponse,
  IPolygonTekbone,
  IPolygonType,
  ISelectPolygonResponse,
  ISelectionPolygonParams,
  IUpsertPolygonPayload,
  IUpsertPolygonResponse,
} from 'interfaces';
import { requestServices } from 'services';

const { projectManagerClient } = requestServices;

const upsertPolygon = (
  payload: IUpsertPolygonPayload
): Promise<IUpsertPolygonResponse> =>
  projectManagerClient
    .post('tekbone/api/v1/polygons/upsert-polygon', payload)
    .then(getData);

const searchPolygons = (
  params: IGetPolygonTekboneParams,
  config?: AxiosRequestConfig
): Promise<IPolygonLimitOffsetResponse<IPolygonTekbone>['data']> =>
  projectManagerClient
    .post(ROUTES.POLYGON.SEARCH_POLYGONS, params, config)
    .then(getData);

const getPolygonTypes = (
  projectId: number
): Promise<{ polygonTypes: IPolygonType[] }> =>
  projectManagerClient
    .get(ROUTES.POLYGON.GET_POLYGON_TYPES, {
      params: { projectId },
    })
    .then(getData);

const deletePolygon = (polygonId: number): Promise<IDeletePolygonResponse> =>
  projectManagerClient
    .delete(ROUTES.POLYGON.DELETE_POLYGON(polygonId))
    .then(getResult);

const selectPolygon = (
  payload: ISelectionPolygonParams
): Promise<ISelectPolygonResponse> =>
  projectManagerClient.post(ROUTES.POLYGON.SELECT, payload).then(getResult);

const getPolygonDetails = (
  polygonId: number,
  params: { projectId: number }
): Promise<IPolygonDetails> => {
  return projectManagerClient
    .get(`/tekbone/api/v1/polygons/${polygonId}`, { params })
    .then(getData);
};

export default {
  searchPolygons,
  getPolygonTypes,
  deletePolygon,
  selectPolygon,
  getPolygonDetails,
  upsertPolygon,
};
