import trackerUtils, { EventData } from './trackerUtils';
import { v4 } from 'uuid';

interface FPICUploadTrackingData {
  startSelect: EventData;
  endSelect: EventData;
  startUpload: EventData;
  successUpload: EventData;
  errorUpload: EventData;
  click: EventData;
  drop: EventData;
}

const FPIC_UPLOAD_TRACKING_DATA: FPICUploadTrackingData = {
  startSelect: {
    regionName: 'FPICStartSelect',
    contentName: 'FPICStartSelect',
    index: 0,
  },
  endSelect: {
    regionName: 'FPICEndSelect',
    contentName: 'FPICEndSelect',
    index: 1,
  },
  startUpload: {
    regionName: 'FPICStartUpload',
    contentName: 'FPICStartUpload',
    index: 2,
  },
  successUpload: {
    regionName: 'FPICSuccessUpload',
    contentName: 'FPICSuccessUpload',
    index: 3,
  },
  errorUpload: {
    regionName: 'FPICErrorUpload',
    contentName: 'FPICErrorUpload',
    index: 4,
  },
  click: {
    regionName: 'FPICUploadWithClick',
    contentName: 'FPICUploadWithClick',
    index: 5,
  },
  drop: {
    regionName: 'FPICUploadWithDrop',
    contentName: 'FPICUploadWithDrop',
    index: 6,
  },
};

const { CLICK_DATA, getPayload } = trackerUtils;

const trackFPICUploadFile = () => {
  let sessionId = '';
  let time = 0;
  let interval: any;

  const trackFPICUploadFileAction = (
    event: EventData,
    value: string,
    extra?: any
  ) => {
    if (!sessionId) return;

    window.track('manualTrackInteractionContent', {
      ...CLICK_DATA,
      screenName: 'FPICUpload',
      regionName: event.regionName,
      contentName: value,
      target: sessionId,
      payload: getPayload('FPICUpload', event.index, extra),
    });
  };

  const init = () => {
    reset();
  };

  const onClick = () => {
    trackFPICUploadFileAction(FPIC_UPLOAD_TRACKING_DATA.click, 'onClick');

    onFileSelect();
  };

  const onDrop = () => {
    trackFPICUploadFileAction(FPIC_UPLOAD_TRACKING_DATA.click, 'onDrop');

    onFileSelect();
  };

  const onFileSelect = () => {
    trackFPICUploadFileAction(
      FPIC_UPLOAD_TRACKING_DATA.startSelect,
      time.toString()
    );

    interval = setInterval(() => {
      time += 1;
    }, 1000);
  };

  const onEndFileSelect = (file: File) => {
    trackFPICUploadFileAction(
      FPIC_UPLOAD_TRACKING_DATA.endSelect,
      time.toString(),
      {
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
      }
    );
    time = 0;
  };

  const onStartUpload = (file: File) => {
    trackFPICUploadFileAction(
      FPIC_UPLOAD_TRACKING_DATA.startUpload,
      time.toString(),
      {
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
      }
    );
  };

  const onSuccessUpload = (file: File, fileResult?: string) => {
    trackFPICUploadFileAction(
      FPIC_UPLOAD_TRACKING_DATA.successUpload,
      time.toString(),
      {
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
        fileResult,
      }
    );
    reset();
  };

  const onErrorUpload = (file: File, fileResult?: string) => {
    trackFPICUploadFileAction(
      FPIC_UPLOAD_TRACKING_DATA.errorUpload,
      time.toString(),
      {
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
        fileResult,
      }
    );
    reset();
  };

  const reset = () => {
    time = 0;
    sessionId = v4();
    interval && clearInterval(interval);
  };

  return {
    init,
    onFileSelect,
    onEndFileSelect,
    onStartUpload,
    onSuccessUpload,
    onErrorUpload,
    reset,
    onClick,
    onDrop,
  };
};

export { trackFPICUploadFile };
