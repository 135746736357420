export interface EventData {
  regionName: string;
  contentName?: string;
  index: number;
}

const CLICK_DATA = {
  eventName: 'click',
  eventType: 'ContentEvent',
  interaction: 'click',
};

const HOVER_DATA = {
  eventName: 'hover',
  eventType: 'ContentEvent',
  interaction: 'hover',
};

const SUBMISSION_MANAGEMENT_DATA = {
  ...CLICK_DATA,
  screenName: 'submissionManagement',
  target: 'submissionManagement',
};

const getPayload = (screenName: string, index: number, extra?: any) => {
  return JSON.stringify({
    currentScreenName: screenName,
    index: index,
    url: window.location.href,
    ...extra,
  });
};

export default {
  CLICK_DATA,
  HOVER_DATA,
  SUBMISSION_MANAGEMENT_DATA,
  getPayload,
};
