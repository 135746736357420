const getOffset = (page: number, pageSize: number) => {
  return (page - 1) * pageSize;
};

const getPageFromLimitOffset = (offset: number, limit: number) => {
  return Math.floor(offset / limit) + 1;
};

const shouldLoadMore = (dataLength: number, limit: number) => {
  // if retreived data length equals limit, there could be data available on next page
  return dataLength === limit;
};

const generateRowIndex = (
  currentPage: number,
  pageSize: number,
  rowIndex: number
) => {
  return (currentPage - 1) * pageSize + rowIndex + 1;
};
export default {
  getOffset,
  getPageFromLimitOffset,
  shouldLoadMore,
  generateRowIndex,
};
