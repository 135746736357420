import React from 'react';
import GCSUploadThread from './GCSUploadThread';
import { UploadFile } from './interfaces';
import { trackFPICUploadFile } from 'helpers/tracker/trackFPIC';

interface GCSUploadProps {
  children?: React.ReactNode;
  tracker?: ReturnType<typeof trackFPICUploadFile>;
  files: UploadFile[];
  setFiles: (files: UploadFile[]) => void;
}

const GCSUpload: React.FC<GCSUploadProps> = ({ files, tracker, setFiles }) => {
  const fileUploads = files.filter(
    file => !file.status || !['Closed', 'Uploaded'].includes(file.status)
  );

  const setFile = (file: UploadFile) => {
    const newFiles = [...files];
    const index = newFiles.findIndex(f => f.uid === file.uid);
    newFiles[index] = file;
    setFiles(newFiles);
  };

  return (
    <>
      {fileUploads.map(file => (
        <GCSUploadThread
          tracker={tracker}
          key={file.uid}
          file={file}
          setFile={setFile}
        />
      ))}
    </>
  );
};

export default GCSUpload;
