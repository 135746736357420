import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

interface YearRangePickerProps {
  value?: [Dayjs, Dayjs];
  onChange?: (value: [Dayjs | null, Dayjs | null] | null) => void;
  minRange: number;
}

const YearRangePicker: React.FC<YearRangePickerProps> = ({
  value,
  onChange,
  minRange,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <DatePicker.RangePicker
      open={open}
      value={value}
      className="w-100"
      picker="year"
      minDate={dayjs()}
      onChange={dates => onChange?.(dates)}
      onOpenChange={status => setOpen(status)}
      onCalendarChange={(dates, _, info) => {
        const { range } = info;
        if (range === 'start' && dates[0]) {
          onChange?.([dates[0], dates[0].add(minRange - 1, 'y')]);
          setOpen(false);
        }
      }}
    />
  );
};

export default YearRangePicker;
