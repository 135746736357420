export const SUBMISSION_STATUS = {
  selected: 'selected',
  waiting: 'submitted',
  rejected: 'rejected',
};

export const SUBMISSION_UNIT = {
  CARBON_CONTENT: 'kgC',
  CO2_OFFSET: 'kgCO2',
  CM: 'cm',
};

export const TREE_LABEL_DEFAULT = '---';
