import { requestServices } from 'services';
import {
  ISubmissionFilters,
  IGetSubmissionResponse,
  IExportSubmissions,
  IUpdateSubmission,
  IUpdateSubmissionResponse,
  IGetSubmissionResponseAfterCAR2355,
  ISnapshotTree,
  IGetTreesParams,
  IGetTreesResponse,
  IGetSnapshotTreesResponse,
  IPaginationOffsetParams,
} from 'interfaces';
import { getData, getResult } from 'helpers/request';

const { projectManagerClient } = requestServices;

const getSubmissions = (
  params: ISubmissionFilters
): Promise<IGetSubmissionResponse> => {
  return projectManagerClient
    .get('/api/v1/submissions', {
      params,
    })
    .then(getData);
};

const getSubmissionsTekbone = (
  params: ISubmissionFilters
): Promise<IGetSubmissionResponseAfterCAR2355> => {
  return projectManagerClient
    .get('tekbone/api/v1/submissions', {
      params,
    })
    .then(getData);
};

const exportSubmissions = (params: IExportSubmissions): Promise<string> =>
  projectManagerClient.get('/api/v1/submissions/export', {
    params,
    responseType: 'blob',
  });

const updateSubmission = (
  payload: IUpdateSubmission
): Promise<IUpdateSubmissionResponse> =>
  projectManagerClient.put('api/v1/submissions', payload).then(getResult);

const updateSubmissionTekbone = (
  payload: IUpdateSubmission
): Promise<IUpdateSubmissionResponse> =>
  projectManagerClient
    .put('tekbone/api/v1/submissions', payload)
    .then(getResult);

/**
 * Get snapshotTree (submission details)
 * @param treeId number
 * @param snapshotTreeId number
 * @param projectId number
 * @returns ISnapshotTree
 */
const getSnapshotTree = (
  treeId: number,
  snapshotTreeId: number,
  projectId: number
): Promise<ISnapshotTree> => {
  return projectManagerClient
    .get(`/tekbone/api/v1/trees/${treeId}/snapshot-trees/${snapshotTreeId}`, {
      params: { projectId },
    })
    .then(getData);
};

/**
 * Get snapshotTrees
 * @param treeId number
 * @param projectId number
 * @returns IGetSnapshotTreesResponse
 */
const getSnapshotTrees = (
  treeId: number,
  params: IPaginationOffsetParams & {
    projectId: number;
  }
): Promise<IGetSnapshotTreesResponse> => {
  return projectManagerClient
    .get(`/tekbone/api/v1/trees/${treeId}/snapshot-trees`, {
      params,
    })
    .then(getData);
};

/**
 * Get trees in submissions table V2
 * @param params IGetTreesParams
 * @returns IGetTreesResponse
 */
const getTrees = (params: IGetTreesParams): Promise<IGetTreesResponse> => {
  return projectManagerClient
    .get(`/tekbone/api/v1/trees`, { params })
    .then(getData);
};

export default {
  getSubmissions,
  exportSubmissions,
  updateSubmission,
  getSubmissionsTekbone,
  updateSubmissionTekbone,
  getSnapshotTree,
  getSnapshotTrees,
  getTrees,
};
