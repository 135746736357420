import { getMessaging, getToken } from 'firebase/messaging';
import { useEffect, useState } from 'react';

interface IUserFirebase {
  vapidKey: string;
}

const useFirebase = ({ vapidKey }: IUserFirebase) => {
  const [token, setToken] = useState('');

  const fetchToken = async (vapidKey: string) => {
    try {
      const messaging = getMessaging();
      const token = await getToken(messaging, {
        vapidKey,
      });
      console.log('token', token);
      setToken(token);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchToken(vapidKey);
  }, [vapidKey, fetchToken]);

  return { token };
};

export default {
  useFirebase,
};
