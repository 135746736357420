import { PlusOutlined } from '@ant-design/icons';
import { Select, SelectProps, Typography } from 'antd';
import { ID_DEFAULT } from 'constants/common';
import { resourceConstants } from 'constants/index';
import localStorageConstants from 'constants/localStorage';
import { useProjectContext, useFlagsupContext } from 'contexts';
import { t } from 'helpers/i18n';
import { IRoute } from 'interfaces';
import { useEffect, useState } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import trackProjectManager from 'helpers/tracker/trackProjectManager';
import { ProjectUpsertionDrawer } from 'components/ProjectList/ProjectModal/ProjectUpsertionDrawer';
import ProjectListAddForm from '../../ProjectList/ProjectModal/ProjectListAddForm';
import { userHooks } from 'hooks';
import { filterSelect } from 'helpers/common';

const { LS_PROJECT_ID } = localStorageConstants;
const { Link } = Typography;
const {
  trackProjectManagerAction,
  PROJECT_MANAGER_TRACKING_DATA,
} = trackProjectManager;
const { APPS, RESOURCES, ACTIONS } = resourceConstants;

const ProjectSelect: React.FC<SelectProps & {
  filteredRoutes: IRoute[];
}> = ({ filteredRoutes = [], ...props }) => {
  const { featureFlagsData } = useFlagsupContext();
  const isCAR418Enabled = featureFlagsData.CAR_418?.enabled;
  const [open, setOpen] = useState<boolean>(false);
  const {
    projects,
    projectId,
    setProjectId,
    setCurrentProject,
  } = useProjectContext();
  const history = useHistory();
  const location = useLocation();
  const { hasPermission } = userHooks.usePermission();
  const canCreateProject = hasPermission(
    APPS.PROJECT_MANAGER,
    RESOURCES.PROJECTS,
    ACTIONS.CREATE
  );

  useEffect(() => {
    const hasRoute = !!filteredRoutes.find(route =>
      matchPath(location.pathname, { path: route.path, exact: true })
    );
    if (!hasRoute) {
      history.push('/');
    }
  }, [projectId]);

  return projects.length ? (
    <>
      <Select
        style={{ minWidth: 180, maxWidth: 200 }}
        defaultValue={projectId === ID_DEFAULT ? '---' : projectId}
        // use any because antd option use DefaultOptionType (value is ReactNode, not string)
        onChange={(id, option: any) => {
          const { pathname } = history.location;
          setProjectId(id);
          setCurrentProject({
            projectTitle: option.label,
            projectId: option.value,
          });
          localStorage.setItem(LS_PROJECT_ID, id.toString());
          history.replace(pathname);
        }}
        showSearch
        filterOption={filterSelect}
        options={projects.map(project => ({
          label: project.projectTitle,
          value: project.projectId,
        }))}
        dropdownRender={originNode => {
          return (
            <>
              {originNode}
              {canCreateProject && (
                <Link
                  style={{
                    padding: '5px 12px',
                    minHeight: 32,
                    display: 'block',
                    borderTop: '1px solid rgba(0, 0, 0, 0.15)',
                  }}
                  onClick={() => {
                    trackProjectManagerAction(
                      PROJECT_MANAGER_TRACKING_DATA.create,
                      PROJECT_MANAGER_TRACKING_DATA.create.contentName
                    );
                    setOpen(true);
                  }}
                >
                  <PlusOutlined style={{ marginRight: 8 }} />
                  {t('AddNewProject')}
                </Link>
              )}
            </>
          );
        }}
        {...props}
      />
      {isCAR418Enabled ? (
        <ProjectUpsertionDrawer isEdit={false} open={open} setOpen={setOpen} />
      ) : (
        <ProjectListAddForm
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          isOpen={open}
        />
      )}
    </>
  ) : (
    <></>
  );
};

export default ProjectSelect;
