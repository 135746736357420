import React from 'react';
import Container, { ContainerProps } from '../shared/Container';
import Detail, { DetailProps } from './Detail';
import { useParams } from 'react-router-dom';
import { t } from 'helpers/i18n';
import { Typography } from 'antd';

interface DetailWrapProps extends Pick<DetailProps, 'mode'>, ContainerProps {}

const { Title } = Typography;

const DetailWrap: React.FC<DetailWrapProps> = ({ mode, ...props }) => {
  const { id } = useParams<{ id: string }>();
  const isEdit = mode === 'EDIT';

  return (
    <Container
      {...props}
      showSelectTemplate={true}
      title={<Title level={5}>{t('FormDetails')}</Title>}
      disabledProject={isEdit}
      disabledTemplate={isEdit}
    >
      <Detail mode={mode} formSubmissionId={id ? Number(id) : undefined} />
    </Container>
  );
};

export default DetailWrap;
