import { Spin } from 'antd';
import { BaseMapLayerType, MapPolygons } from 'components/shared/Map';
import { LegendPlacement } from 'components/shared/Map/constants';
import { DEBOUNCE_TIME } from 'constants/common';
import { BASEMAP_TYPE, MAPPING_LAYER_TO_BASEMAP } from 'constants/map';
import { useFlagsupContext } from 'contexts';
import { legendHooks, mapHooks } from 'hooks';
import { IGetBasemapParams } from 'interfaces';
import React, { Dispatch, SetStateAction } from 'react';
import { useMap, useMapEvents } from 'react-leaflet';
import { useDebouncedCallback } from 'use-debounce';

interface BaseMapPolygonsProps {
  basemapLayerType: BaseMapLayerType;
  legendPlacement?: LegendPlacement;
  viewARREligible?: boolean;
  setViewARREligible?: Dispatch<SetStateAction<boolean>>;
  showARREligibleSwitch?: boolean;
}

const BaseMapPolygons: React.FC<BaseMapPolygonsProps> = ({
  basemapLayerType,
  legendPlacement,
  viewARREligible,
  setViewARREligible,
  showARREligibleSwitch,
}) => {
  const map = useMap();
  const { featureFlagsData } = useFlagsupContext();
  const isCARSA243Enabled = featureFlagsData.CARSA_243_VIEWPORT?.enabled;
  const basemapType = viewARREligible
    ? BASEMAP_TYPE.ARR_ELIGIBLE
    : MAPPING_LAYER_TO_BASEMAP[basemapLayerType];
  const {
    getBasemap,
    basemap,
    loading: loadingBasemapPolygon,
  } = mapHooks.useBasemapTekbone(basemapType);
  const { legends } = legendHooks.useLegend(basemapType);

  const debouncedMoveHandler = useDebouncedCallback(async () => {
    let payload: Omit<IGetBasemapParams, 'projectId'> = {
      basemapType,
    };
    if (isCARSA243Enabled) {
      const southWest = map.getBounds().getSouthWest();
      const northEast = map.getBounds().getNorthEast();
      payload = {
        ...payload,
        zIndexes: [map.getZoom()],
        searchLatitudeMin: southWest.lat, // min lat
        searchLongitudeMin: southWest.lng, // min lng
        searchLatitudeMax: northEast.lat, // max lat
        searchLongitudeMax: northEast.lng, // max lng
      };
    }
    await getBasemap(payload);
  }, DEBOUNCE_TIME);

  useMapEvents({
    move: () => {
      if (isCARSA243Enabled) debouncedMoveHandler();
    },
  });

  return (
    <>
      {loadingBasemapPolygon && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 750, // stack under legends and layer switcher
          }}
        >
          <Spin />
        </div>
      )}
      <MapPolygons
        polygons={basemap?.polygons}
        legends={legends}
        showLegends={true}
        legendPlacement={legendPlacement}
        showARREligibleSwitch={showARREligibleSwitch}
        setViewARREligible={setViewARREligible}
        viewARREligible={viewARREligible}
      />
    </>
  );
};

export default BaseMapPolygons;
