import { AxiosRequestConfig } from 'axios';
import { getData } from 'helpers/request';
import {
  IBaseApiResponse,
  IGetFarmDetailsResponse,
  IGetFarmParams,
  IGetFarmResponse,
  IGetNearbyFarmsRequest,
  IGetNearbyFarmsResponse,
  IGetOverlappedFarmParams,
  IGetOverlappedFarmResponse,
  IUpdateFarmMappingStatusParams,
  IUpsertFarmMappingFormParams,
} from 'interfaces';
import { requestServices } from 'services';

const { projectManagerClient } = requestServices;

const getFarms = (
  params: IGetFarmParams,
  config?: AxiosRequestConfig
): Promise<IGetFarmResponse> => {
  return projectManagerClient
    .post('/tekbone/api/v1/search-farm-mapping', params, config)
    .then(getData);
};

const getFarmDetails = (
  farmId: number,
  projectId?: number
): Promise<IGetFarmDetailsResponse> => {
  return projectManagerClient
    .get(`/tekbone/api/v1/get-farm-details/${farmId}`, {
      params: {
        projectId,
      },
    })
    .then(getData);
};

const updateFarmLandMappingStatus = (
  farmLandId: number,
  params: IUpdateFarmMappingStatusParams
): Promise<IBaseApiResponse> => {
  return projectManagerClient.post(
    `/tekbone/api/v1/update-farm-mapping-status/${farmLandId}`,
    params
  );
};

const upsertFarmMappingForm = (params: IUpsertFarmMappingFormParams) => {
  return projectManagerClient.post(
    '/tekbone/api/v1/upsert-farm-mapping-form',
    params
  );
};

const getOverlappedFarms = (
  params: IGetOverlappedFarmParams
): Promise<IGetOverlappedFarmResponse> => {
  return projectManagerClient
    .post('/tekbone/api/v1/get-overlapped-farms', params)
    .then(getData);
};

const getNearbyFarms = (
  params: IGetNearbyFarmsRequest
): Promise<IGetNearbyFarmsResponse> => {
  return projectManagerClient
    .get('/tekbone/api/v1/get-nearby-farms', { params })
    .then(getData);
};

export default {
  getFarms,
  getFarmDetails,
  updateFarmLandMappingStatus,
  upsertFarmMappingForm,
  getOverlappedFarms,
  getNearbyFarms,
};
