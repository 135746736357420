import React from 'react';
import CreatePage from 'components/FPIC/Create';

interface CreateProps {
  children?: React.ReactNode;
}

const Create: React.FC<CreateProps> = () => {
  return <CreatePage />;
};

export default Create;
