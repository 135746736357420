import { Button, Card, Flex, Form, notification } from 'antd';
import { UpsertRoleGroupForm } from 'components/RoleGroupManagement';
import AppContainer from 'containers/AppLayout/AppContainer';
import { t } from 'helpers/i18n';
import {
  IGetOperationRoleResponse,
  IUpdateOperationRolePayload,
} from 'interfaces';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { userManagementServices } from 'services';

const EditRoleGroup: React.FC = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const roleId = Number(id);
  const [roleGroupDetails, setRoleGroupDetails] = useState<
    IGetOperationRoleResponse
  >();

  useEffect(() => {
    const getRoleGroup = async () => {
      try {
        setLoading(true);
        const data = await userManagementServices.getOperationRole(roleId);
        form.setFieldsValue(data);
        setRoleGroupDetails(data);
      } finally {
        setLoading(false);
      }
    };

    if (roleId) {
      getRoleGroup();
    }
  }, []);

  const handleUpdate = async (
    values: Omit<IUpdateOperationRolePayload, 'roleId'>
  ) => {
    try {
      setLoading(true);
      await userManagementServices.updateOperationRole({
        roleId,
        ...values,
      });
      notification.success({
        message: t('UpdateRoleGroupSuccessfully'),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppContainer>
      <Card classNames={{ body: 'p-base' }}>
        <Flex justify="end" gap={16}>
          <Button onClick={() => history.push(`/accounts/role-groups`)}>
            {t('Back')}
          </Button>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            {t('Save')}
          </Button>
        </Flex>
        <UpsertRoleGroupForm
          form={form}
          onFinish={handleUpdate}
          projects={roleGroupDetails?.projects}
          communities={roleGroupDetails?.communities}
        />
      </Card>
    </AppContainer>
  );
};

export default EditRoleGroup;
