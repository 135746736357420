import {
  IApiResponse,
  IGetBasemapParams,
  IGetBasemapResponse,
} from 'interfaces';
import { requestServices } from '../index';
import { getData } from 'helpers/request';

const { projectManagerClient } = requestServices;

const getBasemap = (
  params: IGetBasemapParams
): Promise<IApiResponse<IGetBasemapResponse>> => {
  return projectManagerClient.get(`/api/v1/basemap`, {
    params,
  });
};

const getBasemapTekbone = (
  params: IGetBasemapParams
): Promise<IGetBasemapResponse> => {
  return projectManagerClient
    .post(`/tekbone/api/v1/get-basemap-polygons`, params)
    .then(getData);
};

export default { getBasemap, getBasemapTekbone };
