import { commonConstants } from 'constants/index';
import Home from 'containers/Home';
import { t } from 'helpers/i18n';
import { ajaRoutes } from './aja';
import { baseRoutes } from './base';
import { demoRoutes } from './demo';
import { wbRoutes } from './wb';
import { waRoutes } from './wa';
import { accRoutes } from './acc';

const { APPS } = commonConstants;

const extraRoutes = () => {
  switch (window.appConfig.appName) {
    case APPS.AJA:
      return ajaRoutes;
    case APPS.BASE:
      return baseRoutes;
    case APPS.DEMO:
      return demoRoutes;
    case APPS.WB:
      return wbRoutes;
    case APPS.WA:
      return waRoutes;
    case APPS.ACC:
      return accRoutes;
    default:
      return [];
  }
};

/*
 * If route has children, it's a parent menu (not link to any pages)
 * You can change permissions to your IAM's permissions
 */
const routes = [
  // This is a menu/route which has no children (sub-menu)
  {
    exact: true,
    path: '/',
    name: t('Home'),
    component: Home,
  },
  ...extraRoutes(),
];

export default routes;
