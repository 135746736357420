import { Card, Result, Skeleton, Space } from 'antd';
import { commonConstants } from 'constants/index';
import { t } from 'helpers/i18n';
import { userHooks } from 'hooks';
import { IProjectDetails } from 'interfaces';
import React, { useEffect, useState } from 'react';
import ProjectPolygonTable from '../ProjectModal/ProjectPolygonTable';
import ProjectPolygonUpload from './ProjectPolygonUpload';
import { useProjectDetail } from './hooks';
import { ID_DEFAULT } from 'constants/common';

const { useUserPermissions } = userHooks;

const { IS_AJA } = commonConstants;

interface ProjectDetailMapProps {
  children?: React.ReactNode;
  projectId: number;
  setProject: (_?: IProjectDetails) => void;
}

const cardProps = {
  bordered: false,
  bodyStyle: {
    padding: 16,
  },
};

const ProjectDetailMap: React.FC<ProjectDetailMapProps> = ({
  projectId,
  setProject,
}) => {
  const { canUploadProject } = useUserPermissions();
  const { project, loading } = useProjectDetail(projectId);
  const [shouldRerenderPolygonTable, setShouldRerenderPolygonTable] = useState<
    number
  >(0);

  useEffect(() => {
    setProject(project);
  }, [project]);

  if (projectId === ID_DEFAULT)
    return <Result status="404" title={t('ThereAreNoProjects')} />;

  return loading ? (
    <Skeleton
      active={loading}
      style={{
        width: '100%',
      }}
    />
  ) : !project ? (
    <Result status="404" title={t('ThereAreNoProjects')} />
  ) : (
    <Space direction={'vertical'} style={{ width: '100%' }}>
      {(!IS_AJA || canUploadProject) && (
        <Card {...cardProps}>
          <ProjectPolygonUpload
            projectId={projectId}
            shouldRerenderPolygonTable={shouldRerenderPolygonTable}
            setShouldRerenderPolygonTable={setShouldRerenderPolygonTable}
          />
        </Card>
      )}
      <Card {...cardProps}>
        <ProjectPolygonTable
          projectId={projectId}
          projectTitle={project.projectTitle || ''}
          shouldRerender={shouldRerenderPolygonTable}
        />
      </Card>
    </Space>
  );
};

export default ProjectDetailMap;
