import { InboxOutlined } from '@ant-design/icons';
import { Button, Flex, Space, Spin, Typography } from 'antd';
import { t } from 'helpers/i18n';
import React from 'react';
import { useProjectDetailV2Context } from '../context';

interface AddPolygonScreenProps {
  setShowAddPolygonScreen: React.Dispatch<React.SetStateAction<boolean>>;
  setTriggered: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
}

const AddPolygonScreen: React.FC<AddPolygonScreenProps> = ({
  setShowAddPolygonScreen,
  setTriggered,
  loading,
}) => {
  const { setShowAddPolygon } = useProjectDetailV2Context();

  return (
    <Flex justify="center" align="center" className="h-100">
      {loading ? (
        <Spin spinning={loading} />
      ) : (
        <Space direction="vertical" align="center">
          <InboxOutlined style={{ fontSize: 64, color: '#1677FF' }} />
          <Typography.Text>{t('NoData')}</Typography.Text>
          <Typography.Text className="d-block">
            {t('PleaseClick')}{' '}
            <Button
              type="link"
              className="p-0"
              onClick={() => {
                setShowAddPolygonScreen(false);
                setShowAddPolygon(true);
                setTriggered(true);
              }}
            >
              {t('Here')}
            </Button>{' '}
            {t('ToAddYourProjectArea')}
          </Typography.Text>
        </Space>
      )}
    </Flex>
  );
};

export { AddPolygonScreen };
export default AddPolygonScreen;
