import { FormSubmissionStatus } from 'interfaces';

const STATUS_COLOR: {
  [key in FormSubmissionStatus]: {
    tag: string;
    background: string;
  };
} = {
  DRAFT: {
    tag: 'default',
    background: 'rgba(0,0,0,.06)',
  },
  REJECTED: {
    tag: 'error',
    background: '#fff2f0',
  },
  NEED_TO_UPDATE: {
    tag: 'warning',
    background: '#fffbe6',
  },
  IN_REVIEW: {
    tag: 'processing',
    background: '#e6f4ff',
  },
  APPROVED: {
    tag: 'success',
    background: '#f6ffed',
  },
};

const REGEX_PATTERN_NUMBER_FORMAT = /(?<!\.\d+)\B(?=(\d{3})+(?!\d))/g;

export { STATUS_COLOR, REGEX_PATTERN_NUMBER_FORMAT };
