/** Third party libs * */
import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Table, Button, Space, Modal } from 'antd';
import {
  DeleteOutlined,
  PlayCircleOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { v4 } from 'uuid';

/** Local libs * */
import { t } from 'helpers/i18n';
import { IObjectValue } from 'interfaces';

/** Components * */
import { MediaPlayer } from './MediaPlayer';
import { BaseItemProps, BaseItem } from '../BaseItem';
import Transcribe, { OCRAndTranscribeItem } from '../../OCRAndTranscribe';
import { Upload as MyUpload, UploadFile } from 'components/shared/Upload';

/** Styles * */

/** Interfaces, enum... * */
interface MediaFileProps extends BaseItemProps {}

export interface IMedia extends Partial<IObjectValue> {
  url: string;
  id: string;
}

/** Variables * */
const FILE_ACCEPT = '.mp3,.mp4,.mov';
const MAX_SIZE = 100;

/** ------------------------- * */
const MediaFile: React.FC<MediaFileProps> = ({
  item,
  disabled,
  form,
  ...props
}) => {
  /** States * */
  const [transcribeOpen, setTranscribeOpen] = useState(false);
  const [listMedia, setListMedia] = useState<IMedia[]>([]);
  const [mediaOpen, setMediaOpen] = useState<boolean>(false);
  const [mediaSelected, setMediaSelected] = useState<
    OCRAndTranscribeItem | undefined
  >(undefined);

  /** Hooks * */
  // const { uploading, googleUploadFileUrl } = useUploadFile();

  /** Variables * */

  const columns: ColumnsType<IMedia> = [
    {
      title: '',
      align: 'center',
      width: 50,
      render: (value, record, index) => {
        return index + 1;
      },
    },
    {
      title: t('File'),
      dataIndex: 'url',
      ellipsis: true,
      render: (value, record, index) => {
        if (!value) return '';

        const $value = (value || '').toString();

        const filename = $value.substring($value.lastIndexOf('/') + 1);
        return (
          <a href={$value} key={index} target={'_blank'} rel="noreferrer">
            {filename}
          </a>
        );
      },
    },
    {
      title: t('Actions'),
      align: 'center',
      width: 150,
      render: (value, record) => {
        return (
          <Space size={'small'}>
            <Button
              icon={<PlayCircleOutlined />}
              ghost={true}
              type={'link'}
              onClick={() => onPlayMedia(record)}
            />
            {record.isAnalyzed && (
              <Button
                icon={<FileTextOutlined />}
                ghost={true}
                type={'link'}
                onClick={() => onTranscribeMedia(record)}
              />
            )}
            {!disabled && (
              <Button
                type={'link'}
                danger={true}
                icon={<DeleteOutlined />}
                size={'small'}
                onClick={() => onDeleteItem(record)}
              />
            )}
          </Space>
        );
      },
    },
  ];

  /** Functions, Events, Actions... * */
  const onDeleteItem = (item: IMedia) => {
    let newListMedia = [...listMedia];
    newListMedia = newListMedia.filter(value => value.id !== item.id);
    setListMedia(newListMedia);
    updateDataForm(newListMedia);
  };

  const onPlayMedia = (media: IMedia) => {
    setMediaSelected({
      id: media.analyzedDataId,
      url: media.url,
      isAnalyzed: media.isAnalyzed,
    });
    setMediaOpen(true);
  };

  const onTranscribeMedia = (media: IMedia) => {
    setMediaSelected({
      id: media.analyzedDataId,
      url: media.url,
      isAnalyzed: media.isAnalyzed,
    });
    setTranscribeOpen(true);
  };

  const updateDataForm = (list: IMedia[]) => {
    if (item.fieldName)
      form.setFieldValue(
        item.fieldName,
        JSON.stringify(list.map(item => item.url))
      );
  };

  const onUploadSuccess = (files: UploadFile[]) => {
    const list: IMedia[] = files.reduce((list, file) => {
      if (file.fileUrl) {
        list.push({
          id: file.uid,
          url: file.fileUrl,
        });
      }

      return list;
    }, [] as IMedia[]);
    setListMedia(listMedia => {
      return [...listMedia, ...list];
    });

    updateDataForm([...listMedia, ...list]);
  };

  /** Effects * */
  useEffect(() => {
    if (item.objectValues && item.objectValues.length) {
      if (item.fieldName) {
        form.setFieldValue(
          item.fieldName,
          JSON.stringify(item.objectValues.map(item => item.analyzedDataUrl))
        );
      }

      setListMedia(
        item.objectValues.map(item => ({
          ...item,
          id: v4(),
          url: item.analyzedDataUrl.trim(),
        }))
      );
    } else {
      if (item.fieldName) {
        form.setFieldValue(item.fieldName, item.value);
      }

      if (item.value) {
        try {
          const values = JSON.parse(item.value);
          setListMedia(
            (values || []).map((value: string) => ({
              id: v4(),
              url: value.trim(),
            }))
          );
        } catch (e) {}
      }
    }
  }, [item.value, item.objectValues]);

  /** Elements * */
  return (
    <BaseItem item={item} disabled={disabled} form={form} {...props}>
      <span>
        {!disabled && (
          <MyUpload
            accept={FILE_ACCEPT}
            maxSize={MAX_SIZE}
            disabled={disabled}
            onSuccess={onUploadSuccess}
          />
        )}
        {listMedia.length > 0 && (
          <Modal
            open={mediaOpen}
            footer={null}
            title={t('Media')}
            onCancel={() => {
              setMediaOpen(false);
            }}
            destroyOnClose={true}
          >
            {mediaSelected && <MediaPlayer url={mediaSelected.url} />}
          </Modal>
        )}
        <Table
          style={{
            marginTop: 12,
          }}
          columns={columns}
          rowKey={'url'}
          size={'small'}
          bordered={true}
          dataSource={listMedia}
          pagination={false}
          locale={{
            emptyText: t('NoData'),
          }}
        />
        <Transcribe
          type={'transcribe'}
          item={mediaSelected}
          mode={props.mode}
          formSubmission={props.formSubmission}
          open={transcribeOpen}
          setOpen={setTranscribeOpen}
        />
      </span>
    </BaseItem>
  );
};

export default MediaFile;
