import { DownOutlined } from '@ant-design/icons';
import { Button, Card, Drawer, List, ListProps, Row, Typography } from 'antd';
import FinancialPlanDetail from 'components/FinancialPlan/FinancialPlanDetail';
import { DRAWER_WIDTH } from 'constants/common';
import { convertNumberFormat } from 'helpers/common';
import { t } from 'helpers/i18n';
import { userHooks } from 'hooks';
import { ActivityStatus, IActivity } from 'interfaces';
import { useState } from 'react';
import ApprovalActivityButtons from './ApprovalActivityButton';

interface IFinancialPlanListProps extends ListProps<IActivity> {
  disabledApproval: boolean;
  showLoadMore?: boolean;
  loadMore?: () => void;
  refresh: () => void;
}

const { useUserPermissions } = userHooks;

const FinancialPlanList: React.FC<IFinancialPlanListProps> = ({
  showLoadMore,
  loadMore,
  refresh,
  disabledApproval,
  ...listProps
}) => {
  const { canApproveActivity } = useUserPermissions();
  const [activityDetail, setActivityDetail] = useState<number | null>(null);

  return (
    <>
      {/* Financial Plan List */}
      <List
        className="financial-plan-list"
        itemLayout="horizontal"
        split={false}
        renderItem={item => (
          <Card>
            <List.Item
              key={item.activityId}
              actions={
                canApproveActivity && item.status === ActivityStatus.IN_REVIEW
                  ? [
                      <ApprovalActivityButtons
                        disabled={disabledApproval}
                        activityId={item.activityId}
                        onApprovalSuccess={refresh}
                      />,
                    ]
                  : []
              }
            >
              <List.Item.Meta
                description={
                  <div className="d-flex flex-column">
                    <Typography.Link
                      strong
                      onClick={() => setActivityDetail(item.activityId)}
                    >
                      {item.activityName}
                    </Typography.Link>
                    <span>{item.description}</span>
                    <span>
                      {t('Amount')}:{' '}
                      <b>{convertNumberFormat(item.proposedAmount)} VND</b>
                    </span>
                  </div>
                }
              />
            </List.Item>
          </Card>
        )}
        loadMore={
          <Row justify="center">
            {!!showLoadMore && (
              <Button
                type="link"
                className="p-0"
                icon={<DownOutlined />}
                onClick={loadMore}
              >
                {t('ViewMore')}
              </Button>
            )}
          </Row>
        }
        {...listProps}
      />
      {/* Financial Plan Detail */}
      <Drawer
        open={!!activityDetail}
        onClose={() => setActivityDetail(null)}
        closable={false}
        width={DRAWER_WIDTH.MEDIUM}
        classNames={{ body: 'overflow-hidden d-flex flex-column' }}
      >
        {activityDetail && <FinancialPlanDetail activityId={activityDetail} />}
      </Drawer>
    </>
  );
};

export default FinancialPlanList;
