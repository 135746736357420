import { Form, Typography } from 'antd';
import { mpiHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import React from 'react';

const { getMPI } = mpiHelpers;

const { Title, Link } = Typography;
const REFERENCE_LINK =
  'https://www.pep-net.org/sites/pep-net.org/files/typo3doc/pdf/files_events/2018_Bangalore_Conference/CMReyes_CBMS-MPI.pdf';

const MPIFormula: React.FC = () => {
  return (
    <Form layout={'vertical'}>
      <div>
        <Title level={5}>{t('MPIFormula')}: MPI = H * A</Title>
        <div className="mb-half">{t('Annotations')}: </div>
        <div className="mb-half">
          <b>H</b>: {t('HAnnotations')}
        </div>
        <div className="mb-half">
          <b>A</b>: {t('AAnnotations')}
        </div>
        <Link href={REFERENCE_LINK} target="_blank">
          {t('ReferenceForMore')}
        </Link>
      </div>
      <Title level={5} className="mt-base">
        {t('Result')}: {getMPI(2024)}
      </Title>
    </Form>
  );
};

export { MPIFormula };
