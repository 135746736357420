export const LIST_FARMER_TYPE_CONSTANT = {
  NEED_APPOINTMENT: 'NEED_APPOINTMENT',
  APPOINTMENT: 'APPOINTMENT',
};

export const DEFAULT_APPOINTMENT_STATUSES = [
  'SCHEDULED',
  'NEED_TO_UPDATE',
  'NEED_TO_REVIEW',
];

export default {
  LIST_FARMER_TYPE_CONSTANT,
  DEFAULT_APPOINTMENT_STATUSES,
};
