import {
  Button,
  Drawer,
  DrawerProps,
  Form,
  Input,
  InputNumber,
  notification,
} from 'antd';
import { tInputPlaceholder } from 'helpers/i18n';
import { useEffect, useState } from 'react';
import { communityServices } from 'services';
import { useFlagsupContext, useProjectContext } from 'contexts';
import { ImageUpload } from 'components/FormTemplateControl/ImageUpload';
import { CremaDrawer, IPOI, IUpsertCommunity } from 'interfaces';
import { useMapContext } from 'contexts/map';
import { validateCoordinates } from 'utils/formValidator';
import { CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { cremaConstants } from 'constants/index';
import { useCremaFormationContext } from 'contexts/cremaFormation';
import { MAX_FILE_UPLOAD } from 'constants/file';
import { DEBOUNCE_TIME } from 'constants/common';
import { useTranslation } from 'react-i18next';

const {
  MAX_LENGTH_CREMA_COMMUNITY_NAME,
  REGEX_PATTERN_CREMA_COMMUNITY_NAME,
} = cremaConstants;

type UpsertCommunityFormProps = Omit<
  IUpsertCommunity,
  'projectId' | 'communityImageId'
> & {
  poiId?: IPOI['poiId'];
  communityImageId?: string[];
};

interface UpsertCommunityProps extends DrawerProps {
  onUpsertSuccess?: (communityId: number) => void;
  initialValues?: Partial<UpsertCommunityFormProps>;
  onClose?: () => void;
}

const UpsertCommunity: React.FC<UpsertCommunityProps> = ({
  onUpsertSuccess,
  initialValues,
  ...drawerProps
}) => {
  const { t } = useTranslation(window.appConfig?.appName);
  const { projectId } = useProjectContext();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<UpsertCommunityFormProps>();
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [isUpsertSuccess, setIsUpsertSuccess] = useState(false);
  const {
    mode,
    pickLocation,
    addPOI,
    setPOI,
    removePOI,
    cancelPickLocation,
  } = useMapContext();
  const { toggleDrawer } = useCremaFormationContext();
  const { open, onClose } = drawerProps;

  const { featureFlagsData } = useFlagsupContext();
  const isCAR1514Enabled = featureFlagsData.CAR_1514?.enabled;

  useEffect(() => {
    if (initialValues?.communityId) {
      form.setFieldsValue(initialValues);
    }
  }, [JSON.stringify(initialValues)]);

  const upsertCommunity = async ({
    communityId,
    communityImageId,
    communityName,
    communityLatitude,
    communityLongitude,
  }: UpsertCommunityFormProps) => {
    try {
      setLoading(true);

      const params = {
        projectId,
        communityId,
        communityName,
        communityLatitude,
        communityLongitude,
        communityImageId: communityImageId?.[0],
      };

      if (communityId) {
        isCAR1514Enabled
          ? await communityServices.updateCommunity({ ...params, communityId })
          : await communityServices.upsertCommunity(params);

        notification.success({ message: t('UpdateCommunitySuccessfully') });
        onUpsertSuccess?.(communityId);
      } else {
        const {
          communityId: createdCommunityId,
        } = await communityServices.upsertCommunity(params);

        notification.success({ message: t('CreateCommunitySuccessfully') });
        onUpsertSuccess?.(createdCommunityId);
      }
      setIsUpsertSuccess(true);
      onClose?.();
    } finally {
      setLoading(false);
    }
  };

  const enablePickMode = () => {
    const { poiId, communityName, communityId } = form.getFieldsValue();
    communityId && toggleDrawer(CremaDrawer.CommunityDetail, { open: false });
    pickLocation?.({ poiId, placeName: communityName }).then((poi: IPOI) => {
      form.setFieldsValue({
        poiId: poi.poiId,
        communityLatitude: poi.latitude,
        communityLongitude: poi.longitude,
      });
      communityId && toggleDrawer(CremaDrawer.CommunityDetail);
      validateCommunity();
    });
  };

  const resetForm = () => {
    const { poiId } = form.getFieldsValue();
    form.resetFields();
    // Reset POI to initial coordinates if it's not saved
    if (!!initialValues?.poiId && !isUpsertSuccess) {
      setPOI({
        poiId: poiId ?? '',
        latitude: initialValues.communityLatitude,
        longitude: initialValues.communityLongitude,
        placeName: initialValues.communityName,
      });
    } else {
      removePOI(poiId ?? '');
    }
    setIsUpsertSuccess(false);
    cancelPickLocation();
  };

  const onFormValuesChange = async (
    changedValues: Partial<UpsertCommunityFormProps>,
    values: UpsertCommunityFormProps
  ) => {
    const {
      poiId,
      communityName,
      communityLatitude,
      communityLongitude,
    } = values;

    const coordinateErrors = form.getFieldsError([
      'communityLatitude',
      'communityLongitude',
    ]);
    // Clear errors when latitude or longitude is changed
    if (
      coordinateErrors.length > 0 &&
      (changedValues.hasOwnProperty('communityLatitude') ||
        changedValues.hasOwnProperty('communityLongitude'))
    ) {
      form.setFields(
        coordinateErrors.map(({ name }) => ({ name, errors: [] }))
      );
    }

    if (!poiId && communityLatitude && communityLongitude) {
      const newPoiId = addPOI?.({
        latitude: communityLatitude,
        longitude: communityLongitude,
        placeName: communityName,
      });
      form.setFieldValue('poiId', newPoiId);
    }
    if (poiId) {
      setPOI({
        poiId,
        placeName: communityName,
        latitude: Number(communityLatitude),
        longitude: Number(communityLongitude),
      });
    }
  };

  const validateCommunity = async () => {
    try {
      setLoading(true);
      const { invalidCases } = await communityServices.validateCommunity({
        ...form.getFieldsValue(),
        projectId,
        communityImageId: undefined,
      });
      if (!!invalidCases.length) {
        form.setFields(
          invalidCases.map(invalidCase => ({
            name: invalidCase.fieldName,
            errors: [t(invalidCase.error)],
          }))
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      open={open}
      destroyOnClose={true}
      maskClosable={false}
      title={
        initialValues?.communityId ? t('EditCommunity') : t('CreateCommunity')
      }
      getContainer={false}
      zIndex={1002}
      mask={false}
      onClose={() => {
        resetForm();
        onClose?.();
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={upsertCommunity}
        initialValues={initialValues}
        onValuesChange={onFormValuesChange}
      >
        <Form.Item name="communityId" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="communityName"
          label={t('CommunityName')}
          rules={[
            {
              required: true,
              whitespace: true,
              message: tInputPlaceholder('CommunityName', {
                ns: window.appConfig?.appName,
              }),
            },
            {
              max: MAX_LENGTH_CREMA_COMMUNITY_NAME,
              message: t('CommunityNameTooLong'),
            },
            {
              pattern: REGEX_PATTERN_CREMA_COMMUNITY_NAME,
              message: t('CommunityNameIsInvalid'),
            },
            {
              validator: (_, value) => {
                if (value.trim()) {
                  validateCommunity();
                }
                return Promise.resolve();
              },
            },
          ]}
          validateDebounce={DEBOUNCE_TIME}
          className="mb-half"
        >
          <Input
            placeholder={tInputPlaceholder('CommunityName', {
              ns: window.appConfig?.appName,
            })}
          />
        </Form.Item>
        <Button
          type="text"
          onClick={enablePickMode}
          disabled={mode === 'locationPick'}
          size="small"
          icon={<InfoCircleOutlined />}
          className="mb-half"
        >
          {t('PickPoiOnMap')} {mode === 'locationPick' && '(Picking...)'}
        </Button>
        {mode === 'locationPick' && (
          <Button
            size="small"
            type="text"
            icon={<CloseCircleOutlined />}
            onClick={() => cancelPickLocation()}
            danger
          />
        )}
        <Form.Item name="poiId" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="communityLatitude"
          label={t('Latitude')}
          rules={[
            { required: true },
            { validator: validateCoordinates(-90, 90, 'Latitude') },
            {
              validator: (_, value) => {
                if (value) {
                  validateCommunity();
                }
                return Promise.resolve();
              },
            },
          ]}
          validateDebounce={DEBOUNCE_TIME}
          className="mb-half"
        >
          <InputNumber placeholder="0.000000" maxLength={20} />
        </Form.Item>
        <Form.Item
          name="communityLongitude"
          label={t('Longitude')}
          rules={[
            { required: true },
            { validator: validateCoordinates(-180, 180, 'Longitude') },
            {
              validator: (_, value) => {
                if (value) {
                  validateCommunity();
                }
                return Promise.resolve();
              },
            },
          ]}
          validateDebounce={DEBOUNCE_TIME}
          className="mb-half"
        >
          <InputNumber placeholder="0.000000" maxLength={20} />
        </Form.Item>
        <Form.Item label={t('Avatar')} name="communityImageId">
          <ImageUpload
            isPrivate
            maxCount={1}
            multiple={false}
            onUploadStateChange={setIsImageUploading}
            maxSize={MAX_FILE_UPLOAD}
          />
        </Form.Item>
        <Form.Item className="text-center" shouldUpdate>
          {({ isFieldsTouched, getFieldsError, isFieldsValidating }) => {
            const hasErrors = Object.values(getFieldsError()).some(
              ({ errors }) => errors.length
            );
            return (
              <Button
                type="primary"
                onClick={form.submit}
                loading={loading}
                disabled={
                  !isFieldsTouched() ||
                  isImageUploading ||
                  hasErrors ||
                  isFieldsValidating()
                }
              >
                {t('Save')}
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export { UpsertCommunity };
