import { Form, notification } from 'antd';
import { DEFAULT_PAGE, PAGING_PAGE_SIZE_5 } from 'constants/pagination';
import { t } from 'helpers/i18n';
import {
  IPaginationResponse,
  IGetCommentsParams,
  ICreateCommentBody,
  IComment,
  IFormSubmission,
} from 'interfaces';
import { useEffect, useMemo, useState } from 'react';

import { commentServices } from 'services';

interface ICommentFormValues {
  comment: string;
}

export const useComments = (formSubmission: IFormSubmission) => {
  const [commentForm] = Form.useForm<ICommentFormValues>();
  const [params, setParams] = useState<IGetCommentsParams>({
    ...PAGING_PAGE_SIZE_5,
    formSubmissionId: formSubmission.formSubmissionId,
  });
  const [fetchLoading, setFetchLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [comments, setComments] = useState<IComment[]>([]);
  const [pagination, setPagination] = useState<
    IPaginationResponse | undefined
  >();

  const isLoadedAll = useMemo(() => {
    if (fetchLoading) return params.page === DEFAULT_PAGE ? true : false;
    return (
      Number(pagination?.page) * Number(pagination?.pageSize) >=
      Number(pagination?.total)
    );
  }, [pagination, fetchLoading]);

  const fetchComments = async (params: IGetCommentsParams) => {
    try {
      setFetchLoading(true);
      const res = await commentServices.getComments(params);
      const data = res?.data?.data;
      const newComments = data?.comments || [];
      const pagination = data?.pagination;
      setComments(
        pagination.page === DEFAULT_PAGE
          ? newComments
          : [...comments, ...newComments]
      );
      setPagination(pagination);
    } catch (error) {
      console.error(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const loadMore = async () => {
    setParams(params => ({ ...params, page: Number(params?.page) + 1 }));
  };

  const createComment = async (body: ICreateCommentBody) => {
    try {
      setCreateLoading(true);
      await commentServices.createComment(body);
      notification.success({
        message: t('CreateCommentSuccess'),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setCreateLoading(false);
      fetchComments(params);
    }
  };

  const onSendComment = async (values: ICommentFormValues) => {
    await createComment({
      content: values.comment,
      formSubmissionId: Number(formSubmission?.formSubmissionId),
    });
    commentForm.resetFields();
  };

  useEffect(() => {
    if (params.formSubmissionId) fetchComments(params);
  }, [params]);

  useEffect(() => {
    if (formSubmission.formSubmissionId !== params.formSubmissionId) {
      setParams({
        ...PAGING_PAGE_SIZE_5,
        formSubmissionId: formSubmission.formSubmissionId,
      });
    }
  }, [formSubmission]);

  return {
    fetchLoading,
    createLoading,
    comments,
    pagination,
    setParams,
    createComment,
    params,
    loadMore,
    isLoadedAll,
    onSendComment,
    commentForm,
  };
};
