import {
  Button,
  Carousel,
  Col,
  Divider,
  Empty,
  Flex,
  Form,
  Image,
  Modal,
  ModalProps,
  notification,
  Row,
  Spin,
} from 'antd';
import { buttonStyle } from 'components/shared/Button';
import { FSImage } from 'components/shared/FSImage';
import { COLOR } from 'constants/styles/color';
import { useFlagsupContext, useProjectContext } from 'contexts';
import { t } from 'helpers/i18n';
import trackSubmissionDetail from 'helpers/tracker/trackSubmissionDetail';
import { submissionHooks } from 'hooks';
import { isNil } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSnapshotTreeItem,
  setSnapshotTreeItem,
} from 'redux/features/submission';
import { AppDispatch } from 'redux/store';
import { selectionServices } from 'services';
import RejectModal from './RejectModal';
import './SnapshotTree.scss';
import './SubmissionInfo.scss';
import SubmissionInfoCard, { TypeSave } from './SubmissionInfoCard';
import SubmissionMap from './SubmissionMap';

interface SnapshotTreeProps extends ModalProps {
  refetchSnapshotTrees: () => void;
}

const { trackTreeAction } = trackSubmissionDetail;
const { selectSingleTree } = selectionServices;

const LAYOUT_ONE_IMAGE = 1;
const LAYOUT_TWO_IMAGE = 2;

const SnapshotTree: React.FC<SnapshotTreeProps> = ({
  refetchSnapshotTrees,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const tree = useSelector(selectSnapshotTreeItem);
  const {
    getSnapshotTree,
    snapshotTree,
    loading,
  } = submissionHooks.useSnapshotTree();
  const [selectLoading, setSelectLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [rejectForm] = Form.useForm();
  const { projectId } = useProjectContext();
  const { featureFlagsData } = useFlagsupContext();
  const isRefetchSnapshotTrees = useRef(false);

  const isCAR2355Enabled = featureFlagsData.CAR_2355?.enabled;
  const imageCnt =
    snapshotTree?.checksumTreeImages?.length ||
    snapshotTree?.treeImages.length ||
    0;

  useEffect(() => {
    if (tree) getSnapshotTree(tree.treeId, tree.snapshotTreeId);
  }, [tree]);

  const handleRejectCancel = () => {
    setIsRejectModalOpen(false);
  };

  const showRejectModal = () => {
    rejectForm.resetFields();
    setIsRejectModalOpen(true);
  };

  const afterUpdateSuccessfully = (typeSave?: TypeSave) => {
    isRefetchSnapshotTrees.current = true;
    if (typeSave === 'species') {
      dispatch(setSnapshotTreeItem(undefined));
      refetchSnapshotTrees();
    }
  };

  const selectTree = async () => {
    if (!snapshotTree || !tree) return;
    trackTreeAction('select', snapshotTree.submissionId);
    try {
      setSelectLoading(true);
      const params = {
        projectId,
        submissionId: snapshotTree.submissionId,
        isSelected: true,
      };
      await selectSingleTree(params);
      notification.success({
        message: t('Success'),
        description: t('SuccessSelect'),
      });
      getSnapshotTree(tree.treeId, snapshotTree.submissionId);
      afterUpdateSuccessfully();
    } finally {
      setSelectLoading(false);
    }
  };

  const onReject = async (values: { rejectReason: string }) => {
    if (!snapshotTree || !tree) return;
    trackTreeAction('reject', snapshotTree.submissionId);
    try {
      setRejectLoading(true);
      const params = {
        projectId,
        submissionId: snapshotTree.submissionId,
        isSelected: false,
        rejectReason: values.rejectReason,
      };
      await selectSingleTree(params);
      notification.success({
        message: t('Success'),
        description: t('SuccessReject'),
      });
      getSnapshotTree(tree.treeId, snapshotTree.submissionId);
      afterUpdateSuccessfully();
      handleRejectCancel();
    } finally {
      setRejectLoading(false);
    }
  };

  const FooterDetail = ({
    rejectLoading,
    selectLoading,
  }: {
    rejectLoading: boolean;
    selectLoading: boolean;
  }) => {
    const isDisableAction =
      !isNil(snapshotTree?.isSelected) ||
      isNil(snapshotTree?.carbonContent) ||
      snapshotTree?.treeType === 'SNAG';

    return (
      <Flex className="mt-base" justify="end" gap="middle">
        <Button
          style={{
            ...buttonStyle,
            backgroundColor: isDisableAction ? COLOR.NEUTRAL : COLOR.ERROR,
          }}
          onClick={showRejectModal}
          disabled={isDisableAction || selectLoading}
          loading={rejectLoading}
        >
          {t('Reject')}
        </Button>
        <Button
          data-testid="btn-selected"
          style={{
            ...buttonStyle,
            backgroundColor: isDisableAction ? COLOR.NEUTRAL : COLOR.PRIMARY,
          }}
          disabled={isDisableAction || rejectLoading}
          onClick={selectTree}
          loading={selectLoading}
        >
          {t('Select')}
        </Button>
      </Flex>
    );
  };

  return (
    <>
      <Modal
        title={
          snapshotTree && (
            <h2 style={{ display: 'inline' }}>
              {t('Tree')} {snapshotTree.submissionId}
              {' - '}
              {snapshotTree.plotName}
              {' - '}
              {imageCnt} {t('Image')}
            </h2>
          )
        }
        onCancel={() => {
          dispatch(setSnapshotTreeItem(undefined));
          if (isRefetchSnapshotTrees.current) refetchSnapshotTrees();
        }}
        open={!!tree}
        width={'75%'}
        wrapClassName={isCAR2355Enabled ? 'submission-modal' : ''}
        centered={true}
        footer={null}
      >
        {loading ? (
          <Spin className="w-100 text-center" />
        ) : !snapshotTree ? (
          <Empty className="h-100" />
        ) : (
          <>
            <Divider />
            <Row gutter={[16, 16]}>
              <Col xs={24} xl={18}>
                <Spin spinning={rejectLoading || selectLoading} delay={500}>
                  <Image.PreviewGroup
                    preview={{
                      styles: { mask: { background: 'rgba(0,0,0,0.8)' } },
                    }}
                  >
                    <Carousel
                      arrows
                      infinite={false}
                      adaptiveHeight
                      dots={{ className: 'dots-tree-image' }}
                      slidesToShow={
                        snapshotTree.treeImages.length <= 1
                          ? LAYOUT_ONE_IMAGE
                          : LAYOUT_TWO_IMAGE
                      }
                      className="carousel-tree-image"
                    >
                      {snapshotTree.treeImages.map(treeImage => {
                        return (
                          <FSImage
                            src={treeImage.image}
                            key={treeImage.image}
                            height={500}
                          />
                        );
                      })}
                    </Carousel>
                  </Image.PreviewGroup>
                </Spin>
                <SubmissionInfoCard
                  currentSubmissionAfterCAR2355={snapshotTree}
                  afterUpdateSuccessfully={afterUpdateSuccessfully}
                />
                <FooterDetail
                  rejectLoading={rejectLoading}
                  selectLoading={selectLoading}
                />
              </Col>
              {isCAR2355Enabled && (
                <Col xs={24} xl={6}>
                  <SubmissionMap currentSubmission={snapshotTree} />
                </Col>
              )}
            </Row>
          </>
        )}
      </Modal>
      <RejectModal
        open={isRejectModalOpen}
        onCancel={handleRejectCancel}
        onFinish={onReject}
        form={rejectForm}
      />
    </>
  );
};

export default SnapshotTree;
export { SnapshotTree };
