/** Third party libs * */
import React, { useEffect, useState } from 'react';
import { Button, Space, Table, Row, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DeleteOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import { v4 } from 'uuid';

/** Local libs * */
import { t } from 'helpers/i18n';
import { BaseItemProps, BaseItem } from '../BaseItem';
import DatagridInput from './DatagridInput';

/** Components * */

/** Styles * */

/** ------------------------- * */
/** Interfaces, enum... * */
interface IDatagridProps extends BaseItemProps {
  readOnly?: boolean;
}
type IItem = {
  id: string;
} & any;

/** Variables * */
const { Text } = Typography;

/** ------------------------- * */
const Datagrid: React.FC<IDatagridProps> = ({
  item,
  disabled,
  form,
  readOnly,
  ...props
}) => {
  /** States * */
  const [dataSource, setDataSource] = useState<IItem[]>([]);

  /** Hooks * */

  /** Variables * */
  const renderDataSource = disabled
    ? dataSource.filter(item => !!item.id)
    : dataSource;
  const onAddNewItem = () => {
    setDataSource([
      ...dataSource,
      {
        id: v4(),
      },
    ]);
  };

  const onDeleteItem = (item: IItem) => {
    let newDataSource = [...dataSource];
    newDataSource = newDataSource.filter(
      (value: IItem) => value.id !== item.id
    );
    setDataSource(newDataSource);
    updateDataForm(newDataSource);
  };

  const onChange = debounce(
    (value: string | number, index: number, name?: string) => {
      updateDataSource(value, index, name);
    },
    500
  );

  const updateDataSource = (
    value: string | number,
    index: number,
    name?: string
  ) => {
    if (name) {
      const values = [...dataSource];
      values[index][name] = value;
      setDataSource(values);
      updateDataForm(values);
    }
  };

  const updateDataForm = (values: IItem[]) => {
    if (item.fieldName) {
      form.setFieldValue(
        item.fieldName,
        JSON.stringify(
          [...values].reduce((arr, { ...item }) => {
            delete item.id;
            if (Object.keys(item).length) {
              arr.push(item);
            }
            return arr;
          }, [])
        )
      );
    }
  };

  const columns: ColumnsType<IItem> = [
    ...(item?.children || []).map(child => ({
      key: child.fieldName || '',
      title: child.fieldLabel || '',
      dataIndex: child.fieldName,
      width: 300,
      render: (value: any, r: any, index: number) => {
        return (
          <DatagridInput
            fieldName={child.fieldName ?? ''}
            fieldType={child.fieldType}
            item={child}
            defaultValue={value}
            index={index}
            onChange={onChange}
            updateDataSource={updateDataSource}
            disabled={disabled}
            readOnly={readOnly}
          />
        );
      },
    })),
    {
      key: 'actions',
      title: t('Actions'),
      align: 'center',
      width: 90,
      render: (_, record, index) => {
        if (disabled || readOnly) return <></>;

        if (index === dataSource.length - 1)
          return (
            <Button type={'primary'} size={'small'} onClick={onAddNewItem}>
              {t('Add')}
            </Button>
          );

        return (
          <Space>
            <Button
              type={'link'}
              danger={true}
              disabled={disabled}
              icon={<DeleteOutlined />}
              size={'small'}
              onClick={() => onDeleteItem(record)}
            />
          </Space>
        );
      },
    },
  ];

  /** Effects * */
  useEffect(() => {
    try {
      if (item.fieldName) {
        form.setFieldValue(item.fieldName, item.value);
      }
      const value = item.value ? JSON.parse(item.value) : [];
      const list = value.map((item: IItem) => ({
        ...item,
        id: v4(),
      }));
      if (disabled || readOnly) {
        setDataSource([...list]);
      } else {
        setDataSource([
          ...list,
          {
            id: v4(),
          },
        ]);
      }
    } catch (e) {
    } finally {
    }
  }, [item.value]);

  /** Functions, Events, Actions... * */

  /** Elements * */
  return (
    <BaseItem
      item={item}
      form={form}
      disabled={disabled}
      className="data-grid"
      {...props}
    >
      <span>
        <Row justify={'end'} align={'middle'}>
          <Text>{`${t('Total')}: ${
            renderDataSource.filter(item => !!item.id).length
          }`}</Text>
        </Row>
        <Table
          style={{
            marginTop: '12px',
            overflowY: 'auto',
          }}
          columns={columns}
          dataSource={renderDataSource}
          pagination={false}
          size={'small'}
          rowKey={'id'}
          bordered={true}
          locale={{
            emptyText: t('NoData'),
          }}
        />
      </span>
    </BaseItem>
  );
};

export default Datagrid;
