export const WORLDBANK_USER_ROLES = {
  BOARD_MEMBER: 'wb:community_board_member',
  FOREST_OWNER: 'wb:forest_owner',
  PROVINCIAL_FUND: 'wb:provincial_fund',
  COMMUNE_COMMITTEE: 'wb:commune_people_committee',
};

export const CEC_ROLES = [
  { value: 'Patron', label: 'Patron' },
  { value: 'Chairperson', label: 'Chairperson' },
  { value: 'Vice Chair', label: 'Vice Chair' },
  { value: 'Secretary', label: 'Secretary' },
  { value: 'Deputy Secretary', label: 'Deputy Secretary' },
  { value: 'Treasurer', label: 'Treasurer' },
  { value: 'Member', label: 'Member' },
  { value: 'Monitoring Team member', label: 'Monitoring Team member' },
  { value: 'Patrol Team member', label: 'Patrol Team member' },
  {
    value: 'Fire Management Team member',
    label: 'Fire Management Team member',
  },
  { value: 'Other', label: 'Other' },
];

export const CRMC_ROLES = [
  {
    value: 'Traditional authority representative',
    label: 'Traditional authority representative',
  },
  { value: 'Women representative', label: 'Women representative' },
  { value: 'Youth representative', label: 'Youth representative' },
  { value: "Farmers' representative", label: "Farmers' representative" },
  { value: "Hunters' representative", label: "Hunters' representative" },
  { value: 'Other', label: 'Other' },
];

export default {
  WORLDBANK_USER_ROLES,
  CRMC_ROLES,
  CEC_ROLES,
};
