export const DEFAULT_PAGE = 1;

export const DEFAULT_PAGE_SIZE = 10;

export const PAGE_SIZE_ALL = 1000;

export const DEFAULT_OFFSET = 0;

export const DEFAULT_OPTION = {
  page: DEFAULT_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
};

export const DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 50];

export const PAGINATION = {
  PAGE_SIZE: DEFAULT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS: DEFAULT_PAGE_SIZE_OPTIONS,
};

export const DEFAULT_PAGINATION_OFFSET = {
  offset: 0,
  limit: 10,
};

export const PAGING_OFFSET_ALL = {
  offset: 0,
  limit: 1000,
};

export const PAGING_PAGE_SIZE_ALL = {
  page: DEFAULT_PAGE,
  pageSize: 1000,
};

export const PAGING_PAGE_SIZE_5 = {
  page: DEFAULT_PAGE,
  pageSize: 5,
};

export const PAGINATION_OFFSET_LIMIT_5 = {
  total: 0,
  limit: 5,
  offset: 0,
};

export const PAGINATION_TOTAL_DEFAULT = {
  page: DEFAULT_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
  total: 0,
};
