import { requestServices } from 'services';
import { IFarmSurveyReport, IGetSurveyReportParams } from 'interfaces';
import { getData } from 'helpers/request';

const { projectManagerClient } = requestServices;

const getFarmlandSurveyReport = (
  params: IGetSurveyReportParams
): Promise<IFarmSurveyReport> => {
  return projectManagerClient
    .get('/tekbone/api/v1/land-cover-info', {
      params,
    })
    .then(getData);
};

export default {
  getFarmlandSurveyReport,
};
