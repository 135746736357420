import { ID_DEFAULT } from 'constants/common';
import localStorageConstants from 'constants/localStorage';
import {
  IGetAllProjectResponse,
  IPaginationOffsetParams,
  IPaginationResponse,
  IPolygon,
  IProjectStateParams,
  IProjectDetails,
  IUserProject,
} from 'interfaces';
import { isNumber } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { projectServices } from 'services';

const useProjects = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<IUserProject[]>([]);
  const [projectId, setProjectId] = useState<number>(ID_DEFAULT);
  const [currentProject, setCurrentProject] = useState<IUserProject>();

  const getProjects = async () => {
    try {
      setLoading(true);
      const { projects } = await projectServices.getProjects();
      setProjects(projects || []);

      const { LS_PROJECT_ID } = localStorageConstants;
      const storedProjectId = localStorage.getItem(LS_PROJECT_ID);
      const numericStoredProjectId =
        ['string', 'number'].includes(typeof storedProjectId) &&
        isNumber(Number(storedProjectId))
          ? Number(storedProjectId)
          : ID_DEFAULT;
      const objStoredProjectId = projects.find(
        p => p.projectId === numericStoredProjectId
      );

      if (!objStoredProjectId) localStorage.removeItem(LS_PROJECT_ID);

      const initProjectId = objStoredProjectId
        ? numericStoredProjectId
        : projects?.[0].projectId || ID_DEFAULT;

      setProjectId(initProjectId);
      setCurrentProject(objStoredProjectId || projects?.[0]);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  return {
    setProjectId,
    projectId,
    projects,
    loading,
    currentProject,
    setCurrentProject,
    getProjects,
  };
};

interface UseProjectListOptions {
  fetchOnMount?: boolean;
}

const useProjectList = (
  params: IProjectStateParams,
  options?: UseProjectListOptions
) => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState<IProjectDetails[]>([]);
  const [pagination, setPagination] = useState<
    IGetAllProjectResponse['pagination']
  >();
  const isMounted = useRef(false);
  const { fetchOnMount = true } = options || {};

  const getProjects = async () => {
    try {
      setLoading(true);
      const {
        pagination: paginationRes,
        projects: projectsRes,
      } = await projectServices.getProjectList(params);
      setProjects(projectsRes);
      setPagination(paginationRes);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!fetchOnMount && isMounted.current) return;
    getProjects();
  }, [params]);

  return {
    loading,
    projects,
    getProjects,
    pagination,
  };
};

const useProjectPolygons = (
  projectId: number,
  params: IPaginationOffsetParams
) => {
  const [loading, setLoading] = useState(false);
  const [polygons, setPolygons] = useState<IPolygon[]>([]);
  const [pagination, setPagination] = useState<IPaginationResponse>();

  useEffect(() => {
    const fetchPolygons = async () => {
      try {
        setLoading(true);
        const {
          pagination: paginationRes,
          polygons: polygonsRes,
        } = await projectServices.getProjectPolygons(projectId, params);
        setPolygons(polygonsRes);
        setPagination(paginationRes);
      } finally {
        setLoading(false);
      }
    };

    fetchPolygons();
  }, [projectId, params]);

  return {
    loading,
    polygons,
    pagination,
  };
};

export default {
  useProjects,
  useProjectList,
  useProjectPolygons,
};
