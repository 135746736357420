import { Select, SelectProps } from 'antd';
import { useProjectContext } from 'contexts';
import { tSelectPlaceholder } from 'helpers/i18n';
import { useMemo } from 'react';

interface MyProjectSelectProps extends SelectProps {
  autoSelectFirstOption?: boolean;
  projects?: { id: number; name: string }[];
}

const MyProjectSelect: React.FC<MyProjectSelectProps> = ({
  value,
  projects,
  ...props
}) => {
  const { projects: myProjects } = useProjectContext();

  const myProjectIds = myProjects.map(project => project.projectId);
  const selectedValues = Array.isArray(value) ? value : [value ?? []].flat();

  // Get projects that in my projects and selected projects
  const projectOptions = useMemo(() => {
    const selectedProjects = projects
      ?.filter(
        project =>
          !myProjectIds.includes(project.id) &&
          selectedValues.includes(project.id)
      )
      // Disable unassigned projects
      .map(project => ({
        projectId: project.id,
        projectTitle: project.name,
        disabled: true,
      }));
    return myProjects.concat(selectedProjects ?? []);
  }, [projects, myProjectIds, selectedValues]);

  return (
    <Select
      {...props}
      value={value}
      options={projectOptions}
      fieldNames={{ label: 'projectTitle', value: 'projectId' }}
      placeholder={tSelectPlaceholder('Project')}
    />
  );
};

export default MyProjectSelect;
