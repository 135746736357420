import { DEFAULT_PAGINATION_OFFSET } from 'constants/pagination';
import { useProjectContext } from 'contexts';
import {
  IGetUserListParams,
  IGetUserListReponseData,
  IOrganizationRole,
} from 'interfaces';
import { useEffect, useState } from 'react';
import {
  IGetListOperationRolesParams,
  IPaginationResponse,
  OperationRole,
} from 'interfaces';
import { userManagementServices } from 'services';
import { DEFAULT_OPTION } from 'constants/pagination';

const useUserList = () => {
  const { projectId } = useProjectContext();
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState<IGetUserListParams>({
    projectId,
    ...DEFAULT_PAGINATION_OFFSET,
  });
  const [data, setData] = useState<IGetUserListReponseData>({
    users: [],
    pagination: { offset: 0 },
  });
  const [shouldLoadMore, setShouldLoadMore] = useState(false);

  const getUserList = async () => {
    try {
      setLoading(true);
      const { pagination, users } = await userManagementServices.getUserList(
        params
      );
      setData({
        pagination,
        users:
          params.offset === DEFAULT_PAGINATION_OFFSET.offset
            ? users
            : [...data.users, ...users],
      });
      setShouldLoadMore(pagination.offset >= params.offset + params.limit);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserList();
  }, [params]);

  const onSearch = (keyword?: string) => {
    setParams({ ...params, keyword, offset: DEFAULT_PAGINATION_OFFSET.offset });
  };

  const onLoadMore = () => {
    setParams({
      ...params,
      offset: data.pagination.offset,
    });
  };

  return { loading, data, shouldLoadMore, onLoadMore, onSearch };
};

const useOrganizationRoles = () => {
  const [roles, setRoles] = useState<IOrganizationRole[]>([]);
  const [loading, setLoading] = useState(false);

  const getOrganizationRoles = async () => {
    try {
      setLoading(true);
      const responseData = await userManagementServices.getOrganizationRoles();
      setRoles(responseData);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrganizationRoles();
  }, []);

  return { roles, loading };
};

export const useOperationRoles = () => {
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [roleGroups, setRoleGroups] = useState<OperationRole[]>([]);
  const [pagination, setPagination] = useState<IPaginationResponse>({
    ...DEFAULT_OPTION,
    total: 0,
  });
  const [keyword, setKeyword] = useState('');

  const getOperationRoles = async (params: IGetListOperationRolesParams) => {
    const {
      operationRoles,
      pagination,
    } = await userManagementServices.getListOperationRoles(params);
    setRoleGroups(prevRoleGroups => {
      if (params.page > 1) {
        return [...prevRoleGroups, ...operationRoles];
      } else {
        return operationRoles;
      }
    });
    setPagination(pagination);
  };

  useEffect(() => {
    onSearch();
  }, []);

  const loadMore = async () => {
    setLoadingMore(true);
    try {
      await getOperationRoles({
        ...DEFAULT_OPTION,
        keyword,
        page: pagination.page + 1,
      });
    } finally {
      setLoadingMore(false);
    }
  };

  const onSearch = async (keyword?: string) => {
    setKeyword(keyword || '');
    try {
      setLoading(true);
      return await getOperationRoles({ ...DEFAULT_OPTION, keyword });
    } finally {
      setLoading(false);
    }
  };

  return {
    roleGroups,
    loading,
    loadMore,
    onSearch,
    loadingMore,
    canLoadMore: pagination.total > roleGroups.length,
  };
};

export default {
  useUserList,
  useOrganizationRoles,
};
