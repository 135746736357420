import { useEffect, useState } from 'react';
import { IAnalyzedDataObject } from 'interfaces';
import { analyzedDataServices } from 'services';

export const useOCROrTranscribe = (analyzedDataIds: number[]) => {
  const [analyzedDataObjects, setAnalyzedDataObjects] = useState<
    IAnalyzedDataObject[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getOCROrTranscribe = async () => {
    setLoading(true);
    try {
      const result = await analyzedDataServices.getOCROrTranscribe(
        analyzedDataIds
      );

      setAnalyzedDataObjects(result.data?.data?.analyzedDataObjects || []);
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (analyzedDataIds.length) getOCROrTranscribe().then();
    else {
      setAnalyzedDataObjects([]);
    }
  }, [JSON.stringify(analyzedDataIds)]);

  return {
    loading,
    analyzedDataObjects,
    setAnalyzedDataObjects,
  };
};
