import { Button, Card, Col, Form, Row, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { t, tSelectPlaceholder } from 'helpers/i18n';
import { communityHooks } from 'hooks';
import { useCurrentMeetingRecord } from '../context';
import { useLocation } from 'react-router-dom';

interface IMeetingRecordsFilterProps {
  getMeetingMinutes: (
    communityId: number,
    financialYear: number
  ) => Promise<void>;
}

interface IFilterForm {
  communeId: number;
  communityId: number;
  financialYear: number;
}

const MeetingRecordsFilter: React.FC<IMeetingRecordsFilterProps> = ({
  getMeetingMinutes,
}) => {
  const [form] = Form.useForm<IFilterForm>();
  const {
    loading,
    communes,
    getCommunesAndCommunities,
  } = communityHooks.useCommunesAndCommunities();
  const communeId = Form.useWatch('communeId', form);
  const { setCurrentCommunityName } = useCurrentMeetingRecord();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const initCommunityId =
    query.get('communityId') && parseInt(query.get('communityId') as string);

  const [initialized, setInitialized] = useState(false);

  const communitiesOptions = useMemo(() => {
    if (communes?.length) {
      return communes
        .find(commune => commune.communeId === communeId)
        ?.communities.map(community => ({
          label: community.communityName,
          value: community.communityId,
        }));
    }
  }, [communeId, communes]);

  const onSubmit = (values: IFilterForm) => {
    getMeetingMinutes(values.communityId, values.financialYear);
    setCurrentCommunityName?.(
      communitiesOptions?.find(option => option.value === values.communityId)
        ?.label || ''
    );
  };

  useEffect(() => {
    getCommunesAndCommunities();
  }, []);

  // Init from params communityId
  useEffect(() => {
    if (communes?.length && initCommunityId && !initialized) {
      let foundCommuneId;
      for (const commune of communes) {
        const foundCommunity = commune.communities.find(
          community => community.communityId === initCommunityId
        );
        if (foundCommunity) {
          foundCommuneId = commune.communeId;
          break;
        }
      }
      if (foundCommuneId) {
        form.setFieldsValue({
          communeId: foundCommuneId,
          communityId: initCommunityId,
        });
        setInitialized(true);
      }
    }
  }, [communes, initCommunityId, initialized, communitiesOptions]);

  // Get list meeting records after init community
  useEffect(() => {
    if (initialized && initCommunityId) {
      form.setFieldsValue({
        communityId: initCommunityId,
      });
      form.validateFields().then(values => {
        if (initCommunityId) {
          setCurrentCommunityName?.(
            communitiesOptions?.find(option => option.value === initCommunityId)
              ?.label || ''
          );
          getMeetingMinutes(initCommunityId, values.financialYear);
          setInitialized(true);
        }
      });
    }
  }, [initialized, initCommunityId]);

  useEffect(() => {
    if (initialized) {
      form.setFieldsValue({
        communityId: communitiesOptions?.[0].value,
      });
    }
  }, [communeId, communitiesOptions]);

  // Init on the first time open page without params
  useEffect(() => {
    if (communes?.length && !initialized && !initCommunityId) {
      const firstCommuneId = communes[0]?.communeId;
      const firstCommunityId = communes[0]?.communities[0]?.communityId;

      if (firstCommuneId && firstCommunityId) {
        form.setFieldsValue({
          communeId: firstCommuneId,
          communityId: firstCommunityId,
        });
        setInitialized(true);
        setCurrentCommunityName?.(
          communes[0]?.communities[0]?.communityName || ''
        );
        getMeetingMinutes(firstCommunityId, dayjs().year());
      }
    }
  }, [communes, initialized]);

  return (
    <Card className="meeting-records-filter">
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        initialValues={{
          financialYear: dayjs().year(),
        }}
      >
        <Row align="bottom" gutter={10}>
          <Col span={6}>
            <Form.Item
              name="communeId"
              label={t('Commune')}
              rules={[{ required: true }]}
            >
              <Select
                placeholder={tSelectPlaceholder('Commune')}
                loading={loading}
                options={communes.map(item => ({
                  label: item.communeName,
                  value: item.communeId,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item dependencies={['communeId']} noStyle>
              {() => {
                const disableCommunity = communeId === undefined;
                return (
                  <Form.Item
                    name="communityId"
                    label={t('Community')}
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder={tSelectPlaceholder('Community')}
                      loading={loading}
                      options={communitiesOptions}
                      disabled={disableCommunity}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={t('Year')} name="financialYear">
              <Select disabled />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="">
              <Button type="primary" htmlType="submit">
                {t('Search')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export { MeetingRecordsFilter };
