/** Third party libs **/
import React from 'react';
import dayjs from 'dayjs';

/** Local libs **/
import { BaseItemProps } from '../BaseItem';
import { IFormAnswer } from 'interfaces';
import { DatePicker, Input, InputNumber, Select, TimePicker } from 'antd';
import { DATE_SLASH_FORMAT, TIME_FORMAT_LITE } from 'constants/dateTime';
import { t } from 'helpers/i18n';

/** Styles **/
import './Datagrid.scss';
import { REGEX_PATTERN_NUMBER_FORMAT } from 'components/FPIC/constants';

/** Interfaces, enum... **/
interface IDatagridInputProps extends Partial<BaseItemProps> {
  fieldName: string;
  fieldType: string;
  item: IFormAnswer;
  index: number;
  defaultValue: any;
  onChange: (
    value: string | number,
    index: number,
    name?: string | undefined
  ) => void;
  updateDataSource: (
    value: string | number,
    index: number,
    name?: string
  ) => void;
  readOnly?: boolean;
}

/** Variables **/

/** ------------------------- **/
const DatagridInput: React.FC<IDatagridInputProps> = ({
  item,
  fieldName,
  fieldType,
  disabled,
  defaultValue,
  index,
  onChange,
  updateDataSource,
  readOnly,
}) => {
  /** States **/

  /** Hooks **/

  /** Variables **/

  /** Functions, Events, Actions... **/
  switch (fieldType) {
    case 'DatePicker':
    case 'DATE_PICKER':
      return (
        <DatePicker
          format={DATE_SLASH_FORMAT}
          placeholder={t('EnterContent')}
          defaultValue={defaultValue}
          disabled={disabled}
          onChange={value =>
            value?.format(DATE_SLASH_FORMAT) &&
            onChange(value?.format(DATE_SLASH_FORMAT), index, fieldName)
          }
          onBlur={event => updateDataSource(event.timeStamp, index, fieldName)}
          readOnly={readOnly}
        />
      );
    case 'TimePicker':
    case 'TIME_PICKER':
      return (
        <TimePicker
          style={{ width: '100%' }}
          format={TIME_FORMAT_LITE}
          allowClear={!item.isRequired}
          disabled={disabled}
          defaultValue={dayjs.unix(Number(defaultValue))}
          placeholder={t('EnterContent')}
          onChange={value =>
            value?.format(TIME_FORMAT_LITE) &&
            onChange(value?.format(TIME_FORMAT_LITE), index, fieldName)
          }
          onBlur={event => updateDataSource(event.timeStamp, index, fieldName)}
          readOnly={readOnly}
        />
      );
    case 'TextArea':
    case 'TEXT_AREA':
      return (
        <Input.TextArea
          rows={1}
          title={defaultValue}
          defaultValue={defaultValue}
          placeholder={t('EnterContent')}
          disabled={disabled}
          onChange={value =>
            value && onChange(value.target.value, index, fieldName)
          }
          onBlur={value =>
            updateDataSource(value.target.value, index, fieldName)
          }
          maxLength={5000}
          readOnly={readOnly}
          style={{
            backgroundColor: readOnly ? '#0000000a' : 'none',
          }}
        />
      );
    case 'Numberic':
      return (
        <InputNumber
          min={1}
          title={defaultValue}
          defaultValue={defaultValue}
          formatter={value =>
            `${value}`.replace(REGEX_PATTERN_NUMBER_FORMAT, ',')
          }
          parser={value => value!.replace(/,/g, '')}
          placeholder={t('EnterContent')}
          disabled={disabled}
          precision={0}
          onChange={value => value && onChange(value, index, fieldName)}
          onBlur={value =>
            updateDataSource(value.target.value, index, fieldName)
          }
          style={{
            backgroundColor: readOnly ? '#0000000a' : 'none',
          }}
        />
      );
    case 'Currency':
      return (
        <InputNumber
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          title={defaultValue}
          parser={value => value!.replace(/\$\s?|(,*)/g, '')}
          defaultValue={defaultValue}
          placeholder={t('EnterContent')}
          disabled={disabled}
          onChange={value => value && onChange(value, index, fieldName)}
          readOnly={readOnly}
          style={{
            backgroundColor: readOnly ? '#0000000a' : 'none',
          }}
        />
      );
    case 'Dropdown':
    case 'DROPDOWN':
      return (
        <>
          {readOnly || disabled ? (
            <Input
              placeholder={t('EnterContent')}
              title={defaultValue}
              defaultValue={defaultValue}
              readOnly={readOnly}
              style={{
                backgroundColor: readOnly ? '#0000000a' : 'none',
              }}
              disabled={disabled}
            />
          ) : (
            <Select
              onChange={value => value && onChange(value, index, fieldName)}
              defaultValue={defaultValue}
            >
              {item.suggestionValues?.map(suggesttion => {
                return (
                  <Select.Option
                    value={suggesttion.value}
                    key={suggesttion.value}
                  >
                    {suggesttion.label}
                  </Select.Option>
                );
              })}
            </Select>
          )}
        </>
      );
    default:
      return (
        <Input
          placeholder={t('EnterContent')}
          title={defaultValue}
          defaultValue={defaultValue}
          disabled={disabled}
          onChange={value =>
            value && onChange(value.target.value, index, fieldName)
          }
          onBlur={value =>
            updateDataSource(value.target.value, index, fieldName)
          }
          readOnly={readOnly}
          style={{
            backgroundColor: readOnly ? '#0000000a' : 'none',
          }}
        />
      );
  }
  /** Effects **/

  /** Elements **/
};

export { DatagridInput };
export default DatagridInput;
