import { Drawer, DrawerProps } from 'antd';
import React from 'react';
import FeasibilityStudyReportForm from './FeasibilityStudyReportForm';
import { t } from 'helpers/i18n';
import { DRAWER_WIDTH } from 'constants/common';

interface FeasibilityStudyReportDrawerProps extends DrawerProps {
  onClose: () => void;
}

const FeasibilityStudyReportDrawer: React.FC<FeasibilityStudyReportDrawerProps> = props => {
  return (
    <Drawer
      {...props}
      title={t('FeasibilityStudyReport')}
      destroyOnClose
      width={DRAWER_WIDTH.MEDIUM_LARGE}
      mask={false}
      getContainer={false}
      styles={{ body: { overflow: 'hidden' } }}
    >
      <FeasibilityStudyReportForm onSuccess={() => props.onClose?.()} />
    </Drawer>
  );
};

export default FeasibilityStudyReportDrawer;
