import { InfoCircleOutlined } from '@ant-design/icons';
import { InputNumber, Radio, Table, Tooltip, Typography, theme } from 'antd';
import './RiskLevelPicker.scss';
import { ColumnsType, ColumnType } from 'antd/es/table';
import {
  RiskAssessmentMetrics,
  RiskLevel,
  RiskMetrics,
  RiskMetricType,
} from 'interfaces';
import carbonPotentialReportConstants from 'constants/carbonPotentialReport';
import { useEffect, useState } from 'react';

const { useToken } = theme;
const {
  RISK_ASSESSMENT_METRICS,
  RISK_METRICS_ROWS,
  RISK_LEVEL_COLUMNS,
} = carbonPotentialReportConstants;

type RiskMetricInfo = {
  key: RiskMetricType;
  label: string;
  description: string;
};

interface RiskLevelPickerProps {
  value?: RiskAssessmentMetrics;
  onChange?: (value: RiskAssessmentMetrics) => void;
}

const RiskLevelPicker: React.FC<RiskLevelPickerProps> = ({
  value,
  onChange,
}) => {
  const { token } = useToken();
  const [customMetrics, setCustomMetrics] = useState<RiskMetrics>({
    uncertainty: 0,
    leakage: 0,
    performanceBenchmark: 0,
    nonPermanenceRisk: 0,
  });

  useEffect(() => {
    if (!value) {
      // Auto select LOW risk level by default
      onChangeRiskLevel(RiskLevel.LOW);
    }
  }, []);

  const getCellProps = (riskLevel: string) => {
    return {
      style:
        riskLevel === value?.riskLevel
          ? { background: token.colorWarningBgHover }
          : undefined,
    };
  };

  const columns: ColumnsType<RiskMetricInfo> = [
    {
      title: '',
      dataIndex: 'label',
      rowScope: 'row',
      render: (label: string, rowData: any) => (
        <Tooltip title={rowData.description}>
          <Typography.Text style={{ wordBreak: 'keep-all' }}>
            {label} <InfoCircleOutlined />
          </Typography.Text>
        </Tooltip>
      ),
    },
    ...RISK_LEVEL_COLUMNS.map(({ key, label }) => ({
      title: (
        <Radio value={key} className="extra-small">
          {label}
        </Radio>
      ),
      align: 'right' as ColumnType<RiskMetricInfo>['align'],
      width: key === RiskLevel.CUSTOM ? '20%' : undefined,
      onCell: () => getCellProps(key),
      render: (_: undefined, rowData: RiskMetricInfo) =>
        key === RiskLevel.CUSTOM ? (
          <InputNumber
            suffix={'%'}
            min={0}
            max={100}
            value={customMetrics[rowData.key]}
            onChange={value =>
              setCustomMetrics({ ...customMetrics, [rowData.key]: value })
            }
            placeholder="0"
            style={{ width: 'auto' }}
          />
        ) : (
          <Typography.Text>
            {RISK_ASSESSMENT_METRICS[key][rowData.key]}%
          </Typography.Text>
        ),
    })),
  ];

  const onChangeRiskLevel = (riskLevel: RiskLevel) => {
    const metrics =
      riskLevel === RiskLevel.CUSTOM
        ? customMetrics
        : RISK_ASSESSMENT_METRICS[riskLevel];

    onChange?.({
      riskLevel,
      ...metrics,
    });
  };

  useEffect(() => {
    if (value?.riskLevel === RiskLevel.CUSTOM) {
      onChangeRiskLevel(RiskLevel.CUSTOM);
    }
  }, [customMetrics]);

  return (
    <Radio.Group
      value={value?.riskLevel}
      onChange={e => onChangeRiskLevel(e.target.value)}
    >
      <Table
        size="small"
        bordered={false}
        columns={columns}
        dataSource={Object.values(RISK_METRICS_ROWS)}
        pagination={false}
      />
    </Radio.Group>
  );
};

export default RiskLevelPicker;
