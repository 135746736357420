import React from 'react';
import { Circle, Pane, useMap } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { selectManualSamplingPlots } from 'redux/features/samplingPlot';
import { mapHelpers } from 'helpers';
import ManualAddSplingPlotMarker from './ManualAddSplingPlotMarker';

interface IManualAddSamplingPlotProps {
  mapRef: React.RefObject<HTMLDivElement>;
}

const { convertRadiusToPixel } = mapHelpers;

const ManualAddSamplingPlot: React.FC<IManualAddSamplingPlotProps> = ({
  mapRef,
}) => {
  const manualSamplingPlots = useSelector(selectManualSamplingPlots);
  const map = useMap();

  return (
    <Pane name="manual-sampling-plot" style={{ zIndex: 201 }}>
      {Array.isArray(manualSamplingPlots) &&
        manualSamplingPlots.length > 0 &&
        manualSamplingPlots.map((samplingPlot, index) => (
          <Circle
            center={samplingPlot.center}
            radius={convertRadiusToPixel(samplingPlot.radius, map, mapRef)}
            key={index}
            pathOptions={{ color: '#82869e' }}
          >
            <ManualAddSplingPlotMarker
              index={index}
              samplingPlot={samplingPlot}
              isActive={samplingPlot.isActive}
            />
          </Circle>
        ))}
    </Pane>
  );
};

export { ManualAddSamplingPlot };
export default ManualAddSamplingPlot;
