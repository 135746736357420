import { Col, Input, Row } from 'antd';
import ContentBlock from 'components/shared/ContentBlock';
import { t } from 'helpers/i18n';
import React, { Dispatch } from 'react';

interface IUserListPanelProps {
  onSearch: Dispatch<string>;
}

const UserListPanel: React.FC<IUserListPanelProps> = ({ onSearch }) => {
  return (
    <ContentBlock className="mb-base pb-half">
      <Row gutter={8} align="middle">
        <Col xs={24} sm={8}>
          <Input.Search
            allowClear
            placeholder={t('SearchBy', {
              object: `${t('Telephone')}`.toLowerCase(),
            })}
            data-testid="search-account-input"
            className="w-100"
            onSearch={keyword => onSearch(keyword.trim())}
          />
        </Col>
      </Row>
    </ContentBlock>
  );
};

export default UserListPanel;
