/** Third party libs **/
import { Card, Tag, Typography } from 'antd';
import React, { useContext } from 'react';
import { EnvironmentOutlined, UserOutlined } from '@ant-design/icons';

/** Local libs **/
import { IFarmSimple } from 'interfaces';
import { CurrentSelectedFarm } from '../../Context';
import { FARM_STATUS_TYPE, APPROVAL_STATUS } from 'constants/farm';
import { t } from 'helpers/i18n';

/** Styles **/
import './FarmCard.scss';
import classNames from 'classnames';

/** Interfaces, enum... **/
interface IFarmCardProps {
  farm: IFarmSimple;
  isSelected?: boolean;
  selectedFarmId?: number;
}

/** Variables **/
const { Text } = Typography;

/** ------------------------- **/
/** FarmCard Component */
/** ------------------------- **/
const FarmCard: React.FC<IFarmCardProps> = ({ farm, selectedFarmId }) => {
  /** States **/

  /** Hooks **/
  const {
    setSelectedFarmId,
    setSelectedSurveyForm,
    setShowDiscussionHistory,
    setShowFarmInfo,
    setShowSurveyReport,
  } = useContext(CurrentSelectedFarm);

  /** Variables **/

  /** Functions, Events, Actions... **/
  const renderFarmStatus = (status?: string) => {
    switch (status) {
      case FARM_STATUS_TYPE.ELIGIBLE:
        return (
          <div>
            <span className="dot eligible" />
            <span>{t('Eligible')}</span>
          </div>
        );

      case FARM_STATUS_TYPE.NOT_ELIGIBLE:
        return (
          <div>
            <span className="dot not-eligible" />
            <span>{t('NotEligible')}</span>
          </div>
        );

      default:
        return (
          <div>
            <span className="dot pending" />
            <span>{t('PendingEligbleCheck')}</span>
          </div>
        );
    }
  };

  const renderApprovalStatus = (status?: string) => {
    switch (status) {
      case APPROVAL_STATUS.PENDING_APPROVAL:
        return (
          <Tag className="pending-approval">
            {t(APPROVAL_STATUS.PENDING_APPROVAL).toUpperCase()}
          </Tag>
        );

      case APPROVAL_STATUS.NEED_CONTRACT:
        return (
          <Tag className="need-contract">
            {t(APPROVAL_STATUS.NEED_CONTRACT).toUpperCase()}
          </Tag>
        );

      case APPROVAL_STATUS.SIGNED_CONTRACT:
        return (
          <Tag className="signed-contract">
            {t(APPROVAL_STATUS.SIGNED_CONTRACT).toUpperCase()}
          </Tag>
        );

      case APPROVAL_STATUS.NEED_TO_UPDATE:
        return (
          <Tag className="need-contract">
            {t(APPROVAL_STATUS.NEED_TO_UPDATE).toUpperCase()}
          </Tag>
        );

      case APPROVAL_STATUS.REJECTED:
        return (
          <Tag className="rejected">
            {t(APPROVAL_STATUS.REJECTED).toUpperCase()}
          </Tag>
        );

      default:
        return (
          <Tag className="not-available">
            {t(APPROVAL_STATUS.NOT_AVAILABLE)}
          </Tag>
        );
    }
  };

  const onClickFarmCard = () => {
    if (selectedFarmId === farm.farmLandId) {
      setSelectedFarmId(undefined);
      setShowFarmInfo(false);
    } else {
      setSelectedFarmId(farm.farmLandId);
      setShowFarmInfo(true);
    }
    setShowSurveyReport(false);
    setSelectedSurveyForm(undefined);
    setShowDiscussionHistory(false);
  };

  /** Effects **/

  /** Elements **/
  return (
    <Card
      className={classNames(
        'card-wrapper',
        'mb-half',
        selectedFarmId === farm.farmLandId && 'selected'
      )}
      onClick={onClickFarmCard}
    >
      <div className="d-flex justify-content-between mb-half">
        <div style={{ display: 'block', width: '50%' }}>
          <Text strong className="d-block">
            {farm.farmName}
          </Text>
        </div>
        {renderApprovalStatus(farm.approvalStatus)}
      </div>
      <Text className="d-block">{renderFarmStatus(farm.farmStatus)}</Text>

      <Text className="d-block">
        <EnvironmentOutlined className="green-icon" /> {farm.area.toFixed(2)} Ha
      </Text>

      <Text className="d-block">
        <UserOutlined className="green-icon" /> {farm.farmerName}
      </Text>
    </Card>
  );
};

export { FarmCard };
export default FarmCard;
