import React from 'react';
import { Drawer, DrawerProps } from 'antd';
import { DRAWER_WIDTH } from 'constants/common';
import { t } from 'helpers/i18n';
import RandomSamplingPlotsForm from './RandomSamplingPlots';
import { useProjectDrawersContext } from 'containers/Project/ProjectDetailMapV2/context';
import ImportPlots from './ImportPlots';

interface SamplingPlotsDrawerProps extends DrawerProps {
  area: number;
  onClose: () => void;
}

const SamplingPlotsDrawer: React.FC<SamplingPlotsDrawerProps> = ({
  area,
  ...props
}) => {
  const { samplingPlotsDrawerType } = useProjectDrawersContext();

  return (
    <Drawer
      {...props}
      title={t('SamplingPlots')}
      destroyOnClose
      width={DRAWER_WIDTH.LARGE}
      mask={false}
      getContainer={false}
      className="sampling-plot-drawer"
    >
      {samplingPlotsDrawerType === 'RANDOM' && (
        <RandomSamplingPlotsForm area={area} onClose={props.onClose} />
      )}
      {samplingPlotsDrawerType === 'IMPORT' && <ImportPlots />}
    </Drawer>
  );
};

export default SamplingPlotsDrawer;
