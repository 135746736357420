import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Row, Select, Space } from 'antd';
import { CremaSelect } from 'components/shared/Select/CremaSelect';
import { t, tSelectPlaceholder } from 'helpers/i18n';
import { cremaConstants } from 'constants/index';
import { CommunitySelect } from 'components/shared/Select/CommunitySelect';
import {
  CremaMeetingRecordStatus,
  CremaTemplateFormType,
  ICommunity,
  ICrema,
  IGetCremaMeetingRecordParams,
} from 'interfaces';
import { useState } from 'react';
import dayjs from 'dayjs';
import { DEFAULT_MAX_TAG_COUNT } from 'constants/common';
import Navbar from 'components/shared/Navbar';
import { useFlagsupContext } from 'contexts';
import { CremaTemplateSelect } from 'components/shared/Select';

const { CREMA_MEETING_RECORD_STATUSES } = cremaConstants;
const MEETING_RECORD_FILTER_STATUSES = CREMA_MEETING_RECORD_STATUSES.filter(
  status => status.value !== CremaMeetingRecordStatus.Draft
);

interface MeetingRecordFilterForm {
  cremaId: ICrema['cremaId'];
  communityIds: ICommunity['communityId'][];
  templateFormIds: number[];
  submissionStatus: CremaMeetingRecordStatus;
  meetingDate: dayjs.Dayjs;
}

interface MeetingRecordFilterProps {
  onSearch: (filters: Partial<IGetCremaMeetingRecordParams>) => void;
  cremaId?: number;
  communityId?: number;
}
const MeetingRecordFilter: React.FC<MeetingRecordFilterProps> = ({
  onSearch,
  cremaId,
  communityId,
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<MeetingRecordFilterForm>();
  const { featureFlagsData } = useFlagsupContext();
  const isCARHCM856Enabled = featureFlagsData.CARHCM_856?.enabled;

  const onFinish = (formValues: MeetingRecordFilterForm) => {
    const params = {
      cremaId: formValues.communityIds.length ? undefined : formValues.cremaId,
      communityIds: formValues.communityIds,
      templateFormIds: formValues.templateFormIds,
      submissionStatus: formValues.submissionStatus,
      meetingTimeFrom: formValues.meetingDate?.startOf('day').unix(),
      meetingTimeTo: formValues.meetingDate?.endOf('day').unix(),
    };
    try {
      setLoading(true);
      onSearch(params);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Navbar>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          cremaId,
          communityIds: communityId ? [communityId] : [],
        }}
      >
        <Row align="middle" gutter={8} wrap={false}>
          <Col span={4}>
            <Form.Item name="meetingDate" noStyle>
              <DatePicker className="w-100" />
            </Form.Item>
          </Col>
          {isCARHCM856Enabled && (
            <Col span={4}>
              <Form.Item name="templateFormIds" noStyle>
                <CremaTemplateSelect
                  className="w-100"
                  templateFormTypes={[CremaTemplateFormType.CremaMeeting]}
                />
              </Form.Item>
            </Col>
          )}
          <Col span={4}>
            <Form.Item name="submissionStatus" noStyle>
              <Select
                allowClear
                className="w-100"
                options={MEETING_RECORD_FILTER_STATUSES}
                placeholder={tSelectPlaceholder('Status')}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="cremaId" noStyle>
              <CremaSelect
                className="w-100"
                placeholder={tSelectPlaceholder('Crema')}
                onChange={() => form.resetFields(['communityIds'])}
              />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, cur) => prev.cremaId !== cur.cremaId}
            >
              {({ getFieldValue }) => (
                <Form.Item name="communityIds" shouldUpdate noStyle>
                  <CommunitySelect
                    mode="multiple"
                    className="w-100"
                    maxTagCount={DEFAULT_MAX_TAG_COUNT}
                    cremaId={getFieldValue('cremaId')}
                    placeholder={tSelectPlaceholder('Community')}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>

          <Col className="text-right">
            <Form.Item noStyle>
              <Space>
                <Button
                  type="default"
                  disabled={loading}
                  onClick={() => form.resetFields()}
                >
                  {t('Reset')}
                </Button>
                <Button
                  type="primary"
                  icon={<SearchOutlined />}
                  htmlType="submit"
                  disabled={loading}
                >
                  {t('Search')}
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Navbar>
  );
};

export default MeetingRecordFilter;
