export const ROUTES = {
  PROJECT: {
    GET_ALL_PROJECT: '/api/v1/projects',
    CREATE_PROJECT_TEKBONE: '/tekbone/api/v1/projects',
    CREATE_PROJECT: '/api/v1/projects',
    DELETE_PROJECT: (id: number) => `/tekbone/api/v1/projects/${id}`,
    GET_PROJECT_DETAIL: (id: number) => `/api/v1/projects/${id}`,
    SELECT_SUBMISSION: '/api/v1/submissions/select',
    UPLOAD_PROJECT_AREA: (id: number) => `/api/v1/projects/${id}/area`,
  },
  POLYGON: {
    GET_ALL_POLYGON: (id: number) => `/api/v1/projects/${id}/polygons`,
    CREATE_POLYGON: (id: number) => `/api/v1/projects/${id}/polygons`,
    EXPORT_POLYGON: (id: number) => `/api/v1/projects/${id}/polygons/export`,
    DELETE_POLYGON: (id: number) => `/api/v1/polygons/${id}`,
    SEARCH_POLYGONS: `/tekbone/api/v1/search-polygons`,
    GET_POLYGON_TYPES: '/tekbone/api/v1/polygons/get-list-polygon-type',
    SELECT: '/api/v1/polygons/select',
  },
  COMMUNITY: {
    GET_FARMERS_BY_COMMUNITIES: '/tekbone/api/v1/farmers-by-community',
    GET_STAFFS: (communityId: number) =>
      `/tekbone/api/v1/cremas/communities/${communityId}/staffs`,
    ASSIGN_STAFFS: '/tekbone/api/v1/cremas/communities/assign-staffs',
  },
  CRMC: {
    GET_CRMC_MEMBERS: 'tekbone/api/v1/crmc-members',
    GET_CRMC_CANDIDATES: 'tekbone/api/v1/crmc-candidates',
    UPSERT_CRMC_MEMBER: 'tekbone/api/v1/cremas/crmc-member',
  },
  CEC: {
    GET_CEC_MEMBERS: 'tekbone/api/v1/cec-members',
    GET_CEC_CANDIDATES: 'tekbone/api/v1/cec-candidates',
    UPSERT_CEC_MEMBER: 'tekbone/api/v1/cec-member',
  },
  DOCUMENTATIONS: {
    GET_DOCUMENTATIONS: 'tekbone/api/v1/documentations',
    UPSERT_DOCUMENTATION: 'tekbone/api/v1/documentation',
  },
  SUBMISSON: {
    UPDATE_SUBMISSION: '/api/v1/submissions',
    EXPORT_SUBMISSION: ({
      plotId,
      projectId,
      userTimezone,
    }: {
      plotId?: number;
      projectId?: number;
      userTimezone?: string;
    }) =>
      `/api/v1/submissions/export?projectId=${projectId}&plotId=${plotId}` +
      (userTimezone ? `&userTimezone=${userTimezone}` : ``),
  },
  TREE_SPECIES: {
    GET_ALL_TREE_SPECIES: '/api/v1/tree-species',
  },
  PLOTS: {
    GENERATE_SAMPLING_PLOTS: '/api/v1/plots/auto-generate',
    GET_PLOTS_BY_VIEW: '/api/v1/plots',
    GENERATE_SAMPLING_PLOTS_TEKBONE: '/tekbone/api/v1/plots/auto-generate',
    GET_PLOTS_BY_VIEW_TEKBONE: '/tekbone/api/v1/plots',
    GET_PLOT_DETAILS: (plotId: number) => `/tekbone/api/v1/plots/${plotId}`,
  },
  GET_BASEMAP: '/api/v1/basemap',
  ACTIVITY: {
    DETAIL: '/tekbone/api/v1/get-detail-activity',
    ORG_ACTIVITY_LOGS: '/tekbone/api/v1/get-org-activity-logs',
    EXPENSE_RECEIPTS: '/tekbone/api/v1/get-activity-expense-receipts',
    APPROVE: (activityId: number) =>
      `/tekbone/api/v1/activity/${activityId}/approve`,
    REJECT: (activityId: number) =>
      `/tekbone/api/v1/activity/${activityId}/reject`,
    APPROVE_ALL: (orgId: number) =>
      `/tekbone/api/v1/organization/${orgId}/approve-all-activites`,
    LIST_LIKES_COMMENTS: '/tekbone/api/v1/get-comments-activity',
    LIKE_COMMENT: '/tekbone/api/v1/comment-activity',
  },
};
