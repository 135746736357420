/** Third party libs * */
import React, { useEffect } from 'react';
import { Input } from 'antd';

/** Local libs * */
import { t } from 'helpers/i18n';
import { BaseItem, BaseItemProps } from '../BaseItem';

/** Components * */

/** Styles * */

/** Interfaces, enum... * */
interface InputTextProps extends BaseItemProps {
  textArea?: boolean;
  readOnly?: boolean;
}

/** Variables * */

/** ------------------------- * */
const InputText: React.FC<InputTextProps> = ({
  item,
  disabled,
  form,
  textArea,
  readOnly,
  ...props
}) => {
  /** States * */

  /** Hooks * */

  /** Variables * */

  /** Effects * */
  useEffect(() => {
    if (item.fieldName) {
      form.setFieldValue(item.fieldName, item.value);
    }
  }, [item.value]);

  /** Functions, Events, Actions... * */

  /** Elements * */
  return (
    <BaseItem
      item={item}
      disabled={disabled}
      form={form}
      validator={(_, value) => {
        if (item.isRequired && (!value || !value.trim())) {
          return Promise.reject(t('NotBeEmpty'));
        }

        return Promise.resolve();
      }}
      {...props}
    >
      {textArea ? (
        <Input.TextArea
          placeholder={t('EnterContent')}
          disabled={disabled}
          readOnly={readOnly}
          style={{
            backgroundColor: readOnly ? '#0000000a' : 'none',
          }}
          value={item.value}
        />
      ) : (
        <Input
          placeholder={t('EnterContent')}
          disabled={disabled}
          readOnly={readOnly}
          style={{
            backgroundColor: readOnly ? '#0000000a' : 'none',
          }}
          value={item.value}
        />
      )}
    </BaseItem>
  );
};

export default InputText;
