import { commonConstants } from 'constants/index';
const { APPS } = commonConstants;

const getFpicUrl = () => {
  const app = window.appConfig.appName;
  switch (app) {
    case APPS.AJA:
      return '/fpic-management/meeting-records';
    default:
      return '/meeting-notes';
  }
};
export { getFpicUrl };
