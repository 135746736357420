import React, { useMemo, useState } from 'react';
import { IFpicFormTemplate } from 'interfaces';
import { FPICContext } from '../context';
import './Provider.scss';

/** Interfaces, enum... * */
interface FpicProviderProps {
  children: React.ReactNode;
}

/** ------------------------- * */
const Provider: React.FC<FpicProviderProps> = ({ children }) => {
  const [template, setTemplate] = useState<IFpicFormTemplate | undefined>(
    undefined
  );
  const [templates, setTemplates] = useState<IFpicFormTemplate[] | undefined>();
  const [title, setTitle] = useState<React.ReactNode | undefined>(undefined);

  const contextValue = useMemo(() => {
    return {
      title,
      setTitle,
      template,
      templates,
      setTemplate,
      setTemplates,
    };
  }, [
    title,
    setTitle,
    JSON.stringify(template),
    JSON.stringify(templates),
    setTemplate,
    setTemplates,
  ]);

  return (
    <FPICContext.Provider value={contextValue}>{children}</FPICContext.Provider>
  );
};

export default Provider;
