import { MICROSOFT_ONLINE_VIEWER } from 'constants/file';
import IFrameContainer from 'containers/shared/IFrameContainer';
import InProgressPage from 'containers/shared/InProgressPage';
import { useProjectContext } from 'contexts';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

export interface IFrameContainerProps extends RouteComponentProps {
  iFrameSrc: string;
}

export const PROJECTS = window.appConfig?.projects || {};

const IFRAME_URL = {
  [PROJECTS.SonLa]: `${MICROSOFT_ONLINE_VIEWER}https://files.tekoapis.com/files/d1ed48d0-794c-4dfe-95d1-6f30b6edfa92`,
  [PROJECTS.Kwahu]:
    'https://drive.google.com/file/d/13S8t0P0lLPFOfJCbpC2HEMZfzrNv9a5B/preview',
};

const FeasibilityStudy: React.FC<IFrameContainerProps> = props => {
  const { projectId } = useProjectContext();
  return IFRAME_URL[projectId] ? (
    <IFrameContainer {...props} iFrameSrc={IFRAME_URL[projectId]} />
  ) : (
    <InProgressPage />
  );
};

export default FeasibilityStudy;
