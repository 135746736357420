import { Drawer } from 'antd';
import { MPITrend, MPIFormula, MPIIndicators } from 'components/MPI';
import { LayerType, MapPolygons } from 'components/shared/Map';
import MapContainerWrapper from 'components/shared/Map/MapContainerWrapper';
import AppContainer from 'containers/AppLayout/AppContainer';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Pane } from 'react-leaflet';
import styled from 'styled-components';
import './MPIReport.scss';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { poiHooks } from 'hooks';
import { DEFAULT_z_INDEX_POLYGON, TPOI } from 'constants/map';
import MarkerClusterGroup from 'react-leaflet-cluster';
import SmallMarker from 'components/shared/Map/SmallMarker/SmallMarker';
import { useProjectContext } from 'contexts';
import { getData } from 'helpers/request';
import { divIcon } from 'leaflet';
import { commonConstants, mpiConstants } from 'constants/index';
import { useQuery } from '@tanstack/react-query';
import { projectServices } from 'services';

const { MPIPOIName } = mpiConstants;
const { QUERY_KEYS } = commonConstants;

const LeafletContainer = styled.div`
  height: 100%;
  width: 100%;
  margin-bottom: 20px;
  .leaflet-container {
    width: 100%;
    height: 100%;
  }
  .leaflet-div-icon {
    background: none;
    border: none;
  }
`;

const classNamePoi: { [key: string]: string } = {
  [MPIPOIName.ElectricPole]: 'custom-marker-default-has-data',
  [MPIPOIName.Well]: 'custom-marker-active',
  [MPIPOIName.NewRoad]: 'custom-marker-default-no-data',
};

const MPIReport: React.FC = () => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [isExtendDrawer, setIsExtendDrawer] = useState<boolean>(true);
  const [selectedLayerTypes, setSelectedLayerTypes] = useState<LayerType[]>([
    'Default',
  ]);
  const { projectId } = useProjectContext();
  const { POIs, getPOIs } = poiHooks.usePOIs();

  const { data: project, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.PROJECT_DETAIL, projectId],
    queryFn: async () =>
      projectServices.getProjectById(projectId).then(getData),
    retry: false,
  });

  const polygon = useMemo(
    () =>
      project?.projectAreaBoundary
        ? [
            {
              polygonMap: project?.projectAreaBoundary || [],
              color: 'rgba(0,0,5,0.4)',
            },
          ]
        : [],
    [project]
  );

  const numPoiIndicators = useMemo(
    () =>
      POIs.reduce((indicators, curPoi) => {
        if (!curPoi.placeName) return indicators;
        if (Object.hasOwn(indicators, curPoi.placeName)) {
          indicators[curPoi.placeName] += 1;
        } else {
          indicators[curPoi.placeName] = 1;
        }
        return indicators;
      }, {} as { [key: string]: number }),
    [POIs]
  );

  useEffect(() => {
    getPOIs([TPOI.MPI]);
  }, []);

  return (
    <AppContainer title={'MPI Report'}>
      <div className="mpi-report">
        <Drawer
          placement={'left'}
          title=""
          width={isExtendDrawer ? 450 : 50}
          open={true}
          mask={false}
          getContainer={false}
          rootClassName="mpi-wrapper"
          styles={{ body: { padding: 16 } }}
          push={{ distance: 0 }}
          closable={false}
        >
          {isExtendDrawer ? (
            <>
              <div className="text-right">
                <DoubleLeftOutlined
                  onClick={() => setIsExtendDrawer(false)}
                  className="p-half"
                />
              </div>
              <MPIFormula />
              <MPITrend />
              <div className="mt-base">
                <MPIIndicators numPoiIndicators={numPoiIndicators} />
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-center">
              <DoubleRightOutlined
                onClick={() => setIsExtendDrawer(true)}
                className="p-half"
              />
            </div>
          )}
        </Drawer>
        <LeafletContainer ref={mapRef} data-testid="project-leaflet-map">
          <MapContainerWrapper
            selectedLayerTypes={selectedLayerTypes}
            setSelectedLayerTypes={setSelectedLayerTypes}
            loading={isLoading}
            allowTypes={['Default', 'Satellite']}
            boundPolygons={polygon}
            currentPolygonId={-1}
            mapRef={mapRef}
          >
            {/* Boundaries */}
            <Pane name="mpi-map" style={{ zIndex: DEFAULT_z_INDEX_POLYGON }}>
              {polygon.length > 0 && <MapPolygons polygons={polygon} />}
            </Pane>
            {/* POI */}
            <Pane name="POI" style={{ zIndex: DEFAULT_z_INDEX_POLYGON + 1 }}>
              <MarkerClusterGroup
                chunkedLoading
                polygonOptions={{
                  stroke: false,
                  color: 'transparent',
                  opacity: 0,
                }}
                zoomToBoundsOnClick
                spiderfyOnMaxZoom={false}
              >
                {POIs.map(poi => {
                  return (
                    <SmallMarker
                      key={poi.poiId}
                      poi={poi}
                      defaultHideIcon={false}
                      iconMarker={divIcon({
                        iconAnchor: [8, 16],
                        popupAnchor: [0, -16],
                        html: `<span class="custom-marker ${
                          poi.placeName && classNamePoi[poi.placeName]
                            ? classNamePoi[poi.placeName]
                            : ''
                        }"  />`,
                      })}
                      alwaysShowLabel
                    />
                  );
                })}
              </MarkerClusterGroup>
            </Pane>
          </MapContainerWrapper>
        </LeafletContainer>
      </div>
    </AppContainer>
  );
};

export default MPIReport;
