import { t } from 'helpers/i18n';
import { IType } from './common';
import { BaseMapLayerType } from 'components/shared/Map';

export const MIN_ZOOM_SHOW_PLOTS = 9;

export const DEFAULT_ZOOM_COMMUNITY = 15;

export const TPOI = {
  MPI: 'MPI',
};

export const DEFAULT_z_INDEX_POLYGON = 200;
export const DEFAULT_z_INDEX_MARKER_ACTIVE = 1000;

export const CREMA_IMAGE_DEFAULT =
  'https://storage.googleapis.com/teko-gae.appspot.com/media/image/2023/12/8/6f0e71d5-6b45-4e0f-a77c-15d3a97e97fc/AbisuNo2.Sept13.2023.Photo1.png';

export enum DrawState {
  DRAW,
  EDIT,
  NONE,
  VIEW_ONLY,
}

export const SELECT_FILTER: IType = {
  COMMUNITY: {
    value: 1,
    label: t('Community'),
  },
  CREMA: {
    value: 2,
    label: t('Crema'),
  },
};

export enum BASEMAP_TYPE {
  LAND_COVER = 'LAND_COVER',
  CUSTOM_CLASSIFICATION = 'CUSTOM_CLASSIFICATION',
  ARR_ELIGIBLE = 'ARR_ELIGIBLE',
  IPCC_2006_LAND_COVER = 'IPCC_2006_LAND_COVER',
  FOREST_LOSS = 'FOREST_LOSS',
  PEATLAND = 'PEATLAND',
}

export const BASEMAP_LAYERS: BaseMapLayerType[] = [
  'LandCover',
  'CustomClassification',
  'ARREligible',
  'IPCC2006LandCover',
  'ForestLoss',
  'Peatland',
];

export const MAPPING_LAYER_TO_BASEMAP: {
  [key in BaseMapLayerType]: BASEMAP_TYPE;
} = {
  LandCover: BASEMAP_TYPE.LAND_COVER,
  CustomClassification: BASEMAP_TYPE.CUSTOM_CLASSIFICATION,
  ARREligible: BASEMAP_TYPE.ARR_ELIGIBLE,
  ForestLoss: BASEMAP_TYPE.FOREST_LOSS,
  IPCC2006LandCover: BASEMAP_TYPE.IPCC_2006_LAND_COVER,
  Peatland: BASEMAP_TYPE.PEATLAND,
};

export const EARTH_RADIUS_KM = 6371;

// key increases => zoom closer
export const VIEWBOX_SCALES = [
  { zoomLevel: -Infinity, offset: 1 },
  { zoomLevel: 9, offset: 0.07 },
  { zoomLevel: 11, offset: 0.05 },
  { zoomLevel: 14, offset: 0.005 },
  { zoomLevel: 17, offset: 0.002 },
  { zoomLevel: Infinity, offset: 0.001 },
];

export const DEFAULT_ZOOM_MARKER = 15;

export const MAP_LABELS = {
  DenseForest: 'Dense Forest',
  NonDenseForest: 'Non-Dense Forest',
  NonForest: 'Non-Forest',
  Water: 'Water',
  ARREligible: 'ARR Eligible',
  Ineligible: 'Ineligible',
};
