import { Button, Col, Row } from 'antd';
import UserListPanel from 'components/UserManagement/UserListPanel';
import UserListTable from 'components/UserManagement/UserListTable';
import AppContainer from 'containers/AppLayout/AppContainer';
import { t } from 'helpers/i18n';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { userHooks, userManagementHooks } from 'hooks';
import { resourceConstants } from 'constants/index';

const { APPS, RESOURCES, ACTIONS } = resourceConstants;
const { PROJECT_MANAGER_TEKBONE } = APPS;
const { USER } = RESOURCES;
const { IMPORT } = ACTIONS;

const UserManagement: React.FC = () => {
  const {
    data,
    shouldLoadMore,
    onSearch,
    onLoadMore,
    ...rest
  } = userManagementHooks.useUserList();
  const { hasPermission } = userHooks.usePermission();

  const isAllowedToImport = hasPermission(
    PROJECT_MANAGER_TEKBONE,
    USER,
    IMPORT
  );

  return (
    <AppContainer
      title={
        <Row align="middle" justify={'end'}>
          <Col>
            {isAllowedToImport && (
              <Link to="/user-management/import" data-testid="import-user-btn">
                <Button icon={<PlusOutlined />} type="primary">
                  {t('ImportUser')}
                </Button>
              </Link>
            )}
          </Col>
        </Row>
      }
    >
      <UserListPanel onSearch={onSearch} />
      <UserListTable
        {...rest}
        dataSource={data.users}
        shouldLoadMore={shouldLoadMore}
        onLoadMore={onLoadMore}
        refreshData={onSearch}
      />
    </AppContainer>
  );
};

export default UserManagement;
