import React, { useEffect, useState } from 'react';
import BaseItem, { BaseItemProps } from '../BaseItem';
import { Button, Space, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { t } from 'helpers/i18n';
import { v4 } from 'uuid';
import { ColumnsType } from 'antd/lib/table';
import { Upload as MyUpload, UploadFile } from 'components/shared/Upload';

interface DocFileProps extends BaseItemProps {}
interface IDocFile {
  url: string;
  id: string;
}

const MAX_SIZE = 100;
const FILE_ACCEPT = `.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsx,.xls,.pdf,.doc,.docx`;
const FILE_ACCEPT_DISPLAY = `.xls,.xlsx,.csv,.pdf,.doc,.docx`;

const DocFile: React.FC<DocFileProps> = ({
  item,
  disabled,
  form,
  ...props
}) => {
  const [listDocs, setListDocs] = useState<IDocFile[]>([]);

  const updateDataForm = (list: IDocFile[]) => {
    if (item.fieldName)
      form.setFieldValue(
        item.fieldName,
        JSON.stringify(list.map(item => item.url))
      );
  };
  const onDeleteItem = (item: IDocFile) => {
    let newListMedia = [...listDocs];
    newListMedia = newListMedia.filter(value => value.id !== item.id);
    setListDocs(newListMedia);
    updateDataForm(newListMedia);
  };

  const columns: ColumnsType<IDocFile> = [
    {
      title: '',
      align: 'center',
      width: 50,
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: t('File'),
      dataIndex: 'url',
      ellipsis: true,
      render: (value, _, index) => {
        if (!value) return '';

        const $value = (value || '').toString();

        const filename = $value.substring($value.lastIndexOf('/') + 1);
        return (
          <a href={$value} key={index} target={'_blank'} rel="noreferrer">
            {filename}
          </a>
        );
      },
    },
    {
      title: t('Actions'),
      align: 'center',
      width: 90,
      render: (_, record) => {
        return (
          <Space size={'small'}>
            {!disabled && (
              <Button
                type={'link'}
                danger={true}
                icon={<DeleteOutlined />}
                size={'small'}
                onClick={() => onDeleteItem(record)}
              />
            )}
          </Space>
        );
      },
    },
  ];

  const onUploadSuccess = (files: UploadFile[]) => {
    const list: IDocFile[] = files.reduce((list, file) => {
      if (file.fileUrl) {
        list.push({
          id: file.uid,
          url: file.fileUrl,
        });
      }

      return list;
    }, [] as IDocFile[]);

    setListDocs(listDocs => {
      return [...listDocs, ...list];
    });
    updateDataForm([...listDocs, ...list]);
  };

  useEffect(() => {
    if (item.fieldName) {
      form.setFieldValue(item.fieldName, item.value);
    }
    if (item.value) {
      try {
        const values = JSON.parse(item.value);
        setListDocs(
          (values || []).map((value: string) => ({ id: v4(), url: value }))
        );
      } catch (e) {}
    }
  }, [item.value]);

  return (
    <BaseItem item={item} disabled={disabled} form={form} {...props}>
      {!disabled && (
        <MyUpload
          accept={FILE_ACCEPT}
          maxSize={MAX_SIZE}
          disabled={disabled}
          fileAcceptDisplay={FILE_ACCEPT_DISPLAY}
          onSuccess={onUploadSuccess}
        />
      )}
      <Table
        style={{
          marginTop: 12,
        }}
        columns={columns}
        rowKey={'url'}
        size={'small'}
        bordered={true}
        dataSource={listDocs}
        pagination={false}
        locale={{
          emptyText: t('NoData'),
        }}
      />
    </BaseItem>
  );
};

export default DocFile;
