import { Spin } from 'antd';
import { FLAGS, IFlagsData } from 'interfaces';
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { flagsupConstants } from 'constants/index';
import { flagHelper } from 'helpers';
import { useStoreContext } from 'contexts';

interface FlagsupProviderProps {
  children: ReactNode;
}

export interface IFlagContext {
  featureFlagsData: IFlagsData;
}

export const FlagsupContext = createContext<IFlagContext>({
  featureFlagsData: {},
});

export const useFlagsupContext = () => useContext(FlagsupContext);

const { FLAG_KEYS } = flagsupConstants;
const { apiServices } = window.config;
const FlagsupProvider: React.FC<FlagsupProviderProps> = ({ children }) => {
  const { currentUser } = useStoreContext();
  const [featureFlagsData, setFeatureFlagsData] = useState<IFlagsData>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    flagHelper.initFlags(apiServices.flagsup, currentUser.sub);
    const getData = async () => {
      setLoading(true);
      try {
        const featureFlagsData = await flagHelper.fetchEvaluateFlags(
          Object.values(FLAG_KEYS)
        );
        const flags: IFlagsData = {};
        Object.entries(FLAG_KEYS).forEach(([key, value]) => {
          flags[key as FLAGS] = featureFlagsData[value];
        });
        setFeatureFlagsData(flags);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  if (loading) return <Spin className="app-spin" />;

  return (
    <FlagsupContext.Provider value={{ featureFlagsData }}>
      {children}
    </FlagsupContext.Provider>
  );
};

export default FlagsupProvider;
