import { commonConstants } from 'constants/index';
import { IGetNotificationsParams, IStnNotification } from 'interfaces';
import { useEffect, useState } from 'react';
import { stnServices } from 'services';
import { IUserDetailsInfo } from 'teko-oauth2';

const useNotifications = (
  firebaseToken: string,
  currentUser: IUserDetailsInfo,
  defaultParams: IGetNotificationsParams
) => {
  const [notifications, setNotifications] = useState<IStnNotification[]>([]);
  const [unread, setUnread] = useState(0);
  const [params, setParams] = useState<IGetNotificationsParams>(defaultParams);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const getNotificationData = async (params: IGetNotificationsParams) => {
    try {
      setLoading(true);
      const res = await stnServices.getNotifications(params);
      await countUnread();
      setNotifications(res.data?.data?.notifications ?? []);
      setTotal(res.data?.pagination?.total ?? 0);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    setParams(params => {
      params.limit = params.limit + 5;

      return { ...params };
    });
  };

  const countUnread = async () => {
    const result = await stnServices.countUnRead({
      app_id: commonConstants.STN_APP,
      token: firebaseToken,
      ignore_pending_message: true,
    });

    setUnread(result.data?.data?.count ?? 0);
  };

  const registerToken = async () => {
    try {
      await stnServices.registerToken({
        app_id: commonConstants.STN_APP,
        user_id: currentUser?.sub,
        token: firebaseToken,
        transport: 'fcm',
        phone: currentUser.phone_number,
        email: currentUser.email,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const unregisterToken = async () => {
    try {
      await stnServices.unregisterToken({
        app_id: commonConstants.STN_APP,
        user_id: currentUser?.sub,
        token: firebaseToken,
        transport: 'fcm',
      });
    } catch (error) {
      console.log(error);
    }
  };

  const markRead = async (msgId: number) => {
    setNotifications(items => {
      return items.map(item => {
        if (item.id === msgId) {
          return {
            ...item,
            is_read: true,
          };
        }

        return item;
      });
    });

    await stnServices.markAsRead({
      msgId,
      token: firebaseToken,
    });
    await countUnread();
  };

  const markReadAll = async () => {
    setNotifications(items => {
      return items.map(item => {
        return {
          ...item,
          is_read: true,
        };
      });
    });

    setUnread(0);

    await stnServices.markReadAll({
      app_id: commonConstants.STN_APP,
      token: firebaseToken,
    });
  };

  useEffect(() => {
    registerToken();
    return () => {
      unregisterToken();
    };
  }, []);

  useEffect(() => {
    getNotificationData(params);
  }, [JSON.stringify(params)]);

  return {
    total,
    notifications,
    unread,
    loading,
    setParams,
    loadMore,
    getNotificationData,
    unregisterToken,
    registerToken,
    markRead,
    countUnread,
    markReadAll,
  };
};

export default {
  useNotifications,
};
