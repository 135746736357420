import { Button, Modal, ModalProps, notification } from 'antd';
import { buttonStyle } from 'components/shared/Button';
import { useDisclosureProps } from 'components/shared/Modal/useDisclosure';
import { COLOR } from 'constants/styles/color';
import { t } from 'helpers/i18n';
import { IProjectDetails } from 'interfaces';
import { useState } from 'react';
import { projectServices } from 'services';

interface ProjectModalDeleteProps extends ModalProps {
  modal: useDisclosureProps;
  project: IProjectDetails;
  refetch?: () => void;
}

const ProjectModalDelete: React.FC<ProjectModalDeleteProps> = ({
  modal,
  project,
  onCancel,
  refetch,
  ...props
}) => {
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState<boolean>(false);

  const onDelete = async () => {
    try {
      setLoading(true);
      const res = await projectServices.deleteProject(project.projectId);
      if (res && res.code === 0) {
        api.success({
          message: t('DeleteProjectSuccessfully'),
        });
        modal.onClose();
        refetch?.();
      } else throw new Error();
    } catch (_err) {
      api.error({
        message: t('SomethingWentWrong'),
        duration: 2,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        {...props}
        open={modal.isOpen}
        centered
        onCancel={onCancel ?? modal.onClose}
        width={400}
        zIndex={2001}
        title={t('DeleteProject')}
        footer={
          <div style={{ textAlign: 'center' }}>
            <Button
              key="back"
              onClick={onCancel ?? modal.onClose}
              style={{
                ...buttonStyle,
                height: '100%',
                minWidth: '100px',
                backgroundColor: COLOR.NEUTRAL,
              }}
              loading={loading}
              className="mr-half"
            >
              {t('No')}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              style={{
                ...buttonStyle,
                height: '100%',
                minWidth: '100px',
                backgroundColor: COLOR.PRIMARY,
              }}
              loading={loading}
              onClick={onDelete}
            >
              {t('Yes')}
            </Button>
          </div>
        }
      >
        <div style={{ textAlign: 'center' }}>
          <span>{t('AreYouSureYouWantTo')}</span>{' '}
          <span style={{ fontWeight: 'bold', textTransform: 'lowercase' }}>
            {t('Delete')}
          </span>{' '}
          <span style={{ textTransform: 'lowercase' }}>
            {t('Project')}
            {': '}
          </span>
          <p style={{ fontWeight: 'bold' }}>
            {project.projectTitle}
            {' ?'}
          </p>
        </div>
      </Modal>
      {contextHolder}
    </>
  );
};

export default ProjectModalDelete;
