import { Card, Spin } from 'antd';
import FundFilter from './FundFilter';
import FundTable from './FundTable';
import { communityHooks } from 'hooks';
import { useLocation } from 'react-router-dom';

const { useFunds } = communityHooks;

const FundList = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const communityId = query.get('communityId');
  const { loading, data, setQueryParams } = useFunds(communityId);
  const { funds = [], pagination } = data;

  return (
    <Spin spinning={loading}>
      <Card
        className="mb-base"
        styles={{
          body: {
            padding: 16,
          },
        }}
      >
        <FundFilter
          onSearch={values =>
            setQueryParams({ page: 1, pageSize: 10, ...values })
          }
        />
      </Card>
      <Card>
        <FundTable
          dataSource={funds}
          pagination={pagination}
          onChange={newPagination => {
            const { current = 1, pageSize = 10 } = newPagination;
            setQueryParams(prev => ({
              ...prev,
              page: pageSize !== pagination.pageSize ? 1 : current,
              pageSize,
            }));
          }}
        />
      </Card>
    </Spin>
  );
};

export default FundList;
