import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Button, List } from 'antd';
import { CREMA_PAGINATION_DEFAULT } from 'components/CREMA/constants';
import { t } from 'helpers/i18n';
import { IFarmer, IPaginationResponse } from 'interfaces';
import { useEffect, useState } from 'react';
import { farmerServices } from 'services';

interface IFarmersTab {
  communityId: number;
  onClickFarmer: (farmer: IFarmer) => void;
}

export const FarmersTab: React.FC<IFarmersTab> = ({
  communityId,
  onClickFarmer,
}) => {
  const [loading, setLoading] = useState(false);
  const [farmers, setFarmers] = useState<IFarmer[]>([]);
  const [pagination, setPagination] = useState<IPaginationResponse>(
    CREMA_PAGINATION_DEFAULT
  );
  const showLoadMore = pagination.total > pagination.page * pagination.pageSize;

  const getFarmers = async (curPagination?: IPaginationResponse) => {
    try {
      setLoading(true);
      const page = curPagination?.page || CREMA_PAGINATION_DEFAULT.page;
      const {
        farmers: farmersRes,
        pagination: paginationRes,
      } = await farmerServices.getFarmersByCommunity({
        page,
        pageSize: curPagination?.pageSize || CREMA_PAGINATION_DEFAULT.pageSize,
        communityIds: [communityId],
      });
      if (page > 1) {
        setFarmers(prev => [...prev, ...(farmersRes || [])]);
      } else {
        setFarmers(farmersRes || []);
      }
      setPagination(paginationRes || CREMA_PAGINATION_DEFAULT);
    } finally {
      setLoading(false);
    }
  };

  const onLoadMoreFarmers = async () => {
    await getFarmers({
      ...pagination,
      page: pagination.page + 1,
    });
  };

  useEffect(() => {
    getFarmers();
  }, [communityId]);

  return (
    <List
      itemLayout="horizontal"
      loading={loading}
      dataSource={farmers}
      renderItem={item => (
        <List.Item
          onClick={() => onClickFarmer(item)}
          style={{ cursor: 'pointer' }}
        >
          <List.Item.Meta
            key={item.farmerId}
            style={{ alignItems: 'center' }}
            avatar={<UserOutlined style={{ fontSize: 20 }} />}
            title={item.farmerName}
            description={item.farmerPhone}
          />
        </List.Item>
      )}
      loadMore={
        !!showLoadMore && (
          <div className="view-more">
            <Button onClick={onLoadMoreFarmers} type="link">
              {t('ViewMore')} <DownOutlined />
            </Button>
          </div>
        )
      }
    />
  );
};
