import { Button, Divider, Row, Table, Tag, Tooltip, Typography } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import ContentBlock from 'components/shared/ContentBlock';
import { t } from 'helpers/i18n';
import { FileSearchOutlined, DownloadOutlined } from '@ant-design/icons';
import {
  DispatchWithoutAction,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { fileServices } from 'services';
import {
  IGetListProcessingFilesParams,
  IGetListProcessingFilesResponse,
  IProcessingFile,
  ProcessingFileType,
} from 'interfaces';
import { dateTimeHelpers, fileHelpers } from 'helpers';
import { DEFAULT_OPTION, DEFAULT_PAGE_SIZE } from 'constants/pagination';
import { forwardRef } from 'react';
import { FILE_PROCESSING_STATUS_MAP } from 'constants/file';

const { previewExcelFile } = fileHelpers;

interface IColumnOptions {
  showError: boolean;
}

const getColumns = ({
  showError,
}: IColumnOptions): ColumnsType<IProcessingFile> => [
  {
    title: 'ID',
    dataIndex: 'processFileId',
    align: 'center',
  },
  {
    title: t('File'),
    render: file => (
      <Row align="middle" wrap={false}>
        <Tooltip title={file.fileDisplayName}>
          <Typography.Link
            href={file.fileUrl}
            ellipsis
            style={{ maxWidth: 250 }}
            className="file-name"
          >
            {file.fileDisplayName}
          </Typography.Link>
        </Tooltip>
        <Divider type="vertical" />
        <Tooltip title="Preview">
          <Button
            icon={<FileSearchOutlined />}
            type="text"
            onClick={() => previewExcelFile(file.fileUrl)}
          />
        </Tooltip>
      </Row>
    ),
  },
  {
    title: t('CreatedDate'),
    render: file => (
      <div>
        <div>
          {dateTimeHelpers.formatUnixTimpestampMilliseconds(file.createdAt)}
        </div>
      </div>
    ),
  },
  {
    title: t('Status'),
    dataIndex: 'status',
    render: (status: IProcessingFile['status']) => {
      const mappedStatus = FILE_PROCESSING_STATUS_MAP[status];
      return (
        <Tag className="text-small" color={mappedStatus?.color}>
          {mappedStatus?.label || status}
        </Tag>
      );
    },
  },
  {
    title: t('Result'),
    render: file => (
      <div>
        {showError && <div className="mb-half">{file.errorDisplay}</div>}
        <Row align="middle" wrap={false} style={{ whiteSpace: 'nowrap' }}>
          <Tooltip
            title={t('SuccessPerTotalRow', {
              success: file.statsTotalSuccess,
              total: file.statsTotalRow,
            })}
          >
            <Typography.Text
              className="text-medium text-size-medium text-medium"
              type={
                file.statsTotalSuccess === file.statsTotalRow &&
                file.statsTotalRow > 0
                  ? 'success'
                  : file.statsTotalSuccess > 0
                  ? 'warning'
                  : 'secondary'
              }
            >
              {file.statsTotalSuccess}
            </Typography.Text>
            <Typography.Text type="secondary">
              /{file.status === 'FAILED' ? '~' : file.statsTotalRow}
            </Typography.Text>
          </Tooltip>
          <Divider type="vertical" />
          <Button
            icon={<FileSearchOutlined />}
            type="text"
            onClick={() => previewExcelFile(file.resultFileUrl)}
          />
          <Divider type="vertical" />
          <a href={file.resultFileUrl}>
            <Button icon={<DownloadOutlined />} type="text" />
          </a>
        </Row>
      </div>
    ),
  },
];

interface FileProcessHistoryProps {
  processingFileType: ProcessingFileType;
  className?: string;
  showError?: boolean;
}

export interface FileProcessingHistoryRef {
  refresh: DispatchWithoutAction;
}

const FileProcessHistory = forwardRef<
  FileProcessingHistoryRef,
  FileProcessHistoryProps
>(({ processingFileType, className, showError = true }, ref) => {
  const [processingFiles, setProcessingFiles] = useState<IProcessingFile[]>([]);
  const [pagination, setPagination] = useState<
    IGetListProcessingFilesResponse['pagination']
  >({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
  });
  const [loading, setLoading] = useState(false);

  // Expose refresh function through ref object to allow parent component to trigger a refresh of the table data
  useImperativeHandle(
    ref,
    () => ({
      refresh: () => {
        getProcessingFiles(DEFAULT_OPTION);
      },
    }),
    []
  );

  const getProcessingFiles = async (
    params: Omit<IGetListProcessingFilesParams, 'type'>
  ) => {
    try {
      setLoading(true);
      const data = await fileServices.getListProcessingFiles({
        ...params,
        isMyUploaded: true,
        type: processingFileType,
      });
      setPagination(data.pagination);
      setProcessingFiles(data.processFiles);
    } finally {
      setLoading(false);
    }
  };

  const onChangePagination = (newPagination: TablePaginationConfig) => {
    getProcessingFiles({
      page: newPagination.current || pagination.page,
      pageSize: newPagination.pageSize || pagination.pageSize,
    });
  };

  useEffect(() => {
    getProcessingFiles({
      page: pagination.page,
      pageSize: pagination.pageSize,
    });
  }, []);

  return (
    <ContentBlock className={`mx-half ${className}`} title={t('ImportHistory')}>
      <Table
        bordered
        size="middle"
        rowKey="processingFileId"
        columns={getColumns({ showError })}
        dataSource={processingFiles}
        loading={loading}
        pagination={{ total: pagination.total }}
        onChange={onChangePagination}
        scroll={{ x: 'auto' }}
      />
    </ContentBlock>
  );
});

export default FileProcessHistory;
