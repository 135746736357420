import {
  Button,
  Drawer,
  Form,
  Input,
  Upload,
  notification,
  UploadFile as UploadFileAntd,
  Flex,
} from 'antd';
import { t } from 'helpers/i18n';
import { useContext, useState } from 'react';
import {
  DOCUMENTATION_FILE_SIZE,
  DOCUMENTATION_FILE_TYPES,
} from 'components/CREMA/constants';
import { cremaServices } from 'services';
import { ProjectContext } from 'contexts';
import { IAddDocumentation } from 'interfaces';
import { MAX_LENGTH_FILE_NAME, REGEX_PATTERN_FILE_NAME } from 'constants/file';
import { InboxOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { fileHelpers } from 'helpers';
import GCSUpload, { UploadFile } from 'components/shared/Upload/GCSUpload';
import { v4 } from 'uuid';

const AddDocumentation: React.FC<IAddDocumentation> = props => {
  const [form] = Form.useForm();
  const { projectId } = useContext(ProjectContext);

  const { cremaId, setTriggerDocumentationTimes, open, onClose } = props;
  const [saveLoading, setSaveLoading] = useState(false);
  const [uploadFileList, setUploadFileList] = useState<UploadFile[]>([]);

  const close = (e?: any) => {
    form.resetFields();
    setUploadFileList([]);
    onClose?.(e);
  };

  const handleSubmit = async ({ filename }: { filename: string }) => {
    try {
      setSaveLoading(true);
      const fileUrl = uploadFileList[0].fileUrl;
      const fileId = fileUrl?.split('/').pop();

      if (fileId) {
        await cremaServices.upsertCremaDocument({
          projectId,
          organizationId: cremaId,
          name: filename,
          fileId,
        });
        notification.success({
          message: t('AddDocumentSuccessfully'),
        });
        close();
        setTriggerDocumentationTimes(prev => prev + 1);
      }
    } finally {
      setSaveLoading(false);
    }
  };

  const footer = (
    <Flex gap={8} className="p-half" justify="center">
      <Button className="w-120px" onClick={close}>
        {t('Cancel')}
      </Button>
      <Button
        className="w-120px"
        type="primary"
        onClick={() => form.submit()}
        loading={saveLoading}
      >
        {t('Save')}
      </Button>
    </Flex>
  );

  const validateFile = (file: RcFile) => {
    const result = fileHelpers.validateFileTypeAndFileSize(
      file,
      DOCUMENTATION_FILE_TYPES,
      DOCUMENTATION_FILE_SIZE
    );

    if (!result.status) {
      notification.error({
        message: result.message,
      });
      return Upload.LIST_IGNORE;
    }

    form.setFieldValue('filename', file.name.replace(/\.[^/.]+$/, ''));
    form.validateFields(['filename']);

    return true;
  };

  const handleUpload = ({ file }: any) => {
    setSaveLoading(true);
    const uid = v4();
    const uploadFile: UploadFileAntd = {
      uid,
      name: file.name,
      percent: 0,
      size: file.size,
      status: 'uploading',
      originFileObj: {
        ...file,
        uid,
        lastModifiedDate: new Date(),
      },
    };

    setUploadFileList([
      fileHelpers.convertAntdFileToUploadFile(uploadFile, file),
    ]);
    form.setFieldValue('files', [uploadFile]);
  };

  const updateUploadFileList = (files: UploadFile[]) => {
    const doneFiles = files.filter(
      file =>
        file.status === 'Uploaded' ||
        (file.status === 'Closed' && file.type !== 'error')
    );
    const errorFiles = files.filter(file => file.status === 'Error');

    if (!!doneFiles.length) {
      setSaveLoading(false);
      setUploadFileList(doneFiles);
      form.setFieldValue('files', [
        { ...doneFiles[0], name: doneFiles[0].originFile?.name },
      ]);
    } else if (!!errorFiles.length) {
      setSaveLoading(false);
      notification.error({
        message: t('UploadFailed'),
      });
      setUploadFileList([]);
      form.setFieldValue('files', []);
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Drawer
      placement="right"
      getContainer={false}
      open={open}
      zIndex={1005}
      closable={false}
      title={t('AddCREMADocumentations')}
      footer={footer}
    >
      <Form
        layout="vertical"
        form={form}
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Form.Item>
          <Form.Item
            name="files"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            noStyle
            rules={[{ required: true, message: t('PleaseSelectFile') }]}
          >
            <Upload.Dragger
              className="add-documentation-upload"
              height={200}
              listType="picture"
              maxCount={1}
              beforeUpload={validateFile}
              customRequest={handleUpload}
              disabled={saveLoading}
              accept={DOCUMENTATION_FILE_TYPES.map(type => `.${type}`).join(
                ', '
              )}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Drop your file here, or <span>browse</span>
              </p>
              <p className="ant-upload-hint">
                {t('SupportForFiles', {
                  accept: DOCUMENTATION_FILE_TYPES.map(type => `.${type}`).join(
                    ', '
                  ),
                  size: DOCUMENTATION_FILE_SIZE,
                })}
              </p>
            </Upload.Dragger>
          </Form.Item>
          <GCSUpload files={uploadFileList} setFiles={updateUploadFileList} />
        </Form.Item>

        <Form.Item
          name="filename"
          label="File name"
          required
          className="add-documentation-filename"
          rules={[
            { whitespace: true, message: t('PleaseInputFilename') },
            { required: true, message: t('PleaseInputFilename') },
            { max: MAX_LENGTH_FILE_NAME, message: t('FilenameTooLong') },
            {
              pattern: REGEX_PATTERN_FILE_NAME,
              message: t('FilenameIsInvalid'),
            },
          ]}
          validateTrigger={['onChange']}
        >
          <Input placeholder="filename.docx" />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddDocumentation;
