import {
  ISubmission,
  IGetSubmissionResponse,
  IUpdateSubmission,
  IUpdateSubmissionResponse,
  IGetSubmissionResponseAfterCAR2355,
  ISubmissionFilters,
  ISnapshotTree,
  IGetTreesParams,
  IGetTreesResponse,
  ISnapTreeItem,
  IGetSnapshotTreesResponse,
  IPaginationOffsetParams,
} from 'interfaces';
import { rest } from 'msw';
import { SERVER_ROUTES } from './apiRoute';

const MOCK_SUBMISSIONS: ISubmission[] = [
  {
    submission: {
      plotId: 1,
      plotName: 'Plot A',
      submissionId: 1,
      submissionTime: '2022-09-27T07:59:30.406Z',
      treeCode: 'Tree A',
      treeImages: [
        {
          imageId: 1,
          image: 'https://path.to.image.url',
          imageTimestamp: 1672240229337,
          latitude: 12.12,
          longitude: 13.13,
        },
      ],
      treeTrunkDiameter: 1.91,
      carbonContent: 1.324,
      co2Offset: 1.324,
      treeSpeciesId: 1,
      treeSpeciesCodeName: 'Mangrove',
      submitterId: 'abcd1234',
      submitterName: 'TanTV',
      isEditable: false,
      treeHeight: 1,
      checksumTreeImages: [],
    },
    submissionStatus: {
      projectId: 1,
      submissionId: 1,
      isSelected: true,
      rejectReason: 'The tree does not belong to project',
      selectedByUserId: 'abcd1234',
      selectedByUserName: 'TanTV',
      selectionTime: '2022-09-27T07:59:30.406Z',
    },
  },
];

const filterByPolygonId = (subs: ISubmission[]): ISubmission[] => {
  let subIdsToBeCollected = [2];
  return subs.filter(sub =>
    subIdsToBeCollected.includes(sub.submission.submissionId)
  );
};

const getSubmissions = (): Promise<IGetSubmissionResponse> => {
  let submissions: ISubmission[] = MOCK_SUBMISSIONS;
  submissions = filterByPolygonId(submissions);

  return Promise.resolve({
    submissions: submissions,
    pagination: {
      offset: 0,
      total: 100,
    },
  });
};

const getSubmissionsTekbone = (
  _params: ISubmissionFilters
): Promise<IGetSubmissionResponseAfterCAR2355> => {
  return Promise.resolve({
    submissions: [
      {
        plotId: 7233569,
        plotName: 'Test China',
        submissionId: 249,
        submissionTime: 111111,
        treeCode: 'Tree 249',
        treeImages: [],
        treeTrunkDiameter: 3.819718634205488,
        carbonContent: 1.2179204532091523,
        co2Offset: 4.465708328433558,
        treeSpeciesId: 83,
        treeSpeciesCodeName: 'mangrove',
        submitterId: '0c3b110f3457496e85271ea0488de1bf',
        submitterName: 'Duong Teko Admin',
        isEditable: false,
        location: { latitude: 21.021254, longitude: 105.423453 },
        checksumTreeImages: ['51dff9829605e45df965437c74231c86'],
        treeType: 'MATURE',
        projectId: 1,
        treeHeight: 10,
      },
    ],
    pagination: {
      offset: 0,
      total: 100,
    },
  });
};

export const handlersSubmission = [
  rest.put(SERVER_ROUTES.SUBMISSION.UPDATE_SUBMISSION, (req, res, ctx) => {
    const body = req.body as IUpdateSubmission;
    if (
      !body.treeSpeciesId ||
      !body.treeSpeciesCodeName ||
      !body.treeTrunkDiameter ||
      body.submissionId % 2
    )
      return res(ctx.status(400));
    const mockResponse: IUpdateSubmissionResponse = {
      code: 200,
      data: {
        submissionId: body.submissionId,
      },
      message: 'Success',
    };
    return res(
      ctx.json({
        ...mockResponse,
      })
    );
  }),
  rest.get(SERVER_ROUTES.SUBMISSION.EXPORT_SUBMISSION, (req, res, ctx) => {
    const fakeSpreadsheet = new Blob([''], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    return res(
      ctx.status(200),
      ctx.set(
        'Content-Disposition',
        'attachment; filename="fake-spreadsheet.xlsx"'
      ),
      ctx.set(
        'Content-Type',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ),
      ctx.body(fakeSpreadsheet)
    );
  }),
];

const SNAPSHOT_TREE: ISnapshotTree = {
  plotId: 1,
  projectId: 1,
  treeSpeciesCodeName: 'mangrove',
  treeImages: [],
  treeHeight: 1.56,
  distance: 5,
  treeDegrees: 2.5,
  treeLabel: 'Tree name 01',
  treeZone: 'NORTH',
  checksumTreeImages: ['d7bbe6c38e119167120bc7ce74ca6874'],
  plotName: 'Plot A',
  carbonContent: 1.324,
  co2Offset: 4.855,
  submissionTime: 1692819200,
  isEditable: true,
  location: {
    latitude: 12.12,
    longitude: 13.13,
  },
  submissionId: 1,
  treeAge: 10,
  treeCode: 'Tree A',
  treeTrunkDiameter: 1.91,
  treeSpeciesId: 1,
  treeType: 'SEEDLING',
  submitterId: 'abcd1234',
  submitterName: 'TanTV',
  snapshotTreeId: 1,
  isSelected: true,
  rejectReason: 'The tree does not belong to project',
  selectedByUserId: 'iam-user-id',
  selectedByUserName: 'TanTV',
  selectionTime: 1662545330,
  systemDetectedDiameter: 11.56,
  allowedDiameterDifference: 1.15,
  isValidDiameter: true,
  diameterDetectStatus: 'SUCCESS',
  isDiameterMeasuredByAR: false,
};

const getSnapshotTree = (
  _treeId: number,
  _snapshotTreeId: number,
  _projectId: number
): Promise<ISnapshotTree> => {
  return Promise.resolve(SNAPSHOT_TREE);
};

const TREE_LIST: ISnapTreeItem[] = [
  {
    treeId: 1,
    snapshotTreeId: 1,
    treeLabel: 'Tree 01',
    submissionTime: 1321942832,
    treeHeight: 156.5,
    isDiameterMeasuredByAR: false,
    treeTrunkCircumference: 100.91,
    treeTrunkDiameter: 1.91,
    carbonContent: {
      value: 1.324,
      unitOfMeasure: 'kgC',
    },
    co2Offset: {
      value: 4.855,
      unitOfMeasure: 'kgCO2',
    },
    treeType: 'SEEDLING',
    approvedStatus: 'SELECTED',
  },
  {
    treeId: 2,
    snapshotTreeId: 2,
    treeLabel: 'Tree 02',
    submissionTime: 1321942832,
    treeHeight: 156.5,
    isDiameterMeasuredByAR: false,
    treeTrunkCircumference: 100.91,
    treeTrunkDiameter: 1.91,
    carbonContent: {
      value: 1.324,
      unitOfMeasure: 'kgC',
    },
    co2Offset: {
      value: 4.855,
      unitOfMeasure: 'kgCO2',
    },
    treeType: 'SNAG',
    approvedStatus: 'REJECTED',
  },
  {
    treeId: 3,
    snapshotTreeId: 3,
    treeLabel: 'Tree 03',
    submissionTime: 1321942832,
    treeHeight: 156.5,
    isDiameterMeasuredByAR: true,
    treeTrunkCircumference: 100.91,
    treeTrunkDiameter: 1.91,
    carbonContent: {
      value: 1.324,
      unitOfMeasure: 'kgC',
    },
    co2Offset: {
      value: 4.855,
      unitOfMeasure: 'kgCO2',
    },
    treeType: 'MATURE',
    approvedStatus: 'SUBMITTED',
  },
];

const getSnapshotTrees = (
  _treeId: number,
  _params: IPaginationOffsetParams & {
    projectId: number;
  }
): Promise<IGetSnapshotTreesResponse> => {
  return Promise.resolve({
    snapshotTrees: TREE_LIST.map(tree => ({
      ...tree,
      snapshotTreeId: tree.snapshotTreeId * 10,
      deltaTreeHeight: 3.3,
      deltaTreeTrunkCircumference: 5.5,
      deltaTreeTrunkDiameter: 4.587,
      deltaCarbonContent: 4,
      deltaCo2Offset: -6.75,
    })),
    pagination: {
      offset: 0,
      total: 100,
    },
  });
};

const getTrees = (_params: IGetTreesParams): Promise<IGetTreesResponse> => {
  return Promise.resolve({
    trees: TREE_LIST,
    pagination: {
      total: 11,
      offset: 10,
    },
  });
};

export default {
  getSubmissions,
  getSubmissionsTekbone,
  getSnapshotTree,
  getSnapshotTrees,
  getTrees,
};
