import { AxiosRequestConfig } from 'axios';
import {
  DEBOUNCE_TIME,
  ID_DEFAULT,
  PROJECT_BOUNDARY_Z_INDEX,
} from 'constants/common';
import { useProjectDetailV2Context } from 'containers/Project/ProjectDetailMapV2/context';
import { mapHelpers } from 'helpers';
import { useAbortController } from 'hooks/axios';
import {
  IGetPolygonTekboneParams,
  IGetProjectPlotsByCoorsParams,
} from 'interfaces';
import React, { useEffect, useRef } from 'react';
import { useMap, useMapEvents } from 'react-leaflet';
import { PAGING_OFFSET_ALL } from 'constants/pagination';
import { useProjectContext } from 'contexts';

const { getSearchBoxObjectFromMap } = mapHelpers;

interface MapEventsProps {
  maxZIndex: number;
  isPlotMode: boolean;
  params: IGetPolygonTekboneParams;
  setParams: React.Dispatch<React.SetStateAction<IGetPolygonTekboneParams>>;
  cancelPreviousRequestPolygons: () => void;
  getPlots: (
    params: Omit<IGetProjectPlotsByCoorsParams, 'projectId'>,
    configs?: AxiosRequestConfig
  ) => Promise<void>;
}

const MapEvents: React.FC<MapEventsProps> = ({
  maxZIndex,
  params,
  setParams,
  cancelPreviousRequestPolygons,
  getPlots,
  isPlotMode,
}) => {
  const { projectId } = useProjectContext();
  const map = useMap();
  const {
    selectedLayerTypes,
    statusAfterMapChanges,
    currentPolygonId,
    setStatusAfterMapChanges,
    showAddPolygon,
    gettingPolygons,
    form,
  } = useProjectDetailV2Context();
  const {
    cancelPreviousRequest: cancelPreviousRequestPlots,
    newAbortSignal,
  } = useAbortController();

  const basemapLayerType = selectedLayerTypes
    ? mapHelpers.getBaseMapLayerType(selectedLayerTypes)
    : undefined;
  const allowSearchPolygons =
    !basemapLayerType &&
    statusAfterMapChanges.callAPI &&
    currentPolygonId === ID_DEFAULT;
  const extraParams = {
    ...PAGING_OFFSET_ALL,
    projectId,
  };
  const prevZoom = useRef(0);

  const getCoorsPolygonPayload = () => {
    const mapBounds = map.getBounds();
    return {
      searchLatitudeMax: mapBounds.getNorthEast().lat,
      searchLongitudeMax: mapBounds.getNorthEast().lng,
      searchLatitudeMin: mapBounds.getSouthWest().lat,
      searchLongitudeMin: mapBounds.getSouthWest().lng,
    };
  };
  const getPolygonPayload = (newZIndex: number) => {
    const formValues = form?.getFieldsValue();
    if (formValues?.polygonName || formValues?.polygonTypeIds?.length) {
      return { ...params, ...(params.zIndex ? { zIndex: newZIndex } : {}) };
    }
    return {
      zIndex: params.zIndex ? newZIndex : PROJECT_BOUNDARY_Z_INDEX,
    };
  };
  const onGetPlots = async () => {
    const mapCornerCoordinates = getSearchBoxObjectFromMap(map);
    await getPlots(
      {
        ...mapCornerCoordinates,
        ...(currentPolygonId !== ID_DEFAULT
          ? { polygonId: currentPolygonId }
          : {}),
      },
      { signal: newAbortSignal() }
    );
  };

  const onZoomPolygons = () => {
    var curZoom = map.getZoom();
    if (allowSearchPolygons) {
      let coorsPayload = getCoorsPolygonPayload();
      // zoom out
      if (curZoom < prevZoom.current) {
        const zIndexParams =
          params.zIndex && params.zIndex > 1
            ? params.zIndex - 1
            : PROJECT_BOUNDARY_Z_INDEX;
        setParams({
          ...getPolygonPayload(zIndexParams),
          ...coorsPayload,
          ...extraParams,
        });
      }
      // zoom in
      if (curZoom > prevZoom.current) {
        const zIndexParams =
          params.zIndex && params.zIndex + 1 < maxZIndex
            ? params.zIndex + 1
            : maxZIndex;
        setParams({
          ...getPolygonPayload(zIndexParams),
          ...coorsPayload,
          ...extraParams,
        });
      }
    }
    prevZoom.current = curZoom;
  };
  const onDragPolygons = async () => {
    if (allowSearchPolygons) {
      let coorsPayload = getCoorsPolygonPayload();
      setParams({
        ...getPolygonPayload(params.zIndex || PROJECT_BOUNDARY_Z_INDEX),
        ...coorsPayload,
        ...extraParams,
      });
    }
  };

  useMapEvents({
    zoomend: () => {
      if (isPlotMode) {
        onGetPlots();
      } else if (!showAddPolygon) {
        onZoomPolygons();
      }
    },
    dragend: () => {
      if (isPlotMode) {
        onGetPlots();
      } else if (!showAddPolygon) {
        onDragPolygons();
      }
    },
    zoomstart: () => {
      cancelPreviousRequestPolygons();
      cancelPreviousRequestPlots();
    },
    dragstart: () => {
      cancelPreviousRequestPolygons();
      cancelPreviousRequestPlots();
    },
  });

  useEffect(() => {
    // Note: when API call => map may change or not
    // Waiting for map changes successfully
    if (!gettingPolygons) {
      setTimeout(
        () =>
          setStatusAfterMapChanges({
            isCenter: false,
            callAPI: true,
          }),
        DEBOUNCE_TIME
      );
    }
  }, [gettingPolygons]);

  useEffect(() => {
    if (isPlotMode) {
      onGetPlots();
    } else {
      cancelPreviousRequestPlots();
    }
  }, [isPlotMode]);

  useEffect(() => {
    prevZoom.current = map.getZoom();
  }, [map]);

  return <></>;
};

export { MapEvents };
