import React, { useState } from 'react';
import { ProjectDrawersContext } from '.';
import { SamplingPlotsType } from 'interfaces';

interface ProjectDrawersContextProps {
  children: React.ReactNode;
  isGenerateReport: boolean;
}

/** Variables **/

/** ------------------------- **/
const ProjectDrawersProvider: React.FC<ProjectDrawersContextProps> = ({
  children,
  isGenerateReport,
}) => {
  const [showGenerateReport, setShowGenerateReport] = useState(
    isGenerateReport
  );
  const [isDetailProjectDrawerOpen, setIsDetailProjectDrawerOpen] = useState(
    false
  );
  const [openList, setOpenList] = useState(false);
  const [samplingPlotsDrawerType, setSamplingPlotsDrawerType] = useState<
    SamplingPlotsType | undefined
  >();

  return (
    <ProjectDrawersContext.Provider
      value={{
        showGenerateReport,
        setShowGenerateReport,
        isDetailProjectDrawerOpen,
        setIsDetailProjectDrawerOpen,
        openList,
        setOpenList,
        samplingPlotsDrawerType,
        setSamplingPlotsDrawerType,
      }}
    >
      {children}
    </ProjectDrawersContext.Provider>
  );
};

export default ProjectDrawersProvider;
