import { useProjectContext } from 'contexts';
import { IMeetingMinutes } from 'interfaces';
import { useState } from 'react';
import { meetingRecordsServices } from 'services';

const useMeetingMinutes = () => {
  const { projectId } = useProjectContext();
  const [loading, setLoading] = useState(false);
  const [meetingMinutes, setMeetingMinutes] = useState<IMeetingMinutes[]>([]);

  const getMeetingMinutes = async (
    communityId: number,
    financialYear: number
  ) => {
    try {
      setLoading(true);
      const {
        meetingMinutes,
      } = await meetingRecordsServices.getListCommunityMeetingMinutes({
        communityId,
        financialYear,
        projectId,
      });
      setMeetingMinutes(meetingMinutes || []);
    } finally {
      setLoading(false);
    }
  };

  return {
    getMeetingMinutes,
    loading,
    meetingMinutes,
  };
};

export default { useMeetingMinutes };
