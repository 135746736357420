import { configureStore } from '@reduxjs/toolkit';
import submissionManagementReducer from './features/submission';
import projectReducer from './features/project';
import showingSamplingPlotReducer from './features/samplingPlot';

export const store = configureStore({
  reducer: {
    submissionManagement: submissionManagementReducer,
    project: projectReducer,
    showingSamplingPlot: showingSamplingPlotReducer,
  },
});

// Infer the `RootState`,  `AppDispatch`, and `AppStore` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
