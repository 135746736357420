export const getDefaultGeneratePlotsValue = (
  plotsWithData: number,
  area: number
) => {
  return Math.max(Math.round(area * 5), plotsWithData);
};

export const getNumOfPlotsSuggestionTypeCircle = (
  area: number,
  plotRadius: number
) => {
  return Math.round(area / (Math.PI * plotRadius * plotRadius));
};
