import { IdcardOutlined } from '@ant-design/icons';
import { orgChartRoutes, accountManagementRoutes } from './common';
import { t } from 'helpers/i18n';

export const kwahuRoutes = [
  { path: '/' },
  { path: '/dashboards' },
  {
    path: '/feasibility-study',
    children: [
      '/carbon-potential-report',
      '/feasibility-study-report',
      '/consultant-reports',
    ],
  },
  { path: '/carbon-potential-report' },
  { path: '/feasibility-study-report' },
  {
    path: '/consultant-reports',
  },
  { path: '/consultant-reports/luluc' },
  { path: '/consultant-reports/intervention-i3a1' },
  {
    path: '/consultant-reports/fire-plan',
  },
  //
  {
    path: '/project-design',
    children: [
      '/project-boundaries',
      '/carbon-assessment',
      '/risk-assessment',
      '/control-plots',
      '/carbon-baseline',
      '/performance-benchmark',
      '/verra-pdd',
    ],
  },
  { path: '/project-boundaries' },
  { path: '/carbon-assessment' },
  { path: '/risk-assessment' },
  { path: '/control-plots' },
  { path: '/carbon-baseline' },
  { path: '/performance-benchmark' },
  { path: '/verra-pdd' },
  //
  {
    path: '/fpic-management',
    children: [
      '/fpic-management/meeting-records',
      '/fpic-management/template-management',
      '/fpic-management/fpic-dashboards',
    ],
  },
  { path: '/fpic-management/main' },
  { path: '/fpic-management/meeting-records' },
  { path: '/fpic-management/meeting-records/create' },
  { path: '/fpic-management/template-management' },
  { path: '/fpic-management/fpic-dashboards' },
  //
  {
    path: '/crema',
    children: [
      '/crema-formation',
      '/crema/crema-operation',
      '/crema/crema-kpi-report',
    ],
  },
  { path: '/crema-formation' },
  { path: '/crema-formation/meeting-records' },
  { path: '/crema/crema-operation' },
  { path: '/crema/crema-kpi-report' },
  //
  {
    path: '/farmer-management',
    children: [
      '/farmer-management/farmer-enrollment',
      '/farmer-management/farm-land-management',
      '/farmer-management/farm-eligible-review',
      '/farmer-management/farmer-profiles',
      '/farmer-management/longitudinal-planting-records',
      '/farmer-management/consultant-report',
    ],
  },
  { path: '/farmer-management/main' },
  { path: '/farmer-management/farmer-enrollment' },
  { path: '/farmer-management/farm-land-management' },
  { path: '/farmer-management/farm-eligible-review' },
  { path: '/farmer-management/farmer-profiles' },
  { path: '/farmer-management/longitudinal-planting-records' },
  { path: '/farmer-management/consultant-report' },
  {
    path: '/farmer-management/consultant-report/eligible-ineligible-catalogue',
  },
  //
  {
    path: '/benefit',
    children: [
      '/benefit-management',
      '/payment-services',
      '/financial-reporting',
    ],
  },
  { path: '/benefit-management' },
  { path: '/payment-services' },
  { path: '/financial-reporting' },
  //
  {
    path: '/monitoring-evaluation',
    children: [
      '/carbon',
      '/submissions',
      '/biodiversity',
      '/community',
      '/landscape-governance',
      '/me-reports',
      '/planting-kpi',
      '/quarterly-reports',
    ],
  },
  { path: '/carbon' },
  { path: '/submissions' },
  { path: '/biodiversity' },
  { path: '/community' },
  { path: '/landscape-governance' },
  { path: '/me-reports' },
  { path: '/planting-kpi' },
  { path: '/quarterly-reports' },
  { path: '/quarterly-reports/:quarter' },
  //
  {
    path: '/feedback-management',
    children: [
      '/feedback-management/dashboards',
      '/feedback-management/tickets',
    ],
  },
  { path: '/feedback-management/dashboards' },
  {
    path: '/feedback-management/tickets',
    children: [
      '/feedback-management/tickets/list',
      '/feedback-management/tickets/create',
      '/feedback-management/tickets/issue-types',
    ],
  },
  { path: '/feedback-management/tickets/list' },
  { path: '/feedback-management/tickets/create' },
  { path: '/feedback-management/tickets/issue-types' },
  ...orgChartRoutes,
  ...accountManagementRoutes,
];

export const sidamaProjectRoutes = [
  { path: '/' },
  { path: '/dashboards' },
  {
    path: '/feasibility-study',
    children: [
      '/project-concept-document',
      '/project-concept-presentation',
      '/carbon-potential-report',
    ],
  },
  { path: '/carbon-potential-report' },
  { path: '/project-concept-document' },
  { path: '/project-concept-presentation' },
  {
    path: '/project-design',
    children: ['/project-boundaries', '/carbon-assessment'],
  },
  { path: '/project-boundaries' },
  { path: '/carbon-assessment' },
  ...orgChartRoutes,
  ...accountManagementRoutes,
];

export const kakumProjectRoutes = [
  { path: '/' },
  { path: '/dashboards' },
  {
    path: '/feasibility-study',
    children: ['/carbon-potential-report'],
  },
  { path: '/carbon-potential-report' },
  {
    path: '/project-design',
    children: ['/project-boundaries'],
  },
  { path: '/project-boundaries' },
  {
    path: '/monitoring-evaluation',
    children: ['/biodiversity'],
  },
  { path: '/biodiversity' },
  {
    path: '/fpic-management/meeting-records',
    icon: IdcardOutlined,
    name: t('SurveyManagement'),
  },
  { path: '/fpic-management/meeting-records/create' },
  ...orgChartRoutes,
  ...accountManagementRoutes,
];

export const biaRoutes = [
  { path: '/' },
  { path: '/dashboards' },
  {
    path: '/feasibility-study',
    children: ['/carbon-potential-report'],
  },
  { path: '/carbon-potential-report' },
  {
    path: '/project-design',
    children: ['/project-boundaries'],
  },
  { path: '/project-boundaries' },
  {
    path: '/monitoring-evaluation',
    children: ['/biodiversity'],
  },
  { path: '/biodiversity' },
  ...orgChartRoutes,
  ...accountManagementRoutes,
];

export const PROJECTS = window.appConfig?.projects || {};

export const mapAjaRoutes = {
  [PROJECTS.Kwahu]: kwahuRoutes,
  [PROJECTS.Sidama]: sidamaProjectRoutes,
  [PROJECTS.Kakum]: kakumProjectRoutes,
  [PROJECTS.Bia]: biaRoutes,
};
