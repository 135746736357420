import { Select, SelectProps, Spin } from 'antd';
import { DEBOUNCE_TIME } from 'constants/common';
import { t } from 'helpers/i18n';
import { useOperationRoles } from 'hooks/userManagement';
import debounce from 'lodash/debounce';

const RoleGroupSelect = (props: SelectProps) => {
  const {
    roleGroups,
    loading,
    loadMore,
    onSearch,
    canLoadMore,
    loadingMore,
  } = useOperationRoles();

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      if (canLoadMore) loadMore();
    }
  };

  const search = debounce(onSearch, DEBOUNCE_TIME);

  return (
    <Select
      {...props}
      showSearch
      loading={loading}
      options={roleGroups}
      filterOption={false}
      onSearch={search}
      fieldNames={{ label: 'name', value: 'id' }}
      onPopupScroll={onScroll}
      notFoundContent={loading ? <Spin size="small" /> : t('NoData')}
      dropdownRender={menu => {
        return (
          <>
            <Spin spinning={loading}>{menu}</Spin>
            {loadingMore && (
              <div className="text-center">
                <Spin size="small" spinning={true} />
              </div>
            )}
          </>
        );
      }}
    />
  );
};

export default RoleGroupSelect;
