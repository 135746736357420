import React, { useEffect } from 'react';
import { useFPicFormSubmission } from '../hooks/fpic';
import Filter from './Filter';
import ListContentV2 from './ListContentV2';
import { IFormSubmission } from 'interfaces';
import { useFpicContext } from '../context';
import { useProjectContext } from 'contexts';
import Provider from '../shared/Provider';
import './ListWrap.scss';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

/** Variables * */

/** ------------------------- * */
const ListWrap: React.FC = () => {
  /** Hooks * */
  const { projectId } = useProjectContext();
  const { templates } = useFpicContext();
  const { search } = useLocation();
  const query = queryString.parse(search) as any;

  /** States * */
  const {
    loading,
    defaultFilter,
    pagination,
    filter,
    fpicFormSubmissions,
    updateFilter,
    resetFilter,
    setFpicFormSubmissions,
    loadMoreFPicFormSubmissions,
  } = useFPicFormSubmission(projectId, templates?.[0]?.templateFormId);

  /** Effects * */
  useEffect(() => {
    const formSubmissionId = Number(query.formSubmissionId);
    if (formSubmissionId) {
      updateFilter({
        ...defaultFilter,
        projectId,
        status: undefined,
        formSubmissionId,
      });
    }
  }, [query.formSubmissionId]);

  /** Functions, Events, Actions... * */
  const onLocalUpdate = (item: IFormSubmission) => {
    setFpicFormSubmissions(prevState => {
      return prevState.map(prevItem => {
        if (prevItem.formSubmissionId === item.formSubmissionId) {
          return { ...item };
        }

        return prevItem;
      });
    });
  };

  /** Elements * */
  return (
    <div className={'fpic-list'}>
      <div className={'fpic-list-filter-wrap'}>
        <Filter
          loading={loading}
          filter={filter}
          projectId={projectId}
          defaultFilter={defaultFilter}
          setFilter={updateFilter}
          resetFilter={resetFilter}
        />
      </div>
      <div className={'fpic-list-content-wrap'}>
        <Provider>
          <ListContentV2
            loading={loading}
            filter={filter}
            projectId={projectId}
            total={pagination?.total}
            items={fpicFormSubmissions}
            allowCreateFpic={!!templates?.length}
            onLocalUpdate={onLocalUpdate}
            loadMoreData={loadMoreFPicFormSubmissions}
            updateFilter={updateFilter}
          />
        </Provider>
      </div>
    </div>
  );
};

export default ListWrap;
