/** Third party libs **/
import React, { useContext } from 'react';

/** Local libs **/
import { Button, Card, Col, FormInstance, Row, Skeleton, Space } from 'antd';
import { Waypoint } from 'react-waypoint';
import { t } from 'helpers/i18n';
import { IFarmerProfile, IUserAppointment } from 'interfaces';
import FarmerCard from './FarmerCard';
import { LIST_FARMER_TYPE_CONSTANT } from 'constants/farmer';
import AppointmentCard from './AppointmentCard';
import { PreEnrollmentContext } from '../../Context';
/** Styles **/

/** Interfaces, enum... **/
export interface IFarmerListProps {
  farmers: IFarmerProfile[];
  userAppointments: IUserAppointment[];
  selectedFarmer: IFarmerProfile | undefined;
  selectedAppointment: IUserAppointment | undefined;
  setSelectedFarmer: (farmer: IFarmerProfile | undefined) => void;
  setSelectedAppointment: (appointment: IUserAppointment | undefined) => void;
  loadingFarmer?: boolean;
  loadingUserAppointment?: boolean;
  makeAppointmentForm: FormInstance;
}
/** Variables **/

/** ------------------------- **/
const FarmerList = ({
  farmers,
  userAppointments,
  selectedFarmer,
  selectedAppointment,
  setSelectedFarmer,
  setSelectedAppointment,
  loadingFarmer,
  makeAppointmentForm,
  loadingUserAppointment,
}: IFarmerListProps) => {
  /** States **/

  /** Hooks **/
  const {
    loadMoreFarmer,
    loadMoreUserAppointment,
    listFarmerType,
    keyword,
    participantIds: participant,
    setListFarmerType,
  } = useContext(PreEnrollmentContext);

  /** Variables **/

  /** Effects **/

  /** Functions, Events, Actions... **/
  const onSelect = (item: IFarmerProfile | IUserAppointment) => {
    if (listFarmerType === LIST_FARMER_TYPE_CONSTANT.APPOINTMENT) {
      setSelectedAppointment(item as IUserAppointment);
    } else {
      setSelectedFarmer(item as IFarmerProfile);
      makeAppointmentForm.resetFields();
    }
  };

  /** Elements **/
  return (
    <Card>
      <Row gutter={5}>
        <Col span={12}>
          <Button
            type={'link'}
            onClick={() => {
              setSelectedAppointment(undefined);
              setListFarmerType(LIST_FARMER_TYPE_CONSTANT.NEED_APPOINTMENT);
            }}
            style={{
              color:
                listFarmerType === LIST_FARMER_TYPE_CONSTANT.NEED_APPOINTMENT
                  ? '#00AA5B'
                  : '#333',
              borderBottom:
                listFarmerType === LIST_FARMER_TYPE_CONSTANT.NEED_APPOINTMENT
                  ? '1px solid #00AA5B'
                  : '',
              borderRadius: '0',
            }}
            className="w-100"
          >
            {t('NeedAppointment')}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type={'link'}
            onClick={() => {
              setSelectedFarmer(undefined);
              setListFarmerType(LIST_FARMER_TYPE_CONSTANT.APPOINTMENT);
            }}
            className="w-100"
            style={{
              color:
                listFarmerType === LIST_FARMER_TYPE_CONSTANT.APPOINTMENT
                  ? '#00AA5B'
                  : '#333',
              borderBottom:
                listFarmerType === LIST_FARMER_TYPE_CONSTANT.APPOINTMENT
                  ? '1px solid #00AA5B'
                  : '',
              borderRadius: '0',
            }}
          >
            {t('Appointment')}
          </Button>
        </Col>
      </Row>

      <Card
        style={{
          height: 625,
          overflowY: 'auto',
          borderRadius: '4px',
        }}
        styles={{
          body: {
            position: 'relative',
            height: '100%',
          },
        }}
        className="mt-half"
      >
        <Space direction="vertical" className="w-100">
          {listFarmerType === LIST_FARMER_TYPE_CONSTANT.APPOINTMENT ? (
            <>
              {userAppointments.map(userAppointment => {
                return (
                  <AppointmentCard
                    key={userAppointment.id}
                    item={userAppointment}
                    onClick={() => onSelect(userAppointment)}
                    isSelected={selectedAppointment?.id === userAppointment.id}
                  />
                );
              })}
            </>
          ) : (
            <>
              {farmers.map(farmer => {
                return (
                  <FarmerCard
                    key={farmer.farmerId}
                    item={farmer}
                    onClick={() => onSelect(farmer)}
                    isSelected={selectedFarmer?.farmerId === farmer.farmerId}
                  />
                );
              })}
            </>
          )}

          <Waypoint
            onEnter={() => {
              listFarmerType === LIST_FARMER_TYPE_CONSTANT.APPOINTMENT
                ? loadMoreUserAppointment(keyword, participant)
                : loadMoreFarmer(keyword, participant);
            }}
          />
          {loadingFarmer || loadingUserAppointment ? (
            <Card>
              <Skeleton loading={true} active title paragraph={{ rows: 1 }} />
            </Card>
          ) : null}
        </Space>
      </Card>
    </Card>
  );
};

export default FarmerList;
