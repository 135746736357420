import { Empty } from 'antd';
import {
  DrawingPolygon,
  IMapPolygon,
  IPolygon,
  MapPolygons,
  LayerType,
} from 'components/shared/Map';
import MapContainerWrapper from 'components/shared/Map/MapContainerWrapper';
import { ID_DEFAULT, PROJECT_BOUNDARY_Z_INDEX } from 'constants/common';
import { commonConstants } from 'constants/index';
import { DrawState } from 'constants/map';
import {
  useProjectDetailV2Context,
  useProjectDrawersContext,
} from 'containers/Project/ProjectDetailMapV2/context';
import { useFlagsupContext, useProjectContext } from 'contexts';
import { mapHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { plotHooks, userHooks } from 'hooks';
import { IGetPolygonTekboneParams, IPolygonTekbone } from 'interfaces';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Pane } from 'react-leaflet';
import styled from 'styled-components';
import ProjectClusterLeaflet from '../ProjectModal/Leaflet/ProjectClusterLeaflet';
import ProjectPlots from '../ProjectModal/Leaflet/ProjectPlots';
import AddPolygonDrawer from './AddPolygonDrawer';
import './Map.scss';
import { MapEvents } from './MapEvents';
import { PolygonDetailDrawer, PolygonListDrawer } from './Polygons';
import AddPolygonScreen from 'containers/Project/ProjectDetailMapV2/AddPolygonScreen';
import { PAGING_OFFSET_ALL } from 'constants/pagination';
import isEqual from 'lodash/isEqual';
import Control from 'react-leaflet-custom-control';
import { SearchOutlined } from '@ant-design/icons';
import SearchLocation from './SearchLocation';
import { useSelector } from 'react-redux';
import {
  selectAddSamplingPlotType,
  selectShowingSamplingPlotTypes,
} from 'redux/features/samplingPlot';
import SamplingPlot from './SamplingPlot/SamplingPlot';
import ManualAddSamplingMapEvents from './ManualAddSamplingMapEvents';
import ManualAddSamplingPlot, {
  FinishManualAddSamplingPlot,
} from './SamplingPlot/ManualAddSamplingPlot';

const { useUserPermissions } = userHooks;

const LeafletContainer = styled.div`
  height: calc(100% - 68px);
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  border-radius: 4px;
  .leaflet-container {
    width: 100%;
    height: 100%;
  }
  .leaflet-div-icon {
    background: none;
    border: none;
  }
`;

interface MapProps {
  gettingPolygons: boolean;
  polygons: IPolygonTekbone[];
  maxZIndex: number;
  setParams: React.Dispatch<React.SetStateAction<IGetPolygonTekboneParams>>;
  getPolygons: () => Promise<void>;
  cancelPreviousRequest: () => void;
  showAddPolygonScreen?: boolean;
  params: IGetPolygonTekboneParams;
  showSearchButton?: boolean;
}

const DRAWER_Z_INDEX = {
  POLYGON_DETAIL: 1050,
};

const MAP_Z_INDEX = {
  POLYGON: 201,
};

const { IS_DEMO, IS_WA } = commonConstants;
export const PROJECTS = window.appConfig?.projects || {};

const Map: React.FC<MapProps> = ({
  gettingPolygons,
  polygons,
  maxZIndex,
  params,
  setParams,
  getPolygons,
  cancelPreviousRequest,
  showAddPolygonScreen,
  children,
  showSearchButton,
}) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const { projectId } = useProjectContext();
  const { plots, loading: loadingPlots, getPlots } = plotHooks.usePlots();
  const {
    drawRef,
    drawState,
    setDrawState,
    currentPolygonId,
    setCurrentPolygonId,
    selectedLayerTypes,
    setSelectedLayerTypes,
    statusAfterMapChanges,
    setStatusAfterMapChanges,
    showAddPolygon,
    setShowAddPolygonScreen,
    setTriggered,
    isPlotMode,
    setIsPlotMode,
    viewARREligible,
    setViewARREligible,
  } = useProjectDetailV2Context();
  const { openList, setOpenList } = useProjectDrawersContext();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const { canViewCarbonPotentialReport } = useUserPermissions();
  const { featureFlagsData } = useFlagsupContext();
  const isCAR2619Enabled = featureFlagsData.CAR_2619?.enabled;

  const loading = gettingPolygons || loadingPlots;
  const basemapLayerType = mapHelpers.getBaseMapLayerType(selectedLayerTypes);
  const isBasemap = !!basemapLayerType;
  const showARREligibleSwitch =
    basemapLayerType === 'LandCover' && canViewCarbonPotentialReport;
  const showingSamplingPlotsTypes = useSelector(selectShowingSamplingPlotTypes);
  const addSamplingPlotType = useSelector(selectAddSamplingPlotType);

  const boundPolygons: IMapPolygon[] = useMemo(
    () =>
      polygons.map(p => ({
        id: p.polygonId,
        label: p.polygonName,
        polygonMap: p.polygonMap,
        multiplePolygonMap: p.multiplePolygonMap,
        color: p.hexColorCode,
        activeOptions: {
          fillColor: 'blue',
        },
      })),
    [polygons]
  );

  const onPolygonClick = (polygon: IPolygon) => {
    if (drawState === DrawState.DRAW || drawState === DrawState.EDIT) return;
    setStatusAfterMapChanges({ callAPI: false, isCenter: true });
    if (addSamplingPlotType !== 'MANUAL')
      setCurrentPolygonId(polygon.id || ID_DEFAULT);
  };

  const getAllowLayerTypes: () => LayerType[] = () => {
    const common: LayerType[] = ['Default', 'Satellite'];
    switch (true) {
      case IS_WA:
        return common;
      case IS_DEMO:
        return [...common, 'LandCover', 'CustomClassification'];
      case PROJECTS.SonLa === projectId:
        return [
          ...common,
          'LandCover',
          'IPCC2006LandCover',
          'ARREligible',
          'ForestLoss',
        ];
      default:
        return isCAR2619Enabled
          ? [...common, 'LandCover', 'ARREligible', 'Peatland']
          : [...common, 'LandCover'];
    }
  };

  useEffect(() => {
    if (polygons.length > 0 && !showAddPolygon) setOpenList(true);
  }, [polygons]);

  useEffect(() => {
    const defaultParams = {
      zIndex: PROJECT_BOUNDARY_Z_INDEX,
      ...PAGING_OFFSET_ALL,
      projectId,
    };
    setCurrentPolygonId(ID_DEFAULT);
    if (!isEqual(params, defaultParams)) {
      setParams(defaultParams);
      setStatusAfterMapChanges({ callAPI: false, isCenter: true });
    }
  }, [isBasemap]);

  return projectId ? (
    <LeafletContainer
      ref={mapRef}
      data-testid="project-leaflet-map"
      style={{
        overflow: 'hidden',
        backgroundColor: showAddPolygonScreen ? '#e2ede0' : '',
      }}
      className="project-leaflet-map"
    >
      {showAddPolygonScreen ? (
        <AddPolygonScreen
          setShowAddPolygonScreen={setShowAddPolygonScreen}
          setTriggered={setTriggered}
          loading={loading}
        />
      ) : (
        <>
          <MapContainerWrapper
            selectedLayerTypes={selectedLayerTypes}
            setSelectedLayerTypes={setSelectedLayerTypes}
            boundPolygons={boundPolygons}
            mapRef={mapRef}
            loading={loading}
            allowTypes={getAllowLayerTypes()}
            currentPolygonId={currentPolygonId}
            isCenteredWhenPolygonChanges={statusAfterMapChanges.isCenter}
            viewARREligible={viewARREligible}
            setViewARREligible={setViewARREligible}
            showARREligibleSwitch={showARREligibleSwitch}
          >
            <MapEvents
              isPlotMode={isPlotMode}
              maxZIndex={maxZIndex}
              setParams={setParams}
              params={params}
              getPlots={getPlots}
              cancelPreviousRequestPolygons={cancelPreviousRequest}
            />
            {addSamplingPlotType === 'MANUAL' && (
              <>
                <ManualAddSamplingMapEvents />
                <ManualAddSamplingPlot mapRef={mapRef} />
                <FinishManualAddSamplingPlot />
              </>
            )}
            <Pane name="project-map-v2" style={{ zIndex: MAP_Z_INDEX.POLYGON }}>
              {boundPolygons.length > 0 && (
                <MapPolygons
                  polygons={boundPolygons}
                  activePolygonIds={[currentPolygonId]}
                  onPolygonClick={!isBasemap ? onPolygonClick : undefined}
                />
              )}
            </Pane>
            {!isBasemap && (
              <>
                {isPlotMode && (
                  <ProjectClusterLeaflet>
                    <ProjectPlots plots={plots} />
                  </ProjectClusterLeaflet>
                )}
                <DrawingPolygon
                  drawRef={drawRef}
                  drawState={drawState}
                  setDrawState={setDrawState}
                  limitRender
                />
              </>
            )}
            {showSearchButton && (
              <div>
                <Control position="topright" prepend>
                  <div className="search-control">
                    <SearchOutlined
                      style={{ fontSize: 20 }}
                      onClick={() => setShowSearchBar(prev => !prev)}
                    />
                  </div>
                </Control>
              </div>
            )}
            {showSearchBar && <SearchLocation />}
            {Array.isArray(showingSamplingPlotsTypes) &&
              showingSamplingPlotsTypes.length > 0 && (
                <Pane
                  name="sampling-plot"
                  style={{ zIndex: MAP_Z_INDEX.POLYGON }}
                >
                  <SamplingPlot mapRef={mapRef} />
                </Pane>
              )}
          </MapContainerWrapper>
          {!isBasemap && (
            <>
              {currentPolygonId !== ID_DEFAULT && (
                <PolygonDetailDrawer
                  polygonId={currentPolygonId}
                  getPolygons={getPolygons}
                  setIsPlotMode={setIsPlotMode}
                  isPlotMode={isPlotMode}
                  zIndexDrawer={DRAWER_Z_INDEX.POLYGON_DETAIL}
                  setParams={setParams}
                />
              )}
              <PolygonListDrawer
                polygons={polygons}
                open={openList}
                onClose={() => setOpenList(false)}
              />
              <AddPolygonDrawer />
            </>
          )}
          {children}
        </>
      )}
    </LeafletContainer>
  ) : (
    <Empty
      className="empty-map"
      description={
        <div>
          <div className="mt-base">{t('NoProject')}</div>
          <div className="mt-half">{t('PleaseCreateNewProject')}</div>
        </div>
      }
    />
  );
};

export { Map };
