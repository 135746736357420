import trackerUtils from './trackerUtils';

const { SUBMISSION_MANAGEMENT_DATA, getPayload } = trackerUtils;

const trackProjectChange = (contentName: number, index: number) => {
  window.track('manualTrackInteractionContent', {
    ...SUBMISSION_MANAGEMENT_DATA,
    regionName: 'projectFilter',
    contentName: contentName,
    payload: getPayload('submissionManagement', index),
  });
};

const trackPlotChange = (contentName: number, index: number) => {
  window.track('manualTrackInteractionContent', {
    ...SUBMISSION_MANAGEMENT_DATA,
    regionName: 'plotFilter',
    contentName: contentName,
    payload: getPayload('submissionManagement', index),
  });
};

const trackPolygonChange = (contentName: number, index: number) => {
  window.track('manualTrackInteractionContent', {
    ...SUBMISSION_MANAGEMENT_DATA,
    regionName: 'polygonFilter',
    contentName: contentName,
    payload: getPayload('submissionManagement', index),
  });
};

const trackStatusChange = (contentName: number, index: number) => {
  window.track('manualTrackInteractionContent', {
    ...SUBMISSION_MANAGEMENT_DATA,
    regionName: 'statusFilter',
    contentName: contentName,
    payload: getPayload('submissionManagement', index),
  });
};

const trackDateChange = (contentName: number) => {
  window.track('manualTrackInteractionContent', {
    ...SUBMISSION_MANAGEMENT_DATA,
    regionName: 'submissionTimeFilter',
    contentName: contentName,
    payload: getPayload('submissionManagement', 0),
  });
};

const trackSearch = () => {
  window.track('manualTrackInteractionContent', {
    ...SUBMISSION_MANAGEMENT_DATA,
    regionName: 'filterBar',
    contentName: 'searchSubmission',
    payload: getPayload('submissionManagement', 0),
  });
};

const trackViewTree = (contentName: number, index: number) => {
  window.track('manualTrackInteractionContent', {
    ...SUBMISSION_MANAGEMENT_DATA,
    regionName: 'submissionListResult',
    contentName: contentName,
    payload: getPayload('submissionManagement', index),
  });
};

const trackPageView = (page: number | undefined) => {
  window.track('manualTrackInteractionContent', {
    ...SUBMISSION_MANAGEMENT_DATA,
    regionName: 'bottomBarPageList',
    contentName: page,
    payload: getPayload('submissionManagement', page!),
  });
};

export default {
  trackProjectChange,
  trackPlotChange,
  trackPolygonChange,
  trackStatusChange,
  trackDateChange,
  trackSearch,
  trackPageView,
  trackViewTree,
};
