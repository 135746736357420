// @ts-ignore
import FlagSup from 'flagsup-js';
import { IFlagItem, IFlagsData, IFlagsSDK, IRoute } from 'interfaces';
import { IUser } from 'teko-oauth2';
import { keyBy } from 'lodash';

// Initialization, provide FlagSup SDK with target domain and userId from IAM (if available)
const initFlags = (target: string, userId?: IUser['profile']['sub']) => {
  FlagSup.init({
    target,
    userId,
    useCacheRefresh: false, // optional, whether to use batch to refresh cache. Default = true
  });
};

// Fetch multiple flag values without userId. Response is cached in memory for subsequent calls.
// Refresh every `refreshInterval`
const fetchBatchFlags = async (flagList: string[]): Promise<IFlagsSDK> => {
  const flagsData: IFlagsSDK = await FlagSup.batchGetFlags(flagList);
  return flagsData;
};

// Fetch multiple flag values for the logged in user. Response is cached in memory for subsequent calls
// To reduce network overhead, call this to fetch all flags when your app initialize
// Refresh every `refreshInterval` if useCacheRefresh is enabled
const fetchEvaluateFlags = async (flagList: string[]): Promise<IFlagsSDK> => {
  const flagsData: IFlagsSDK = await FlagSup.batchEvaluateFlags(flagList);
  return flagsData;
};

// Fetch a single flag value. Response is cached in memory for subsequent calls.
// Refresh every `refreshInterval` if useCacheRefresh is enabled
const evaluateFlag = async (flagName: string): Promise<IFlagItem> => {
  const flagData: IFlagItem = await FlagSup.evaluateFlag(flagName);
  return flagData;
};

/**
 * Given the list of feature flag key, determines if a leaf route is accessible.
 * By definition, a leaf route has no child routes.
 * @param route the leaf route
 * @param flagsData the list of feature flag key
 */
const isLeafRouteVisible = (route: IRoute, flagsData: IFlagsData): boolean => {
  const { flagKey } = route;
  if (!flagKey || !flagsData[flagKey]) return true;
  return flagsData[flagKey]!.enabled;
};

/**
 * Given the list of feature flag key, returns which routes are accessible
 * @param items the routes to check
 * @param flagsData the list of feature flag key
 */
const filterFlagsEnabled = (
  items: IRoute[],
  flagsData: IFlagsData
): IRoute[] => {
  const pathMap = keyBy(items, 'path');
  return items.filter(item => {
    const { flagKey, children: childPaths } = item;

    // If the feature flag of this route was disabled => return false
    if (flagKey && flagsData[flagKey] && !flagsData[flagKey]?.enabled) {
      return false;
    }

    // If this route has children => return true if one of its children is visible
    if (childPaths) {
      const childRoutes = childPaths
        .map(path => pathMap[path])
        .filter(route => !!route);
      const visibleChild = childRoutes.find(route =>
        isLeafRouteVisible(route, flagsData)
      );
      return !!visibleChild;
    }

    // If this route doesn't have either children or feature flag => return true
    return true;
  });
};

export default {
  initFlags,
  fetchBatchFlags,
  fetchEvaluateFlags,
  evaluateFlag,
  filterFlagsEnabled,
};
