import { useQuery } from '@tanstack/react-query';
import { commonConstants } from 'constants/index';
import { ID_DEFAULT } from 'constants/common';
import { useProjectContext } from 'contexts';
import { useAbortController } from 'hooks/axios';
import {
  IFormSubmission,
  IFPicForm,
  IFPicFormGetParams,
  IFPicFormSubmissionsGetParams,
  IFpicFormTemplate,
  IPaginationResponse,
} from 'interfaces';
import React, { useEffect, useState } from 'react';
import { fpicServices } from 'services';

const { QUERY_KEYS } = commonConstants;

export const useFPicForm = (params?: IFPicFormGetParams) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fpicForm, setFpicForm] = useState<IFPicForm>();
  const [fpicForms, setFpicForms] = useState<IFPicForm[]>([]);
  const { projectId } = useProjectContext();

  const getFPicForms = async () => {
    try {
      setLoading(true);
      const result = await fpicServices.getFPicForm(params);
      const forms = result?.data?.data?.fpicForms || [];
      const form = forms.find(item => item.projectId === projectId);
      setFpicForm(form);
      setFpicForms(forms);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFPicForms().then();
  }, []);

  return {
    loading,
    fpicForms,
    fpicForm,
    setFpicForms,
  };
};

export const useFPicFormSubmission = (
  projectId: number,
  templateFormId?: number
) => {
  const DEFAULT_FILTER: IFPicFormSubmissionsGetParams = {
    projectId,
    page: 1,
    pageSize: 10,
    status: 'IN_REVIEW',
    stageId: undefined,
    meetingDate: undefined,
    stakeholderIds: [],
    templateFormId,
  };
  const [filter, setFilter] = React.useState<
    IFPicFormSubmissionsGetParams & { loadMore?: boolean }
  >(DEFAULT_FILTER);
  const [loading, setLoading] = useState<boolean>(false);
  const [fpicFormSubmissions, setFpicFormSubmissions] = useState<
    IFormSubmission[]
  >([]);
  const [pagination, setPagination] = useState<
    IPaginationResponse | undefined
  >();
  const { cancelPreviousRequest, newAbortSignal } = useAbortController();

  const getFPicFormSubmissions = async () => {
    try {
      setLoading(true);
      cancelPreviousRequest();
      const loadMore = filter.loadMore;
      const params = {
        ...filter,
        loadMore: undefined,
      };
      if (!loadMore) {
        setPagination({ page: 1, pageSize: 10, total: 0 });
      }
      const result = await fpicServices.getFPicFormSubmissions(params, {
        signal: newAbortSignal(),
      });
      const submissions = result?.data?.data?.formSubmissions || [];
      setFpicFormSubmissions(list =>
        loadMore ? [...list, ...submissions] : [...submissions]
      );

      setPagination(result?.data?.data?.pagination);
    } catch (e) {
      setFpicFormSubmissions([]);
      setPagination(undefined);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreFPicFormSubmissions = async () => {
    if ((pagination?.total || 0) <= fpicFormSubmissions.length) return false;

    setFilter(values => ({ ...values, page: values.page + 1, loadMore: true }));
    return true;
  };

  const reloadFPicFormSubmissions = async () => {
    await getFPicFormSubmissions();
  };

  const updateFilter = (params: Partial<IFPicFormSubmissionsGetParams>) => {
    setFilter(value => {
      return {
        ...value,
        ...params,
        loadMore: false,
      };
    });
  };

  const resetFilter = () => {
    setFilter(DEFAULT_FILTER);
  };

  useEffect(() => {
    resetFilter();
  }, [projectId]);

  useEffect(() => {
    getFPicFormSubmissions().then();
  }, [JSON.stringify(filter)]);

  return {
    defaultFilter: DEFAULT_FILTER,
    loading,
    filter,
    pagination,
    fpicFormSubmissions,
    updateFilter,
    resetFilter,
    setFpicFormSubmissions,
    reloadFPicFormSubmissions,
    loadMoreFPicFormSubmissions,
  };
};

export const useFPicFormTemplate = (
  projectId: number,
  callback?: (template: IFpicFormTemplate[]) => void,
  templateFormType?: number
) => {
  const { data: templates, isLoading: loading, refetch } = useQuery({
    queryKey: [QUERY_KEYS.TEMPLATES, projectId, templateFormType || 0],
    queryFn: async () =>
      projectId !== ID_DEFAULT
        ? await fpicServices
            .getFPicFormTemplatesTekbone({
              projectId,
              page: 1,
              pageSize: 100,
              templateFormType,
            })
            .then(res => {
              const templates = res.data?.data?.templateForms || [];
              callback?.(templates);
              return templates;
            })
        : Promise.resolve([] as IFpicFormTemplate[]),
  });

  useEffect(() => {
    refetch();
  }, [projectId]);

  return {
    loading,
    templates,
  };
};
