import React from 'react';
import { UploadFile } from './interfaces';
import { useGCSUpload } from './hooks';
import { trackFPICUploadFile } from 'helpers/tracker/trackFPIC';

interface GCSUploadThreadProps {
  tracker?: ReturnType<typeof trackFPICUploadFile>;
  children?: React.ReactNode;
  file: UploadFile;
  setFile: (file: UploadFile) => void;
}

const GCSUploadThread: React.FC<GCSUploadThreadProps> = ({
  file,
  tracker,
  setFile,
}) => {
  useGCSUpload(
    file,
    (type, data) => {
      if (data?.error) {
        console.error(data?.error);
        return;
      }

      if (
        data?.file &&
        (data?.file.status !== file.status || data.file?.type !== file.type)
      ) {
        setFile(data.file);
      }
    },
    tracker
  );

  return <></>;
};

export default GCSUploadThread;
