import { Col, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { ID_DEFAULT } from 'constants/common';
import AppContainer from 'containers/AppLayout/AppContainer';
import {
  CommunitiesFilter,
  CommunityOverview,
  ListCommunity,
} from 'components/Communities';
import { communityHooks } from 'hooks';
import './Communities.scss';

const Communities: React.FC = () => {
  /** States **/
  const [keyword, setKeyword] = useState('');
  const [currentCommunity, setCurrentCommunity] = useState<{
    communityId: number;
    communityName: string;
  }>({ communityId: ID_DEFAULT, communityName: '' });

  const {
    communities,
    loading,
    getListCommunities,
  } = communityHooks.useCommunities();

  const filteredCommunities = useMemo(() => {
    if (keyword) {
      return communities.filter(community =>
        community.communityName.toLowerCase().includes(keyword.toLowerCase())
      );
    } else {
      return communities;
    }
  }, [keyword, communities]);

  useEffect(() => {
    getListCommunities();
  }, []);

  return (
    <AppContainer className="d-flex flex-column communities">
      <CommunitiesFilter
        setKeyword={setKeyword}
        setCurrentCommunity={setCurrentCommunity}
      />
      <div className="mt-half h-100">
        <Row gutter={[16, 16]} className="h-100">
          <Col xxl={6} xl={6} xs={12} className="h-100">
            <ListCommunity
              communities={filteredCommunities}
              setCurrentCommunity={setCurrentCommunity}
              loading={loading}
            />
          </Col>
          <Col xxl={18} xl={18} xs={12} className="h-100">
            <CommunityOverview
              communityName={currentCommunity.communityName}
              communityId={currentCommunity.communityId}
              onClose={() =>
                setCurrentCommunity({
                  communityId: ID_DEFAULT,
                  communityName: '',
                })
              }
            />
          </Col>
        </Row>
      </div>
    </AppContainer>
  );
};

export default Communities;
