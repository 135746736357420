import { Button, Col, Form, Radio, Row, Select, Typography } from 'antd';
import { ActivityCategorySelect } from 'components/shared/Select';
import { ID_DEFAULT } from 'constants/common';
import { communityConstants, activityConstants } from 'constants/index';
import { useStoreContext } from 'contexts';
import { filterSelect } from 'helpers/common';
import { t, tSelectPlaceholder } from 'helpers/i18n';
import { communityHooks } from 'hooks';
import {
  ActivityStatus,
  IActivitiesStatus,
  IGetListActivitiesParams,
} from 'interfaces';
import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

interface IFundFilterForm {
  organizationId: number;
  categoryId?: number;
  communeId?: number;
  statuses?: ActivityStatus;
  year?: number;
}

interface IFundFilter {
  onSearch: (values: Partial<IGetListActivitiesParams>) => void;
  activitiesStatus: IActivitiesStatus;
}

const { ACTIVITY_ALL_STATUS } = activityConstants;
const { WORLDBANK_USER_ROLES } = communityConstants;

const {
  FOREST_OWNER,
  COMMUNE_COMMITTEE,
  BOARD_MEMBER,
  PROVINCIAL_FUND,
} = WORLDBANK_USER_ROLES;

const { useCommunes, useCommuneCommunities } = communityHooks;

const FundFilter: React.FC<IFundFilter> = ({ onSearch, activitiesStatus }) => {
  const [form] = Form.useForm<IFundFilterForm>();
  const communeId = Form.useWatch('communeId', form);
  const { communes, loading: communesLoading } = useCommunes();

  const {
    communeCommunities,
    allCommunities,
    loading: communitiesLoading,
  } = useCommuneCommunities(communeId || ID_DEFAULT);
  const [paramsInitialized, setParamsInitialized] = useState(false);
  const [paramsInReviewInitialized, setParamsInReviewInitialized] = useState(
    false
  );
  const [initCommuneFromQuery, setInitCommuneFromQuery] = useState(false);
  const { currentUser } = useStoreContext();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const initReviewStatus = query.get('inReview') === 'true';
  const parentOrganizationId = Number(query.get('parentOrganizationId'));
  const organizationIdQuery = Number(query.get('organizationId'));

  const { roles = [] } = currentUser || {};
  const filteredRoles = roles.filter(role =>
    [BOARD_MEMBER, FOREST_OWNER, PROVINCIAL_FUND, COMMUNE_COMMITTEE].includes(
      role
    )
  );

  useEffect(() => {
    const inReviewCommunities = allCommunities.filter(
      item => item.hasActivityNeedToReview
    );
    if (allCommunities.length > 0) {
      if (
        !initCommuneFromQuery &&
        parentOrganizationId &&
        organizationIdQuery
      ) {
        form.setFieldsValue({
          organizationId: organizationIdQuery,
          communeId: parentOrganizationId,
        });
        setInitCommuneFromQuery(true);
        setParamsInitialized(true);
      }

      if (
        inReviewCommunities.length > 0 &&
        [COMMUNE_COMMITTEE, FOREST_OWNER].includes(filteredRoles[0])
      ) {
        form.setFieldsValue({
          organizationId: inReviewCommunities[0].communityId,
          communeId: inReviewCommunities[0].communeId,
        });
        setParamsInitialized(true);
      }
      setParamsInReviewInitialized(true);
    }
  }, [allCommunities]);

  useEffect(() => {
    if (communes.length > 0) {
      if (paramsInReviewInitialized && !form.getFieldValue('communeId')) {
        form.setFieldValue('communeId', communes[0].communeId);
      }
    }
  }, [communes, paramsInReviewInitialized]);

  useEffect(() => {
    if (communeCommunities.length > 0) {
      if (
        communeCommunities.find(
          item => item.communityId === form.getFieldValue('organizationId')
        )
      ) {
        return;
      }
      form.setFieldValue('organizationId', communeCommunities[0].communityId);
      if (paramsInReviewInitialized && !paramsInitialized) {
        setParamsInitialized(true);
      }
    } else {
      form.resetFields(['organizationId']);
    }
  }, [communeCommunities]);

  useEffect(() => {
    if (paramsInitialized) {
      onSearch(form.getFieldsValue(['organizationId', 'statuses', 'year']));
    }
  }, [paramsInitialized]);

  const yearOptions = useMemo(
    () => [
      {
        label: dayjs().year(),
        value: dayjs().year(),
      },
    ],
    []
  );

  const statusOptions = [
    {
      label: (
        <span>
          {t('ActivityStatus.All')}{' '}
          <Typography.Text type="danger">
            ({activitiesStatus.total})
          </Typography.Text>
        </span>
      ),
      value: ACTIVITY_ALL_STATUS,
    },
    {
      label: (
        <span>
          {t('ActivityStatus.InReview')}{' '}
          <Typography.Text type="danger">
            ({activitiesStatus[ActivityStatus.IN_REVIEW]})
          </Typography.Text>
        </span>
      ),
      value: ActivityStatus.IN_REVIEW,
    },
    {
      label: (
        <span>
          {t('ActivityStatus.NeedToUpdate')}{' '}
          <Typography.Text type="danger">
            ({activitiesStatus[ActivityStatus.NEED_TO_UPDATE]})
          </Typography.Text>
        </span>
      ),
      value: ActivityStatus.NEED_TO_UPDATE,
    },
    {
      label: (
        <span>
          {t('ActivityStatus.Approved')}{' '}
          <Typography.Text type="danger">
            ({activitiesStatus[ActivityStatus.APPROVED]})
          </Typography.Text>
        </span>
      ),
      value: ActivityStatus.APPROVED,
    },
  ];

  const handleSubmit = (values: IFundFilterForm) => {
    const { organizationId, categoryId, statuses, year } = values;
    onSearch({
      organizationId,
      categoryId: categoryId === ID_DEFAULT ? undefined : categoryId,
      statuses,
      year,
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      className="financial-plan-filter"
    >
      <Row align="bottom">
        <Col span={6}>
          <Form.Item label={t('Commune')} name="communeId">
            <Select
              showSearch
              options={communes.map(item => ({
                label: item.communeName,
                value: item.communeId,
              }))}
              placeholder={tSelectPlaceholder('Commune')}
              loading={communesLoading}
              filterOption={filterSelect}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t('Community')} name="organizationId">
            <Select
              showSearch
              options={communeCommunities.map(item => ({
                label: item.communityName,
                value: item.communityId,
              }))}
              placeholder={tSelectPlaceholder('Community')}
              loading={communitiesLoading}
              filterOption={filterSelect}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t('ActivityCategories')} name="categoryId">
            <ActivityCategorySelect />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            label={t('Year')}
            name="year"
            initialValue={dayjs().year()}
          >
            <Select disabled options={yearOptions} />
          </Form.Item>
        </Col>
        <Button htmlType="submit" type="primary">
          {t('Search')}
        </Button>
      </Row>

      <Row>
        <Form.Item
          label={<b>{t('Status')}</b>}
          className="status-filter"
          name="statuses"
          initialValue={
            initReviewStatus ||
            [FOREST_OWNER, COMMUNE_COMMITTEE].includes(filteredRoles[0])
              ? ActivityStatus.IN_REVIEW
              : ACTIVITY_ALL_STATUS
          }
        >
          <Radio.Group
            options={statusOptions}
            className="custom-radio-button"
            optionType="button"
            size="small"
            onChange={() =>
              onSearch(
                form.getFieldsValue([
                  'organizationId',
                  'categoryId',
                  'statuses',
                  'year',
                ])
              )
            }
          />
        </Form.Item>
      </Row>
    </Form>
  );
};

export default FundFilter;
