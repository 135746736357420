import Icon, { TeamOutlined } from '@ant-design/icons';
import { Card, Flex, List, ListProps, Space, Tag, Typography } from 'antd';
import { NOT_AVAILABLE } from 'constants/common';
import { DATE_SLASH_FORMAT } from 'constants/dateTime';
import { cremaConstants } from 'constants/index';
import { dateTimeHelpers } from 'helpers';
import { CremaMeetingRecord } from 'interfaces';
import { Dispatch, SetStateAction } from 'react';
import { ReactComponent as Polygon } from 'assets/svgs/Polygon.svg';
import { useFlagsupContext } from 'contexts';

const { CREMA_MEETING_RECORD_STATUSES } = cremaConstants;

interface MeetingRecordListProps extends ListProps<any> {
  currentMeetingId: number;
  setCurrentMeetingId: Dispatch<SetStateAction<number>>;
}

const MeetingRecordList: React.FC<MeetingRecordListProps> = ({
  currentMeetingId,
  setCurrentMeetingId,
  ...listProps
}) => {
  const { featureFlagsData } = useFlagsupContext();
  const isCARHCM786Enabled = featureFlagsData.CARHCM_786?.enabled;

  return (
    <List
      {...listProps}
      rowKey="submissionId"
      grid={{ column: 1 }}
      renderItem={(meetingRecord: CremaMeetingRecord) => {
        const props = {
          meetingRecord,
          setCurrentMeetingId,
          currentMeetingId,
        };
        if (isCARHCM786Enabled) {
          return <MeetingRecordItemV2 {...props} />;
        } else {
          return <MeetingRecordItem {...props} />;
        }
      }}
    />
  );
};

interface MeetingRecordItemProps {
  meetingRecord: CremaMeetingRecord;
  currentMeetingId: number;
  setCurrentMeetingId: Dispatch<SetStateAction<number>>;
}

const MeetingRecordItem = (props: MeetingRecordItemProps) => {
  const { meetingRecord, currentMeetingId, setCurrentMeetingId } = props;
  const status = CREMA_MEETING_RECORD_STATUSES.find(
    status => status.value === meetingRecord.status
  );

  return (
    <List.Item
      onClick={() => setCurrentMeetingId(meetingRecord.submissionId)}
      style={{ marginBottom: 8 }}
    >
      <Card
        hoverable
        style={{
          background:
            currentMeetingId === meetingRecord.submissionId
              ? '#E6F4FF'
              : '#F3F3F7',
        }}
        styles={{ body: { padding: 8 } }}
      >
        <Space className="mb-half">
          <Typography.Text type="secondary">
            {dateTimeHelpers.formatUnixTimestamp(
              meetingRecord.meetingTime,
              DATE_SLASH_FORMAT
            )}
          </Typography.Text>
          <Tag color={status?.color} className="mr-0">
            {status?.label || meetingRecord.status}
          </Tag>
        </Space>
        <div>
          <Typography.Text strong>
            {meetingRecord.templateFormTitle}
          </Typography.Text>
          <br />
          <Typography.Text>{meetingRecord.meetingName}</Typography.Text>
          <br />
          <div className="mt-half">
            {meetingRecord.communities.map(community => (
              <Tag key={community.communityId}>{community.communityName}</Tag>
            ))}
          </div>
        </div>
      </Card>
    </List.Item>
  );
};

const MeetingRecordItemV2 = (props: MeetingRecordItemProps) => {
  const { featureFlagsData } = useFlagsupContext();
  const isCARHCM856Enabled = featureFlagsData.CARHCM_856?.enabled;

  const { meetingRecord, currentMeetingId, setCurrentMeetingId } = props;
  const status = CREMA_MEETING_RECORD_STATUSES.find(
    status => status.value === meetingRecord.status
  );
  const communityNames = meetingRecord.communities.map(
    community => community.communityName
  );
  return (
    <List.Item
      onClick={() => setCurrentMeetingId(meetingRecord.submissionId)}
      style={{ marginBottom: 8 }}
    >
      <Card
        hoverable
        style={{
          background:
            currentMeetingId === meetingRecord.submissionId
              ? '#E6F4FF'
              : '#F3F3F7',
        }}
        styles={{ body: { padding: 8 } }}
      >
        <Flex className="mb-half w-100" justify="space-between">
          <Tag color="processing">
            {dateTimeHelpers.formatUnixTimestamp(
              meetingRecord.meetingTime,
              DATE_SLASH_FORMAT
            )}
          </Tag>
          <Tag color={status?.color} className="mr-0">
            {status?.label || meetingRecord.status}
          </Tag>
        </Flex>
        <div>
          {isCARHCM856Enabled && (
            <Typography.Text className="d-block mb-half">
              {meetingRecord.templateFormTitle}
            </Typography.Text>
          )}
          <Typography.Text strong className="d-block mb-half text-size-medium">
            {meetingRecord.meetingName}
          </Typography.Text>
          <Typography.Text className="d-block">
            <Icon component={Polygon} className="text-16" />{' '}
            {meetingRecord.cremaName || NOT_AVAILABLE}
          </Typography.Text>
          <Typography.Text
            className="d-block"
            ellipsis={{ tooltip: communityNames.join(' | ') }}
          >
            <TeamOutlined />{' '}
            {!!meetingRecord.communities.length
              ? communityNames.join(' | ')
              : NOT_AVAILABLE}
          </Typography.Text>
        </div>
      </Card>
    </List.Item>
  );
};
export default MeetingRecordList;
