import { requestServices } from 'services';
import { ISelectionParams, ISelectionResponse } from 'interfaces';

const { projectManagerClient } = requestServices;

const selectSingleTree = (
  params: ISelectionParams
): Promise<ISelectionResponse> => {
  return projectManagerClient.post('/api/v1/submissions/select', params);
};

export default {
  selectSingleTree,
};
