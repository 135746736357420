import React from 'react';
import { Col, Flex, Popover, PopoverProps, Row } from 'antd';
import Control from 'react-leaflet-custom-control';
import layerIcon from './images/layers.png';
import { ILayer, LayerType } from '../interfaces';
import './MapLayerSwitcher.scss';
import classnames from 'classnames';

interface MapLayerSwitcherProps {
  children?: React.ReactNode;
  allowLayers?: ILayer[];
  layersOpened?: LayerType[];
  setLayersOpened?: (layers: LayerType[]) => void;
}

const WIDTH_ITEM = 80;
const ANTD_COLS = 24;
const COLS = 3;

const MapLayerSwitcher: React.FC<MapLayerSwitcherProps> = ({
  layersOpened,
  allowLayers = [],
  setLayersOpened,
}) => {
  const onClick = (type?: LayerType) => {
    if (!type) return;
    setLayersOpened?.([type]);
  };

  const minWidth =
    allowLayers.length >= COLS
      ? WIDTH_ITEM * COLS
      : allowLayers.length * WIDTH_ITEM;

  const spanCol =
    allowLayers.length >= COLS
      ? ANTD_COLS / COLS
      : ANTD_COLS / allowLayers.length;

  const content: PopoverProps['content'] = (
    <Flex>
      <Row gutter={[8, 8]} align="top" style={{ minWidth, maxWidth: 320 }}>
        {allowLayers.map(layer => {
          return (
            <Col span={spanCol} key={layer.type}>
              <div
                className={classnames({
                  'layer-switcher-layer': true,
                  'layer-switcher-layer-selected':
                    layer.type && layersOpened?.includes(layer.type),
                })}
                onClick={() => onClick(layer.type)}
                key={layer.type}
              >
                {layer.icon && (
                  <img
                    src={layer.icon}
                    alt={layer.name}
                    className={'layer-switcher-layer-icon'}
                  />
                )}
                <span>{layer.name}</span>
              </div>
            </Col>
          );
        })}
      </Row>
    </Flex>
  );

  return (
    <Control prepend={true} position={'topright'}>
      <Popover content={content} placement={'bottomRight'} trigger={['click']}>
        <div className={'layer-switcher'}>
          <img src={layerIcon} className={'layer-switcher-icon'} alt={''} />
        </div>
      </Popover>
    </Control>
  );
};

export default MapLayerSwitcher;
export { MapLayerSwitcher };
