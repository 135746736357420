import React from 'react';
import AppContainer from 'containers/AppLayout/AppContainer';
import CarbonPotentialReportPage from 'components/FeasibilityStudy/CarbonPotentialReport';

const CarbonPotentialReport: React.FC = () => {
  return (
    <AppContainer>
      <CarbonPotentialReportPage />
    </AppContainer>
  );
};

export default CarbonPotentialReport;
