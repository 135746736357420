/** Third party libs * */
import React from 'react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import {
  Divider,
  List,
  Tag,
  Skeleton,
  Space,
  Typography,
  Row,
  Tooltip,
  Button,
} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

/** Local libs * */
import { t } from 'helpers/i18n';
import {
  FormSubmissionAnswerType,
  FormSubmissionStatus,
  IFormAnswer,
  IFormSubmission,
  IFPicFormSubmissionsGetParams,
} from 'interfaces';
import { userHooks } from 'hooks';
import { colorConstants, commonConstants } from 'constants/index';

/** Components * */
import { CardItem } from '../shared/CardItem';
import queryString from 'query-string';
import { DATE_SLASH_FORMAT } from 'constants/dateTime';

/** Styles * */

/** ------------------------- * */
/** Interfaces, enum... * */
interface ListContentProps {
  loading?: boolean;
  total?: number;
  projectId: number;
  allowCreateFpic?: boolean;
  filter?: IFPicFormSubmissionsGetParams;
  items: IFormSubmission[];
  loadMoreData?: () => Promise<boolean>;
  onLocalUpdate?: (item: IFormSubmission) => void;
  currentSubmissionId?: number;
  setCurrentSubmissionId?: React.Dispatch<React.SetStateAction<number>>;
}
interface IDisplayAnswer {
  type: FormSubmissionAnswerType;
  label?: string;
  value?: string;
}

/** Variables * */
const { COLOR } = colorConstants;
const { Text } = Typography;
const statusColorMap: {
  [key in FormSubmissionStatus]: {
    tag: string;
    background: string;
  };
} = {
  DRAFT: {
    tag: 'default',
    background: 'rgba(0,0,0,.06)',
  },
  REJECTED: {
    tag: 'error',
    background: '#fff2f0',
  },
  NEED_TO_UPDATE: {
    tag: 'warning',
    background: '#fffbe6',
  },
  IN_REVIEW: {
    tag: 'processing',
    background: '#e6f4ff',
  },
  APPROVED: {
    tag: 'success',
    background: '#f6ffed',
  },
};
const { useUserPermissions } = userHooks;
const { IS_AJA } = commonConstants;

/** ------------------------- * */
const ListContent: React.FC<ListContentProps> = ({
  loading,
  items = [],
  projectId,
  total = 0,
  allowCreateFpic,
  filter,
  loadMoreData,
  currentSubmissionId,
  setCurrentSubmissionId,
}) => {
  /** States * */

  /** Hooks * */
  const history = useHistory();
  const { canUpdateMeetingRecords } = useUserPermissions();

  /** Variables * */

  /** Effects * */

  /** Functions, Events, Actions... * */
  const onCreate = () => {
    const query: any = queryString.parse(history.location.search);
    query.project = projectId;

    history.push({
      pathname: `${history.location.pathname}/create`,
      search: queryString.stringify(query),
    });
  };

  const getFpic = () => {
    if (loading) return;
    loadMoreData?.();
  };

  const getDisplayAnswer = (answers: IFormAnswer[]) => {
    return answers
      .sort((a, b) => a.questionId - b.questionId)
      .reduce((values, value) => {
        if (value.isTitleShowed && value.value) {
          let val: string | undefined = value.value;
          if (['DatePicker', 'DATE_PICKER'].includes(value.fieldType)) {
            val = dayjs
              .unix(Number(value.value))
              .format('DD/MM/YYYY')
              .toString();
          } else if (['TimePicker', 'TIME_PICKER'].includes(value.fieldType)) {
            val = dayjs
              .unix(Number(value.value))
              .format('HH:mm:ss')
              .toString();
          } else if (['Dropdown', 'DROPDOWN'].includes(value.fieldType)) {
            const findValue = value.suggestionValues?.find(
              v => v.value === value.value
            );

            val = findValue?.label;
          } else if (value.fieldType === 'CustomizableDropdown') {
            const { suggestionValues } = value;
            if (Array.isArray(suggestionValues)) {
              val = suggestionValues.find(
                suggestValue => suggestValue.value === value.value
              )?.label;
            }
          }

          if (val)
            values.push({
              value: val,
              label: value.fieldLabel,
              type: value.fieldType,
            });
        }

        return values;
      }, [] as IDisplayAnswer[]);
  };

  /** Elements * */
  return (
    <CardItem
      className={'fpic-list-content'}
      bodyStyle={{
        position: 'relative',
        height: '100%',
      }}
    >
      <Row justify={'space-between'}>
        {total > 0 ? (
          <Text style={{ fontWeight: 600 }}>
            {t('XofYItems', { x: items.length, y: total })}
          </Text>
        ) : (
          <div />
        )}
        <div />
        <Tooltip
          title={
            !canUpdateMeetingRecords
              ? t('YouDontHavePermission')
              : allowCreateFpic
              ? undefined
              : t('TheProjectYouAreSelectingDoesNotHaveAnyFormTemplates')
          }
        >
          <Button
            type={'primary'}
            disabled={!canUpdateMeetingRecords || !allowCreateFpic}
            onClick={onCreate}
          >
            {t('CreateForm')}
          </Button>
        </Tooltip>
      </Row>
      {loading && total === 0 ? (
        <Skeleton active={true} />
      ) : (
        <div
          id="scrollableDiv"
          style={{
            position: 'absolute',
            top: '60px',
            bottom: '16px',
            left: 0,
            right: 0,
            overflow: 'auto',
            padding: '8px 16px',
          }}
        >
          <InfiniteScroll
            dataLength={items.length}
            next={getFpic}
            hasMore={items.length < total}
            loader={
              <Space
                direction={'vertical'}
                style={{
                  width: '100%',
                  marginTop: 16,
                }}
              >
                <Skeleton.Button active={true} size={'small'} block={true} />
                <Skeleton.Button active={true} size={'small'} block={true} />
                <Skeleton.Button active={true} size={'small'} block={true} />
              </Space>
            }
            endMessage={
              items.length ? (
                <Divider plain>{t('ItIsAllNothingMore')}</Divider>
              ) : (
                <></>
              )
            }
            scrollableTarget="scrollableDiv"
          >
            <List
              dataSource={items}
              bordered={false}
              renderItem={item => {
                const color = item.status
                  ? statusColorMap[item.status]
                  : undefined;

                return !filter?.status || filter.status === item.status ? (
                  <List.Item
                    key={item.formSubmissionId}
                    className={'fpic-item'}
                  >
                    <div
                      className={'fpic-item-content'}
                      style={{
                        backgroundColor: IS_AJA
                          ? currentSubmissionId === item.formSubmissionId
                            ? COLOR.BG_ACTIVE
                            : COLOR.BG_NEUTRAL
                          : color?.background,
                      }}
                      onClick={() =>
                        setCurrentSubmissionId?.(item.formSubmissionId)
                      }
                    >
                      <div className={'fpic-item-header'}>
                        <Tag
                          color="processing"
                          className={'fpic-item-meeting-date'}
                        >
                          {!!item.meetingDate &&
                            dayjs
                              .unix(item.meetingDate)
                              .format(DATE_SLASH_FORMAT)}
                        </Tag>
                        {!!item.status && (
                          <Tag color={color?.tag}>{t(item.status)}</Tag>
                        )}
                      </div>
                      {getDisplayAnswer(item.answers || []).map(
                        (displayAnswer, index) => (
                          <p
                            key={index}
                            style={{ marginBottom: 6, padding: '0px 6px' }}
                          >
                            <span
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {displayAnswer.label}:{' '}
                            </span>
                            {displayAnswer.value}
                            {/*{displayAnswer.type === 'Dropdown' ? (*/}
                            {/*  <Tag*/}
                            {/*    style={{*/}
                            {/*      fontWeight: 400,*/}
                            {/*      fontSize: 14,*/}
                            {/*    }}*/}
                            {/*  >*/}
                            {/*    {displayAnswer.value}*/}
                            {/*  </Tag>*/}
                            {/*) : (*/}
                            {/*  displayAnswer.value*/}
                            {/*)}*/}
                          </p>
                        )
                      )}
                    </div>
                  </List.Item>
                ) : (
                  <></>
                );
              }}
            />
          </InfiniteScroll>
        </div>
      )}
    </CardItem>
  );
};

export default ListContent;
