import { UploadFile as UploadFileAntd } from 'antd';
import axios from 'axios';
import { UploadFile } from 'components/shared/Upload';
import {
  IMAGE_FILE_MIME_TYPE,
  IMAGE_FILE_TYPE,
  MICROSOFT_ONLINE_VIEWER,
  MIME_FILE_TYPES,
} from 'constants/file';
import heic2any from 'heic2any';
import { t } from 'helpers/i18n';
import { IGoogleImageSizingOptions } from 'interfaces';
import { LatLng } from 'leaflet';
import { userServices } from 'services';

const createFileChunks = (file: File, fromSize: number, endSize: number) => {
  const blob = file.slice(fromSize, endSize);
  return new File([blob], file.name, { type: file.type });
};

/**
 * Get polygons from KML file, ignore others information in the file
 * @param file KML file
 * @returns error (if has) and array of polygons. The array will empty if error(s) occurs
 */
const getPolygonFromKmlFile = async (
  file: File
): Promise<{ error: string; polygons: LatLng[][] }> => {
  const result = {
    error: '',
    polygons: [] as LatLng[][],
  };
  const content: string = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = event => {
      resolve(event.target?.result as string);
    };
    reader.onerror = error => reject(error);
    reader.readAsText(file);
  });

  try {
    const domParser = new DOMParser();
    const dom = domParser.parseFromString(content, 'text/xml');
    const isError = dom.documentElement.innerHTML.includes('parsererror');

    if (isError) {
      result.error = t('TheFileContentIsIncorrect');
      return result;
    }

    // css selector: find all <coordinates /> element inside <Polygon />
    const coordinates = dom.querySelectorAll('Polygon coordinates');
    if (coordinates?.length) {
      const list = Array.from(coordinates);
      list.forEach(item => {
        const coordinate = (item.innerHTML || '')
          .split(' ')
          .reduce((items, item) => {
            if (!!item) {
              const value = item
                .replaceAll('/n', '')
                .replaceAll('/t', '')
                .trim();
              if (value) {
                items.push(value);
              }
            }
            return items;
          }, [] as string[]);
        const formattedCoors = coordinate.map(c => {
          const nums = c.split(',').map(Number);
          if (nums.length < 2 || nums.some(isNaN)) throw Error;
          return new LatLng(nums[1], nums[0]); // KML uses 3D geographic coordinates: longitude, latitude, and altitude
        });
        if (formattedCoors.length < 2) throw Error;
        result.polygons.push(formattedCoors);
      });
    } else {
      result.error = t('FileHasNoPolygon');
      return result;
    }
  } catch (e) {
    result.error = t('TheFileContentIsIncorrect');
    result.polygons = [];
    return result;
  }

  return result;
};

export const getFileExtension = (fileName: string) => {
  return fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);
};

const previewExcelFile = (fileUrl: string) => {
  window.open(
    MICROSOFT_ONLINE_VIEWER + fileUrl,
    '_blank',
    'popup=yes,width=1000,height=500,top=200,left=200'
  );
};

const getFileNameFromStorageURL = (url: string) => {
  return decodeURI(url.split('/').pop() || '');
};

const convertImageFile = async (file: File): Promise<File> => {
  const ext = getFileExtension(file.name).toLowerCase();

  if (ext === IMAGE_FILE_TYPE.HEIC) {
    const temp_blob = await heic2any({ blob: file });
    return new File([temp_blob] as BlobPart[], file.name, {
      type: IMAGE_FILE_MIME_TYPE.PNG,
    });
  }
  return file;
};

const validateFileTypeAndFileSize = (
  file: File,
  types: string[],
  size?: number
) => {
  const ext = getFileExtension(file.name).toLowerCase();
  const fileType =
    ext === IMAGE_FILE_TYPE.HEIC ? IMAGE_FILE_MIME_TYPE.HEIC : file.type;

  if (!types.includes(MIME_FILE_TYPES[fileType])) {
    return {
      status: false,
      message: t('FileIsIncorrectType', {
        types: types.map(t => `".${t}"`).join(', '),
      }),
    };
  }
  if (size && file.size > size * 1024 * 1024) {
    return {
      status: false,
      message: t('FileIsIncorrectSize', { size: size }),
    };
  }
  return {
    status: true,
    message: '',
  };
};

const downloadFile = (file: Blob, fileName: string) => {
  try {
    // Create object URL for the downloaded file
    const url = window.URL.createObjectURL(new Blob([file]));
    // Create a temporary link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);

    // Stimulate click to initiate download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch {}
};

const convertAntdFileToUploadFile = (
  file: UploadFileAntd,
  originFile: File
): UploadFile => {
  return {
    uid: file.uid,
    percent: file.percent,
    originFile,
  };
};

// document: https://developers.google.com/people/image-sizing
/**
 *
 * @param fsImageUrl original url of the image, must be File Service url
 * @param options config to apply image sizing. size, width, height must be integer and > 0
 * @returns the url that applied image sizing configs, or the fsImageUrl if not match requirement
 */
const applyGoogleImageSizing = (
  fsImageUrl: string,
  options?: IGoogleImageSizingOptions
) => {
  if (
    !fsImageUrl.match(
      /^https:\/\/files\.(((dev|stage)\.tekoapis\.net)|(tekoapis\.com)).+/gi
    ) ||
    !options
  )
    return fsImageUrl;
  const suffix = Object.entries(options)
    .map(([key, value]) => {
      switch (key) {
        case 'size':
          return Number.isInteger(value) && value > 0 ? `s${value}` : undefined;
        case 'width':
          return Number.isInteger(value) && value > 0 ? `w${value}` : undefined;
        case 'height':
          return Number.isInteger(value) && value > 0 ? `h${value}` : undefined;
        case 'squareCrop':
          return value ? 'c' : undefined;
        case 'smartCrop':
          return value ? 'p' : undefined;
        default:
          return undefined;
      }
    })
    .filter(v => !!v)
    .join('-');
  return `${fsImageUrl}=${suffix}`;
};

/**
 *
 * @param url file service url
 * @returns image url in base64 format
 */
const getImgBase64FromFsUrl = async (url: string): Promise<string> => {
  if (
    !url.match(
      /^https:\/\/files\.(((dev|stage)\.tekoapis\.net)|(tekoapis\.com)).+/gi
    )
  )
    return url;
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${userServices.getAccessToken()}`,
      'Content-Type': 'multipart/form-data',
    },
    responseType: 'arraybuffer',
  });
  const base64 = btoa(
    new Uint8Array(res.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ''
    )
  );
  const contentType = res.headers['content-type'];

  return `data:${contentType || ''};base64,${base64}`;
};

export default {
  createFileChunks,
  getPolygonFromKmlFile,
  getFileExtension,
  previewExcelFile,
  getFileNameFromStorageURL,
  convertImageFile,
  validateFileTypeAndFileSize,
  downloadFile,
  convertAntdFileToUploadFile,
  applyGoogleImageSizing,
  getImgBase64FromFsUrl,
};
