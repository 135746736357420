import { ROUTES } from 'constants/routes';
import { getData } from 'helpers/request';
import {
  IAssignStaffsPayload,
  ICommunityStaff,
  IGetStaffParams,
  IGetStaffResponse,
} from 'interfaces/staff';
import { requestServices } from 'services';

const { projectManagerClient } = requestServices;

const getStaff = async (
  params: IGetStaffParams
): Promise<IGetStaffResponse> => {
  return await projectManagerClient
    .get('/api/v1/list-staff-profiles', {
      params,
    })
    .then(getData);
};

const getCommunityStaffs = async (
  communityId: number,
  params: { projectId: number }
): Promise<{ staffs: ICommunityStaff[] }> => {
  return await projectManagerClient
    .get(ROUTES.COMMUNITY.GET_STAFFS(communityId), {
      params,
    })
    .then(getData);
};

const assignStaffs = async (payload: IAssignStaffsPayload) => {
  return await projectManagerClient.post(
    ROUTES.COMMUNITY.ASSIGN_STAFFS,
    payload
  );
};

export default {
  getStaff,
  getCommunityStaffs,
  assignStaffs,
};
