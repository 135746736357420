import MeetingRecord from 'components/CREMA/MeetingRecord/MeetingRecord';
import AppContainer from 'containers/AppLayout/AppContainer';
import React from 'react';

const CremaFormation: React.FC = () => {
  return (
    <AppContainer>
      <MeetingRecord />
    </AppContainer>
  );
};

export default CremaFormation;
