import { t } from 'helpers/i18n';
import { CremaDrawer, CremaMeetingRecordStatus } from 'interfaces';

const CREMA_MEETING_RECORD_STATUSES = [
  {
    label: t('Draft'),
    value: CremaMeetingRecordStatus.Draft,
  },
  {
    label: t('InReview'),
    value: CremaMeetingRecordStatus.InReview,
    color: 'error',
  },
  {
    label: t('Approved'),
    value: CremaMeetingRecordStatus.Approved,
    color: 'success',
  },
  {
    label: t('NeedToUpdate'),
    value: CremaMeetingRecordStatus.NeedUpdate,
    color: 'warning',
  },
  {
    label: t('Rejected'),
    value: CremaMeetingRecordStatus.Rejected,
    color: 'error',
  },
];

const ALLOWED_TO_EDIT_STATUSES = [
  CremaMeetingRecordStatus.Draft,
  CremaMeetingRecordStatus.InReview,
  CremaMeetingRecordStatus.NeedUpdate,
];

const ALLOWED_TO_APPROVE_REJECT_STATUSES = [CremaMeetingRecordStatus.InReview];

const MAX_LENGTH_CREMA_COMMUNITY_NAME = 255;
const REGEX_PATTERN_CREMA_COMMUNITY_NAME = /^[a-zA-Z0-9_.-\s]*$/;

const CREMA_DRAWERS: {
  [key: string]: {
    align: 'left' | 'right';
    dependencies?: CremaDrawer[];
  };
} = {
  [CremaDrawer.CommunityDetail]: {
    align: 'left',
    dependencies: [CremaDrawer.UpdateCommunity, CremaDrawer.CRMCMember],
  },
  [CremaDrawer.UpdateCrema]: {
    align: 'right',
  },
  [CremaDrawer.CreateCrema]: {
    align: 'right',
  },
  [CremaDrawer.CreateCommunity]: {
    align: 'right',
  },
  [CremaDrawer.UpdateCommunity]: {
    align: 'right',
  },
  [CremaDrawer.AddDocumentation]: {
    align: 'right',
  },
  CECMemberDrawer: {
    align: 'right',
  },
  [CremaDrawer.CRMCMember]: {
    align: 'right',
  },
  [CremaDrawer.CremaDetail]: {
    align: 'left',
    dependencies: [
      CremaDrawer.UpdateCrema,
      CremaDrawer.AddCommunity,
      CremaDrawer.AddDocumentation,
      CremaDrawer.Assigment,
      CremaDrawer.CommunityDetail,
      CremaDrawer.CECMember,
    ],
  },
  [CremaDrawer.Assigment]: {
    align: 'right',
  },
  [CremaDrawer.AddCommunity]: {
    align: 'right',
  },
};

export default {
  CREMA_MEETING_RECORD_STATUSES,
  ALLOWED_TO_EDIT_STATUSES,
  ALLOWED_TO_APPROVE_REJECT_STATUSES,
  MAX_LENGTH_CREMA_COMMUNITY_NAME,
  REGEX_PATTERN_CREMA_COMMUNITY_NAME,
  CREMA_DRAWERS,
};
