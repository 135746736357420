import TekoID from 'teko-oauth2';
import { browserHistory } from 'helpers';
import localization from 'helpers/localization';

const isLoggedIn = () => {
  return TekoID.user?.isLoggedIn();
};

const login = () => {
  const { getCurrentLanguage } = localization;
  let currentLanguage = getCurrentLanguage();
  TekoID.user?.login(undefined, {
    locale: currentLanguage ? currentLanguage : 'en',
  });
};

const logout = () => {
  // Tracking - Reset userId when logged out
  track('resetUserId');

  TekoID.user?.logout(window.location.origin);
};

const getAccessToken = () => {
  return TekoID.user?.getAccessToken();
};

const getUserInfo = () => {
  return TekoID.user?.getUserInfo();
};

const getFullUserInfo = async () => {
  return TekoID.user?.getFullUserInfo();
};

const denyAccess = () => {
  browserHistory.push('/403');
};

export default {
  isLoggedIn,
  login,
  logout,
  getAccessToken,
  getUserInfo,
  getFullUserInfo,
  denyAccess,
};
