import { PrinterOutlined } from '@ant-design/icons';
import { Button, List, Space, Typography, Modal } from 'antd';
import { useProjectContext } from 'contexts';
import { renderAsync } from 'docx-preview/dist/docx-preview';
import { dateTimeHelpers, fileHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { IMeetingMinutes } from 'interfaces';
import React, { useState, useEffect, useRef } from 'react';
import { meetingRecordsServices } from 'services';
import { MODAL_WIDTH } from 'constants/common';
import './MeetingRecordsList.scss';
import { useCurrentMeetingRecord } from '../context';
import { DATE_WITHOUT_SLASH_FORMAT } from 'constants/dateTime';

interface IMeetingRecordsItemProps {
  meetingMinute: IMeetingMinutes;
}

const { formatUnixTimestamp } = dateTimeHelpers;

const MeetingRecordsItem: React.FC<IMeetingRecordsItemProps> = ({
  meetingMinute,
}) => {
  const [loadingDocx, setLoadingDocx] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [docxBlob, setDocxBlob] = useState<Blob | null>(null);
  const { projectId } = useProjectContext();
  const docxContainerRef = useRef<HTMLDivElement | null>(null);
  const { currentCommunityName } = useCurrentMeetingRecord();

  const fetchDocxBlob = async (meetingId: number) => {
    try {
      setLoadingDocx(true);
      const response = await meetingRecordsServices.downloadCommunityMeetingMinute(
        {
          meetingId,
          projectId,
        }
      );

      const blob = new Blob([response.data], {
        type:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      setDocxBlob(blob);
      setIsModalVisible(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDocx(false);
    }
  };

  const handlePrint = () => {
    if (docxBlob) {
      const previewWindow = window.open('', '_blank');
      if (previewWindow) {
        previewWindow.document.write("<div id='docx-container'></div>");
        previewWindow.document.write(`
          <style>
            @page {
              size: A4;
              margin: 20mm;
            }
            body {
              margin: 0;
              padding: 20px;
            }
            #docx-container {
              width: 100%;
              height: 100%;
              box-sizing: border-box;
              page-break-after: always;
            }
            .docx-wrapper>section.docx {
              box-shadow: none;
            } 
          </style>
        `);
        previewWindow.document.close();

        const container = previewWindow.document.getElementById(
          'docx-container'
        ) as HTMLElement;
        if (container) {
          renderAsync(docxBlob, container)
            .then(() => {
              previewWindow.focus();
              previewWindow.print();
            })
            .catch(() => {
              previewWindow.close();
            });
        }
      }
    }
  };

  const downloadDocx = () => {
    if (docxBlob) {
      fileHelpers.downloadFile(
        docxBlob,
        `CommunityMeetingMinute-${formatUnixTimestamp(
          meetingMinute.updatedAt,
          DATE_WITHOUT_SLASH_FORMAT
        )}.docx`
      );
    }
  };

  useEffect(() => {
    if (isModalVisible && docxBlob && docxContainerRef.current) {
      docxContainerRef.current.innerHTML = '';
      renderAsync(docxBlob, docxContainerRef.current).catch(console.error);
    }
  }, [isModalVisible, docxBlob]);

  return (
    <>
      <List.Item key={meetingMinute.meetingId}>
        <Space direction="vertical" className="w-100">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography.Text className="d-block text-link">
              {formatUnixTimestamp(meetingMinute.updatedAt)}
            </Typography.Text>
            <Button
              type="link"
              icon={<PrinterOutlined style={{ fontSize: 20 }} />}
              onClick={() => fetchDocxBlob(meetingMinute.meetingId)}
              loading={loadingDocx}
            />
          </div>
          <Typography.Text className="d-block" style={{ width: '80%' }}>
            {currentCommunityName}
          </Typography.Text>
          <Typography.Text className="d-block" style={{ width: '80%' }}>
            {meetingMinute.meetingName}
          </Typography.Text>
        </Space>
      </List.Item>

      <Modal
        title={t('Document Preview')}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        width={MODAL_WIDTH.LARGE}
        className="preview-modal"
        closeIcon={null}
        footer={[
          <Button key="download" type="primary" onClick={downloadDocx}>
            {t('Download')}
          </Button>,
          <Button key="print" type="primary" onClick={handlePrint}>
            {t('Print')}
          </Button>,
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
            {t('Cancel')}
          </Button>,
        ]}
      >
        <div id="docx-container" ref={docxContainerRef} />
      </Modal>
    </>
  );
};

export { MeetingRecordsItem };
