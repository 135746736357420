import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useFlagsupContext } from 'contexts';
import { Button, Form, Input, Space, notification } from 'antd';
import { COLOR } from 'constants/styles/color';
import { t } from 'helpers/i18n';
import React, { useEffect, useState } from 'react';
import { projectServices } from 'services';

const FORM_FIELD = {
  SAMPLING_PLOTS_CNT: 'samplingPlotsCount',
};

interface ProjectPlotMeasureFormProps {
  isEditing: boolean;
  polygonId: number;
  onClose: any;
  samplingPlots?: number;
  suggestGeneratePlots: number;
  plotsWithData: number;
  mutatePolygon: Function;
}

const ProjectPlotMeasureForm: React.FC<ProjectPlotMeasureFormProps> = ({
  isEditing,
  polygonId,
  onClose,
  samplingPlots,
  suggestGeneratePlots,
  plotsWithData,
  mutatePolygon,
}) => {
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [isInvalid, setIsInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const { featureFlagsData } = useFlagsupContext();
  const isCAR418Enabled = featureFlagsData.CAR_418?.enabled;

  useEffect(() => {
    if (!samplingPlots)
      form.setFieldValue(FORM_FIELD.SAMPLING_PLOTS_CNT, suggestGeneratePlots);
  }, [isEditing, samplingPlots]);

  const onSubmitGeneratePlot = async () => {
    try {
      const isValid = await form.validateFields();
      if (!isValid) return;
      if (
        samplingPlots !== +form.getFieldValue(FORM_FIELD.SAMPLING_PLOTS_CNT)
      ) {
        setLoading(true);
        const apiFunc = isCAR418Enabled
          ? projectServices.generateSamplingPlotsTekbone
          : projectServices.generateSamplingPlots;
        const { samplingPlotsCount } = await apiFunc({
          polygonId: polygonId,
          samplingPlotsCount: form.getFieldValue(FORM_FIELD.SAMPLING_PLOTS_CNT)
            ? +form.getFieldValue(FORM_FIELD.SAMPLING_PLOTS_CNT)
            : undefined,
        });
        form.setFieldValue(FORM_FIELD.SAMPLING_PLOTS_CNT, samplingPlotsCount);
      }
      onClose();
      api.success({
        message: t('GeneratePlotsSuccess'),
        duration: 2,
      });
      await mutatePolygon();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Form form={form} preserve={false}>
        <Space size="small" align="baseline">
          <Form.Item
            name={FORM_FIELD.SAMPLING_PLOTS_CNT}
            initialValue={samplingPlots}
            style={{ position: 'relative', zIndex: 99, marginBottom: 0 }}
            rules={[
              {
                validator: (_, genPlotValue) => {
                  if (!genPlotValue || genPlotValue <= plotsWithData) {
                    setIsInvalid(true);
                    return Promise.reject();
                  }
                  setIsInvalid(false);
                  return Promise.resolve();
                },
                message: t('GenPlotValidateMessage', {
                  numPlots: plotsWithData,
                }),
              },
            ]}
          >
            <Input
              disabled={loading}
              type="number"
              onClick={e => e.stopPropagation()}
              data-testid="measure-input"
              onKeyDown={event => {
                if (event.key.length === 1 && /\D/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Button
            size="small"
            icon={<CheckOutlined />}
            style={{ color: COLOR.PRIMARY, zIndex: 99 }}
            disabled={loading || isInvalid}
            loading={loading}
            onClick={e => {
              e.stopPropagation();
              onSubmitGeneratePlot();
            }}
            data-testid="btn-generate"
          />
          <Button
            size="small"
            icon={<CloseOutlined />}
            danger
            style={{ zIndex: 99 }}
            disabled={loading}
            onClick={e => {
              e.stopPropagation();
              onClose();
            }}
          />
        </Space>
      </Form>
    </>
  );
};

export default ProjectPlotMeasureForm;
