import { GOOGLE_DRIVE_ONLINE_VIEWER } from 'constants/file';
import IFrameContainer from 'containers/shared/IFrameContainer';
import InProgressPage from 'containers/shared/InProgressPage';
import { useProjectContext } from 'contexts';
import { RouteComponentProps, useParams } from 'react-router-dom';

export interface IFrameContainerProps extends RouteComponentProps {
  iFrameSrc: string;
}

export const PROJECTS = window.appConfig?.projects || {};

const QuarterlyReport: React.FC<IFrameContainerProps> = props => {
  const { projectId } = useProjectContext();
  const { quarter } = useParams<{ quarter: string }>();

  const iframeUrls: { [key: string]: { [projectId: string]: string } } = {
    'Q4-2023': {
      [PROJECTS.Kwahu]:
        'https://files.tekoapis.com/files/9307804f-b3c3-4d2e-ba6d-caf96134b42e',
    },
    'Q1-2024': {
      [PROJECTS.Kwahu]:
        'https://files.tekoapis.com/files/d98c4295-d0fb-4d90-b626-b3f2720a79fc',
    },
    'Q2-2024': {
      [PROJECTS.Kwahu]:
        'https://files.tekoapis.com/files/eedc4063-953d-4a99-b417-0ff9a24a65bf',
    },
  };

  return iframeUrls[quarter] ? (
    <IFrameContainer
      {...props}
      iFrameSrc={GOOGLE_DRIVE_ONLINE_VIEWER(iframeUrls[quarter][projectId])}
    />
  ) : (
    <InProgressPage />
  );
};

export default QuarterlyReport;
