import { useQuery } from '@tanstack/react-query';
import { commonConstants } from 'constants/index';
import { PAGING_OFFSET_ALL } from 'constants/pagination';
import { useProjectContext } from 'contexts';
import {
  IGetPolygonTekboneParams,
  IPolygonDetails,
  IPolygonTekbone,
  IUpsertPolygonPayload,
} from 'interfaces';
import { useEffect, useRef, useState } from 'react';
import { polygonServices } from 'services';
import { useAbortController } from './axios';

const { QUERY_KEYS } = commonConstants;

interface usePolygonOptions {
  fetchOnMount?: boolean;
}

const usePolygons = (
  params: IGetPolygonTekboneParams,
  options: usePolygonOptions = { fetchOnMount: true }
) => {
  const [polygons, setPolygons] = useState<IPolygonTekbone[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const { projectId } = useProjectContext();
  const { newAbortSignal, cancelPreviousRequest } = useAbortController();

  const firstUpdate = useRef(true);

  const getPolygons = async () => {
    try {
      cancelPreviousRequest();
      setLoading(true);
      setCanLoadMore(false);
      const {
        pagination,
        polygons: plgRes,
      } = await polygonServices.searchPolygons(
        { ...params, projectId },
        { signal: newAbortSignal() }
      );
      setPolygons(plgRes || []);
      if (
        params.limit !== PAGING_OFFSET_ALL.limit &&
        pagination.offset < pagination.total
      )
        setCanLoadMore(true);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = async () => {
    try {
      setLoading(true);
      const {
        pagination,
        polygons: plgRes,
      } = await polygonServices.searchPolygons(
        { ...params, projectId, offset: polygons.length },
        { signal: newAbortSignal() }
      );
      setPolygons(prev => [...prev, ...plgRes]);
      if (pagination.offset >= pagination.total) setCanLoadMore(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const upsertPolygons = async (params: IUpsertPolygonPayload) => {
    try {
      setLoading(true);
      cancelPreviousRequest();
      return await polygonServices.upsertPolygon(params);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!options.fetchOnMount && firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    getPolygons();
  }, [params]);

  return {
    loading,
    polygons,
    params,
    setPolygons,
    getPolygons,
    cancelPreviousRequest,
    upsertPolygons,
    canLoadMore,
    loadMore,
  };
};

const usePolygon = () => {
  const { projectId } = useProjectContext();
  const [polygonDetails, setPolygonDetails] = useState<IPolygonDetails>();
  const [gettingDetails, setGettingDetails] = useState(false);
  const [upsertingPolygon, setUpsertingPolygon] = useState(false);

  const getPolygonDetails = async (polygonId: number) => {
    try {
      setGettingDetails(true);
      const details = await polygonServices.getPolygonDetails(polygonId, {
        projectId,
      });
      setPolygonDetails(details);
    } finally {
      setGettingDetails(false);
    }
  };

  const upsertPolygon = async (payload: IUpsertPolygonPayload) => {
    try {
      setUpsertingPolygon(true);
      return await polygonServices.upsertPolygon(payload);
    } finally {
      setUpsertingPolygon(false);
    }
  };

  return {
    upsertingPolygon,
    gettingDetails,
    polygonDetails,
    getPolygonDetails,
    upsertPolygon,
  };
};

const usePolygonTypes = () => {
  const { projectId } = useProjectContext();
  const { data: polygonTypes, isLoading: loading } = useQuery({
    queryKey: [QUERY_KEYS.POLYGON_TYPES, projectId],
    queryFn: async () =>
      await polygonServices
        .getPolygonTypes(projectId)
        .then(res => res.polygonTypes || []),
    retry: false,
    refetchOnWindowFocus: false,
  });

  return {
    polygonTypes,
    loading,
  };
};

export default { usePolygons, usePolygon, usePolygonTypes };
