import { AutoComplete, AutoCompleteProps, Empty, Select, Spin } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { AnyObject } from 'interfaces';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { isScrollEnded } from 'helpers/select';
import { hasValue } from 'helpers/common';
import { t } from 'helpers/i18n';
import { ALL, DEBOUNCE_TIME } from 'constants/common';
import { DEFAULT_PAGINATION_OFFSET } from 'constants/pagination';

const DEFAULT_LIVE_SELECT_LIMIT = 50;

export enum LiveSelectType {
  SELECT,
  AUTO_COMPLETE,
}

export interface IOption {
  value: string | number;
  label: React.ReactNode;
  disabled?: boolean;
  title?: string;
  key?: string | number;
}

export interface IBaseFilter {
  id?: number;
  keyword?: string | undefined;
  offset?: number;
  limit?: number;
}

type IFilterResult<FilterType extends IBaseFilter> = FilterType & {
  options?: IOption[];
};

interface BaseProps<FilterType extends IBaseFilter> {
  filter?: AnyObject;
  /**
   * Number of data items per fetch
   */
  limit?: number;
  /**
   * Callback delay in ms
   */
  debounceTime?: number;
  /**
   * Get value of key
   */
  optionKey: string;
  /**
   * Function get data from service
   */
  optionFetcher: (filter: FilterType) => Promise<IFilterResult<FilterType>>;
  /**
   * Use to get fetched options from parent component
   */
  setDataOptions?: any;
  /**
   * Allow search empty keyword or not. Default is true
   * */
  allowSearchEmpty?: boolean;
  /**
   * Fetch option by value on initial
   */
  fetchByValueOnInit?: boolean;
  /**
   * Add option all
   */
  isAll?: boolean;
  /**
   * Max length search
   */
  maxLengthSearch?: number;
  /**
   * Fetch on mount
   */
  fetchOnMount?: boolean;
  allLabel?: string;
  allValue?: any;
}

interface LiveSelectProps<
  ValueType = any,
  FilterType extends IBaseFilter = IBaseFilter
> extends SelectProps<ValueType>, BaseProps<FilterType> {
  type?: LiveSelectType.SELECT;
  defaultOption?: IOption[];
}

export interface LiveAutoCompleteProps
  extends AutoCompleteProps,
    BaseProps<IBaseFilter> {
  loading?: boolean;
  type?: LiveSelectType.AUTO_COMPLETE;
  defaultOption?: IOption[];
}

const LiveSelectTypeOffset = <
  Props extends LiveSelectProps | LiveAutoCompleteProps
>({
  type = LiveSelectType.SELECT,
  loading,
  limit = DEFAULT_LIVE_SELECT_LIMIT,
  debounceTime = DEBOUNCE_TIME,
  optionFetcher,
  value,
  optionKey,
  setDataOptions, // Use to get fetched options from parent component
  allowSearchEmpty = true,
  placeholder,
  fetchByValueOnInit = false,
  isAll,
  maxLengthSearch,
  allLabel,
  allValue,
  defaultOption,
  fetchOnMount = false,
  ...rest
}: Props) => {
  const [options, setOptions] = useState<IOption[]>(
    defaultOption ? defaultOption : []
  );
  const [filter, setFilter] = useState<IBaseFilter>({
    keyword: undefined,
    offset: DEFAULT_PAGINATION_OFFSET.offset,
    limit,
  });

  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [openDropdown, setOpenDropdown] = useState<boolean | undefined>(
    allowSearchEmpty ? undefined : false
  );

  const handleSearch = (keyword?: string) => {
    const normalizedKeyword = keyword?.trim() || undefined;
    let shouldFetchOptions = allowSearchEmpty
      ? true
      : Boolean(normalizedKeyword);

    if (!allowSearchEmpty) {
      setOpenDropdown(Boolean(normalizedKeyword));
    }

    if (keyword && !normalizedKeyword) {
      shouldFetchOptions = false;
    }

    if (shouldFetchOptions) {
      // search keyword and keep selected
      optionFetcher({
        ...filter,
        keyword: normalizedKeyword,
        offset: DEFAULT_PAGINATION_OFFSET.offset,
        limit,
      }).then((response: any) => {
        const { options: optionData, ...filterData } = response;
        setOptions(optionData ?? []);
        setFilter(filterData);
      });
    }
  };

  const handleFocus = () => {
    if (type === LiveSelectType.SELECT) {
      // fetch if no option
      if (!loading && options.length <= _.flatten([value]).length) {
        handleSearch();
      }
    } else if (type === LiveSelectType.AUTO_COMPLETE) {
      if (searchValue?.trim()) {
        setOpenDropdown(true);
      } else {
        setSearchValue(value);
        handleSearch(value);
      }
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (!loading && isScrollEnded(event)) {
      const canLoadMore = filter.offset !== -1;

      if (canLoadMore) {
        // load more
        optionFetcher(filter).then(
          ({ options: optionData, ...filterData }): void => {
            setFilter(filterData);
            setOptions(options.concat(optionData ?? []));
          }
        );
      }
    }
  };

  const debouncedSearchHandler: (
    keyword?: string
  ) => void = useDebouncedCallback(handleSearch, debounceTime);

  // Get fetched options from parent component
  useEffect(() => {
    if (setDataOptions) setDataOptions(options);
    // eslint-disable-next-line
  }, [options]);

  useEffect(() => {
    // fetch value's options
    if (hasValue(value) || fetchOnMount) {
      const normalizedValue = _.flatten([value]);
      optionFetcher({
        keyword: fetchByValueOnInit ? value : undefined,
        [optionKey]: normalizedValue.join(),
      }).then(({ options: optionData, ...filterData }) => {
        setOptions(optionData ?? []);
        setFilter(filterData);
      });
    }
  }, []);

  const commonProps: SelectProps & AutoCompleteProps = {
    value,
    allowClear: Boolean(searchValue),
    placeholder: searchValue ? null : placeholder,
    showSearch: true,
    filterOption: false,
    searchValue,
    onSearch: valueSearch => {
      if (
        valueSearch &&
        maxLengthSearch &&
        valueSearch.length > maxLengthSearch
      ) {
        valueSearch = valueSearch.slice(0, maxLengthSearch);
      }
      if (valueSearch || !value) {
        debouncedSearchHandler(valueSearch);
      }
      setSearchValue(valueSearch);
    },
    onPopupScroll: handleScroll,
    onFocus: handleFocus,
    dropdownRender: node => <Spin spinning={loading}>{node}</Spin>,
    open: type === LiveSelectType.SELECT ? openDropdown : Boolean(openDropdown),
  };

  const renderOptions = (
    Option: typeof AutoComplete.Option | typeof Select.Option
  ) => {
    const optionRender: IOption[] = isAll
      ? [
          {
            value: allValue || ALL,
            label: allLabel || t('All'),
            disabled: false,
            title: undefined,
          },
          ...options,
        ]
      : options;

    return optionRender.map(
      ({ label, value, disabled, title, key, ...rest }) => (
        <Option
          data={rest}
          key={key || value}
          value={value}
          disabled={disabled}
          title={title}
        >
          {label}
        </Option>
      )
    );
  };

  if (type === LiveSelectType.AUTO_COMPLETE) {
    return (
      <AutoComplete
        {...commonProps}
        onBlur={() => {
          setOpenDropdown(false);
        }}
        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        {...rest}
      >
        {renderOptions(AutoComplete.Option)}
      </AutoComplete>
    );
  }

  return (
    <Select
      {...commonProps}
      {...rest}
      onSelect={(value, option) => {
        setOpenDropdown(false);
        rest.onSelect?.(value, option);
      }}
      onBlur={event => {
        setOpenDropdown(false);
        rest.onBlur?.(event);
      }}
      onClick={event => {
        if (
          options.length > 0 &&
          (event.currentTarget as Node).contains(event.target as Node) // check if click target is in select input
        )
          setOpenDropdown(true);
        rest.onClick?.(event);
      }}
      onInputKeyDown={event => {
        if (event.key === 'Enter') setOpenDropdown(!openDropdown);
        rest.onInputKeyDown?.(event);
      }}
      onClear={() => {
        if (allowSearchEmpty && !loading) {
          setSearchValue(undefined);
          handleSearch();
        }
        rest.onClear?.();
      }}
      loading={loading}
    >
      {renderOptions(Select.Option)}
    </Select>
  );
};

export { LiveSelectTypeOffset };
