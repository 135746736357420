import { Card, Col, ListProps, Row, List, Flex, Button } from 'antd';
import classNames from 'classnames';
import ContentBlock from 'components/shared/ContentBlock';
import { Key, ReactElement, useState } from 'react';
import ListStyles from './ListWithDetailLayout.module.scss';
import { DownOutlined } from '@ant-design/icons';
import { t } from 'helpers/i18n';

interface ListLayoutProps<T> extends ListProps<T> {
  contentRender: (item: T) => ReactElement;
  actions: ReactElement;
  onLoadMore: () => Promise<void>;
  onItemSelect: (item: T) => void;
  canLoadMore?: boolean;
}

const ListWithDetailLayout: React.FC & {
  Filter: React.FC;
  Detail: React.FC;
  List: <T>(props: ListLayoutProps<T>) => ReactElement;
} = ({ children }) => {
  const filter =
    Array.isArray(children) &&
    children.find(child => child.type === ListWithDetailLayout.Filter);

  return (
    <Flex vertical style={{ height: '100vh' }}>
      <Row className="mb-base">
        <ContentBlock className="w-100">{filter}</ContentBlock>
      </Row>
      <Row
        gutter={16}
        style={{ alignItems: 'stretch' }}
        className="flex-1 h-100 overflow-hidden"
      >
        <Col span={8} className="h-100">
          <ContentBlock
            className="h-100"
            classNames={{ body: 'h-100 d-flex flex-column' }}
          >
            {Array.isArray(children) &&
              children.find(child => child.type === ListWithDetailLayout.List)}
          </ContentBlock>
        </Col>
        <Col span={16} className="h-100">
          <ContentBlock
            className="h-100"
            classNames={{ body: 'h-100 d-flex flex-column overflow-auto' }}
          >
            {Array.isArray(children) &&
              children.find(
                child => child.type === ListWithDetailLayout.Detail
              )}
          </ContentBlock>
        </Col>
      </Row>
    </Flex>
  );
};

const FilterSection: React.FC = ({ children }) => {
  return <div>{children}</div>;
};

const DetailSection: React.FC = ({ children }) => {
  return <div>{children}</div>;
};

const ListSection = <T,>({
  contentRender,
  actions,
  onLoadMore,
  onItemSelect,
  canLoadMore,
  ...listProps
}: ListLayoutProps<T>) => {
  const [selectedItemKey, setSelectedItemKey] = useState<Key | null>(null);
  const [loadingMore, setLoadingMore] = useState(false);

  const loadMore = async () => {
    setLoadingMore(true);
    await onLoadMore();
    setLoadingMore(false);
  };

  return (
    <>
      {actions && (
        <Flex className="mb-base" justify="flex-end">
          {actions}
        </Flex>
      )}
      <List
        {...listProps}
        className="h-100"
        style={{ overflow: 'auto' }}
        loadMore={
          canLoadMore && (
            <Row
              justify="center"
              style={{
                marginTop: 12,
              }}
            >
              <Button
                loading={loadingMore}
                onClick={loadMore}
                type="link"
                className="p-0"
                icon={<DownOutlined />}
              >
                {t('ViewMore')}
              </Button>
            </Row>
          )
        }
        grid={{ gutter: 16, column: 1 }}
        bordered={false}
        renderItem={(item, index) => {
          const key = listProps.rowKey
            ? typeof listProps.rowKey === 'function'
              ? listProps.rowKey(item)
              : item[listProps.rowKey]?.toString()
            : index;
          return (
            <List.Item
              key={key}
              onClick={() => {
                onItemSelect(item);
                setSelectedItemKey(key || null);
              }}
            >
              <Card
                hoverable
                className={classNames('w-100', {
                  [ListStyles.selectedCard]: selectedItemKey === key,
                })}
              >
                {contentRender(item)}
              </Card>
            </List.Item>
          );
        }}
      />
    </>
  );
};

ListWithDetailLayout.Filter = FilterSection;
ListWithDetailLayout.Detail = DetailSection;
ListWithDetailLayout.List = ListSection;

export default ListWithDetailLayout;
