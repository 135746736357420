import trackerUtils from './trackerUtils';

const { CLICK_DATA, getPayload } = trackerUtils;

const trackTreeAction = (contentName: string, index: number) => {
  window.track('manualTrackInteractionContent', {
    ...CLICK_DATA,
    screenName: 'submissionDetail',
    regionName: 'actionStatus',
    contentName: contentName,
    target: 'submissionDetail',
    payload: getPayload('submissionDetail', index),
  });
};

export default {
  trackTreeAction,
};
