import React from 'react';
import { useProjectDetailV2Context, useProjectDrawersContext } from './context';
import FeasibilityStudyReportDrawer from './GenerateFeasibilityStudyReport/FeasibilityStudyReportDrawer';
import { ProjectUpsertionDrawer } from 'components/ProjectList/ProjectModal/ProjectUpsertionDrawer';
import SamplingPlotsDrawer from 'components/ProjectList/ProjectDetailMapV2/SamplingPlotsDrawer';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { IProjectDetails } from 'interfaces';
import { ID_DEFAULT } from 'constants/common';
import { useSelector } from 'react-redux';
import { selectProject } from 'redux/features/project';

interface ProjectMapDrawersProps {
  refetchProject: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<IProjectDetails | undefined, unknown>>;
}

const ProjectMapDrawers: React.FC<ProjectMapDrawersProps> = ({
  refetchProject,
}) => {
  const {
    showGenerateReport,
    setShowGenerateReport,
    isDetailProjectDrawerOpen,
    setIsDetailProjectDrawerOpen,
    samplingPlotsDrawerType,
    setSamplingPlotsDrawerType,
  } = useProjectDrawersContext();
  const { currentPolygonId, polygons } = useProjectDetailV2Context();
  const project = useSelector(selectProject);
  const area =
    (currentPolygonId === ID_DEFAULT
      ? project?.totalArea
      : polygons.find(polygon => polygon.polygonId === currentPolygonId)
          ?.area) ?? 0;

  return (
    <>
      <FeasibilityStudyReportDrawer
        open={showGenerateReport}
        onClose={() => setShowGenerateReport(false)}
      />
      <ProjectUpsertionDrawer
        isEdit={true}
        open={isDetailProjectDrawerOpen}
        setOpen={setIsDetailProjectDrawerOpen}
        refetchProject={refetchProject}
      />
      <SamplingPlotsDrawer
        open={
          !!samplingPlotsDrawerType &&
          ['IMPORT', 'RANDOM'].includes(samplingPlotsDrawerType)
        }
        onClose={() => {
          setSamplingPlotsDrawerType(undefined);
        }}
        area={area}
      />
    </>
  );
};

export { ProjectMapDrawers };
