import { t } from 'helpers/i18n';
import { RiskLevel, RiskMetrics, RiskMetricType } from 'interfaces';

const RISK_ASSESSMENT_METRICS: Record<RiskLevel, RiskMetrics> = {
  [RiskLevel.LOW]: {
    uncertainty: 0,
    leakage: 5,
    performanceBenchmark: 0,
    nonPermanenceRisk: 10,
  },
  [RiskLevel.MEDIUM]: {
    uncertainty: 0,
    leakage: 10,
    performanceBenchmark: 2.5,
    nonPermanenceRisk: 15,
  },
  [RiskLevel.HIGH]: {
    uncertainty: 0,
    leakage: 15,
    performanceBenchmark: 5,
    nonPermanenceRisk: 20,
  },
  [RiskLevel.CUSTOM]: {
    uncertainty: 0,
    leakage: 0,
    performanceBenchmark: 0,
    nonPermanenceRisk: 0,
  },
};

const RISK_METRICS_ROWS = [
  {
    key: RiskMetricType.uncertainty,
    label: t('Uncertainty'),
    description: t('UncertaintyExplanation'),
  },
  {
    key: RiskMetricType.leakage,
    label: t('Leakage'),
    description: t('LeakageExplanation'),
  },
  {
    key: RiskMetricType.performanceBenchmark,
    label: t('PerformanceBenchmark'),
    description: t('PerformanceBenchmarkExplanation'),
  },
  {
    key: RiskMetricType.nonPermanenceRisk,
    label: t('NonPermanenceRisk'),
    description: t('NonPermanenceRiskExplanation'),
  },
];

const RISK_LEVEL_COLUMNS = [
  {
    key: RiskLevel.LOW,
    label: t('Low'),
  },
  {
    key: RiskLevel.MEDIUM,
    label: t('Medium'),
  },
  {
    key: RiskLevel.HIGH,
    label: t('High'),
  },
  {
    key: RiskLevel.CUSTOM,
    label: t('Custom'),
  },
];

export default {
  RISK_ASSESSMENT_METRICS,
  RISK_METRICS_ROWS,
  RISK_LEVEL_COLUMNS,
};
