/** Third party libs **/
import {
  Button,
  Card,
  Form,
  FormInstance,
  Input,
  notification,
  Typography,
} from 'antd';
import React, { useContext } from 'react';
import { SendOutlined } from '@ant-design/icons';

/** Local libs **/
import { ISurveyForm } from 'interfaces/survey';
import { t } from 'helpers/i18n';
import { farmHooks, userHooks } from 'hooks';
import { CurrentSelectedFarm } from 'components/FarmerManagement/FarmerEnrolment/Context';

/** Styles **/
import commentCardStyles from './CommentCard.module.scss';

/** Interfaces, enum... **/
interface ICommentCardProps {
  showDiscussionHistory: boolean;
  setShowDiscussionHistory: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedSurveyForm: React.Dispatch<
    React.SetStateAction<ISurveyForm | undefined>
  >;
  setShowFarmList: React.Dispatch<React.SetStateAction<boolean>>;
  formComment: FormInstance;
  farmLandId?: number;
}

/** Variables **/
const { Title } = Typography;
const { useUserPermissions } = userHooks;

/** ------------------------- **/
/** CommentCard Component */
/** ------------------------- **/
const CommentCard: React.FC<ICommentCardProps> = ({
  showDiscussionHistory,
  setShowDiscussionHistory,
  setSelectedSurveyForm,
  setShowFarmList,
  formComment,
  farmLandId,
}) => {
  /** States **/

  /** Hooks **/
  const { setTriggerReloadComment, setShowFarmInfo } = useContext(
    CurrentSelectedFarm
  );
  const { upsertCommentFarmLand, upsertingComment } = farmHooks.useFarmDetail();

  /** Variables **/
  const { canApproveFarmerEnrollment } = useUserPermissions();

  /** Functions, Events, Actions... **/
  const onSubmit = async (values: any) => {
    if (values.content?.trim() && farmLandId) {
      const result = await upsertCommentFarmLand(
        farmLandId,
        values.content.trim()
      );
      if (result.status === 200) {
        formComment.resetFields();
        setShowDiscussionHistory(true);
        setTriggerReloadComment(prev => !prev);
        setShowFarmInfo(false);
      }
    } else {
      notification.error({
        message: t('Error'),
        description: t('PleaseEnterComment'),
      });
    }
  };

  /** Effects **/

  /** Elements **/
  return (
    <Card
      className={commentCardStyles['comment-card']}
      extra={
        <div className={commentCardStyles['comment-card-header-wrapper']}>
          <Title className={commentCardStyles['comment-card-title']} level={5}>
            {t('Comment')}
          </Title>
          <Button
            type="link"
            className={commentCardStyles['view-discussion-history']}
            onClick={() => {
              setShowDiscussionHistory(!showDiscussionHistory);
              setSelectedSurveyForm(undefined);
              setShowFarmList(false);
              setShowFarmInfo(false);
            }}
          >
            {t('ViewDiscussionHistory')}
          </Button>
        </div>
      }
    >
      <Form form={formComment} onFinish={onSubmit}>
        <div className="position-relative">
          <Form.Item name="content" className="mb-0">
            <Input.TextArea
              disabled={!canApproveFarmerEnrollment}
              rows={5}
              placeholder={t('WriteAComment')}
              allowClear
            />
          </Form.Item>
          <Button
            className="position-absolute"
            icon={<SendOutlined />}
            style={{ right: 5, bottom: 5, zIndex: 2000 }}
            type="text"
            onClick={() => formComment.submit()}
            loading={upsertingComment}
          />
        </div>
      </Form>
    </Card>
  );
};

export default CommentCard;
export { CommentCard };
