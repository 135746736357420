/** Third party libs * */
import React from 'react';
import { Modal, Form, Input } from 'antd';

/** Local libs * */
import { t } from 'helpers/i18n';

/** Components * */

/** Styles * */

/** ------------------------- * */
/** Interfaces, enum... * */
interface ProjectPolygonRejectModalProps {
  open: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: (values: any) => void;
}

/** Variables * */
const { Item } = Form;

/** ------------------------- * */
const ProjectPolygonRejectModal: React.FC<ProjectPolygonRejectModalProps> = ({
  open,
  setOpen,
  onSubmit,
}) => {
  /** States * */

  /** Hooks * */
  const [form] = Form.useForm();

  /** Variables * */

  /** Effects * */

  /** Functions, Events, Actions... * */
  const onOk = async () => {
    form.submit();
  };

  const onCancel = () => {
    setOpen?.(false);
  };

  const onFinished = (values: any) => {
    onSubmit?.(values.rejectReason);
    setOpen?.(false);
  };

  /** Elements * */
  return (
    <Modal
      open={open}
      okText={t('Ok')}
      cancelText={t('Cancel')}
      maskClosable={false}
      onOk={onOk}
      onCancel={onCancel}
    >
      <Form form={form} layout={'vertical'} onFinish={onFinished}>
        <Item
          label={t('ReasonForReject')}
          required={true}
          name={'rejectReason'}
          rules={[
            {
              required: true,
              message: t('PleaseInputReasonReject'),
            },
            {
              validator: (rule, value) => {
                if (value && value.length > 100) {
                  return Promise.reject(t('Maximum100Characters'));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder={t('ReasonPlaceholder')} maxLength={100} />
        </Item>
      </Form>
    </Modal>
  );
};

export default ProjectPolygonRejectModal;
