import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Modal,
  Button,
  Upload,
  Divider,
  Typography,
  Radio,
  FormItemProps,
  notification,
  UploadProps,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { t } from 'helpers/i18n';
import { uploadHelpers } from 'helpers';
import { buttonStyle } from 'components/shared/Button';
import { PhoneOutlined, MailOutlined, GlobalOutlined } from '@ant-design/icons';
import { ReactNode, useEffect, useState, useMemo } from 'react';
import { useDisclosureProps } from 'components/shared/Modal/useDisclosure';
import { IProjectForm } from 'interfaces';
import { removeUndefinedKey } from 'utils/helper';
import { convertDateFormat, filterSelect } from 'helpers/common';
import { checkTelephone } from 'utils/formValidator';
import { RcFile } from 'antd/es/upload';
import { fileServices, projectServices } from 'services';
import './ProjectAreaRequired.scss';
import { useProjectContext } from 'contexts';
import {
  plotConstants,
  countryConstants,
  projectConstants,
  colorConstants,
} from 'constants/index';

const { Dragger } = Upload;
const { Title } = Typography;
const { PLOT_SHAPE, PLOT_SHAPE_OPTIONS } = plotConstants;
const { COUNTRY } = countryConstants;
const { PROJECT_TYPE } = projectConstants;
const { COLOR } = colorConstants;

type formListProps = FormItemProps & {
  placeholder?: string;
  span?: number;
  children?: ReactNode;
};

const DEFAULT_FORM_VALUE = {
  STANDARD: { value: 'PLAN_VIVO', label: 'Plan Vivo' },
  LOCATION: { value: 'Vietnam', label: 'Vietnam' },
};

const FORM_PAYLOAD = {
  ID: 'projectId',
  PROJECT_AREA_URL: 'projectAreaUrl',
  STANDARD: 'standard',
  LOCATION: 'location',
  TITLE: 'projectTitle',
  ORGANIZATION: 'organizationName',
  TELEPHONE: 'telephone',
  EMAIL: 'email',
  WEBSITE: 'website',
  DESCRIPTION: 'description',
  PLOT_SHAPE: 'plotShape',
};

const formList_1: formListProps[] = [
  {
    name: FORM_PAYLOAD.ID,
    span: 0,
  },
  {
    rules: [{ required: true, message: t('PleaseInputProjectTitle') }],
    label: t('ProjectTitle'),
    name: FORM_PAYLOAD.TITLE,
    placeholder: 'Project Title',
    span: 24,
    children: <Input maxLength={250} />,
  },
];

interface IProjectListForm extends useDisclosureProps {
  initialValue?: Partial<IProjectForm>;
}

/**
 * @deprecated since CAR-418.
 * Use `ProjectUpsertionDrawer ` instead.
 */
const ProjectListAddForm = (props: IProjectListForm) => {
  const { getProjects } = useProjectContext();
  const [form] = Form.useForm<Partial<IProjectForm>>();
  const [api, contextHolder] = notification.useNotification();
  const { initialValue, isOpen } = props;
  const [uploading, setUploading] = useState<boolean>(false);
  const [editing, setEditing] = useState(false);
  const isEdit = !!props.initialValue?.projectId;
  const [areaFile, setAreaFile] = useState<RcFile | undefined>(undefined);
  const [files, setFiles] = useState<RcFile[]>([]);
  const uploadProps: UploadProps = {
    name: 'file',
    fileList: files,
    multiple: false,
    accept: '.kml',
    beforeUpload: async (file: RcFile) => {
      setFiles([]);
      const state = uploadHelpers.beforeUpload(file, {
        accept: 'kml,application/vnd.google-earth.kml+xml',
        acceptErrorMessage: t('OnlySupportKmlFormat'),
        size: 1.25,
      });
      setAreaFile(state ? file : undefined);
      return state;
    },
    customRequest: ({ file, onSuccess }: any) => {
      setFiles([file]);
      onSuccess('ok');
    },
    onRemove: file => {
      setAreaFile(undefined);
      setFiles(files => {
        return files.filter(f => f.uid !== file.uid);
      });
    },
  };

  const formList_2: formListProps[] = useMemo(() => {
    return [
      {
        label: t('Standard'),
        name: FORM_PAYLOAD.STANDARD,
        span: 8,
        rules: [{ required: true }],
        children: (
          <Select
            options={PROJECT_TYPE}
            showSearch
            labelInValue
            filterOption={(input, option) => filterSelect(input, option)}
          />
        ),
      },
      {
        label: t('Location'),
        name: FORM_PAYLOAD.LOCATION,
        span: 8,
        children: (
          <Select
            maxLength={250}
            options={COUNTRY}
            showSearch
            labelInValue
            filterOption={(input, option) => filterSelect(input, option)}
          />
        ),
      },
      {
        label: t('Organization'),
        name: FORM_PAYLOAD.ORGANIZATION,
        span: 8,
        children: <Input maxLength={250} />,
      },
      {
        label: (
          <label>
            <Space>
              <PhoneOutlined />
              <span>{t('Telephone')}</span>
            </Space>
          </label>
        ),
        name: FORM_PAYLOAD.TELEPHONE,
        span: 8,
        children: <Input maxLength={250} type="number" />,
        rules: [
          {
            message: t('InvalidTelephone'),
            validator: (_, value) => {
              if (!value || checkTelephone(value)) return Promise.resolve();
              else return Promise.reject();
            },
          },
        ],
      },
      {
        rules: [
          {
            type: 'email',
            message: t('InvalidEmail'),
          },
        ],
        name: FORM_PAYLOAD.EMAIL,
        span: 8,
        label: (
          <label>
            <Space>
              <MailOutlined />
              <span>{t('Email')}</span>
            </Space>
          </label>
        ),
        children: <Input maxLength={250} />,
      },
      {
        name: FORM_PAYLOAD.WEBSITE,
        label: (
          <label>
            <Space>
              <GlobalOutlined />
              <span>{t('Website')}</span>
            </Space>
          </label>
        ),
        span: 8,
        children: <Input maxLength={250} />,
      },
      {
        name: FORM_PAYLOAD.PLOT_SHAPE,
        label: t('PlotShape'),
        span: 24,
        required: true,
        rules: [{ required: true }],
        children: (
          <Radio.Group disabled={isEdit} options={PLOT_SHAPE_OPTIONS} />
        ),
      },
      {
        span: 24,
        label: t('Description'),
        name: FORM_PAYLOAD.DESCRIPTION,
        children: <Input.TextArea maxLength={5000} rows={4} />,
      },
    ];
  }, [isEdit]);

  useEffect(() => {
    form.resetFields();
  }, [isOpen]);
  useEffect(() => {
    if (initialValue && isOpen) {
      form.setFieldsValue(initialValue);
      if (initialValue.location)
        form.setFieldValue(FORM_PAYLOAD.LOCATION, {
          value: initialValue.location,
        });
      if (initialValue.standard)
        form.setFieldValue(FORM_PAYLOAD.STANDARD, {
          value: initialValue.standard,
        });
    }
  }, [initialValue, form, isOpen]);

  const updateFileType = (file: RcFile): Promise<File> => {
    const dataURLtoFile = (dataurl: string, filename: string): File => {
      const arr = dataurl.split(',');
      const bstr = atob(arr[arr.length - 1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: 'application/vnd.google-earth.kml+xml',
      });
    };

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        resolve(dataURLtoFile(reader.result as string, file.name));
      };
      reader.onerror = function(error) {
        reject(error);
      };
    });
  };

  const uploadFile = async () => {
    const file = files[0];
    if (file) {
      setUploading(true);
      const formData = new FormData();
      formData.append('file', await updateFileType(file));
      formData.append('cloud', 'true');
      const res = await fileServices.uploadFile('doc', formData);
      setUploading(false);
      return res?.data?.url;
    }
  };

  const handleCreate = async () => {
    try {
      const isValid = await form.validateFields();
      if (isValid) {
        const projectAreaUrl = await uploadFile();
        form.setFieldValue(FORM_PAYLOAD.PROJECT_AREA_URL, projectAreaUrl);

        setEditing(true);
        const res = await projectServices.createProject({
          ...removeUndefinedKey({
            ...form.getFieldsValue(),
            standard: form.getFieldValue(FORM_PAYLOAD.STANDARD).value,
            location: form.getFieldValue(FORM_PAYLOAD.LOCATION).value,
            [FORM_PAYLOAD.PROJECT_AREA_URL]: form.getFieldValue(
              FORM_PAYLOAD.PROJECT_AREA_URL
            ),
          }),
        });

        if (res && res.code === 0) {
          api.success({
            message: isEdit
              ? t('EditProjectSuccessfully')
              : t('CreateProjectSuccessfully'),
          });
          props.onClose();
          getProjects();
        }
      }
    } catch (error) {
      if (!(error as any).errorFields) {
        api.error({
          message: t('SomethingWentWrong'),
          duration: 2,
        });
      }
    } finally {
      setEditing(false);
    }
  };

  const getProjectAreaFormItem = (): formListProps[] => {
    if (isEdit && initialValue?.totalPolygons && initialValue.totalPolygons > 0)
      return [];

    return [
      {
        rules: [
          {
            message: t('PleaseInputProjectArea'),
            validator: (_, value) => {
              if (!value || !value.file || !areaFile) return Promise.reject();
              else return Promise.resolve();
            },
          },
        ],
        required: true,
        label: t('ProjectArea'),
        span: 24,
        children: (
          <Dragger {...uploadProps}>
            <Space>
              <Space direction={'vertical'} size={'small'}>
                <p className="ant-upload-text">{t('ClickOrDragFileArea')}</p>
                <p className="ant-upload-hint">{t('SupportForKmlFile')}</p>
              </Space>
              <p className="ant-upload-drag-icon" style={{ marginBottom: 0 }}>
                <InboxOutlined />
              </p>
            </Space>
          </Dragger>
        ),
      },
    ];
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={props.isOpen}
        onCancel={props.onClose}
        title={
          <Title level={2}>
            {isEdit ? t('EditProject') : t('CreateNewProject')}
          </Title>
        }
        width={'900px'}
        footer={
          <div style={{ textAlign: 'center' }}>
            <Button
              key="back"
              onClick={props.onClose}
              style={{
                ...buttonStyle,
                height: '100%',
                minWidth: '100px',
                backgroundColor: COLOR.NEUTRAL,
              }}
              className="mr-half"
            >
              {t('Close')}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              onClick={handleCreate}
              loading={uploading || editing}
              data-testid="btn-submit"
              style={{
                ...buttonStyle,
                height: '100%',
                minWidth: '100px',
                backgroundColor: COLOR.PRIMARY,
              }}
            >
              {isEdit ? t('SaveProject') : t('CreateProject')}
            </Button>
          </div>
        }
      >
        <Divider />
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            location: DEFAULT_FORM_VALUE.LOCATION,
            standard: DEFAULT_FORM_VALUE.STANDARD,
            [FORM_PAYLOAD.PROJECT_AREA_URL]: null,
            [FORM_PAYLOAD.PLOT_SHAPE]: PLOT_SHAPE.RECTANGLE,
          }}
        >
          <Row gutter={{ xs: 8, md: 12, lg: 64 }}>
            {[...formList_1, ...getProjectAreaFormItem(), ...formList_2].map(
              formItem => (
                <Col span={formItem.span}>
                  <Form.Item {...formItem}>{formItem.children}</Form.Item>
                </Col>
              )
            )}
            {isEdit && initialValue?.creationDate ? (
              <Col span={24}>
                {t('CreateDate')} :{' '}
                {convertDateFormat(initialValue.creationDate)}
              </Col>
            ) : null}
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ProjectListAddForm;
