import { DEFAULT_PAGE } from 'constants/pagination';
import { useProjectContext } from 'contexts';
import { IDiscussion, IGetDiscussionHistoryParams } from 'interfaces';
import { useState } from 'react';
import { discussionHistoryServices } from 'services';

const useDiscussionHistory = (farmLandId?: number) => {
  const { projectId } = useProjectContext();

  const DEFAULT_PARAMS: IGetDiscussionHistoryParams = {
    farmLandId,
    page: DEFAULT_PAGE,
    pageSize: 500,
    projectId,
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [discussionHistory, setDiscussionHistory] = useState<IDiscussion[]>([]);
  const [params, setParams] = useState<IGetDiscussionHistoryParams>(
    DEFAULT_PARAMS
  );

  const getFarmDiscussionHistory = async (
    farmLandId: number,
    projectId: number
  ) => {
    if (!farmLandId) return;
    try {
      setLoading(true);
      const result = await discussionHistoryServices.getDiscussionHistory({
        ...params,
        farmLandId,
        projectId,
      });
      setDiscussionHistory(result.comments || []);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    discussionHistory,
    getFarmDiscussionHistory,
    setParams,
  };
};

export default {
  useDiscussionHistory,
};
