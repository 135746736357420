import { t } from 'helpers/i18n';

const PLOT_SHAPE = {
  SQUARE: 'SQUARE',
  RECTANGLE: 'RECTANGLE',
  CIRCLE: 'CIRCLE',
};

const PLOT_SHAPE_OPTIONS = [
  {
    label: t('Rectangle'),
    value: PLOT_SHAPE.RECTANGLE,
  },
  {
    label: t('Square'),
    value: PLOT_SHAPE.SQUARE,
  },
  {
    label: t('Circle'),
    value: PLOT_SHAPE.CIRCLE,
  },
];

export default { PLOT_SHAPE, PLOT_SHAPE_OPTIONS };
