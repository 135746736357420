import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Typography,
  notification,
} from 'antd';
import ContentBlock from 'components/shared/ContentBlock';
import AppContainer from 'containers/AppLayout/AppContainer';
import { t } from 'helpers/i18n';
import { IPayloadUserProjectAddition } from 'interfaces';
import React, { useState } from 'react';
import { projectServices } from 'services';

const UserProjectAddition: React.FC = () => {
  const [form] = Form.useForm<IPayloadUserProjectAddition>();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinishForm = async (values: IPayloadUserProjectAddition) => {
    setLoading(true);
    try {
      await projectServices.addUserToProject({
        ...values,
        iamId: values.iamId.trim(),
      });
      notification.success({
        message: t('Success'),
        description: t('AddUserSuccessfully'),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppContainer
      title={
        <Typography.Title level={4} className="mb-0">
          {t('AddUserToProject')}
        </Typography.Title>
      }
    >
      <Row align="middle" justify="center">
        <Col xs={24} lg={12}>
          <ContentBlock>
            <Form form={form} layout="vertical" onFinish={onFinishForm}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Project ID"
                    name="projectId"
                    rules={[{ required: true }]}
                  >
                    <InputNumber placeholder="Project ID" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="IAM ID"
                    name="iamId"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="IAM ID" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ type: 'email', message: t('InvalidEmail') }]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={t('FullName')} name="fullName">
                    <Input placeholder={t('FullName')} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Merchant ID" name="merchantId">
                    <InputNumber placeholder="Merchant ID" />
                  </Form.Item>
                </Col>
                <Col span={24} className="text-center">
                  <Button
                    htmlType="submit"
                    className="primary-base-button"
                    loading={loading}
                  >
                    {t('Add')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </ContentBlock>
        </Col>
      </Row>
    </AppContainer>
  );
};

export default UserProjectAddition;
