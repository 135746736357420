/** Third party libs * */
import React, { useEffect } from 'react';
import { Input } from 'antd';

/** Local libs * */
import { t } from 'helpers/i18n';
import { BaseItem, BaseItemProps } from '../BaseItem';

/** Components * */

/** Styles * */

/** Interfaces, enum... * */
interface PasswordProps extends BaseItemProps {}

/** Variables * */

/** ------------------------- * */
const Password: React.FC<PasswordProps> = ({
  item,
  disabled,
  form,
  ...props
}) => {
  /** States * */

  /** Hooks * */

  /** Variables * */

  /** Effects * */
  useEffect(() => {
    if (item.fieldName) {
      form.setFieldValue(item.fieldName, item.value);
    }
  }, [item.value]);

  /** Functions, Events, Actions... * */

  /** Elements * */
  return (
    <BaseItem
      item={item}
      disabled={disabled}
      form={form}
      validator={(_, value) => {
        if (item.isRequired && (!value || !value.trim())) {
          return Promise.reject(t('NotBeEmpty'));
        }

        return Promise.resolve();
      }}
      {...props}
    >
      <Input.Password placeholder={t('EnterContent')} disabled={disabled} />
    </BaseItem>
  );
};

export default Password;
