import {
  Button,
  Col,
  Flex,
  Form,
  InputNumber,
  notification,
  Popconfirm,
  Row,
  Select,
  theme,
  Tooltip,
} from 'antd';
import React, { useEffect, useState } from 'react';
import SamplingModuleSelect from '../SamplingModuleSelect';
import { REGEX_PATTERN_NUMBER_FORMAT } from 'components/FPIC/constants';
import { getNumOfPlotsSuggestionTypeCircle } from 'helpers/plots';
import { t } from 'helpers/i18n';
import { InfoCircleOutlined } from '@ant-design/icons';
import { plotConstants } from 'constants/index';
import { projectServices } from 'services';
import { useProjectDetailV2Context } from 'containers/Project/ProjectDetailMapV2/context';
import { useProjectContext } from 'contexts';
import './RandomSamplingPlotsForm.scss';
import { ID_DEFAULT } from 'constants/common';
import { selectSamplingModules } from 'redux/features/project';
import { useSelector } from 'react-redux';

const { PLOT_SHAPE, PLOT_SHAPE_OPTIONS } = plotConstants;

const PLOT_RADIUS_DEFAULT = 3;
const INITIAL_VALUES = {
  plotShape: PLOT_SHAPE.CIRCLE,
  plotRadius: PLOT_RADIUS_DEFAULT,
};

const { useToken } = theme;

interface RandomSamplingPlotsFormProps {
  area: number;
  onClose: () => void;
}

const RandomSamplingPlotsForm: React.FC<RandomSamplingPlotsFormProps> = ({
  area,
  onClose,
}) => {
  const [form] = Form.useForm();
  const { token } = useToken();
  const plotRadius = Form.useWatch('plotRadius', form);
  const { currentPolygonId } = useProjectDetailV2Context();
  const { projectId } = useProjectContext();
  const samplingModules = useSelector(selectSamplingModules);

  const [loading, setLoading] = useState(false);

  const onGenerateSamplingPlots = async (values: any) => {
    try {
      setLoading(true);
      const {
        samplingPlotsCount,
        isProcessedAsync,
      } = await projectServices.generateSamplingPlotsTekbone({
        ...values,
        projectId,
        polygonId:
          currentPolygonId !== ID_DEFAULT ? currentPolygonId : undefined,
      });

      // Notification of results sent by email
      if (isProcessedAsync) {
        notification.success({
          message: t('GeneratePlotsSuccessfullyResultWillBeSentByEmail'),
        });
      } else {
        notification.success({
          message: t('SuccessfullyGeneratedPlots', {
            successPlots: samplingPlotsCount,
            totalPlots: values.samplingPlotsCount,
          }),
        });
      }
      onClose();

      // @TODO: Need to refetch plots after generate
      // await refetchPlots();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (plotRadius) {
      form.setFieldValue(
        'samplingPlotsCount',
        getNumOfPlotsSuggestionTypeCircle(area, plotRadius)
      );
    }
  }, [plotRadius]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={INITIAL_VALUES}
      onFinish={onGenerateSamplingPlots}
    >
      <SamplingModuleSelect showUploadOption={false} />
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <Form.Item
            name="plotShape"
            label={t('PlotShape')}
            rules={[{ required: true }]}
          >
            <Select options={PLOT_SHAPE_OPTIONS} className="read-only" />
          </Form.Item>
        </Col>
        <Form.Item noStyle dependencies={['plotShape']}>
          {({ getFieldValue }) =>
            getFieldValue('plotShape') === PLOT_SHAPE.CIRCLE && (
              <Col span={12}>
                <Form.Item
                  name="plotRadius"
                  label={t('PlotRadius')}
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    suffix="m"
                    className="w-100"
                    min={0}
                    formatter={value =>
                      `${value}`.replace(REGEX_PATTERN_NUMBER_FORMAT, ',')
                    }
                  />
                </Form.Item>
              </Col>
            )
          }
        </Form.Item>
        <Col span={24}>
          <Form.Item
            name="samplingPlotsCount"
            rules={[
              {
                required: true,
                message: t('RequiredFieldError', { field: t('NumberOfPlots') }),
              },
            ]}
            label={
              <Tooltip
                title={t('NumberOfPlotsExtraInfo')}
                placement="right"
                color={token.colorLink}
                rootClassName="plots-tooltip"
              >
                {t('NumberOfPlots')}
                <InfoCircleOutlined className="ml-quarter" />
              </Tooltip>
            }
          >
            <InputNumber
              min={1}
              formatter={value =>
                `${value}`.replace(REGEX_PATTERN_NUMBER_FORMAT, ',')
              }
              onKeyDown={event => {
                if (event.key.length === 1 && /\D/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
        </Col>
        <Flex justify="center" className="w-100">
          <Form.Item noStyle dependencies={['samplingModuleId']}>
            {({ getFieldValue }) => {
              const samplingModuleId = getFieldValue('samplingModuleId');
              const isModuleHasData = samplingModules.find(
                samplingModule => samplingModule.id === samplingModuleId
              )?.activePlotSet.hasSubmissionData;

              return isModuleHasData ? (
                <Popconfirm
                  title={t('GeneratePlotsWarning')}
                  onConfirm={form.submit}
                  okText={t('Yes')}
                  cancelText={t('No')}
                  rootClassName="plots-tooltip"
                  placement="bottom"
                >
                  <Button type="primary" loading={loading}>
                    {t('GeneratePlots')}
                  </Button>
                </Popconfirm>
              ) : (
                <Button type="primary" loading={loading} htmlType="submit">
                  {t('GeneratePlots')}
                </Button>
              );
            }}
          </Form.Item>
        </Flex>
      </Row>
    </Form>
  );
};

export default RandomSamplingPlotsForm;
