import {
  Button,
  Collapse,
  CollapseProps,
  Flex,
  Form,
  Typography,
  FormInstance,
  Row,
  Col,
  Input,
  Radio,
  Spin,
  notification,
} from 'antd';
import { t } from 'helpers/i18n';
import React, { useEffect, useRef, useState } from 'react';
import {
  JobTitleSelect,
  MyProjectSelect,
  ProjectCommunitySelect,
} from 'components/shared/Select';
import RoleGroupTable from './RoleGroupTable';
import {
  AccountStatus,
  IAccount,
  ICreateAccountPayload,
  IGetAccountDetailsResponse,
} from 'interfaces';
import { PHONE_NUMBER_REGEX } from 'constants/common';
import { userManagementServices } from 'services';

interface UpsertUserAccountProps {
  title: string;
  onCancel?: () => void;
  selectedAccountId?: number;
  onFinish?: (account: IAccount) => void;
}

const UpsertUserAccount: React.FC<UpsertUserAccountProps> = ({
  title,
  onCancel,
  selectedAccountId,
  onFinish,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const oldData = useRef<ICreateAccountPayload>();
  const [accountDetails, setAccountDetails] = useState<
    IGetAccountDetailsResponse
  >();

  useEffect(() => {
    const getDetails = async (accountId: number) => {
      try {
        setLoading(true);
        const data = await userManagementServices.getAccountDetails({
          accountId,
        });
        const initialValues = {
          ...data,
          projectIds: data.projects.map(project => project.id),
          communityIds: data.communities.map(community => community.id),
        };
        oldData.current = initialValues;
        form.setFieldsValue(initialValues);
        setAccountDetails(data);
      } finally {
        setLoading(false);
      }
    };

    if (selectedAccountId) {
      form.resetFields();
      getDetails(selectedAccountId);
    }
  }, [selectedAccountId]);

  const handleSave = async (values: ICreateAccountPayload) => {
    try {
      setLoading(true);
      if (selectedAccountId) {
        await userManagementServices.updateAccount({
          accountId: selectedAccountId,
          ...values,
        });
        notification.success({
          message: t('UpdateUserSuccessfully'),
        });
        onFinish?.({ ...values, id: selectedAccountId });
      } else {
        await userManagementServices.createAccount({
          ...values,
        });
        notification.success({
          message: t('CreateUserSuccessfully'),
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const items: CollapseProps['items'] = [
    {
      key: 'basic',
      label: t('BasicInformation'),
      children: <BasicInformation form={form} />,
    },
    {
      key: 'permission',
      label: t('PermissionInformation'),
      children: (
        <PermissionInformation
          projects={accountDetails?.projects}
          communities={accountDetails?.communities}
        />
      ),
    },
  ];

  return (
    <Spin spinning={loading}>
      <Form form={form} onFinish={handleSave} layout="vertical">
        <Typography.Title level={4} className="text-center">
          {title}
        </Typography.Title>
        <Collapse items={items} defaultActiveKey={['basic', 'permission']} />
        <Flex justify="end" gap={16} className="mt-base">
          <Button
            onClick={() =>
              onCancel ? onCancel() : form.setFieldsValue(oldData.current)
            }
          >
            {t('Cancel')}
          </Button>
          <Button type="primary" htmlType="submit">
            {t('Save')}
          </Button>
        </Flex>
      </Form>
    </Spin>
  );
};

interface IBasicInformationProps {
  form: FormInstance;
}

const BasicInformation: React.FC<IBasicInformationProps> = ({ form }) => {
  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="fullName"
            label={t('FullName')}
            rules={[
              {
                required: true,
                whitespace: true,
                message: t('RequiredMessage'),
              },
            ]}
          >
            <Input placeholder={t('EnterFullName')} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="jobTitle" label={t('JobTitle')}>
            <JobTitleSelect />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="phoneNumber"
            label={t('PhoneNumber')}
            tooltip={{
              title: t('EitherPhoneNumberOrEmailIsRequired'),
            }}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value || getFieldValue('email')) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t('EitherPhoneNumberOrEmailIsRequired'))
                  );
                },
              }),
              {
                pattern: PHONE_NUMBER_REGEX,
                message: t('PhoneNumberInvalidFormat'),
              },
            ]}
          >
            <Input
              placeholder={t('EnterPhoneNumber')}
              disabled={!!form.getFieldValue('phoneNumber')}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="email"
            label={t('Email')}
            tooltip={{
              title: t('EitherPhoneNumberOrEmailIsRequired'),
            }}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value || getFieldValue('phoneNumber')) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t('EitherPhoneNumberOrEmailIsRequired'))
                  );
                },
              }),
              {
                type: 'email',
                message: t('EmailInvalidFormat'),
              },
            ]}
          >
            <Input
              placeholder={t('EnterEmail')}
              disabled={!!form.getFieldValue('email')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="status"
        label={t('Status')}
        rules={[{ required: true, message: t('RequiredMessage') }]}
        initialValue={AccountStatus.Active}
      >
        <Radio.Group
          options={[
            { label: t('Active'), value: AccountStatus.Active },
            { label: t('Inactive'), value: AccountStatus.Inactive },
          ]}
        />
      </Form.Item>
    </>
  );
};

interface IPermissionInformationProps {
  projects?: { id: number; name: string }[];
  communities?: { id: number; name: string }[];
}

const PermissionInformation: React.FC<IPermissionInformationProps> = ({
  projects,
  communities,
}) => {
  const projectIds = Form.useWatch('projectIds');

  return (
    <>
      <Form.Item name="projectIds" label={t('Project')}>
        <MyProjectSelect mode="multiple" projects={projects} />
      </Form.Item>
      <Form.Item name="communityIds" label={t('Community')}>
        <ProjectCommunitySelect
          projectIds={projectIds}
          communities={communities}
        />
      </Form.Item>
      <Form.Item name="operationRoleIds">
        <RoleGroupTable />
      </Form.Item>
    </>
  );
};

export default UpsertUserAccount;
