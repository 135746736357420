import { Checkbox, DatePicker, Input, Select, TimePicker } from 'antd';
import { Location } from 'components/FPIC/Detail/FormItem';
import { DATE_SLASH_FORMAT, TIME_FORMAT_LITE } from 'constants/dateTime';
import { FormAnswer } from 'interfaces';
import { ImageUpload } from 'components/FormTemplateControl/ImageUpload';
import { Datagrid } from 'components/FormTemplateControl/Datagrid';
import dayjs from 'dayjs';
import { MediaUpload } from 'components/FormTemplateControl/MediaUpload';
import { v4 } from 'uuid';
import CustomizableSelect from 'components/shared/Select/CustomizableSelect';
import { Store } from 'antd/es/form/interface';
import { OTHER_VALUE } from 'constants/common';

const MAX_CHECKBOX_ITEM = 10;

const renderFormControlFromTemplate = (
  item: FormAnswer,
  isReadOnly?: boolean
) => {
  const { fieldType, suggestionValue, value, fieldDescription } = item;
  const placeholder = isReadOnly && !value ? '' : fieldDescription;
  switch (fieldType) {
    case 'InputText':
    case 'INPUT_TEXT':
      return <Input placeholder={placeholder} />;
    case 'TextArea':
    case 'TEXT_AREA':
      return <Input.TextArea placeholder={placeholder} />;
    case 'Dropdown':
    case 'DROPDOWN':
      return <Select options={suggestionValue} placeholder={placeholder} />;
    case 'CustomizableDropdown':
    case 'CUSTOMIZABLE_DROPDOWN':
      const options = suggestionValue;
      if (value && !options?.find(option => option.value === value)) {
        options.push({ label: value, value: value });
      }
      return <CustomizableSelect options={options} placeholder={placeholder} />;
    case 'DatePicker':
    case 'DATE_PICKER':
      return (
        <DatePicker format={DATE_SLASH_FORMAT} placeholder={placeholder} />
      );
    case 'TimePicker':
    case 'TIME_PICKER':
      return <TimePicker format={TIME_FORMAT_LITE} placeholder={placeholder} />;
    case 'CHECKBOX':
    case 'Checkbox': {
      if (suggestionValue.length <= MAX_CHECKBOX_ITEM)
        return (
          <Checkbox.Group
            options={suggestionValue}
            style={{ display: 'grid' }}
          />
        );
      else {
        return (
          <Select
            mode="tags"
            options={suggestionValue}
            placeholder={placeholder}
          />
        );
      }
    }
    case 'ImageFile':
    case 'IMAGE_FILE':
      return <ImageUpload isPrivate />;
    case 'MediaFile':
    case 'MEDIA_FILE':
      return <MediaUpload />;
    case 'Location':
    case 'LOCATION':
      return <Location item={item} mode={'EDIT'} label={null} />;
    case 'Datagrid':
    case 'DATAGRID':
      return <Datagrid item={item} />;
    default:
      return value;
  }
};

const getFormDataFromAnswers = (answers: FormAnswer[]) => {
  let formData: {
    [key: string]: any;
  } = {};
  answers.forEach(answer => {
    switch (answer.fieldType) {
      case 'DATE_PICKER':
      case 'DatePicker':
      case 'TIME_PICKER':
      case 'TimePicker':
        const unixTime = Number(answer.value);
        formData[answer.fieldName] =
          unixTime === 0 ? undefined : dayjs.unix(unixTime);
        break;
      case 'CHECKBOX':
      case 'Checkbox':
      case 'IMAGE_FILE':
      case 'ImageFile':
      case 'MEDIA_FILE':
      case 'MediaFile': {
        let checkedValues: (string | number)[] = [];
        try {
          const answerValues = JSON.parse(answer.value);
          if (Array.isArray(answerValues)) checkedValues = answerValues;
        } catch {
        } finally {
          formData[answer.fieldName] = checkedValues;
        }
        break;
      }
      case 'DATAGRID':
      case 'Datagrid': {
        let dataSource = [];
        try {
          const answerValues = JSON.parse(answer.value);
          if (Array.isArray(answerValues))
            dataSource = answerValues.map(row => ({ uid: v4(), ...row }));
        } catch {
        } finally {
          formData[answer.fieldName] = dataSource;
          break;
        }
      }
      case 'CUSTOMIZABLE_DROPDOWN':
      case 'CustomizableDropdown': {
        formData[answer.fieldName] = answer.value || undefined;
        break;
      }
      default:
        formData[answer.fieldName] = answer.value;
    }
  });
  return formData;
};

const getFormAnswersFromData = (
  answers: FormAnswer[],
  formData: { [key: string]: any }
) => {
  return answers.map(answer => {
    return {
      questionId: answer.questionId,
      fieldName: answer.fieldName,
      value: cleanData(answer.fieldType, formData[answer.fieldName]),
    };
  });
};

const cleanData = (fieldType: FormAnswer['fieldType'], value: any) => {
  switch (fieldType) {
    case 'DATE_PICKER':
    case 'DatePicker':
    case 'TIME_PICKER':
    case 'TimePicker':
      return value ? value.unix().toString() : undefined;
    case 'DATAGRID':
    case 'Datagrid':
      //Remove empty rows
      const rowsWithData = value.filter((rowData: any) => {
        return (
          Object.entries(rowData).filter(([key, value]) => {
            return key !== 'uid' && !!value;
          }).length > 0
        );
      });
      return JSON.stringify(rowsWithData);
    default:
      return typeof value !== 'string' ? JSON.stringify(value) : value;
  }
};

const getCustomizableCheckboxValue = (values: Store, fieldName: string) => {
  const checkboxValues: string[] = values[fieldName];
  return checkboxValues.map(value => {
    const otherValue = values[`${fieldName}${OTHER_VALUE}`]?.trim();
    if (value === OTHER_VALUE && otherValue) {
      return otherValue;
    }
    return value;
  });
};

export default {
  renderFormControlFromTemplate,
  getFormDataFromAnswers,
  getFormAnswersFromData,
  getCustomizableCheckboxValue,
};
