import React from 'react';
import { t } from 'helpers/i18n';
import AppContainer from 'containers/AppLayout/AppContainer';
import { getMetaData } from 'helpers/common';

const Home: React.FC = () => {
  const { title } = getMetaData();
  return <AppContainer title={t('WelcomeText', { title })} />;
};

export default Home;
