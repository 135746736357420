import { DEFAULT_PAGINATION_OFFSET } from 'constants/pagination';
import { cremaConstants, resourceConstants } from 'constants/index';
import { useProjectContext } from 'contexts';
import { userHooks } from 'hooks';
import {
  CremaMeetingRecordStatus,
  ICremaDetail,
  IGetListCremaOrganizationsParams,
  IGetListCremaOrganizationsResponse,
  ICremaOrganization,
} from 'interfaces';
import { useEffect, useState } from 'react';
import { cremaServices } from 'services';
import { ID_DEFAULT } from 'constants/common';

const { APPS, RESOURCES, ACTIONS } = resourceConstants;
const { PROJECT_MANAGER_TEKBONE } = APPS;
const { CREMA_MEETING_RECORD } = RESOURCES;
const { EDIT, APPROVE } = ACTIONS;
const {
  ALLOWED_TO_EDIT_STATUSES,
  ALLOWED_TO_APPROVE_REJECT_STATUSES,
} = cremaConstants;
const { usePermission } = userHooks;

const useCremaMeetingRecordPermissions = (status: CremaMeetingRecordStatus) => {
  const { hasPermission } = usePermission();

  const isAllowedToEdit =
    ALLOWED_TO_EDIT_STATUSES.includes(status) &&
    hasPermission(PROJECT_MANAGER_TEKBONE, CREMA_MEETING_RECORD, EDIT);

  const isAllowedToApproveReject =
    ALLOWED_TO_APPROVE_REJECT_STATUSES.includes(status) &&
    hasPermission(PROJECT_MANAGER_TEKBONE, CREMA_MEETING_RECORD, APPROVE);

  return { isAllowedToEdit, isAllowedToApproveReject };
};

const useSearchOrganizations = () => {
  const [organizations, setOrganizations] = useState<ICremaOrganization[]>([]);
  const [loading, setLoading] = useState(false);
  const { projectId } = useProjectContext();

  const onSearch = async (
    params: Partial<
      Pick<
        IGetListCremaOrganizationsParams,
        'keyword' | 'offset' | 'limit' | 'organizationType'
      >
    >
  ) => {
    let response: IGetListCremaOrganizationsResponse = {
      organizations: [],
      pagination: { offset: 0 },
    };
    try {
      setLoading(true);
      response = await cremaServices.getListCremaOrganizations({
        projectId,
        ...DEFAULT_PAGINATION_OFFSET,
        ...params,
      });
      const { organizations } = response;
      setOrganizations(organizations);
    } finally {
      setLoading(false);
    }
    return response;
  };

  return {
    organizations,
    onSearch,
    loading,
  };
};

const useCremaDetail = (cremaInfo: { id: number }) => {
  const [crema, setCrema] = useState<ICremaDetail>();
  const [warnings, setWarnings] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { projectId } = useProjectContext();

  const getCremaDetail = async () => {
    try {
      setLoading(true);
      const cremaDetail = await cremaServices.getCremaDetail({
        projectId,
        cremaId: cremaInfo.id,
      });
      setCrema(cremaDetail);
    } finally {
      setLoading(false);
    }
  };

  const validateCrema = async () => {
    const { invalidCases } = await cremaServices.validateCrema({
      projectId,
      cremaId: cremaInfo.id,
    });
    setWarnings(invalidCases);
  };

  useEffect(() => {
    if (cremaInfo.id !== ID_DEFAULT) {
      getCremaDetail();
      validateCrema();
    }
  }, [cremaInfo]);

  return { crema, getCremaDetail, loading, warnings, validateCrema };
};

export default {
  useCremaMeetingRecordPermissions,
  useSearchOrganizations,
  useCremaDetail,
};
