import { useQuery } from '@tanstack/react-query';
import { commonConstants } from 'constants/index';
import { BASEMAP_TYPE } from 'constants/map';
import { useProjectContext } from 'contexts';
import { ILegend } from 'interfaces';
import { legendServices } from 'services';

const { QUERY_KEYS } = commonConstants;

const useLegend = (basemapType: BASEMAP_TYPE) => {
  const { projectId } = useProjectContext();

  const { data: legends, isLoading: loading } = useQuery({
    queryKey: [QUERY_KEYS.BASEMAP_LEGENDS, projectId, basemapType],
    queryFn: async (): Promise<ILegend[]> => {
      const result = await legendServices.getLegends({
        projectId,
        basemapType,
      });
      return result.labels || [];
    },
  });

  return {
    loading,
    legends,
  };
};

export default {
  useLegend,
};
