/** Third party libs **/
import {
  Button,
  Card,
  notification,
  Popconfirm,
  Space,
  Typography,
} from 'antd';
import React, { useContext } from 'react';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import classNames from 'classnames';

/** Local libs **/

/** Styles **/
import './SurveyCard.scss';
import { ISurveyForm } from 'interfaces/survey';
import { t } from 'helpers/i18n';
import { CurrentSelectedFarm } from 'components/FarmerManagement/FarmerEnrolment/Context';
import { useFlagsupContext } from 'contexts';
import { userHooks } from 'hooks';
import { useFarmDetailContext } from 'components/FarmerManagement/FarmerEnrolment';
import { FARM_EDITABLE_STATUSES, LOCATION_SURVEY_NAME } from 'constants/farm';
import { mapHelpers } from 'helpers';

/** Interfaces, enum... **/
interface ISurveyCardProps {
  listSurvey?: ISurveyForm[];
  selectedSurveyForm?: ISurveyForm;
  onSelectSurveyForm: (surveyForm: ISurveyForm | undefined) => void;
  setShowDiscussionHistory: React.Dispatch<React.SetStateAction<boolean>>;
  setShowFarmList: React.Dispatch<React.SetStateAction<boolean>>;
}

/** Variables **/
const { Text, Title } = Typography;
const { isValidLocation } = mapHelpers;

/** ------------------------- **/
/** SurveyCard Component */
/** ------------------------- **/
const SurveyCard: React.FC<ISurveyCardProps> = ({
  listSurvey,
  selectedSurveyForm,
  onSelectSurveyForm,
  setShowDiscussionHistory,
  setShowFarmList,
}) => {
  /** States **/
  const [loading, setLoading] = React.useState<boolean>(false);

  /** Hooks **/
  const {
    setShowFarmInfo,
    setShowSurveyReport,
    showFarmInfo,
    setCurrentSurveyLocation,
  } = useContext(CurrentSelectedFarm);
  const { featureFlagsData } = useFlagsupContext();
  const { canEditFarmMappingSurvey } = userHooks.useUserPermissions();
  const { removeSurvey, refresh, farmDetails } = useFarmDetailContext();

  /** Variables **/
  const isCAR2399Enabled = featureFlagsData.CAR_2399?.enabled;

  /** Functions, Events, Actions... **/
  const onSelectNewSurveyForm = (survery: ISurveyForm) => {
    onSelectSurveyForm(survery);
    setShowFarmList(false);
    setShowFarmInfo(false);
  };

  const onShowSurveyReport = () => {
    setShowFarmList(false);
    setShowSurveyReport(true);
    if (showFarmInfo && selectedSurveyForm) setShowFarmInfo(false);
  };

  const isBoundaryPlacemarkSurvey = (survey: ISurveyForm) => {
    return (
      survey.templateFormId === window.config.boundaryPlacemarkSurveyTemplateId
    );
  };

  const canDeleteSurvey = (survey: ISurveyForm) => {
    return (
      farmDetails &&
      FARM_EDITABLE_STATUSES.includes(farmDetails?.farmStatus) &&
      isBoundaryPlacemarkSurvey(survey) &&
      canEditFarmMappingSurvey
    );
  };

  const onRemoveSurvey = async (surveyId: number) => {
    try {
      setLoading(true);
      await removeSurvey(surveyId);
      notification.success({
        message: t('ObjectRemovedSuccessfully', { object: t('Survey') }),
      });
      // Refresh farm data
      refresh();
    } finally {
      setLoading(false);
    }
  };

  const highlightSurveyLocation = (survey: ISurveyForm) => {
    const locationAnswer = survey.answers?.find(
      answer => answer.fieldName === LOCATION_SURVEY_NAME
    );
    if (locationAnswer?.value) {
      const location = JSON.parse(locationAnswer.value || '{}');
      if (isValidLocation(location))
        setCurrentSurveyLocation({
          formSurveyId: survey.formSubmissionId,
          latitude: location.latitude,
          longitude: location.longitude,
          draggable: false,
        });
    }
  };
  /** Effects **/

  /** Elements **/
  return (
    <Card
      title="Survey"
      extra={
        <div className="survey-card-extra">
          <Title level={5} className="mb-0 d-flex align-items-center">
            {t('Survey')}
          </Title>
          {isCAR2399Enabled && (
            <Button type="link" className="p-0" onClick={onShowSurveyReport}>
              {t('ViewFarmBoudarySurveyReport')}
            </Button>
          )}
        </div>
      }
    >
      {listSurvey?.map(survey => {
        return (
          <Card
            key={survey.formSubmissionId}
            className={classNames(
              'surveySubmission',
              'mb-half',
              'd-flex',
              'w-100',
              selectedSurveyForm?.formSubmissionId ===
                survey.formSubmissionId && 'selected'
            )}
            styles={{
              body: {
                width: '100%',
                padding: 0,
              },
            }}
            onClick={() => highlightSurveyLocation(survey)}
          >
            <div className={classNames('d-flex justify-content-between w-100')}>
              <Text className="d-block">
                {survey.formName ||
                  `${t('SurveySubmission')} ${survey.formSubmissionId}`}
              </Text>
              <Space size="small">
                {canDeleteSurvey(survey) && (
                  <Popconfirm
                    title={t('DeleteSurveyConfirmation')}
                    onConfirm={() => onRemoveSurvey(survey.formSubmissionId)}
                    cancelButtonProps={{ disabled: loading }}
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      type="link"
                      size="small"
                      disabled={loading}
                      danger
                    />
                  </Popconfirm>
                )}
                <Button
                  icon={<EyeOutlined />}
                  size="small"
                  type="link"
                  onClick={() => {
                    selectedSurveyForm?.formSubmissionId ===
                    survey.formSubmissionId
                      ? onSelectSurveyForm(undefined)
                      : onSelectNewSurveyForm(survey);
                    setShowDiscussionHistory(false);
                  }}
                />
              </Space>
            </div>
          </Card>
        );
      })}
    </Card>
  );
};

export { SurveyCard };
export default SurveyCard;
