import { Button, notification, Row, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ContentBlock from 'components/shared/ContentBlock';
import { t } from 'helpers/i18n';
import React from 'react';
import { DoubleRightOutlined } from '@ant-design/icons';
import { IUser, UserProfileStatus } from 'interfaces';
import { SwitchPopconfirm } from 'components/shared/Popconfirm';
import { userManagementServices } from 'services';
import { useFlagsupContext, useProjectContext } from 'contexts';

interface IUserRowData extends Omit<IUser, 'userOrganizationRoles'> {
  key: string;
  rowSpan: number;
  roleName: string;
  organizationName: string;
}

interface UserListTableProps {
  dataSource: IUser[];
  loading: boolean;
  shouldLoadMore: boolean;
  onLoadMore: () => void;
  refreshData: () => void;
}

const UserListTable: React.FC<UserListTableProps> = ({
  dataSource,
  loading,
  shouldLoadMore,
  onLoadMore,
  refreshData,
}) => {
  const { projectId } = useProjectContext();
  const { featureFlagsData } = useFlagsupContext();
  const isCARHCM507Enabled = featureFlagsData.CARHCM_507?.enabled;

  const getColumns = () => {
    const columns: ColumnsType<IUserRowData> = [
      {
        title: t('Telephone'),
        dataIndex: 'phoneNumber',
        onCell: record => ({
          rowSpan: record.rowSpan,
        }),
      },
      {
        title: t('UserRole'),
        dataIndex: 'roleName',
      },
      {
        title: t('AdministrativeArea'),
        dataIndex: 'organizationName',
      },
      {
        title: t('UserProfileStatus'),
        dataIndex: 'profileStatus',
        onCell: record => ({
          rowSpan: record.rowSpan,
        }),
        render: status =>
          status === UserProfileStatus.Verfied ? (
            <Tag color="success" style={{ fontSize: 'small' }}>
              {t('Verified')}
            </Tag>
          ) : (
            <Tag color="default" style={{ fontSize: 'small' }}>
              {t('NotVerified')}
            </Tag>
          ),
      },
    ];

    if (isCARHCM507Enabled) {
      columns.push({
        title: t('AccountStatus'),
        dataIndex: 'isAccountActive',
        onCell: record => ({
          rowSpan: record.rowSpan,
        }),
        render: (isAccountActive, record) => (
          <SwitchPopconfirm
            title={null}
            description={
              isAccountActive
                ? t('AreYouSureToDisableAccountWithPhoneNumber', {
                    phoneNumber: record.phoneNumber,
                  })
                : t('AreYouSureToEnableAccountWithPhoneNumber', {
                    phoneNumber: record.phoneNumber,
                  })
            }
            onConfirm={async () => {
              await userManagementServices.updateAccountStatus({
                projectId,
                userId: record.id,
                isActive: !isAccountActive,
              });
              notification.success({
                message: t('UpdateAccountStatusSuccessfully'),
              });
              refreshData();
            }}
            switchProps={{ defaultChecked: isAccountActive }}
          />
        ),
      });
    }

    return columns;
  };

  // Convert user data into row data
  const data = dataSource.reduce((acc, user) => {
    if (!user.userOrganizationRoles) {
      acc.push({
        key: `${user.id}`, // Unique key for each row
        id: user.id,
        rowSpan: 1,
        phoneNumber: user.phoneNumber,
        profileStatus: user.profileStatus,
        isAccountActive: user.isAccountActive,
        roleName: '',
        organizationName: '',
      });

      return acc;
    }

    user.userOrganizationRoles.forEach((role, index) => {
      acc.push({
        key: `${user.id}_${index}`, // Unique key for each row
        id: user.id,
        rowSpan: index === 0 ? user.userOrganizationRoles.length : 0,
        phoneNumber: user.phoneNumber,
        profileStatus: user.profileStatus,
        isAccountActive: user.isAccountActive,
        ...role,
      });
    });
    return acc;
  }, [] as IUserRowData[]);

  return (
    <ContentBlock>
      <Table
        rowKey="key"
        bordered
        columns={getColumns()}
        loading={loading}
        dataSource={data}
        pagination={false}
        data-testid="user-table"
      />
      {shouldLoadMore && (
        <Row justify="center" className="my-half">
          <Button
            type="link"
            className="w-100"
            onClick={onLoadMore}
            loading={loading}
            data-testid="load-more-button"
          >
            {t('LoadMore')}
            <DoubleRightOutlined
              style={{
                transform: 'rotate(90deg)',
              }}
            />
          </Button>
        </Row>
      )}
    </ContentBlock>
  );
};

export default UserListTable;
