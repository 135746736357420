import { ILatLng, IPolygonMap } from 'interfaces';
import { degreesToRadians } from './math';
import { BASEMAP_LAYERS, EARTH_RADIUS_KM, VIEWBOX_SCALES } from 'constants/map';
import { LayerType } from 'components/shared/Map';
import { LatLng, Map } from 'leaflet';

export const generateDrawPointsMultiLayer = (
  polygonsPoints: ILatLng[][]
): ILatLng[][] => {
  return polygonsPoints.map(polygonMapPoints =>
    generateDrawPoints(polygonMapPoints)
  );
};

export const generateDrawPoints = (points: ILatLng[]) => {
  const realPoints = points.filter(p => !p.temporary);
  const length = realPoints.length;
  const lastIndex = length - 1;
  const tempPoints: ILatLng[] = [];

  for (let i = 0; i < lastIndex; i++) {
    tempPoints.push(realPoints[i]);
    tempPoints.push(
      new ILatLng(
        (realPoints[i].lat + realPoints[i + 1].lat) / 2,
        (realPoints[i].lng + realPoints[i + 1].lng) / 2
      )
    );
    tempPoints[tempPoints.length - 1].temporary = true;
  }
  tempPoints.push(realPoints[lastIndex]);

  tempPoints.push(
    new ILatLng(
      (realPoints[0].lat + realPoints[lastIndex].lat) / 2,
      (realPoints[0].lng + realPoints[lastIndex].lng) / 2
    )
  );
  tempPoints[tempPoints.length - 1].temporary = true;

  return tempPoints;
};

export const sphericalDistance = (coord1: ILatLng, coord2: ILatLng): number => {
  const dLat = degreesToRadians(coord2.lat - coord1.lng);
  const dLon = degreesToRadians(coord2.lng - coord1.lng);
  const lat1 = degreesToRadians(coord1.lat);
  const lat2 = degreesToRadians(coord2.lat);

  const haversine =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const angularDistance =
    2 * Math.atan2(Math.sqrt(haversine), Math.sqrt(1 - haversine));
  return EARTH_RADIUS_KM * angularDistance;
};

export const getPolygonArea = (coordinates: ILatLng[]): number => {
  let area = 0;
  const numCoords = coordinates.length;

  for (let i = 0; i < numCoords; i++) {
    const j = (i + 1) % numCoords;
    const coord1 = coordinates[i];
    const coord2 = coordinates[j];
    area +=
      degreesToRadians(coord2.lng - coord1.lng) *
      (2 +
        Math.sin(degreesToRadians(coord1.lat)) +
        Math.sin(degreesToRadians(coord2.lat)));
  }

  area = (area * EARTH_RADIUS_KM ** 2) / 2;
  return Math.abs(area) * 100;
};

export const getPolygonSignedArea = (coordinates: ILatLng[]): number => {
  let area = 0;
  const numCoords = coordinates.length;
  for (let i = 0; i < numCoords - 1; i++) {
    area +=
      coordinates[i].lat * coordinates[i + 1].lng -
      coordinates[i + 1].lat * coordinates[i].lng;
  }

  return area / 2;
};

export const getPolygonCentroid = (coordinates: ILatLng[]) => {
  // equation reference https://www.omnicalculator.com/math/centroid
  let centroidX = 0;
  let centroidY = 0;
  const numCoords = coordinates.length;
  const area = getPolygonSignedArea(coordinates);

  for (let i = 0; i < numCoords - 1; i++) {
    centroidX +=
      (coordinates[i].lat + coordinates[i + 1].lat) *
      (coordinates[i].lat * coordinates[i + 1].lng -
        coordinates[i + 1].lat * coordinates[i].lng);
    centroidY +=
      (coordinates[i].lng + coordinates[i + 1].lng) *
      (coordinates[i].lat * coordinates[i + 1].lng -
        coordinates[i + 1].lat * coordinates[i].lng);
  }
  centroidX = centroidX / (6 * area);
  centroidY = centroidY / (6 * area);
  return { lat: centroidX, lng: centroidY };
};

export const translateCentroidByZoomLevel = (zoomLevel: number) => {
  switch (zoomLevel) {
    case 15:
      return 0.06;
    case 16:
      return 0.035;
    case 17:
      return 0.02;
    case 18:
      return 0.01;
    case 19:
      return 0.0045;
    case 20:
      return 0.002;
    case 21:
      return 0.001;
    case 22:
      return 0.0003;
    case 23:
      return 0.0001;
    case 24:
      return 0.00008;
    case 25:
      return 0.00006;
    case 26:
      return 0.00004;
    case 27:
      return 0.00003;
    default:
      return 0;
  }
};

const getSearchBoxObjectFromMap = (map: Map) => {
  const mapBounds = map.getBounds();
  var currScale = VIEWBOX_SCALES[0];
  VIEWBOX_SCALES.forEach(scale => {
    if (map.getZoom() > scale.zoomLevel) currScale = scale;
  });
  return {
    searchLatitudeMin: mapBounds.getSouthWest().lat - currScale.offset,
    searchLatitudeMax: mapBounds.getNorthWest().lat + currScale.offset,
    searchLongitudeMin: mapBounds.getSouthWest().lng - currScale.offset,
    searchLongitudeMax: mapBounds.getSouthEast().lng + currScale.offset,
  };
};

// cause in leaflet the map you can drag infinity so you need check longtitude is valid
const isLngInvalid = (lngValue: number) => {
  return lngValue > 180 || lngValue < -180;
};

const getBaseMapLayerType = (selectedLayer: LayerType[]) =>
  BASEMAP_LAYERS.find(layer => selectedLayer.includes(layer));

const getPolygonPositions = (polygonMap: IPolygonMap[]) =>
  polygonMap.map(position => [position.latitude, position.longitude]);

const getPolygonsMapToSendAPI = (polygonsPoints: LatLng[][]) => {
  const convertedPolygons = polygonsPoints.map(polygonPoints => ({
    polygonMap: polygonPoints.map(point => {
      return {
        latitude: point.lat,
        longitude: point.lng,
      };
    }),
  }));
  const isMultiplePolygon = convertedPolygons.length > 1;
  return isMultiplePolygon
    ? {
        multiplePolygonMap: convertedPolygons,
      }
    : {
        polygonMap: convertedPolygons?.[0].polygonMap || [],
      };
};

const isValidLocation = (object: any) => {
  if (typeof object !== 'object' || object === null) {
    return false;
  }

  const { latitude, longitude } = object;
  if (typeof latitude !== 'number' || typeof longitude !== 'number') {
    return false;
  }

  return true;
};

const convertRadiusToPixel = (
  radius: number,
  map: Map,
  leafletContainer: React.RefObject<HTMLDivElement>
) => {
  const searchBox = getSearchBoxObjectFromMap(map);
  const leafletContainerWidth = leafletContainer.current?.offsetWidth || 1;
  const leafletContainerWidthDistance = sphericalDistance(
    {
      lat: searchBox.searchLatitudeMin,
      lng: searchBox.searchLongitudeMin,
    } as ILatLng,
    {
      lat: searchBox.searchLatitudeMin,
      lng: searchBox.searchLongitudeMax,
    } as ILatLng
  );

  const pixelRatio = leafletContainerWidthDistance / leafletContainerWidth;
  return radius / pixelRatio;
};

export default {
  sphericalDistance,
  getPolygonArea,
  getPolygonCentroid,
  translateCentroidByZoomLevel,
  getBaseMapLayerType,
  getSearchBoxObjectFromMap,
  isLngInvalid,
  getPolygonPositions,
  getPolygonsMapToSendAPI,
  isValidLocation,
  convertRadiusToPixel,
};
