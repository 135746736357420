import { trackFPICUploadFile } from 'helpers/tracker/trackFPIC';
import { UploadFileType } from 'interfaces';
import { useState } from 'react';
import { fileServices } from 'services';

type UploadOptions = {
  type?: UploadFileType;
  fileContentType?: string;
  tracker?: ReturnType<typeof trackFPICUploadFile>;
  isPrivate?: boolean;
  thumbnail?: boolean;
};

const useUploadFile = (fileType?: UploadFileType) => {
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);

  const uploadFile = async (file: File, options?: UploadOptions) => {
    const { type, fileContentType, isPrivate, tracker, thumbnail } =
      options || {};
    const uploadType = type || fileType;

    if (!uploadType) return null;

    let result;
    try {
      setUploading(true);

      const formData = new FormData();
      if (fileContentType) {
        formData.append(
          'file',
          new Blob([file], { type: fileContentType }),
          file.name
        );
      } else formData.append('file', file);
      formData.append('cloud', 'true');
      if (isPrivate) {
        formData.append('isPrivate', 'true');
      }

      tracker?.onStartUpload(file);
      result = await fileServices.uploadFile(uploadType, formData);

      tracker?.onSuccessUpload(file, result?.data?.url);
    } catch (e) {
      tracker?.onErrorUpload(file, (e as any).toString());
    } finally {
      setUploading(false);
    }
    return type === 'image' && thumbnail
      ? result?.data?.image_url
      : result?.data?.url;
  };

  const googleUploadFileUrl = async (
    file: File,
    tracker?: ReturnType<typeof trackFPICUploadFile>
  ) => {
    let url = null;
    try {
      setUploading(true);

      tracker?.onStartUpload(file);

      const { location } = await fileServices.getGoogleLocationHost(file);
      if (location) url = await fileServices.uploadGoogleHost(file, location);
    } catch (e) {
      tracker?.onErrorUpload(file, (e as any).toString());
    } finally {
      setUploading(false);
    }
    return url;
  };

  return {
    uploading,
    loading,
    uploadFile,
    setLoading,
    setUploading,
    googleUploadFileUrl,
  };
};

export default { useUploadFile };
