import { LeafletMarkerIcon } from 'components/shared/Map';
import { IManualSamplingPlot } from 'interfaces';
import { LeafletEvent } from 'leaflet';
import React, { useEffect, useRef } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { useDispatch } from 'react-redux';
import { updateManualSamplingPlots } from 'redux/features/samplingPlot';
import { AppDispatch } from 'redux/store';
import { ManualAddSamplingPlotForm } from './ManualAddSamplingPlotForm';

interface IManualAddSplingPlotMarkerProps {
  index: number;
  samplingPlot: IManualSamplingPlot;
  isActive?: boolean;
}

const ManualAddSplingPlotMarker: React.FC<IManualAddSplingPlotMarkerProps> = ({
  index,
  samplingPlot,
  isActive,
}) => {
  const markerRef = useRef<any>(null);
  const dispatch: AppDispatch = useDispatch();

  const handleMarkerDragEnd = (
    index: number,
    event: LeafletEvent,
    radius: number
  ) => {
    const { lat, lng } = event.target.getLatLng();
    dispatch(
      updateManualSamplingPlots({
        lat: lat,
        lng: lng,
        radius: radius,
        index: index,
      })
    );
  };

  useEffect(() => {
    if (isActive) markerRef.current?.openPopup();
  }, [isActive]);

  return (
    <Marker
      position={samplingPlot.center}
      draggable
      icon={LeafletMarkerIcon.DEFAULT.NO_DATA}
      key={index}
      eventHandlers={{
        dragend: e => {
          handleMarkerDragEnd(index, e, samplingPlot.radius);
          markerRef.current?.openPopup();
        },
      }}
      ref={markerRef}
    >
      <Popup
        minWidth={350}
        className="sampling-plot-point-popup"
        autoClose={false}
      >
        <ManualAddSamplingPlotForm
          location={samplingPlot.center}
          index={index}
          plotRadius={samplingPlot.radius}
        />
      </Popup>
    </Marker>
  );
};

export { ManualAddSplingPlotMarker };
export default ManualAddSplingPlotMarker;
