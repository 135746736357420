import { Button } from 'antd';
import { notification } from 'antd';
import { t } from 'helpers/i18n';
import { ISubmissionFilters } from 'interfaces';
import SubmissionExportIcon from './SubmissionExportIcon';
import { CONTENT_TYPE } from 'constants/http/headers';
import { downloadURL } from 'utils/helper';
import { submissionServices } from 'services';
import { useState } from 'react';

interface ISubmissionExport {
  filter: ISubmissionFilters;
  dataLength: number;
  isFetching: boolean;
}

const SubmissionExport: React.FC<ISubmissionExport> = ({
  filter,
  dataLength,
  isFetching,
}) => {
  const [loading, setLoading] = useState(false);
  const isDisable =
    !filter.projectId ||
    !(filter.submissionStatus === 'selected') ||
    !dataLength ||
    isFetching;

  const onExport = async () => {
    const filename = `Project_${filter.projectId}__submissions.xlsx`;
    try {
      if (!isDisable) {
        setLoading(true);
        const xlsxContent = await submissionServices.exportSubmissions({
          projectId: filter.projectId,
          userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });

        const blob = new Blob([xlsxContent || ''], {
          type: CONTENT_TYPE.APP_EXCEL_2007_ABOVE,
        });
        const url = URL.createObjectURL(blob);
        downloadURL(url, filename);
        URL.revokeObjectURL(url);
      }
    } catch (err) {
      notification.error({
        message: t('Error'),
        description: t('SomethingWentWrong'),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '10px',
      }}
    >
      <Button
        icon={<SubmissionExportIcon width={24} height={24} />}
        type="primary"
        style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
        disabled={isDisable}
        onClick={onExport}
        loading={loading}
        data-testid="btn-export"
      >
        {t('Export')}
      </Button>
    </div>
  );
};

export default SubmissionExport;
