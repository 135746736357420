import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PAGINATION_OFFSET_LIMIT_5 } from 'constants/pagination';
import { IPaginationOffsetResponse, ISnapTreeItem } from 'interfaces';
import { RootState } from 'redux/store';

interface IExpandedRows {
  treeId?: number;
  snapshotTreeId: React.Key[];
}

// Define a type for the slice state
export interface ISubmissionManagementState {
  gettingTrees: boolean;
  trees: ISnapTreeItem[];
  expandedRows: IExpandedRows;
  pagination: IPaginationOffsetResponse;
  snapshotTreeItem?: ISnapTreeItem;
  submissionId?: number;
}

// Define the initial state using that type
const initialState: ISubmissionManagementState = {
  gettingTrees: false,
  trees: [],
  expandedRows: { treeId: undefined, snapshotTreeId: [] },
  pagination: PAGINATION_OFFSET_LIMIT_5,
  snapshotTreeItem: undefined,
  submissionId: undefined,
};

export const selectGettingTrees = (state: RootState) =>
  state.submissionManagement.gettingTrees;

export const selectTrees = (state: RootState) =>
  state.submissionManagement.trees;

export const selectExpandedRows = (state: RootState) =>
  state.submissionManagement.expandedRows;

export const selectPagination = (state: RootState) =>
  state.submissionManagement.pagination;

export const selectSnapshotTreeItem = (state: RootState) =>
  state.submissionManagement.snapshotTreeItem;

export const selectSubmissionId = (state: RootState) =>
  state.submissionManagement.submissionId;

export const submissionSlice = createSlice({
  name: 'submissionManagement',
  initialState,
  reducers: {
    setGettingTrees: (state, action: PayloadAction<boolean>) => {
      state.gettingTrees = action.payload;
    },
    // update whole trees
    setTrees: (state, action: PayloadAction<ISnapTreeItem[]>) => {
      state.trees = action.payload;
    },
    // update single tree (when expand)
    updateTree: (
      state,
      action: PayloadAction<{ treeId: number; newTree: ISnapTreeItem }>
    ) => {
      const { treeId, newTree } = action.payload;
      const existedTreeIndex = state.trees.findIndex(
        item => item.snapshotTreeId === treeId
      );
      if (existedTreeIndex !== -1) state.trees[existedTreeIndex] = newTree;
    },
    setExpandedRows: (state, action: PayloadAction<IExpandedRows>) => {
      state.expandedRows = action.payload;
    },
    setPagination: (
      state,
      action: PayloadAction<IPaginationOffsetResponse>
    ) => {
      state.pagination = action.payload;
    },
    setSnapshotTreeItem: (
      state,
      action: PayloadAction<ISnapTreeItem | undefined>
    ) => {
      state.snapshotTreeItem = action.payload;
    },
    setSubmissionId: (state, action: PayloadAction<number | undefined>) => {
      state.submissionId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setGettingTrees,
  setTrees,
  updateTree,
  setExpandedRows,
  setPagination,
  setSnapshotTreeItem,
  setSubmissionId,
} = submissionSlice.actions;

export default submissionSlice.reducer;
