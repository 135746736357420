import { requestServices } from 'services';
import { getData, getResult } from 'helpers/request';
import {
  IPlotFilters,
  IGetPlotResponse,
  IGetPlotSubmissionParams,
  IGetPlotSubmissionResponse,
  IGetSamplingModuleReponse,
  ICreateSamplingPlotManualParams,
  ICreateSamplingPlotManualResponse,
} from 'interfaces';

const { projectManagerClient } = requestServices;

/**
 * @deprecated since July 2024.
 */
const getPlots = (params: IPlotFilters): Promise<IGetPlotResponse> => {
  return projectManagerClient.get('/api/v1/load-plot-list', {
    params,
  });
};

const getPlotsSubmission = (
  params: IGetPlotSubmissionParams
): Promise<IGetPlotSubmissionResponse> => {
  return projectManagerClient
    .get('/tekbone/api/v1/load-plot-list', {
      params,
    })
    .then(getData);
};

const getSamplingModule = (
  projectId: number
): Promise<IGetSamplingModuleReponse> => {
  return projectManagerClient
    .get(`/tekbone/api/v1/projects/${projectId}/sampling-modules`)
    .then(getData);
};

const importPlots = (formData: FormData) => {
  return projectManagerClient.post(
    `/tekbone/api/v1/plots/upload-sampling-plot`,
    formData
  );
};

const createPlotManually = (
  params: ICreateSamplingPlotManualParams
): Promise<ICreateSamplingPlotManualResponse> => {
  return projectManagerClient
    .post(`/tekbone/api/v1/plots`, params)
    .then(getResult);
};

export default {
  getPlots,
  getPlotsSubmission,
  getSamplingModule,
  importPlots,
  createPlotManually,
};
