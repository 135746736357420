import { DEFAULT_APPOINTMENT_STATUSES } from 'constants/farmer';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constants/pagination';
import {
  IGetUserAppointmentParams,
  IPaginationResponse,
  IUpsertUserAppointment,
  IUserAppointment,
} from 'interfaces';
import { useMemo, useState } from 'react';
import { userAppointmentServices } from 'services';
import { useAbortController } from './axios';

const useUserAppointment = (projectId: number) => {
  const [loading, setLoading] = useState<boolean>(false);

  const upsertUserAppointment = async (params: IUpsertUserAppointment) => {
    try {
      setLoading(true);
      return await userAppointmentServices.upsertUserAppointment({
        ...params,
        projectId,
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    upsertUserAppointment,
  };
};

const useUserAppointments = (
  projectId: number,
  participantIds?: number[],
  keyword?: string
) => {
  const DEFAULT_PARAMS: IGetUserAppointmentParams = {
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    projectId,
    participantIds,
    keyword,
  };

  const [params, setParams] = useState<IGetUserAppointmentParams>(
    DEFAULT_PARAMS
  );
  const [appointments, setAppointments] = useState<IUserAppointment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<
    Omit<IPaginationResponse, 'totalPages'>
  >({
    pageSize: DEFAULT_PARAMS.pageSize,
    total: 0,
    page: DEFAULT_PARAMS.page,
  });
  const { cancelPreviousRequest, newAbortSignal } = useAbortController();

  const getUserAppointments = async (
    keyword?: string,
    participantIds?: number[]
  ) => {
    try {
      const queryParams = params ?? DEFAULT_PARAMS;
      setLoading(true);
      cancelPreviousRequest();
      const result = await userAppointmentServices.getListUserAppointment(
        {
          ...queryParams,
          keyword,
          participantIds,
          statuses: DEFAULT_APPOINTMENT_STATUSES,
          myAppointment: false,
        },
        { signal: newAbortSignal() }
      );
      setAppointments(result.userAppointments || []);
      setPagination({
        page: result.pagination.page ?? DEFAULT_PAGE,
        pageSize: result.pagination.pageSize ?? DEFAULT_PAGE_SIZE,
        total: result.pagination.total ?? 0,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = async (keyword?: string, participantIds?: number[]) => {
    if (!loading && !isLoadedAll) {
      try {
        setLoading(true);
        const result = await userAppointmentServices.getListUserAppointment(
          {
            page: pagination.page + 1,
            pageSize: pagination.pageSize,
            projectId,
            keyword,
            participantIds,
            statuses: DEFAULT_APPOINTMENT_STATUSES,
            myAppointment: false,
          },
          {
            signal: newAbortSignal(),
          }
        );
        setAppointments([...appointments, ...(result.userAppointments || [])]);
        setPagination({
          page: result.pagination.page ?? DEFAULT_PAGE,
          pageSize: result.pagination.pageSize ?? DEFAULT_PAGE_SIZE,
          total: result.pagination.total ?? 0,
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const isLoadedAll = useMemo(() => {
    if (loading) return false;
    return pagination.page * pagination.pageSize >= pagination.total;
  }, [pagination, loading]);

  const refresh = (keyword?: string, participantIds?: number[]) => {
    setAppointments([]);
    getUserAppointments(keyword, participantIds);
  };

  return {
    params,
    setParams,
    appointments,
    getUserAppointments,
    pagination,
    loading,
    loadMore,
    isLoadedAll,
    refresh,
    setAppointments,
  };
};

export default {
  useUserAppointment,
  useUserAppointments,
};
