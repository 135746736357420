import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { requestHelpers } from 'helpers';
import { userServices } from 'services';
import { localizationHelpers } from 'helpers';

const { apiServices } = window.config;
const { getCurrentLanguage } = localizationHelpers;
const TOKEN_TYPE = 'Bearer';
const LOCAL_HOST = 'http://localhost:3000';

const projectManagerClient = axios.create({
  baseURL: apiServices.projectManager,
});
const volunteerBFFClient = axios.create({
  baseURL: apiServices.volunteerBFF,
});
const fileServiceClient = axios.create({
  baseURL: apiServices.fileService,
});
const stnServiceClient = axios.create({
  baseURL: apiServices.stnService,
});
const fpsBFFClient = axios.create({
  baseURL: apiServices.fpsBFF,
});

const mockClient = axios.create({ baseURL: LOCAL_HOST });
const getAuthorization = () => {
  return userServices.isLoggedIn()
    ? `${TOKEN_TYPE} ${userServices.getAccessToken()}`
    : '';
};

// Do something before request is sent
const requestInterceptor = (request: InternalAxiosRequestConfig) => {
  request.headers['Authorization'] = getAuthorization();
  request.headers['Accept-Language'] = getCurrentLanguage();
  return request;
};

// Any status code that lie within the range of 2xx cause this function to trigger
const responseSuccessInterceptor = (response: AxiosResponse) => {
  // Do something with response data
  return response;
};

// Any status codes that falls outside the range of 2xx cause this function to trigger
const responseErrorInterceptor = (error: AxiosError) => {
  // Do something with response error
  requestHelpers.handleResponseError(error);
  return Promise.reject(error);
};

const clients = [
  projectManagerClient,
  volunteerBFFClient,
  fileServiceClient,
  mockClient,
  fpsBFFClient,
];

const ignoreErrorClients = [stnServiceClient];

clients.forEach(client => {
  client.interceptors.request.use(requestInterceptor);
  client.interceptors.response.use(
    responseSuccessInterceptor,
    responseErrorInterceptor
  );
});

ignoreErrorClients.forEach(client => {
  client.interceptors.request.use(requestInterceptor);
  client.interceptors.response.use(responseSuccessInterceptor);
});

export default {
  projectManagerClient,
  volunteerBFFClient,
  mockClient,
  fileServiceClient,
  stnServiceClient,
  fpsBFFClient,
};
