import IFrameContainer from 'containers/shared/IFrameContainer';
import { reportHooks } from 'hooks';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

interface IFrameContainerProps extends RouteComponentProps {
  iFrameSrc: string;
}

const WbInternalReport: React.FC<IFrameContainerProps> = props => {
  const { generateInternalReport } = reportHooks.useWbReport();
  const iFrameSrc = generateInternalReport();
  return (
    <>{iFrameSrc && <IFrameContainer {...props} iFrameSrc={iFrameSrc} />}</>
  );
};

export default WbInternalReport;
