import queryString from 'query-string';
import { rest } from 'msw';
import { SERVER_ROUTES } from './apiRoute';
import {
  IApiResponse,
  IAutoGeneratePlotsResponse,
  ICreateUpdateProjectResponse,
  IGetAllPlotsOnMapResponse,
  IGetAllProjectResponse,
  IPolygon,
  IPolygonResponse,
  IProjectDeleteResponse,
  IProjectDetails,
  IUploadPolygonResponse,
  IUpsertPolygonResponse,
  IUserProject,
} from 'interfaces';

const projectMock = {
  projectTitle: 'Ba Vi National Park',
  organizationName: 'Ba Vi National Park Ha Noi',
  location: 'Vietnam',
  telephone: '0123456789',
  email: 'abc@gmail.com',
  website: 'carbon.com',
  description: 'Project of planting trees in Hanoi city',
  totalPolygons: 1,
  totalPlots: 1,
  totalArea: 15.6,
  creationDate: '2022-09-27T07:59:30.406Z',
  isDeletable: true,
  totalCarbonPerYear: 123.5,
  totalCarbonStandardDeviation: 2.34,
};

const createProjectMock = {
  projectId: 1,
  projectTitle: 'Ba Vi National Park',
  organizationName: 'Ba Vi National Park Ha Noi',
  location: 'Vietnam',
  telephone: '0123456789',
  email: 'abc@gmail.com',
  website: 'carbon.com',
  description: 'Project of planting trees in Hanoi city',
};

const FORMULA_EXPLANATION =
  'King; Schnell; R; 1972; Biomass estimates of black oak tree components; Tech; Note B1; Norris; TN; Tennessee Valley Authority; Division of Forestry; Fisheries; and Wildlife Development;';

const allProject = new Array(100).fill(1).map((_, id) => {
  const newProject = { ...projectMock } as any;
  newProject['projectId'] = id;
  newProject['projectTitle'] = `Project ${id}`;
  return newProject;
});

export const handlersProjects = [
  rest.get(SERVER_ROUTES.PROJECT.GET_ALL_PROJECT, (req, res, ctx) => {
    let { page, pageSize, searchString } = queryString.parse(
      req.url.search
    ) as any;
    if (!page) page = 1;
    if (!pageSize) pageSize = 10;

    const searchProject = allProject.filter(function(project) {
      let query = (searchString || '') as string;

      return (
        project.projectTitle.includes(query) ||
        project.organizationName.includes(query)
      );
    });

    const startIndex = (parseInt(page) - 1) * parseInt(pageSize);
    const endIndex = startIndex + parseInt(pageSize!);

    const filterProject = searchProject.slice(startIndex, endIndex);

    return res(
      ctx.json({
        code: 0,
        traceId: 'string',
        message: 'string',
        data: {
          projects: filterProject,
          pagination: {
            page: page,
            pageSize: pageSize,
            total: searchProject.length,
          },
        },
      })
    );
  }),
  rest.post(SERVER_ROUTES.PROJECT.CREATE_PROJECT, (req, res, ctx) => {
    const { projectTitle } = JSON.parse(
      req.body?.toString().length ? req.body.toString() : ''
    ) as Partial<IProjectDetails>;
    if (projectTitle === '500') return res(ctx.status(500));
    if (projectTitle === '400') return res(ctx.status(400));

    return res(
      ctx.json({ data: createProjectMock, code: 200, traceId: 'string' })
    );
  }),

  rest.post(SERVER_ROUTES.PROJECT.SELECT_SUBMISSION, (req, res, ctx) => {
    return res(ctx.json({ code: 0, message: 'Success' }));
  }),

  rest.delete(SERVER_ROUTES.PROJECT.DELETE_PROJECT, (req, res, ctx) => {
    if (+req.params.projectId < 10) return res(ctx.status(400));
    else return res(ctx.json({ code: 0, message: 'Success' }));
  }),

  rest.get(SERVER_ROUTES.PROJECT.GET_PROJECT_DETAIL, (req, res, ctx) => {
    const projectDetail = {
      projectId: +req.params.projectId,
      ...projectMock,
      formularExplanation: FORMULA_EXPLANATION,
    };
    return res(
      ctx.json({
        data: projectDetail,
        code: 0,
        message: 'Success',
        traceId: 'string',
      })
    );
  }),
];

const getProjects = (): Promise<{ projects: IUserProject[] }> => {
  return new Promise(resolve => {
    setTimeout(
      () =>
        resolve({
          projects: [
            {
              projectId: 1,
              projectTitle: 'Kwahu',
            },
            {
              projectId: 275,
              projectTitle: 'Sidama',
            },
            {
              projectId: 276,
              projectTitle: 'Kakum',
            },
            {
              projectId: 409,
              projectTitle: 'Bia',
            },
          ],
        }),
      5000
    );
  });
};

const addUserToProject = () => Promise.resolve({});

const projectData: IProjectDetails = {
  creationDate: '',
  description: '',
  email: 'user@gmail.com',
  formularExplanation: '',
  isDeletable: true,
  location: '',
  organizationName: '',
  projectAreaBoundary: [{ latitude: 1, longitude: 2 }],
  projectId: 1,
  projectTitle: 'kwahu',
  standard: 'GOLD_STANDARD',
  telephone: '0123456789',
  totalArea: 123,
  totalCarbonPerYear: 321,
  totalCarbonStandardDeviation: 111,
  totalPlots: 222,
  totalPolygons: 2,
  website: 'teko.vn',
  samplingModuleTypes: [],
};

const getProjectList = (): Promise<IGetAllProjectResponse> =>
  Promise.resolve({
    pagination: { page: 1, pageSize: 10, total: 1 },
    projects: [
      {
        ...projectData,
      },
    ],
  });

const getProjectPolygons = (): Promise<IPolygonResponse<IPolygon>['data']> =>
  Promise.resolve({
    pagination: { page: 1, pageSize: 10, total: 1 },
    polygons: [
      {
        area: 100,
        isDeletable: false,
        isSelected: true,
        polygonId: 1,
        polygonMap: [{ latitude: 1, longitude: 2 }],
        selectedSubmissions: 10,
        totalCarbonPerYear: 2,
        totalCarbonStandardDeviation: 2,
        waitingSubmissions: 3,
        plotsCount: 100,
        samplingPlots: 99,
      },
    ],
  });

const getProjectById = (): Promise<IApiResponse<IProjectDetails>> =>
  Promise.resolve({
    data: {
      code: 200,
      message: 'ok',
      traceId: '1',
      data: {
        ...projectMock,
        projectId: 1,
        formularExplanation: '',
        standard: 'GOLD_STANDARD',
        projectAreaBoundary: [],
        samplingModuleTypes: [],
      },
    },
  });

const createPolygon = (): Promise<IUploadPolygonResponse> =>
  Promise.resolve({
    code: 200,
    message: 'ok',
    traceId: '1',
  });

const upsertPolygon = (): Promise<IUpsertPolygonResponse> =>
  Promise.resolve({ polygonId: 1 });

const createProject = (): Promise<ICreateUpdateProjectResponse> =>
  Promise.resolve({
    code: 0,
    data: {
      creationDate: '',
      description: '',
      email: 'user@gmail.com',
      formularExplanation: '',
      isDeletable: true,
      location: '',
      organizationName: '',
      projectAreaBoundary: [{ latitude: 1, longitude: 2 }],
      projectId: 1,
      projectTitle: 'kwahu',
      standard: 'GOLD_STANDARD',
      telephone: '0123456789',
      totalArea: 123,
      totalCarbonPerYear: 321,
      totalCarbonStandardDeviation: 111,
      totalPlots: 222,
      totalPolygons: 2,
      website: 'teko.vn',
    },
    traceId: '',
  });

const deleteProject = (): Promise<IProjectDeleteResponse> =>
  Promise.resolve({ code: 0, message: '' });

const generateSamplingPlots = (): Promise<IAutoGeneratePlotsResponse['data']> =>
  Promise.resolve({ samplingPlotsCount: 10 });

const getProjectPlotsByCoors = (): Promise<IGetAllPlotsOnMapResponse['data']> =>
  Promise.resolve({
    plots: [
      {
        createdAt: '',
        hasData: false,
        latitude: 1,
        longitude: 1.2,
        plotId: 1,
        plotName: 'plot',
        selectedSubmissions: 1,
        submitterId: '1',
        polygonId: 1,
      },
    ],
  });

const generateSamplingPlotsTekbone = (): Promise<IAutoGeneratePlotsResponse['data']> =>
  Promise.resolve({ samplingPlotsCount: 10, isProcessedAsync: false });

const getProjectPlotsByCoorsTekbone = (): Promise<IGetAllPlotsOnMapResponse['data']> =>
  Promise.resolve({
    plots: [
      {
        createdAt: '',
        hasData: false,
        latitude: 1,
        longitude: 1.2,
        plotId: 1,
        plotName: 'plot',
        selectedSubmissions: 1,
        submitterId: '1',
        polygonId: 1,
      },
    ],
  });

export default {
  getProjectById,
  createPolygon,
  upsertPolygon,
  getProjects,
  addUserToProject,
  getProjectList,
  getProjectPolygons,
  createProject,
  deleteProject,
  generateSamplingPlots,
  getProjectPlotsByCoors,
  generateSamplingPlotsTekbone,
  getProjectPlotsByCoorsTekbone,
};
