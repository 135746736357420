import {
  EnvironmentOutlined,
  IdcardOutlined,
  LineChartOutlined,
  VideoCameraOutlined,
  ContactsOutlined,
  GroupOutlined,
  TeamOutlined,
  CarryOutOutlined,
} from '@ant-design/icons';
import Community from 'containers/CREMA/Community';
import FPICCreate from 'containers/FPIC/Create';
import FPICDetail from 'containers/FPIC/Detail';
import FPICList from 'containers/FPIC/List';
import ForecastingVideo from 'containers/ForecastingVideo';
import MPIReport from 'containers/MPIReport';
import ProjectDetail from 'containers/Project/ProjectDetail/ProjectDetail';
import ProjectDetailMapV2 from 'containers/Project/ProjectDetailMapV2';
import ProjectMap from 'containers/Project/ProjectMap';
import { t } from 'helpers/i18n';
import { withFeatureFlag } from 'hocs';
import { lazy } from 'react';
import { orgChartRoutes } from './common';

const InProgressPage = lazy(() => import('containers/shared/InProgressPage'));
const SubmissionList = lazy(() =>
  import('containers/Submission/SubmissionList')
);
const SubmissionListV2 = lazy(() =>
  import('containers/Submission/SubmissionListV2')
);

// Route configs for DEMO
export const demoRoutes = [
  {
    exact: true,
    path: '/project-boundaries',
    name: t('ProjectBoundaries'),
    component: withFeatureFlag(ProjectMap, ProjectDetailMapV2, 'CAR_418'),
    icon: ContactsOutlined,
  },
  {
    exact: true,
    path: '/communities',
    name: t('Communities'),
    icon: GroupOutlined,
    component: Community,
  },
  {
    exact: true,
    path: '/farmers',
    name: t('Farmers'),
    icon: TeamOutlined,
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/submissions',
    name: t('TreeSubmissions'),
    icon: EnvironmentOutlined,
    component: withFeatureFlag(SubmissionList, SubmissionListV2, 'CAR_2356'),
  },
  {
    exact: true,
    path: '/carbon-monitoring',
    name: t('CarbonMonitoring'),
    icon: CarryOutOutlined,
    component: ProjectDetail,
  },
  {
    exact: true,
    path: '/meeting-notes',
    name: t('MeetingRecords'),
    component: FPICList,
    hideTitle: true,
    icon: IdcardOutlined,
  },
  {
    exact: true,
    path: '/meeting-notes/create',
    name: t('CreateForm'),
    component: FPICCreate,
  },
  {
    exact: true,
    path: '/meeting-notes/:id',
    name: t('FormDetails'),
    component: FPICDetail,
  },
  {
    exact: true,
    path: '/mpi',
    name: t('MPI Report'),
    icon: LineChartOutlined,
    component: MPIReport,
  },
  {
    exact: true,
    path: '/forecasting-video',
    name: t('ForecastingVideo'),
    icon: VideoCameraOutlined,
    component: ForecastingVideo,
  },
  ...orgChartRoutes,
];
