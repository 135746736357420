import { DrawState } from 'constants/map';
import {
  ICurrentPlacemark,
  IDrawPolygonRef,
  IGetFarmParams,
  INearbyPolygon,
  IOverlappedFarm,
  IPlacemark,
  ISurveyForm,
} from 'interfaces';
import { createContext, Dispatch } from 'react';

export const CurrentSelectedFarm = createContext<{
  drawState?: DrawState;
  setDrawState?: Dispatch<React.SetStateAction<DrawState>>;
  drawRef?: React.MutableRefObject<IDrawPolygonRef>;
  setTriggerReloadComment: React.Dispatch<React.SetStateAction<boolean>>;
  currentSurveyLocation?: ICurrentPlacemark;
  setCurrentSurveyLocation: Dispatch<
    React.SetStateAction<ICurrentPlacemark | undefined>
  >;
  setSelectedFarmId: Dispatch<React.SetStateAction<number | undefined>>;
  selectedFarmId: number | undefined;
  showDiscussionHistory: boolean;
  setShowDiscussionHistory: React.Dispatch<React.SetStateAction<boolean>>;
  selectedSurveyForm?: ISurveyForm;
  setSelectedSurveyForm: React.Dispatch<
    React.SetStateAction<ISurveyForm | undefined>
  >;
  setShowFarmList: React.Dispatch<React.SetStateAction<boolean>>;
  showFarmList: boolean;
  showFarmInfo: boolean;
  setShowFarmInfo: React.Dispatch<React.SetStateAction<boolean>>;
  showSurveyReport: boolean;
  setShowSurveyReport: React.Dispatch<React.SetStateAction<boolean>>;
  currentFarmArea: number;
  setCurrentFarmArea: React.Dispatch<React.SetStateAction<number>>;
  listPlacemark?: IPlacemark[];
  setListPlacemark: React.Dispatch<React.SetStateAction<IPlacemark[]>>;
  listFormSurvey: ISurveyForm[];
  setListFormSurvey: React.Dispatch<React.SetStateAction<ISurveyForm[]>>;
  listNearbyFarm: INearbyPolygon[];
  setListNearbyFarm: React.Dispatch<React.SetStateAction<INearbyPolygon[]>>;
  overlapPolygon: IOverlappedFarm[];
  setOverlapPolygon: React.Dispatch<React.SetStateAction<IOverlappedFarm[]>>;
  setParams: React.Dispatch<React.SetStateAction<IGetFarmParams>>;
}>({
  drawState: DrawState.NONE,
  setDrawState: () => {},
  drawRef: {} as React.MutableRefObject<IDrawPolygonRef>,
  setTriggerReloadComment: () => {},
  currentSurveyLocation: undefined,
  setCurrentSurveyLocation: () => {},
  setSelectedFarmId: () => {},
  selectedFarmId: undefined,
  setShowDiscussionHistory: () => {},
  selectedSurveyForm: undefined,
  setSelectedSurveyForm: () => {},
  setShowFarmList: () => {},
  showDiscussionHistory: false,
  showFarmList: true,
  showFarmInfo: false,
  setShowFarmInfo: () => {},
  showSurveyReport: false,
  setShowSurveyReport: () => {},
  currentFarmArea: 0,
  setCurrentFarmArea: () => {},
  listPlacemark: [],
  setListPlacemark: () => {},
  listFormSurvey: [],
  setListFormSurvey: () => {},
  listNearbyFarm: [],
  setListNearbyFarm: () => {},
  overlapPolygon: [],
  setOverlapPolygon: () => {},
  setParams: () => {},
});

export const PreEnrollmentContext = createContext<{
  refreshFarmer: (keyword?: string | undefined, userIds?: number[]) => void;
  refreshUserAppointment: (keyword?: string, participantIds?: number[]) => void;
  keyword: string;
  participantIds: number[];
  listFarmerType: string;
  loadMoreFarmer: (keyword?: string, userIds?: number[]) => Promise<void>;
  loadMoreUserAppointment: (
    keyword?: string,
    participantIds?: number[]
  ) => Promise<void>;
  setListFarmerType: React.Dispatch<React.SetStateAction<string>>;
}>({
  refreshFarmer: () => {},
  refreshUserAppointment: () => {},
  keyword: '',
  participantIds: [],
  listFarmerType: '',
  loadMoreFarmer: () => Promise.resolve(),
  loadMoreUserAppointment: () => Promise.resolve(),
  setListFarmerType: () => {},
});
