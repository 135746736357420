import { useFlagsupContext } from 'contexts';
import { FLAGS } from 'interfaces';
import React from 'react';

export const withFeatureFlag = <P extends {}, T extends {}>(
  OldComponent: React.FC<P>,
  NewComponent: React.FC<T>,
  flagKey: FLAGS
): React.FC<P & T> => {
  return props => {
    const { featureFlagsData } = useFlagsupContext();
    const isFlagEnabled = featureFlagsData[flagKey]?.enabled;

    return isFlagEnabled ? (
      <NewComponent {...props} />
    ) : (
      <OldComponent {...props} />
    );
  };
};
