import {
  AppstoreOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  List,
  Popover,
  Row,
  Select,
  Spin,
  Switch,
  Typography,
} from 'antd';
import { ID_DEFAULT, PROJECT_BOUNDARY_Z_INDEX } from 'constants/common';
import { DEFAULT_PAGINATION_OFFSET } from 'constants/pagination';
import {
  useProjectDetailV2Context,
  useProjectDrawersContext,
} from 'containers/Project/ProjectDetailMapV2/context';
import { useFlagsupContext, useProjectContext } from 'contexts';
import { mapHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { plotHooks, polygonHooks, userHooks } from 'hooks';
import {
  IGetPolygonTekboneParams,
  IPolygonType,
  ISamplingModuleDetails,
  SamplingPlotsType,
} from 'interfaces';
import React, { useEffect, useMemo, useState } from 'react';
import './Filter.scss';
import { DrawState } from 'constants/map';
import { FormInstance } from 'antd/lib/form';
import { useSelector, useDispatch } from 'react-redux';
import { selectProject, selectSamplingModules } from 'redux/features/project';
import { AppDispatch } from 'redux/store';
import {
  addShowingSamplingPlotType,
  removeShowingSamplingPlotType,
  setAddSamplingPlotType,
  setAvailableSamplingModules,
  setManualSamplingPlots,
} from 'redux/features/samplingPlot';
import { FaMapMarker } from 'react-icons/fa';

const { usePolygonTypes } = polygonHooks;
const { useUserPermissions } = userHooks;

export interface IPolygonFilter {
  polygonTypeIds?: number[];
  polygonName?: string;
  overlapStatus?: string;
}

interface FilterProps {
  setParams: React.Dispatch<React.SetStateAction<IGetPolygonTekboneParams>>;
  setMaxZIndex: React.Dispatch<React.SetStateAction<number>>;
  gettingPolygons: boolean;
  form: FormInstance<IPolygonFilter>;
}

const overlapStatusOptions = [
  { value: 'OVERLAPPED', label: t('Draft') },
  { value: 'NOT_OVERLAPPED', label: t('Approved') },
];

const Filter: React.FC<FilterProps> = ({
  setParams,
  setMaxZIndex,
  gettingPolygons,
  form,
}) => {
  const { projectId } = useProjectContext();
  const { polygonTypes, loading } = usePolygonTypes();
  const {
    setShowAddPolygon,
    setIsAddProjectBoundary,
    setCurrentPolygonId,
    selectedLayerTypes,
    setStatusAfterMapChanges,
    showAddPolygonScreen,
    showAddPolygon,
    setDrawState,
    setIsPlotMode,
    setSelectedLayerTypes,
    setViewARREligible,
  } = useProjectDetailV2Context();
  const {
    setIsDetailProjectDrawerOpen,
    setShowGenerateReport,
    setOpenList,
    setSamplingPlotsDrawerType,
  } = useProjectDrawersContext();
  const projectDetails = useSelector(selectProject);
  const isBasemap = !!mapHelpers.getBaseMapLayerType(selectedLayerTypes);
  const disableForm = gettingPolygons || isBasemap;
  const [showPopover, setShowPopover] = useState(false);
  const [showSamplingPlotMenu, setShowSamplingPlotMenu] = useState(false);
  const { featureFlagsData } = useFlagsupContext();
  const { canGenerateCarbonPotentialReport } = useUserPermissions();
  const { loading: loadingSamplingModule } = plotHooks.useSamplingModules();

  const isCAR613Enabled = featureFlagsData.CAR_613?.enabled;
  const dispatch: AppDispatch = useDispatch();
  const samplingModules = useSelector(selectSamplingModules);
  const findMaxZIndex = (polygonTypes: IPolygonType[]) => {
    return polygonTypes.reduce(
      (maxZIndex, polygonType) => Math.max(maxZIndex, polygonType.zIndex),
      1
    );
  };

  const onSearchPolygon = async (values: IPolygonFilter) => {
    setCurrentPolygonId(ID_DEFAULT);
    setShowAddPolygon(false);
    setDrawState?.(DrawState.NONE);
    if (values.polygonTypeIds?.length || values.polygonName) {
      setParams({ ...values, ...DEFAULT_PAGINATION_OFFSET, projectId });
    } else {
      setParams({
        zIndex: PROJECT_BOUNDARY_Z_INDEX,
        ...DEFAULT_PAGINATION_OFFSET,
        projectId,
      });
    }
    setStatusAfterMapChanges({ callAPI: false, isCenter: true });
  };

  const onSwitchSamplingPlot = (plotId: number, value: boolean) => {
    if (value) {
      dispatch(addShowingSamplingPlotType(plotId));
    } else {
      dispatch(removeShowingSamplingPlotType(plotId));
    }
  };

  const onReset = () => {
    form.resetFields();
    onSearchPolygon(form.getFieldsValue());
    setDrawState?.(DrawState.NONE);
    setIsPlotMode(false);
  };

  const onAddPolygon = () => {
    setShowAddPolygon(true);
    setIsAddProjectBoundary(false);
    setCurrentPolygonId(ID_DEFAULT);
    setOpenList(false);
    setIsPlotMode(false);
    setShowPopover(false);
    setDrawState?.(DrawState.NONE);
    setShowGenerateReport(false);
    setSamplingPlotsDrawerType(undefined);
  };

  const onEditProject = () => {
    setShowAddPolygon(false);
    setIsDetailProjectDrawerOpen(true);
    setShowPopover(false);
    setSamplingPlotsDrawerType(undefined);
  };

  const onGenerateReport = () => {
    setSelectedLayerTypes(['LandCover']);
    setViewARREligible(true);
    setShowGenerateReport(true);
    setShowPopover(false);
    setShowAddPolygon(false);
    setIsDetailProjectDrawerOpen(false);
    setOpenList(false);
    setSamplingPlotsDrawerType(undefined);
  };

  const onOpenManualAdd = () => {
    setSelectedLayerTypes(['Satellite']);
    dispatch(setAddSamplingPlotType('MANUAL'));
  };

  const openSamplingPlots = (type: SamplingPlotsType) => {
    if (['RANDOM', 'IMPORT'].includes(type)) {
      setSamplingPlotsDrawerType(type);
      dispatch(setAddSamplingPlotType(type));
    } else {
      onOpenManualAdd();
      dispatch(setAddSamplingPlotType('MANUAL'));
      setSamplingPlotsDrawerType(undefined);
    }
    dispatch(setManualSamplingPlots([]));
    setShowSamplingPlotMenu(false);
    setIsDetailProjectDrawerOpen(false);
    setCurrentPolygonId(ID_DEFAULT);
    setShowAddPolygon(false);
    setIsPlotMode(false);
    setShowPopover(false);
    setDrawState?.(DrawState.NONE);
    setShowGenerateReport(false);
  };

  const items = [
    {
      key: 'addPolygon',
      label: t('AddPolygon'),
      onClick: onAddPolygon,
      disabled: showAddPolygonScreen || showAddPolygon || isBasemap,
      icon: <PlusOutlined />,
    },
    {
      key: 'editProject',
      label: t('EditProject'),
      onClick: onEditProject,
      icon: <EditOutlined />,
    },
  ];

  if (canGenerateCarbonPotentialReport) {
    items.splice(1, 0, {
      key: 'generateReport',
      label: t('GenerateCarbonPotentialReport'),
      onClick: onGenerateReport,
      icon: <PlusOutlined />,
    });
  }

  const content = (
    <List
      dataSource={items}
      itemLayout="vertical"
      renderItem={item => (
        <div>
          <Button
            type="text"
            onClick={item.onClick}
            className="clickable w-100 text-left justify-content-start"
            disabled={item.disabled}
          >
            {item.icon} {item.label}
          </Button>
        </div>
      )}
      size="large"
    />
  );

  const availableSamplingModules:
    | ISamplingModuleDetails[]
    | undefined = useMemo(() => {
    const mergedSamplingModules = samplingModules
      ?.filter(samplingModule => samplingModule.activePlotSet.hasSubmissionData)
      .map(item => {
        const match = projectDetails?.samplingModuleTypes?.find(
          module => module.id === item.id
        );

        if (match) {
          return {
            ...item,
            ...match,
          };
        }
        return item;
      });

    return mergedSamplingModules;
  }, [samplingModules, projectDetails]);

  const samplingPlotItems = (
    <>
      <Spin spinning={loadingSamplingModule}>
        {availableSamplingModules?.length ? (
          <>
            {availableSamplingModules.map((item, index) => (
              <div
                className="sampling-plot-item d-flex justify-content-between mb-half"
                key={index}
              >
                <div className="sampling-plot-item-label d-flex align-items-center">
                  <FaMapMarker color={item.colorCode ?? '#BFBFBF'} />
                  {item.typeName}
                </div>
                <Switch
                  onChange={value => onSwitchSamplingPlot(item.id, value)}
                />
              </div>
            ))}
          </>
        ) : (
          <Typography.Text
            className="d-block w-100 p-half"
            style={{ textAlign: 'center' }}
          >
            {t('NoSamplingPlot')}
          </Typography.Text>
        )}
      </Spin>
      <Flex
        className="w-100 sampling-plot-btn-container"
        justify="space-between"
      >
        <Button
          className="sampling-plot-btn"
          onClick={() => openSamplingPlots('MANUAL')}
        >
          {t('ManualAdd')}
        </Button>
        <Button
          className="sampling-plot-btn"
          onClick={() => openSamplingPlots('IMPORT')}
        >
          {t('ImportFile')}
        </Button>
        <Button
          className="sampling-plot-btn"
          onClick={() => openSamplingPlots('RANDOM')}
        >
          {t('RandomGenerate')}
        </Button>
      </Flex>
    </>
  );

  useEffect(() => {
    setMaxZIndex(findMaxZIndex(polygonTypes || []));
  }, [polygonTypes]);

  useEffect(() => {
    if (availableSamplingModules) {
      dispatch(setAvailableSamplingModules(availableSamplingModules || []));
    }
  }, [availableSamplingModules]);

  return (
    <Card styles={{ body: { padding: 12 } }} className="project-detail-filter">
      <Form form={form} layout="horizontal" onFinish={onSearchPolygon}>
        <Row className="w-100 align-items-center justify-content-between">
          <Col span={20}>
            <Row gutter={[16, 16]}>
              <Col xs={8} xl={6} xxl={6}>
                <Form.Item name={'polygonName'}>
                  <Input
                    placeholder={t('InputPolygonName')}
                    allowClear
                    prefix={<SearchOutlined />}
                    disabled={disableForm}
                  />
                </Form.Item>
              </Col>
              <Col xs={6} xl={6} xxl={6}>
                <Form.Item name={'polygonTypeIds'}>
                  <Select
                    allowClear
                    placeholder={t('SelectType')}
                    loading={loading}
                    fieldNames={{
                      label: 'polygonTypeName',
                      value: 'polygonTypeId',
                    }}
                    options={polygonTypes || []}
                    onChange={() => {
                      form.setFieldsValue({
                        overlapStatus: undefined,
                      });
                    }}
                    mode="multiple"
                    disabled={disableForm}
                    maxTagCount="responsive"
                  />
                </Form.Item>
              </Col>
              <Form.Item dependencies={['polygonTypeIds']} noStyle>
                {() => {
                  const disabledSearch =
                    form.getFieldValue('polygonTypeIds') === undefined ||
                    form.getFieldValue('polygonTypeIds').length === 0;
                  return (
                    <Col xs={6} xl={6} xxl={4}>
                      <Form.Item name={'overlapStatus'}>
                        <Select
                          allowClear
                          placeholder={t('SelectStatus')}
                          options={overlapStatusOptions}
                          disabled={disabledSearch || disableForm}
                        />
                      </Form.Item>
                    </Col>
                  );
                }}
              </Form.Item>
              <Form.Item>
                <Button
                  icon={<UndoOutlined />}
                  className="ml-half"
                  onClick={onReset}
                >
                  {t('Reset')}
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  icon={<SearchOutlined />}
                  className="ml-half"
                  loading={gettingPolygons}
                  disabled={isBasemap}
                >
                  {t('Search')}
                </Button>
              </Form.Item>
            </Row>
          </Col>
          {isCAR613Enabled && (
            <Col className="text-right d-flex" style={{ paddingRight: 0 }}>
              <div className="samplingPlot">
                <Popover
                  content={samplingPlotItems}
                  trigger="click"
                  placement="bottomRight"
                  className="samplingPlot"
                  overlayInnerStyle={{ width: 320 }}
                  afterOpenChange={setShowSamplingPlotMenu}
                >
                  <Button
                    icon={
                      <DownOutlined rotate={showSamplingPlotMenu ? -180 : 0} />
                    }
                    iconPosition="end"
                  >
                    {t('SamplingPlot')}
                  </Button>
                </Popover>
              </div>
            </Col>
          )}
          <Col className="text-right d-flex" style={{ paddingRight: 0 }}>
            <Popover
              trigger="click"
              content={content}
              className="h-100"
              placement="bottomRight"
              open={showPopover}
              onOpenChange={setShowPopover}
            >
              <Button
                type="link"
                icon={
                  <AppstoreOutlined
                    style={{ fontSize: 24 }}
                    onClick={() => setShowPopover(prev => !prev)}
                  />
                }
              />
            </Popover>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export { Filter };
