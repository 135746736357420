import AppContainer from 'containers/AppLayout/AppContainer';
import FileUploadAndHistory from 'containers/shared/FileUploadAndHistory';
import { ProcessingFileType } from 'interfaces';
import React from 'react';
import { communityServices } from 'services';

const ImportFunds: React.FC = () => {
  return (
    <AppContainer>
      <FileUploadAndHistory
        processingFileType={ProcessingFileType.IMPORT_FUND}
        customUploadRequest={formData =>
          communityServices.importFunds(formData)
        }
      />
    </AppContainer>
  );
};

export default ImportFunds;
