import React, { useEffect, useState } from 'react';
import { List, Skeleton, Empty, Typography, Divider, Button, Row } from 'antd';
import { dateTimeHelpers } from 'helpers';
import { VN_DATE_SLASH_FORMAT } from 'constants/dateTime';
import { OrganizationActivityLog } from 'interfaces';
import { useProjectContext } from 'contexts';
import { DEFAULT_PAGINATION_OFFSET } from 'constants/pagination';
import { activityServices } from 'services';
import { t } from 'helpers/i18n';
const { formatUnixTimestamp } = dateTimeHelpers;

interface FinancialPlanExpenseReceiptsProps {
  activityId: number;
}

const FinancialPlanActivityLog: React.FC<FinancialPlanExpenseReceiptsProps> = ({
  activityId,
}) => {
  const { projectId } = useProjectContext();
  const [initialLoading, setInitialLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [activityLogs, setActivityLogs] = useState<OrganizationActivityLog[]>(
    []
  );
  const [nextOffset, setNextOffset] = useState<string | null>(null);
  const canLoadMore = nextOffset !== null && nextOffset !== '';

  const fetchData = async (offset?: string) => {
    try {
      offset ? setLoadingMore(true) : setInitialLoading(true);

      const {
        organizationActivityLogs,
        pagination: { offset: nextOffset },
      } = await activityServices.getOrgActivityLogs({
        activityId,
        projectId,
        limit: DEFAULT_PAGINATION_OFFSET.limit,
        offset,
      });
      if (offset) {
        setActivityLogs([...activityLogs, ...organizationActivityLogs]);
      } else {
        setActivityLogs(organizationActivityLogs);
      }
      setNextOffset(
        organizationActivityLogs.length === DEFAULT_PAGINATION_OFFSET.limit
          ? nextOffset
          : null
      );
    } finally {
      offset ? setLoadingMore(false) : setInitialLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const loadMore = () => {
    if (!canLoadMore) return;
    fetchData(nextOffset);
  };

  if (initialLoading) return <Skeleton active />;
  if (activityLogs.length === 0) return <Empty />;

  return (
    <List
      itemLayout="vertical"
      dataSource={activityLogs}
      split={false}
      loadMore={
        canLoadMore && (
          <Row justify="center">
            <Button
              loading={loadingMore}
              type="text"
              color="primary"
              className="text-center"
              onClick={() => loadMore()}
            >
              {t('LoadMore')}
            </Button>
          </Row>
        )
      }
      renderItem={log => (
        <List.Item key={log.id}>
          <Divider
            orientation="left"
            orientationMargin={0}
            className="mb-half mt-0"
            plain
          >
            <Typography.Text type="secondary">
              {formatUnixTimestamp(log.createdAt, VN_DATE_SLASH_FORMAT)}
            </Typography.Text>
            <Divider type="vertical" plain />
            <Typography.Text strong>{log.createdByUsername}</Typography.Text>
          </Divider>
          {/* CAR-2295: Activity Log Adress: Commented out for WEB version as it's currently not needed. 
              May be required in future updates.
          */}
          {/* {log.address && (
            <div className="mb-half">
              <Typography.Text className="text-caption">
                {<EnvironmentOutlined />} {log.address}
              </Typography.Text>
            </div>
          )} */}
          <Typography.Paragraph ellipsis={{ rows: 4, expandable: true }}>
            {log.type === 'ApproveActivity' && (
              <Typography.Text strong>{t('Approved')}</Typography.Text>
            )}
            {log.type === 'RejectActivity' && (
              <>
                <Typography.Text strong>{t('Rejected')}</Typography.Text>
                {log.description && ': '}
              </>
            )}
            {log.description}
          </Typography.Paragraph>

          {/* CAR-2295: Activity Log Attachments: Commented out for WEB version as it's currently not needed. 
              May be required in future updates.
          */}
          {/* {(log.recordingFiles?.length > 0 || log.imageFiles?.length > 0) && (
            <Collapse
              bordered={false}
              expandIconPosition="right"
              size="small"
              items={[
                {
                  label: <Typography.Text>{t('ViewDetail')}</Typography.Text>,
                  children: <ActivityLogAttachment {...log} />,
                },
              ]}
            />
          )} */}
        </List.Item>
      )}
    />
  );
};

export default FinancialPlanActivityLog;
