import { IRoute, PartialAllExceptOne } from 'interfaces';
import { orgChartRoutes } from './common';
export const demoRoutes: PartialAllExceptOne<IRoute, 'path'>[] = [
  { path: '/' },
  { path: '/project-boundaries' },
  { path: '/communities' },
  { path: '/farmers' },
  { path: '/submissions' },
  { path: '/carbon-monitoring' },
  { path: '/meeting-notes' },
  { path: '/meeting-notes/create' },
  { path: '/meeting-notes/:id' },
  { path: '/mpi' },
  { path: '/forecasting-video' },
  ...orgChartRoutes,
];

const PROJECTS = window.appConfig?.projects || {};

export const mapDemoRoutes = {
  [PROJECTS.Demo]: demoRoutes,
};
