import FinancialPlanPage from 'components/Plan/FinancialPlan';
import AppContainer from 'containers/AppLayout/AppContainer';
import React from 'react';

const FinancialPlan: React.FC = () => {
  return (
    <AppContainer>
      <FinancialPlanPage />
    </AppContainer>
  );
};

export default FinancialPlan;
