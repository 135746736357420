import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  SelectProps,
} from 'antd';
import { t } from 'helpers/i18n';
import { DefaultOptionType } from 'antd/es/select';

const CustomizableSelect: React.FC<SelectProps> = ({
  options,
  onChange,
  ...selectProps
}) => {
  const [form] = Form.useForm();
  const [optionsState, setOptionsState] = useState<DefaultOptionType[]>(
    options || []
  );

  const addItem = ({ name }: { name: string }) => {
    const normalizedName = name.trim();

    setOptionsState([
      ...optionsState,
      {
        label: normalizedName,
        value: normalizedName,
      },
    ]);
    onChange?.(normalizedName, optionsState);
    form.resetFields();
  };

  return (
    <Select
      dropdownRender={menu => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Form onFinish={addItem} form={form}>
            <Row style={{ padding: '0 8px 4px' }} gutter={8}>
              <Col flex={1}>
                <Form.Item
                  name="name"
                  className="mb-0"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: t('PleaseEnterItem'),
                    },
                    {
                      validator: (_, value) => {
                        if (
                          value &&
                          optionsState.some(item => item.value === value.trim())
                        ) {
                          return Promise.reject(t('ItemAlreadyExists'));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input placeholder={t('PleaseEnterItem')} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item className="mb-0">
                  <Button type="text" htmlType="submit" icon={<PlusOutlined />}>
                    {t('AddItem')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      )}
      options={optionsState}
      onChange={onChange}
      {...selectProps}
    />
  );
};

export default CustomizableSelect;
