import React, { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';
import { LayerType } from './interfaces';
import { MapContainer } from 'react-leaflet';
import { Spin } from 'antd';
import { MapLayers } from './MapLayers';
import { SetBoundsComponent } from './SetBoundsComponent';
import { IMapPolygon } from './MapPolygons';
import { LeafletEventHandlerFnMap, Map } from 'leaflet';
import BaseMapPolygons from './BaseMapPolygons';
import { mapHelpers } from 'helpers';
import { LegendPlacement } from './constants';

interface MapContainerWrapperProps {
  selectedLayerTypes: LayerType[];
  loading: boolean;
  boundPolygons: IMapPolygon[];
  children?: ReactNode;
  allowTypes: LayerType[];
  currentPolygonId: number;
  mapRef: React.RefObject<HTMLDivElement>;
  isCenteredWhenPolygonChanges?: boolean;
  setSelectedLayerTypes: React.Dispatch<React.SetStateAction<LayerType[]>>;
  eventHandlers?: (map: Map) => LeafletEventHandlerFnMap;
  legendPlacement?: LegendPlacement;
  viewARREligible?: boolean;
  setViewARREligible?: Dispatch<SetStateAction<boolean>>;
  showARREligibleSwitch?: boolean;
}

// BaseMap = LAND_COVER has large polygons data, so we need to memoize it to prevent unnecessary expensive re-rendering
const BaseMapPolygonsMemo = React.memo(BaseMapPolygons);

const MapContainerWrapper: React.FC<MapContainerWrapperProps> = ({
  loading,
  boundPolygons,
  children,
  allowTypes,
  currentPolygonId,
  isCenteredWhenPolygonChanges,
  mapRef,
  selectedLayerTypes,
  setSelectedLayerTypes,
  eventHandlers,
  legendPlacement,
  viewARREligible,
  setViewARREligible,
  showARREligibleSwitch,
}) => {
  const basemapLayerType = mapHelpers.getBaseMapLayerType(selectedLayerTypes);

  useEffect(() => {
    window.dispatchEvent(new Event('resize')); // NOTE: this is a workaround for leaflet correctly renders on webpage
  }, []);

  return (
    <MapContainer
      scrollWheelZoom
      fadeAnimation={true}
      markerZoomAnimation={true}
      zoomControl={false}
    >
      <MapLayers
        mapRef={mapRef}
        allowTypes={allowTypes}
        selectedLayerTypes={selectedLayerTypes}
        setSelectedLayerTypes={setSelectedLayerTypes}
      />
      {loading && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1049,
          }}
        >
          <Spin />
        </div>
      )}
      {children}
      <SetBoundsComponent
        currentPolygonId={currentPolygonId}
        polygons={boundPolygons}
        isCenteredWhenPolygonChanges={isCenteredWhenPolygonChanges}
        mapRef={mapRef}
        eventHandlers={eventHandlers}
      />
      {basemapLayerType && (
        <BaseMapPolygonsMemo
          basemapLayerType={basemapLayerType}
          legendPlacement={legendPlacement}
          viewARREligible={viewARREligible}
          setViewARREligible={setViewARREligible}
          showARREligibleSwitch={showARREligibleSwitch}
        />
      )}
    </MapContainer>
  );
};

export default MapContainerWrapper;
