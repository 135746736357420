import { Button } from 'antd';
import ContentBlock from 'components/shared/ContentBlock';
import React from 'react';
import { useHistory } from 'react-router-dom';

const ConsultantReportContainer: React.FC = () => {
  const consultantReports = [
    { tabName: 'LULUC', path: 'luluc' },
    { tabName: 'Intervention I3A1', path: 'intervention-i3a1' },
    { tabName: 'Fire Plan', path: 'fire-plan' },
  ];
  const history = useHistory();

  return (
    <ContentBlock>
      {consultantReports.map(report => (
        <Button
          type="link"
          onClick={() => history.push(`/consultant-reports/${report.path}`)}
        >
          {report.tabName}
        </Button>
      ))}
    </ContentBlock>
  );
};

export { ConsultantReportContainer };
export default ConsultantReportContainer;
