import { CloseOutlined } from '@ant-design/icons';
import { Button, Table, TableColumnsType, Typography } from 'antd';
import { CurrentSelectedFarm } from 'components/FarmerManagement/FarmerEnrolment/Context';
import { t } from 'helpers/i18n';
import { IDetailedLancoverStatistic } from 'interfaces';
import React, { useContext, useEffect } from 'react';
import './FarmSurveyReport.scss';
import { convertNumberFormat } from 'helpers/common';
import ContentBlock from 'components/shared/ContentBlock';
import { surveyHooks } from 'hooks';

const FarmSurveyReport: React.FC = () => {
  const {
    setShowSurveyReport,
    selectedFarmId,
    currentFarmArea,
    listFormSurvey,
    setSelectedSurveyForm,
    setShowFarmInfo,
  } = useContext(CurrentSelectedFarm);
  const {
    surveyReport,
    loading,
    getSurveyReport,
  } = surveyHooks.useSurveyReport();

  const columns: TableColumnsType<IDetailedLancoverStatistic> = [
    {
      title: t('RawFieldData'),
      children: [
        {
          title: <Typography.Text ellipsis>{t('Placemark')}</Typography.Text>,
          render: (value: IDetailedLancoverStatistic) => (
            <Button
              type="link"
              className="p-0"
              onClick={() => onCLickPlacemark(value.farmSurveyId)}
            >
              {t('Placemark')} {value.farmSurveyId}
            </Button>
          ),
        },
        {
          title: (
            <Typography.Text style={{ wordWrap: 'break-word' }}>
              {t('GrassCoverPercentage')}
            </Typography.Text>
          ),
          dataIndex: 'grassCoverPercentage',
          render: value => {
            return `${convertNumberFormat(value)}`;
          },
        },
        {
          title: (
            <Typography.Text style={{ wordWrap: 'break-word' }}>
              {t('FoodCropsCoverPercentage')}
            </Typography.Text>
          ),
          dataIndex: 'foodCropsCoverPercentage',
          render: value => {
            return `${convertNumberFormat(value)}`;
          },
        },
        {
          title: (
            <Typography.Text style={{ wordWrap: 'break-word' }}>
              {t('TreeCropsCoverPercentage')}
            </Typography.Text>
          ),
          dataIndex: 'treeCropsCoverPercentage',
          render: value => {
            return `${convertNumberFormat(value)}`;
          },
        },
        {
          title: (
            <Typography.Text style={{ wordWrap: 'break-word' }}>
              {t('WoodyBushesCoverPercentage')}
            </Typography.Text>
          ),
          dataIndex: 'woodyBushesCoverPercentage',
          render: value => {
            return `${convertNumberFormat(value)}`;
          },
        },
        {
          title: (
            <Typography.Text style={{ wordWrap: 'break-word' }}>
              {t('TreesCoverPercentage')}
            </Typography.Text>
          ),
          dataIndex: 'treesCoverPercentage',
          render: value => {
            return `${convertNumberFormat(value)}`;
          },
        },
        {
          title: (
            <Typography.Text style={{ wordWrap: 'break-word' }}>
              {t('CannotBePlantedCoverPercentage')}
            </Typography.Text>
          ),
          dataIndex: 'cannotBePlantedCoverPercentage',
          render: value => {
            return `${convertNumberFormat(value)}`;
          },
        },
        {
          title: (
            <Typography.Text style={{ wordWrap: 'break-word' }}>
              {t('TotalPercentage')}
            </Typography.Text>
          ),
          render: (value: IDetailedLancoverStatistic) => {
            const {
              grassCoverPercentage,
              foodCropsCoverPercentage,
              treeCropsCoverPercentage,
              woodyBushesCoverPercentage,
              treesCoverPercentage,
              cannotBePlantedCoverPercentage,
            } = value;
            const totalPercentage =
              grassCoverPercentage +
              foodCropsCoverPercentage +
              treeCropsCoverPercentage +
              woodyBushesCoverPercentage +
              treesCoverPercentage +
              cannotBePlantedCoverPercentage;
            return `${convertNumberFormat(totalPercentage)}`;
          },
        },
      ],
    },
    {
      title: t('CarbonModelingInputs'),
      children: [
        {
          title: (
            <Typography.Text style={{ wordWrap: 'break-word' }}>
              {t('PlantableAreaPercentage')}
            </Typography.Text>
          ),
          dataIndex: 'plantableAreaPercentage',
          render: value => {
            return `${convertNumberFormat(value)}`;
          },
        },
        {
          title: (
            <Typography.Text style={{ wordWrap: 'break-word' }}>
              {t('NonPlantableAreaPercentage')}
            </Typography.Text>
          ),
          dataIndex: 'nonPlantableAreaPercentage',
          render: value => {
            return `${convertNumberFormat(value)}`;
          },
        },
        {
          title: (
            <Typography.Text style={{ wordWrap: 'break-word' }}>
              {t('PlantableArea')}
            </Typography.Text>
          ),
          dataIndex: 'plantableArea',
          render: value => {
            return `${convertNumberFormat(value)}`;
          },
        },
        {
          title: (
            <Typography.Text style={{ wordWrap: 'break-word' }}>
              {t('NonPlantableArea')}
            </Typography.Text>
          ),
          dataIndex: 'nonPlantableArea',
          render: value => {
            return `${convertNumberFormat(value)}`;
          },
        },
      ],
    },
  ];

  const tableSummary = () => {
    if (
      surveyReport &&
      Array.isArray(surveyReport?.detailedLandCoverStatistics) &&
      surveyReport?.detailedLandCoverStatistics.length > 0
    ) {
      const data = [
        surveyReport?.averageGrassCoverPercentage,
        surveyReport?.averageFoodCropsCoverPercentage,
        surveyReport?.averageTreeCropsCoverPercentage,
        surveyReport?.averageWoodyBushesCoverPercentage,
        surveyReport?.averageTreesCoverPercentage,
        surveyReport?.averageCannotBePlantedCoverPercentage,
        surveyReport?.averageGrassCoverPercentage +
          surveyReport?.averageFoodCropsCoverPercentage +
          surveyReport?.averageTreeCropsCoverPercentage +
          surveyReport?.averageWoodyBushesCoverPercentage +
          surveyReport?.averageTreesCoverPercentage +
          surveyReport?.averageCannotBePlantedCoverPercentage,
        surveyReport?.averagePlantableAreaPercentage,
        surveyReport?.averageNonPlantableAreaPercentage,
        surveyReport?.averagePlantableArea,
        surveyReport?.averageNonPlantableArea,
      ];
      return (
        <Table.Summary fixed>
          <Table.Summary.Row>
            <Table.Summary.Cell index={0}>
              <Typography.Text strong>{t('Average')}</Typography.Text>
            </Table.Summary.Cell>
            {data.map((value, index) => (
              <Table.Summary.Cell index={index}>
                <Typography.Text strong>
                  {convertNumberFormat(value || 0)}
                </Typography.Text>
              </Table.Summary.Cell>
            ))}
          </Table.Summary.Row>
        </Table.Summary>
      );
    }
  };

  const onCLickPlacemark = (id: number) => {
    const currentSelectedSurveyForm = listFormSurvey?.find(
      item => item.formSubmissionId === id
    );
    if (currentSelectedSurveyForm) {
      setShowFarmInfo(false);
      setSelectedSurveyForm(currentSelectedSurveyForm);
    }
  };

  useEffect(() => {
    if (selectedFarmId) getSurveyReport(selectedFarmId);
  }, [selectedFarmId]);

  return (
    <ContentBlock
      className="farm-survey-report"
      style={{ top: 15, right: 55 }}
      extra={
        <div className="d-flex justify-content-between w-100 pt-half">
          <Typography.Text className="d-block">
            {t('FarmLand') +
              ' ' +
              selectedFarmId +
              ' - ' +
              convertNumberFormat(currentFarmArea) +
              ' ha'}
          </Typography.Text>
          <div className="extraButton">
            <CloseOutlined onClick={() => setShowSurveyReport(false)} />
          </div>
        </div>
      }
    >
      <Table
        dataSource={surveyReport?.detailedLandCoverStatistics}
        columns={columns}
        bordered
        size="small"
        scroll={{ x: 'auto' }}
        pagination={false}
        locale={{
          emptyText: t('NoData'),
        }}
        summary={tableSummary}
        loading={loading}
      />
    </ContentBlock>
  );
};

export { FarmSurveyReport };
export default FarmSurveyReport;
