import React, { useRef } from 'react';
import ImportPlotsForm from './ImportPlotsForm';
import FileProcessHistory, {
  FileProcessingHistoryRef,
} from 'components/FileUploadAndHistory/FileProcessHistory';
import { ProcessingFileType } from 'interfaces';
import './ImportPlots.scss';

interface ImportPlotsProps {}

const ImportPlots: React.FC<ImportPlotsProps> = () => {
  const fileProcessHistoryRef = useRef<FileProcessingHistoryRef>(null);
  const callbackAfterImport = () => {
    // 1. Refresh history
    fileProcessHistoryRef?.current?.refresh();

    // 2. Refresh plots
    // @TODO: waiting for https://jira.teko.vn/browse/CARSA-1013
  };

  return (
    <>
      <ImportPlotsForm callbackAfterImport={callbackAfterImport} />
      <FileProcessHistory
        processingFileType={ProcessingFileType.IMPORT_SAMPLING_PLOT}
        className="import-plot-history"
        showError={false}
        ref={fileProcessHistoryRef}
      />
    </>
  );
};

export default ImportPlots;
