import { notification } from 'antd';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { t } from 'helpers/i18n';
import { userServices } from 'services';
import trackError from './tracker/trackError';
import { HIDE_ERROR_URLS } from 'constants/request';
import { IMap } from 'interfaces';

const { trackApiError } = trackError;

const handleResponseError = (error: AxiosError) => {
  const status = error && error.response && error.response.status;
  switch (status) {
    case 401:
      userServices.logout();
      break;
    case 403:
      userServices.denyAccess();
      break;
    default:
      if (axios.isCancel(error)) break; // ignore cancel request token
      let message: any = null;
      const response = (error as AxiosError).response;
      // Handle error message from API response
      if (
        response &&
        response?.data // This is just work around for axios.isCancel method. Refereence: https://github.com/axios/axios/issues/5153
      ) {
        message = response?.data;
      }

      const { config } = (response || {}) as AxiosResponse;
      const { url, method } = config || {};
      const hideRequest = HIDE_ERROR_URLS.find(hideUrl =>
        typeof hideUrl === 'object'
          ? url === hideUrl?.url && method === hideUrl?.method
          : hideUrl === url
      );
      if (!hideRequest)
        notification.error({
          message: t('Error'),
          description:
            typeof message === 'object'
              ? message?.message
              : message || t('SomethingWentWrong'),
        });
      break;
  }
  trackApiError(error);
};

export const getData = (response: any) => response?.data.data;
export const getResult = (response: any) => response?.data;
export const getResultAndHeaders = (response: any) => ({
  result: response?.data,
  headers: response?.headers,
});

export const encodeQueryData = (data: IMap<any>) => {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
};

export default {
  handleResponseError,
  getData,
  getResult,
  getResultAndHeaders,
};
