import React, { useEffect } from 'react';
import { Select, Space, Typography } from 'antd';
import queryString from 'query-string';
import { t } from 'helpers/i18n';
import { useFpicContext } from '../context';
import { useFPicFormTemplate } from '../hooks/fpic';
import './Header.scss';
import { browserHistory } from 'helpers';
import { IFpicFormTemplate } from 'interfaces';
import { useProjectContext } from 'contexts';
import { commonConstants } from 'constants/index';

/** Interfaces, enum... * */
interface HeaderProps {
  title?: React.ReactNode;
  disabledProject?: boolean;
  disabledTemplate?: boolean;
  showSelectTemplate?: boolean;
}

/** Variables * */
const { Title } = Typography;
const { IS_AJA } = commonConstants;

/** ------------------------- * */
const Header: React.FC<HeaderProps> = ({
  title: titleProp,
  disabledTemplate,
  showSelectTemplate,
}) => {
  const { projectId } = useProjectContext();
  /** States * */
  const search = browserHistory.location.search;
  const { template } = queryString.parse(search) as {
    template?: string;
  };

  const templateFormId = template ? Number(template) : undefined;
  const FPIC_FORM_TYPE = 1;

  /** Hooks * */
  const {
    title,
    template: templateForm,
    setTemplate,
    templates,
    setTemplates,
  } = useFpicContext();
  const { loading: loadingTemplate } = useFPicFormTemplate(
    projectId,
    setTemplates,
    // FPIC form type = 1
    FPIC_FORM_TYPE
  );

  const getTemplateForm = (id: number) =>
    templates?.find(item => item.templateFormId === id);

  const setTemplateForm = (temp?: IFpicFormTemplate) => {
    setTemplate?.(temp);
  };

  const onSelectTemplate = (templateId: number) => {
    const temp = getTemplateForm(templateId);
    setTemplateForm(temp);
  };

  /** Effects * */
  useEffect(() => {
    if (!showSelectTemplate) return;

    if (templates?.length) {
      const temp = templateFormId
        ? getTemplateForm(templateFormId) || templates[0]
        : templates[0];
      setTemplateForm(temp);
    }
  }, [templateFormId, JSON.stringify(templates)]);

  /** Elements * */
  return IS_AJA && disabledTemplate ? null : (
    <div className={'fpic-header'}>
      {title ?? titleProp}
      <Space>
        {showSelectTemplate && (
          <>
            <Title
              level={5}
              style={{
                margin: 0,
              }}
            >
              {t('Template')}
            </Title>
            <Select
              style={{
                minWidth: 300,
              }}
              loading={loadingTemplate}
              disabled={disabledTemplate || loadingTemplate}
              value={templateForm?.templateFormId}
              showSearch={true}
              allowClear={false}
              placeholder={t('Select')}
              options={templates?.map(template => {
                return {
                  value: template.templateFormId,
                  label: template.templateFormTitle,
                };
              })}
              filterOption={(inputValue, option) => {
                return !!option?.label
                  ?.toLowerCase()
                  .includes(inputValue.toLowerCase());
              }}
              onSelect={onSelectTemplate}
            />
          </>
        )}
      </Space>
    </div>
  );
};

export default Header;
