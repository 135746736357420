/** Third party libs **/
import { Card, Col, Row, Typography } from 'antd';
import React from 'react';
import { FieldTimeOutlined, FileTextOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

/** Local libs **/
import { t } from 'helpers/i18n';
import { IFarmLand } from 'interfaces';

/** Styles **/
import './FarmSubmitInfoCard.scss';
import { DATE_SLASH_FORMAT } from 'constants/dateTime';

/** Interfaces, enum... **/
interface IFarmSubmitInfoCardProps {
  selectedFarmLand?: IFarmLand;
}

/** Variables **/
const { Text } = Typography;

/** ------------------------- **/
/** FarmSubmitInfoCard Component */
/** ------------------------- **/
const FarmSubmitInfoCard: React.FC<IFarmSubmitInfoCardProps> = ({
  selectedFarmLand,
}) => {
  /** States **/

  /** Hooks **/

  /** Variables **/

  /** Functions, Events, Actions... **/
  const renderFarmStatus = (status: string) => {
    return (
      <Text className="d-block text-center">{t(status).toUpperCase()}</Text>
    );
  };

  /** Effects **/

  /** Elements **/
  return (
    <Card className="farm-submit-info-wrapper">
      <Row gutter={5} className="justify-content-between align-items-center">
        <Col span={11}>
          <div
            className="d-flex justify-content-center"
            style={{ flexDirection: 'column' }}
          >
            <Text className="d-block text-center">
              <FieldTimeOutlined className="green-icon" /> {t('Submitted')}
            </Text>
            <Text className="d-block text-center">
              {selectedFarmLand?.submittedDate &&
                dayjs
                  .unix(selectedFarmLand.submittedDate)
                  .format(DATE_SLASH_FORMAT)}
            </Text>
          </div>
        </Col>
        <div className="farm-submit-info-divider" />
        <Col span={11}>
          <div
            className="d-flex justify-content-center"
            style={{ flexDirection: 'column' }}
          >
            <Text className="d-block text-center">
              <FileTextOutlined className="green-icon" /> Status
            </Text>
            {selectedFarmLand?.approvalStatus &&
              renderFarmStatus(selectedFarmLand?.approvalStatus)}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export { FarmSubmitInfoCard };
export default FarmSubmitInfoCard;
