import { CACHE_STORAGE_NAME } from 'constants/cacheStorage';

interface ICacheStorageBase {
  key: string;
}

interface ICacheStorageBaseAdd extends ICacheStorageBase {
  data: any;
}

export interface ICacheStorage {
  deleteCache: (cacheKey: string) => Promise<boolean>;
  getData: (params: ICacheStorageBase) => Promise<any>;
  addData: (params: ICacheStorageBaseAdd) => Promise<void>;
}

export class CacheStorageInstance {
  private cacheRepository: Promise<Cache>;
  private cacheName: string;

  constructor(cacheName: string) {
    this.cacheName = cacheName;
    this.cacheRepository = new Promise((resolve, reject) => {
      caches
        .open(cacheName)
        .then(cacheStorage => resolve(cacheStorage))
        .catch(reject);
    });
  }
  async deleteCache(cacheKey: string) {
    const cacheStorage = await this.cacheRepository;
    return await cacheStorage.delete(cacheKey);
  }
  async getData(params: ICacheStorageBase) {
    const cacheStorage = await this.cacheRepository;
    const cachedResponse = await cacheStorage.match(params.key);
    if (!cachedResponse || !cachedResponse.ok) {
      throw new Error();
    }
    return await cachedResponse.json();
  }
  async addData(params: ICacheStorageBaseAdd) {
    const cacheStorage = await this.cacheRepository;
    const stringifyData = JSON.stringify(params.data);
    const response = new Response(stringifyData);
    return await cacheStorage.put(params.key, response);
  }
  async deleteAllCache() {
    return await caches.delete(this.cacheName);
  }
}

export const VolunteerCacheStorage = new CacheStorageInstance(
  CACHE_STORAGE_NAME.CARBON
);
