import React, { useEffect, useState } from 'react';
import { PopconfirmProps, SwitchProps, Switch, Popconfirm } from 'antd';

interface SwitchPopconfirmProps extends PopconfirmProps {
  switchProps?: SwitchProps;
}

const SwitchPopconfirm: React.FC<SwitchPopconfirmProps> = ({
  switchProps,
  ...popconfirmProps
}) => {
  const [switchChecked, setSwitchChecked] = useState(
    switchProps?.defaultChecked ?? false
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (switchProps?.defaultChecked !== undefined) {
      setSwitchChecked(switchProps.defaultChecked);
    }
  }, [switchProps]);

  return (
    <Popconfirm
      {...popconfirmProps}
      onConfirm={async () => {
        try {
          setLoading(true);
          await popconfirmProps.onConfirm?.();
          setSwitchChecked(prev => !prev);
        } finally {
          setLoading(false);
        }
      }}
      okButtonProps={{ loading }}
    >
      <Switch {...switchProps} checked={switchChecked} />
    </Popconfirm>
  );
};

export default SwitchPopconfirm;
