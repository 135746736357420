import { AxiosError } from 'axios';

const trackApiError = (error: AxiosError) => {
  const { message, code, config, response } = error;
  window.track('error', {
    errorSource: 'client',
    errorMessage: message,
    errorCode: code,
    apiCall: config?.url,
    apiPayload: JSON.stringify(config?.params || config?.data || {}),
    httpResponseCode: response?.status?.toString() || 'Unknown code',
    responseJson: JSON.stringify(response?.data),
  });
};

export default {
  trackApiError,
};
