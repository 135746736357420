import { useQuery } from '@tanstack/react-query';
import { Select, SelectProps } from 'antd';
import { commonConstants } from 'constants/index';
import { useProjectContext } from 'contexts';
import { tSelectPlaceholder } from 'helpers/i18n';
import { CremaTemplateFormType } from 'interfaces';
import { cremaServices } from 'services';

const { QUERY_KEYS } = commonConstants;

interface ICremaTemplateSelectProps extends SelectProps {
  templateFormTypes?: CremaTemplateFormType[];
}

const CremaTemplateSelect: React.FC<ICremaTemplateSelectProps> = ({
  templateFormTypes,
  ...selectProps
}) => {
  const { projectId } = useProjectContext();

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.CREMA_TEMPLATES, projectId, templateFormTypes],
    queryFn: async () =>
      await cremaServices
        .getCremaMeetingNoteTemplates({
          projectId,
          templateFormTypes,
        })
        .then(res => res.templateForms),
  });

  return (
    <Select
      allowClear
      showSearch
      loading={isLoading}
      fieldNames={{ label: 'templateFormTitle', value: 'templateFormId' }}
      options={data}
      placeholder={tSelectPlaceholder('Template')}
      {...selectProps}
    />
  );
};

export default CremaTemplateSelect;
