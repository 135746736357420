/** Third party libs * */
import React, { useEffect } from 'react';
import { Checkbox as AntdCheckbox, Row, Col, Flex, Input, Form } from 'antd';
import { keyBy } from 'lodash';

/** Local libs * */
import { t } from 'helpers/i18n';
import { OTHER_VALUE } from 'constants/common';

/** Components * */
import { BaseItemProps, BaseItem } from '../BaseItem';

/** Styles * */

/** Interfaces, enum... * */
interface CheckboxProps extends BaseItemProps {
  readOnly?: boolean;
  customizable?: boolean;
}

/** Variables * */

/** ------------------------- * */
const Checkbox: React.FC<CheckboxProps> = ({
  item,
  disabled,
  form,
  readOnly,
  customizable,
  ...props
}) => {
  /** States * */

  /** Hooks * */

  /** Variables * */
  const options = item.suggestionValues || [];

  /** Effects * */
  useEffect(() => {
    if (item.fieldName) {
      let values: string[] = [];

      try {
        values = item.value
          ? (JSON.parse(item.value) || []).map((item: string) => item.trim())
          : undefined;
        if (customizable) {
          const optionMap = keyBy(options, 'value');
          const otherValue = values.find(value => !optionMap[value]);
          otherValue &&
            form.setFieldValue(`${item.fieldName}${OTHER_VALUE}`, otherValue);
        }
      } catch (e) {
      } finally {
        form.setFieldValue(item.fieldName, values);
      }
    }
  }, [item.value]);

  /** Functions, Events, Actions... * */

  /** Elements * */
  return (
    <BaseItem
      item={item}
      form={form}
      validator={(rule, value: string[]) => {
        if (
          customizable &&
          value.length === 1 &&
          value[0] === OTHER_VALUE &&
          !form.getFieldValue(`${item.fieldName}${OTHER_VALUE}`)
        ) {
          return Promise.reject(t('NotBeEmpty'));
        }
        return Promise.resolve();
      }}
      {...props}
    >
      <AntdCheckbox.Group className="w-100">
        <Row gutter={[6, 6]}>
          {options.map(option => (
            <Col span={24} key={option.value}>
              <AntdCheckbox
                value={option.value}
                disabled={disabled || readOnly}
              >
                {option.label}
              </AntdCheckbox>
            </Col>
          ))}
          {customizable && (
            <Col span={24}>
              <Flex align="center" gap={16}>
                <AntdCheckbox
                  value={OTHER_VALUE}
                  disabled={disabled || readOnly}
                >
                  {t('Other')}:
                </AntdCheckbox>
                <Form.Item noStyle name={`${item.fieldName}${OTHER_VALUE}`}>
                  <Input
                    placeholder={t('InputText')}
                    className="custom-input p-0"
                    disabled={disabled}
                    readOnly={readOnly}
                    onChange={() => {
                      form.validateFields([item.fieldName]);
                    }}
                  />
                </Form.Item>
              </Flex>
            </Col>
          )}
        </Row>
      </AntdCheckbox.Group>
    </BaseItem>
  );
};

export default Checkbox;
