import { Select, SelectProps, Spin } from 'antd';
import { DEBOUNCE_TIME, DEFAULT_MAX_TAG_COUNT } from 'constants/common';
import { t, tSelectPlaceholder } from 'helpers/i18n';
import { communityHooks } from 'hooks';
import React, { useEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { DefaultOptionType } from 'antd/es/select';
import { useProjectContext } from 'contexts';

const { useProjectCommunities } = communityHooks;

interface IProjectCommunitySelectProps extends SelectProps<number[]> {
  projectIds: number[];
  communities?: { id: number; name: string }[];
}

const ProjectCommunitySelect: React.FC<IProjectCommunitySelectProps> = ({
  projectIds,
  value,
  communities: initialCommunities,
  ...selectProps
}) => {
  const {
    loading,
    loadingMore,
    communities: allCommunities,
    onSearch: searchCommunities,
    onLoadMore: loadMore,
    canLoadMore,
  } = useProjectCommunities();
  const { projects: myProjects } = useProjectContext();
  const myProjectIds = myProjects.map(project => project.projectId);

  const onSearch = async (keyword: string) => {
    searchCommunities({ keyword });
  };

  useEffect(() => {
    searchCommunities({ projectIds });

    // Remove communities when its project is removed
    const removedCommunities = allCommunities.filter(
      community => !projectIds.includes(community.projectId)
    );
    const newSelectedValues = selectedValues.filter(
      communityId =>
        !removedCommunities.some(
          community => community.organizationId === communityId
        )
    );
    onChange(newSelectedValues, communityOptions);
  }, [projectIds]);

  const myCommunities = useMemo(
    () =>
      allCommunities
        .filter(community => myProjectIds.includes(community.projectId))
        .map(community => ({
          organizationId: community.organizationId,
          organizationName: community.organizationName,
        })),
    [allCommunities]
  );
  const myCommunityIds = useMemo(
    () => myCommunities.map(community => community.organizationId),
    [myCommunities]
  );
  const selectedValues = Array.isArray(value) ? value : [value ?? []].flat();

  // Get communities that in my communities and selected communities
  const communityOptions = useMemo(() => {
    // Disable unassigned initial communities
    const unassignedInitialCommunities = initialCommunities
      ?.filter(
        community =>
          !myCommunityIds.includes(community.id) &&
          selectedValues.includes(community.id)
      )
      .map(community => ({
        organizationId: community.id,
        organizationName: community.name,
        disabled: true,
      }));

    // Disable other unassigned communities
    const unassignedCommunities = allCommunities
      ?.filter(
        community =>
          !myCommunityIds.includes(community.organizationId) &&
          !unassignedInitialCommunities?.some(
            initialCommunity =>
              initialCommunity.organizationId === community.organizationId
          )
      )
      .map(community => ({
        organizationId: community.organizationId,
        organizationName: community.organizationName,
        disabled: true,
      }));

    // Merge all communities
    const mergedCommunities = myCommunities.concat(
      unassignedInitialCommunities ?? [],
      unassignedCommunities ?? []
    );

    // Sort to move selected values to the top
    const sortedCommunities = mergedCommunities.sort((a, b) => {
      const isASelected = selectedValues.includes(a.organizationId);
      const isBSelected = selectedValues.includes(b.organizationId);

      if (isASelected && !isBSelected) {
        return -1;
      } else if (!isASelected && isBSelected) {
        return 1;
      }
      return 0;
    });

    return sortedCommunities;
  }, [allCommunities, myCommunityIds, selectedValues]);

  const search = debounce(onSearch, DEBOUNCE_TIME);

  const onChange = (
    value: number[],
    options: DefaultOptionType | DefaultOptionType[]
  ) => {
    selectProps?.onChange?.(value, options);
  };

  return (
    <Select
      options={communityOptions}
      onChange={onChange}
      value={value}
      showSearch
      filterOption={false}
      onSearch={search}
      loading={loading}
      fieldNames={{ label: 'organizationName', value: 'organizationId' }}
      onPopupScroll={e => {
        const target = e.target as HTMLDivElement;
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
          if (canLoadMore) loadMore();
        }
      }}
      mode="multiple"
      placeholder={
        projectIds?.length
          ? tSelectPlaceholder('Community')
          : t('SelectOneProject')
      }
      maxTagCount={DEFAULT_MAX_TAG_COUNT}
      notFoundContent={loading ? <Spin size="small" /> : t('NoData')}
      dropdownRender={menu => {
        return (
          <>
            <Spin spinning={loading}>{menu}</Spin>
            <div className="text-center">
              <Spin size="small" spinning={loadingMore}></Spin>
            </div>
          </>
        );
      }}
    />
  );
};

export default ProjectCommunitySelect;
