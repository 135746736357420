import { useCremaFormationContext } from 'contexts/cremaFormation';
import React, { ComponentType } from 'react';
import { cremaConstants } from 'constants/index';
import { CremaDrawer } from 'interfaces';

const { CREMA_DRAWERS } = cremaConstants;

const withCremaDrawerManager = <P extends object>(
  Component: ComponentType<P>,
  drawerName: CremaDrawer
) => {
  const WithDrawerManager: React.FC<P & { onClose?: () => void }> = props => {
    const { openDrawer, toggleDrawer } = useCremaFormationContext();
    const drawerConfig = CREMA_DRAWERS[drawerName];
    const children = drawerConfig.dependencies;

    const handleClose = () => {
      const openChildren = children?.filter(
        child =>
          openDrawer.right?.includes(child) || openDrawer.left.includes(child)
      );

      // Close all children drawers
      if (openChildren?.length) {
        openChildren.forEach(child => toggleDrawer(child, { open: false }));
      }

      // Close the parent drawer
      toggleDrawer(drawerName, { open: false });

      props.onClose?.();
    };

    return (
      <Component
        {...(props as P)}
        open={openDrawer[drawerConfig.align]?.includes(drawerName)}
        onClose={handleClose}
      />
    );
  };

  return WithDrawerManager;
};

export default withCremaDrawerManager;
