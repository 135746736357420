import { CloudUploadOutlined } from '@ant-design/icons';
import { Button, Upload, notification } from 'antd';
import { RcFile, UploadFile } from 'antd/es/upload';
import { UploadProps } from 'antd/es/upload/Upload';
import { KML_FILE_TYPES } from 'constants/file';
import { fileHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { uploadHooks } from 'hooks';
import { ILatLng } from 'interfaces';
import { useEffect, useState } from 'react';

const { getPolygonFromKmlFile } = fileHelpers;

export interface KmlFileUploadProps
  extends Omit<UploadProps, 'onChange' | 'value' | 'maxCount'> {
  value?: string;
  onChange?: (url?: string) => void;
  onFileLoaded?: (polygon: ILatLng[]) => void;
  onUploadStateChange?: (isUploading: boolean) => void;
}

const KmlFileUpload: React.FC<KmlFileUploadProps> = ({
  value,
  onChange,
  onFileLoaded,
  onUploadStateChange,
  ...uploadProps
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>(
    value
      ? [
          {
            url: value,
            name: fileHelpers.getFileNameFromStorageURL(value),
          } as UploadFile,
        ]
      : []
  );

  const { uploadFile, uploading } = uploadHooks.useUploadFile();

  useEffect(() => {
    setFileList(
      value
        ? [
            {
              url: value,
              name: fileHelpers.getFileNameFromStorageURL(value),
            } as UploadFile,
          ]
        : []
    );
  }, [value]);

  useEffect(() => {
    onUploadStateChange?.(uploading);
  }, [uploading]);

  const validateAndUploadKmlFile = async (file: RcFile) => {
    const loadedData = await getPolygonFromKmlFile(file as RcFile);
    if (loadedData.error) {
      notification.error({
        message: loadedData.error,
      });
      return Upload.LIST_IGNORE;
    }
    if (file.size > 10 * 1024 * 1024) {
      notification.warning({
        message: t('TheFileBeingUploadedLargerThan10MbPleaseCheckAndTryAgain'),
      });
      return Upload.LIST_IGNORE;
    }

    const splitName = file.name?.split('.');
    const fileType = splitName?.length
      ? splitName[splitName.length - 1]
      : undefined;

    if (fileType !== 'kml') {
      notification.warning({
        message: t('InvalidKMLFile'),
      });
      return Upload.LIST_IGNORE;
    }
    if (loadedData.polygons?.[0]) {
      onFileLoaded?.(loadedData.polygons?.[0]);
    }
  };

  const upload = async ({ file }: any) => {
    const uploadingFile: UploadFile = {
      uid: file.uid,
      name: file.name,
      status: 'uploading',
    };
    setFileList([uploadingFile]);
    const url = await uploadFile(file, {
      type: 'doc',
      fileContentType: 'application/vnd.google-earth.kml+xml',
    });
    setFileList(url ? [{ ...uploadingFile, url, status: 'done' }] : []);
    onChange?.(url ?? undefined);
  };

  return (
    <Upload
      accept={KML_FILE_TYPES}
      beforeUpload={validateAndUploadKmlFile}
      customRequest={upload}
      onRemove={() => {
        setFileList([]);
        onChange?.(undefined);
      }}
      fileList={fileList}
      {...uploadProps}
      multiple={false}
      maxCount={1}
    >
      <Button
        rootClassName="upload-button"
        htmlType="button"
        disabled={uploading}
      >
        {t('FileTypeKmlMaxSize10Mb')}
        <CloudUploadOutlined style={{ color: '#5099FF' }} />
      </Button>
    </Upload>
  );
};

export default KmlFileUpload;
