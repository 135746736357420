import commonConstants from 'constants/common';
import { browserHistory } from 'helpers';
import commonHelpers from 'helpers/common';
import { IRoute } from 'interfaces';
import { useEffect, useRef, useState } from 'react';
import { useFlagsupContext } from 'contexts';

const { LAPTOP_WIDTH } = commonConstants;
const { getWindowDimensions } = commonHelpers;

const useAppMenu = (items: IRoute[]) => {
  const { location } = browserHistory;
  let selectedKey = location.pathname;
  const selectedKeySplitArr = location.pathname.split('/');
  let i = 1;
  let newSelectedKey = '';

  const getParentKey = (key: string): IRoute | undefined => {
    const newParentKey = items.find(
      item => item.children && item.children.includes(key)
    );
    if (newParentKey) return newParentKey;
    else if (i < selectedKeySplitArr.length) {
      newSelectedKey += `/${selectedKeySplitArr[i++]}`;
      selectedKey = newSelectedKey;
      return getParentKey(selectedKey);
    }
  };

  const parentKey = getParentKey(selectedKey);
  const openKey = parentKey ? parentKey.path : '/';

  return { selectedKey, parentKey: '/' + selectedKeySplitArr[1], openKey };
};

const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState(getWindowDimensions);

  useEffect(() => {
    const handleResize = () => setDimensions(getWindowDimensions());
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { ...dimensions, isTabletView: dimensions.width <= LAPTOP_WIDTH };
};

const useIam = () => {
  /**
   * Get clientId & oathDomain
   * PROD: get from env
   * DEV/STAG: get from url / local storage
   */
  const { featureFlagsData } = useFlagsupContext();
  const isCAR2458Enabled = featureFlagsData.CAR_2458?.enabled;

  const getProjectInfo = () => {
    const { iam } = window.appConfig;

    return {
      clientId: isCAR2458Enabled ? iam.clientIdAfterCAR2458 : iam.clientId,
      oauthDomain: iam.oauthDomain,
    };
  };
  return { getProjectInfo };
};

const useDebounceEffect = (
  callback: () => void,
  delay: number,
  deps: any[],
  runOnInitial: boolean = false
) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!runOnInitial && !didMountRef.current) {
      didMountRef.current = true;
      return;
    }

    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => clearTimeout(handler);
  }, deps);
};

type usePreviousType<T> = (value: T) => T | undefined;

const usePrevious: usePreviousType<any> = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export default {
  useAppMenu,
  useWindowDimensions,
  useIam,
  useDebounceEffect,
  usePrevious,
};
