import { t } from 'helpers/i18n';
import { ValidatorRule } from 'rc-field-form/lib/interface';

export function checkTelephone(telephone: string) {
  const regexTelephone = /^\+?[0-9]{9,15}/;
  return regexTelephone.test(telephone);
}

export function checkKMLFile(file: string) {
  const FILE_TYPE = '.kml';
  const fileName = file.trim();
  return (
    fileName.length >= 4 &&
    fileName
      .slice(-4)
      .toLowerCase()
      .includes(FILE_TYPE)
  );
}
const MAXIMUM_TREE_AGE = 9999;
const MAXIMUM_TRUNK_CIRCUMFERENCE = 1000000000;

export const validateCircumferenceUpdate = (_: any, value: number) => {
  if (value > 0 && value <= MAXIMUM_TRUNK_CIRCUMFERENCE)
    return Promise.resolve();
  else return Promise.reject();
};

export const validateTreeAgeUpdate = (_: any, value: number) => {
  if ((value > 0 && value <= MAXIMUM_TREE_AGE) || !value)
    return Promise.resolve();
  else return Promise.reject();
};

export const validateCoordinates = (
  min: number,
  max: number,
  label: string
): ValidatorRule['validator'] => {
  return (_, value, callback) => {
    const numb = Number(value);
    if (isNaN(numb)) return Promise.resolve();
    if (numb < min)
      callback(
        t('NumberMustBeHigherMin', {
          field: label,
          min,
        })
      );
    if (numb > max)
      callback(
        t('NumberMustBeLowerMax', {
          field: label,
          max,
        })
      );
    return callback();
  };
};

/**
 * Use this to clear error message from server side validation
 * Reference : https://github.com/ant-design/ant-design/issues/24599#issuecomment-829898728
 */
export const fakeRule = {
  validator: () => Promise.resolve(),
};
