import { DrawState } from 'constants/map';
import { LatLng } from 'leaflet';

export interface IGetBasemapParams {
  projectId: number;
  basemapType: string;
  polygonType?: string;
  zIndexes?: number[];
  searchLatitudeMin?: number;
  searchLongitudeMin?: number;
  searchLatitudeMax?: number;
  searchLongitudeMax?: number;
}

export interface IPolygonMap {
  latitude: number;
  longitude: number;
}

export interface IBasemapPolygon {
  label?: string;
  polygonMap?: IPolygonMap[];
  multiplePolygonMap?: { polygonMap: IPolygonMap[] }[];
}

export interface IBasemapLabel {
  label?: string;
  area?: number;
}

export interface IBasemapDetail {
  labels: IBasemapLabel[];
}

export interface IGetBasemapResponse {
  basemapType: string;
  polygons: IBasemapPolygon[];
  basemapDetail: IBasemapDetail;
}

export class ILatLng extends LatLng {
  temporary?: boolean;
  originalIndex?: number;
}

export interface IDrawPolygonRef {
  getDrawPolygon: () => LatLng[][];
  setInitialPolygon: (initPolygon?: LatLng[][], drawState?: DrawState) => void;
  stopDrawing: () => void;
  clear: () => void;
  edit: () => void;
  setPolygonViewOnly: () => void;
  refresh?: (farmStatus: string, keyword?: string) => void;
}
