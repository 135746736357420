import React, { createContext, useContext } from 'react';
import { IFpicFormTemplate } from 'interfaces';

export interface FpicContextType {
  title?: React.ReactNode;
  setTitle?: (title?: React.ReactNode) => void;
  template?: IFpicFormTemplate;
  templates?: IFpicFormTemplate[]; // undefined mean loading template
  setTemplate?: (template?: IFpicFormTemplate) => void;
  setTemplates?: (templates: IFpicFormTemplate[]) => void;
}

export const FPICContext = createContext<FpicContextType>({
  templates: undefined,
});

export const useFpicContext = () => useContext(FPICContext);
