/** Third party libs **/
import { Empty, Space, Spin, Typography } from 'antd';
import React, { useContext, useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';

/** Local libs **/
import { t } from 'helpers/i18n';
import { discussionHistoryHooks } from 'hooks';
import FarmCommentCard from './FarmCommentCard';
import ContentBlock from 'components/shared/ContentBlock';

/** Styles **/
import './farmDiscussionHistory.scss';
import { useProjectContext } from 'contexts';
import { CurrentSelectedFarm } from '../../Context';

/** Interfaces, enum... **/
interface IFarmDiscussionHistoryProps {
  selectedFarmId?: number;
  setShowDiscussionHistory: React.Dispatch<React.SetStateAction<boolean>>;
  showDiscussionHistory: boolean;
  triggerReloadComment: boolean;
}

/** Variables **/
const { Title } = Typography;

/** ------------------------- **/
/** FarmDiscussionHistory Component */
/** ------------------------- **/
const FarmDiscussionHistory: React.FC<IFarmDiscussionHistoryProps> = ({
  selectedFarmId,
  setShowDiscussionHistory,
  showDiscussionHistory,
  triggerReloadComment,
}) => {
  /** States **/

  /** Hooks **/
  const {
    loading,
    getFarmDiscussionHistory,
    discussionHistory,
  } = discussionHistoryHooks.useDiscussionHistory(selectedFarmId);
  const { setShowFarmInfo } = useContext(CurrentSelectedFarm);
  const { projectId } = useProjectContext();

  /** Variables **/

  /** Functions, Events, Actions... **/

  /** Effects **/
  useEffect(() => {
    if (selectedFarmId && showDiscussionHistory) {
      getFarmDiscussionHistory(selectedFarmId, projectId);
    }
  }, [selectedFarmId, showDiscussionHistory, triggerReloadComment]);

  /** Elements **/
  return (
    <ContentBlock
      className="farmDiscussionHistory"
      extra={
        <div className="position-relative">
          <div>
            <Title level={5} className="d-block w-100 text-center">
              {t('DiscussionHistory')}
            </Title>
          </div>
          <div className="position-absolute" style={{ top: 0, right: 0 }}>
            <CloseOutlined
              className="extraButton"
              onClick={() => {
                setShowDiscussionHistory(false);
                setShowFarmInfo(true);
              }}
            />
          </div>
        </div>
      }
    >
      <Space direction="vertical" className="w-100">
        {loading ? (
          <Spin spinning={loading} className="w-100" />
        ) : !!discussionHistory.length ? (
          discussionHistory.map(discussion => {
            return <FarmCommentCard comment={discussion} />;
          })
        ) : (
          <Empty />
        )}
      </Space>
    </ContentBlock>
  );
};

export { FarmDiscussionHistory };
export default FarmDiscussionHistory;
