import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import FundList from 'components/Plan/FundList';
import { resourceConstants } from 'constants/index';
import AppContainer from 'containers/AppLayout/AppContainer';
import { t } from 'helpers/i18n';
import { userHooks } from 'hooks';
import React from 'react';
import { Link } from 'react-router-dom';

const { usePermission } = userHooks;

const { APPS, RESOURCES, ACTIONS } = resourceConstants;

const Funds: React.FC = () => {
  const { hasPermission } = usePermission();
  const allowImport = hasPermission(
    APPS.PROJECT_MANAGER_TEKBONE,
    RESOURCES.FUND,
    ACTIONS.IMPORT
  );

  return (
    <AppContainer
      title={
        <Row align="middle" justify={'end'}>
          <Col>
            <Link to="/funds/import">
              <Button
                icon={<PlusOutlined />}
                type="primary"
                disabled={!allowImport}
                title={!allowImport ? t('YouDontHavePermission') : ''}
              >
                {t('ImportFunds')}
              </Button>
            </Link>
          </Col>
        </Row>
      }
    >
      <FundList />
    </AppContainer>
  );
};

export default Funds;
