/** Third party libs * */
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Form, Select, DatePicker, Row, Col, Button, Space } from 'antd';

/** Local libs * */
import { t, tSelectPlaceholder } from 'helpers/i18n';
import { useFpicContext } from '../context';
import {
  FormSubmissionStatus,
  IFPicFormSubmissionsGetParams,
} from 'interfaces';
import { merchantConstants } from 'constants/index';
import { useStoreContext } from 'contexts';
import {
  DATE_REVERSE_DASH_FORMAT,
  DATE_REVERSE_TIME_WS_DASH_FORMAT,
  DATE_SLASH_FORMAT,
} from 'constants/dateTime';

/** Components * */
import { CardItem } from '../shared/CardItem';
import { useFPicForm } from '../hooks/fpic';
import { PAGING_PAGE_SIZE_ALL } from 'constants/pagination';
import { CommunitySelect } from 'components/shared/Select/CommunitySelect';

/** Styles * */

/** ------------------------- * */
/** Interfaces, enum... * */
enum EFilterType {
  Community,
  Stage,
  Status,
  Date,
  Templates,
  Submit,
}
interface FilterProps {
  loading?: boolean;
  projectId: number;
  filter?: IFPicFormSubmissionsGetParams;
  defaultFilter?: IFPicFormSubmissionsGetParams;
  resetFilter: () => void;
  setFilter?: (value: Partial<IFPicFormSubmissionsGetParams>) => void;
}

/** Variables * */
const { MERCHANT_ID_BY_ENV } = merchantConstants;
const statusMap: {
  [k in FormSubmissionStatus]: string;
} = {
  DRAFT: t('DRAFT'),
  REJECTED: t('REJECTED'),
  APPROVED: t('APPROVED'),
  IN_REVIEW: t('IN_REVIEW'),
  NEED_TO_UPDATE: t('NEED_TO_UPDATE'),
};
const FILTER_BY_MERCHANT = {
  [MERCHANT_ID_BY_ENV.AJA]: [
    {
      type: EFilterType.Community,
      props: { xl: 5, md: 6, sm: 12, xs: 24 },
    },
    { type: EFilterType.Stage, props: { xl: 5, md: 6, sm: 12, xs: 24 } },
    {
      type: EFilterType.Status,
      props: { xl: 5, md: 6, sm: 12, xs: 24 },
    },
    { type: EFilterType.Date, props: { xl: 5, md: 6, sm: 12, xs: 24 } },
    {
      type: EFilterType.Submit,
      props: { xl: 4, lg: 24, md: 24, sm: 24, xs: 24 },
    },
    { type: EFilterType.Templates, props: { xl: 5, md: 6, sm: 12, xs: 24 } },
  ],
  [MERCHANT_ID_BY_ENV.WORLDBANK]: [
    { type: EFilterType.Status, props: { lg: 12, md: 12, sm: 24, xs: 24 } },
    { type: EFilterType.Templates, props: { lg: 12, md: 12, sm: 24, xs: 24 } },
    { type: EFilterType.Submit, props: { span: 24 } },
  ],
  [MERCHANT_ID_BY_ENV.DEMO]: [
    { type: EFilterType.Status, props: { lg: 8, md: 8, sm: 12, xs: 24 } },
    { type: EFilterType.Date, props: { lg: 8, md: 8, sm: 12, xs: 24 } },
    { type: EFilterType.Templates, props: { lg: 8, md: 8, sm: 12, xs: 24 } },
    { type: EFilterType.Submit, props: { span: 24 } },
  ],
  [MERCHANT_ID_BY_ENV.WILD_ASIA]: [
    { type: EFilterType.Status, props: { lg: 8, md: 8, sm: 12, xs: 24 } },
    { type: EFilterType.Date, props: { lg: 8, md: 8, sm: 12, xs: 24 } },
    { type: EFilterType.Templates, props: { lg: 8, md: 8, sm: 12, xs: 24 } },
  ],
  [MERCHANT_ID_BY_ENV.ACC]: [
    { type: EFilterType.Status, props: { lg: 8, md: 8, sm: 12, xs: 24 } },
    { type: EFilterType.Date, props: { lg: 8, md: 8, sm: 12, xs: 24 } },
    { type: EFilterType.Templates, props: { lg: 8, md: 8, sm: 12, xs: 24 } },
    { type: EFilterType.Submit, props: { span: 24 } },
  ],
  OTHER: [
    { type: EFilterType.Community, props: { span: 24 } },
    { type: EFilterType.Stage, props: { lg: 6, md: 8, sm: 12, xs: 24 } },
    { type: EFilterType.Status, props: { lg: 6, md: 8, sm: 12, xs: 24 } },
    { type: EFilterType.Date, props: { lg: 6, md: 8, sm: 12, xs: 24 } },
    { type: EFilterType.Templates, props: { lg: 6, md: 8, sm: 12, xs: 24 } },
    { type: EFilterType.Submit, props: { span: 24 } },
  ],
};

dayjs.extend(utc);

/** ------------------------- * */
const Filter: React.FC<FilterProps> = ({
  loading,
  projectId,
  filter,
  defaultFilter,
  setFilter,
  resetFilter,
}) => {
  /** States * */

  /** Hooks * */

  /** Variables * */
  const [form] = Form.useForm();
  const { currentUser } = useStoreContext();
  const { templates } = useFpicContext();
  const itemStyles = {
    marginBottom: 0,
  };
  const statusOptions = Object.entries(statusMap).map(([value, label]) => ({
    value,
    label,
  }));
  const currentMerchantId = currentUser.meta_data?.merchant_id;
  const filterOptions =
    FILTER_BY_MERCHANT[currentMerchantId] || FILTER_BY_MERCHANT.OTHER;
  const { fpicForm } = useFPicForm(PAGING_PAGE_SIZE_ALL);

  /** Effects * */
  useEffect(() => {
    form.resetFields();
  }, [projectId]);

  useEffect(() => {
    if (filter?.formSubmissionId) {
      form.setFieldsValue({
        ...defaultFilter,
        status: undefined,
      });
    }
  }, [filter?.formSubmissionId]);

  /** Functions, Events, Actions... * */
  const onReset = () => {
    form.resetFields();
    resetFilter?.();
  };

  const onSearch = () => {
    const values = form.getFieldsValue();
    const query = {
      ...values,
      page: 1,
      meetingDate: values.meetingDate
        ? dayjs
            .utc(
              `${values.meetingDate.format(DATE_REVERSE_DASH_FORMAT)} 00:00`,
              DATE_REVERSE_TIME_WS_DASH_FORMAT
            )
            .subtract(1, 'second')
            .unix()
        : undefined,
      formSubmissionId: undefined,
    };
    setFilter?.(query);
  };

  const formItems: {
    [key in EFilterType]?: any;
  } = {
    [EFilterType.Community]: (
      <Form.Item name={'stakeholder'} style={itemStyles}>
        <CommunitySelect
          className="w-100"
          placeholder={tSelectPlaceholder('Community')}
          disabled={loading}
        />
      </Form.Item>
    ),
    [EFilterType.Stage]: (
      <Form.Item name={'stageId'} style={itemStyles}>
        <Select
          allowClear={true}
          disabled={loading}
          placeholder={t('SelectStage')}
          options={fpicForm?.stages
            ?.map(item => ({
              value: item.id,
              label: item.name || '',
            }))
            .sort((a, b) => a.label.localeCompare(b.label))}
        />
      </Form.Item>
    ),
    [EFilterType.Status]: (
      <Form.Item name={'status'} style={itemStyles}>
        <Select
          allowClear={true}
          disabled={loading}
          placeholder={t('SelectStatus')}
          options={statusOptions}
        />
      </Form.Item>
    ),
    [EFilterType.Date]: (
      <Form.Item name={'meetingDate'} style={itemStyles}>
        <DatePicker
          disabled={loading}
          format={DATE_SLASH_FORMAT}
          placeholder={t('SelectMeetingDate')}
          className="w-100"
        />
      </Form.Item>
    ),
    [EFilterType.Templates]: (
      <>
        {(templates || []).length > 0 && (
          <Form.Item name={'templateFormId'} style={itemStyles}>
            <Select
              showSearch
              disabled={loading}
              placeholder={t('SelectTemplate')}
              options={templates?.map(template => ({
                label: template.templateFormTitle,
                value: template.templateFormId,
              }))}
              filterOption={(inputValue, option) => {
                return !!option?.label
                  ?.toLowerCase()
                  .includes(inputValue.toLowerCase());
              }}
            />
          </Form.Item>
        )}
      </>
    ),
    [EFilterType.Submit]: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Space style={{ flexWrap: 'wrap' }}>
          <Button disabled={loading} htmlType="button" onClick={onReset}>
            {t('Reset')}
          </Button>
          <Button type={'primary'} disabled={loading} onClick={onSearch}>
            {t('Search')}
          </Button>
        </Space>
      </div>
    ),
  };

  /** Elements * */
  return (
    <CardItem>
      <Form form={form} initialValues={defaultFilter} layout={'vertical'}>
        <Row gutter={[16, 16]}>
          {filterOptions.map((option, index) => {
            const item = formItems[option.type];
            if (!item) return <></>;

            return (
              <Col key={index} {...option.props}>
                {item}
              </Col>
            );
          })}
        </Row>
      </Form>
    </CardItem>
  );
};

export default Filter;
