import {
  IPlotFilters,
  IGetPlotResponse,
  IGetPlotSubmissionParams,
  IGetPlotSubmissionResponse,
  IGetSamplingModuleReponse,
} from 'interfaces';
import { rest } from 'msw';
import { SERVER_ROUTES } from './apiRoute';

const getPlots = (params: IPlotFilters): Promise<IGetPlotResponse> => {
  if (params.projectId === 1) {
    return Promise.resolve({
      data: {
        code: 0,
        traceId: 'string',
        message: 'string',
        data: {
          projects: [
            {
              projectId: 1,
              projectName: 'Ba Vi National Park',
              isSelected: true,
            },
            {
              projectId: 2,
              projectName: 'Quang Nam Magrove Forest',
              isSelected: false,
            },
            {
              projectId: 3,
              projectName: 'Project nam abc',
              isSelected: false,
            },
          ],
          plots: [
            {
              plotId: 1,
              plotName: 'Plot A',
              latitude: 1,
              longitude: 1,
              submitterId: '1',
              createdAt: '06-06-2023',
              selectedSubmissions: 1,
              hasData: true,
              polygonId: 1,
            },
          ],
          polygons: [
            {
              polygonId: 1,
            },
            {
              polygonId: 2,
            },
          ],
        },
      },
    });
  } else if (params.projectId === 2) {
    return Promise.resolve({
      data: {
        code: 0,
        traceId: 'string',
        message: 'string',
        data: {
          projects: [
            {
              projectId: 1,
              projectName: 'Ba Vi National Park',
              isSelected: false,
            },
            {
              projectId: 2,
              projectName: 'Quang Nam Magrove Forest',
              isSelected: true,
            },
            {
              projectId: 3,
              projectName: 'Project nam abc',
              isSelected: false,
            },
          ],
          plots: [
            {
              plotId: 2,
              plotName: 'Plot B',
              latitude: 1,
              longitude: 1,
              submitterId: '1',
              createdAt: '06-06-2023',
              selectedSubmissions: 1,
              hasData: true,
              polygonId: 1,
            },
          ],
          polygons: [
            {
              polygonId: 1,
            },
          ],
        },
      },
    });
  } else {
    return Promise.resolve({
      data: {
        code: 0,
        traceId: 'string',
        message: 'string',
        data: {
          projects: [
            {
              projectId: 1,
              projectName: 'Ba Vi National Park',
              isSelected: false,
            },
            {
              projectId: 2,
              projectName: 'Quang Nam Magrove Forest',
              isSelected: false,
            },
            {
              projectId: 3,
              projectName: 'Project nam abc',
              isSelected: true,
            },
          ],
          plots: [
            {
              plotId: 3,
              plotName: 'Plot C',
              latitude: 1,
              longitude: 1,
              submitterId: '1',
              createdAt: '06-06-2023',
              selectedSubmissions: 1,
              hasData: true,
              polygonId: 1,
            },
          ],
          polygons: [
            {
              polygonId: 1,
            },
          ],
        },
      },
    });
  }
};

export const handlersPlots = [
  rest.post(SERVER_ROUTES.PLOTS.GENERATE_SAMPLING_PLOTS, (req, res, ctx) => {
    const DELAY_TIME = 5000;
    const { samplingPlotsCount } = req.body as any;
    return res(
      ctx.delay(DELAY_TIME),
      ctx.json({
        code: 0,
        traceId: 'string',
        message: 'string',
        data: {
          samplingPlotsCount,
        },
      })
    );
  }),
];

const getPlotsSubmission = (
  _params: IGetPlotSubmissionParams
): Promise<IGetPlotSubmissionResponse> => {
  return Promise.resolve({
    plots: [
      { plotId: 1, plotName: 'Plot 1' },
      { plotId: 2, plotName: 'Plot 2' },
      { plotId: 3, plotName: 'Plot 3' },
      { plotId: 4, plotName: 'Plot 4' },
      { plotId: 5, plotName: 'Plot 5' },
      { plotId: 6, plotName: 'Plot 6' },
      { plotId: 7, plotName: 'Plot 7' },
      { plotId: 8, plotName: 'Plot 8' },
      { plotId: 9, plotName: 'Plot 9' },
      { plotId: 10, plotName: 'Plot 10' },
    ],
    pagination: {
      total: 11,
      offset: 10,
    },
  });
};

const getSamplingModule = (
  _projectId: number
): Promise<IGetSamplingModuleReponse> => {
  return Promise.resolve({
    samplingModuleTypes: [
      {
        id: 1,
        activePlotSet: {
          plotSetId: 1,
          hasSubmissionData: true,
        },
      },
      {
        id: 2,
        activePlotSet: {
          plotSetId: 2,
          hasSubmissionData: false,
        },
      },
    ],
  });
};
export default {
  getPlots,
  getPlotsSubmission,
  getSamplingModule,
};
