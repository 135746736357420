import { getData } from 'helpers/request';
import {
  IDownloadCommunityMeetingMinutesParams,
  IGetListCommunityMeetingMinutesParams,
  IGetListCommunityMeetingMinutesResponse,
} from 'interfaces/meetingRecord';

import { requestServices } from 'services';

const { projectManagerClient } = requestServices;

const PREFIX_API_URL = '/tekbone/api/v1';

const getListCommunityMeetingMinutes = async (
  params: IGetListCommunityMeetingMinutesParams
): Promise<IGetListCommunityMeetingMinutesResponse> => {
  return projectManagerClient
    .get(`${PREFIX_API_URL}/get-list-community-meeting-minutes`, { params })
    .then(getData);
};

const downloadCommunityMeetingMinute = async (
  params: IDownloadCommunityMeetingMinutesParams
) => {
  return projectManagerClient.get(
    `${PREFIX_API_URL}/download-community-meeting-minute`,
    { params: params, responseType: 'arraybuffer' }
  );
};

export default {
  getListCommunityMeetingMinutes,
  downloadCommunityMeetingMinute,
};
