/* eslint-disable no-unused-vars */
import {
  IGetListOperationRolesData,
  IGetListOperationRolesParams,
  IGetListAccountsParams,
  IGetListAccountsResponse,
  IGetUserListReponseData,
  IOrganizationRole,
  OrganizationType,
  UserOrganizationRole,
  UserProfileStatus,
  ICreateOperationRoleResponse,
  IGetOperationPermissionsResponse,
  IGetOperationRoleResponse,
  IUpdateOperationRoleResponse,
  AccountStatus,
  IGetAccountDetailsResponse,
  ICreateAccountResponse,
  IUpdateAccountResponse,
} from 'interfaces';

const MOCK_ORGANIZATION_ROLES = [
  {
    roleCode: UserOrganizationRole.ProvicialFund,
    organizationType: OrganizationType.Province,
  },
  {
    roleCode: UserOrganizationRole.ForestOwner,
    organizationType: OrganizationType.Forest,
  },
  {
    roleCode: UserOrganizationRole.CommunePeopleCommittee,
    organizationType: OrganizationType.Commune,
  },
  {
    roleCode: UserOrganizationRole.CommunityBoardMember,
    organizationType: OrganizationType.Community,
  },
  {
    roleCode: UserOrganizationRole.CommunityMember,
    organizationType: OrganizationType.Community,
  },
];

const MOCK_USERS = [
  {
    id: 7109285200789504,
    phoneNumber: '554-540-1403 x360',
    userOrganizationRoles: [
      {
        organizationName: 'BQL RPH Sao La',
        roleName: UserOrganizationRole.ForestOwner,
      },
      {
        organizationName: 'Thôn Pa Hy',
        roleName: UserOrganizationRole.CommunityBoardMember,
      },
    ],
    profileStatus: UserProfileStatus.Verfied,
    isAccountActive: true,
  },
  {
    id: 8169282884075520,
    phoneNumber: '776.338.5745 x112',
    userOrganizationRoles: [
      {
        organizationName: 'Tỉnh Thừa Thiên Huế',
        roleName: UserOrganizationRole.ProvicialFund,
      },
    ],
    profileStatus: UserProfileStatus.Verfied,
    isAccountActive: true,
  },
  {
    id: 6309769730064384,
    phoneNumber: '1-405-614-7552 x0331',
    userOrganizationRoles: [
      {
        organizationName: 'Xã Hồng Hạ',
        roleName: UserOrganizationRole.CommunePeopleCommittee,
      },
    ],
    profileStatus: UserProfileStatus.NotVerified,
    isAccountActive: true,
  },
];

const getUserList = (): Promise<IGetUserListReponseData> => {
  return Promise.resolve({
    users: MOCK_USERS,
    pagination: {
      offset: 10,
    },
  });
};

const getOrganizationRoles = (): Promise<IOrganizationRole[]> => {
  return Promise.resolve(MOCK_ORGANIZATION_ROLES);
};

const updateAccountStatus = (): Promise<void> => {
  return Promise.resolve();
};

const importUsers = (): Promise<void> => Promise.resolve();

const getListOperationRoles = (
  params: IGetListOperationRolesParams
): Promise<IGetListOperationRolesData> =>
  Promise.resolve({
    operationRoles: [
      {
        id: 1,
        name: 'Project manager - Kwahu',
        description: '',
        updatedAt: 1722312317,
        updatedBy: 'someone@gmail.com',
        projectNames: ['Project 1', 'Project 2'],
        communityNames: ['Community 1', 'Community 2'],
      },
      {
        id: 2,
        name: 'Project coordinator - Kwahu',
        description: '',
        updatedAt: 1722312317,
        updatedBy: 'someone@gmail.com',
        projectNames: ['Project 1', 'Project 2'],
        communityNames: ['Community 1', 'Community 2'],
      },
      {
        id: 3,
        name: 'Investor - Kwahu',
        description: '',
        updatedAt: 1722312317,
        updatedBy: 'someone@gmail.com',
        projectNames: ['Project 1', 'Project 2'],
        communityNames: ['Community 1', 'Community 2'],
      },
      {
        id: 4,
        name: 'Project lead - Kwahu',
        description: '',
        updatedAt: 1722312317,
        updatedBy: 'someone@gmail.com',
        projectNames: ['Project 1', 'Project 2'],
        communityNames: ['Community 1', 'Community 2'],
      },
    ],
    pagination: {
      page: 1,
      pageSize: 10,
      total: 4,
    },
  });

const getListOperationPermissions = (): Promise<IGetOperationPermissionsResponse> =>
  Promise.resolve({
    operationObjects: [
      {
        id: 2001,
        name: 'CREMA boundary',
        permissions: [
          {
            id: 200101,
            type: 'READ',
          },
        ],
      },
      {
        id: 20012,
        name: 'CREMA boundary',
        permissions: [
          {
            id: 2001012,
            type: 'READ',
          },
          {
            id: 2001013,
            type: 'EDIT_OWN',
          },
        ],
      },
    ],
  });

const createOperationRole = async (): Promise<ICreateOperationRoleResponse> =>
  Promise.resolve({
    operationRoleId: 1,
  });

const updateOperationRole = async (): Promise<IUpdateOperationRoleResponse> =>
  Promise.resolve({});

const getOperationRole = async (): Promise<IGetOperationRoleResponse> =>
  Promise.resolve({
    operationRoleId: 1,
    operationRoleName: 'admin',
    description: 'admin role',
    permissionIds: [200101],
    projectIds: [1, 2, 3],
    communityIds: [1, 2, 3],
    projects: [
      {
        id: 1,
        name: 'Project 1',
      },
      {
        id: 2,
        name: 'Project 2',
      },
      {
        id: 3,
        name: 'Project 3',
      },
    ],
    communities: [
      {
        id: 1,
        name: 'Community 1',
      },
      {
        id: 2,
        name: 'Community 2',
      },
      {
        id: 3,
        name: 'Community 3',
      },
    ],
  });

const getListAccounts = (
  params: IGetListAccountsParams
): Promise<IGetListAccountsResponse> =>
  Promise.resolve({
    accounts: [
      {
        id: 1,
        fullName: '1',
        email: '1',
        phoneNumber: '1',
        status: AccountStatus.Active,
      },
      {
        id: 2,
        fullName: '2',
        email: '2',
        phoneNumber: '2',
        status: AccountStatus.Inactive,
      },
    ],
    pagination: { offset: 0 },
  });

const createAccount = (): Promise<ICreateAccountResponse> =>
  Promise.resolve({
    accountId: 1,
  });

const updateAccount = (): Promise<IUpdateAccountResponse> =>
  Promise.resolve({});

const getAccountDetails = (): Promise<IGetAccountDetailsResponse> =>
  Promise.resolve({
    id: 1,
    fullName: '1',
    jobTitle: '1',
    email: '1',
    phoneNumber: '',
    status: AccountStatus.Active,
    operationRoleIds: [1],
    projects: [
      {
        id: 1,
        name: 'Project 1',
      },
    ],
    communities: [
      {
        id: 1,
        name: 'Community 1',
      },
    ],
  });

export default {
  getUserList,
  getOrganizationRoles,
  updateAccountStatus,
  importUsers,
  getListOperationRoles,
  getListOperationPermissions,
  createOperationRole,
  updateOperationRole,
  getOperationRole,
  getListAccounts,
  getAccountDetails,
  createAccount,
  updateAccount,
};
