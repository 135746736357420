import { TablePaginationConfig } from 'antd';
import { commonConstants, communityConstants } from 'constants/index';
import {
  IFund,
  IFundCategory,
  IGetFundsParams,
  IProvinceCommuntiy,
  ICommunity,
  IGetCremaCommunitiesParams,
  IPaginationOffsetResponse,
  ICommunityDetail,
  ICommuneCommunity,
  ICommunityOverview,
  IGetProjectCommunitiesParams,
  IOrganization,
} from 'interfaces';
import { useProjectContext } from 'contexts';
import { useEffect, useState } from 'react';
import { communityServices } from 'services';
import { useStoreContext } from 'contexts';
import {
  DEFAULT_PAGINATION_OFFSET,
  PAGINATION,
  PAGING_OFFSET_ALL,
} from 'constants/pagination';
import { ID_DEFAULT } from 'constants/common';
import { useQuery } from '@tanstack/react-query';
import { paginationHelpers } from 'helpers';

const { QUERY_KEYS } = commonConstants;
const { WORLDBANK_USER_ROLES } = communityConstants;

const { BOARD_MEMBER, FOREST_OWNER, PROVINCIAL_FUND } = WORLDBANK_USER_ROLES;

const useCommunity = (
  params: Omit<IGetCremaCommunitiesParams, 'offset' | 'projectId'>
) => {
  const { limit } = params;

  const { projectId } = useProjectContext();
  const [pagination, setPagination] = useState<IPaginationOffsetResponse>(
    DEFAULT_PAGINATION_OFFSET
  );
  const [communities, setCommunities] = useState<ICommunity[]>([]);
  const [loading, setLoading] = useState(false);
  const showLoadMore = (pagination.total as number) > pagination.offset;

  const getMoreCommunities = async () => {
    await getCommunities({ offset: pagination.offset });
  };

  const getCommunities = async (curPagination?: IPaginationOffsetResponse) => {
    if (!loading) {
      try {
        setLoading(true);

        const offset =
          curPagination?.offset || DEFAULT_PAGINATION_OFFSET.offset;

        const data = await communityServices.getListCremaCommunities({
          offset,
          ...params,
          projectId,
        });
        if (offset >= limit) {
          setCommunities([...communities, ...(data?.communities || [])]);
        } else {
          setCommunities(data?.communities || []);
        }
        setPagination(data?.pagination);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getCommunities();
  }, []);

  return {
    communities,
    getCommunities,
    loading,
    showLoadMore,
    getMoreCommunities,
  };
};

const useProvinceCommunities = () => {
  const [provinceCommunities, setProvinceCommunities] = useState<
    IProvinceCommuntiy[]
  >([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProvinceCommunities = async () => {
      try {
        setLoading(true);
        const {
          communities,
        } = await communityServices.getProvinceCommunities();

        setProvinceCommunities(communities || []);
      } finally {
        setLoading(false);
      }
    };

    fetchProvinceCommunities();
  }, []);

  return { provinceCommunities, loading };
};

const useFundCategories = () => {
  const { currentUser } = useStoreContext();
  const [fundCategories, setFundCategories] = useState<IFundCategory[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchFundCategories = async () => {
      try {
        setLoading(true);
        const { categories } = await communityServices.getFundCategories();
        const { roles = [] } = currentUser || {};
        const allowRoles = roles.reduce((result, current) => {
          switch (current) {
            case BOARD_MEMBER:
              result.push('COMMUNITY');
              break;
            case FOREST_OWNER:
              result.push('FOREST');
              break;
            case PROVINCIAL_FUND:
              result.push(...['PROVINCE', 'FOREST', 'COMMUNITY']);
              break;
          }
          return result;
        }, [] as string[]);

        setFundCategories(
          categories.filter(cat => allowRoles.includes(cat.organizationType)) ||
            []
        );
      } finally {
        setLoading(false);
      }
    };

    fetchFundCategories();
  }, []);

  return { fundCategories, loading };
};

interface IFundsState {
  funds: IFund[];
  pagination: TablePaginationConfig;
}
const useFunds = (communityId?: string | null) => {
  const defaultPagination = { page: 1, pageSize: 10 };
  const [data, setData] = useState<IFundsState>({
    funds: [],
    pagination: {
      current: 1,
      pageSize: PAGINATION.PAGE_SIZE,
      pageSizeOptions: PAGINATION.PAGE_SIZE_OPTIONS,
      showSizeChanger: true,
    },
  });
  const [queryParams, setQueryParams] = useState<IGetFundsParams>({
    ...defaultPagination,
    organizationId: communityId ? parseInt(communityId) : undefined,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchFunds = async () => {
      try {
        setLoading(true);
        const { funds, pagination } = await communityServices.getFunds(
          queryParams
        );
        setData(prev => ({
          funds,
          pagination: {
            ...prev.pagination,
            ...pagination,
            current: pagination.page,
          },
        }));
      } finally {
        setLoading(false);
      }
    };

    fetchFunds();
  }, [queryParams]);

  return { data, queryParams, setQueryParams, loading };
};

const useCremaCommunities = (
  options: Pick<
    IGetCremaCommunitiesParams,
    'isCremaLinked' | 'coordinateInCremaId' | 'cremaId'
  >
) => {
  const [communities, setCommunities] = useState<ICommunity[]>([]);
  const [loading, setLoading] = useState(false);
  const { projectId } = useProjectContext();
  const { coordinateInCremaId } = options;

  const getCommunities = async () => {
    try {
      setLoading(true);
      const params = {
        ...PAGING_OFFSET_ALL,
        ...options,
        projectId,
      };
      const response = await communityServices.getListCremaCommunities(params);
      setCommunities(response.communities);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCommunities();
  }, [coordinateInCremaId]);

  return {
    communities,
    loading,
    getCommunities,
  };
};

const useCommunityDetail = (communityInfo: { id: number }) => {
  const [loading, setLoading] = useState(false);
  const [community, setCommunity] = useState<ICommunityDetail>();
  const { projectId } = useProjectContext();
  const getCommunityDetail = async () => {
    try {
      setLoading(true);
      const community = await communityServices.getCommunityDetail({
        communityId: communityInfo.id,
        projectId,
      });
      setCommunity(community);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (communityInfo.id !== ID_DEFAULT) {
      getCommunityDetail();
    }
  }, [communityInfo]);

  return { community, loading, getCommunityDetail };
};

const useCommunes = () => {
  const { projectId } = useProjectContext();
  const { data: communes = [], isFetching: loading } = useQuery({
    queryKey: [QUERY_KEYS.COMMUNE, projectId],
    queryFn: async () =>
      await communityServices
        .getListCommunes({
          projectId,
        })
        .then(response => response.communes),
  });

  return { communes, loading };
};

const useCommuneCommunities = (communeId: number) => {
  const { projectId } = useProjectContext();
  const [communeCommunities, setCommuneCommunities] = useState<
    ICommuneCommunity[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [allCommunities, setAllCommunities] = useState<ICommuneCommunity[]>([]);

  useEffect(() => {
    const fetchCommunities = async () => {
      try {
        setLoading(true);
        const {
          communities,
        } = await communityServices.getListCommuneCommunities({
          projectId,
          communeId: communeId === ID_DEFAULT ? undefined : communeId,
        });

        if (communeId === ID_DEFAULT) {
          setAllCommunities(communities || []);
        } else {
          setCommuneCommunities(communities || []);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchCommunities();
  }, [communeId]);

  return { communeCommunities, loading, allCommunities };
};

const useCommunities = () => {
  const { projectId } = useProjectContext();
  const [communities, setCommunities] = useState<ICommunity[]>([]);
  const [loading, setLoading] = useState(false);

  const getListCommunities = async () => {
    try {
      setLoading(true);
      const { communities } = await communityServices.getListCommunitiesTekbone(
        {
          projectId,
        }
      );
      setCommunities(communities || []);
    } finally {
      setLoading(false);
    }
  };

  return {
    communities,
    loading,
    getListCommunities,
  };
};

const useCommunesAndCommunities = () => {
  const { projectId } = useProjectContext();

  const {
    data: communes = [],
    isFetching: loading,
    refetch: getCommunesAndCommunities,
  } = useQuery({
    queryKey: [projectId],
    queryFn: async () => {
      return await communityServices
        .getListCommunesAndCommunities({
          projectId,
        })
        .then(res => res.communes);
    },
  });

  return {
    getCommunesAndCommunities,
    communes,
    loading,
  };
};

const useCommunityOverview = () => {
  const { projectId } = useProjectContext();
  const [communityOverview, setCommunityOverview] = useState<
    ICommunityOverview
  >();
  const [loading, setLoading] = useState(false);

  const getCommunityOverview = async (communityId: number) => {
    try {
      setLoading(true);
      const response = await communityServices.getCommunityOverview({
        projectId,
        communityId,
      });
      setCommunityOverview(response);
    } finally {
      setLoading(false);
    }
  };

  return {
    communityOverview,
    loading,
    getCommunityOverview,
  };
};

const useProjectCommunities = () => {
  const [communities, setCommunities] = useState<IOrganization[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [params, setParams] = useState<
    Pick<IGetProjectCommunitiesParams, 'keyword' | 'projectIds'>
  >({ projectIds: [] });
  const [pagination, setPagination] = useState<IPaginationOffsetResponse>({
    offset: 0,
  });
  const [canLoadMore, setCanLoadMore] = useState(false);

  const getProjectCommunities = async (
    params: Omit<IGetProjectCommunitiesParams, 'limit'>
  ) => {
    if (!params?.projectIds?.length) {
      setCommunities([]);
      return;
    }

    const {
      organizations,
      pagination,
    } = await communityServices.getListProjectCommunities({
      ...params,
      limit: PAGING_OFFSET_ALL.limit,
    });
    setCommunities(
      params.offset > 0 ? [...communities, ...organizations] : organizations
    );
    setCanLoadMore(
      paginationHelpers.shouldLoadMore(
        organizations.length,
        PAGING_OFFSET_ALL.limit
      )
    );
    setPagination(pagination);
  };

  const onSearch = async (
    filterOptions?: Partial<
      Pick<IGetProjectCommunitiesParams, 'keyword' | 'projectIds'>
    >
  ) => {
    setParams(prev => ({ ...prev, ...filterOptions }));
    try {
      setLoading(true);
      return await getProjectCommunities({
        ...params,
        ...filterOptions,
        ...DEFAULT_PAGINATION_OFFSET,
      });
    } finally {
      setLoading(false);
    }
  };

  const onLoadMore = async () => {
    try {
      setLoadingMore(true);
      await getProjectCommunities({
        ...params,
        offset: pagination.offset,
      });
    } finally {
      setLoadingMore(false);
    }
  };

  return {
    communities,
    loading,
    loadingMore,
    setParams,
    onSearch,
    onLoadMore,
    pagination,
    canLoadMore,
  };
};

export default {
  useCommunity,
  useProvinceCommunities,
  useFundCategories,
  useFunds,
  useCremaCommunities,
  useCommunityDetail,
  useCommunes,
  useCommuneCommunities,
  useCommunesAndCommunities,
  useCommunities,
  useCommunityOverview,
  useProjectCommunities,
};
