import { MailOutlined, PhoneOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, Form, Button, Space, Row, Col, Tag, Typography } from 'antd';
import { Store } from 'antd/es/form/interface';
import RoleGroupSelect from 'components/RoleGroupManagement/RoleGroupSelect';
import { UpsertUserAccount } from 'components/PermissionManagement';
import { MyProjectSelect } from 'components/shared/Select';
import { DEFAULT_PAGINATION_OFFSET } from 'constants/pagination';
import AppContainer from 'containers/AppLayout/AppContainer';
import ListWithDetailLayout from 'containers/shared/Layout/ListWithDetailLayout';
import { t } from 'helpers/i18n';
import { IAccount, IGetListAccountsParams } from 'interfaces';
import { useEffect, useState } from 'react';
import { userManagementServices } from 'services';

const AccountsManagement = () => {
  const [filters, setFilters] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState<IAccount[]>([]);
  const [nextOffset, setNextOffset] = useState<number>(-1);
  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(
    null
  );
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    onSearch();
  }, []);

  const getListAccounts = async (params: IGetListAccountsParams) => {
    const {
      accounts,
      pagination,
    } = await userManagementServices.getListAccounts(params);
    setNextOffset(
      accounts.length === DEFAULT_PAGINATION_OFFSET.limit
        ? pagination.offset
        : -1
    );
    setAccounts(prev =>
      params.offset > 0 ? [...prev, ...accounts] : accounts
    );
  };

  const onSearch = async (formValues?: Store) => {
    try {
      setLoading(true);
      setFilters(formValues);
      await getListAccounts({ ...DEFAULT_PAGINATION_OFFSET, ...formValues });
    } finally {
      setLoading(false);
    }
  };

  const onLoadMore = async () => {
    await getListAccounts({
      offset: nextOffset,
      limit: DEFAULT_PAGINATION_OFFSET.limit,
      ...filters,
    });
  };

  const onReset = () => {
    form.resetFields();
    onSearch();
  };

  const onUpdateFinish = (updatedAccount: IAccount) => {
    setAccounts(prev => {
      return prev.map(account => {
        if (account.id === updatedAccount.id) {
          return updatedAccount;
        }
        return account;
      });
    });
  };

  return (
    <AppContainer
      title={t('AccountsManagement')}
      classNames={{ body: 'pt-half' }}
    >
      <ListWithDetailLayout>
        <ListWithDetailLayout.Filter>
          <Form form={form} onFinish={onSearch} layout="vertical">
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form.Item
                  name="fullName"
                  label={t('Name')}
                  className="mb-0"
                  rules={[
                    {
                      min: 3,
                      message: t('PleaseInputAtLeast3Characters'),
                    },
                  ]}
                >
                  <Input placeholder={t('Name')} allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="email" label={t('Email')} className="mb-0">
                  <Input placeholder={t('Email')} allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="phoneNumber"
                  label={t('Phone')}
                  className="mb-0"
                >
                  <Input placeholder={t('Phone')} allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="projectIds"
                  label={t('Project')}
                  className="mb-0"
                >
                  <MyProjectSelect allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="operationRoleIds"
                  label={t('Role')}
                  className="mb-0"
                >
                  <RoleGroupSelect
                    mode="multiple"
                    placeholder={t('Role')}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label=" " className="mb-0">
                  <Space>
                    <Button type="primary" htmlType="submit">
                      {t('Search')}
                    </Button>
                    <Button type="default" onClick={onReset}>
                      {t('Reset')}
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </ListWithDetailLayout.Filter>
        <ListWithDetailLayout.List
          rowKey="id"
          onLoadMore={onLoadMore}
          loading={loading}
          actions={
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setIsCreateMode(true);
                setSelectedAccountId(null);
              }}
            >
              {t('Create')}
            </Button>
          }
          dataSource={accounts}
          onItemSelect={item => setSelectedAccountId(item.id)}
          canLoadMore={nextOffset !== -1}
          contentRender={item => (
            <div>
              <Tag
                color={item.status === 'ACTIVE' ? 'success' : 'error'}
                style={{ float: 'right' }}
              >
                {item.status}
              </Tag>
              <div>
                <Typography.Title level={5}>{item.fullName}</Typography.Title>
              </div>
              <div>
                <MailOutlined />{' '}
                {item.email || (
                  <Typography.Text type="secondary">
                    {t('NotAvailable')}
                  </Typography.Text>
                )}
              </div>
              <div>
                <PhoneOutlined />{' '}
                {item.phoneNumber || (
                  <Typography.Text type="secondary">
                    {t('NotAvailable')}
                  </Typography.Text>
                )}
              </div>
            </div>
          )}
        />
        <ListWithDetailLayout.Detail>
          {selectedAccountId ? (
            <UpsertUserAccount
              title={t('DetailUserAccount')}
              selectedAccountId={selectedAccountId}
              onFinish={onUpdateFinish}
            />
          ) : isCreateMode ? (
            <UpsertUserAccount
              title={t('CreateUserAccount')}
              onCancel={() => setIsCreateMode(false)}
              key="create"
            />
          ) : (
            t('PleaseSelectAnAccount')
          )}
        </ListWithDetailLayout.Detail>
      </ListWithDetailLayout>
    </AppContainer>
  );
};

export default AccountsManagement;
