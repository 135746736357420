import { MICROSOFT_ONLINE_VIEWER } from 'constants/file';
import IFrameContainer from 'containers/shared/IFrameContainer';
import InProgressPage from 'containers/shared/InProgressPage';
import { useProjectContext } from 'contexts';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

export interface IFrameContainerProps extends RouteComponentProps {
  iFrameSrc: string;
}

export const PROJECTS = window.appConfig?.projects || {};

const ExternalReport: React.FC<IFrameContainerProps> = props => {
  const { projectId } = useProjectContext();
  const iframeUrl = {
    [PROJECTS.GreenKonservasi]:
      'https://files.tekoapis.com/files/b3ead0ee-b31c-458e-838a-7edf052fa107',
  };
  return iframeUrl[projectId] ? (
    <IFrameContainer
      {...props}
      iFrameSrc={`${MICROSOFT_ONLINE_VIEWER}${iframeUrl[projectId]}`}
    />
  ) : (
    <InProgressPage />
  );
};

export default ExternalReport;
