import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ChartOptions,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { mpiHelpers } from 'helpers';
import { t } from 'helpers/i18n';

const { getMPI } = mpiHelpers;

const options: ChartOptions<'line'> = {
  responsive: true,
  hover: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    y: {
      ticks: {
        stepSize: 0.05,
      },
    },
  },
  plugins: {
    tooltip: {
      mode: 'index',
      intersect: false,
    },
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: t('MPIReport'),
    },
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
);

const MPITrend: React.FC = () => {
  const labels = ['2018', '2019', '2020', '2021', '2022', '2023', 'Now'];
  const data = {
    labels,
    datasets: [
      {
        label: 'MPI',
        data: [
          getMPI(2018),
          getMPI(2019),
          getMPI(2020),
          getMPI(2021),
          getMPI(2022),
          getMPI(2023),
          getMPI(2024),
        ],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };
  return <Line options={options} data={data} />;
};

export { MPITrend };
