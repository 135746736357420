import { Button, Form, Input, Popconfirm, Space } from 'antd';
import { Store } from 'antd/es/form/interface';
import { useProjectContext } from 'contexts';
import { t } from 'helpers/i18n';
import { useEffect, useState } from 'react';
import { activityServices } from 'services';

interface RejectActivityFormProps {
  activityId: number;
  disabled: boolean;
  onApprovalSuccess: () => void;
}

const ApprovalActivityButtons: React.FC<RejectActivityFormProps> = ({
  activityId,
  disabled,
  onApprovalSuccess,
}) => {
  const { projectId } = useProjectContext();

  const [form] = Form.useForm();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<'approve' | 'reject' | null>(null);

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
  }, [open]);

  const onReject = async (values: Store) => {
    try {
      setLoading('reject');
      await activityServices.rejectActivity({
        activityId,
        projectId,
        feedback: values.feedback,
      });
      setOpen(false);
      onApprovalSuccess();
    } finally {
      setLoading(null);
    }
  };

  const onApprove = async () => {
    try {
      setLoading('approve');
      await activityServices.approveActivity({ activityId, projectId });
      onApprovalSuccess();
    } finally {
      setLoading(null);
    }
  };

  const rejectForm = (
    <Form form={form} layout="vertical" onFinish={onReject}>
      <Form.Item
        name="feedback"
        label={t('RejectReason')}
        rules={[{ required: true, message: t('RequiredMessage') }]}
        className="mb-0"
        required
      >
        <Input.TextArea />
      </Form.Item>
    </Form>
  );

  return (
    <Space>
      <Popconfirm
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={form.submit}
        title={null}
        okButtonProps={{
          loading: loading === 'reject',
        }}
        onOpenChange={(open, event) => {
          if (!open && !event) setOpen(false); // Close popconfirm on click outside
        }}
        description={rejectForm}
        icon={null}
      >
        <Button
          danger
          type="primary"
          disabled={disabled || loading === 'approve'}
          onClick={() => setOpen(true)}
        >
          {t('Reject')}
        </Button>
      </Popconfirm>
      <Popconfirm
        title={null}
        description={t('AreYouSureToApproveActivity')}
        okButtonProps={{
          loading: loading === 'approve',
        }}
        onConfirm={onApprove}
      >
        <Button type="primary" disabled={disabled || loading === 'reject'}>
          {t('ApproveActivity')}
        </Button>
      </Popconfirm>
    </Space>
  );
};

export default ApprovalActivityButtons;
