// TODO: Add unitt tests for tracking service
import trackerUtils, { EventData } from './trackerUtils';

const { CLICK_DATA, getPayload, HOVER_DATA } = trackerUtils;

interface ProjectDetailTrackingData {
  edit: EventData;
  upload: EventData;
  formula: EventData;
  viewSelected: EventData;
  viewWaiting: EventData;
}

const PROJECT_DETAIL_TRACKING_DATA: ProjectDetailTrackingData = {
  edit: {
    regionName: 'editProject',
    contentName: 'editProject',
    index: 0,
  },
  upload: {
    regionName: 'uploadPolygon',
    contentName: 'uploadPolygon',
    index: 1,
  },
  formula: {
    regionName: 'formulaExplanation',
    contentName: 'formulaExplanation',
    index: 2,
  },
  viewSelected: {
    regionName: 'viewSeletedSumission',
    index: 3,
  },
  viewWaiting: {
    regionName: 'viewWaitingSumission',
    index: 4,
  },
};

const trackProjectDetailAction = (
  data: EventData,
  contentName?: string,
  hover: boolean = false
) => {
  window.track('manualTrackInteractionContent', {
    ...(hover ? HOVER_DATA : CLICK_DATA),
    screenName: 'projectDetail',
    regionName: data.regionName,
    contentName: contentName,
    target: 'projectDetail',
    payload: getPayload('projectDetail', data.index),
  });
};

export default {
  trackProjectDetailAction,
  PROJECT_DETAIL_TRACKING_DATA,
};
