import { useState, useEffect } from 'react';
import { Form } from 'antd';
import {
  IPlotFilters,
  IPlot,
  IProject,
  IPlotListPolygon,
  IGetProjectPlotsByCoorsParams,
  IGetPlotDetailsParams,
  IGetPlotSubmissionParams,
  IGetPlotSubmissionResponse,
  ISamplingModule,
} from 'interfaces';
import { plotServices, projectServices } from 'services';
import trackSubmissionFilter from 'helpers/tracker/trackSubmissionFilter';
import { useFlagsupContext, useProjectContext } from 'contexts';
import { AxiosRequestConfig } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { commonConstants } from 'constants/index';
import { AppDispatch } from 'redux/store';
import { useDispatch } from 'react-redux';
import { setSamplingModules } from 'redux/features/project';

const { trackProjectChange } = trackSubmissionFilter;
const { QUERY_KEYS } = commonConstants;

/**
 *  @deprecated
 *  Use `usePlots ` instead.
 */
const usePlotData = (defaultFilters: IPlotFilters) => {
  const [plots, setPlots] = useState<IPlot[]>([]);
  const [polygons, setPolygons] = useState<IPlotListPolygon[]>([]);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [filters, setFilters] = useState(defaultFilters);
  const [fetchingPlot, setFetching] = useState(false);
  const [form] = Form.useForm();
  const getPlots = async (params: IPlotFilters) => {
    try {
      setFetching(true);
      const res = await plotServices.getPlots(params);
      if (res.data) {
        const result = res.data.data;
        const { plots, polygons, projects } = result;
        if (!params.projectId) {
          setProjects(projects);
          if (projects.length > 0)
            form.setFieldsValue({ projectId: projects[0]['projectId'] });
        }
        setPlots(plots);
        setPolygons(polygons);
      }
    } finally {
      setFetching(false);
    }
  };

  const handleSelectChange = (projectId: any) => {
    const newQueryParams = {
      projectId: projectId,
    };
    setFilters(newQueryParams);
    trackProjectChange(
      projectId,
      projects.map(project => project.projectId).indexOf(projectId)
    );
  };

  useEffect(() => {
    getPlots(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return {
    plots: plots,
    projects: projects,
    polygons: polygons,
    fetchingPlot,
    handleSelectChange,
    form,
  };
};

const usePlots = () => {
  const [plots, setPlots] = useState<IPlot[]>([]);
  const [loading, setLoading] = useState(false);
  const { projectId } = useProjectContext();
  const [plotDetails, setPlotDetails] = useState<IPlot>();
  const [gettingDetails, setGettingDetails] = useState(false);
  const { featureFlagsData } = useFlagsupContext();
  const isCAR418Enabled = featureFlagsData.CAR_418?.enabled;

  const getPlots = async (
    params: Omit<IGetProjectPlotsByCoorsParams, 'projectId'>,
    configs?: AxiosRequestConfig
  ) => {
    try {
      setLoading(true);
      const apiFunc = isCAR418Enabled
        ? projectServices.getProjectPlotsByCoorsTekbone
        : projectServices.getProjectPlotsByCoors;
      const { plots } = await apiFunc(
        {
          projectId,
          ...params,
        },
        configs
      );
      setPlots(plots);
    } catch {
      // ignore cancelled error
    } finally {
      setLoading(false);
    }
  };

  const getPlotDetails = async (
    plotId: number,
    params?: IGetPlotDetailsParams,
    configs?: AxiosRequestConfig
  ) => {
    try {
      setGettingDetails(true);
      const plot = await projectServices.getPlotDetails(
        plotId,
        params,
        configs
      );
      setPlotDetails(plot);
    } catch {
      // ignore cancelled error
    } finally {
      setGettingDetails(false);
    }
  };

  return {
    plots,
    loading,
    plotDetails,
    gettingDetails,
    getPlots,
    getPlotDetails,
  };
};

const usePlotSubmission = () => {
  const [loading, setLoading] = useState(false);

  const getPlotsSubmissions = async (params: IGetPlotSubmissionParams) => {
    let response: IGetPlotSubmissionResponse = {
      plots: [],
      pagination: { total: 0, offset: 0 },
    };
    try {
      setLoading(true);
      response = await plotServices.getPlotsSubmission(params);
    } finally {
      setLoading(false);
      return response;
    }
  };
  return { getPlotsSubmissions, loading };
};

const useSamplingModules = () => {
  const dispatch: AppDispatch = useDispatch();
  const { projectId } = useProjectContext();
  const { featureFlagsData } = useFlagsupContext();
  const isCAR613Enabled = featureFlagsData.CAR_613?.enabled;

  const {
    data: samplingModules,
    isLoading: loading,
    refetch: getSamplingModules,
  } = useQuery({
    queryKey: [QUERY_KEYS.SAMPLING_MODULES, projectId],
    queryFn: async (): Promise<ISamplingModule[] | undefined> => {
      const samplingModuleType = (
        await plotServices.getSamplingModule(projectId)
      ).samplingModuleTypes;
      dispatch(setSamplingModules(samplingModuleType));
      return samplingModuleType;
    },
    retry: false,
    enabled: isCAR613Enabled,
  });

  return {
    loading,
    samplingModules,
    getSamplingModules,
  };
};

export default {
  usePlotData,
  usePlots,
  usePlotSubmission,
  useSamplingModules,
};
