import { DownOutlined, LogoutOutlined, MenuOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Layout, Switch } from 'antd';
import moonIcon from 'assets/images/moon.png';
import sunIcon from 'assets/images/sun.png';
import ProjectSelect from 'components/shared/Select/ProjectSelect';
import { commonConstants, localizationConstants } from 'constants/index';
import { StoreContext, useFlagsupContext } from 'contexts';
import { localizationHelpers, userHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { stnHooks } from 'hooks';
import { useThemeSwitch } from 'hooks/theme';
import { IRoute } from 'interfaces';
import React, { useContext } from 'react';
import { AppNotifications } from '../AppNotifications';
import { MenuProps } from 'antd/lib/menu';
import { getMetaData } from 'helpers/common';
import { matchPath } from 'react-router-dom';

const { Header } = Layout;
const { REGIONS } = localizationConstants;
const { getCurrentLanguage, changeLanguage } = localizationHelpers;
const { logout } = userHelpers;
const {
  allowDarkTheme,
  supportLangs: supportLangsEnv = ['en'],
} = window.appConfig;

const AppHeader: React.FC<{
  onClickSiderIcon: () => void;
  filteredRoutes: IRoute[];
}> = ({ onClickSiderIcon, filteredRoutes }) => {
  const { currentUser, firebaseToken } = useContext(StoreContext);
  const { unregisterToken } = stnHooks.useNotifications(
    firebaseToken,
    currentUser,
    {
      app_id: commonConstants.STN_APP,
      limit: 10,
      page: 1,
      token: firebaseToken,
    }
  );
  const { featureFlagsData } = useFlagsupContext();
  const isCAR2398Enabled = featureFlagsData.CAR_2398?.enabled;
  const supportLangs = isCAR2398Enabled
    ? getMetaData().supportLangs
    : supportLangsEnv;

  const localizationMenuItems: MenuProps['items'] = Object.values(REGIONS)
    .filter(lang => supportLangs.includes(lang.key))
    .map(el => ({
      key: el.key,
      label: (
        <>
          <Avatar src={el.flag} shape="square" />
          <span style={{ marginLeft: 10 }}>{el.name}</span>
        </>
      ),
      onClick: () => changeLanguage(el.key),
    }));

  const userMenuItems: MenuProps['items'] = [
    {
      key: 'btn-logout',
      label: (
        <>
          <LogoutOutlined />
          <span style={{ marginLeft: 10 }}>{t('Logout')}</span>
        </>
      ),
      onClick: async () => {
        try {
          await unregisterToken();
        } finally {
          logout();
        }
      },
    },
  ];

  const currentRegion = REGIONS[getCurrentLanguage()];

  const { isDarkMode, toggleDarkMode } = useThemeSwitch();
  const currentRouteConfig = filteredRoutes.find(route =>
    matchPath(window.location.pathname, { path: route.path, exact: true })
  );
  const hideProjectSelect = currentRouteConfig?.hideProjectSelect;

  return (
    <Header className="app-header">
      <div className="d-flex align-items-center">
        <MenuOutlined
          data-testid="sider-icon"
          className="app-icon"
          onClick={onClickSiderIcon}
        />
      </div>

      <div className="d-flex align-items-center">
        {!hideProjectSelect && (
          <ProjectSelect className="mr-base" filteredRoutes={filteredRoutes} />
        )}
        <AppNotifications />
        {allowDarkTheme ? (
          <Switch
            style={{
              marginLeft: 24,
            }}
            data-testid="theme-switch"
            className="theme-switch ml-base"
            title={t('SwitchTheme')}
            checked={isDarkMode}
            checkedChildren={
              <img width="16" height="16" src={moonIcon} alt="dark" />
            }
            unCheckedChildren={
              <img width="16" height="16" src={sunIcon} alt="light" />
            }
            onClick={toggleDarkMode}
          />
        ) : (
          <></>
        )}

        <Dropdown menu={{ items: localizationMenuItems }} trigger={['click']}>
          <span className="app-user">
            <Avatar src={currentRegion && currentRegion.flag} shape="square" />
            <span className="label">{currentRegion && currentRegion.name}</span>
            <DownOutlined />
          </span>
        </Dropdown>

        <Dropdown menu={{ items: userMenuItems }} trigger={['click']}>
          <span className="app-user">
            <Avatar src={currentUser.picture} />
            <span className="label">{currentUser.name}</span>
            <DownOutlined />
          </span>
        </Dropdown>
      </div>
    </Header>
  );
};

export default AppHeader;
