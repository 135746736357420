import resourceConstants from 'constants/resource';
import { useProjectContext, useStoreContext } from 'contexts';
import { roleHelpers, userHelpers } from 'helpers';
import { IRoute } from 'interfaces';
import { keyBy } from 'lodash';
import { useEffect, useState } from 'react';
import { userServices } from 'services';
import { IUserDetailsInfo } from 'teko-oauth2';

const { APPS, RESOURCES, ACTIONS } = resourceConstants;

const useUserInfo = () => {
  const [currentUser, setCurrentUser] = useState<IUserDetailsInfo>();

  const getFullUserInfo = async () => {
    const fullUserInfo = await userServices.getFullUserInfo();
    setCurrentUser(fullUserInfo);
  };

  useEffect(() => {
    getFullUserInfo();
  }, []);

  return { currentUser };
};

const useAuthorizationData = (routes: IRoute[]) => {
  const { currentUser } = useStoreContext();
  const { projectId } = useProjectContext();
  const appRoutes = userHelpers.getAppRoutes(projectId);
  const mapRoutes = keyBy(routes, 'path');

  const orderedRoutes = appRoutes.map(route => {
    return {
      ...mapRoutes[route.path],
      ...route,
      ...(route.children ? { children: route.children } : {}),
      path: route.path,
    };
  });
  // Get navigation which match permissions to build menu
  const filteredNavigation = userHelpers.filterHasPermissions(
    orderedRoutes,
    currentUser.permissions
  );

  // Only get routes which is link to a component
  const filteredRoutes = filteredNavigation.filter(
    item => item.component || item.children?.length
  );

  return {
    filteredNavigation,
    filteredRoutes,
  };
};

const useRole = () => {
  const { currentUser } = useStoreContext();

  const hasRole = (app: string, resource: string) => {
    return !!currentUser?.roles?.includes(
      roleHelpers.getPermission(app, resource)
    );
  };

  return {
    hasRole,
  };
};

const usePermission = () => {
  const { currentUser } = useStoreContext();

  const hasPermission = (app: string, resource: string, action: string) => {
    return !!currentUser?.permissions?.includes(
      roleHelpers.getPermission(app, resource, action)
    );
  };
  return {
    hasPermission,
  };
};

const useUserPermissions = () => {
  const { hasPermission } = usePermission();

  const canUploadProject = hasPermission(
    APPS.PROJECT_MANAGER_TEKBONE,
    RESOURCES.PROJECT_BOUNDARIES,
    ACTIONS.CREATE
  );

  const canUpdateProject = hasPermission(
    APPS.PROJECT_MANAGER_TEKBONE,
    RESOURCES.PROJECT_BOUNDARIES,
    ACTIONS.UPDATE
  );

  const canApproveProject = hasPermission(
    APPS.PROJECT_MANAGER_TEKBONE,
    RESOURCES.PROJECT_BOUNDARIES,
    ACTIONS.APPROVE
  );

  const canUpdateMeetingRecords = hasPermission(
    APPS.PROJECT_MANAGER_TEKBONE,
    RESOURCES.MEETING_RECORDS,
    ACTIONS.UPDATE
  );

  const canApproveMeetingRecords = hasPermission(
    APPS.PROJECT_MANAGER_TEKBONE,
    RESOURCES.MEETING_RECORDS,
    ACTIONS.APPROVE
  );

  const canUpdateCrema = hasPermission(
    APPS.PROJECT_MANAGER_TEKBONE,
    RESOURCES.CREMA_FORMATION,
    ACTIONS.UPDATE
  );

  const canUpdateFarmerEnrollment = hasPermission(
    APPS.PROJECT_MANAGER_TEKBONE,
    RESOURCES.FARMER_ENROLLMENT,
    ACTIONS.UPDATE
  );

  const canApproveFarmerEnrollment = hasPermission(
    APPS.PROJECT_MANAGER_TEKBONE,
    RESOURCES.FARMER_ENROLLMENT,
    ACTIONS.APPROVE
  );

  const canApproveActivity = hasPermission(
    APPS.PROJECT_MANAGER_TEKBONE,
    RESOURCES.ACTIVITY,
    ACTIONS.APPROVE
  );

  const canPrintActivity = hasPermission(
    APPS.PROJECT_MANAGER_TEKBONE,
    RESOURCES.ACTIVITY,
    ACTIONS.PRINT
  );

  const canGenerateCarbonPotentialReport = hasPermission(
    APPS.PROJECT_MANAGER_TEKBONE,
    RESOURCES.CARBON_POTENTIAL_REPORT,
    ACTIONS.CREATE
  );

  const canViewCarbonPotentialReport = hasPermission(
    APPS.PROJECT_MANAGER_TEKBONE,
    RESOURCES.CARBON_POTENTIAL_REPORT,
    ACTIONS.READ
  );

  const canUpdateSubmission = hasPermission(
    APPS.PROJECT_MANAGER_TEKBONE,
    RESOURCES.SUBMISSION,
    ACTIONS.UPDATE
  );

  const canEditFarmMappingSurvey = hasPermission(
    APPS.PROJECT_MANAGER_TEKBONE,
    RESOURCES.FARM_LAND_MANAGEMENT,
    ACTIONS.UPDATE_SURVEY
  );

  return {
    canUploadProject,
    canUpdateProject,
    canApproveProject,
    canUpdateMeetingRecords,
    canApproveMeetingRecords,
    canUpdateCrema,
    canUpdateFarmerEnrollment,
    canApproveFarmerEnrollment,
    canApproveActivity,
    canPrintActivity,
    canGenerateCarbonPotentialReport,
    canViewCarbonPotentialReport,
    canUpdateSubmission,
    canEditFarmMappingSurvey,
  };
};

export default {
  useRole,
  usePermission,
  useUserInfo,
  useAuthorizationData,
  useUserPermissions,
};
