import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  notification,
  Space,
  Spin,
} from 'antd';
import { FSImage } from 'components/shared/FSImage';
import { t } from 'helpers/i18n';
import { FormSubmissionStatus, IFormSubmission } from 'interfaces';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { analyzedDataServices } from 'services';
import { Mode } from '../FormItem/BaseItem';
import MediaPlayer from '../FormItem/MediaFile/MediaPlayer';
import { useOCROrTranscribe } from './hooks';
import './OCRAndTranscribe.scss';

export interface OCRAndTranscribeItem {
  url: string;
  id?: number;
  isAnalyzed?: boolean;
}
interface TranscribeProps {
  children?: React.ReactNode;
  type: 'transcribe' | 'ocr';
  open?: boolean;
  setOpen?: (open: boolean) => void;
  item?: OCRAndTranscribeItem;
  mode: Mode;
  formSubmission?: IFormSubmission;
}

const NOT_EDITABLE_STATUS: FormSubmissionStatus[] = ['APPROVED', 'REJECTED'];

const OCRAndTranscribe: React.FC<TranscribeProps> = ({
  type,
  open,
  setOpen,
  item,
  mode,
  formSubmission,
}) => {
  const isAnalyzed = item?.isAnalyzed;
  const {
    analyzedDataObjects,
    loading,
    setAnalyzedDataObjects,
  } = useOCROrTranscribe(isAnalyzed && item?.id ? [item.id] : []);
  const [isEditForm, setIsEditForm] = React.useState(false);
  const [updating, setUpdating] = useState<boolean>(false);

  const [form] = Form.useForm();
  const objectId = item?.id;
  const filename = (item?.url || '').substring(
    (item?.url || '').lastIndexOf('/') + 1
  );
  const isOcr = type === 'ocr';
  const dataObject = analyzedDataObjects?.[0];
  const isEdit = mode === 'EDIT';
  const isEditable =
    isEdit &&
    !!item?.id &&
    formSubmission?.status &&
    !NOT_EDITABLE_STATUS.includes(formSubmission.status);
  const isOCREnable = !!formSubmission?.answers?.find(
    answer =>
      window.config.analyzedDemandQuestionIds?.OCR?.includes(
        answer.questionId
      ) &&
      answer.value &&
      !!(JSON.parse(answer.value) || []).length
  );

  const onOk = async () => {
    if (!objectId) return;

    try {
      const values = await form.validateFields();

      setUpdating(true);
      const result = await analyzedDataServices.updateAnalyzed(
        objectId,
        values.systemDetectedResult || ''
      );

      if (result.data) {
        setIsEditForm(false);
        setAnalyzedDataObjects([
          {
            ...dataObject,
            systemDetectedResult: values.systemDetectedResult,
          },
        ]);
        notification.success({ message: t('UpdateSuccessful') });
      }
    } catch (e) {
    } finally {
      setUpdating(false);
    }
  };

  useEffect(() => {
    setIsEditForm(false);
  }, [open]);

  useEffect(() => {
    if (isEditable) {
      form.setFieldValue(
        'systemDetectedResult',
        dataObject?.systemDetectedResult
      );
    }
  }, [isEditable, dataObject?.systemDetectedResult]);

  useEffect(() => {
    if (!isNil(isAnalyzed) && !isAnalyzed && isOCREnable) {
      notification.info({
        message: t('Analyzing', { type: isOcr ? 'OCR' : 'Transcribe' }),
      });
    }
  }, [item]);

  return (
    <Modal
      open={open}
      centered={true}
      maskClosable={!isEditable}
      title={
        objectId && isAnalyzed
          ? t(isOcr ? 'OcrResult' : 'TranscribeResult')
          : ''
      }
      onCancel={() => setOpen?.(false)}
      okText={t('Ok')}
      cancelText={t('Cancel')}
      footer={isEditForm && !!item?.id ? undefined : false}
      onOk={onOk}
      okButtonProps={{
        loading: updating,
      }}
      width={600}
      className="ocr-transcribe-modal"
      destroyOnClose
    >
      <Spin spinning={loading} className={'text-panel-loading'}>
        <Flex className="w-100 justify-content-center">
          {isOcr ? (
            <div className={'ocr-image'}>
              {/* Already resize */}
              <FSImage src={item?.url} alt={item?.url} />
            </div>
          ) : (
            item?.url && (
              <Flex
                vertical
                align="center"
                className="w-100"
                style={{ marginTop: 24 }}
                gap={16}
              >
                <a href={item.url} download target="_blank" rel="noreferrer">
                  {filename}
                </a>
                <MediaPlayer url={item.url} />
              </Flex>
            )
          )}
        </Flex>
        {!!objectId && isAnalyzed && !loading && (
          <Form form={form} className={'ocr-form'} layout={'vertical'}>
            <Form.Item
              name={'systemDetectedResult'}
              label={
                <Space size={'small'}>
                  {t('Content')}{' '}
                  {isEditable && !isEditForm && (
                    <Button
                      type={'link'}
                      icon={<EditOutlined />}
                      onClick={() => {
                        setIsEditForm(true);
                      }}
                    />
                  )}
                </Space>
              }
            >
              {isEditForm ? (
                <Input.TextArea size={'large'} rows={10} />
              ) : (
                <p className={'text-panel'}>
                  {dataObject?.systemDetectedResult}
                </p>
              )}
            </Form.Item>
          </Form>
        )}
      </Spin>
    </Modal>
  );
};

export default OCRAndTranscribe;
