import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  notification,
} from 'antd';
import { t } from 'helpers/i18n';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { FormItemProps } from 'antd/lib/form';
import { convertDateFormat, filterSelect } from 'helpers/common';
import { GlobalOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { checkTelephone } from 'utils/formValidator';
import { useProjectContext } from 'contexts';
import {
  commonConstants,
  countryConstants,
  localStorageConstants,
  plotConstants,
  projectConstants,
} from 'constants/index';
import { projectServices } from 'services';
import ProjectModalDelete from '../ProjectModalDelete';
import { useDisclosure } from 'components/shared/Modal/useDisclosure';
import { normalizeStringValue } from 'utils/helper';
import { CreateBoundariesConfirmModal } from './CreateBoundariesConfirmModal';
import { useHistory } from 'react-router-dom';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { IProjectDetails } from 'interfaces';
import { useSelector } from 'react-redux';
import { selectGettingProject, selectProject } from 'redux/features/project';

const FORM_PAYLOAD = {
  STANDARD: 'standard',
  LOCATION: 'location',
  TITLE: 'projectTitle',
  ORGANIZATION: 'organizationName',
  TELEPHONE: 'telephone',
  EMAIL: 'email',
  WEBSITE: 'website',
  DESCRIPTION: 'description',
  PLOT_SHAPE: 'plotShape',
};
const DEFAULT_FORM_VALUE = {
  STANDARD: 'PLAN_VIVO',
  LOCATION: 'Vietnam',
};
const { IS_WB, IS_AJA, IS_DEMO } = commonConstants;
const { LS_PROJECT_ID } = localStorageConstants;
const { PLOT_SHAPE, PLOT_SHAPE_OPTIONS } = plotConstants;
const { COUNTRY } = countryConstants;
const { PROJECT_TYPE } = projectConstants;

type formListProps = FormItemProps & {
  placeholder?: string;
  children?: ReactNode;
};
interface ProjectUpsertionDrawerProps {
  isEdit: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetchProject?: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<IProjectDetails | undefined, unknown>>;
}

const ProjectUpsertionDrawer: React.FC<ProjectUpsertionDrawerProps> = ({
  isEdit,
  open,
  setOpen,
  refetchProject,
}) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { projectId, getProjects } = useProjectContext();
  const modalDelete = useDisclosure();
  const createBoundariesConfirmModal = useDisclosure();
  const [upserting, setUpserting] = useState<boolean>(false);
  const project = useSelector(selectProject);
  const gettingProject = useSelector(selectGettingProject);

  const onClose = () => setOpen(false);
  const onUpsert = async () => {
    try {
      await form.validateFields();
      setUpserting(true);
      const res = await projectServices.createProjectTekbone({
        ...normalizeStringValue(form.getFieldsValue()),
        projectId: isEdit ? projectId : undefined,
      });
      notification.success({
        message: isEdit
          ? t('EditProjectSuccessfully')
          : t('CreateProjectSuccessfully'),
      });
      onClose();
      if (isEdit) {
        refetchProject?.();
        getProjects();
      } else {
        localStorage.setItem(LS_PROJECT_ID, res.data.projectId.toString());
        // Worldbank doesn't have Project boundary screen
        // While AJA & DEMO need setup menu for new project, so the project just created don't have Project boundary too
        if (!IS_WB && !IS_AJA && !IS_DEMO) {
          createBoundariesConfirmModal.onOpen();
        } else {
          await getProjects();
          history.push('/');
        }
      }
    } finally {
      setUpserting(false);
    }
  };
  const onShowDeleteConfirm = () => {
    modalDelete.onOpen();
  };

  const footer = (
    <div className="text-center">
      <Button
        key="back"
        onClick={onClose}
        className="mr-half neutral-button w-120px"
      >
        {t('Close')}
      </Button>
      <Button
        htmlType="submit"
        type="primary"
        onClick={onUpsert}
        loading={upserting}
        data-testid="btn-submit"
        className="w-120px"
      >
        {isEdit ? t('SaveProject') : t('CreateProject')}
      </Button>
    </div>
  );

  const formFields: formListProps[] = useMemo(() => {
    return [
      {
        rules: [
          {
            required: true,
            whitespace: true,
            message: t('PleaseInputProjectTitle'),
          },
        ],
        label: t('ProjectTitle'),
        name: FORM_PAYLOAD.TITLE,
        placeholder: t('ProjectTitle'),
        children: <Input maxLength={250} />,
      },
      {
        label: t('Standard'),
        name: FORM_PAYLOAD.STANDARD,
        rules: [{ required: true }],
        children: (
          <Select
            options={PROJECT_TYPE}
            showSearch
            filterOption={(input, option) => filterSelect(input, option)}
          />
        ),
      },
      {
        label: t('Location'),
        name: FORM_PAYLOAD.LOCATION,
        children: (
          <Select
            maxLength={250}
            options={COUNTRY}
            showSearch
            allowClear
            filterOption={(input, option) => filterSelect(input, option)}
          />
        ),
      },
      {
        label: t('Organization'),
        name: FORM_PAYLOAD.ORGANIZATION,
        children: <Input maxLength={250} />,
      },
      {
        label: (
          <label>
            <Space>
              <PhoneOutlined />
              <span>{t('Telephone')}</span>
            </Space>
          </label>
        ),
        name: FORM_PAYLOAD.TELEPHONE,
        children: <Input maxLength={250} type="number" />,
        rules: [
          {
            message: t('InvalidTelephone'),
            validator: (_, value) => {
              if (!value || checkTelephone(value)) return Promise.resolve();
              else return Promise.reject();
            },
          },
        ],
      },
      {
        rules: [
          {
            type: 'email',
            message: t('InvalidEmail'),
          },
        ],
        name: FORM_PAYLOAD.EMAIL,
        label: (
          <label>
            <Space>
              <MailOutlined />
              <span>{t('Email')}</span>
            </Space>
          </label>
        ),
        children: <Input maxLength={250} />,
      },
      {
        name: FORM_PAYLOAD.WEBSITE,
        label: (
          <label>
            <Space>
              <GlobalOutlined />
              <span>{t('Website')}</span>
            </Space>
          </label>
        ),
        children: <Input maxLength={250} />,
      },
      {
        name: FORM_PAYLOAD.PLOT_SHAPE,
        label: t('PlotShape'),
        rules: [{ required: true }],
        children: (
          <Radio.Group disabled={isEdit} options={PLOT_SHAPE_OPTIONS} />
        ),
      },
      {
        label: t('Description'),
        name: FORM_PAYLOAD.DESCRIPTION,
        children: <Input.TextArea maxLength={5000} rows={3} />,
      },
    ];
  }, [isEdit]);

  useEffect(() => {
    if (!isEdit && open) {
      form.resetFields();
    }
    if (project && isEdit && open) {
      form.setFieldsValue(project);
    }
  }, [project, open, isEdit]);

  return (
    <>
      <Drawer
        placement="right"
        width={600}
        zIndex={2000}
        open={open}
        closable={false}
        title={isEdit ? t('ProjectDetail') : t('CreateNewProject')}
        destroyOnClose
        onClose={onClose}
        extra={
          isEdit &&
          project?.isDeletable && (
            <Button type="link" danger onClick={onShowDeleteConfirm}>
              {t('Delete')}
            </Button>
          )
        }
        footer={footer}
      >
        {!isEdit || !gettingProject ? (
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              [FORM_PAYLOAD.LOCATION]: DEFAULT_FORM_VALUE.LOCATION,
              [FORM_PAYLOAD.STANDARD]: DEFAULT_FORM_VALUE.STANDARD,
              [FORM_PAYLOAD.PLOT_SHAPE]: PLOT_SHAPE.RECTANGLE,
            }}
          >
            <Row gutter={8}>
              {formFields.map(formItem => (
                <Col span={24} key={formItem.name}>
                  <Form.Item {...formItem}>{formItem.children}</Form.Item>
                </Col>
              ))}
              {isEdit && project?.creationDate ? (
                <Col span={24}>
                  {t('CreateDate')} : {convertDateFormat(project.creationDate)}
                </Col>
              ) : null}
            </Row>
          </Form>
        ) : (
          <Spin className="d-flex align-items-center justify-content-center h-100" />
        )}
      </Drawer>
      {project && (
        <ProjectModalDelete
          project={project}
          modal={modalDelete}
          refetch={() => {
            onClose();
            getProjects();
          }}
        />
      )}
      <CreateBoundariesConfirmModal modal={createBoundariesConfirmModal} />
    </>
  );
};

export { ProjectUpsertionDrawer };
