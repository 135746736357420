import trackerUtils from './trackerUtils';

const { SUBMISSION_MANAGEMENT_DATA, getPayload } = trackerUtils;

const trackViewTree = (contentName: number, index: number) => {
  window.track('manualTrackInteractionContent', {
    ...SUBMISSION_MANAGEMENT_DATA,
    regionName: 'submissionListResult',
    contentName: contentName,
    payload: getPayload('submissionManagement', index),
  });
};

const trackPageView = (page: number | undefined) => {
  window.track('manualTrackInteractionContent', {
    ...SUBMISSION_MANAGEMENT_DATA,
    regionName: 'bottomBarPageList',
    contentName: page,
    payload: getPayload('submissionManagement', page!),
  });
};

export default {
  trackViewTree,
  trackPageView,
};
