import { Table } from 'antd';
import { ColumnType, TableProps } from 'antd/es/table';
import { WORLDBANK_USER_ROLES } from 'constants/community';
import { DATE_TIME_DASH_FORMAT } from 'constants/dateTime';
import { useStoreContext } from 'contexts';
import dayjs from 'dayjs';
import { convertNumberFormat } from 'helpers/common';
import { t } from 'helpers/i18n';
import { IFund } from 'interfaces';

const { BOARD_MEMBER, FOREST_OWNER, PROVINCIAL_FUND } = WORLDBANK_USER_ROLES;

const FundTable: React.FC<TableProps<IFund>> = props => {
  const { currentUser } = useStoreContext();

  const columns: ColumnType<IFund>[] = [
    {
      title: t('FundCategories'),
      dataIndex: 'categoryName',
    },
    {
      title: t('Target'),
      render: (_, record) => {
        const { roles = [] } = currentUser || {};
        const filteredRoles = roles.filter(role =>
          [BOARD_MEMBER, FOREST_OWNER, PROVINCIAL_FUND].includes(role)
        );
        if (!filteredRoles.length) return '';

        const { organizationName, creatorOrganizationName } = record;
        switch (filteredRoles[0]) {
          case PROVINCIAL_FUND:
          case FOREST_OWNER:
            return organizationName;
          case BOARD_MEMBER:
            return creatorOrganizationName;
          default:
            return '';
        }
      },
    },
    {
      title: t('TotalAmount'),
      render: (_, record) => {
        const { unplannedAmountLastYear, agreedAmount, totalAmount } = record;
        return (
          <>
            {t('UnplannedAmountLastYear')}:{' '}
            <b>{convertNumberFormat(unplannedAmountLastYear)}</b> VND
            <br />
            {t('AgreedAmount')}: <b>{convertNumberFormat(agreedAmount)}</b> VND
            <br />
            {t('TotalAmount')}: <b>{convertNumberFormat(totalAmount)}</b> VND
          </>
        );
      },
    },
    {
      title: t('DueDateSubmitFinancialPlan'),
      dataIndex: 'planSubmissionDeadline',
      render: planSubmissionDeadline =>
        planSubmissionDeadline
          ? dayjs.unix(planSubmissionDeadline).format(DATE_TIME_DASH_FORMAT)
          : '',
    },
  ];
  return <Table rowKey="id" columns={columns} {...props} />;
};

export default FundTable;
