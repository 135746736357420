import { Layout } from 'antd';
import AppBreadcrumb from 'containers/AppLayout/AppBreadcrumb';
import { useProjectContext } from 'contexts';
import { IRoute } from 'interfaces';
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const { Content } = Layout;

interface AppContentProps {
  filteredRoutes: IRoute[];
}

const AppContent: React.FC<AppContentProps> = props => {
  const { filteredRoutes } = props;
  const { projectId } = useProjectContext();

  return (
    <Content className="app-content">
      <Suspense fallback={null}>
        <Switch>
          {filteredRoutes.map(
            ({ component: Component, iFrameSrc, hideBreadcrumb, ...rest }) => {
              return (
                <Route
                  {...rest}
                  key={rest.path}
                  render={routeProps => {
                    const crumbs = filteredRoutes
                      // Get all routes that contain the current one
                      .filter(({ path }) => {
                        const matchingRegex = new RegExp(
                          String.raw`^${path}*(/|$)`
                        );
                        return routeProps.match.path.match(matchingRegex);
                      })
                      // Swap out any dynamic routes with their param values
                      // E.g. "/products/:id" will become "/products/1"
                      .map(({ path, name, redirectTo, useParamName }) => {
                        return {
                          path: redirectTo
                            ? redirectTo
                            : Object.keys(routeProps.match.params).length
                            ? Object.keys(routeProps.match.params).reduce(
                                (path, param) =>
                                  path.replace(
                                    `:${param}`,
                                    routeProps.match.params[param]!
                                  ),
                                path
                              )
                            : path,
                          breadcrumbName: useParamName
                            ? routeProps.match.params[useParamName] || name
                            : name,
                        };
                      });
                    return (
                      <>
                        {!hideBreadcrumb && <AppBreadcrumb crumbs={crumbs} />}
                        <Component
                          key={`${projectId}-${rest.path}`}
                          iFrameSrc={iFrameSrc}
                          name={rest.name}
                          hideTitle={rest.hideTitle}
                          {...routeProps}
                        />
                      </>
                    );
                  }}
                />
              );
            }
          )}
          <Redirect from="/" to="/404" />
        </Switch>
      </Suspense>
    </Content>
  );
};

export default AppContent;
