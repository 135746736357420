import marker from 'assets/images/marker.png';
import classNames from 'classnames';
import { IPOI } from 'interfaces';
import {
  DivIcon,
  Icon,
  IconOptions,
  LeafletMouseEvent,
  Point,
  icon,
} from 'leaflet';
import { useState } from 'react';
import { Marker, Tooltip } from 'react-leaflet';

interface ISmallMarker {
  poi: IPOI;
  alwaysShowLabel?: boolean;
  defaultHideIcon?: boolean;
  iconMarker?: Icon<IconOptions> | DivIcon | undefined;
  onDbClick?: (e: LeafletMouseEvent) => void;
}

const SmallMarker: React.FC<ISmallMarker> = props => {
  const {
    poi,
    alwaysShowLabel,
    defaultHideIcon,
    iconMarker,
    onDbClick,
  } = props;
  const label = poi.placeName || 'Unknown';
  const [hideIcon, setHideIcon] = useState(defaultHideIcon || false);

  return (
    <Marker
      position={[poi.latitude, poi.longitude]}
      key={poi.poiId}
      icon={
        iconMarker ??
        icon({
          iconUrl: marker,
          iconSize: new Point(15, 15),
          className: hideIcon ? 'opacity-0' : 'opacity-1',
        })
      }
      eventHandlers={{
        dblclick: e => onDbClick?.(e),
      }}
    >
      <Tooltip
        direction="right"
        permanent={alwaysShowLabel}
        interactive
        eventHandlers={{
          click: () => setHideIcon(prev => !prev),
        }}
      >
        <span className={classNames({ 'text-link': poi?.isHighlighted })}>
          {label}
        </span>
      </Tooltip>
    </Marker>
  );
};

export default SmallMarker;
