const MERCHANT_ID_BY_ENV = {
  AJA: 100104,
  WORLDBANK: 100112,
  DEMO: 100000,
  WILD_ASIA: 100107,
  ACC: 100106,
};

export default {
  MERCHANT_ID_BY_ENV,
};
