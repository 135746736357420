import React, { useEffect, useState } from 'react';
import { TileLayer, LayersControl } from 'react-leaflet';
import { ILayer, LayerType } from '../interfaces';
import { MAPS, ZOOM_OPTIONS } from '../constants';
import { MapLayerSwitcher } from './MapLayerSwitcher';
import './MapLayers.scss';

interface MapLayersProps {
  children?: React.ReactNode;
  selectedLayerTypes?: LayerType[];
  allowTypes?: LayerType[];
  setSelectedLayerTypes?: React.Dispatch<React.SetStateAction<LayerType[]>>;
  mapRef: any;
}

const MapLayers: React.FC<MapLayersProps> = ({
  selectedLayerTypes,
  allowTypes = ['Default'],
  setSelectedLayerTypes,
}) => {
  const allowLayers = Object.entries(MAPS).reduce((list, [key, value]) => {
    if (allowTypes?.includes(key as LayerType)) {
      list.push(value);
    }

    return list;
  }, [] as ILayer[]);
  const defaultLayer = allowLayers.find(layer => layer.base);

  const [layersOpened, setLayersOpened] = useState<LayerType[]>(
    selectedLayerTypes || (defaultLayer ? [defaultLayer.type] : ['Default'])
  );

  const onLayerTypeChange = (types: LayerType[]) => {
    setLayersOpened(types);
    setSelectedLayerTypes?.(types);
  };

  useEffect(() => {
    if (selectedLayerTypes) setLayersOpened(selectedLayerTypes);
  }, [selectedLayerTypes]);

  return (
    <>
      <MapLayerSwitcher
        allowLayers={allowLayers}
        layersOpened={layersOpened}
        setLayersOpened={onLayerTypeChange}
      />
      <LayersControl>
        {allowLayers.map(layer => {
          const Component = layer.base
            ? LayersControl.BaseLayer
            : LayersControl.Overlay;

          return (
            <Component
              name={layer.name}
              checked={layer.type && layersOpened?.includes(layer.type)}
              key={layer.type}
            >
              <TileLayer
                {...layer}
                maxNativeZoom={ZOOM_OPTIONS.MAX_NATIVE_ZOOM}
                maxZoom={ZOOM_OPTIONS.MAX_ZOOM}
              />
            </Component>
          );
        })}
      </LayersControl>
    </>
  );
};

export default MapLayers;
