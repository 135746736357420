import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { userHooks, commonHooks, flagHooks } from 'hooks';
import routes from 'routes';
import { IRoute } from 'interfaces';
import AppSider from './AppSider';
import AppHeader from './AppHeader';
import AppContent from './AppContent';
import './AppLayout.scss';
import { useFlagsupContext, useProjectContext } from 'contexts';
import { useHistory, useLocation } from 'react-router-dom';
import { getMetaData } from 'helpers/common';

const { useWindowDimensions } = commonHooks;

let autoCollapseSider = true;

/**
 * This container is for lifting-up the `AppContent` to the parent node,
 * so we can avoid unnecessary re-calculation when resizing window
 * */
const AppLayoutContainer: React.FC<{
  filteredNavigation: IRoute[];
  filteredRoutes: IRoute[];
}> = ({ children, filteredNavigation, filteredRoutes }) => {
  const { isTabletView } = useWindowDimensions();
  const [siderCollapsed, setSiderCollapsed] = useState(false);

  const toggleSider = () => {
    autoCollapseSider = false;
    setSiderCollapsed(collapsed => !collapsed);
  };

  useEffect(() => {
    if (autoCollapseSider) {
      setSiderCollapsed(isTabletView);
    }
  }, [isTabletView]);

  return (
    <div className={window.appConfig?.appName || 'base-app'}>
      <Layout className="app-layout">
        <AppSider
          filteredNavigation={filteredNavigation}
          collapsed={siderCollapsed}
          setCollapsed={setSiderCollapsed}
        />
        <Layout>
          <AppHeader
            onClickSiderIcon={toggleSider}
            filteredRoutes={filteredRoutes}
          />
          {children}
        </Layout>
      </Layout>
    </div>
  );
};

const AppLayout: React.FC = () => {
  const {
    filteredNavigation: filteredNavigationByPermissions,
  } = userHooks.useAuthorizationData(routes as IRoute[]);
  const {
    filteredNavigation,
    filteredRoutes,
  } = flagHooks.useFilterNavigationByFeatureFlags(
    filteredNavigationByPermissions
  );
  const { projects, gettingProjects } = useProjectContext();
  const history = useHistory();
  const location = useLocation();
  const { featureFlagsData } = useFlagsupContext();
  const isCAR418Enabled = featureFlagsData.CAR_418?.enabled;

  useEffect(() => {
    if (!projects.length && !gettingProjects && isCAR418Enabled) {
      history.push('/unassigned');
    }
  }, [projects, gettingProjects]);

  useEffect(() => {
    const currentRoute = filteredRoutes.find(({ path }) =>
      path.includes(location.pathname)
    );

    if (currentRoute?.name && currentRoute.path !== '/') {
      document.title = `${currentRoute.name} - ${getMetaData().title}`;
    } else {
      document.title = getMetaData().title;
    }
  }, [location, filteredRoutes]);

  return !projects.length ? null : (
    <AppLayoutContainer
      filteredNavigation={filteredNavigation}
      filteredRoutes={filteredRoutes}
    >
      <AppContent filteredRoutes={filteredRoutes} />
    </AppLayoutContainer>
  );
};

export default AppLayout;
