/** Third party libs **/
import { Select, SelectProps } from 'antd';
import React, { useEffect, useMemo } from 'react';

/** Local libs **/
import { staffHooks } from 'hooks';
import { t } from 'helpers/i18n';

/** Styles **/

/** Interfaces, enum... **/
interface IStaffSelectProps extends SelectProps {
  projectId: number;
}

/** Variables **/

/** ------------------------- **/
const StaffSelect: React.FC<IStaffSelectProps> = ({ projectId, ...props }) => {
  /** States **/

  /** Hooks **/
  const { loading, staffs, getStaffs } = staffHooks.useStaff();

  /** Variables **/
  const memoizedStaffs = useMemo(() => {
    return staffs.map(staff => {
      return {
        value: staff.userId,
        label: staff.userEmail,
      };
    });
  }, [staffs]);

  /** Functions, Events, Actions... **/

  /** Effects **/
  useEffect(() => {
    getStaffs(projectId);
  }, [projectId]);

  /** Elements **/
  return (
    <Select
      placeholder={t('selectStaff')}
      loading={loading}
      mode="multiple"
      options={memoizedStaffs}
      {...props}
    />
  );
};

export { StaffSelect };
export default StaffSelect;
