/** Third party libs **/
import { Button, Card, Col, List, Row, Spin } from 'antd';
import { DoubleLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';

/** Local libs **/
import { IFarmSimple } from 'interfaces';
import { FARM_STATUS } from 'constants/farm';
import FarmCard from './FarmCard';
import { t } from 'helpers/i18n';
import ContentBlock from 'components/shared/ContentBlock';
import { CurrentSelectedFarm } from '../../Context';

/** Styles **/
import './FarmCard.scss';
import './FarmList.scss';

/** Interfaces, enum... **/
interface IFarmListProps {
  listFarmType: string;
  setListFarmType: (type: string) => void;
  loading: boolean;
  loadMore: () => Promise<void>;
  farms: IFarmSimple[];
  showLoadMore: boolean;
}

/** Variables **/

/** ------------------------- **/
/** FarmList Component */
/** ------------------------- **/
const FarmList: React.FC<IFarmListProps> = ({
  listFarmType,
  setListFarmType,
  loading,
  loadMore,
  farms,
  showLoadMore,
}) => {
  /** States **/

  /** Hooks **/
  const {
    setSelectedFarmId,
    selectedFarmId,
    setShowDiscussionHistory,
    setSelectedSurveyForm,
    setShowFarmInfo,
    setCurrentSurveyLocation,
  } = useContext(CurrentSelectedFarm);

  /** Variables **/

  /** Functions, Events, Actions... **/
  const onChangeListFarmType = (type: string) => {
    setListFarmType(type);
    setSelectedFarmId(undefined);
    setShowDiscussionHistory(false);
    setSelectedSurveyForm(undefined);
    setShowFarmInfo(false);
    setCurrentSurveyLocation(undefined);
  };

  /** Effects **/

  useEffect(() => {
    setSelectedFarmId(undefined);
    setShowDiscussionHistory(false);
  }, [listFarmType]);

  /** Elements **/
  return (
    <ContentBlock className="farm-list-wrapper">
      <Row gutter={5}>
        <Col span={8}>
          <Button
            type={'link'}
            onClick={() => {
              onChangeListFarmType(FARM_STATUS.IN_REVIEW);
            }}
            className={classNames(
              { isSelected: listFarmType === FARM_STATUS.IN_REVIEW },
              'w-100'
            )}
          >
            {t('IN_REVIEW')}
          </Button>
        </Col>
        <Col span={8}>
          <Button
            type={'link'}
            onClick={() => {
              onChangeListFarmType(FARM_STATUS.APPROVED);
            }}
            className={classNames(
              { isSelected: listFarmType === FARM_STATUS.APPROVED },
              'w-100'
            )}
          >
            {t('APPROVED')}
          </Button>
        </Col>
        <Col span={8}>
          <Button
            type={'link'}
            onClick={() => {
              onChangeListFarmType(FARM_STATUS.INELIGIBLE);
            }}
            className={classNames(
              { isSelected: listFarmType === FARM_STATUS.INELIGIBLE },
              'w-100'
            )}
          >
            {t('REJECTED')}
          </Button>
        </Col>
      </Row>
      <Card
        styles={{
          body: {
            position: 'relative',
            height: '100%',
          },
        }}
        className="farm-list mt-half"
      >
        <List
          itemLayout="horizontal"
          dataSource={farms}
          loading={loading}
          renderItem={farm => (
            <FarmCard
              key={farm.farmLandId}
              farm={farm}
              selectedFarmId={selectedFarmId}
            />
          )}
          loadMore={
            showLoadMore && (
              <Button type="link" onClick={loadMore} className="w-100">
                {t('LoadMore')}
                {loading ? (
                  <Spin
                    spinning
                    indicator={<LoadingOutlined />}
                    size="small"
                    className="ml-half"
                  />
                ) : (
                  <DoubleLeftOutlined rotate={-90} />
                )}
              </Button>
            )
          }
        />
      </Card>
    </ContentBlock>
  );
};

export { FarmList };
export default FarmList;
