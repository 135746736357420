import { AxiosRequestConfig } from 'axios';
import { ROUTES } from 'constants/routes';
import { getData } from 'helpers/request';
import {
  IGetFarmerByCommunityParams,
  IGetFarmerByCommunityResponse,
  IGetFarmerParams,
  IGetFarmerResponse,
} from 'interfaces';
import QueryString from 'qs';
import { requestServices } from 'services';

const { projectManagerClient } = requestServices;

const getFarmer = async (
  params: IGetFarmerParams,
  config?: AxiosRequestConfig
): Promise<IGetFarmerResponse> => {
  return await projectManagerClient
    .get('/api/v1/list-farmer-profiles', {
      params,
      paramsSerializer: params => {
        return QueryString.stringify(params, { arrayFormat: 'repeat' });
      },
      ...config,
    })
    .then(getData);
};

const getFarmersByCommunity = (
  params: IGetFarmerByCommunityParams
): Promise<IGetFarmerByCommunityResponse> =>
  projectManagerClient
    .post(ROUTES.COMMUNITY.GET_FARMERS_BY_COMMUNITIES, params)
    .then(getData);

export default {
  getFarmer,
  getFarmersByCommunity,
};
