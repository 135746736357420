import { t } from 'helpers/i18n';
import { orgChartRoutes } from './common';

export const baseRoutes = [
  { path: '/' },
  { path: '/project-boundaries' },
  { path: '/communities' },
  { path: '/farmers' },
  { path: '/submissions' },
  { path: '/carbon-monitoring' },
  { path: '/meeting-notes' },
  { path: '/meeting-notes/create' },
  { path: '/meeting-notes/:id' },
  { path: '/report' },
  { path: '/fi-report' },
  {
    path: '/feasibility-study',
    children: ['/carbon-potential-report', '/feasibility-study-report'],
  },
  { path: '/carbon-potential-report' },
  { path: '/feasibility-study-report' },
  { path: '/user-of-project' },
  ...orgChartRoutes,
];

export const IUCNRoutes = [
  { path: '/' },
  // Remote Assessment Phase
  {
    path: '/prefeasibility-assessment',
    children: [
      '/prefeasibility-assessment/historical-land-use-analysis',
      '/prefeasibility-assessment/carbon-revenue-potential',
      '/prefeasibility-assessment/pre-feasibility-study-report',
    ],
  },
  {
    path: '/prefeasibility-assessment/historical-land-use-analysis',
    children: [
      '/prefeasibility-assessment/historical-land-use-analysis/satellite-data',
      '/prefeasibility-assessment/historical-land-use-analysis/remote-sensor',
      '/prefeasibility-assessment/historical-land-use-analysis/local-maps',
    ],
  },
  {
    path:
      '/prefeasibility-assessment/historical-land-use-analysis/satellite-data',
  },
  {
    path:
      '/prefeasibility-assessment/historical-land-use-analysis/remote-sensor',
  },
  {
    path: '/prefeasibility-assessment/historical-land-use-analysis/local-maps',
  },
  {
    path: '/prefeasibility-assessment/carbon-revenue-potential',
  },
  { path: '/prefeasibility-assessment/pre-feasibility-study-report' },

  // Feasibility Phase
  {
    path: '/feasibility-assessment',
    children: [
      '/feasibility-assessment/land-use-permit',
      '/project-boundaries',
      '/feasibility-assessment/ground-truth-data-management',
      '/feasibility-assessment/revenue-potential',
      '/feasibility-assessment/budgeting',
      '/feasibility-assessment/cross-checking-reports',
      '/feasibility-assessment/feasibility-study-reports',
    ],
  },
  { path: '/feasibility-assessment/land-use-permit' },
  { path: '/project-boundaries', name: t('ProjectBoundary'), icon: undefined },
  {
    path: '/feasibility-assessment/ground-truth-data-management',
    children: [
      '/submissions',
      '/feasibility-assessment/ground-truth-data-management/biodiversity',
      '/communities',
    ],
  },
  { path: '/submissions', name: t('Carbon'), icon: undefined },
  { path: '/feasibility-assessment/ground-truth-data-management/biodiversity' },
  { path: '/communities', name: t('Community'), icon: undefined },
  { path: '/feasibility-assessment/revenue-potential' },
  { path: '/feasibility-assessment/budgeting' },
  { path: '/feasibility-assessment/cross-checking-reports' },
  { path: '/feasibility-assessment/feasibility-study-reports' },

  {
    path: '/fund-raising',
    children: ['/fund-raising/off-chain', '/fund-raising/on-chain'],
  },
  { path: '/fund-raising/off-chain' },
  { path: '/fund-raising/on-chain' },

  {
    path: '/community-consent',
    children: [
      '/community-consent/meeting-management',
      '/community-consent/fpic-report',
    ],
  },
  {
    path: '/community-consent/meeting-management',
    children: [
      '/community-consent/meeting-management/meeting-template-management',
      '/meeting-notes',
    ],
  },
  { path: '/community-consent/meeting-management/meeting-template-management' },
  { path: '/meeting-notes', name: t('_MeetingRecord'), icon: undefined },
  { path: '/meeting-notes/create' },
  { path: '/meeting-notes/:id' },
  {
    path: '/community-consent/fpic-report',
    children: ['/community-consent/fpic-report/dashboard'],
  },
  { path: '/community-consent/fpic-report/dashboard' },

  // Project Design
  {
    path: '/project-design',
    children: [
      '/project-design/costing',
      '/project-design/crediting-baseline',
      '/project-design/donor-pool-and-control-plots',
      '/project-design/performance-benchmark',
      '/project-design/carbon-pool',
      '/project-design/project-design-document',
    ],
  },
  { path: '/project-design/costing' },
  { path: '/project-design/crediting-baseline' },
  { path: '/project-design/donor-pool-and-control-plots' },
  {
    path: '/project-design/performance-benchmark',
    children: ['/project-design/performance-benchmark/parameters-selection'],
  },
  { path: '/project-design/performance-benchmark/parameters-selection' },
  {
    path: '/project-design/carbon-pool',
    children: [
      '/project-design/carbon-pool/submission',
      '/project-design/carbon-pool/allometric-equations',
      '/carbon-monitoring',
    ],
  },
  { path: '/project-design/carbon-pool/submission' },
  { path: '/project-design/carbon-pool/allometric-equations' },
  { path: '/carbon-monitoring', icon: undefined },
  {
    path: '/project-design/project-design-document',
    children: [
      '/project-design/project-design-document/sample-reports',
      '/project-design/project-design-document/ai-design-outline',
      '/project-design/project-design-document/ai-design-translation',
      '/project-design/project-design-document/ai-video-outline',
      '/project-design/project-design-document/report',
    ],
  },
  { path: '/project-design/project-design-document/sample-reports' },
  { path: '/project-design/project-design-document/ai-design-outline' },
  { path: '/project-design/project-design-document/ai-design-translation' },
  { path: '/project-design/project-design-document/ai-video-outline' },
  { path: '/project-design/project-design-document/report' },

  // Project Implementation Phase
  {
    path: '/grievances-redress-mechanism',
    children: [
      '/grievances-redress-mechanism/tickets',
      '/grievances-redress-mechanism/form',
      '/grievances-redress-mechanism/whatsapp',
      '/grievances-redress-mechanism/sms',
      '/grievances-redress-mechanism/voice',
      '/grievances-redress-mechanism/ai',
    ],
  },
  { path: '/grievances-redress-mechanism/tickets' },
  { path: '/grievances-redress-mechanism/form' },
  { path: '/grievances-redress-mechanism/whatsapp' },
  { path: '/grievances-redress-mechanism/sms' },
  { path: '/grievances-redress-mechanism/voice' },
  { path: '/grievances-redress-mechanism/ai' },

  {
    path: '/farmer-management',
    children: [
      '/farmers',
      '/farmer-management/farmer-onboarding',
      '/farmer-management/asset-management',
      '/farmer-management/training-management',
      '/farmer-management/project-activities',
      '/farmer-management/kpi-report',
    ],
  },
  { path: '/farmers', name: t('FarmerSourcing'), icon: undefined },
  {
    path: '/farmer-management/farmer-onboarding',
    children: [
      '/farmer-management/farmer-onboarding/application',
      '/farmer-management/farmer-onboarding/approval',
      '/farmer-management/farmer-onboarding/contracting',
    ],
  },
  { path: '/farmer-management/farmer-onboarding/application' },
  { path: '/farmer-management/farmer-onboarding/approval' },
  { path: '/farmer-management/farmer-onboarding/contracting' },
  {
    path: '/farmer-management/asset-management',
    children: [
      '/farmer-management/asset-management/farmland',
      '/farmer-management/asset-management/cattle',
    ],
  },
  { path: '/farmer-management/asset-management/farmland' },
  { path: '/farmer-management/asset-management/cattle' },
  { path: '/farmer-management/training-management' },
  {
    path: '/farmer-management/project-activities',
    children: [
      '/farmer-management/project-activities/planting',
      '/farmer-management/project-activities/shared-natural-resource-management',
    ],
  },
  { path: '/farmer-management/project-activities/planting' },
  {
    path:
      '/farmer-management/project-activities/shared-natural-resource-management',
  },
  {
    path: '/farmer-management/kpi-report',
    children: ['/farmer-management/kpi-report/dashboard'],
  },
  { path: '/farmer-management/kpi-report/dashboard' },
  {
    path: '/asset-managing-agent',
    children: [
      '/asset-managing-agent/meeting-management',
      '/asset-managing-agent/ma-report',
    ],
  },
  {
    path: '/asset-managing-agent/meeting-management',
    children: [
      '/asset-managing-agent/meeting-management/meeting-template-management',
      '/asset-managing-agent/meeting-management/meeting-record',
    ],
  },
  {
    path:
      '/asset-managing-agent/meeting-management/meeting-template-management',
  },
  { path: '/asset-managing-agent/meeting-management/meeting-record' },
  {
    path: '/asset-managing-agent/ma-report',
    children: ['/asset-managing-agent/ma-report/dashboard'],
  },
  { path: '/asset-managing-agent/ma-report/dashboard' },
  {
    path: '/nursery-warehouse',
    children: [
      '/nursery-warehouse/inventory-sites-management',
      '/nursery-warehouse/delivery',
      '/nursery-warehouse/survival-and-health-monitoring',
    ],
  },
  { path: '/nursery-warehouse/inventory-sites-management' },
  { path: '/nursery-warehouse/delivery' },
  { path: '/nursery-warehouse/survival-and-health-monitoring' },

  // Certification
  {
    path: '/verification-and-credit-issuance',
    children: [
      '/verification-and-credit-issuance/certification-module',
      '/verification-and-credit-issuance/inventory-management',
    ],
  },
  { path: '/verification-and-credit-issuance/certification-module' },
  { path: '/verification-and-credit-issuance/inventory-management' },
  {
    path: '/benefit-management',
    children: [
      '/benefit-management/performance-management',
      '/benefit-management/payment-services',
      '/benefit-management/use-of-fund',
      '/benefit-management/financial-reporting',
      '/benefit-management/loyalty-customer',
    ],
  },
  { path: '/benefit-management/performance-management' },
  {
    path: '/benefit-management/payment-services',
    children: [
      '/benefit-management/payment-services/cash-payment',
      '/benefit-management/payment-services/bank-transfer',
      '/benefit-management/payment-services/mobile-money',
      '/benefit-management/payment-services/e-wallet',
      '/benefit-management/payment-services/payment-on-chain',
    ],
  },
  { path: '/benefit-management/payment-services/cash-payment' },
  { path: '/benefit-management/payment-services/bank-transfer' },
  { path: '/benefit-management/payment-services/mobile-money' },
  { path: '/benefit-management/payment-services/e-wallet' },
  { path: '/benefit-management/payment-services/payment-on-chain' },
  { path: '/benefit-management/use-of-fund' },
  { path: '/benefit-management/financial-reporting' },
  { path: '/benefit-management/loyalty-customer' },
  {
    path: '/monitoring-and-evaluation',
    children: [
      '/monitoring-and-evaluation/survey-template-management',
      '/monitoring-and-evaluation/sampling-method-selection',
      '/monitoring-and-evaluation/data',
    ],
  },
  {
    path: '/monitoring-and-evaluation/survey-template-management',
    children: [
      '/monitoring-and-evaluation/survey-template-management/carbon',
      '/monitoring-and-evaluation/survey-template-management/biodiversity',
      '/monitoring-and-evaluation/survey-template-management/community',
    ],
  },
  { path: '/monitoring-and-evaluation/survey-template-management/carbon' },
  {
    path: '/monitoring-and-evaluation/survey-template-management/biodiversity',
  },
  { path: '/monitoring-and-evaluation/survey-template-management/community' },
  {
    path: '/monitoring-and-evaluation/sampling-method-selection',
    children: [
      '/monitoring-and-evaluation/sampling-method-selection/random',
      '/monitoring-and-evaluation/sampling-method-selection/stratifications',
    ],
  },
  { path: '/monitoring-and-evaluation/sampling-method-selection/random' },
  {
    path:
      '/monitoring-and-evaluation/sampling-method-selection/stratifications',
  },
  { path: '/monitoring-and-evaluation/data' },

  // Consultancy Services
  {
    path: '/marketing',
    children: [
      '/marketing/segmentation',
      '/marketing/website',
      '/marketing/social-management',
      '/marketing/performance-ads-management',
      '/marketing/crm',
    ],
  },
  {
    path: '/marketing/segmentation',
    children: ['/marketing/segmentation/cdp'],
  },
  { path: '/marketing/segmentation/cdp' },
  {
    path: '/marketing/website',
    children: [
      '/marketing/website/seo',
      '/marketing/website/ai-gen-template-website',
    ],
  },
  { path: '/marketing/website/seo' },
  { path: '/marketing/website/ai-gen-template-website' },
  { path: '/marketing/social-management' },
  { path: '/marketing/performance-ads-management' },
  {
    path: '/marketing/crm',
    children: [
      '/marketing/crm/farmer-profiling',
      '/marketing/crm/pipeline-reporting-and-optimization',
      '/marketing/crm/customer-loyalty',
    ],
  },
  { path: '/marketing/crm/farmer-profiling' },
  { path: '/marketing/crm/pipeline-reporting-and-optimization' },
  { path: '/marketing/crm/customer-loyalty' },
  {
    path: '/credit-management',
    children: [
      '/credit-management/ico-smartContract',
      '/credit-management/security-exchange-and-market-making',
      '/credit-management/on-chain-transaction-tracing',
      '/credit-management/financial-reportings-and-dashboards',
      '/credit-management/on-chain-content-generation',
    ],
  },
  { path: '/credit-management/ico-smartContract' },
  { path: '/credit-management/security-exchange-and-market-making' },
  { path: '/credit-management/on-chain-transaction-tracing' },
  { path: '/credit-management/financial-reportings-and-dashboards' },
  { path: '/credit-management/on-chain-content-generation' },
  {
    path: '/data-analysis',
    children: [
      '/data-analysis/bi',
      '/data-analysis/dashboard',
      '/data-analysis/statistics',
      '/data-analysis/nlp',
      '/data-analysis/image-processing',
      '/data-analysis/survey-design',
      '/data-analysis/sampling-design',
    ],
  },
  { path: '/data-analysis/bi' },
  { path: '/data-analysis/dashboard' },
  { path: '/data-analysis/statistics' },
  { path: '/data-analysis/nlp' },
  { path: '/data-analysis/image-processing' },
  { path: '/data-analysis/survey-design' },
  { path: '/data-analysis/sampling-design' },
];

const PROJECTS = window.appConfig?.projects || {};

export const mapBaseRoutes = {
  [PROJECTS.IUCN]: IUCNRoutes,
};
