import { Select, SelectProps } from 'antd';
import { filterSelect } from 'helpers/common';
import { tSelectPlaceholder } from 'helpers/i18n';
import { communityHooks } from 'hooks';

const { useFundCategories } = communityHooks;

/**
 *
 * @param props Antd SelectProps
 * @returns Antd Select component with options are communities that user has permission to access
 */
const FundCategoySelect: React.FC<SelectProps<number>> = props => {
  const { fundCategories, loading } = useFundCategories();
  return (
    <Select
      allowClear
      showSearch
      placeholder={tSelectPlaceholder('FundCategories')}
      options={fundCategories.map(item => ({
        label: item.name,
        value: item.id,
      }))}
      loading={loading}
      filterOption={filterSelect}
      {...props}
    />
  );
};

export default FundCategoySelect;
