import {
  CheckSquareOutlined,
  CompassOutlined,
  ContactsOutlined,
  CreditCardOutlined,
  DatabaseOutlined,
  FundOutlined,
  GlobalOutlined,
  GroupOutlined,
  HomeOutlined,
  IdcardOutlined,
  MailOutlined,
  MonitorOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { t } from 'helpers/i18n';
import FPICCreate from 'containers/FPIC/Create';
import FPICDetail from 'containers/FPIC/Detail';
import FPICList from 'containers/FPIC/List';
import ProjectMap from 'containers/Project/ProjectMap';
import ProjectDetailMapV2 from 'containers/Project/ProjectDetailMapV2';
import { withFeatureFlag } from 'hocs';
import InProgressPage from 'containers/shared/InProgressPage';
import ExternalReport from 'containers/ProjectSourcing/ExternalReport';
import LandPermit from 'containers/ProjectSourcing/LandPermit';
import { lazy } from 'react';
import Community from 'containers/CREMA/Community';

const CarbonAssessment = lazy(() =>
  import('containers/ProjectSourcing/CarbonAssessment')
);
const SubmissionList = lazy(() =>
  import('containers/Submission/SubmissionList')
);
const SubmissionListV2 = lazy(() =>
  import('containers/Submission/SubmissionListV2')
);
const FeasibilityAssessmentReport = lazy(() =>
  import('containers/Report/FeasibilityAssessmentReport')
);

// Route configs for ACC
export const accRoutes = [
  // Remote Assessment Phase
  {
    exact: true,
    path: '/historical-land-use-analysis',
    name: t('HistoricalLandUseAnalysis'),
    icon: CheckSquareOutlined,
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/historical-land-use-analysis/satellite-data',
    name: t('SatelliteData'),
    icon: CheckSquareOutlined,
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/historical-land-use-analysis/remote-sensor',
    name: t('RemoteSensor'),
    icon: CheckSquareOutlined,
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/historical-land-use-analysis/local-maps',
    name: t('LocalMaps'),
    icon: CheckSquareOutlined,
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/carbon-revenue-potential',
    name: t('CarbonRevenuePotential'),
    icon: CheckSquareOutlined,
    component: CarbonAssessment,
  },
  {
    exact: true,
    path: '/pre-feasibility-study-report',
    name: t('PrefeasibilityStudyReport'),
    component: InProgressPage,
  },

  // Feasibility Phase
  {
    exact: true,
    path: '/prefeasibility-assessment',
    name: t('Pre-feasibilityAssessment'),
    icon: IdcardOutlined,
  },
  {
    exact: true,
    path: '/prefeasibility-assessment/historical-land-use-analysis',
    name: t('HistoricalLandUseAnalysis'),
    component: InProgressPage,
  },
  {
    exact: true,
    path:
      '/prefeasibility-assessment/historical-land-use-analysis/satellite-data',
    name: t('SatelliteData'),
    component: InProgressPage,
  },
  {
    exact: true,
    path:
      '/prefeasibility-assessment/historical-land-use-analysis/remote-sensor',
    name: t('RemoteSensor'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/prefeasibility-assessment/historical-land-use-analysis/local-maps',
    name: t('LocalMaps'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/prefeasibility-assessment/carbon-revenue-potential',
    name: t('CarbonRevenuePotential'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/prefeasibility-assessment/pre-feasibility-study-report',
    name: t('Pre-feasibilityStudyReport'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/feasibility-assessment',
    name: t('FeasibilityAssessment'),
    icon: CompassOutlined,
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/feasibility-assessment/land-use-permit',
    name: t('LandUsePermit'),
    component: LandPermit,
  },
  {
    exact: true,
    path: '/feasibility-assessment/project-boundaries',
    name: t('ProjectBoundaries'),
    component: withFeatureFlag(ProjectMap, ProjectDetailMapV2, 'CAR_418'),
  },
  {
    exact: true,
    path: '/feasibility-assessment/ground-truth-data-management',
    name: t('GroundTruthDataManagement'),
    component: withFeatureFlag(SubmissionList, SubmissionListV2, 'CAR_2356'),
  },
  {
    exact: true,
    path: '/feasibility-assessment/ground-truth-data-management/carbon',
    name: t('Carbon'),
    component: withFeatureFlag(SubmissionList, SubmissionListV2, 'CAR_2356'),
  },
  {
    exact: true,
    path: '/feasibility-assessment/ground-truth-data-management/biodiversity',
    name: t('Biodiversity'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/feasibility-assessment/ground-truth-data-management/communities',
    name: t('Communities'),
    component: Community,
  },
  {
    exact: true,
    path: '/feasibility-assessment/revenue-potential',
    name: t('RevenuePotential'),
    component: CarbonAssessment,
  },
  {
    exact: true,
    path: '/feasibility-assessment/budgeting',
    name: t('Budgeting'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/feasibility-assessment/cross-checking-reports',
    name: t('Cross-checkingReports'),
    component: ExternalReport,
  },
  {
    exact: true,
    path: '/feasibility-assessment/feasibility-study-reports',
    name: t('FeasibilityStudyReports'),
    component: FeasibilityAssessmentReport,
  },
  {
    exact: true,
    path: '/fund-raising',
    name: t('FundRaising'),
    icon: FundOutlined,
  },
  {
    exact: true,
    path: '/fund-raising/off-chain',
    name: t('Off-chain'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/fund-raising/on-chain',
    name: t('On-chain'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/community-consent',
    name: t('CommunityConsent'),
    icon: GroupOutlined,
  },
  {
    exact: true,
    path: '/community-consent/meeting-management',
    name: t('MeetingManagement'),
    component: FPICList,
    hideTitle: true,
  },
  {
    exact: true,
    path: '/community-consent/meeting-management/create',
    name: t('CreateForm'),
    component: FPICCreate,
  },
  {
    exact: true,
    path: '/community-consent/meeting-management/:id',
    name: t('FormDetails'),
    component: FPICDetail,
  },
  {
    exact: true,
    path: '/community-consent/meeting-management/meeting-template-management',
    name: t('MeetingTemplateManagement'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/community-consent/fpic-report',
    name: t('FPICReport'),
  },
  {
    exact: true,
    path: '/community-consent/fpic-report/dashboard',
    name: t('Dashboard'),
    component: InProgressPage,
  },
  // Project Design
  {
    exact: true,
    path: '/project-design',
    name: t('ProjectDesign'),
    icon: MailOutlined,
  },
  {
    exact: true,
    path: '/project-design/costing',
    name: t('Costing'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/project-design/crediting-baseline',
    name: t('CreditingBaseline'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/project-design/donor-pool-and-control-plots',
    name: t('DonorPool&ControlPlots'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/project-design/performance-benchmark',
    name: t('PerformanceBenchmark'),
  },
  {
    exact: true,
    path: '/project-design/performance-benchmark/parameters-selection',
    name: t('ParametersSelection'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/project-design/carbon-pool',
    name: t('CarbonPool'),
  },
  {
    exact: true,
    path: '/project-design/carbon-pool/submission',
    name: t('Submission'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/project-design/carbon-pool/allometric-equations',
    name: t('AllometricEquations'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/carbon-monitoring',
    name: t('CarbonMonitoring'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/project-design/project-design-document',
    name: t('ProjectDesignDocument'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/project-design/project-design-document/sample-reports',
    name: t('SampleReports'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/project-design/project-design-document/ai-design-outline',
    name: t('AIDesignOutline'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/project-design/project-design-document/ai-design-translation',
    name: t('AIDesignTranslation'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/project-design/project-design-document/ai-video-outline',
    name: t('AIVideoOutline'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/project-design/project-design-document/report',
    name: t('Report'),
    component: InProgressPage,
  },
  // Project Implementation Phase
  {
    exact: true,
    path: '/grievances-redress-mechanism',
    name: t('GrievancesRedressMechanism'),
    icon: ContactsOutlined,
  },
  {
    exact: true,
    path: '/grievances-redress-mechanism/tickets',
    name: t('Tickets'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/grievances-redress-mechanism/form',
    name: t('Form'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/grievances-redress-mechanism/whatsapp',
    name: t('Whatsapp'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/grievances-redress-mechanism/sms',
    name: t('SMS'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/grievances-redress-mechanism/voice',
    name: t('Voice'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/grievances-redress-mechanism/ai',
    name: t('AI'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/farmer-management',
    name: t('FarmerManagement'),
    icon: TeamOutlined,
  },
  {
    exact: true,
    path: '/farmer-management/farmer-onboarding',
    name: t('FarmerOnboarding'),
  },
  {
    exact: true,
    path: '/farmer-management/farmer-onboarding/application',
    name: t('Application'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/farmer-management/farmer-onboarding/approval',
    name: t('Approval'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/farmer-management/farmer-onboarding/contracting',
    name: t('Contracting'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/farmer-management/asset-management',
    name: t('AssetManagement'),
  },
  {
    exact: true,
    path: '/farmer-management/asset-management/farmland',
    name: t('Farmland'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/farmer-management/asset-management/cattle',
    name: t('Cattle'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/farmer-management/training-management',
    name: t('TrainingManagement'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/farmer-management/project-activities',
    name: t('ProjectActivities'),
  },
  {
    exact: true,
    path: '/farmer-management/project-activities/planting',
    name: t('Planting'),
    component: InProgressPage,
  },
  {
    exact: true,
    path:
      '/farmer-management/project-activities/shared-natural-resource-management',
    name: t('SharedNaturalResourceManagement'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/farmer-management/kpi-report',
    name: t('KPIReport'),
  },
  {
    exact: true,
    path: '/farmer-management/kpi-report/dashboard',
    name: t('Dashboard'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/asset-managing-agent',
    name: t('AssetManagingAgent'),
    icon: UserOutlined,
  },
  {
    exact: true,
    path: '/asset-managing-agent/meeting-management',
    name: t('MeetingManagement'),
  },
  {
    exact: true,
    path:
      '/asset-managing-agent/meeting-management/meeting-template-management',
    name: t('MeetingTemplateManagement'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/asset-managing-agent/meeting-management/meeting-record',
    name: t('MeetingRecord'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/asset-managing-agent/ma-report',
    name: t('MAReport'),
  },
  {
    exact: true,
    path: '/asset-managing-agent/ma-report/dashboard',
    name: t('Dashboard'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/nursery-warehouse',
    name: t('NurseryWarehouse'),
    icon: HomeOutlined,
  },
  {
    exact: true,
    path: '/nursery-warehouse/inventory-sites-management',
    name: t('InventorySitesManagement'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/nursery-warehouse/delivery',
    name: t('Delivery'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/nursery-warehouse/survival-and-health-monitoring',
    name: t('Survival&HealthMonitoring'),
    component: InProgressPage,
  },

  // Certification
  {
    exact: true,
    path: '/verification-and-credit-issuance',
    name: t('Verification&CreditIssuance'),
    icon: CheckSquareOutlined,
  },
  {
    exact: true,
    path: '/verification-and-credit-issuance/certification-module',
    name: t('CertificationModule'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/verification-and-credit-issuance/inventory-management',
    name: t('InventoryManagement'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/benefit-management',
    name: t('BenefitManagement'),
    icon: CreditCardOutlined,
  },
  {
    exact: true,
    path: '/benefit-management/performance-management',
    name: t('PerformanceManagement'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/benefit-management/payment-services',
    name: t('PaymentServices'),
  },
  {
    exact: true,
    path: '/benefit-management/payment-services/cash-payment',
    name: t('CashPayment'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/benefit-management/payment-services/bank-transfer',
    name: t('BankTransfer'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/benefit-management/payment-services/mobile-money',
    name: t('MobileMoney'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/benefit-management/payment-services/e-wallet',
    name: t('E-wallet'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/benefit-management/payment-services/payment-on-chain',
    name: t('PaymentOnChain'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/benefit-management/use-of-fund',
    name: t('UseOfFund'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/benefit-management/financial-reporting',
    name: t('FinancialReporting'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/benefit-management/loyalty-customer',
    name: t('LoyaltyCustomer'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/monitoring-and-evaluation',
    name: t('Monitoring&Evaluation'),
    icon: MonitorOutlined,
  },
  {
    exact: true,
    path: '/monitoring-and-evaluation/survey-template-management',
    name: t('SurveyTemplateManagement'),
  },
  {
    exact: true,
    path: '/monitoring-and-evaluation/survey-template-management/carbon',
    name: t('Carbon'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/monitoring-and-evaluation/survey-template-management/biodiversity',
    name: t('Biodiversity'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/monitoring-and-evaluation/survey-template-management/community',
    name: t('Community'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/monitoring-and-evaluation/sampling-method-selection',
    name: t('SamplingMethodSelection'),
  },
  {
    exact: true,
    path: '/monitoring-and-evaluation/sampling-method-selection/random',
    name: t('Random'),
    component: InProgressPage,
  },
  {
    exact: true,
    path:
      '/monitoring-and-evaluation/sampling-method-selection/stratifications',
    name: t('Stratifications'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/monitoring-and-evaluation/data',
    name: t('Data'),
    component: InProgressPage,
  },

  // Marketing
  {
    exact: true,
    path: '/marketing',
    name: t('Marketing'),
    icon: GlobalOutlined,
  },
  {
    exact: true,
    path: '/marketing/segmentation',
    name: t('Segmentation'),
  },
  {
    exact: true,
    path: '/marketing/segmentation/cdp',
    name: t('CDP'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/marketing/website',
    name: t('Website'),
  },
  {
    exact: true,
    path: '/marketing/website/seo',
    name: t('SEO'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/marketing/website/ai-gen-template-website',
    name: t('AIGenTemplateWebsite'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/marketing/social-management',
    name: t('SocialManagement'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/marketing/performance-ads-management',
    name: t('PerformanceAdsManagement'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/marketing/crm',
    name: t('CRM'),
  },
  {
    exact: true,
    path: '/marketing/crm/farmer-profiling',
    name: t('FarmerProfiling'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/marketing/crm/pipeline-reporting-and-optimization',
    name: t('PipelineReporting&Optimization'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/marketing/crm/customer-loyalty',
    name: t('CustomerLoyalty'),
    component: InProgressPage,
  },

  // Credit Management
  {
    exact: true,
    path: '/credit-management',
    name: t('CreditManagement'),
    icon: CreditCardOutlined,
  },
  {
    exact: true,
    path: '/credit-management/ico-smartContract',
    name: t('ICOSmartContract'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/credit-management/security-exchange-and-market-making',
    name: t('SecurityExchange&MarketMaking'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/credit-management/on-chain-transaction-tracing',
    name: t('On-chainTransactionTracing'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/credit-management/financial-reportings-and-dashboards',
    name: t('FinancialReportings&Dashboards'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/credit-management/on-chain-content-generation',
    name: t('On-chainContentGeneration'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/data-analysis',
    name: t('DataAnalysis'),
    icon: DatabaseOutlined,
  },
  {
    exact: true,
    path: '/data-analysis/bi',
    name: t('BI'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/data-analysis/dashboard',
    name: t('Dashboard'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/data-analysis/statistics',
    name: t('Statistics'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/data-analysis/nlp',
    name: t('NLP'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/data-analysis/image-processing',
    name: t('ImageProcessing'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/data-analysis/survey-design',
    name: t('SurveyDesign'),
    component: InProgressPage,
  },
  {
    exact: true,
    path: '/data-analysis/sampling-design',
    name: t('SamplingDesign'),
    component: InProgressPage,
  },
];
