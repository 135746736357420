import { Select, SelectProps } from 'antd';
import { tSelectPlaceholder } from 'helpers/i18n';
import React from 'react';

const JobTitleSelect: React.FC<SelectProps> = selectProps => {
  return (
    <Select placeholder={tSelectPlaceholder('JobTitle')} {...selectProps} />
  );
};

export default JobTitleSelect;
