export const FARMER_MANAGEMENT_PAGE_TYPE = {
  PRE_ENROLLMENT: 'PRE_ENROLLMENT',
  FARM_EGBILITY: 'FARM_EGBILITY',
  FARMER_PROFILE: 'FARMER_PROFILE',
  CONTRACTS: 'CONTRACTS',
};

export enum APPROVAL_STATUS {
  NEED_TO_UPDATE = 'NEED_TO_UPDATE',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  NEED_CONTRACT = 'NEED_CONTRACT',
  SIGNED_CONTRACT = 'SIGNED_CONTRACT',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export enum CRITERIA_STATUS_TYPE {
  ELIGIBLE = 'ELIGIBLE',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  NO_DATA = 'NO_DATA',
}

export enum CRITERIA_FARM_INFO {
  ELIGIBLE = 'ELIGIBLE',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  PENDING_ELIGIBLE_CHECK = 'PENDING_ELIGIBLE_CHECK',
}

export enum FARM_STATUS_TYPE {
  ELIGIBLE = 'ELIGIBLE',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  PENDING_ELIGIBLE_CHECK = 'PENDING_ELIGIBLE_CHECK',
}

export enum SIGNING_STATUS {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PENDING_ELIGIBLE_CHECK = 'PENDING_ELIGIBLE_CHECK',
  NEED_CONTRACT = 'NEED_CONTRACT',
  SIGNED_CONTRACT = 'SIGNED_CONTRACT',
  NEED_TO_UPDATE = 'NEED_TO_UPDATE',
  REJECT = 'REJECT',
  IN_REVIEW = 'IN_REVIEW',
}

export enum FARM_STATUS {
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  INELIGIBLE = 'INELIGIBLE',
  NEED_TO_UPDATE = 'NEED_TO_UPDATE',
  REJECTED = 'REJECTED',
}

export const FARM_EDITABLE_STATUSES = [
  FARM_STATUS.IN_REVIEW,
  FARM_STATUS.NEED_TO_UPDATE,
];

export enum ELIGIBLE_CRITERIA_NAME {
  LOCATED_WITHIN_PROJECT_AREA_BOUNDARIES = 'Located within project area boundaries',
  CONTIGUOUS_AREA_GREATER_THAN_1_HA = 'Contiguous area > 1ha',
  NOT_OVERLAPPING_NEARBY_FARMS = 'Not overlapping nearby farms',
  NOT_WITHIN_FOREST_RESERVE = 'Not Within Forest Reserve',
  NOT_DEFORESTED_IN_THE_LAST_10_YEARS = 'Not Deforested in the last 10 years',
}

export const LOCATION_SURVEY_NAME = 'pickLocation4FarmBoundarySurvey';

export default {
  FARMER_MANAGEMENT_PAGE_TYPE,
  FARM_STATUS,
  CRITERIA_STATUS_TYPE,
  FARM_STATUS_TYPE,
  SIGNING_STATUS,
  ELIGIBLE_CRITERIA_NAME,
};
