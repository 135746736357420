import { Button, Flex, Form, Typography, Upload, notification } from 'antd';
import React, { useState } from 'react';
import SamplingModuleSelect, { UploadType } from '../SamplingModuleSelect';
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { t } from 'helpers/i18n';
import { useProjectContext } from 'contexts';
import { plotServices } from 'services';

const { Text, Link } = Typography;
const TEMPLATE_ROUTE = '/templates/sampling_plots.xlsx';

interface ImportPlotsFormProps {
  callbackAfterImport: () => void;
}

interface IImportForm {
  samplingModuleId: number;
  uploadOption: UploadType;
  files: { originFileObj: File }[];
}

const ImportPlotsForm: React.FC<ImportPlotsFormProps> = ({
  callbackAfterImport,
}) => {
  const [form] = Form.useForm<IImportForm>();
  const { projectId } = useProjectContext();
  const [loading, setLoading] = useState(false);

  const onImportPlots = async (values: IImportForm) => {
    try {
      setLoading(true);
      let formData = new FormData();
      formData.append('projectId', projectId.toString());
      formData.append('uploadOption', values.uploadOption);
      formData.append('samplingModuleId', values.samplingModuleId.toString());
      formData.append('file', values.files[0].originFileObj);
      await plotServices.importPlots(formData);
      form.resetFields();
      notification.success({ message: t('UploadFileSuccessfully') });
      callbackAfterImport();
    } finally {
      setLoading(false);
    }
  };

  const INITIAL_VALUES: Partial<IImportForm> = {
    uploadOption: 'ADDITIONAL',
  };

  const beforeUpload = (file: File) => {
    if (file.size > 1024 * 1024 * 30 || !/^.*.(?:xlsx?)$/i.test(file.name)) {
      notification.error({ message: t('FileTypeOrFileSizeIsInvalid') });
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={INITIAL_VALUES}
      onFinish={onImportPlots}
    >
      <SamplingModuleSelect showUploadOption={true} />
      <Flex justify="space-between" className="mb-base">
        <Text type="secondary">
          {t('DownloadSampleFileToFillInInformation')}
        </Text>
        <Link download href={TEMPLATE_ROUTE}>
          <DownloadOutlined className="mr-quarter" />
          {t('DownloadTemplate')}
        </Link>
      </Flex>
      <Form.Item
        name="files"
        valuePropName="fileList"
        getValueFromEvent={({ fileList }) => fileList}
        className="w-100"
      >
        <Upload.Dragger
          maxCount={1}
          beforeUpload={beforeUpload}
          disabled={loading}
          accept=".xlsx"
        >
          <div className="mx-half my-half">
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('DragAndDropFileHere')}</p>
            <p className="ant-upload-hint">
              {t('UploadHint', {
                fileTypes: '.xlsx',
                maxSize: '30MB',
              })}
            </p>
          </div>
        </Upload.Dragger>
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <div className="text-center">
            <Button
              type="primary"
              htmlType="submit"
              disabled={!getFieldValue('files')?.length}
              loading={loading}
            >
              {t('Confirm')}
            </Button>
          </div>
        )}
      </Form.Item>
    </Form>
  );
};

export default ImportPlotsForm;
