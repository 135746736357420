import { List, Button } from 'antd';
import { DownOutlined, PlusOutlined, TeamOutlined } from '@ant-design/icons';
import { communityHooks, userHooks } from 'hooks';
import { CremaDrawer, ICommunity } from 'interfaces';
import { PAGINATION_OFFSET_LIMIT_5 } from 'constants/pagination';
import AddCommunity from './AddCommunity';
import withDrawerManager from 'hocs/withDrawerManager';
import { useCremaFormationContext } from 'contexts/cremaFormation';
import { useTranslation } from 'react-i18next';

const { useUserPermissions } = userHooks;

const AddCommunityDrawer = withDrawerManager(
  AddCommunity,
  CremaDrawer.AddCommunity
);

export const CommunityTab = ({
  setShowDetailCommunity,
  cremaId,
  getCremaDetail,
}: {
  cremaId: number;
  setShowDetailCommunity: (communityId: ICommunity['communityId']) => void;
  getCremaDetail: () => void;
}) => {
  const { t } = useTranslation(window.appConfig?.appName);
  const {
    communities,
    loading: gettingCommunities,
    getCommunities,
    getMoreCommunities,
    showLoadMore,
  } = communityHooks.useCommunity({
    cremaId,
    limit: PAGINATION_OFFSET_LIMIT_5.limit,
  });
  const { toggleDrawer } = useCremaFormationContext();
  const { canUpdateCrema } = useUserPermissions();

  return (
    <>
      <List
        itemLayout="horizontal"
        className="grid-item-list"
        split={false}
        dataSource={communities}
        loading={gettingCommunities}
        header={
          <Button
            type="link"
            disabled={!canUpdateCrema}
            className="p-0"
            onClick={() => {
              toggleDrawer(CremaDrawer.AddCommunity);
            }}
            icon={<PlusOutlined />}
          >
            {t('AddCommunity')}
          </Button>
        }
        renderItem={item => (
          <List.Item
            onClick={() => {
              setShowDetailCommunity(item.communityId);
            }}
            style={{ cursor: 'pointer' }}
            key={item.communityId}
          >
            <List.Item.Meta
              title={item.communityName}
              avatar={<TeamOutlined className="font-size-medium" />}
            />
          </List.Item>
        )}
        loadMore={
          !!showLoadMore && (
            <div className="view-more text-center mt-half">
              <Button onClick={getMoreCommunities} type="link">
                {t('ViewMore')} <DownOutlined />
              </Button>
            </div>
          )
        }
      />
      <AddCommunityDrawer
        cremaId={cremaId}
        refetchCommunities={() => {
          getCommunities();
          getCremaDetail();
        }}
      />
    </>
  );
};
