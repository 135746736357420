import { CloseOutlined } from '@ant-design/icons';
import {
  Button,
  Collapse,
  CollapseProps,
  Drawer,
  DrawerProps,
  Flex,
  Skeleton,
  Typography,
} from 'antd';
import { FSImage } from 'components/shared/FSImage';
import { CREMA_IMAGE_DEFAULT } from 'constants/map';
import { useFlagsupContext } from 'contexts';
import { fileHelpers } from 'helpers';
import { ICommunity } from 'interfaces';
import { Dispatch, ReactNode } from 'react';
import './OrganizationDetail.scss';

interface OrganizationDetailProps
  extends Pick<DrawerProps, 'open' | 'rootStyle' | 'onClose' | 'zIndex'> {
  organizationName?: string | ReactNode;
  organizationImageUrl?: string;
  items: CollapseProps['items'];
  loading: boolean;
  onSelectCommunity?: Dispatch<ICommunity['communityId']>;
  alert?: ReactNode;
}

const { Title } = Typography;
const IMAGE_HEIGHT = 240;
const DRAWER_WIDTH = 340;
const { applyGoogleImageSizing } = fileHelpers;

const OrganizationDetail: React.FC<OrganizationDetailProps> = ({
  organizationName,
  organizationImageUrl,
  open,
  items,
  children,
  loading,
  rootStyle,
  zIndex = 1002,
  alert,
  onClose,
}) => {
  const { featureFlagsData } = useFlagsupContext();
  const isCarsa919Enable = featureFlagsData.CARSA_919?.enabled;

  return (
    <>
      <Drawer
        placement="left"
        width={DRAWER_WIDTH}
        closable={false}
        open={open}
        mask={false}
        getContainer={false}
        zIndex={zIndex}
        classNames={{ body: 'p-0' }}
        push={{ distance: 0 }}
        rootStyle={rootStyle}
        destroyOnClose
      >
        <Flex vertical className="h-100">
          {loading ? (
            <Skeleton.Image
              active={loading}
              rootClassName="w-100 mb-base"
              className="w-100"
              style={{ height: IMAGE_HEIGHT }}
            />
          ) : (
            <FSImage
              src={
                isCarsa919Enable
                  ? organizationImageUrl &&
                    applyGoogleImageSizing(organizationImageUrl, {
                      height: IMAGE_HEIGHT,
                    })
                  : organizationImageUrl
              }
              fallback={CREMA_IMAGE_DEFAULT}
              preview={false}
              style={{ height: IMAGE_HEIGHT, borderRadius: 0 }}
            />
          )}
          <Button
            icon={
              <CloseOutlined className="close-icon organization-close-icon" />
            }
            type="text"
            shape="circle"
            className="position-absolute"
            style={{ top: 10, right: 10 }}
            onClick={onClose}
          />
          <Skeleton
            active={loading}
            loading={loading}
            rootClassName="mx-half"
            style={{ width: 'auto' }}
          >
            <Title level={4} className="mx-base mt-base mb-half">
              {organizationName}
            </Title>
            <div className="mx-base">{alert}</div>
            <Collapse
              expandIconPosition="end"
              bordered={false}
              items={items}
              className="h-100"
              style={{ background: 'inherit', overflow: 'auto' }}
            />
          </Skeleton>
        </Flex>
      </Drawer>
      {/** Nested drawers */}
      {children}
    </>
  );
};

export default OrganizationDetail;
