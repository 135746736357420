import { requestServices } from 'services';
import {
  IApiResponse,
  IAutoGeneratePlots,
  IAutoGeneratePlotsResponse,
  ICreateUpdateProjectResponse,
  IGetAllPlotsOnMapResponse,
  IGetAllProjectResponse,
  IGetPlotDetailsParams,
  IGetProjectPlotsByCoorsParams,
  IPaginationOffsetParams,
  IPayloadUserProjectAddition,
  IPolygon,
  IPolygonResponse,
  IProjectDeleteResponse,
  IProjectForm,
  IProjectStateParams,
  IProjectDetails,
  IUploadPolygonResponse,
  IUserProject,
  IPlot,
  IAutoGeneratePlotsCAR613,
} from 'interfaces';
import { getData, getResult } from 'helpers/request';
import { ROUTES } from 'constants/routes';
import { AxiosRequestConfig } from 'axios';

const { projectManagerClient } = requestServices;

const getProjectById = (id: number): Promise<IApiResponse<IProjectDetails>> => {
  return projectManagerClient.get(`/api/v1/projects/${id}`);
};

const getProjectByIdTekbone = (
  id: number
): Promise<IApiResponse<IProjectDetails>> => {
  return projectManagerClient.get(`/tekbone/api/v1/projects/${id}`);
};

const createPolygon = (
  id: number,
  formData: FormData
): Promise<IUploadPolygonResponse> => {
  return projectManagerClient.post(`/api/v1/projects/${id}/polygons`, formData);
};

const getProjects = (): Promise<{ projects: IUserProject[] }> => {
  return projectManagerClient.get('/tekbone/api/v1/my-projects').then(getData);
};

const addUserToProject = (payload: IPayloadUserProjectAddition) => {
  return projectManagerClient.post('/tekbone/api/v1/add-user-project', payload);
};

const getProjectList = (
  params: IProjectStateParams
): Promise<IGetAllProjectResponse> =>
  projectManagerClient
    .get(ROUTES.PROJECT.GET_ALL_PROJECT, { params })
    .then(getData);

const getProjectPolygons = (
  projectId: number,
  params: IPaginationOffsetParams
): Promise<IPolygonResponse<IPolygon>['data']> =>
  projectManagerClient
    .get(ROUTES.POLYGON.GET_ALL_POLYGON(projectId), {
      params,
    })
    .then(getData);

const createProject = (
  payload: Partial<IProjectForm>
): Promise<ICreateUpdateProjectResponse> =>
  projectManagerClient
    .post(ROUTES.PROJECT.CREATE_PROJECT, payload)
    .then(getResult);

const createProjectTekbone = (
  payload: Partial<IProjectForm>
): Promise<ICreateUpdateProjectResponse> =>
  projectManagerClient
    .post(ROUTES.PROJECT.CREATE_PROJECT_TEKBONE, payload)
    .then(getResult);

const deleteProject = (projectId: number): Promise<IProjectDeleteResponse> =>
  projectManagerClient
    .delete(ROUTES.PROJECT.DELETE_PROJECT(projectId))
    .then(getResult);

const generateSamplingPlots = (
  payload: IAutoGeneratePlots
): Promise<IAutoGeneratePlotsResponse['data']> =>
  projectManagerClient
    .post(ROUTES.PLOTS.GENERATE_SAMPLING_PLOTS, payload)
    .then(getData);

const getProjectPlotsByCoors = (
  params: IGetProjectPlotsByCoorsParams,
  configs?: AxiosRequestConfig
): Promise<IGetAllPlotsOnMapResponse['data']> =>
  projectManagerClient
    .get(ROUTES.PLOTS.GET_PLOTS_BY_VIEW, {
      ...configs,
      params,
    })
    .then(getData);

const generateSamplingPlotsTekbone = (
  payload: IAutoGeneratePlots | IAutoGeneratePlotsCAR613
): Promise<IAutoGeneratePlotsResponse['data']> =>
  projectManagerClient
    .post(ROUTES.PLOTS.GENERATE_SAMPLING_PLOTS_TEKBONE, payload)
    .then(getData);

const getProjectPlotsByCoorsTekbone = (
  params: IGetProjectPlotsByCoorsParams,
  configs?: AxiosRequestConfig
): Promise<IGetAllPlotsOnMapResponse['data']> =>
  projectManagerClient
    .get(ROUTES.PLOTS.GET_PLOTS_BY_VIEW_TEKBONE, {
      ...configs,
      params,
    })
    .then(getData);

const getPlotDetails = (
  plotId: number,
  params?: IGetPlotDetailsParams,
  configs?: AxiosRequestConfig
): Promise<IPlot> =>
  projectManagerClient
    .get(ROUTES.PLOTS.GET_PLOT_DETAILS(plotId), {
      ...configs,
      params,
    })
    .then(getData);

export default {
  getProjectById,
  getProjectByIdTekbone,
  createPolygon,
  getProjects,
  addUserToProject,
  getProjectList,
  getProjectPolygons,
  createProject,
  deleteProject,
  generateSamplingPlots,
  getProjectPlotsByCoors,
  createProjectTekbone,
  generateSamplingPlotsTekbone,
  getProjectPlotsByCoorsTekbone,
  getPlotDetails,
};
