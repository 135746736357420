import { Button, ConfigProvider, Form, Space, Table, TableProps } from 'antd';
import { FormAnswer } from 'interfaces';
import { formHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { DeleteOutlined } from '@ant-design/icons';
import { v4 } from 'uuid';

interface DatagridProps extends TableProps {
  item: FormAnswer;
}

const Datagrid: React.FC<DatagridProps> = ({ item, ...tableProps }) => {
  const form = Form.useFormInstance();
  const submissionId = Form.useWatch('submissionId');
  const dataSource = Form.useWatch(item.fieldName, { form, preserve: true });
  const { componentDisabled } = ConfigProvider.useConfig();
  const [columns, setColumns] = useState<ColumnsType>([]);

  useEffect(() => {
    generateColumns();
    if (dataSource?.length === 0 && !componentDisabled) {
      addRow();
    }
  }, [submissionId]);

  const generateColumns = () => {
    let defaultColumns: ColumnsType = item.children?.map(child => ({
      title: child.fieldLabel,
      dataIndex: child.fieldName,
      editable: !componentDisabled,
      render: (value: any, rowData: any, rowIndex: number) => {
        return (
          <Form.Item name={[item.fieldName, rowIndex, child.fieldName]} noStyle>
            {formHelpers.renderFormControlFromTemplate(
              {
                ...child,
                value,
              },
              componentDisabled
            )}
          </Form.Item>
        );
      },
    }));

    if (!componentDisabled) {
      defaultColumns = [
        ...defaultColumns,
        {
          title: t('Action'),
          dataIndex: 'uid',
          render: (uid: string, rowData: any, rowIndex: number) =>
            rowIndex !== 0 && (
              <Space>
                <Button
                  icon={<DeleteOutlined />}
                  type="text"
                  onClick={() => deleteRow(uid)}
                />
              </Space>
            ),
        },
      ];
    }
    setColumns(defaultColumns);
  };

  const generateEmptyRow = () => {
    let emptyRow: { [key: string]: any } = { uid: v4() };
    item.children.forEach(field => {
      emptyRow[field.fieldName] = undefined;
    });
    return emptyRow;
  };

  const addRow = () => {
    const emptyRow = generateEmptyRow();
    form.setFieldValue(item.fieldName, [...dataSource, emptyRow]);
  };

  const deleteRow = (rowUid: string) => {
    form.setFieldValue(
      item.fieldName,
      form
        .getFieldValue(item.fieldName)
        .filter((row: any) => row.uid !== rowUid)
    );
  };

  return (
    <>
      {!componentDisabled && (
        <div className="text-right mb-half">
          <Button type="primary" size="small" onClick={addRow}>
            {t('Add')}
          </Button>
        </div>
      )}
      <Table
        rowKey="uid"
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
        size="small"
        locale={{ emptyText: t('NoData') }}
        {...tableProps}
      />
    </>
  );
};

export { Datagrid };
