import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  Modal,
  ModalProps,
  Row,
  Typography,
} from 'antd';
import { t } from 'helpers/i18n';
import { OperationRole } from 'interfaces';
import React, { useEffect, useState } from 'react';
import './AddRoleGroupModal.scss';

interface IAddRoleGroupModalProps extends ModalProps {
  onAddRole: (roleIds: number[]) => void;
  roleGroups: OperationRole[];
}

const AddRoleGroupModal: React.FC<IAddRoleGroupModalProps> = ({
  onAddRole,
  roleGroups,
  ...modalProps
}) => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState<OperationRole[]>();

  useEffect(() => {
    setDataSource(roleGroups);
  }, [roleGroups]);

  return (
    <Modal
      className="add-role-group-modal"
      title={t('AddRoleGroup')}
      centered
      okText={t('Add')}
      cancelText={t('Back')}
      onOk={() => {
        onAddRole(form.getFieldValue('roleIds'));
      }}
      maskClosable={false}
      destroyOnClose={true}
      {...modalProps}
    >
      <Form
        form={form}
        onFinish={({ keyword }) =>
          setDataSource(
            roleGroups.filter(role =>
              role.name.toLowerCase().includes(keyword.toLowerCase())
            )
          )
        }
      >
        <Row>
          <Col flex={1} className="mr-base">
            <Form.Item name="keyword">
              <Input
                prefix={<SearchOutlined />}
                placeholder={t('RoleGroupName')}
              />
            </Form.Item>
          </Col>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('Search')}
            </Button>
          </Form.Item>
        </Row>
        <Form.Item name="roleIds">
          <Checkbox.Group className="d-block">
            <List
              header={
                <Typography.Title level={5} className="mb-0">
                  {t('RoleGroupName')}
                </Typography.Title>
              }
              bordered
              dataSource={dataSource}
              renderItem={item => (
                <List.Item key={item.id} className="justify-content-start">
                  <Checkbox value={item.id}>
                    <Typography.Text>{item.name}</Typography.Text>
                  </Checkbox>
                </List.Item>
              )}
            />
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddRoleGroupModal;
