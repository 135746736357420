/** Third party libs * */
import React, { useEffect } from 'react';
import { InputNumber } from 'antd';

/** Local libs * */
import { t } from 'helpers/i18n';
import { eventsHelpers } from 'helpers';
import { BaseItem, BaseItemProps } from '../BaseItem';

/** Components * */

/** Styles * */

/** Interfaces, enum... * */
interface NumbericProps extends BaseItemProps {
  currency?: boolean;
  min?: number;
}

/** Variables * */

/** ------------------------- * */
const Numberic: React.FC<NumbericProps> = ({
  item,
  disabled,
  form,
  currency,
  min,
  ...props
}) => {
  /** States * */

  /** Hooks * */

  /** Variables * */

  /** Effects * */
  useEffect(() => {
    if (item.fieldName) {
      form.setFieldValue(item.fieldName, item.value);
    }
  }, [item.value]);

  /** Functions, Events, Actions... * */
  const numbericValidator = (value: number) => {
    if (!value && item.isRequired) return Promise.reject(t('NotBeEmpty'));

    if (value && item.regex) {
      const regex = new RegExp(item.regex);
      if (!regex.test(value.toString()))
        return Promise.reject(t('InvalidData'));
    }
    return Promise.resolve();
  };

  /** Elements * */
  return (
    <BaseItem
      item={item}
      disabled={disabled}
      form={form}
      validator={(_, value) => numbericValidator(value)}
      {...props}
    >
      <InputNumber
        placeholder={t('EnterValue')}
        disabled={disabled}
        style={{ width: '100%' }}
        maxLength={19}
        formatter={
          currency
            ? value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : undefined
        }
        parser={
          (currency
            ? (value: any) => value!.replace(/\$\s?|(,*)/g, '')
            : undefined) as any
        }
        min={min}
        onKeyDown={eventsHelpers.onKeyNumberPress}
      />
    </BaseItem>
  );
};

export default Numberic;
