import React, { useEffect, useState } from 'react';
import {
  List,
  Skeleton,
  Empty,
  Typography,
  Divider,
  Flex,
  Button,
  Row,
} from 'antd';
import { dateTimeHelpers } from 'helpers';
import { VN_DATE_SLASH_FORMAT } from 'constants/dateTime';
import { ActivityExpenseReceipt } from 'interfaces';
import { useProjectContext } from 'contexts';
import { DEFAULT_PAGINATION_OFFSET } from 'constants/pagination';
import { activityServices } from 'services';
import { convertNumberFormat } from 'helpers/common';
import { activityConstants } from 'constants/index';
import { t } from 'helpers/i18n';

const { formatUnixTimestamp } = dateTimeHelpers;
const { PAYMENT_LABEL_MAP } = activityConstants;

interface FinancialPlanExpenseReceiptsProps {
  activityId: number;
}

const FinancialPlanExpenseReceipts: React.FC<FinancialPlanExpenseReceiptsProps> = ({
  activityId,
}) => {
  const { projectId } = useProjectContext();
  const [initialLoading, setInitialLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [expenseReceipts, setExpenseReceipts] = useState<
    ActivityExpenseReceipt[]
  >([]);
  const [nextOffset, setNextOffset] = useState(-1);
  const canLoadMore = nextOffset !== -1;

  const fetchData = async (offset?: number) => {
    try {
      offset ? setLoadingMore(true) : setInitialLoading(true);

      const {
        activityExpenseReceipts,
        pagination: { offset: nextOffset },
      } = await activityServices.getAcitivityExpenseReceipts({
        activityId,
        projectId,
        limit: DEFAULT_PAGINATION_OFFSET.limit,
        offset: offset || DEFAULT_PAGINATION_OFFSET.offset,
      });
      if (offset) {
        setExpenseReceipts([...expenseReceipts, ...activityExpenseReceipts]);
      } else {
        setExpenseReceipts(activityExpenseReceipts);
      }

      setNextOffset(
        activityExpenseReceipts.length === DEFAULT_PAGINATION_OFFSET.limit
          ? nextOffset
          : -1
      );
    } finally {
      offset ? setLoadingMore(false) : setInitialLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const loadMore = () => {
    fetchData(nextOffset);
  };

  if (initialLoading) return <Skeleton active />;
  if (expenseReceipts.length === 0) return <Empty />;

  return (
    <List
      itemLayout="vertical"
      dataSource={expenseReceipts}
      split={false}
      loadMore={
        canLoadMore && (
          <Row justify="center">
            <Button
              loading={loadingMore}
              type="text"
              color="primary"
              className="text-center"
              onClick={() => loadMore()}
            >
              {t('LoadMore')}
            </Button>
          </Row>
        )
      }
      renderItem={expenseReceipt => (
        <List.Item key={expenseReceipt.id} className="mb-base">
          <Divider
            orientation="left"
            orientationMargin={0}
            className="mb-half mt-0"
            plain
          >
            <Typography.Text type="secondary">
              {formatUnixTimestamp(
                expenseReceipt.receiptDate,
                VN_DATE_SLASH_FORMAT
              )}
            </Typography.Text>
          </Divider>
          <Typography.Title level={5}>{expenseReceipt.note}</Typography.Title>
          <Flex justify="space-between" className="mb-half">
            {PAYMENT_LABEL_MAP[expenseReceipt.paymentMethod] ||
              expenseReceipt.paymentMethod}
            <Typography.Text type="danger" strong>
              -{convertNumberFormat(expenseReceipt.amount)} VND
            </Typography.Text>
          </Flex>

          {/* CAR-2295: Activity Expense Receipt Detail: Commented out for WEB version as it's currently not needed. 
              May be required in future updates.
          */}
          {/* {expenseReceipt?.imageFiles?.length > 0 && (
            <Image.PreviewGroup items={expenseReceipt.imageFiles}>
              <Space className="flex-wrap">
                {expenseReceipt.imageFiles.map(image => (
                  <Image
                    {...THUMBNAIL_SIZE}
                    // @TODO: apply resize image & private file when enable
                    src={image}
                    placeholder={
                      <Skeleton.Image active style={{ ...THUMBNAIL_SIZE }} />
                    }
                  />
                ))}
              </Space>
            </Image.PreviewGroup>
          )} */}
        </List.Item>
      )}
    />
  );
};

export default FinancialPlanExpenseReceipts;
