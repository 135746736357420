import { useQuery } from '@tanstack/react-query';
import { Modal, Result, Spin } from 'antd';
import { commonConstants } from 'constants/index';
import { t } from 'helpers/i18n';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';
import { selectSubmissionId, setSubmissionId } from 'redux/features/submission';
import { AppDispatch } from 'redux/store';
import { requestServices } from 'services';

const { volunteerBFFClient } = requestServices;
const { QUERY_KEYS } = commonConstants;

const SubmissionFormula: React.FC = () => {
  const submissionId = useSelector(selectSubmissionId);
  const dispatch: AppDispatch = useDispatch();

  const { data: carbonFormula, error, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.FORMULAR, submissionId || ''],
    queryFn: async () =>
      submissionId
        ? volunteerBFFClient
            .get(`/api/v1/submissions/${submissionId}/biomass-references`)
            .then(res => res.data)
        : Promise.resolve(null),
    retry: false,
  });

  return (
    <Modal
      open={!!submissionId}
      footer={null}
      width={800}
      onCancel={() => dispatch(setSubmissionId())}
      closable={true}
    >
      {error && !isLoading ? (
        <Result
          status="500"
          subTitle={t('SorrySomethingWentWrong')}
          data-testid="error-modal"
        />
      ) : null}
      {!isLoading ? (
        carbonFormula ? (
          <div data-testid="carbon-modal">{ReactHtmlParser(carbonFormula)}</div>
        ) : null
      ) : (
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <Spin />
        </div>
      )}
    </Modal>
  );
};

export default SubmissionFormula;
