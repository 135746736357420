import { useProjectContext } from 'contexts';
import {
  IAssignStaffsPayload,
  ICommunityStaff,
  IStaffProfile,
} from 'interfaces';
import { useState } from 'react';
import { staffServices } from 'services';

const useStaff = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [staffs, setStaffs] = useState<IStaffProfile[]>([]);

  const getStaffs = async (projectId: number) => {
    try {
      setLoading(true);
      const result = await staffServices.getStaff({
        page: 1,
        pageSize: 1000,
        projectId,
      });
      if (result) {
        setStaffs(result.staffProfiles);
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    getStaffs,
    loading,
    staffs,
  };
};

const useCommunityStaffs = () => {
  const [staffs, setStaffs] = useState<ICommunityStaff[]>([]);
  const [gettingStaffs, setGettingStaff] = useState(false);
  const [assigningStaffs, setAssigningStaffs] = useState(false);
  const { projectId } = useProjectContext();

  const getStaffs = async (communityId: number) => {
    try {
      setGettingStaff(true);
      const { staffs } = await staffServices.getCommunityStaffs(communityId, {
        projectId,
      });
      setStaffs(staffs || []);
    } finally {
      setGettingStaff(false);
    }
  };

  const assignStaffs = async (payload: IAssignStaffsPayload) => {
    try {
      setAssigningStaffs(true);
      await staffServices.assignStaffs(payload);
    } finally {
      setAssigningStaffs(false);
    }
  };

  return {
    getStaffs,
    assignStaffs,
    gettingStaffs,
    staffs,
    assigningStaffs,
  };
};

export default {
  useStaff,
  useCommunityStaffs,
};
