export const DATE_SLASH_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT_LITE = 'HH:mm';
export const DATE_TIME_SLASH_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const DATE_TIME_SLASH_FORMAT_DASH = 'DD/MM/YYYY - HH:mm:ss';
export const DATE_TIME_DASH_FORMAT = 'DD-MM-YYYY HH:mm:ss';
export const VN_DATE_SLASH_FORMAT = 'HH:mm:ss DD/MM/YYYY';
export const DATE_WITHOUT_SLASH_FORMAT = 'DDMMYYYY';
export const DATE_REVERSE_TIME_WS_DASH_FORMAT = 'YYYY-MM-DD HH:mm';
export const DATE_REVERSE_DASH_FORMAT = 'YYYY-MM-DD';

export default {
  DATE_SLASH_FORMAT,
  TIME_FORMAT_LITE,
  DATE_TIME_SLASH_FORMAT,
  DATE_TIME_DASH_FORMAT,
  DATE_WITHOUT_SLASH_FORMAT,
  DATE_REVERSE_TIME_WS_DASH_FORMAT,
  DATE_REVERSE_DASH_FORMAT,
};
