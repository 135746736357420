import { flagHelper } from 'helpers';
import { IRoute } from 'interfaces';
import { useFlagsupContext } from 'contexts';

const useFilterNavigationByFeatureFlags = (items: IRoute[]) => {
  const { featureFlagsData } = useFlagsupContext();

  // Get navigation which their feature flags was enable to build menu
  const filteredNavigation = flagHelper.filterFlagsEnabled(
    items,
    featureFlagsData
  );

  // Only get routes which is link to a component
  const filteredRoutes = filteredNavigation.filter(
    item => !item.children && item.component
  );

  return { filteredNavigation, filteredRoutes };
};

export default {
  useFilterNavigationByFeatureFlags,
};
