import { MIME_FILE_TYPES } from 'constants/file';
import { getData, getResult, getResultAndHeaders } from 'helpers/request';
import {
  ICECCandidatesResponse,
  ICECParams,
  ICECResponse,
  IGetCecCandidatesPayload,
  IGetCremaMeetingRecordParams,
  IGetCremaMeetingRecordResponse,
  IGetCremasCrmcMemberPayload,
  IGetCremasCrmcMemberResponse,
  IGetCremasPayload,
  IGetCremasResponseData,
  IUpsertCECMemberPayload,
  IUpsertCECMembersResponse,
  CremaMeetingRecord,
  IGetCremaMeetingRecordDetailParams,
  IUpsertMeetingNoteSubmission,
  MeetingNoteSubmissionApprovalPayload,
  IGetCremaMeetingNoteCommentsParams,
  IGetCremaMeetingNoteCommentsResponse,
  ICreateCremaMeetingNoteComment,
  IUpsertCRMCParams,
  ICRMCCandidatesParams,
  ICRMCCandidatesResponse,
  IUpsertCremaDocumentPayload,
  IUpsertCremaDocumentResponse,
  IDeleteCremaDocumentResponse,
  IGetCremaDocumentsParams,
  IGetCremaDocumentsResponse,
  IDeleteCremaDocumentParams,
  IDownloadCremaDocumentParams,
  IDownloadCremaDocumentResponse,
  CreateCremaPayload,
  IAddCommunityToCremaPayload,
  IAddCommunityToCremaResponse,
  IGetListCremaOrganizationsParams,
  IGetListCremaOrganizationsResponse,
  IGetCremaDetailParams,
  ICremaDetail,
  IUpdateCremaPayload,
  IValidateCremaResponse,
  IUpsertCRMCRespone,
  IDeleteCECMemberParams,
  IDeleteCECMemberResponse,
  IDeleteCRMCMemberParams,
  IDeleteCRMCMemberResponse,
  IGetCremaMeetingNoteTemplatesParams,
  IGetCremaMeetingNoteTemplatesResponse,
} from 'interfaces';
import QueryString from 'qs';
import { requestServices } from 'services';

const { projectManagerClient } = requestServices;
const prefixUrl = '/tekbone/api/v1';

const getCremaCecMembers = (payload: ICECParams): Promise<ICECResponse> =>
  projectManagerClient.post(`${prefixUrl}/cec-members`, payload).then(getData);

const getCremasCrmcMembers = (
  payload: IGetCremasCrmcMemberPayload
): Promise<IGetCremasCrmcMemberResponse> =>
  projectManagerClient.post(`${prefixUrl}/crmc-members`, payload).then(getData);

const getCremaCrmcCandidates = (
  params: ICRMCCandidatesParams
): Promise<ICRMCCandidatesResponse> =>
  projectManagerClient
    .post(`${prefixUrl}/crmc-candidates`, params)
    .then(getData);

const upsertCRMCMembers = (
  payload: IUpsertCRMCParams
): Promise<IUpsertCRMCRespone> =>
  projectManagerClient
    .post(`${prefixUrl}/cremas/crmc-member`, payload)
    .then(getResult);

const deleteCRMCMember = (
  params: IDeleteCRMCMemberParams
): Promise<IDeleteCRMCMemberResponse> =>
  projectManagerClient
    .delete(`${prefixUrl}/cremas/crmc-member`, { params })
    .then(getResult);

const upsertCremasCECMembers = (
  payload: IUpsertCECMemberPayload
): Promise<IUpsertCECMembersResponse> => {
  return projectManagerClient
    .post(`${prefixUrl}/cremas/cec-member`, payload)
    .then(getData);
};

const deleteCECMember = (
  params: IDeleteCECMemberParams
): Promise<IDeleteCECMemberResponse> =>
  projectManagerClient
    .delete(`${prefixUrl}/cremas/cec-member`, { params })
    .then(getData);

const getCecCandidates = (
  payload: IGetCecCandidatesPayload
): Promise<ICECCandidatesResponse> =>
  projectManagerClient
    .post(`${prefixUrl}/cec-candidates`, payload)
    .then(getData);

const getCremas = (
  params: IGetCremasPayload
): Promise<IGetCremasResponseData> =>
  projectManagerClient.get('/tekbone/api/v1/cremas', { params }).then(getData);

const getCremaFormSubmissions = (
  params: IGetCremaMeetingRecordParams
): Promise<IGetCremaMeetingRecordResponse> =>
  projectManagerClient
    .get('/tekbone/api/v1/cremas/meeting-note-submissions', {
      params,
      paramsSerializer: params => {
        return QueryString.stringify(params, { arrayFormat: 'comma' });
      },
    })
    .then(getData);

const getCremaMeetingRecordDetail = (
  params: IGetCremaMeetingRecordDetailParams
): Promise<CremaMeetingRecord> =>
  projectManagerClient
    .get(
      `/tekbone/api/v1/cremas/meeting-note-submissions/${params.submissionId}`,
      {
        params,
      }
    )
    .then(getData);

const upsertMeetingNoteSubmission = (
  payload: IUpsertMeetingNoteSubmission
): Promise<{ submissionId: number }> =>
  projectManagerClient.post(
    '/tekbone/api/v1/cremas/meeting-note-submission',
    payload
  );

const meetingNoteSubmissionApproval = (
  payload: MeetingNoteSubmissionApprovalPayload
): Promise<void> =>
  projectManagerClient.post(
    `/tekbone/api/v1/cremas/meeting-note-submissions/${payload.submissionId}/approval`,
    payload
  );

const getCremaMeetingNoteComments = (
  params: IGetCremaMeetingNoteCommentsParams
): Promise<IGetCremaMeetingNoteCommentsResponse> =>
  projectManagerClient
    .get(
      `/tekbone/api/v1/cremas/meeting-note-submissions/${params.submissionId}/comments`,
      { params }
    )
    .then(getData);

const createCremaMeetingNoteComment = (
  payload: ICreateCremaMeetingNoteComment
) =>
  projectManagerClient.post(
    `/tekbone/api/v1/cremas/meeting-note-submissions/${payload.submissionId}/comment`,
    payload
  );

const getCremaDocuments = (
  params: IGetCremaDocumentsParams
): Promise<IGetCremaDocumentsResponse> =>
  projectManagerClient.get(`${prefixUrl}/documents`, { params }).then(getData);

const upsertCremaDocument = (
  payload: IUpsertCremaDocumentPayload
): Promise<IUpsertCremaDocumentResponse> => {
  return projectManagerClient
    .post(`${prefixUrl}/document`, payload)
    .then(getData);
};

const deleteCremaDocument = (
  params: IDeleteCremaDocumentParams
): Promise<IDeleteCremaDocumentResponse> => {
  return projectManagerClient
    .delete(`${prefixUrl}/document/${params.documentId}`, { params })
    .then(getData);
};

const downloadCremaDocument = async (
  params: IDownloadCremaDocumentParams
): Promise<IDownloadCremaDocumentResponse> => {
  const { result, headers } = await projectManagerClient
    .get(`${prefixUrl}/document/${params.documentId}`, {
      params,
      responseType: 'blob',
    })
    .then(getResultAndHeaders);

  return {
    data: result,
    type: MIME_FILE_TYPES[headers['content-type']],
  };
};

const createCrema = (
  payload: CreateCremaPayload
): Promise<{ cremaId: number }> =>
  projectManagerClient.post('/tekbone/api/v1/crema', payload).then(getData);

const addCommunityToCrema = (
  payload: IAddCommunityToCremaPayload
): Promise<IAddCommunityToCremaResponse> =>
  projectManagerClient
    .post(`${prefixUrl}/cremas/${payload.cremaId}/add-communities`, payload)
    .then(getData);

const getListCremaOrganizations = (
  params: IGetListCremaOrganizationsParams
): Promise<IGetListCremaOrganizationsResponse> =>
  projectManagerClient
    .get('/tekbone/api/v1/cremas/organizations', { params })
    .then(getData);

const getCremaDetail = (params: IGetCremaDetailParams): Promise<ICremaDetail> =>
  projectManagerClient
    .get(`/tekbone/api/v1/cremas/${params.cremaId}`, { params })
    .then(getData);

const validateCrema = (
  payload: IUpdateCremaPayload
): Promise<IValidateCremaResponse> =>
  projectManagerClient
    .post(`/tekbone/api/v1/cremas/validate`, payload)
    .then(getData);

const updateCrema = (payload: IUpdateCremaPayload) =>
  projectManagerClient.put(
    `/tekbone/api/v1/cremas/${payload.cremaId}`,
    payload
  );

const getCremaMeetingNoteTemplates = (
  params: IGetCremaMeetingNoteTemplatesParams
): Promise<IGetCremaMeetingNoteTemplatesResponse> =>
  projectManagerClient
    .get(`${prefixUrl}/cremas/meeting-note-templates`, {
      params,
    })
    .then(getData);

export default {
  getCremasCrmcMembers,
  upsertCremasCECMembers,
  getCremaCrmcCandidates,
  upsertCRMCMembers,
  getCremaCecMembers,
  getCecCandidates,
  getCremas,
  getCremaFormSubmissions,
  getCremaMeetingRecordDetail,
  upsertMeetingNoteSubmission,
  meetingNoteSubmissionApproval,
  upsertCremaDocument,
  deleteCremaDocument,
  getCremaMeetingNoteComments,
  createCremaMeetingNoteComment,
  getCremaDocuments,
  downloadCremaDocument,
  createCrema,
  addCommunityToCrema,
  getListCremaOrganizations,
  getCremaDetail,
  updateCrema,
  validateCrema,
  deleteCECMember,
  deleteCRMCMember,
  getCremaMeetingNoteTemplates,
};
