import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  createContext,
  useContext,
  useRef,
  useState,
} from 'react';
import { LatLng, Map as LeafletMap } from 'leaflet';
import { IDrawPolygonRef, IPOI } from 'interfaces';
import { v4 } from 'uuid';
import { DEFAULT_ZOOM_MARKER } from 'constants/map';

type AddPOIType = Partial<Pick<IPOI, 'placeName' | 'onDbClick'>> &
  Required<Pick<IPOI, 'latitude' | 'longitude'>>;

type UpdatePOIType = Partial<IPOI> & Required<Pick<IPOI, 'poiId'>>;

export type MarkersType = { [key: IPOI['poiId']]: IPOI };

export interface MapContextProps {
  /**
   * The map instance
   */
  map?: LeafletMap;
  /**
   * The current mode of the map (e.g, 'view', 'pickLocation')
   */
  mode: 'view' | 'locationPick';
  /**
   * The markers on the map
   */
  markers: MarkersType;
  /** Function to Pick a location on the map
   * - If poiId is provided, update the corresponding marker with the new coordinates
   * - If poiId is not provided, add a new marker with the picked coordinates and generate a unique ID for the POI
   */
  clusterMarkers: MarkersType;
  /** Function to Pick a location on the map
   * - If poiId is provided, update the corresponding marker with the new coordinates
   * - If poiId is not provided, add a new marker with the picked coordinates and generate a unique ID for the POI
   */
  pickLocation: (
    options?: Partial<Pick<IPOI, 'poiId' | 'placeName'>>
  ) => Promise<IPOI>;
  /**
   * Function to Cancel the location picking mode.
   */
  cancelPickLocation: () => void;
  /**
   * Function to set the Leaflet Map instance
   * Map instance should be set in child components of MapContainer
   */
  setMap: Dispatch<SetStateAction<LeafletMap | undefined>>;
  /**
   * Function to Add a POI on the map
   */
  addPOI: (poi: AddPOIType) => IPOI['poiId'];
  /**
   * Function to Set the cluster markers on the map
   */
  setClusterMarkers: Dispatch<SetStateAction<MarkersType>>;
  /**
   * Updates a POI in both the `markers` and `clusterMarkers` state.
   */
  setPOI: (poi: UpdatePOIType | UpdatePOIType[]) => void;
  /**
   * Function to Remove a POI on the map
   */
  removePOI: (poiId: IPOI['poiId']) => void;
  /**
   * Ref object to draw polygons
   */
  drawRef?: MutableRefObject<IDrawPolygonRef>;
  /**
   * Ref object to draw polygons
   */
  getPOI?: (poiId: IPOI['poiId']) => IPOI | undefined;
}

export const MapContext = createContext<MapContextProps>({
  mode: 'view',
  clusterMarkers: {},
  markers: {},
  setMap: () => {},
  addPOI: () => '',
  setClusterMarkers: () => {},
  setPOI: () => {},
  removePOI: () => {},
  pickLocation: () => Promise.resolve({ poiId: '', latitude: 0, longitude: 0 }),
  cancelPickLocation: () => {},
});

export const useMapContext = () => useContext(MapContext);

export const MapProvider: React.FC = ({ children }) => {
  const [map, setMap] = useState<LeafletMap>();
  const [mode, setMode] = useState<MapContextProps['mode']>('view');
  const [markers, setMarkers] = useState<MarkersType>({});
  const [clusterMarkers, setClusterMarkers] = useState<MarkersType>({});

  const drawRef = useRef<IDrawPolygonRef>({
    getDrawPolygon: () => [],
    setInitialPolygon: () => {},
    stopDrawing: () => {},
    edit: () => {},
    setPolygonViewOnly: () => {},
    clear: () => {},
  });

  const waitForLocationPicked = (): Promise<LatLng> => {
    return new Promise(resolve => {
      const locationPicked = (coordinate: LatLng) => {
        setMode('view');
        resolve(coordinate);
      };
      map?.addOneTimeEventListener('click', e => locationPicked(e.latlng));
    });
  };

  const pickLocation = async (
    options?: Partial<Pick<IPOI, 'poiId' | 'placeName'>>
  ) => {
    const { poiId, placeName } = options || {};
    setMode('locationPick');
    const coordinate = await waitForLocationPicked();
    let updatedPoi = {
      poiId: poiId || '',
      latitude: coordinate.lat,
      longitude: coordinate.lng,
    };
    if (poiId) {
      setPOI(updatedPoi);
    } else {
      updatedPoi.poiId = addPOI({
        latitude: coordinate.lat,
        longitude: coordinate.lng,
        placeName,
      });
    }
    return updatedPoi;
  };

  const cancelPickLocation = () => {
    setMode('view');
    map?.removeEventListener('click');
  };

  const addPOI = (poi: AddPOIType) => {
    const newPoiId = v4();

    setMarkers(prevMarkers => ({
      ...prevMarkers,
      [newPoiId]: {
        poiId: newPoiId,
        ...poi,
      },
    }));

    return newPoiId;
  };

  const setPOI = (changedPoi: UpdatePOIType | UpdatePOIType[]) => {
    const changedPois = Array.isArray(changedPoi) ? changedPoi : [changedPoi];
    if (changedPois.length === 0) return;

    const updateMarkers = (prevMarkers: MarkersType) => {
      const newMarkers = { ...prevMarkers };

      changedPois.forEach(poi => {
        const existingPoi = newMarkers[poi.poiId];
        if (existingPoi) {
          newMarkers[poi.poiId] = {
            ...existingPoi,
            ...poi,
          };
        }
      });
      return newMarkers;
    };

    setMarkers(updateMarkers);
    setClusterMarkers(updateMarkers);
  };

  const removePOI = (poiId: IPOI['poiId']) => {
    setMarkers(prevMarkers => {
      const updatedMarkers = { ...prevMarkers };
      delete updatedMarkers[poiId];
      return updatedMarkers;
    });
  };

  const getPOI = (poiId: IPOI['poiId']) => {
    const poi = clusterMarkers[poiId] || markers[poiId];

    if (poi) {
      poi.focus = () => {
        map?.flyTo([poi.latitude, poi.longitude], DEFAULT_ZOOM_MARKER);
      };
    }
    return poi;
  };

  return (
    <MapContext.Provider
      value={{
        map,
        mode,
        markers,
        clusterMarkers,
        pickLocation,
        cancelPickLocation,
        setMap,
        addPOI,
        setClusterMarkers,
        setPOI,
        removePOI,
        drawRef,
        getPOI,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export const MapConsumer = MapContext.Consumer;
