import {
  DeleteOutlined,
  DownOutlined,
  DownloadOutlined,
  FileTextOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, List, Popconfirm, Row, Typography, notification } from 'antd';
import { PAGINATION_OFFSET_LIMIT_5 } from 'constants/pagination';
import { userHooks } from 'hooks';
import { t } from 'helpers/i18n';
import {
  IDocument,
  IPaginationOffsetParams,
  IPaginationOffsetResponse,
} from 'interfaces';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { cremaServices } from 'services';
import { useProjectContext } from 'contexts';
import { fileHelpers } from 'helpers';
import { ID_DEFAULT } from 'constants/common';

const { useUserPermissions } = userHooks;

export const DocumentationsTab = ({
  cremaId,
  setShowCreationDocumentationModal,
  triggerDocumentationTimes,
}: {
  cremaId: number;
  setShowCreationDocumentationModal: Dispatch<SetStateAction<boolean>>;
  triggerDocumentationTimes: number;
}) => {
  const { projectId } = useProjectContext();
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [loading, setLoading] = useState(false);
  const [downloadingDocumentIds, setDownloadingDocumentIds] = useState<{
    [key: number]: boolean;
  }>({});
  const [pagination, setPagination] = useState<IPaginationOffsetResponse>(
    PAGINATION_OFFSET_LIMIT_5
  );
  const showLoadMore = (pagination.total as number) > pagination.offset;

  const { canUpdateCrema } = useUserPermissions();

  const getDocuments = async (curPagination?: IPaginationOffsetParams) => {
    try {
      setLoading(true);
      const offset = curPagination?.offset || PAGINATION_OFFSET_LIMIT_5.offset;

      const {
        documents: documentsRes,
        pagination: paginationRes,
      } = await cremaServices.getCremaDocuments({
        offset,
        limit: curPagination?.limit || PAGINATION_OFFSET_LIMIT_5.limit,
        organizationId: cremaId,
        projectId,
      });

      if (offset >= PAGINATION_OFFSET_LIMIT_5.limit) {
        setDocuments(prev => [...prev, ...(documentsRes || [])]);
      } else {
        setDocuments(documentsRes || []);
      }

      setPagination(paginationRes || PAGINATION_OFFSET_LIMIT_5);
    } finally {
      setLoading(false);
    }
  };

  const getMoreDocuments = async () => {
    await getDocuments({
      offset: pagination.offset,
      limit: PAGINATION_OFFSET_LIMIT_5.limit,
    });
  };

  useEffect(() => {
    if (cremaId && cremaId !== ID_DEFAULT) getDocuments();
  }, [cremaId, triggerDocumentationTimes]);

  const handleDelete = async (item: IDocument) => {
    try {
      setLoading(true);
      await cremaServices.deleteCremaDocument({
        projectId,
        documentId: item.id,
      });
      notification.success({
        message: t('DeleteDocumentSuccessfully'),
      });
      getDocuments();
    } catch {
      setLoading(false);
    }
  };

  const handleDownload = async (item: IDocument) => {
    setDownloadingDocumentIds(prev => ({ ...prev, [item.id]: true }));
    const { data, type } = await cremaServices.downloadCremaDocument({
      projectId,
      documentId: item.id,
    });

    fileHelpers.downloadFile(data, item.name + '.' + type);
    setDownloadingDocumentIds(prev => ({ ...prev, [item.id]: false }));
  };

  return (
    <List
      itemLayout="horizontal"
      split={false}
      dataSource={documents}
      loading={loading}
      className="grid-item-list"
      header={
        <Button
          type="link"
          disabled={!canUpdateCrema}
          style={{ padding: 0 }}
          onClick={() => {
            setShowCreationDocumentationModal(true);
          }}
        >
          <PlusOutlined /> {t('AddDocumentation')}
        </Button>
      }
      renderItem={item => (
        <List.Item
          actions={[
            <Button
              type="text"
              loading={downloadingDocumentIds[item.id]}
              size="small"
              onClick={() => handleDownload(item)}
              icon={<DownloadOutlined style={{ color: 'green' }} />}
            />,
            <Popconfirm
              title={null}
              description={t('AreYouSureToDeleteThisDocument')}
              onConfirm={() => handleDelete(item)}
              okText={t('Yes')}
              cancelText={t('No')}
              overlayStyle={{ width: 200 }}
              rootClassName="confirm-delete-popconfirm"
            >
              <Button
                type="text"
                size="small"
                danger
                icon={<DeleteOutlined />}
              ></Button>
            </Popconfirm>,
          ]}
        >
          <List.Item.Meta
            style={{ alignItems: 'center' }}
            avatar={<FileTextOutlined className="font-size-medium" />}
            title={
              <Typography.Text ellipsis={{ tooltip: item.name }}>
                {item.name}
              </Typography.Text>
            }
          />
        </List.Item>
      )}
      loadMore={
        <Row
          justify="center"
          style={{
            marginTop: 12,
          }}
        >
          {!!showLoadMore && (
            <Button
              onClick={getMoreDocuments}
              type="link"
              className="p-0"
              icon={<DownOutlined />}
            >
              {t('ViewMore')}
            </Button>
          )}
        </Row>
      }
    />
  );
};
