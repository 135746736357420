import { t } from 'helpers/i18n';
import { IProcessingFile, ProcessingFileType } from 'interfaces';

export const EXCEL_TYPES = ['.xls', '.xlsx', '.csv'];

export const KML_TYPES = ['.kml'];

/*
 * Using for docx, xlsx...
 */
export const MICROSOFT_ONLINE_VIEWER =
  'https://view.officeapps.live.com/op/embed.aspx?src=';

/*
 * Using for pdf
 */
export const GOOGLE_DRIVE_ONLINE_VIEWER = (fileUrl: string) =>
  `https://drive.google.com/viewerng/viewer?url=${fileUrl}&embedded=true`;

export const FILE_PROCESSING_STATUS_MAP: {
  [key in IProcessingFile['status']]: { color: string; label: string };
} = {
  INIT: {
    color: 'default',
    label: t('Processing'),
  },
  FAILED: {
    color: 'error',
    label: t('Failed'),
  },
  PROCESSING: {
    color: 'blue',
    label: t('Processing'),
  },
  FINISHED: {
    color: 'success',
    label: t('Finished'),
  },
};

export const FILE_TEMPLATES: {
  [key: string]: {
    filename: string;
  };
} = {
  [ProcessingFileType.IMPORT_USER]: {
    filename: 'Template_Import_Tai_Khoan.xlsx',
  },
  [ProcessingFileType.IMPORT_FUND]: {
    filename: 'Template_Import_Nguon_Tien.xlsx',
  },
};

export const MAX_FILE_UPLOAD = 10;

export enum IMAGE_FILE_TYPE {
  JPG = 'jpg',
  PNG = 'png',
  JPEG = 'jpeg',
  HEIC = 'heic',
}
export enum IMAGE_FILE_MIME_TYPE {
  HEIC = 'image/heic',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
}

export const IMAGE_FILE_TYPES = [
  IMAGE_FILE_TYPE.JPG,
  IMAGE_FILE_TYPE.PNG,
  IMAGE_FILE_TYPE.HEIC,
  IMAGE_FILE_TYPE.JPEG,
];
export const MEDIA_FILE_TYPES = '.mp3,.mov';
export const KML_FILE_TYPES = '.kml';

export const MIME_FILE_TYPES: { [key: string]: string } = {
  'application/pdf': 'pdf',
  'application/msword': 'doc',

  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'docx',
  'application/vnd.ms-excel': 'xls',

  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'text/csv; charset=utf-8': 'csv',
  'text/csv': 'csv',
  'image/heic': 'heic',
  'image/jpeg': 'jpg',
  'image/jpg': 'jpg',
  'image/png': 'png',
};

export const MAX_LENGTH_FILE_NAME = 255;
export const REGEX_PATTERN_FILE_NAME = /^[a-zA-Z0-9_.-\s]*$/;
