import { TeamOutlined } from '@ant-design/icons';
import { Badge, Card, Collapse, List, Space, Typography } from 'antd';
import React from 'react';
import { t } from 'helpers/i18n';
import { ICommunity } from 'interfaces';
import './ListCommunity.scss';

interface IListCommunityProps {
  communities?: ICommunity[];
  setCurrentCommunity: React.Dispatch<
    React.SetStateAction<{
      communityId: number;
      communityName: string;
    }>
  >;
  loading: boolean;
}

const ListCommunity: React.FC<IListCommunityProps> = ({
  communities,
  setCurrentCommunity,
  loading,
}) => {
  const items = [
    {
      key: 'Community',
      label: (
        <Space className="text-medium">
          <TeamOutlined />
          {t('Community')}
          <Badge count={communities?.length ?? 0} showZero />
        </Space>
      ),
      children: (
        <List
          dataSource={communities}
          renderItem={community => (
            <List.Item
              onClick={() => setCurrentCommunity(community)}
              className="list-community-item clickable d-block"
            >
              <TeamOutlined />
              &nbsp;
              <Typography.Text strong>
                {community.communityName}
              </Typography.Text>
            </List.Item>
          )}
        />
      ),
    },
  ];

  return (
    <Card className="list-community" loading={loading}>
      <Collapse
        expandIconPosition="end"
        bordered={false}
        items={items}
        className="h-100"
        style={{ background: 'inherit', overflow: 'auto', maxHeight: 800 }}
        defaultActiveKey={['Community']}
      />
    </Card>
  );
};

export { ListCommunity };
