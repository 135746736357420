import { useQuery } from '@tanstack/react-query';
import { commonConstants } from 'constants/index';
import { useProjectContext } from 'contexts';
import { CremaDrawer, ICommunity, ICrema, IPolygonTekbone } from 'interfaces';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';
import { communityServices, cremaServices, polygonServices } from 'services';
import { PAGING_OFFSET_ALL } from 'constants/pagination';
import { POLYGON_TYPES } from 'constants/project';
import { cremaConstants } from 'constants/index';
import { ID_DEFAULT } from 'constants/common';

const { CREMA_DRAWERS } = cremaConstants;

interface DrawerToggleOptions {
  open?: boolean;
}

export interface CremaFormationContextProps {
  openDrawer: {
    left: CremaDrawer[];
    right: CremaDrawer | null;
  };
  toggleDrawer: (name: CremaDrawer, options?: DrawerToggleOptions) => void;
  cremaInfo: { id: number };
  communityInfo: { id: number };
  setCremaInfo: Dispatch<SetStateAction<{ id: number }>>;
  setCommunityInfo: Dispatch<SetStateAction<{ id: number }>>;

  // Formation data
  cremas: ICrema[];
  cremaLoading: boolean;
  cremaRefetch: () => void;
  communities: ICommunity[];
  communityLoading: boolean;
  communityRefetch: () => void;
  polygons: IPolygonTekbone[];
  polygonsLoading: boolean;
  refetchPolygons: () => void;
  overlapPolygonIds: number[];
}

export const CremaFormationContext = createContext<CremaFormationContextProps>({
  openDrawer: { left: [], right: null },
  toggleDrawer: () => {},

  cremaInfo: { id: ID_DEFAULT },
  communityInfo: { id: ID_DEFAULT },
  setCremaInfo: () => {},
  setCommunityInfo: () => {},

  // Formation data
  cremas: [],
  cremaLoading: false,
  cremaRefetch: () => {},
  communities: [],
  communityLoading: false,
  communityRefetch: () => {},
  polygons: [],
  polygonsLoading: false,
  refetchPolygons: () => {},
  overlapPolygonIds: [],
});

export const useCremaFormationContext = () => useContext(CremaFormationContext);

const { QUERY_KEYS } = commonConstants;

export const CremaFormationProvider: React.FC = ({ children }) => {
  const { projectId } = useProjectContext();

  const [cremaInfo, setCremaInfo] = useState<{ id: number }>({
    id: ID_DEFAULT,
  });
  const [communityInfo, setCommunityInfo] = useState<{ id: number }>({
    id: ID_DEFAULT,
  });
  const [openDrawer, setOpenDrawer] = useState<
    CremaFormationContextProps['openDrawer']
  >({
    left: [],
    right: null,
  });

  const {
    data: cremas,
    isFetching: cremaLoading,
    refetch: cremaRefetch,
  } = useQuery({
    initialData: [],
    queryKey: [QUERY_KEYS.CREMA, projectId],
    queryFn: async () =>
      cremaServices
        .getCremas({ ...PAGING_OFFSET_ALL, projectId })
        .then(response => response.cremas),
  });

  const {
    data: communities,
    isFetching: communityLoading,
    refetch: communityRefetch,
  } = useQuery({
    initialData: [],
    queryKey: [QUERY_KEYS.COMMUNITY, projectId],
    queryFn: async () =>
      communityServices
        .getListCremaCommunities({
          ...PAGING_OFFSET_ALL,
          projectId,
        })
        .then(response => response.communities),
  });

  const ProjectBoundaryTypeId = POLYGON_TYPES.PROJECT_BOUNDARY.id;
  const InterventionAreaTypeId = POLYGON_TYPES.INTERVENTION_AREA.id;
  const getSelectedPolygonTypeIds = () => {
    let queryParams = [];
    if (ProjectBoundaryTypeId) {
      queryParams.push(ProjectBoundaryTypeId);
    }
    if (InterventionAreaTypeId) {
      queryParams.push(InterventionAreaTypeId);
    }
    return queryParams;
  };

  const {
    data: polygons,
    isFetching: polygonsLoading,
    refetch: refetchPolygons,
  } = useQuery({
    initialData: [],
    queryKey: [QUERY_KEYS.SEARCH_POLYGON, projectId],
    queryFn: async () =>
      polygonServices
        .searchPolygons({
          ...PAGING_OFFSET_ALL,
          projectId,
          polygonTypeIds: getSelectedPolygonTypeIds(),
        })
        .then(response => response.polygons),
  });

  const toggleDrawer = (
    name: CremaDrawer,
    options: DrawerToggleOptions = { open: true }
  ) => {
    const { open } = options;
    const drawerConfig = CREMA_DRAWERS[name];

    if (!drawerConfig) return;

    if (drawerConfig.align === 'left') {
      setOpenDrawer(prev => ({
        ...prev,
        left: open ? [...prev.left, name] : prev.left.filter(n => n !== name),
      }));
    } else {
      setOpenDrawer(prev => ({
        ...prev,
        right: open ? name : null,
      }));
    }
  };

  return (
    <CremaFormationContext.Provider
      value={{
        // drawer management
        openDrawer,
        toggleDrawer,

        // Detail
        cremaInfo,
        setCremaInfo,
        communityInfo,
        setCommunityInfo,

        // Formation data
        cremas,
        cremaLoading,
        cremaRefetch,
        communities,
        communityLoading,
        communityRefetch,
        polygons,
        polygonsLoading,
        refetchPolygons,
        overlapPolygonIds: [],
      }}
    >
      {children}
    </CremaFormationContext.Provider>
  );
};

export const CremaFormationConsumer = CremaFormationContext.Consumer;
