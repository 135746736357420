import { useQuery } from '@tanstack/react-query';
import { Image, ImageProps } from 'antd';
import { fileHelpers } from 'helpers';
import { IGoogleImageSizingOptions } from 'interfaces';

const { getImgBase64FromFsUrl, applyGoogleImageSizing } = fileHelpers;

interface IFSImageProps extends ImageProps {
  resizePreview?: IGoogleImageSizingOptions;
}

const FSImage: React.FC<IFSImageProps> = ({
  src,
  placeholder,
  width,
  height,
  resizePreview,
  preview = true,
  ...props
}) => {
  // Prevent spam API
  const { data: url, isLoading } = useQuery({
    queryKey: [src],
    queryFn: async () => (src ? await getImgBase64FromFsUrl(src) : ''),
  });

  const getPreviewConfig = (): ImageProps['preview'] => {
    if (!resizePreview || !preview || typeof preview === 'boolean')
      return preview;
    // only apply resize if passing preview.src
    return {
      ...preview,
      src: preview.src
        ? applyGoogleImageSizing(preview.src, resizePreview)
        : src,
    };
  };

  return isLoading ? (
    <div style={{ width, height }}>{placeholder}</div>
  ) : (
    <Image
      {...props}
      width={width}
      height={height}
      src={url}
      preview={getPreviewConfig()}
    />
  );
};

export default FSImage;
