import { SVGProps } from 'react';

const EmptyImageIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="m21.9 21.9l-6.1-6.1l-2.69-2.69L5 5L3.59 3.59L2.1 2.1L.69 3.51L3 5.83V19c0 1.1.9 2 2 2h13.17l2.31 2.31l1.42-1.41zM5 19V7.83l6.84 6.84l-.84 1.05L9 13l-3 4h8.17l2 2H5zM7.83 5l-2-2H19c1.1 0 2 .9 2 2v13.17l-2-2V5H7.83z"
      ></path>
    </svg>
  );
};

export default EmptyImageIcon;
