import { colorHelpers, mapHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { IBasemapPolygon, ILegend } from 'interfaces';
import { PathOptions } from 'leaflet';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { MapLegends } from './MapLegends';
import { IPolygon, MapPolygon } from './MapPolygon';
import './MapPolygons.scss';
import { Pane } from 'react-leaflet';
import { DEFAULT_z_INDEX_POLYGON } from 'constants/map';
import { useFlagsupContext } from 'contexts';
import { LegendPlacement } from '../constants';

export interface IMapPolygon extends IBasemapPolygon {
  id?: number;
  color?: string;
  activeOptions?: PathOptions;
  polygonOverviewRenderer?: React.ReactNode;
}

interface MapPolygonsProps {
  polygons?: IMapPolygon[];
  activePolygonIds?: IPolygon['id'][];
  errorPolygonIds?: IPolygon['id'][];
  showLegends?: boolean;
  showARREligibleSwitch?: boolean;
  className?: string;
  legends?: ILegend[];
  onPolygonClick?: (polygon: IPolygon, curPolygonInstance: any) => void;
  legendPlacement?: LegendPlacement;
  viewARREligible?: boolean;
  setViewARREligible?: Dispatch<SetStateAction<boolean>>;
  showPolygonOverviewOnHover?: boolean;
  fillColor?: boolean;
}

const { randomColor } = colorHelpers;
const { getPolygonPositions } = mapHelpers;

const MAP_LABELS_COLORS: any = {
  Water: '#4fa1f5',
  Forest: '#389e0d',
  'Dense Forest': '#237804',
  'Non-Forest': '#eed35e',
  'Non-Dense Forest': '#d2a965',
};

const MapPolygons: React.FC<MapPolygonsProps> = ({
  showLegends = false,
  showARREligibleSwitch = false,
  activePolygonIds = [],
  errorPolygonIds = [],
  polygons = [],
  className,
  legends = [],
  onPolygonClick,
  legendPlacement,
  viewARREligible,
  setViewARREligible,
  showPolygonOverviewOnHover,
  fillColor = true,
}) => {
  const { featureFlagsData } = useFlagsupContext();
  const isCAR418Enabled = featureFlagsData.CAR_418?.enabled;

  const polygonPositions = (
    polygon: IBasemapPolygon
  ): number[][] | number[][][] => {
    return polygon.polygonMap?.length
      ? getPolygonPositions(polygon.polygonMap)
      : (polygon.multiplePolygonMap || []).map(polygon =>
          getPolygonPositions(polygon.polygonMap)
        );
  };

  const pathOptions = (color: string): PathOptions => {
    return {
      color,
      fillColor: fillColor ? color : '#bebebe',
      stroke: true,
      fill: true,
      opacity: 0.1,
      fillOpacity: 0.6,
      weight: fillColor ? 3 : 5,
    };
  };

  const hoverOptions = (color: string): PathOptions => {
    return {
      color: color,
      fillColor: fillColor ? color : '#bebebe',
      stroke: true,
      fill: true,
      opacity: 0.5,
      fillOpacity: 0.8,
      dashArray: '0',
    };
  };

  const activeOptions = (
    color: string,
    activeOptions?: PathOptions
  ): PathOptions => {
    return {
      color: color,
      fillColor: color,
      stroke: true,
      fill: true,
      opacity: 0.8,
      fillOpacity: 0.4,
      ...activeOptions,
    };
  };

  const errorOptions = {
    color: '#FF4D4F',
    fillColor: '#FF4D4F',
    stroke: true,
    fill: true,
    opacity: 0.4,
    fillOpacity: 0.3,
  };

  const data = useMemo(() => {
    const mappedPolygons = polygons.map((polygon, index) => {
      const labelColor = MAP_LABELS_COLORS[polygon.label || ''];
      const color =
        legends.find(item => item.label === polygon.label)?.colorCode ||
        polygon.color ||
        labelColor ||
        randomColor();

      return {
        id: polygon.id || index, // Polygon from basemap does not have id
        label: polygon.label,
        showLabel: !!polygon.label,
        positions: polygonPositions(polygon),
        options: pathOptions(color),
        hoverOptions: hoverOptions(color),
        activeOptions: activeOptions(color, polygon.activeOptions),
        errorOptions,
        showPolygonOverviewOnHover,
        polygonOverviewRenderer: polygon.polygonOverviewRenderer,
      };
    });

    return mappedPolygons;
  }, [polygons]);

  return (
    <>
      {data.map(polygon => (
        <MapPolygon
          key={polygon.id}
          polygon={polygon}
          error={!!polygon.id && errorPolygonIds.includes(polygon.id)}
          active={
            !isCAR418Enabled &&
            !!polygon.id &&
            activePolygonIds.includes(polygon.id)
          }
          onPolygonClick={onPolygonClick}
          className={className}
        />
      ))}
      {isCAR418Enabled && activePolygonIds.length > 0 && (
        <Pane
          name="active-polygons"
          style={{ zIndex: DEFAULT_z_INDEX_POLYGON + 50 }}
        >
          {data
            .filter(
              polygon => !!polygon.id && activePolygonIds.includes(polygon.id)
            )
            .map(polygon => {
              return (
                <MapPolygon
                  key={`active-${polygon.id}`}
                  polygon={polygon}
                  active={true}
                  onPolygonClick={onPolygonClick}
                  className={className}
                />
              );
            })}
        </Pane>
      )}
      {showLegends && (
        <MapLegends
          title={t('Legends')}
          data={legends}
          placement={legendPlacement}
          showARREligibleSwitch={showARREligibleSwitch}
          viewARREligible={viewARREligible}
          setViewARREligible={setViewARREligible}
        />
      )}
    </>
  );
};

export { MapPolygons };
