import { notification, Upload } from 'antd';
import { IBeforeUploadOptions } from 'interfaces';
import { t } from 'helpers/i18n';

const beforeUpload = (file: File, options?: IBeforeUploadOptions) => {
  const accept = options?.accept;
  const acceptErrorMessage =
    options?.acceptErrorMessage || t('UploadFormatWarning');

  if (!accept) return Upload.LIST_IGNORE;

  const ext = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);

  if (
    (!file.type && !ext) ||
    !accept
      .toLowerCase()
      .split(',')
      .some(s => s.includes((ext || file.type).toLowerCase()))
  ) {
    notification.error({
      message: t('Error'),
      description: acceptErrorMessage,
    });

    return Upload.LIST_IGNORE;
  }

  const size = options?.size;

  if (size) {
    const sizeState = file.size / 1024 / 1024 < size;
    const sizeErrorMessage =
      options?.sizeErrorMessage || t('UploadMaxSize', { size });

    if (!sizeState) {
      notification.error({
        message: sizeErrorMessage,
      });
      return Upload.LIST_IGNORE;
    }
  }

  return true;
};

const fileToFormData = (file?: File) => {
  const formData = new FormData();
  if (file) {
    formData.append('upload_file_request', file);
  }

  return formData;
};

const beforeUploadKml = async (file: File) => {
  const content: string = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = event => {
      resolve(event.target?.result as string);
    };
    reader.onerror = error => reject(error);
    reader.readAsText(file);
  });
  try {
    const domParser = new DOMParser();
    const dom = domParser.parseFromString(content, 'text/xml');
    const isError = dom.documentElement.innerHTML.includes('parsererror');

    if (isError) {
      notification.error({
        message: t('TheFileContentIsIncorrect'),
      });
      return false;
    }

    const coordinates = dom.getElementsByTagName('coordinates');
    if (coordinates) {
      if (coordinates.length) {
        const list = Array.from(coordinates);
        const isError = list.some(item => {
          const coordinate = (item.innerHTML || '')
            .split(' ')
            .reduce((items, item) => {
              if (!!item) {
                const value = item
                  .replaceAll('/n', '')
                  .replaceAll('/t', '')
                  .trim();
                if (value) {
                  items.push(value);
                }
              }
              return items;
            }, [] as string[]);
          return coordinate.some(c => c.split(',').length < 2);
        });
        if (isError) {
          notification.error({
            message: t('TheCoordinatesContentIsIncorrect'),
          });
          return false;
        }
      } else {
        notification.error({
          message: t('TheFileContentIsIncorrect'),
        });
        return false;
      }
    }
  } catch (e) {}

  return beforeUpload(file, {
    accept: 'kml,application/vnd.google-earth.kml+xml',
    acceptErrorMessage: t('OnlySupportKmlFormat'),
    size: 1.25,
  });
};

export default { beforeUpload, fileToFormData, beforeUploadKml };
