/** Third party libs **/
import { Modal } from 'antd';
import { FarmerDetails } from 'components/shared/Farmer';
import { t } from 'helpers/i18n';
import React from 'react';

/** Local libs **/

/** Styles **/

/** Interfaces, enum... **/
interface IFarmerDetailModalProps {
  open: boolean;
  farmerId: number;
  setShowFarmerDetail: React.Dispatch<React.SetStateAction<boolean>>;
}

/** Variables **/

/** ------------------------- **/
/** FarmerDetailModal Component */
/** ------------------------- **/
const FarmerDetailModal: React.FC<IFarmerDetailModalProps> = ({
  open,
  farmerId,
  setShowFarmerDetail,
}) => {
  /** States **/

  /** Hooks **/

  /** Variables **/

  /** Functions, Events, Actions... **/

  /** Effects **/

  /** Elements **/
  return (
    <Modal
      open={open}
      footer={null}
      onCancel={() => setShowFarmerDetail(false)}
      title={t('FarmerPersonalDetails')}
      className={'farmer-detail'}
      centered
    >
      <FarmerDetails farmerId={farmerId} />
    </Modal>
  );
};

export { FarmerDetailModal };
export default FarmerDetailModal;
