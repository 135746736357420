import { Spin } from 'antd';
import { ProjectContext, ProjectContextType } from 'contexts';
import { projectHooks } from 'hooks';
import React, { useMemo } from 'react';

interface ProjectProviderProps {
  children: React.ReactNode;
}

const ProjectProvider: React.FC<ProjectProviderProps> = ({ children }) => {
  const {
    loading: gettingProjects,
    projectId,
    projects,
    setProjectId,
    currentProject,
    setCurrentProject,
    getProjects,
  } = projectHooks.useProjects();

  const projectContextValue: ProjectContextType = useMemo(
    () => ({
      projectId,
      setProjectId,
      projects,
      currentProject,
      setCurrentProject,
      getProjects,
      gettingProjects,
    }),
    [projectId, projects, currentProject, gettingProjects]
  );

  if (gettingProjects) {
    return <Spin className="app-spin" />;
  }

  return (
    <ProjectContext.Provider value={projectContextValue}>
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
