import FileUploadAndHistory from 'containers/shared/FileUploadAndHistory';
import { ProcessingFileType } from 'interfaces';
import userManagementServices from 'services/apis/userManagement';

const { importUsers } = userManagementServices;

const ImportUser: React.FC = () => {
  return (
    <FileUploadAndHistory
      processingFileType={ProcessingFileType.IMPORT_USER}
      customUploadRequest={importUsers}
    />
  );
};

export default ImportUser;
