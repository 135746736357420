import React from 'react';
import { Result } from 'antd';
import { t } from 'helpers/i18n';
import { BackToHomeButton } from 'components/shared/Button';

const Page403: any = () => {
  return (
    <Result
      className="app-result-page w-100 h-100"
      status="warning"
      title={t('FeatureUnderDevelopment')}
      extra={<BackToHomeButton />}
    />
  );
};

export default Page403;
