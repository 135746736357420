import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Polygon, Tooltip } from 'react-leaflet';
import { PathOptions, Polygon as PolygonType } from 'leaflet';
import { IBasemapLabel } from 'interfaces';
import './MapPolygons.scss';
import { t } from 'helpers/i18n';
import classNames from 'classnames';

export interface IPolygon {
  id?: number;
  label?: string;
  showLabel?: boolean;
  positions: number[] | number[][] | number[][][] | any;
  options: PathOptions;
  hoverOptions: PathOptions;
  activeOptions: PathOptions;
  errorOptions?: PathOptions;
  value?: IBasemapLabel;
  polygonOverviewRenderer?: React.ReactNode;
}
interface MapPolygonProps {
  children?: React.ReactNode;
  polygon: IPolygon;
  active?: boolean;
  error?: boolean;
  onReady?: (polygon: IPolygon, ref: any) => void;
  onPolygonClick?: (polygon: IPolygon, curPolygonInstance: any) => void;
  className?: string;
}

const activeColor = '#1890ff';

const MapPolygon: React.FC<MapPolygonProps> = ({
  active,
  error,
  polygon,
  onReady,
  onPolygonClick,
  className,
}) => {
  const polygonRef = useRef<PolygonType>(null);
  const [hover, setHover] = useState(false);

  const label = useMemo(() => {
    return (
      <div className={'map-polygon-label'}>
        {polygon.value && (polygon.value.label || polygon.value.area) ? (
          <>
            {polygon.value.label && (
              <div
                className={
                  'map-polygon-label-head map-polygon-label-head-bordered'
                }
              >
                {polygon.value.label}
              </div>
            )}
            {polygon.value.area && (
              <div className={'map-polygon-label-body'}>
                {`${t('Acreage')}: ${polygon.value.area} Ha`}
              </div>
            )}
          </>
        ) : polygon.label ? (
          <span className={'map-polygon-label-head'}>{polygon.label}</span>
        ) : (
          <></>
        )}
      </div>
    );
  }, [polygon]);

  useEffect(() => {
    if (polygonRef) {
      onReady?.(polygon, polygonRef);
    }
  }, [polygonRef]);

  return (
    <Polygon
      ref={polygonRef}
      positions={polygon.positions}
      pathOptions={
        error
          ? polygon.errorOptions
          : active
          ? polygon.activeOptions || activeColor
          : hover
          ? polygon.hoverOptions
          : polygon.options
      }
      eventHandlers={{
        mouseover: () => {
          setHover(true);
        },
        mouseout: () => {
          setHover(false);
        },
        click: () => {
          const curPolygonInstance = polygonRef.current;
          if (curPolygonInstance && polygon.id) {
            onPolygonClick?.(polygon, curPolygonInstance);
          }
        },
      }}
      className={classNames('map-polygon', className)}
    >
      {polygon.showLabel &&
        ((polygon.value && (polygon.value.label || polygon.value.area)) ||
          polygon.label) && (
          <Tooltip className={'tooltip-map'} sticky>
            {label}
          </Tooltip>
        )}
      {polygon.polygonOverviewRenderer && (
        <Tooltip className={'tooltip-map'} sticky>
          {polygon.polygonOverviewRenderer}
        </Tooltip>
      )}
    </Polygon>
  );
};

export { MapPolygon };
