import { Form, Row, Col, Select, Button, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { t } from 'helpers/i18n';
import { ISubmissionFilters } from 'interfaces';
import dayjs from 'dayjs';
import trackSubmissionFilter from 'helpers/tracker/trackSubmissionFilter';
import 'components/shared/Table/Table.scss';
import classNames from 'classnames';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { SUBMISSION_STATUS_FILTERS } from './filters';
import { filterSelect } from 'helpers/common';
import { useFlagsupContext, useProjectContext } from 'contexts';
import { DEFAULT_PAGINATION_OFFSET } from 'constants/pagination';
const { RangePicker } = DatePicker;

interface SubmissionFilterProps {
  filters: ISubmissionFilters;
  setFilters: Dispatch<SetStateAction<ISubmissionFilters>>;
}

interface FormFields {
  submissionStatus: string;
  submissionDateRange: [dayjs.Dayjs, dayjs.Dayjs];
}

const localeCompareString = (s1: string, s2: string): number => {
  return s1.toLowerCase().localeCompare(s2.toLowerCase());
};

const SubmissionFilter: React.FC<SubmissionFilterProps> = props => {
  const { filters, setFilters } = props;
  const { projectId } = useProjectContext();
  const [form] = Form.useForm<FormFields>();
  const { featureFlagsData } = useFlagsupContext();
  const {
    trackStatusChange,
    trackDateChange,
    trackSearch,
  } = trackSubmissionFilter;

  const isCAR2355Enabled = featureFlagsData.CAR_2355?.enabled;

  const getISODate = (value: any, index: number) => {
    if (value.submissionDateRange) {
      let date = value.submissionDateRange[index].$d
        .toISOString()
        .split('T')[0];
      if (!index) return date + 'T00:00:00.000Z';
      else return date + 'T23:59:59.999Z';
    } else return undefined;
  };

  const rangePresets: {
    label: any;
    value: any;
  }[] = [
    { label: t('Last7Days'), value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: t('LastMonth'), value: [dayjs().add(-1, 'M'), dayjs()] },
    { label: t('Last6Months'), value: [dayjs().add(-6, 'M'), dayjs()] },
    { label: t('LastYear'), value: [dayjs().add(-365, 'd'), dayjs()] },
  ];

  const handleStatusChange = (status: any) => {
    trackStatusChange(
      status,
      ['submitted', 'rejected', 'selected'].indexOf(status)
    );
  };

  const handleDateChange = (date: any) => {
    trackDateChange(date);
  };

  const onFinish = (values: FormFields) => {
    let newFilters: ISubmissionFilters = {
      limit: filters.limit,
      offset: DEFAULT_PAGINATION_OFFSET.offset,
      projectId: projectId,
      submissionStatus: values.submissionStatus,
    };
    if (isCAR2355Enabled) {
      newFilters = Object.assign(newFilters, {
        submissionTimeFrom:
          values.submissionDateRange &&
          values.submissionDateRange[0].startOf('day').unix(),
        submissionTimeTo:
          values.submissionDateRange &&
          values.submissionDateRange[1].endOf('day').unix(),
      });
    } else {
      newFilters = Object.assign(newFilters, {
        submissionDateFrom: getISODate(values, 0),
        submissionDateTo: getISODate(values, 1),
      });
    }
    setFilters(newFilters);
    trackSearch();
  };

  useEffect(() => {
    if (filters.submissionStatus && form?.getFieldValue('submissionStatus'))
      form?.setFieldValue('submissionStatus', filters.submissionStatus);
  }, [filters]);

  return (
    <Form
      data-testid="form-filter"
      layout="vertical"
      form={form}
      className={'table-custom'}
      onFinish={onFinish}
      initialValues={filters}
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="submissionStatus"
            label={t('Status')}
            className={classNames('mb-0', 'table-custom-item')}
          >
            <Select
              showSearch
              filterOption={(input, option) => filterSelect(input, option)}
              filterSort={(optionA, optionB) =>
                localeCompareString(optionA?.label ?? '', optionB?.label ?? '')
              }
              onChange={handleStatusChange}
              options={SUBMISSION_STATUS_FILTERS}
              data-testid="status"
              placeholder={t('SelectStatus')}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name="submissionDateRange"
            label={t('SubmissionTime')}
            className={classNames('mb-0', 'table-custom-item')}
          >
            <RangePicker
              presets={rangePresets}
              format="YYYY/MM/DD"
              onChange={handleDateChange}
              className="w-100"
            />
          </Form.Item>
        </Col>
        <Col xl={3} xxl={2} style={{ alignSelf: 'end' }}>
          <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
            {t('Search')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SubmissionFilter;
