/** Third party libs **/
import { Card, Space, Typography } from 'antd';
import classNames from 'classnames';
import React from 'react';
import {
  CalendarOutlined,
  PhoneOutlined,
  TeamOutlined,
} from '@ant-design/icons';

/** Local libs **/
import { IFarmerProfile } from 'interfaces';

/** Styles **/
import './FarmerCard.scss';

/** Interfaces, enum... **/
interface IFarmerCardProps {
  onClick: () => void;
  item: IFarmerProfile;
  isSelected?: boolean;
}

/** Variables **/
const { Text } = Typography;

/** ------------------------- **/
const FarmerCard: React.FC<IFarmerCardProps> = ({
  onClick,
  item,
  isSelected,
}) => {
  /** States **/

  /** Hooks **/

  /** Variables **/

  /** Functions, Events, Actions... **/

  /** Effects **/

  /** Elements **/
  return (
    <Card
      className={classNames('card-wrapper', 'w-100', isSelected && 'selected')}
      onClick={onClick}
    >
      <Space className="d-flex justify-content-between ">
        <div>
          <Text strong className="d-block">
            {item.fullName}
          </Text>
          <Text className="d-block">
            <PhoneOutlined style={{ color: '#00AA5B' }} /> {item.phoneNumber}
          </Text>
          <Text className="d-block">
            <TeamOutlined style={{ color: '#00AA5B' }} /> {item.communityName}
          </Text>
        </div>
        <div>
          <CalendarOutlined className={isSelected ? 'selected' : ''} />
        </div>
      </Space>
    </Card>
  );
};

export { FarmerCard };
export default FarmerCard;
