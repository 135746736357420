import { InboxOutlined } from '@ant-design/icons';
import { Upload, UploadFile } from 'antd';
import { MEDIA_FILE_TYPES } from 'constants/file';
import { fileHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import uploadHooks from 'hooks/upload';
import { useEffect, useState } from 'react';

const { getFileNameFromStorageURL } = fileHelpers;
interface MediaUploadProps {
  value?: string[];
  onChange?: (urls: string[]) => void;
}

const MediaUpload: React.FC<MediaUploadProps> = ({ value, onChange }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { uploadFile } = uploadHooks.useUploadFile();

  useEffect(() => {
    if (value)
      setFileList(
        value.map(
          url => ({ url, name: getFileNameFromStorageURL(url) } as UploadFile)
        )
      );
  }, [value]);

  const uploadMedia = async ({ file }: any) => {
    setFileList([...fileList, { ...file, status: 'uploading' }]);
    const url = await uploadFile(file, {
      type: file?.type.includes('video') ? 'video' : 'audio',
      isPrivate: true,
    });
    if (url && value) {
      onChange?.([...value, url]);
    } else {
      setFileList(fileList.slice(-1));
    }
  };

  const onRemove = (file: any) => {
    if (value) onChange?.(value.filter(url => url !== file.url));
  };

  return (
    <>
      <Upload.Dragger
        fileList={fileList}
        accept={MEDIA_FILE_TYPES}
        customRequest={uploadMedia}
        onRemove={onRemove}
      >
        <div className="mx-half my-half">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t('DragAndDropFileHere')}</p>
          <p className="ant-upload-hint">
            {t('UploadHint', { fileTypes: MEDIA_FILE_TYPES, maxSize: '10MB' })}
          </p>
        </div>
      </Upload.Dragger>
    </>
  );
};

export { MediaUpload };
