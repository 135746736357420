import FarmEligibility from 'components/FarmerManagement/FarmerEnrolment/FarmEligibility';
import AppContainer from 'containers/AppLayout/AppContainer';

const FarmLandsManagement: React.FC = () => {
  return (
    <AppContainer title="">
      <FarmEligibility />
    </AppContainer>
  );
};

export default FarmLandsManagement;
