export const wbRoutes = [
  {
    path: '/',
  },
  { path: '/user-management' },
  { path: '/user-management/import' },
  { path: '/plan', children: ['/funds', '/financial-plan'] },
  { path: '/funds' },
  { path: '/funds/import' },
  { path: '/financial-plan' },
  { path: '/community/meeting-records' },
  { path: '/communities' },
  { path: '/report' },
];
