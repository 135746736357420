export const COLOR = {
  PRIMARY: '#75AB6D',
  ERROR: '#DA4343',
  SUPPORT: '#1990FF',
  NEUTRAL: '#A7ABC3',
  BG_NEUTRAL: '#f3f3f7',
  WHITE: '#FFFF',
  BG_ACTIVE: '#E6F4FF',
  ACTIVE: '#1677FF',
  PLOT: '#cd8930',
};

export default {
  COLOR,
};
