import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, MenuProps } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { IRoute } from 'interfaces';
import { commonHooks } from 'hooks';
import { browserHistory } from 'helpers';
import { getMetaData } from 'helpers/common';
import { useFlagsupContext } from 'contexts';
import { keyBy, Dictionary } from 'lodash';

const { Sider, Footer } = Layout;
const { useAppMenu } = commonHooks;

interface AppSiderProps {
  filteredNavigation: IRoute[];
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

/*
 * loop multi-level menu
 */
const getChildrenMenu = (
  menu: IRoute[],
  navigationMap: Dictionary<IRoute>
): MenuProps['items'] =>
  menu.map(item => {
    const children = item.children ?? [];
    if (!children.length) {
      return {
        key: item.path,
        label: (
          <Link to={item.redirectTo || item.path}>
            <span style={{ verticalAlign: 'middle' }}>{item.name}</span>
          </Link>
        ),
      };
    }
    const filteredChildren = children
      .filter(nav => !!navigationMap[nav] && !navigationMap[nav].icon)
      .map(nav => navigationMap[nav]);
    return {
      key: item.path,
      label: <span style={{ verticalAlign: 'middle' }}>{item.name}</span>,
      children: getChildrenMenu(filteredChildren, navigationMap),
    };
  });

const AppSider: React.FC<AppSiderProps> = props => {
  // Get selectedKey, openKey from route & pathname
  const { filteredNavigation, collapsed, setCollapsed } = props;
  const { collapseOnBottom } = window.appConfig || {};
  const [logo, setLogo] = useState<string>();
  const { featureFlagsData } = useFlagsupContext();
  const { selectedKey, openKey, parentKey } = useAppMenu(filteredNavigation);

  const isCAR2398Enabled = featureFlagsData.CAR_2398?.enabled;
  const logoName = isCAR2398Enabled
    ? getMetaData().logo
    : window.appConfig.logoName || 'common.png';
  const navigationMap = useMemo(() => keyBy(filteredNavigation, 'path'), [
    filteredNavigation,
  ]);

  import(`assets/images/logo/${logoName}`).then((img: { default: string }) =>
    setLogo(img.default)
  );

  const menuItems = useMemo<MenuProps['items']>(
    () =>
      filteredNavigation
        .map(item => {
          if (!item.icon) return null;
          if (!item.children) {
            return {
              key: item.path,
              label: (
                <Link to={item.path}>
                  <item.icon className="app-icon" />
                  <span style={{ verticalAlign: 'middle' }}>{item.name}</span>
                </Link>
              ),
            };
          }
          const { children } = item;
          const firstLevelChildren = children
            .filter(nav => !!navigationMap[nav] && !navigationMap[nav].icon)
            .map(nav => navigationMap[nav]);
          return {
            key: item.path,
            label: (
              <span>
                <item.icon className="app-icon" />
                <span style={{ verticalAlign: 'middle' }}>{item.name}</span>
              </span>
            ),
            onTitleClick: () => {
              const { location } = browserHistory;
              if (item.redirectTo && location.pathname !== item.redirectTo)
                browserHistory.push(item.redirectTo);
            },
            className: 'first-level-menu',
            children: getChildrenMenu(firstLevelChildren, navigationMap),
          };
        })
        .filter(item => item), // filter out nullable items
    [filteredNavigation]
  );

  return (
    <Sider
      className={classNames({
        'app-sider': true,
        collapsed,
      })}
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={270}
    >
      {logo && (
        <div className="app-logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
      )}
      <Menu
        className="app-menu"
        theme="dark"
        mode="inline"
        defaultOpenKeys={[openKey]}
        selectedKeys={[selectedKey, parentKey]}
        items={menuItems}
      />

      {collapseOnBottom ? (
        !collapsed ? (
          <Footer
            className="app-footer collapsible"
            onClick={() => setCollapsed(true)}
          >
            <div className="d-flex align-items-center justify-content-center">
              <MenuFoldOutlined style={{ fontSize: 20, marginRight: 4 }} />
              Collapse
            </div>
          </Footer>
        ) : (
          <Footer
            className="app-footer collapsible collapsed"
            onClick={() => setCollapsed(false)}
          >
            <MenuUnfoldOutlined style={{ fontSize: 20 }} />
          </Footer>
        )
      ) : (
        <Footer className={classNames('app-footer', { hidden: collapsed })}>
          Carbon MRV Web Admin © {process.env.REACT_APP_VERSION || ''}
        </Footer>
      )}
    </Sider>
  );
};

export default AppSider;
