import React, { useState } from 'react';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { tSelectPlaceholder } from 'helpers/i18n';
import { ISearchLocation } from 'interfaces';
import {
  IBaseFilter,
  LiveSelectTypeOffset,
} from 'components/shared/Select/LiveSelectTypeOffset';
import { filterSelect } from 'helpers/common';
import { useMap } from 'react-leaflet';

const SearchLocation: React.FC = () => {
  const map = useMap();
  const [loading, setLoading] = useState(false);
  const [listLocation, setListLocation] = useState<ISearchLocation[]>([]);

  const provider = new OpenStreetMapProvider();

  const optionFetcher = async ({ keyword }: IBaseFilter) => {
    try {
      setLoading(true);
      if (keyword) {
        const results = await provider.search({ query: keyword });
        if (results) {
          setListLocation(results as ISearchLocation[]);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }

    return {
      options: listLocation.map(location => ({
        label: location.label,
        value: JSON.stringify({ lng: location.x, lat: location.y }),
        key: JSON.stringify({ lng: location.x, lat: location.y }),
      })),
    };
  };

  return (
    <div className="d-flex justify-content-center p-half">
      <LiveSelectTypeOffset
        allowClear
        allowSearchEmpty={false}
        optionKey="label"
        loading={loading}
        optionFetcher={optionFetcher}
        showSearch
        options={listLocation.map(location => {
          return {
            label: location.label,
            value: JSON.stringify({ lng: location.x, lat: location.y }),
          };
        })}
        placeholder={tSelectPlaceholder('Location')}
        filterOption={filterSelect}
        style={{ zIndex: 9999, width: 300 }}
        onChange={value => {
          if (value) {
            map.setView([JSON.parse(value).lat, JSON.parse(value).lng], 12);
          }
        }}
      />
    </div>
  );
};

export { SearchLocation };
export default SearchLocation;
