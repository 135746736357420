import { IPOI } from 'interfaces';
import { useState } from 'react';
import { POIServices } from 'services';
import { useProjectContext } from 'contexts';
import { PAGING_PAGE_SIZE_ALL } from 'constants/pagination';

const usePOIs = () => {
  const [POIs, setPOIs] = useState<IPOI[]>([]);
  const [gettingPOIs, setGettingPOIs] = useState<boolean>(false);
  const { projectId } = useProjectContext();
  const getPOIs = async (poiTypes: string[]) => {
    try {
      setGettingPOIs(true);
      const res = await POIServices.getPOIs({
        ...PAGING_PAGE_SIZE_ALL,
        poiTypes,
        projectId,
      });
      setPOIs(res.pois || []);
    } catch (e) {
      console.error(e);
    } finally {
      setGettingPOIs(false);
    }
  };

  return { getPOIs, POIs, gettingPOIs };
};

export default { usePOIs };
