import React from 'react';
import './OverlapWarning.scss';
import { Card, Typography } from 'antd';
import { t } from 'helpers/i18n';
import classNames from 'classnames';

interface IOverlapWarningProps {
  showWarning?: boolean;
}

const OverlapWarning: React.FC<IOverlapWarningProps> = ({
  showWarning = false,
}) => {
  return (
    <Card
      className={classNames('overlap-warning', {
        visible: showWarning,
        hidden: !showWarning,
        'opacity-1': showWarning,
        'opacity-0': !showWarning,
      })}
    >
      <Typography.Text type="danger">
        {t('OverlappedWithExistingPolygon')}
      </Typography.Text>
    </Card>
  );
};

export { OverlapWarning };
export default OverlapWarning;
