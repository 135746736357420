/** Third party libs * */
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Popover, Badge, Avatar, List, Button } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import classnames from 'classnames';

/** Local libs * */
import { t } from 'helpers/i18n';
import { StoreContext } from 'contexts';
import { stnHooks } from 'hooks';
import { commonConstants } from 'constants/index';

import {
  IFPICNotification,
  INotificationLink,
  IStnNotification,
} from 'interfaces';
/** Components * */

/** Styles * */
import './AppNotifications.scss';
import { getFpicUrl } from 'components/FPIC/helpers';

/** Interfaces, enum... * */
interface AppNotificationsProps {}

/** Variables * */

/** ------------------------- * */
const AppNotifications: React.FC<AppNotificationsProps> = () => {
  /** States * */
  const [open, setOpen] = useState(false);

  /** Hooks * */
  const history = useHistory();
  const { currentUser, firebaseToken } = useContext(StoreContext);
  const {
    total,
    loading,
    unread,
    notifications,
    markRead,
    markReadAll,
    loadMore,
  } = stnHooks.useNotifications(firebaseToken, currentUser, {
    app_id: commonConstants.STN_APP,
    limit: 5,
    page: 1,
    token: firebaseToken,
  });

  /** Variables * */

  /** Effects * */

  /** Functions, Events, Actions... * */
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const onMarkAllAsRead = () => {
    markReadAll();
  };

  const onSelect = (item: IStnNotification) => {
    try {
      if (item.link) {
        const data = JSON.parse(item.link) as INotificationLink;
        switch (data.type) {
          case 'FORMSUBMISSION':
            const fpicData = data as IFPICNotification | undefined;
            const formSubmissionId = fpicData?.data.formSubmissionId;
            if (formSubmissionId) {
              if (!item.is_read) {
                markRead(item.id);
              }
              setOpen(false);
              const url = getFpicUrl();
              history.push(`${url}?formSubmissionId=${formSubmissionId}`);
            }
            break;
        }
      }
    } catch (e) {}
  };

  const onSeeMore = () => {
    loadMore();
  };

  /** Elements * */
  const title = (
    <div className={'app-notification-content-title'}>
      <span>{t('Notifications')}</span>
      {unread > 0 && (
        <Button
          size={'small'}
          type={'link'}
          disabled={loading}
          onClick={onMarkAllAsRead}
        >
          {t('MarkAllAsRead')}
        </Button>
      )}
    </div>
  );
  const content = (
    <div className={'app-notification-container'}>
      <List
        itemLayout="horizontal"
        dataSource={notifications}
        renderItem={item => {
          let disable = true;
          const data = JSON.parse(item.link) as INotificationLink;
          switch (data.type) {
            case 'FORMSUBMISSION':
              const fpicData = data as IFPICNotification | undefined;
              const formSubmissionId = fpicData?.data.formSubmissionId;
              if (formSubmissionId) {
                disable = false;
              }
              break;
          }

          return (
            <List.Item
              className={classnames({
                'app-notification-item': true,
                unread: !item.is_read,
                disable,
              })}
              onClick={disable ? undefined : () => onSelect(item)}
            >
              <List.Item.Meta
                avatar={
                  <Avatar src={item.image_url} size={42} shape={'square'} />
                }
                title={item.title}
                description={item.message}
              />
            </List.Item>
          );
        }}
      />
      {total > notifications.length && (
        <div className={'app-notification-footer'} onClick={onSeeMore}>
          <Button type={'link'} size={'small'} loading={loading}>
            {t('SeeMore')}
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <Popover
      open={open}
      placement={'bottomRight'}
      trigger={['click']}
      title={title}
      content={content}
      onOpenChange={handleOpenChange}
      overlayClassName={'app-notification-popover'}
    >
      <Badge
        count={unread}
        style={{
          cursor: 'pointer',
          color: '#fff',
        }}
      >
        <Avatar
          icon={<BellOutlined />}
          size={32}
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            setOpen(!open);
          }}
        />
      </Badge>
    </Popover>
  );
};

export { AppNotifications };
export default AppNotifications;
