import { Typography } from 'antd';
import ProjectDetailMap from 'components/ProjectList/ProjectDetailMap';
import AppContainer from 'containers/AppLayout/AppContainer';
import { useProjectContext } from 'contexts';
import { convertNumberFormat } from 'helpers/common';
import { t } from 'helpers/i18n';
import { IProjectDetails } from 'interfaces';
import React, { useState } from 'react';

interface ProjectMapProps {
  children?: React.ReactNode;
}

const { Title } = Typography;

const ProjectMap: React.FC<ProjectMapProps> = () => {
  const [project, setProject] = useState<IProjectDetails | undefined>(
    undefined
  );
  const { projectId } = useProjectContext();

  return (
    <AppContainer
      title={
        <Title level={4}>
          {`${t('ProjectMap')} (${t('TotalArea')} : ${convertNumberFormat(
            project?.totalArea || 0
          )} Ha)`}
        </Title>
      }
    >
      <ProjectDetailMap projectId={projectId} setProject={setProject} />
    </AppContainer>
  );
};

export default ProjectMap;
