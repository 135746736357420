import {
  DATE_SLASH_FORMAT,
  DATE_TIME_SLASH_FORMAT,
  TIME_FORMAT_LITE,
} from 'constants/dateTime';
import dayjs from 'dayjs';

const formatUnixTimestamp = (
  date: number,
  format: string = DATE_TIME_SLASH_FORMAT
) => {
  return dayjs.unix(date).format(format);
};

const formatUnixTimpestampMilliseconds = (
  date: number,
  format: string = DATE_TIME_SLASH_FORMAT
) => {
  return dayjs(date).format(format);
};

/**
 * Format date range from unix timestamp
 * - If only startDate is provided, return startDate
 * - If only endDate is provided, return endDate
 * - If both startDate and endDate are provided, return startDate - endDate
 *
 * Example of possible return values: 01/01/2022 - 02/01/2022, 01/01/2022
 */
const formatUnixDateRange = (startDate: number, endDate: number) => {
  if (!startDate && !endDate) return '';

  const startDateFormatted = formatUnixTimestamp(startDate, DATE_SLASH_FORMAT);
  const endDateFormatted = formatUnixTimestamp(endDate, DATE_SLASH_FORMAT);

  if (!endDate) return startDateFormatted;
  if (!startDate) return endDateFormatted;

  return `${startDateFormatted} - ${endDateFormatted}`;
};

/**
 * Format date time range from unix timestamp
 * - If fromTime or toTime are not provided, return date
 * - If date, fromTime, and toTime are provided, return fromTime - toTime date
 *
 *  Example of possible return values: 10:00 - 12:00 01/01/2022, 01/01/2022
 */
const formatUnixDateWithTimeRange = (
  date: number,
  fromTime: number,
  toTime: number
) => {
  if (!date) return '';

  const dateFormatted = dayjs.unix(date).format(DATE_SLASH_FORMAT);

  if (!fromTime || !toTime) return dateFormatted;

  const fromTimeFormatted = dayjs.unix(fromTime).format(TIME_FORMAT_LITE);
  const toTimeFormatted = dayjs.unix(toTime).format(TIME_FORMAT_LITE);

  return `${fromTimeFormatted} - ${toTimeFormatted} ${dateFormatted}`;
};

export default {
  formatUnixTimestamp,
  formatUnixTimpestampMilliseconds,
  formatUnixDateRange,
  formatUnixDateWithTimeRange,
};
