import React from 'react';
import './Alert.scss';
import { Card, Space, Typography } from 'antd';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  InfoCircleFilled,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { v4 } from 'uuid';
import classNames from 'classnames';

type AlertType = 'error' | 'warning' | 'success' | 'info';

interface IAlertProps {
  type: AlertType;
  contents?: string[];
  showDot?: boolean;
}

const { Text } = Typography;

const Alert: React.FC<IAlertProps> = ({ type, contents, showDot = true }) => {
  const renderIcon = (type: AlertType) => {
    switch (type) {
      case 'error':
        return (
          <CloseCircleFilled className="d-block" style={{ color: '#F5222D' }} />
        );
      case 'warning':
        return (
          <InfoCircleFilled className="d-block" style={{ color: '#FAAD14' }} />
        );
      case 'success':
        return (
          <CheckCircleFilled className="d-block" style={{ color: '#52C41A' }} />
        );
      case 'info':
        return (
          <InfoCircleOutlined
            className="d-block"
            style={{ color: '#1677FF' }}
          />
        );
      default:
        return (
          <InfoCircleOutlined
            className="d-block"
            style={{ color: '#1677FF' }}
          />
        );
    }
  };

  return (
    <Card
      className={classNames('custom-alert', type)}
      styles={{
        body: {
          paddingTop: 6,
          display: 'flex',
        },
      }}
    >
      <div className="icon-warpper d-flex align-items-center justify-content-center">
        {renderIcon(type)}
      </div>
      <Space direction="vertical" className="warning-warpper">
        {Array.isArray(contents) &&
          contents?.length > 0 &&
          contents?.map(content => (
            <div key={v4()} className="d-flex align-items-center">
              {showDot && <div className="dot" />}
              <Text>{content}</Text>
            </div>
          ))}
      </Space>
    </Card>
  );
};

export { Alert };
export default Alert;
