import { useComments } from 'components/FPIC/hooks/comment';
import { IFormSubmission } from 'interfaces';
import React from 'react';
import './CommentV2.scss';
import { Comment } from 'components/shared/FormSubmission/Comment';

interface CommentV2Props {
  formSubmission: IFormSubmission;
  disabled?: boolean;
}

const CommentV2: React.FC<CommentV2Props> = ({ disabled, formSubmission }) => {
  const {
    comments,
    fetchLoading,
    isLoadedAll,
    loadMore,
    onSendComment,
  } = useComments(formSubmission);

  return (
    <Comment
      comments={comments.map(comment => ({
        id: comment.commentId,
        commenter: comment.commenterName,
        createdAt: comment.updatedAt,
        content: comment.content,
      }))}
      onLoadMore={loadMore}
      canLoadMore={!isLoadedAll}
      fetchDataloading={fetchLoading}
      disabled={disabled}
      onSendComment={content => onSendComment({ comment: content })}
    />
  );
};

export { CommentV2 };
