import { t } from 'helpers/i18n';
import { IMap } from 'interfaces';

const IAM_SCOPES = [
  'openid',
  'profile',
  'read:permissions',
  'pm-tekbone-bff',
  'files',
];

const APPS = {
  AJA: 'aja-app',
  BASE: 'base-app',
  DEMO: 'demo-app',
  ACC: 'acc-app',
  WB: 'wb-app',
  WA: 'wa-app',
};

const IS_AJA = window.appConfig?.appName === APPS.AJA;
const IS_DEMO = window.appConfig?.appName === APPS.DEMO;
const IS_BASE = window.appConfig?.appName === APPS.BASE;
const IS_WA = window.appConfig?.appName === APPS.WA;
const IS_WB = window.appConfig?.appName === APPS.WB;

// Same query key = same cached data
const QUERY_KEYS = {
  CREMA: 'crema',
  COMMUNITY: 'communities',
  FARMLANDS: 'farmlands',
  SEARCH_POLYGON: 'search_polygon',
  POLYGON_TYPES: 'polygon_types',
  FORMULAR: 'formular',
  PROJECT_DETAIL: 'project_detail',
  COMMUNE: 'commune',
  SURVEY_REPORT: 'survey_report',
  BASEMAP_POLYGONS: 'basemap_polygons',
  BASEMAP_LEGENDS: 'basemap_legends',
  NEARBY_POLYGON: 'nearby_polygon',
  SAMPLING_MODULES: 'sampling_modules',
  TEMPLATES: 'templates',
  CREMA_TEMPLATES: 'crema_templates',
};

export default {
  IAM_SCOPES,
  ALL: 'all',
  TABLET_WIDTH: 768,
  LAPTOP_WIDTH: 1200,
  STN_APP: window.config.stnAppId || 'carbon_web',
  APPS,
  IS_AJA,
  IS_DEMO,
  IS_WA,
  IS_BASE,
  IS_WB,
  QUERY_KEYS,
};

export const ALL = -1;
export const DEBOUNCE_TIME = 800;
export const ID_DEFAULT = -1;
export const PROJECT_BOUNDARY_Z_INDEX = 1; // zIndex backend
export const OTHER_VALUE = '__option_other__';

export const GENDER_OPTIONS: {
  [key: string]: { value: string; label: string };
} = {
  FEMALE: {
    value: 'FEMALE',
    label: t('Female'),
  },
  MALE: {
    value: 'MALE',
    label: t('Male'),
  },
  ANOTHER: {
    value: 'ANOTHER',
    label: t('Other'),
  },
};

export type IType = IMap<{ value: number | string; label: string }>;

export const DEFAULT_MAX_TAG_COUNT = 4;

export const DRAWER_WIDTH = {
  SMALL: 340,
  MEDIUM: 450,
  MEDIUM_LARGE: 500,
  LARGE: 600,
};

export const MODAL_WIDTH = {
  LARGE: 1000,
};

export const THUMBNAIL_SIZE = { width: 60, height: 60 };

export const NOT_AVAILABLE = 'N/A';

export const PHONE_NUMBER_REGEX = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
