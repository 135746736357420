import { AxiosInstance } from 'axios';
import { useEffect, useState } from 'react';
import { ICacheStorage } from 'utils/cacheStorage';

interface IFetchOptions {
  TTL: number;
  cacheKey: string;
  method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH';
}

const isValidData = (value: string) => {
  try {
    const obj = JSON.parse(value);
    if (!obj.expiresBy) return false;
    return Date.now() < obj.expiresBy;
  } catch (err) {
    return false;
  }
};

export const useFetchWithCache = <T extends any>(
  axiosInstance: AxiosInstance,
  cacheStorageInstance: ICacheStorage,
  url: string,
  options: IFetchOptions
) => {
  const [fetchData, setFetchData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const res = await axiosInstance.request<T>({
          method: options.method,
          url,
        });
        setFetchData(res.data as any);
        const cacheObj = {
          expiresBy: Date.now() + options.TTL,
          data: res.data,
        };
        await cacheStorageInstance.addData({
          key: options.cacheKey,
          data: cacheObj,
        });
      } catch (err) {
        setError(err as any);
      } finally {
        setLoading(false);
      }
    };

    const getStorageData = async () => {
      try {
        const localData = await cacheStorageInstance.getData({
          key: options.cacheKey,
        });
        if (!localData) throw new Error('No local data');
        if (isValidData(localData)) throw new Error('Invalid json data');
        setFetchData(localData.data);
      } catch (err) {
        await fetch();
      }
    };

    getStorageData();
  }, []);

  return {
    data: fetchData as T,
    error,
    loading,
  };
};
