import { GOOGLE_DRIVE_ONLINE_VIEWER } from 'constants/file';
import IFrameContainer from 'containers/shared/IFrameContainer';
import InProgressPage from 'containers/shared/InProgressPage';
import { useProjectContext } from 'contexts';
import { RouteComponentProps } from 'react-router-dom';

export interface IFrameContainerProps extends RouteComponentProps {
  iFrameSrc: string;
}

export const PROJECTS = window.appConfig?.projects || {};

const InterventionI3A1: React.FC<IFrameContainerProps> = props => {
  const { projectId } = useProjectContext();
  const iframeUrl = {
    [PROJECTS.Kwahu]:
      'https://files.tekoapis.com/files/03e0f7b4-4fc6-4c04-8ab5-5779d7b67e36',
  };

  return iframeUrl[projectId] ? (
    <IFrameContainer
      {...props}
      iFrameSrc={GOOGLE_DRIVE_ONLINE_VIEWER(iframeUrl[projectId])}
    />
  ) : (
    <InProgressPage />
  );
};

export default InterventionI3A1;
