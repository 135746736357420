import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';

interface AppBreadcrumbProps {
  crumbs: ItemType[];
}

const itemRender = (route: ItemType, params: object, routes: ItemType[]) => {
  const last = routes.indexOf(route) === routes.length - 1;

  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={route.path || '/'}>{route.breadcrumbName}</Link>
  );
};

const AppBreadcrumb: React.FC<AppBreadcrumbProps> = props => {
  const { crumbs } = props;

  return (
    <Breadcrumb
      className="app-breadcrumb pt-half pb-0"
      itemRender={itemRender}
      items={crumbs}
      separator=">"
    />
  );
};

export default AppBreadcrumb;
