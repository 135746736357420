/** Third party libs * */
import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { Spin } from 'antd';

/** Local libs * */

/** Components * */

/** Styles * */
import './MediaPlayer.scss';

/** Interfaces, enum... * */
interface MediaPlayerProps {
  url: string;
}

/** Variables * */

/** ------------------------- * */
const MediaPlayer: React.FC<MediaPlayerProps> = ({ url }) => {
  /** States * */
  const [height, setHeight] = useState<string>('0px');
  const [loading, setLoading] = useState<boolean>(true);

  /** Hooks * */

  /** Variables * */

  /** Effects * */

  /** Functions, Events, Actions... * */
  const onReady = (e: any) => {
    setLoading(false);
    const html = e.wrapper.innerHTML;
    if (html.includes('video')) {
      setHeight('250px');
    } else if (html.includes('audio')) {
      setHeight('50px');
    }
  };

  /** Elements * */
  return (
    <div className="media-player w-100">
      <Spin style={{ width: '100%', minHeight: '50px' }} spinning={loading}>
        <ReactPlayer
          width={'100%'}
          height={height}
          onReady={onReady}
          url={url}
          pip={false}
          loop={false}
          controls={true}
        />
      </Spin>
    </div>
  );
};

export { MediaPlayer };
export default MediaPlayer;
