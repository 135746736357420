import { IRegion } from 'interfaces';
// Flags
import viFlag from 'assets/images/flags/vi.svg';
import enFlag from 'assets/images/flags/en.svg';
import idFlag from 'assets/images/flags/id.png';
import frFlag from 'assets/images/flags/fr.png';
import kmFlag from 'assets/images/flags/km.png';
// Translation files
import viTrans from 'locales/vi/translation.json';
import enTrans from 'locales/en/translation.json';
import idTrans from 'locales/id/translation.json';
import frTrans from 'locales/fr/translation.json';
import kmTrans from 'locales/km/translation.json';
import waTrans from 'locales/en/wa-app.json';
// Antd locale files
import viVN from 'antd/lib/locale/vi_VN';
import enUS from 'antd/lib/locale/en_US';
import idID from 'antd/lib/locale/id_ID';
import frFR from 'antd/lib/locale/fr_FR';
import kmKH from 'antd/lib/locale/km_KH';
// Tree species files
import viSpecies from 'locales/vi/treeSpecies.json';
import enSpecies from 'locales/en/treeSpecies.json';
import idSpecies from 'locales/id/treeSpecies.json';

// Tree scientific name
import scientificSpecies from 'locales/en/treeScientific.json';

const RESOURCES = {
  vi: { translation: viTrans },
  en: { translation: enTrans, 'wa-app': waTrans },
  id: { translation: idTrans },
  fr: { translation: frTrans },
  km: { translation: kmTrans },
};

const TREE_SPECIES_RESOURCES: { [key: string]: { [key: string]: string } } = {
  vi: viSpecies,
  en: enSpecies,
  id: idSpecies,
  fr: enSpecies,
  km: enSpecies,
};

const TREE_SPECIES_SCIENTIFIC_RESOURCES: {
  [key: string]: string;
} = scientificSpecies;

const REGIONS: IRegion = {
  vi: {
    key: 'vi',
    name: 'Tiếng Việt',
    flag: viFlag,
    antdLocale: viVN,
  },
  en: {
    key: 'en',
    name: 'English',
    flag: enFlag,
    antdLocale: enUS,
  },
  id: {
    key: 'id',
    name: 'Indonesian',
    flag: idFlag,
    antdLocale: idID,
  },
  fr: {
    key: 'fr',
    name: 'France',
    flag: frFlag,
    antdLocale: frFR,
  },
  km: {
    key: 'km',
    name: 'Khmer',
    flag: kmFlag,
    antdLocale: kmKH,
  },
};

export default {
  RESOURCES,
  REGIONS,
  TREE_SPECIES_RESOURCES,
  TREE_SPECIES_SCIENTIFIC_RESOURCES,
};
