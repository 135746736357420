import { getData } from 'helpers/request';
import { IGetPoisPayload, IGetPoiResponse } from 'interfaces';
import { requestServices } from 'services';

const { projectManagerClient } = requestServices;

const getPOIs = (payload: IGetPoisPayload): Promise<IGetPoiResponse> => {
  return projectManagerClient
    .post('tekbone/api/v1/pois', payload)
    .then(getData);
};

export default { getPOIs };
