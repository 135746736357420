import { Button } from 'antd';
import ContentBlock from 'components/shared/ContentBlock';
import React from 'react';
import { useHistory } from 'react-router-dom';

const QuarterlyReportContainer: React.FC = () => {
  const QuarterlyReports = ['Q4-2023', 'Q1-2024', 'Q2-2024'];
  const history = useHistory();

  return (
    <ContentBlock>
      {QuarterlyReports.map(quarter => (
        <Button
          type="link"
          key={quarter}
          onClick={() => history.push(`/quarterly-reports/${quarter}`)}
        >
          {quarter}
        </Button>
      ))}
    </ContentBlock>
  );
};

export { QuarterlyReportContainer };
export default QuarterlyReportContainer;
