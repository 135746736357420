import {
  Col,
  Collapse,
  DatePicker,
  Empty,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import { GENDER_OPTIONS } from 'constants/common';
import { DATE_SLASH_FORMAT } from 'constants/dateTime';
import dayjs from 'dayjs';
import { t } from 'helpers/i18n';
import { farmerHooks } from 'hooks';
import React, { useEffect } from 'react';
import { FSImage } from '../FSImage';
import './FarmerDetails.scss';

interface IFarmerDetailsProps {
  farmerId: number;
}

const { Panel } = Collapse;
const { Item } = Form;
const { Title } = Typography;
const COLLAPSE_KEYS = {
  BasicInfo: 'BasicInfo',
  Identification: 'Identification',
  Face: 'Face',
  Spouse: 'Spouse',
  Children: 'Children',
};

const FarmerDetails: React.FC<IFarmerDetailsProps> = ({ farmerId }) => {
  const [form] = Form.useForm();
  const { farmers, setParams, loading } = farmerHooks.useFarmers({
    userIds: [farmerId],
  });

  const farmer = farmers.length ? farmers[0] : undefined;

  useEffect(() => {
    if (farmer) {
      form.setFieldsValue({
        ...farmer,
        birthdate: farmer?.birthdate
          ? dayjs.unix(farmer?.birthdate)
          : undefined,
      });
    }
  }, [farmers]);

  useEffect(() => {
    setParams(params => {
      return { ...params, userIds: [farmerId] };
    });
  }, [farmerId]);

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <Spin />
      </div>
    );
  if (!farmer) return <Empty />;

  return (
    <Form form={form} layout="vertical">
      <Collapse
        defaultActiveKey={[
          COLLAPSE_KEYS.BasicInfo,
          ...(farmer.identityCardBack?.image || farmer.identityCardFront?.image
            ? [COLLAPSE_KEYS.Identification]
            : []),
        ]}
        bordered={true}
      >
        <Panel key={COLLAPSE_KEYS.BasicInfo} header={t('BasicInfo')}>
          <Item name="fullName" label={t('FullName')}>
            <Input readOnly />
          </Item>
          <Item name="phoneNumber" label={t('PhoneNumber')}>
            <Input readOnly />
          </Item>
          <Item name="gender" label={t('Gender')}>
            <Select
              className="read-only"
              options={Object.values(GENDER_OPTIONS)}
            />
          </Item>
          <Item name="birthdate" label={t('DateOfBirth')}>
            <DatePicker
              className="w-100 read-only"
              format={DATE_SLASH_FORMAT}
            />
          </Item>
          <Item name="communityName" label={t('HomeCommunity')}>
            <Input readOnly />
          </Item>
          <Item name="address" label={t('Address')}>
            <Input readOnly />
          </Item>
        </Panel>
        <Panel
          key={COLLAPSE_KEYS.Identification}
          header={t('IdentificationDocuments')}
        >
          <Row gutter={[8, 8]}>
            {farmer.identityCardBack?.image && (
              <Col span={12}>
                <FSImage
                  rootClassName="img-preview-mask"
                  width="100%"
                  src={farmer.identityCardBack.image}
                />
              </Col>
            )}
            {farmer.identityCardFront?.image && (
              <Col span={12}>
                <FSImage
                  rootClassName="img-preview-mask"
                  width="100%"
                  src={farmer.identityCardFront.image}
                />
              </Col>
            )}
          </Row>
        </Panel>
        <Panel key={COLLAPSE_KEYS.Face} header={t('FaceVerification')}>
          {farmer.faceImageUrl && (
            <FSImage
              rootClassName="img-preview-mask"
              width="100%"
              src={farmer.faceImageUrl}
            />
          )}
        </Panel>
        <Panel key={COLLAPSE_KEYS.Spouse} header={t('SpousesInformation')}>
          {farmer?.spouses?.map((spouse, index) => {
            return (
              <div key={index}>
                <Title level={4}>
                  {t('Spouses')} #{index + 1}
                </Title>
                <Item name="spouse-fullName" label={t('FullName')}>
                  <Input defaultValue={spouse?.fullName} readOnly />
                </Item>
                <Item name="spouse-phoneNumber" label={t('PhoneNumber')}>
                  <Input defaultValue={spouse?.phoneNumber} readOnly />
                </Item>
                <Item name="spouse-gender" label={t('Gender')}>
                  <Select
                    className="read-only"
                    defaultValue={spouse?.gender}
                    options={Object.values(GENDER_OPTIONS)}
                  />
                </Item>
                <Item name="spouse-birthdate" label={t('DateOfBirth')}>
                  <DatePicker
                    defaultValue={
                      spouse?.birthdate ? dayjs(spouse?.birthdate) : undefined
                    }
                    className="w-100 read-only"
                    format={DATE_SLASH_FORMAT}
                  />
                </Item>
              </div>
            );
          })}
        </Panel>
        <Panel key={COLLAPSE_KEYS.Children} header={t('ChildrenInformation')}>
          {farmer?.children?.map((child, index) => {
            return (
              <div key={index}>
                <Title level={4}>
                  {t('Children')} #{index + 1}
                </Title>
                <Item name="child-fullName" label={t('FullName')}>
                  <Input defaultValue={child?.fullName} readOnly />
                </Item>
                <Item name="child-birthdate" label={t('DateOfBirth')}>
                  <DatePicker
                    defaultValue={
                      child?.birthdate ? dayjs(child?.birthdate) : undefined
                    }
                    className="w-100 read-only"
                    format={DATE_SLASH_FORMAT}
                  />
                </Item>
              </div>
            );
          })}
        </Panel>
      </Collapse>
    </Form>
  );
};

export { FarmerDetails };
export default FarmerDetails;
