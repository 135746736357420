import { Table, TableProps } from 'antd';
import { NOT_AVAILABLE } from 'constants/common';
import { convertNumberFormat } from 'helpers/common';
import { t } from 'helpers/i18n';
import {
  IARRCarbonPotentialReport,
  IARRCarbonPotentialReportRowData,
} from 'interfaces';
import { sumBy } from 'lodash';

interface CarbonPotentialReportTableProps extends TableProps {
  carbonPotentialReport?: IARRCarbonPotentialReport;
}

const CarbonPotentialReportTable: React.FC<CarbonPotentialReportTableProps> = ({
  carbonPotentialReport,
  ...tableProps
}) => {
  const {
    riskLevel,
    uncertainty,
    leakage,
    performanceBenchmark,
    nonPermanenceRisk,
  } = carbonPotentialReport?.reportAttributes?.riskLevel || {};

  const columns: TableProps<IARRCarbonPotentialReportRowData>['columns'] = [
    {
      title: t('Year'),
      dataIndex: 'year',
    },
    {
      title: t('PlantedAreaHa'),
      dataIndex: 'plantedArea',
      render: value => convertNumberFormat(value),
    },
    {
      title: t('IncrementalPlantingAreaHa'),
      dataIndex: 'incrementalPlantingArea',
      render: value => convertNumberFormat(value),
    },
    {
      title: t('LinearModelEstimatedRemovalCO2eTonHa'),
      dataIndex: 'linearModelEstimatedRemoval',
      render: value => convertNumberFormat(value),
    },
    {
      title: t('EmissionCO2eTon'),
      dataIndex: 'emission',
      render: value => convertNumberFormat(value),
    },
    {
      title: (
        <>
          <p>
            {t('Risk')} - {t(riskLevel || NOT_AVAILABLE)}
          </p>
          <ul>
            <li>
              {t('Uncertainty')}: {uncertainty}%
            </li>
            <li>
              {t('Leakage')}: {leakage}%
            </li>
            <li>
              {t('PerformanceBenchmark')}: {performanceBenchmark}%
            </li>
            <li>
              {t('NonPermanenceRisk')}: {nonPermanenceRisk}%
            </li>
          </ul>
        </>
      ),
      dataIndex: 'risk',
      render: value => convertNumberFormat(value),
    },
  ];

  const data = carbonPotentialReport?.reportData;
  const totalLinearModelEstimatedRemoval = sumBy(
    data,
    'linearModelEstimatedRemoval'
  );
  const totalEmission = sumBy(data, 'emission');
  const totalRisk = sumBy(data, 'risk');

  return (
    <Table
      columns={columns}
      dataSource={data}
      bordered
      pagination={false}
      rowKey="year"
      sticky={{ offsetHeader: 0 }}
      summary={() => (
        <Table.Summary fixed>
          <Table.Summary.Row>
            <Table.Summary.Cell index={0}>{t('Total')}</Table.Summary.Cell>
            <Table.Summary.Cell index={1} />
            <Table.Summary.Cell index={2} />
            <Table.Summary.Cell index={3}>
              {convertNumberFormat(totalLinearModelEstimatedRemoval)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={4}>
              {convertNumberFormat(totalEmission)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={5}>
              {convertNumberFormat(totalRisk)}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
      )}
      {...tableProps}
    />
  );
};

export default CarbonPotentialReportTable;
