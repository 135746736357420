import { getData } from 'helpers/request';
import {
  ICreatePOIPayload,
  IGetCremaCommunitiesParams,
  IGetCremaCommunitiesResponse,
  IGetFundCategoriesResponse,
  IGetFundsParams,
  IGetFundsResponse,
  IGetListCommunitiesReponse,
  IGetProvinceCommunitiesResponse,
  IFarmLandsResponse,
  IGetCommunitiesParams,
  IGetFarmLandsParams,
  IValidateCremaResponse,
  ICommunityDetail,
  ICommunityOverview,
  IGetCommuneCommunitiesParams,
  IGetCommuneCommunitiesResponse,
  IGetCommuneWithCommunities,
  IGetCommunityDetailParams,
  IGetCommunityOverviewParams,
  IGetListCommunesParams,
  IGetListCommunesReponse,
  IGetListCommunitiesTekboneReponse,
  IUpsertCommunity,
  IGetProjectCommunitiesParams,
  IGetProjectCommunitiesReponse,
  IUpdateCommunityPayload,
  IUpdateCommunityResponse,
} from 'interfaces';
import { requestServices } from 'services';
import qs from 'qs';

const { projectManagerClient } = requestServices;

const PREFIX_API_URL = '/tekbone/api/v1';

const getListCommunities = async (
  data: IGetCommunitiesParams
): Promise<IGetListCommunitiesReponse> => {
  return await projectManagerClient
    .post(`${PREFIX_API_URL}/communities`, data)
    .then(getData);
};

const createPOI = (payload: ICreatePOIPayload) =>
  projectManagerClient.post(`${PREFIX_API_URL}/poi`, payload);

const getProvinceCommunities = (): Promise<IGetProvinceCommunitiesResponse> =>
  projectManagerClient
    .get(`${PREFIX_API_URL}/province-communities`)
    .then(getData);

const getFundCategories = (): Promise<IGetFundCategoriesResponse> =>
  projectManagerClient
    .get(`${PREFIX_API_URL}/get-fund-categories`)
    .then(getData);

const getFunds = (params: IGetFundsParams): Promise<IGetFundsResponse> =>
  projectManagerClient
    .get(`${PREFIX_API_URL}/get-list-funds`, { params })
    .then(getData);

const importFunds = (formData: FormData): Promise<void> =>
  projectManagerClient.post(`${PREFIX_API_URL}/import-funds`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

const getFarmlands = (
  params: IGetFarmLandsParams
): Promise<IFarmLandsResponse> =>
  projectManagerClient
    .post(`${PREFIX_API_URL}/farmlands`, params)
    .then(getData);

const getListCremaCommunities = (
  params: IGetCremaCommunitiesParams
): Promise<IGetCremaCommunitiesResponse> =>
  projectManagerClient
    .get(`${PREFIX_API_URL}/cremas/communities`, { params })
    .then(getData);

const upsertCommunity = (
  payload: IUpsertCommunity
): Promise<{ communityId: number }> =>
  projectManagerClient
    .post(`${PREFIX_API_URL}/cremas/community`, payload)
    .then(getData);

const updateCommunity = (
  payload: IUpdateCommunityPayload
): Promise<IUpdateCommunityResponse> =>
  projectManagerClient
    .put(`${PREFIX_API_URL}/cremas/communities/${payload.communityId}`, payload)
    .then(getData);

const getCommunityDetail = (
  params: IGetCommunityDetailParams
): Promise<ICommunityDetail> =>
  projectManagerClient
    .get(`${PREFIX_API_URL}/cremas/communities/${params.communityId}`, {
      params,
    })
    .then(getData);

const validateCommunity = (
  payload: IUpsertCommunity
): Promise<IValidateCremaResponse> =>
  projectManagerClient
    .post(`${PREFIX_API_URL}/cremas/communities/validate`, payload)
    .then(getData);

const getListCommunes = (
  params: IGetListCommunesParams
): Promise<IGetListCommunesReponse> =>
  projectManagerClient
    .get(`${PREFIX_API_URL}/communes`, { params })
    .then(getData);

const getListCommuneCommunities = (
  params: IGetCommuneCommunitiesParams
): Promise<IGetCommuneCommunitiesResponse> =>
  projectManagerClient
    .get(`${PREFIX_API_URL}/get-communities`, { params })
    .then(getData);

const getListCommunesAndCommunities = (
  params: IGetListCommunesParams
): Promise<IGetCommuneWithCommunities> =>
  projectManagerClient
    .get(`${PREFIX_API_URL}/get-list-communes-and-communities`, { params })
    .then(getData);

const getListCommunitiesTekbone = (
  params: IGetCommunitiesParams
): Promise<IGetListCommunitiesTekboneReponse> =>
  projectManagerClient
    .get(`${PREFIX_API_URL}/get-list-communities`, { params })
    .then(getData);

const getCommunityOverview = (
  params: IGetCommunityOverviewParams
): Promise<ICommunityOverview> =>
  projectManagerClient
    .get(`${PREFIX_API_URL}/get-community-overview`, {
      params,
    })
    .then(getData);

const getListProjectCommunities = async (
  params: IGetProjectCommunitiesParams
): Promise<IGetProjectCommunitiesReponse> =>
  projectManagerClient
    .get(`${PREFIX_API_URL}/communities`, {
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'comma' });
      },
    })
    .then(getData);

export default {
  getListCommunities,
  createPOI,
  getProvinceCommunities,
  getFundCategories,
  getFunds,
  importFunds,
  getFarmlands,
  getListCremaCommunities,
  upsertCommunity,
  getCommunityDetail,
  validateCommunity,
  getListCommunes,
  getListCommuneCommunities,
  getListCommunesAndCommunities,
  getListCommunitiesTekbone,
  getCommunityOverview,
  getListProjectCommunities,
  updateCommunity,
};
