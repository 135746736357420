import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constants/pagination';
import { useProjectContext } from 'contexts';
import {
  IFarmerProfile,
  IGetFarmerParams,
  IPaginationResponse,
} from 'interfaces';
import { useEffect, useMemo, useState } from 'react';
import { farmerServices } from 'services';
import { useAbortController } from './axios';

const useFarmers = (defaultParams?: Partial<IGetFarmerParams>) => {
  const { projectId } = useProjectContext();
  const DEFAULT_PARAMS: IGetFarmerParams = {
    ...(defaultParams ?? {}),
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    projectId,
  };

  const [params, setParams] = useState<IGetFarmerParams>(DEFAULT_PARAMS);
  const [farmers, setFarmers] = useState<IFarmerProfile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<
    Omit<IPaginationResponse, 'totalPages'>
  >({
    pageSize: DEFAULT_PARAMS.pageSize,
    total: 0,
    page: DEFAULT_PARAMS.page,
  });
  const { newCancelToken } = useAbortController();

  const getFarmers = async (farmerParams?: Partial<IGetFarmerParams>) => {
    try {
      setLoading(true);
      const data = await farmerServices.getFarmer(
        {
          ...params,
          ...(farmerParams ?? {}),
        },
        { cancelToken: newCancelToken() }
      );
      setFarmers(data.farmerProfiles || []);
      setPagination(data.pagination);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = async (keyword?: string, userIds?: number[]) => {
    if (!loading && !isLoadedAll) {
      try {
        setLoading(true);
        const data = await farmerServices.getFarmer(
          {
            page: pagination.page + 1,
            pageSize: pagination.pageSize,
            keyword,
            userIds,
            projectId,
          },
          { cancelToken: newCancelToken() }
        );
        setFarmers([...farmers, ...(data.farmerProfiles || [])]);
        setPagination(data.pagination);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const isLoadedAll = useMemo(() => {
    if (loading) return false;
    return pagination.page * pagination.pageSize >= pagination.total;
  }, [pagination, loading]);

  useEffect(() => {
    getFarmers();
  }, [params]);

  const refresh = (keyword?: string, userIds?: number[]) => {
    setFarmers([]);
    getFarmers({ keyword, userIds });
  };

  return {
    params,
    setParams,
    farmers,
    getFarmers,
    pagination,
    loading,
    loadMore,
    isLoadedAll,
    refresh,
    setFarmers,
  };
};

export default {
  useFarmers,
};
