import { CheckOutlined } from '@ant-design/icons';
import { Button, Card, Typography } from 'antd';
import { useProjectDetailV2Context } from 'containers/Project/ProjectDetailMapV2/context';
import { t } from 'i18next';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setAddSamplingPlotType } from 'redux/features/samplingPlot';
import { AppDispatch } from 'redux/store';

const FinishManualAddSamplingPlot: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { setSelectedLayerTypes } = useProjectDetailV2Context();

  const onFinishAddManualSamplingPlot = () => {
    dispatch(setAddSamplingPlotType('NONE'));
    setSelectedLayerTypes(['Default']);
  };

  return (
    <Card
      className="finish-manual-sampling-plot"
      style={{
        zIndex: 999,
        position: 'absolute',
        bottom: 20,
        translate: '340% -25%',
      }}
      styles={{ body: { padding: 8 } }}
    >
      <Typography.Text className="mr-half">{t('PickingPlots')}</Typography.Text>
      <Button
        icon={<CheckOutlined />}
        onClick={onFinishAddManualSamplingPlot}
        type="primary"
      >
        {t('Finish')}
      </Button>
    </Card>
  );
};

export { FinishManualAddSamplingPlot };
export default FinishManualAddSamplingPlot;
