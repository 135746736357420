import { AxiosRequestConfig } from 'axios';
import { getData } from 'helpers/request';
import {
  IGetUserAppointmentParams,
  IUpsertUserAppointment,
  IUserAppointmentResponse,
} from 'interfaces';
import { requestServices } from 'services';

const { projectManagerClient } = requestServices;

const getListUserAppointment = (
  params: IGetUserAppointmentParams,
  config?: AxiosRequestConfig
): Promise<IUserAppointmentResponse> => {
  return projectManagerClient
    .post('/api/v1/user-appointments', params, config)
    .then(getData);
};

const upsertUserAppointment = (params: IUpsertUserAppointment) => {
  return projectManagerClient
    .post('/api/v1/user-appointment', params)
    .catch(error => error.response);
};

export default {
  getListUserAppointment,
  upsertUserAppointment,
};
