import { createContext, useContext } from 'react';

interface ICurrentMeetingRecord {
  currentCommunityName?: string;
  setCurrentCommunityName?: React.Dispatch<React.SetStateAction<string>>;
}

export const CurrentMeetingRecordContext = createContext<ICurrentMeetingRecord>(
  { currentCommunityName: '', setCurrentCommunityName: () => {} }
);

export const useCurrentMeetingRecord = () =>
  useContext(CurrentMeetingRecordContext);
