/** Third party libs **/
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';

/** Local libs **/
import ContentBlock from 'components/shared/ContentBlock';
import FarmerList from './FarmerList';
import FarmerFilter from './FarmerFilter';
import { farmerHooks, userAppointmentHooks } from 'hooks';
import { IFarmerProfile, IUserAppointment } from 'interfaces';
import MakeAppointment from './MakeAppointment';
import { LIST_FARMER_TYPE_CONSTANT } from 'constants/farmer';
import { useProjectContext } from 'contexts';
import { PreEnrollmentContext } from '../Context';

/** Styles **/

/** Interfaces, enum... **/

/** Variables **/

/** ------------------------- **/
const PreFarmerEnrollment: React.FC = () => {
  /** States **/
  const [listFarmerType, setListFarmerType] = useState<string>(
    LIST_FARMER_TYPE_CONSTANT.NEED_APPOINTMENT
  );
  const [selectedAppointment, setSelectedAppointment] = useState<
    IUserAppointment
  >();
  const [selectedFarmer, setSelectedFarmer] = useState<IFarmerProfile>();
  const [keyword, setKeyword] = useState<string>('');
  const [participant, setParticipant] = useState<number[]>([]);

  /** Hooks **/
  const { projectId } = useProjectContext();
  const [form] = Form.useForm();
  const [makeAppointmentForm] = Form.useForm();
  const {
    farmers: listFarmer,
    loadMore: loadMoreFarmer,
    loading: loadingFarmer,
    refresh: refreshFarmer,
    setFarmers,
  } = farmerHooks.useFarmers({
    status: LIST_FARMER_TYPE_CONSTANT.NEED_APPOINTMENT,
    keyword,
  });

  const {
    appointments: listAppointments,
    loadMore: loadMoreUserAppointment,
    loading: loadingUserAppointment,
    refresh: refreshUserAppointment,
    setAppointments,
  } = userAppointmentHooks.useUserAppointments(projectId, participant, keyword);

  /** Variables **/

  /** Functions, Events, Actions... **/
  const onSubmit = () => {
    const values = form.getFieldsValue();
    setParticipant(values.participantIds);
    setFarmers([]);
    setAppointments([]);
    setKeyword(values.keyword);
    setSelectedAppointment(undefined);
    setSelectedFarmer(undefined);
    refreshUserAppointment(values.keyword, values.participantIds);
    refreshFarmer(values.keyword, values.participantIds);
  };

  const onReset = () => {
    form.resetFields();
    setKeyword('');
    setParticipant([]);
    setSelectedAppointment(undefined);
    setSelectedFarmer(undefined);
    refreshUserAppointment();
    refreshFarmer();
  };

  /** Effects **/
  useEffect(() => {
    setFarmers([]);
    setAppointments([]);
    if (listFarmerType === LIST_FARMER_TYPE_CONSTANT.APPOINTMENT) {
      setSelectedFarmer(undefined);
      refreshUserAppointment(keyword, participant);
    } else if (listFarmerType === LIST_FARMER_TYPE_CONSTANT.NEED_APPOINTMENT) {
      setSelectedAppointment(undefined);
      refreshFarmer(keyword, participant);
    }
  }, [listFarmerType]);

  /** Elements **/
  return (
    <PreEnrollmentContext.Provider
      value={{
        refreshFarmer,
        refreshUserAppointment,
        keyword,
        participantIds: participant,
        listFarmerType,
        loadMoreFarmer,
        loadMoreUserAppointment,
        setListFarmerType,
      }}
    >
      <Row gutter={2}>
        <Col span={24}>
          <FarmerFilter
            form={form}
            onSubmit={onSubmit}
            onReset={onReset}
            projectId={projectId}
          />
        </Col>
        <Col sm={24} md={7} className="mt-half">
          <FarmerList
            farmers={listFarmer}
            userAppointments={listAppointments}
            makeAppointmentForm={makeAppointmentForm}
            loadingFarmer={loadingFarmer}
            loadingUserAppointment={loadingUserAppointment}
            setSelectedFarmer={setSelectedFarmer}
            setSelectedAppointment={setSelectedAppointment}
            selectedFarmer={selectedFarmer}
            selectedAppointment={selectedAppointment}
          />
        </Col>
        <Col
          sm={24}
          md={17}
          style={{
            height: 700,
            borderRadius: 4,
          }}
          className="mt-half"
        >
          <ContentBlock className="ml-half">
            <MakeAppointment
              selectedFarmer={selectedFarmer}
              selectedAppointment={selectedAppointment}
              listFarmerType={listFarmerType}
              makeAppointmentForm={makeAppointmentForm}
              setListFarmerType={setListFarmerType}
              projectId={projectId}
            />
          </ContentBlock>
        </Col>
      </Row>
    </PreEnrollmentContext.Provider>
  );
};

export { PreFarmerEnrollment };
export default PreFarmerEnrollment;
