import trackerUtils, { EventData } from './trackerUtils';

const { CLICK_DATA, getPayload } = trackerUtils;

interface ProjectMapTrackingData {
  choseFileToUpload: EventData;
  uploadPolygon: EventData;
  exportPolygon: EventData;
  deletePolygon: EventData;
  approvePolygon: EventData;
  rejectPolygon: EventData;
}

const PROJECT_MAP_TRACKING_DATA: ProjectMapTrackingData = {
  choseFileToUpload: {
    regionName: 'uploadNewPolygon',
    contentName: 'choseFileToUpload',
    index: 0,
  },
  uploadPolygon: {
    regionName: 'uploadNewPolygon',
    contentName: 'uploadPolygon',
    index: 1,
  },
  exportPolygon: {
    regionName: 'exportPolygon',
    contentName: 'exportPolygon',
    index: 2,
  },
  deletePolygon: {
    regionName: 'deletePolygon',
    contentName: 'deletePolygon',
    index: 3,
  },
  approvePolygon: {
    regionName: 'approvePolygon',
    contentName: 'approvePolygon',
    index: 4,
  },
  rejectPolygon: {
    regionName: 'rejectPolygon',
    contentName: 'rejectPolygon',
    index: 5,
  },
};

const trackProjectMapAction = (data: EventData) => {
  window.track('manualTrackInteractionContent', {
    ...CLICK_DATA,
    screenName: 'projectMap',
    regionName: data.regionName,
    contentName: data.contentName,
    target: 'projectMap',
    payload: getPayload('projectMap', data.index),
  });
};

export default {
  trackProjectMapAction,
  PROJECT_MAP_TRACKING_DATA,
};
