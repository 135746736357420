import { requestServices } from 'services';
import { IApiResponse, IGetOCROrTranscribeResponse } from 'interfaces';

const { projectManagerClient } = requestServices;

const getOCROrTranscribe = (
  analyzedDataIds: number[]
): Promise<IApiResponse<IGetOCROrTranscribeResponse>> => {
  return projectManagerClient.post('/api/v1/get-system-detected-results', {
    analyzedDataIds,
  });
};

const updateAnalyzed = (
  id: number,
  userUpdatedResult: string
): Promise<IApiResponse<any>> => {
  return projectManagerClient.post(`/api/v1/update-analyzed-data/${id}`, {
    userUpdatedResult,
  });
};

export default { updateAnalyzed, getOCROrTranscribe };
