/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { rest } from 'msw';
import { SERVER_ROUTES } from './apiRoute';
import queryString from 'query-string';
import {
  IDeletePolygonResponse,
  IGetPolygonTekboneParams,
  IPolygon,
  IPolygonDetails,
  IPolygonLimitOffsetResponse,
  IPolygonResponse,
  IPolygonTekbone,
  IPolygonType,
} from 'interfaces/polygon';
import { ISelectPolygonResponse } from 'interfaces';
import { AxiosRequestConfig } from 'axios';

const mockPolygons: IPolygon[] = new Array(100).fill(1).map((_, id) => {
  return {
    polygonId: id,
    area: 33169000,
    selectedSubmissions: Math.floor(Math.random() * 100),
    waitingSubmissions: Math.floor(Math.random() * 100),
    polygonMap: [
      {
        latitude: 13.406196,
        longitude: 107.674413,
      },
      {
        latitude: 13.416605,
        longitude: 107.686486,
      },
      {
        latitude: 13.409825,
        longitude: 107.677522,
      },
      {
        latitude: 13.417335,
        longitude: 107.682433,
      },
      {
        latitude: 13.409638,
        longitude: 107.676643,
      },
      {
        latitude: 13.417231,
        longitude: 107.679581,
      },
      {
        latitude: 13.406196,
        longitude: 107.674413,
      },
    ],
    isDeletable: id % 2 === 0,
    isSelected: id % 2 === 0,
    totalCarbonPerYear: 123.5,
    totalCarbonStandardDeviation: 2.34,
    plots: [],
    plotsCount: 1,
    samplingPlots: id % 2 === 0 ? 2 : undefined,
  };
});

const xml = `
<kml xmlns="http://www.opengis.net/kml/2.2">
  <Document>
    <name>congvienMeTri</name>
    <Placemark>
      <name>metri</name>
      <Polygon>
        <outerBoundaryIs>
          <LinearRing>
            <coordinates>
              105.7818568,21.0173695,0
              105.780977,21.018331,0
              105.7790673,21.0212553,0
              105.7744539,21.0223169,0
              105.7697761,21.0193525,0
              105.7709778,21.0165483,0
              105.773145,21.0133835,0
              105.7751835,21.0125822,0
              105.7773936,21.0121416,0
              105.7775653,21.0110399,0
              105.7766641,21.0095375,0
              105.7749474,21.0101785,0
              105.7740891,21.0117009,0
              105.7683385,21.0092971,0
              105.7671368,21.0068133,0
              105.7690895,21.005391,0
              105.7777799,21.0039087,0
              105.7876075,21.0040288,0
              105.7903755,21.0070937,0
              105.7876718,21.0105791,0
              105.7818568,21.0173695,0
            </coordinates>
          </LinearRing>
        </outerBoundaryIs>
      </Polygon>
    </Placemark>
  </Document>
</kml>`;

export const handlersPolygons = [
  rest.get(SERVER_ROUTES.POLYGON.GET_ALL_POLYGON, (req, res, ctx) => {
    let { page, pageSize } = queryString.parse(req.url.search) as any;

    const startIndex = (parseInt(page) - 1) * parseInt(pageSize);
    const endIndex = startIndex + parseInt(pageSize!);
    const mockPolygonCurrentPage = mockPolygons.slice(startIndex, endIndex);

    return res(
      ctx.json({
        code: 0,
        traceId: 'string',
        message: 'string',
        data: {
          polygons: mockPolygonCurrentPage,
          pagination: {
            page: page,
            pageSize: pageSize,
            total: mockPolygons.length,
          },
        },
      })
    );
  }),

  rest.post(SERVER_ROUTES.POLYGON.CREATE_POLYGON, (req, res, ctx) => {
    if (+req.params.projectId < 5)
      return res(ctx.status(400), ctx.json('400 Message'));
    else if (+req.params.projectId < 10)
      return res(ctx.status(500), ctx.json('500 Message'));
    else
      return res(
        ctx.json({ data: mockPolygons, code: 200, traceId: 'string' })
      );
  }),

  rest.get(SERVER_ROUTES.POLYGON.EXPORT_POLYGON, (req, res, ctx) => {
    if (+req.params.projectId < 2) return res(ctx.status(500));
    else {
      return res(ctx.xml(xml));
    }
  }),

  rest.delete(SERVER_ROUTES.POLYGON.DELETE_POLYGON, (req, res, ctx) => {
    if (+req.params.projectId < 2) return res(ctx.status(400));
    else if (+req.params.projectId < 5) return res(ctx.status(404));
    else if (+req.params.projectId < 10) return res(ctx.status(500));
    else return res(ctx.status(200));
  }),
];

const searchPolygons = (
  params: IGetPolygonTekboneParams,
  config?: AxiosRequestConfig
): Promise<IPolygonLimitOffsetResponse<IPolygonTekbone>['data']> =>
  Promise.resolve({
    pagination: {
      offset: 10,
      total: 1,
    },
    polygons: [
      {
        centerPoint: { latitude: 1.11, longitude: 1.23 },
        polygonId: 1,
        polygonMap: [],
        multiplePolygonMap: [
          {
            polygonMap: [
              { latitude: 6.652373, longitude: -0.797159 },
              { latitude: 6.647598, longitude: -0.796767 },
              { latitude: 6.645199, longitude: -0.795295 },
            ],
          },
          {
            polygonMap: [
              { latitude: 6.642373, longitude: -0.797159 },
              { latitude: 6.637598, longitude: -0.796767 },
              { latitude: 6.635199, longitude: -0.795295 },
            ],
          },
        ],
        polygonTypeId: 1001,
        zIndex: 1001,
        hexColorCode: '#000',
        polygonName: 'Intervention 1',
        createdAt: 0,
        area: 0,
        eligibleArea: 0,
      },
      {
        centerPoint: { latitude: 1.11, longitude: 1.23 },
        polygonId: 2,
        polygonMap: [
          { latitude: 6.752363, longitude: -0.797149 },
          { latitude: 6.747588, longitude: -0.796757 },
          { latitude: 6.745189, longitude: -0.795285 },
          { latitude: 6.752363, longitude: -0.797149 },
        ],
        polygonTypeId: 1001,
        zIndex: 1001,
        hexColorCode: '#000',
        polygonName: 'Intervention 2',
        createdAt: 0,
        area: 0,
        eligibleArea: 0,
      },
    ],
  });

const getPolygonTypes = (): Promise<{ polygonTypes: IPolygonType[] }> =>
  Promise.resolve({
    polygonTypes: [
      {
        hexColorCode: '#CDC49A',
        polygonTypeId: 1001,
        polygonTypeName: 'Type 1',
        zIndex: 1001,
        isProjectAreaType: true,
      },
    ],
  });

const deletePolygon = (): Promise<IDeletePolygonResponse> =>
  Promise.resolve({ code: 0, message: '', traceId: '' });

const selectPolygon = (): Promise<ISelectPolygonResponse> =>
  Promise.resolve({ code: 0, message: '', traceId: '' });

const getPolygonDetails = (
  polygonId: number,
  params: { projectId: number }
): Promise<IPolygonDetails> =>
  Promise.resolve({
    createdAt: 1709658527,
    polygonId: 1,
    polygonName: 'Project 1',
    area: 1689481.9049604686,
    samplingPlots: 0,
    plotsCount: 15,
    selectedSubmissions: 0,
    waitingSubmissions: 0,
    isDeletable: true,
    totalCarbonPerYear: 0,
    totalCarbonStandardDeviation: 0,
    submitterId: 'b80418188fe2444c83f1599e6bf7b88d',
    submitterName: 'Hạnh Bùi Mỹ',
    isSelected: false,
    rejectReason: '',
    isLandParcel: false,
    hexColorCode: '#F9F9DF',
    centerPoint: {
      latitude: 6.816047034883887,
      longitude: -0.721646635103629,
    },
    multiplePolygonMap: [
      {
        polygonMap: [
          { latitude: 6.652373, longitude: -0.797159 },
          { latitude: 6.647598, longitude: -0.796767 },
          { latitude: 6.645199, longitude: -0.795295 },
          { latitude: 6.652373, longitude: -0.797159 },
        ],
      },
      {
        polygonMap: [
          { latitude: 6.642373, longitude: -0.797159 },
          { latitude: 6.637598, longitude: -0.796767 },
          { latitude: 6.635199, longitude: -0.795295 },
          { latitude: 6.642373, longitude: -0.797159 },
        ],
      },
    ],
    overlapStatus: '',
    polygonTypeId: 1000,
    polygonTypeName: 'Project Boundary',
    zIndex: 1,
    eligibleArea: 100,
  });

export default {
  searchPolygons,
  getPolygonTypes,
  deletePolygon,
  selectPolygon,
  getPolygonDetails,
};
