export const currentUser = {
  sub: '7b8f2d6336024944a909996bf00a0885',
  name: 'Ngô Tứng',
  picture:
    'https://lh3.googleusercontent.com/a/AItbvmlpJjOXV9Ite1bIInWSL7rpeJLclkDAShyX6O23=s96-c',
  email: 'tuan.nva@teko.vn',
  phone_number: '0852161778',
  address: '',
  birthday: '',
  roles: ['catalog:admin'],
  permissions: ['catalog:all:all', 'ppm:all:all'],
  meta_data: {
    seller_id: '',
    sso_id: null,
    addr_code: '',
    department: 'NIO',
    role: 'staff',
    title: 'Developer',
    asia_id: null,
    asia_username: null,
    manager_id: null,
    manager_asia_id: null,
    date_created: '2022-12-28 15:00:09',
    date_modified: '2022-12-28 15:00:09',
  },
  updated_at: '2022-12-28 15:00:09',
  tenant_id: '1',
};

const isLoggedIn = () => true;
const login = () => {};
const logout = () => {};
const getAccessToken = () => '1';
const getUserInfo = () => currentUser;
const getFullUserInfo = () => Promise.resolve(currentUser);
const denyAccess = () => {};

export default {
  isLoggedIn,
  login,
  logout,
  getAccessToken,
  getUserInfo,
  getFullUserInfo,
  denyAccess,
};
