/** Third party libs **/
import { Button, Card, Empty, Form, notification, Typography } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  CloseOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  EditOutlined,
  SaveOutlined,
} from '@ant-design/icons';

/** Local libs **/
import { BaseItemProps } from 'components/FPIC/Detail/FormItem/BaseItem';
import { IFormAnswer, ISurveyForm } from 'interfaces';
import {
  Camera,
  Checkbox,
  Datagrid,
  DatePicker,
  DocFile,
  Dropdown,
  ImageFile,
  InputText,
  Location,
  MediaFile,
  Numberic,
  Password,
  Signature,
  CustomizableDropdown,
} from 'components/FPIC/Detail/FormItem';
import { t } from 'helpers/i18n';
import { fileHelpers, mapHelpers } from 'helpers';
import { CurrentSelectedFarm } from '../../Context';
import { FARM_EDITABLE_STATUSES, LOCATION_SURVEY_NAME } from 'constants/farm';

/** Styles **/
import './SurveySubmission.scss';
import { useFlagsupContext } from 'contexts';
import { useFarmDetailContext } from 'components/FarmerManagement/FarmerEnrolment';
import { Trans } from 'react-i18next';
import { userHooks } from 'hooks';

/** Interfaces, enum... **/
interface ISurveySubmissionProps {
  selectedSurveyForm?: ISurveyForm;
  onClose: () => void;
}

/** Variables **/
const { Title } = Typography;
const { applyGoogleImageSizing } = fileHelpers;
const { isValidLocation } = mapHelpers;

/** ------------------------- **/
/** SurveySubmission Component */
/** ------------------------- **/
const SurveySubmission: React.FC<ISurveySubmissionProps> = ({
  selectedSurveyForm,
  onClose,
}) => {
  /** States **/
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  /** Hooks **/
  const [form] = Form.useForm();
  const {
    setCurrentSurveyLocation,
    setShowFarmInfo,
    showFarmInfo,
    setShowSurveyReport,
    currentSurveyLocation,
  } = useContext(CurrentSelectedFarm);
  const { updateSurvey, refresh, farmDetails } = useFarmDetailContext();
  const { canEditFarmMappingSurvey } = userHooks.useUserPermissions();

  /** Variables **/
  const { featureFlagsData } = useFlagsupContext();
  const isCarsa919Enable = featureFlagsData.CARSA_919?.enabled;

  const canEditLocation =
    canEditFarmMappingSurvey &&
    farmDetails &&
    FARM_EDITABLE_STATUSES.includes(farmDetails?.farmStatus);

  /** Functions, Events, Actions... **/
  const getFormItem = (answer: IFormAnswer) => {
    const { fieldType } = answer;

    const props: BaseItemProps = {
      item: answer,
      form,
      mode: 'EDIT',
    };

    switch (fieldType) {
      case 'Dropdown':
      case 'DROPDOWN':
        return <Dropdown {...props} readOnly />;
      case 'Datagrid':
      case 'DATAGRID':
        return <Datagrid {...props} readOnly />;
      case 'DatePicker':
      case 'DATE_PICKER':
        return <DatePicker {...props} disabled />;
      case 'TimePicker':
      case 'TIME_PICKER':
        return <DatePicker {...props} timePicker={true} disabled />;
      case 'InputText':
      case 'INPUT_TEXT':
        return <InputText {...props} readOnly />;
      case 'Location':
      case 'LOCATION':
        return (
          <Location
            {...props}
            label={
              <>
                <div>
                  {props.item.fieldLabel}{' '}
                  {canEditLocation && (
                    <>
                      {!isEditing && (
                        <Button
                          icon={<EditOutlined />}
                          type="link"
                          size="small"
                          onClick={() => {
                            setIsEditing(true);
                          }}
                        />
                      )}
                      {isEditing && (
                        <>
                          <Button
                            icon={<CloseOutlined />}
                            type="link"
                            size="small"
                            disabled={loading}
                            onClick={onResetLocation}
                          />
                          <Button
                            icon={<SaveOutlined />}
                            loading={loading}
                            type="link"
                            size="small"
                            onClick={onUpdateLocation}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            }
          >
            {isEditing && (
              <div className="mb-half">
                <Typography.Text type="warning">
                  <Trans
                    i18nKey="DragPlacemarkToNewLocation"
                    formSurveyId={currentSurveyLocation?.formSurveyId}
                  >
                    Drag the Placemark
                    {{ formSurveyId: currentSurveyLocation?.formSurveyId }}
                    <b>on the background map</b> to the desired location
                  </Trans>{' '}
                  <DoubleRightOutlined />
                </Typography.Text>
              </div>
            )}
          </Location>
        );
      case 'TextArea':
      case 'TEXT_AREA':
        return <InputText {...props} textArea={true} readOnly />;
      case 'ImageFile':
      case 'IMAGE_FILE':
        return (
          <ImageFile
            transformUrl={url =>
              isCarsa919Enable
                ? applyGoogleImageSizing(url, { width: 100 })
                : url
            }
            {...props}
            disabled
          />
        );
      case 'MediaFile':
        return <MediaFile {...props} disabled />;
      case 'Checkbox':
      case 'CHECKBOX':
        return <Checkbox {...props} readOnly />;
      case 'DocFile':
        return <DocFile {...props} disabled />;
      case 'Password':
        return <Password {...props} disabled />;
      case 'Numberic':
        return <Numberic {...props} min={1} disabled />;
      case 'Currency':
        return <Numberic {...props} currency={true} disabled />;
      case 'Camera':
      case 'CAMERA':
        return (
          <Camera
            transformUrl={url =>
              isCarsa919Enable
                ? applyGoogleImageSizing(url, { width: 100 })
                : url
            }
            {...props}
            disabled
          />
        );
      case 'Signature':
        return <Signature {...props} disabled />;
      case 'CustomizableDropdown':
      case 'CUSTOMIZABLE_DROPDOWN':
        return <CustomizableDropdown {...props} readOnly />;
      case 'CustomizableCheckbox':
      case 'CUSTOMIZABLE_CHECKBOX':
        return <Checkbox {...props} customizable readOnly />;
      default:
        return <></>;
    }
  };

  const toggleFarmInfo = () => {
    setShowFarmInfo(prev => !prev);
    setShowSurveyReport(false);
  };

  const onResetLocation = () => {
    const locationAnswer = selectedSurveyForm?.answers.find(
      anwser => anwser.fieldName === LOCATION_SURVEY_NAME
    );
    const location = JSON.parse(locationAnswer?.value || '{}');

    if (isValidLocation(location))
      setCurrentSurveyLocation(prev => ({
        ...prev,
        latitude: location.latitude,
        longitude: location.longitude,
        draggable: false,
      }));
    setIsEditing(false);
  };

  const onUpdateLocation = async () => {
    if (!selectedSurveyForm) return;
    const { formSubmissionId, answers } = selectedSurveyForm || {};

    try {
      setLoading(true);
      await updateSurvey(
        formSubmissionId,
        answers?.map(answer => {
          if (answer.fieldName === LOCATION_SURVEY_NAME) {
            return {
              ...answer,
              value: JSON.stringify({
                latitude: currentSurveyLocation?.latitude,
                longitude: currentSurveyLocation?.longitude,
              }),
            };
          }
          return answer;
        })
      );
      setIsEditing(false);
      notification.success({ message: t('UpdateSuccessfully') });
      // Refresh farm data
      refresh();
    } finally {
      setLoading(false);
    }
  };

  const selectedSurveyFormOrdered = useMemo(() => {
    if (selectedSurveyForm) {
      return selectedSurveyForm.answers
        .slice()
        .sort((a, b) => a.ordinal - b.ordinal);
    }
    return [];
  }, [selectedSurveyForm]);

  /** Effects **/
  useEffect(() => {
    let farmSurveyLocation:
      | {
          latitude: number;
          longitude: number;
          formSurveyId?: number;
        }
      | undefined;
    const locationFarmSurvey = selectedSurveyForm?.answers?.find(
      answer => answer.fieldName === LOCATION_SURVEY_NAME
    );
    if (locationFarmSurvey?.value) {
      const location = JSON.parse(locationFarmSurvey.value);
      farmSurveyLocation =
        location.latitude && location.longitude
          ? {
              latitude: location.latitude,
              longitude: location.longitude,
              formSurveyId: selectedSurveyForm?.formSubmissionId,
            }
          : undefined;
    }
    setCurrentSurveyLocation(farmSurveyLocation);
  }, [selectedSurveyForm]);

  useEffect(() => {
    setIsEditing(false);
  }, [currentSurveyLocation?.formSurveyId]);

  useEffect(() => {
    setCurrentSurveyLocation(prev =>
      prev ? { ...prev, draggable: isEditing } : undefined
    );
  }, [isEditing]);

  /** Elements **/
  return (
    <Card
      className="survey-submission-wrapper"
      extra={
        <div className="position-relative">
          <div>
            <Title level={5} className="d-block w-100 text-center mb-0">
              {selectedSurveyForm?.formName ? (
                selectedSurveyForm?.formName
              ) : (
                <>
                  {t('SurveySubmission')} {selectedSurveyForm?.formSubmissionId}
                </>
              )}
            </Title>
          </div>
          <div
            className="position-absolute d-flex justify-content-between w-100 h-100"
            style={{ top: 0, right: 0 }}
          >
            <DoubleLeftOutlined
              className="extraButton"
              onClick={toggleFarmInfo}
              rotate={showFarmInfo ? 0 : -180}
            />
            <CloseOutlined
              className="extraButton"
              onClick={() => {
                onClose();
                setShowFarmInfo(true);
              }}
            />
          </div>
        </div>
      }
    >
      <Form
        form={form}
        initialValues={{}}
        layout={'vertical'}
        className="form-submission-wrapper"
      >
        {selectedSurveyFormOrdered.length ? (
          selectedSurveyFormOrdered.map((answer, index) => (
            <Card key={index} className="mt-half">
              {getFormItem(answer)}
            </Card>
          ))
        ) : (
          <Empty />
        )}
      </Form>
    </Card>
  );
};

export { SurveySubmission };
export default SurveySubmission;
