/** Third party libs **/
import { Button, Form, notification } from 'antd';
import React, { useContext, useState } from 'react';

/** Local libs **/
import { FARM_STATUS } from 'constants/farm';
import { farmHooks, userHooks } from 'hooks';
import { t } from 'helpers/i18n';
import { CurrentSelectedFarm } from 'components/FarmerManagement/FarmerEnrolment';
import { IFarmLand } from 'interfaces';
import { DrawState } from 'constants/map';

/** Styles **/

/** Interfaces, enum... **/
interface ISubmitFarmButtonProps {
  isApproved: boolean;
  farmLandId?: number;
  getFarmDetails: (farmId: number) => Promise<void>;
  setListFarmType: (type: string) => void;
  setShowDiscussionHistory: React.Dispatch<React.SetStateAction<boolean>>;
  farmDetails?: IFarmLand;
  refreshFarmList: (farmStatus: string, keyword?: string) => void;
}

/** Variables **/
const { useUserPermissions } = userHooks;

/** ------------------------- **/
/** SubmitFarmButton Component */
/** ------------------------- **/
const SubmitFarmButton: React.FC<ISubmitFarmButtonProps> = ({
  isApproved,
  farmLandId,
  getFarmDetails,
  setListFarmType,
  setShowDiscussionHistory,
  refreshFarmList,
}) => {
  /** States **/
  const [farmMappingStatus, setFarmMappingStatus] = useState<string>('');

  /** Hooks **/
  const { updateFarmLandMappingStatus, loading } = farmHooks.useFarmDetail();
  const { setTriggerReloadComment, setDrawState } = useContext(
    CurrentSelectedFarm
  );
  const { canApproveFarmerEnrollment } = useUserPermissions();
  const [updateStatusForm] = Form.useForm();

  /** Variables **/
  const disabled = !canApproveFarmerEnrollment || loading;

  /** Functions, Events, Actions... **/
  const onSubmit = async () => {
    if (farmMappingStatus && farmLandId) {
      const result = await updateFarmLandMappingStatus(
        farmLandId,
        farmMappingStatus
      );

      if (result.status === 200) {
        setListFarmType(farmMappingStatus);
        setShowDiscussionHistory(false);
        refreshFarmList(farmMappingStatus);
        notification.success({
          message: t('Success'),
          description: t('UpdateSuccessfully'),
        });
      }
      getFarmDetails(farmLandId);
      setTriggerReloadComment(prev => !prev);
      setDrawState?.(DrawState.NONE);
    }
  };

  /** Effects **/

  /** Elements **/
  return (
    <Form form={updateStatusForm} onFinish={onSubmit}>
      <div className="d-flex justify-content-space-around w-100">
        {!isApproved && (
          <div
            className="d-flex justify-content-between w-100"
            style={{ flexWrap: 'wrap' }}
          >
            <Button
              disabled={disabled}
              htmlType="submit"
              className="needToUpdateButton"
              type="link"
              onClick={() => setFarmMappingStatus(FARM_STATUS.NEED_TO_UPDATE)}
            >
              {t('NeedToUpdate')}
            </Button>
            <Button
              disabled={disabled}
              htmlType="submit"
              onClick={() => setFarmMappingStatus(FARM_STATUS.INELIGIBLE)}
            >
              {t('Ineligible')}
            </Button>
            <Button
              disabled={disabled}
              htmlType="submit"
              className="approveButton"
              onClick={() => setFarmMappingStatus(FARM_STATUS.APPROVED)}
              type="primary"
            >
              {t('Approve')}
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

export { SubmitFarmButton };
export default SubmitFarmButton;
