import { InputNumber, Table } from 'antd';
import { t, tSelectPlaceholder } from 'helpers/i18n';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';
import { convertNumberFormat } from 'helpers/common';
import { IncPlantingAreaByYear } from 'interfaces';

interface IncrementalPlantingAreaTableProps {
  totalEligibleArea: number;
  projectDate?: [dayjs.Dayjs, dayjs.Dayjs];
  value?: IncPlantingAreaByYear[];
  onChange?: (data: IncPlantingAreaByYear[]) => void;
}

const IncrementalPlantingAreaTable: React.FC<IncrementalPlantingAreaTableProps> = ({
  value,
  totalEligibleArea,
  projectDate,
  onChange,
}) => {
  const [
    incrementalPlantingAreaByYear,
    setIncrementPlantingAreaByYear,
  ] = useState<IncPlantingAreaByYear[]>(value || []);

  useEffect(() => {
    if (!projectDate) {
      setIncrementPlantingAreaByYear([]);
    } else {
      const startYear = projectDate[0].year();
      const endYear = projectDate[1].year();
      const years = generateYearsArray(startYear, endYear);

      // Create a map of existing values for quick lookup
      const existingValuesMap = new Map(
        incrementalPlantingAreaByYear.map(item => [item.year, item])
      );

      // Merge existing values with new years
      const mergedValues = years.map(year => {
        const existingValue = existingValuesMap.get(year);
        return (
          existingValue || {
            year,
            incPlantingAreaPercentage: 0,
            incPlantingAreaHa: 0,
          }
        );
      });

      setIncrementPlantingAreaByYear(mergedValues);
    }
  }, [projectDate]);

  const columns: ColumnsType<IncPlantingAreaByYear> = [
    {
      title: t('Year'),
      dataIndex: 'year',
    },
    {
      title: <span>{t('IncrementalPlantingArea')} %</span>,
      dataIndex: 'incrementalPercent',
      align: 'right',
      render: (incrementalPercent, rowData) => (
        <InputNumber
          min={0}
          max={100}
          suffix={'%'}
          placeholder="0"
          value={incrementalPercent}
          onChange={incPlantingAreaPercentage =>
            onChangeIncrementalPlantingArea({
              incPlantingAreaPercentage,
              year: rowData.year,
              incPlantingAreaHa:
                ((incPlantingAreaPercentage || 0) * totalEligibleArea) / 100,
            })
          }
        />
      ),
    },
    {
      title: <span>{t('IncrementalPlantingArea')} (ha)</span>,
      align: 'right',
      dataIndex: 'incPlantingAreaHa',
      render: value => convertNumberFormat(value),
    },
  ];

  const generateYearsArray = (startYear: number, endYear: number) => {
    const years: number[] = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  const onChangeIncrementalPlantingArea = (
    changedValue: IncPlantingAreaByYear
  ) => {
    const { year } = changedValue;

    setIncrementPlantingAreaByYear(prev => {
      const newData = prev.map(data => {
        if (data.year === year) {
          return {
            ...data,
            ...changedValue,
          };
        }
        return data;
      });
      return newData;
    });
  };

  useEffect(() => {
    // Calculate incremental planting area
    setIncrementPlantingAreaByYear(
      incrementalPlantingAreaByYear.map(data => ({
        ...data,
        incPlantingAreaHa:
          (data.incPlantingAreaPercentage * totalEligibleArea) / 100,
      }))
    );
  }, [totalEligibleArea]);

  useEffect(() => {
    onChange?.(incrementalPlantingAreaByYear);
  }, [incrementalPlantingAreaByYear]);

  return (
    <Table
      size="small"
      rowKey={record => record.year}
      columns={columns}
      dataSource={incrementalPlantingAreaByYear}
      pagination={false}
      locale={{ emptyText: tSelectPlaceholder('ProjectDateRange') }}
    />
  );
};

export default IncrementalPlantingAreaTable;
