const countryList = [
  { name: 'Afghanistan', independent: false },
  { name: 'Åland Islands', independent: false },
  { name: 'Albania', independent: false },
  { name: 'Algeria', independent: false },
  { name: 'American Samoa', independent: false },
  { name: 'Andorra', independent: false },
  { name: 'Angola', independent: false },
  { name: 'Anguilla', independent: false },
  { name: 'Antarctica', independent: false },
  { name: 'Antigua and Barbuda', independent: false },
  { name: 'Argentina', independent: false },
  { name: 'Armenia', independent: false },
  { name: 'Aruba', independent: false },
  { name: 'Australia', independent: false },
  { name: 'Austria', independent: false },
  { name: 'Azerbaijan', independent: false },
  { name: 'Bahamas', independent: false },
  { name: 'Bahrain', independent: false },
  { name: 'Bangladesh', independent: false },
  { name: 'Barbados', independent: false },
  { name: 'Belarus', independent: false },
  { name: 'Belgium', independent: false },
  { name: 'Belize', independent: false },
  { name: 'Benin', independent: false },
  { name: 'Bermuda', independent: false },
  { name: 'Bhutan', independent: false },
  { name: 'Bolivia (Plurinational State of)', independent: false },
  { name: 'Bonaire, Sint Eustatius and Saba', independent: false },
  { name: 'Bosnia and Herzegovina', independent: false },
  { name: 'Botswana', independent: false },
  { name: 'Bouvet Island', independent: false },
  { name: 'Brazil', independent: false },
  { name: 'British Indian Ocean Territory', independent: false },
  { name: 'United States Minor Outlying Islands', independent: false },
  { name: 'Virgin Islands (British)', independent: false },
  { name: 'Virgin Islands (U.S.)', independent: false },
  { name: 'Brunei Darussalam', independent: false },
  { name: 'Bulgaria', independent: false },
  { name: 'Burkina Faso', independent: false },
  { name: 'Burundi', independent: false },
  { name: 'Cambodia', independent: false },
  { name: 'Cameroon', independent: false },
  { name: 'Canada', independent: false },
  { name: 'Cabo Verde', independent: false },
  { name: 'Cayman Islands', independent: false },
  { name: 'Central African Republic', independent: false },
  { name: 'Chad', independent: false },
  { name: 'Chile', independent: false },
  { name: 'China', independent: false },
  { name: 'Christmas Island', independent: false },
  { name: 'Cocos (Keeling) Islands', independent: false },
  { name: 'Colombia', independent: false },
  { name: 'Comoros', independent: false },
  { name: 'Congo', independent: false },
  { name: 'Congo (Democratic Republic of the)', independent: false },
  { name: 'Cook Islands', independent: false },
  { name: 'Costa Rica', independent: false },
  { name: 'Croatia', independent: false },
  { name: 'Cuba', independent: false },
  { name: 'Curaçao', independent: false },
  { name: 'Cyprus', independent: false },
  { name: 'Czech Republic', independent: false },
  { name: 'Denmark', independent: false },
  { name: 'Djibouti', independent: false },
  { name: 'Dominica', independent: false },
  { name: 'Dominican Republic', independent: false },
  { name: 'Ecuador', independent: false },
  { name: 'Egypt', independent: false },
  { name: 'El Salvador', independent: false },
  { name: 'Equatorial Guinea', independent: false },
  { name: 'Eritrea', independent: false },
  { name: 'Estonia', independent: false },
  { name: 'Ethiopia', independent: false },
  { name: 'Falkland Islands (Malvinas)', independent: false },
  { name: 'Faroe Islands', independent: false },
  { name: 'Fiji', independent: false },
  { name: 'Finland', independent: false },
  { name: 'France', independent: false },
  { name: 'French Guiana', independent: false },
  { name: 'French Polynesia', independent: false },
  { name: 'French Southern Territories', independent: false },
  { name: 'Gabon', independent: false },
  { name: 'Gambia', independent: false },
  { name: 'Georgia', independent: false },
  { name: 'Germany', independent: false },
  { name: 'Ghana', independent: false },
  { name: 'Gibraltar', independent: false },
  { name: 'Greece', independent: false },
  { name: 'Greenland', independent: false },
  { name: 'Grenada', independent: false },
  { name: 'Guadeloupe', independent: false },
  { name: 'Guam', independent: false },
  { name: 'Guatemala', independent: false },
  { name: 'Guernsey', independent: false },
  { name: 'Guinea', independent: false },
  { name: 'Guinea-Bissau', independent: false },
  { name: 'Guyana', independent: false },
  { name: 'Haiti', independent: false },
  { name: 'Heard Island and McDonald Islands', independent: false },
  { name: 'Vatican City', independent: false },
  { name: 'Honduras', independent: false },
  { name: 'Hungary', independent: false },
  { name: 'Hong Kong', independent: false },
  { name: 'Iceland', independent: false },
  { name: 'India', independent: false },
  { name: 'Indonesia', independent: false },
  { name: 'Ivory Coast', independent: false },
  { name: 'Iran (Islamic Republic of)', independent: false },
  { name: 'Iraq', independent: false },
  { name: 'Ireland', independent: false },
  { name: 'Isle of Man', independent: false },
  { name: 'Israel', independent: false },
  { name: 'Italy', independent: false },
  { name: 'Jamaica', independent: false },
  { name: 'Japan', independent: false },
  { name: 'Jersey', independent: false },
  { name: 'Jordan', independent: false },
  { name: 'Kazakhstan', independent: false },
  { name: 'Kenya', independent: false },
  { name: 'Kiribati', independent: false },
  { name: 'Kuwait', independent: false },
  { name: 'Kyrgyzstan', independent: false },
  { name: "Lao People's Democratic Republic", independent: false },
  { name: 'Latvia', independent: false },
  { name: 'Lebanon', independent: false },
  { name: 'Lesotho', independent: false },
  { name: 'Liberia', independent: false },
  { name: 'Libya', independent: false },
  { name: 'Liechtenstein', independent: false },
  { name: 'Lithuania', independent: false },
  { name: 'Luxembourg', independent: false },
  { name: 'Macao', independent: false },
  { name: 'North Macedonia', independent: false },
  { name: 'Madagascar', independent: false },
  { name: 'Malawi', independent: false },
  { name: 'Malaysia', independent: false },
  { name: 'Maldives', independent: false },
  { name: 'Mali', independent: false },
  { name: 'Malta', independent: false },
  { name: 'Marshall Islands', independent: false },
  { name: 'Martinique', independent: false },
  { name: 'Mauritania', independent: false },
  { name: 'Mauritius', independent: false },
  { name: 'Mayotte', independent: false },
  { name: 'Mexico', independent: false },
  { name: 'Micronesia (Federated States of)', independent: false },
  { name: 'Moldova (Republic of)', independent: false },
  { name: 'Monaco', independent: false },
  { name: 'Mongolia', independent: false },
  { name: 'Montenegro', independent: false },
  { name: 'Montserrat', independent: false },
  { name: 'Morocco', independent: false },
  { name: 'Mozambique', independent: false },
  { name: 'Myanmar', independent: false },
  { name: 'Namibia', independent: false },
  { name: 'Nauru', independent: false },
  { name: 'Nepal', independent: false },
  { name: 'Netherlands', independent: false },
  { name: 'New Caledonia', independent: false },
  { name: 'New Zealand', independent: false },
  { name: 'Nicaragua', independent: false },
  { name: 'Niger', independent: false },
  { name: 'Nigeria', independent: false },
  { name: 'Niue', independent: false },
  { name: 'Norfolk Island', independent: false },
  { name: "Korea (Democratic People's Republic of)", independent: false },
  { name: 'Northern Mariana Islands', independent: false },
  { name: 'Norway', independent: false },
  { name: 'Oman', independent: false },
  { name: 'Pakistan', independent: false },
  { name: 'Palau', independent: false },
  { name: 'Palestine, State of', independent: false },
  { name: 'Panama', independent: false },
  { name: 'Papua New Guinea', independent: false },
  { name: 'Paraguay', independent: false },
  { name: 'Peru', independent: false },
  { name: 'Philippines', independent: false },
  { name: 'Pitcairn', independent: false },
  { name: 'Poland', independent: false },
  { name: 'Portugal', independent: false },
  { name: 'Puerto Rico', independent: false },
  { name: 'Qatar', independent: false },
  { name: 'Republic of Kosovo', independent: false },
  { name: 'Réunion', independent: false },
  { name: 'Romania', independent: false },
  { name: 'Russian Federation', independent: false },
  { name: 'Rwanda', independent: false },
  { name: 'Saint Barthélemy', independent: false },
  { name: 'Saint Helena, Ascension and Tristan da Cunha', independent: false },
  { name: 'Saint Kitts and Nevis', independent: false },
  { name: 'Saint Lucia', independent: false },
  { name: 'Saint Martin (French part)', independent: false },
  { name: 'Saint Pierre and Miquelon', independent: false },
  { name: 'Saint Vincent and the Grenadines', independent: false },
  { name: 'Samoa', independent: false },
  { name: 'San Marino', independent: false },
  { name: 'Sao Tome and Principe', independent: false },
  { name: 'Saudi Arabia', independent: false },
  { name: 'Senegal', independent: false },
  { name: 'Serbia', independent: false },
  { name: 'Seychelles', independent: false },
  { name: 'Sierra Leone', independent: false },
  { name: 'Singapore', independent: false },
  { name: 'Sint Maarten (Dutch part)', independent: false },
  { name: 'Slovakia', independent: false },
  { name: 'Slovenia', independent: false },
  { name: 'Solomon Islands', independent: false },
  { name: 'Somalia', independent: false },
  { name: 'South Africa', independent: false },
  { name: 'South Georgia and the South Sandwich Islands', independent: false },
  { name: 'Korea (Republic of)', independent: false },
  { name: 'Spain', independent: false },
  { name: 'Sri Lanka', independent: false },
  { name: 'Sudan', independent: false },
  { name: 'South Sudan', independent: false },
  { name: 'Suriname', independent: false },
  { name: 'Svalbard and Jan Mayen', independent: false },
  { name: 'Swaziland', independent: false },
  { name: 'Sweden', independent: false },
  { name: 'Switzerland', independent: false },
  { name: 'Syrian Arab Republic', independent: false },
  { name: 'Taiwan', independent: false },
  { name: 'Tajikistan', independent: false },
  { name: 'Tanzania, United Republic of', independent: false },
  { name: 'Thailand', independent: false },
  { name: 'Timor-Leste', independent: false },
  { name: 'Togo', independent: false },
  { name: 'Tokelau', independent: false },
  { name: 'Tonga', independent: false },
  { name: 'Trinidad and Tobago', independent: false },
  { name: 'Tunisia', independent: false },
  { name: 'Turkey', independent: false },
  { name: 'Turkmenistan', independent: false },
  { name: 'Turks and Caicos Islands', independent: false },
  { name: 'Tuvalu', independent: false },
  { name: 'Uganda', independent: false },
  { name: 'Ukraine', independent: false },
  { name: 'United Arab Emirates', independent: false },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    independent: false,
  },
  { name: 'United States of America', independent: false },
  { name: 'Uruguay', independent: false },
  { name: 'Uzbekistan', independent: false },
  { name: 'Vanuatu', independent: false },
  { name: 'Venezuela (Bolivarian Republic of)', independent: false },
  { name: 'Vietnam', independent: false },
  { name: 'Wallis and Futuna', independent: false },
  { name: 'Western Sahara', independent: false },
  { name: 'Yemen', independent: false },
  { name: 'Zambia', independent: false },
  { name: 'Zimbabwe', independent: false },
];

export const COUNTRY = countryList.map(country => ({
  label: country.name,
  value: country.name,
}));

export default {
  COUNTRY,
};
