// TODO: Add unitt tests for tracking service
import trackerUtils, { EventData } from './trackerUtils';

const { CLICK_DATA, getPayload } = trackerUtils;

interface ProjectMapTrackingData {
  create: EventData;
  delete: EventData;
  actionViewDetail: EventData;
  viewDetailProject: EventData;
  viewDetailPolygon: EventData;
}

const PROJECT_MANAGER_TRACKING_DATA: ProjectMapTrackingData = {
  create: {
    regionName: 'createProject',
    contentName: 'createProject',
    index: 0,
  },
  delete: {
    regionName: 'actionDeleteProject',
    index: 1,
  },
  actionViewDetail: {
    regionName: 'actionViewDetailProject',
    index: 2,
  },
  viewDetailProject: {
    regionName: 'viewDetailProject',
    index: 3,
  },
  viewDetailPolygon: {
    regionName: 'viewDetailPolygon',
    index: 4,
  },
};

const trackProjectManagerAction = (data: EventData, contentName?: string) => {
  window.track('manualTrackInteractionContent', {
    ...CLICK_DATA,
    screenName: 'projectManager',
    regionName: data.regionName,
    contentName: contentName,
    target: 'projectManager',
    payload: getPayload('projectManager', data.index),
  });
};

export default {
  trackProjectManagerAction,
  PROJECT_MANAGER_TRACKING_DATA,
};
