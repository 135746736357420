import { Button, Flex, Result, Space } from 'antd';
import FormationPage from 'components/CREMA/Formation';
import {
  LiveSelectTypeOffset,
  IBaseFilter,
} from 'components/shared/Select/LiveSelectTypeOffset';
import AppContainer from 'containers/AppLayout/AppContainer';
import { useProjectContext } from 'contexts';
import { MapProvider } from 'contexts/map';
import { cremaHooks } from 'hooks';
import React from 'react';
import Icon, { SearchOutlined } from '@ant-design/icons';
import { ReactComponent as Marker } from 'assets/svgs/EnvironmentOutlined.svg';
import Navbar from 'components/shared/Navbar';
import {
  CremaFormationProvider,
  useCremaFormationContext,
} from 'contexts/cremaFormation';
import { ID_DEFAULT } from 'constants/common';
import { paginationHelpers } from 'helpers';
import { CremaDrawer } from 'interfaces';
import { useTranslation } from 'react-i18next';

const Community: React.FC = () => {
  const { t } = useTranslation(window.appConfig?.appName);
  const { projectId } = useProjectContext();
  const { loading, onSearch } = cremaHooks.useSearchOrganizations();
  const {
    setCommunityInfo,
    toggleDrawer,
    communityRefetch,
  } = useCremaFormationContext();

  if (projectId === ID_DEFAULT)
    return <Result status="404" title={t('ThereAreNoProjects')} />;

  const optionFetcher = async ({ keyword, offset, limit }: IBaseFilter) => {
    const { organizations, pagination } = await onSearch({
      keyword,
      limit,
      offset,
      organizationType: 'COMMUNITY',
    });
    let filterData = { ...pagination, keyword, limit };
    const shouldLoadMore = paginationHelpers.shouldLoadMore(
      organizations.length,
      limit || 0
    );
    if (!shouldLoadMore) filterData.offset = -1;
    return {
      options: organizations.map(organization => ({
        label: organization.organizationName,
        value: organization.organizationId,
        key: organization.organizationId,
        ...organization,
      })),
      ...filterData,
    };
  };

  const openCommunityDetail = (communityId: number) => {
    // open community detail drawer
    setCommunityInfo?.({ id: communityId });
    toggleDrawer(CremaDrawer.CommunityDetail);
    communityRefetch();
  };

  return (
    <>
      <Navbar>
        <Flex justify="space-between">
          <LiveSelectTypeOffset
            allowClear
            suffixIcon={<SearchOutlined />}
            allowSearchEmpty={false}
            optionKey="organizationId"
            loading={loading}
            optionFetcher={optionFetcher}
            onSelect={(_, { data }) => {
              const { organizationId } = data;
              openCommunityDetail?.(organizationId);
            }}
            optionRender={({ label }) => {
              return (
                <Space>
                  <Icon component={Marker} />
                  {label}
                </Space>
              );
            }}
            style={{ width: 300 }}
            placeholder={t('TypeCommunityNameToSearch')}
          />
          <Space>
            <Button
              type="default"
              onClick={() => toggleDrawer(CremaDrawer.CreateCommunity)}
            >
              {t('CreateCommunity')}
            </Button>
          </Space>
        </Flex>
      </Navbar>
      <FormationPage preventOpenCrema hideMeetingRecords />
    </>
  );
};

const CommunityWrapper: React.FC = () => {
  const { projectId } = useProjectContext();
  const { t } = useTranslation(window.appConfig?.appName);

  if (projectId === ID_DEFAULT)
    return <Result status="404" title={t('ThereAreNoProjects')} />;

  return (
    <AppContainer className="d-flex flex-column">
      <CremaFormationProvider>
        <MapProvider>
          <Community />
        </MapProvider>
      </CremaFormationProvider>
    </AppContainer>
  );
};

export default CommunityWrapper;
