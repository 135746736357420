import {
  BarChartOutlined,
  BookOutlined,
  LineChartOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { resourceConstants } from 'constants/index';
import Communities from 'containers/Communities';
import MeetingRecords from 'containers/MeetingRecords';
import FinancialPlan from 'containers/Plan/FinancialPlan';
import Funds from 'containers/Plan/Funds';
import ImportFunds from 'containers/Plan/ImportFunds';
import UserManagement from 'containers/UserManagement';
import ImportUser from 'containers/UserManagement/ImportUser';
import WbInternalReport from 'containers/Report/WbInternalReport';
import { permission } from 'helpers/common';
import { t } from 'helpers/i18n';

const { APPS, RESOURCES, ACTIONS } = resourceConstants;
const { PROJECT_MANAGER_TEKBONE } = APPS;
const {
  USER,
  FUND,
  ACTIVITY,
  MEETING_RECORDS,
  COMMUNITIES,
  REPORT,
} = RESOURCES;
const { READ, READ_LIST, IMPORT } = ACTIONS;

// Route configs for World Bank
export const wbRoutes = [
  {
    exact: true,
    path: '/user-management',
    name: t('UserManagement'),
    icon: UserOutlined,
    component: UserManagement,
    permissions: [permission(PROJECT_MANAGER_TEKBONE, USER, READ_LIST)],
  },
  {
    exact: true,
    path: '/user-management/import',
    name: t('ImportUser'),
    component: ImportUser,
    permissions: [permission(PROJECT_MANAGER_TEKBONE, USER, IMPORT)],
  },
  {
    exact: true,
    path: '/plan',
    icon: BarChartOutlined,
    name: t('Plan'),
    permissions: [
      permission(PROJECT_MANAGER_TEKBONE, FUND, READ),
      permission(PROJECT_MANAGER_TEKBONE, ACTIVITY, READ),
    ],
  },
  {
    exact: true,
    path: '/funds',
    name: t('Funds'),
    component: Funds,
    permissions: [permission(PROJECT_MANAGER_TEKBONE, FUND, READ)],
  },
  {
    exact: true,
    path: '/funds/import',
    name: t('ImportFunds'),
    component: ImportFunds,
    permissions: [permission(PROJECT_MANAGER_TEKBONE, FUND, IMPORT)],
  },
  {
    exact: true,
    path: '/financial-plan',
    name: t('FinancialPlan'),
    component: FinancialPlan,
    permissions: [permission(PROJECT_MANAGER_TEKBONE, ACTIVITY, READ)],
  },
  {
    exact: true,
    path: '/community/meeting-records',
    icon: BookOutlined,
    name: t('MeetingRecords'),
    component: MeetingRecords,
    permissions: [permission(PROJECT_MANAGER_TEKBONE, MEETING_RECORDS, READ)],
  },
  {
    exact: true,
    path: '/communities',
    name: t('Communities'),
    icon: LineChartOutlined,
    component: Communities,
    permissions: [permission(PROJECT_MANAGER_TEKBONE, COMMUNITIES, READ)],
  },
  {
    exact: true,
    path: '/report',
    name: t('Report'),
    icon: BarChartOutlined,
    component: WbInternalReport,
    permissions: [permission(PROJECT_MANAGER_TEKBONE, REPORT, READ)],
  },
];
