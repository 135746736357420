import { Col, Empty, Row, Skeleton } from 'antd';
import { IFPicFormSubmissionsGetParams, IFormSubmission } from 'interfaces';
import React, { useEffect, useMemo, useState } from 'react';
import ListContent from './ListContent';
import { ID_DEFAULT } from 'constants/common';
import DetailContentV2 from './DetailContentV2';
import { CommentV2 } from './CommentV2';
import { userHooks } from 'hooks';
import { DEFAULT_PAGE } from 'constants/pagination';

interface ListContentV2Props {
  loading?: boolean;
  total?: number;
  projectId: number;
  allowCreateFpic?: boolean;
  filter?: IFPicFormSubmissionsGetParams;
  items: IFormSubmission[];
  loadMoreData?: () => Promise<boolean>;
  onLocalUpdate?: (item: IFormSubmission) => void;
  updateFilter: (params: Partial<IFPicFormSubmissionsGetParams>) => void;
}
const { useUserPermissions } = userHooks;

const ListContentV2: React.FC<ListContentV2Props> = ({
  items,
  filter,
  loading,
  updateFilter,
  ...props
}) => {
  const [currentSubmissionId, setCurrentSubmissionId] = useState<number>(
    ID_DEFAULT
  );
  const currentFormSubmission = useMemo(
    () => items.find(item => item.formSubmissionId === currentSubmissionId),
    [items, currentSubmissionId]
  ) as IFormSubmission;

  const {
    canUpdateMeetingRecords,
    canApproveMeetingRecords,
  } = useUserPermissions();
  const allowFullActions = canApproveMeetingRecords;
  const allowEditAction = canUpdateMeetingRecords || allowFullActions;
  const allowCommonAction = allowEditAction || allowFullActions;

  useEffect(() => {
    if (filter?.page === DEFAULT_PAGE) {
      setCurrentSubmissionId(
        items.length ? items[0].formSubmissionId : ID_DEFAULT
      );
    }
  }, [items]);

  return (
    <Row gutter={[8, 8]} className="h-100">
      <Col span={7}>
        <ListContent
          {...props}
          loading={loading}
          filter={filter}
          items={items}
          currentSubmissionId={currentSubmissionId}
          setCurrentSubmissionId={setCurrentSubmissionId}
        />
      </Col>
      {loading && filter?.page === DEFAULT_PAGE ? (
        <Col span={17}>
          <Skeleton active={true} />
        </Col>
      ) : items.length > 0 ? (
        <>
          <Col span={12}>
            <DetailContentV2
              mode="EDIT"
              formSubmissionId={currentSubmissionId}
              currentFormSubmission={currentFormSubmission}
              updateFilter={updateFilter}
            />
          </Col>
          <Col span={5}>
            {currentFormSubmission && (
              <CommentV2
                formSubmission={currentFormSubmission}
                disabled={!allowCommonAction}
              />
            )}
          </Col>
        </>
      ) : (
        <Col span={17} className="d-flex align-items-center h-100">
          <Empty className="w-100" />
        </Col>
      )}
    </Row>
  );
};

export default ListContentV2;
