import { Card } from 'antd';
import { CurrentSelectedPolygon } from 'components/ProjectList/ProjectModal/context';
import { DrawState } from 'constants/map';
import React, { useEffect, useState } from 'react';
import CremaDetail from '../FormationMap/CremaDetail/CremaDetail';
import './Formation.scss';
import FormationMap from './FormationMap';
import { MarkersType, useMapContext } from 'contexts/map';
import CommunityDetail from '../FormationMap/CommunityDetail/CommunityDetail';
import { ID_DEFAULT } from 'constants/common';
import { useCremaFormationContext } from 'contexts/cremaFormation';
import { CreateCREMA } from 'components/CREMA/CreateCREMA/CreateCREMA';
import { UpsertCommunity } from 'components/CREMA/UpsertCommunity/UpsertCommunity';
import withDrawerManager from 'hocs/withDrawerManager';
import { CremaDrawer } from 'interfaces';
import { mapHooks } from 'hooks';

const cardProps = {
  bordered: false,
  styles: {
    body: {
      padding: 0,
      height: '100%',
    },
  },
  style: {
    height: '100%',
  },
};

const CreateCommunityDrawer = withDrawerManager(
  UpsertCommunity,
  CremaDrawer.CreateCommunity
);
const CreateCremaDrawer = withDrawerManager(
  CreateCREMA,
  CremaDrawer.CreateCrema
);
const CremaDetailDrawer = withDrawerManager(
  CremaDetail,
  CremaDrawer.CremaDetail
);
const CommunityDetailDrawer = withDrawerManager(
  CommunityDetail,
  CremaDrawer.CommunityDetail
);

interface FormationProps {
  preventOpenCrema?: boolean;
  hideMeetingRecords?: boolean;
}

const Formation: React.FC<FormationProps> = ({
  preventOpenCrema,
  hideMeetingRecords,
}) => {
  const {
    cremas,
    communities,
    cremaLoading,
    communityLoading,
    cremaRefetch,
    communityRefetch,
    cremaInfo,
    communityInfo,
    setCommunityInfo,
    setCremaInfo,
    openDrawer,
    toggleDrawer,
  } = useCremaFormationContext();
  const { setClusterMarkers } = useMapContext();
  const [drawState, setDrawState] = useState<DrawState>(DrawState.NONE);
  const [errorPolygonIds, setErrorPolygonIds] = useState<number[]>([]);

  // Highlight && focus on the selected community
  mapHooks.useMarkerHighlight(communityInfo.id, {
    focus: true,
  });

  useEffect(() => {
    cremaRefetch();
    communityRefetch();
  }, []);

  useEffect(() => {
    const clusterMarkersMap: MarkersType = {};
    communities.forEach(community => {
      const poi = {
        poiId: community.communityId,
        latitude: community.communityLatitude,
        longitude: community.communityLongitude,
        placeName: community.communityName,
        onDbClick: () => {
          openCommunityDetailDrawer(community.communityId);
        },
      };
      clusterMarkersMap[community.communityId] = poi;
    });
    setClusterMarkers(clusterMarkersMap);
  }, [communities]);

  const openCommunityDetailDrawer = (communityId: number) => {
    setCommunityInfo({ id: communityId });
    toggleDrawer(CremaDrawer.CommunityDetail);
  };

  const openCremaDetailDrawer = (cremaId: number) => {
    setCremaInfo({ id: cremaId });
    toggleDrawer(CremaDrawer.CremaDetail);
  };

  const handleUpsertCommunitySuccess = (communityId: number) => {
    openCommunityDetailDrawer(communityId);
    communityRefetch();
  };

  return (
    <>
      <Card {...cardProps}>
        <CurrentSelectedPolygon.Provider
          value={{
            currentPolygonId: cremaInfo.id,
            setCurrentPolygonId: id => {
              toggleDrawer(CremaDrawer.CremaDetail);
              setCremaInfo({ id });
            },
            errorPolygonIds,
            setErrorPolygonIds,
            drawState,
            setDrawState,
          }}
        >
          <div className="formation-wrapper">
            <CreateCremaDrawer onCreateSuccess={openCremaDetailDrawer} />
            <CreateCommunityDrawer
              onUpsertSuccess={handleUpsertCommunitySuccess}
            />
            <CremaDetailDrawer
              cremaInfo={cremaInfo}
              onSelectCommunity={id => openCommunityDetailDrawer(id)}
              onRefreshCremas={cremaRefetch}
              onClose={() => {
                setCremaInfo({ id: ID_DEFAULT });
                setCommunityInfo({ id: ID_DEFAULT });
              }}
              hideMeetingRecords={hideMeetingRecords}
            />
            <CommunityDetailDrawer
              communityInfo={communityInfo}
              rootStyle={
                openDrawer.left.includes(CremaDrawer.CremaDetail)
                  ? { left: 350, margin: 10 }
                  : undefined
              }
              onUpdateSuccess={communityRefetch}
              onClose={() => setCommunityInfo({ id: ID_DEFAULT })}
              hideMeetingRecords={hideMeetingRecords}
            />

            <FormationMap
              loading={cremaLoading || communityLoading}
              cremas={cremas}
              preventOpenCrema={preventOpenCrema}
            />
          </div>
        </CurrentSelectedPolygon.Provider>
      </Card>
    </>
  );
};

export default Formation;
