import { DoubleRightOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Row } from 'antd';
import ContentBlock from 'components/shared/ContentBlock';
import { cremaServices } from 'services';
import { ID_DEFAULT } from 'constants/common';
import { DEFAULT_PAGINATION_OFFSET } from 'constants/pagination';
import { useProjectContext } from 'contexts';
import { paginationHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { CremaMeetingRecord, IGetCremaMeetingRecordParams } from 'interfaces';
import { useEffect, useState } from 'react';
import MeetingRecordFilter from './MeetingRecordFilter';
import MeetingRecordList from './MeetingRecordList';
import MeetingRecordDetail from './MeetingRecordDetail';
import './MeetingRecord.scss';
import { useLocation } from 'react-router-dom';

const { getCremaFormSubmissions } = cremaServices;

const MeetingRecord: React.FC = () => {
  const { projectId } = useProjectContext();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const cremaId = query.get('cremaId');
  const communityId = query.get('communityId');

  const [currentMeetingId, setCurrentMeetingId] = useState<number>(ID_DEFAULT);
  const [params, setParams] = useState<IGetCremaMeetingRecordParams>({
    projectId,
    cremaId: cremaId ? +cremaId : undefined,
    communityIds: communityId ? [+communityId] : undefined,
    ...DEFAULT_PAGINATION_OFFSET,
  });
  const [data, setData] = useState<CremaMeetingRecord[]>([]);
  const [nextOffset, setNextOffset] = useState(
    DEFAULT_PAGINATION_OFFSET.offset
  );
  const [loading, setLoading] = useState(false);
  const [shouldLoadMore, setShouldLoadMore] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);

      const {
        meetingNoteSubmissions,
        pagination,
      } = await getCremaFormSubmissions(params);

      setShouldLoadMore(
        paginationHelpers.shouldLoadMore(
          meetingNoteSubmissions.length,
          params.limit
        )
      );
      const isNewSearch = params.offset === DEFAULT_PAGINATION_OFFSET.offset;
      setData(
        isNewSearch
          ? meetingNoteSubmissions
          : [...data, ...meetingNoteSubmissions]
      );
      // Auto select first meeting record if exists
      if (isNewSearch) {
        setCurrentMeetingId(
          meetingNoteSubmissions.length > 0
            ? meetingNoteSubmissions[0].submissionId
            : ID_DEFAULT
        );
      }
      setNextOffset(pagination.offset);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [params]);

  const onSearch = (filters: Partial<IGetCremaMeetingRecordParams>) => {
    setParams({ ...params, ...filters, ...DEFAULT_PAGINATION_OFFSET });
  };

  const onLoadMore = () => {
    setParams({
      ...params,
      offset: nextOffset,
      limit: DEFAULT_PAGINATION_OFFSET.limit,
    });
  };

  const refresh = () => {
    setParams({
      ...params,
      offset: DEFAULT_PAGINATION_OFFSET.offset,
      limit: nextOffset,
    });
  };

  return (
    <Flex vertical>
      <MeetingRecordFilter
        onSearch={onSearch}
        cremaId={cremaId ? parseInt(cremaId) : undefined}
        communityId={communityId ? parseInt(communityId) : undefined}
      />
      <Row gutter={8}>
        <Col
          span={6}
          style={{ overflow: 'auto' }}
          className="crema-meeting-record-col scrollable"
        >
          <ContentBlock
            className="h-100 overflow-hidden"
            classNames={{ body: 'h-100 ' }}
            styles={{ body: { overflow: 'auto' } }}
          >
            <MeetingRecordList
              loading={loading}
              dataSource={data}
              currentMeetingId={currentMeetingId}
              setCurrentMeetingId={setCurrentMeetingId}
              loadMore={
                shouldLoadMore && (
                  <Row justify="center" className="my-half">
                    <Button
                      type="link"
                      className="w-100"
                      onClick={onLoadMore}
                      loading={loading}
                    >
                      {t('LoadMore')}
                      <DoubleRightOutlined
                        style={{
                          transform: 'rotate(90deg)',
                        }}
                      />
                    </Button>
                  </Row>
                )
              }
            />
          </ContentBlock>
        </Col>
        {currentMeetingId && (
          <>
            <Col span={18}>
              <MeetingRecordDetail
                currentMeetingId={currentMeetingId}
                refreshMeetingRecordList={refresh}
              />
            </Col>
          </>
        )}
      </Row>
    </Flex>
  );
};

export default MeetingRecord;
