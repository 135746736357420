import { Flex, PaginationProps } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import TagStatus from 'components/shared/Tag/TagStatus';
import { COLOR } from 'constants/styles/color';
import { useFlagsupContext } from 'contexts';
import { convertDateFormat, convertNumberFormat } from 'helpers/common';
import { t } from 'helpers/i18n';
import localization from 'helpers/localization';
import { ISubmission, ISubmissionAfterCAR2355 } from 'interfaces';
import React from 'react';
import { TableProps } from 'antd/lib/table';
import dayjs from 'dayjs';
import { DATE_TIME_SLASH_FORMAT } from 'constants/dateTime';
import { ReactComponent as ARIcon } from 'assets/svgs/AR.svg';

const check10PercentDetected = (primaryValue: number, secondValue: number) => {
  if (!primaryValue || !secondValue) return 0;
  const difference = Math.abs(secondValue - primaryValue);
  return (difference / primaryValue) * 100;
};

const getColumnsBeforeCAR2355: (
  onTreeIdClick: (record: ISubmission, index: number) => void,
  onClickCarbonContent: (submissionId: number) => void
) => ColumnsType<ISubmission> = (onTreeIdClick, onClickCarbonContent) => [
  {
    title: <span data-testid="plotName">{t('PlotName')}</span>,
    dataIndex: 'submission',
    render: item => item['plotName'],
    key: 'plotName',
    width: 100,
  },
  {
    title: <span data-testid="treeId">{t('TreeID')}</span>,
    dataIndex: 'submission',
    render: (item, record, index) => (
      <a
        onClick={e => {
          e.preventDefault();
          onTreeIdClick(record, index);
        }}
        href="#/"
      >
        {t('Tree')} {item['submissionId']}
      </a>
    ),
    width: 100,
  },
  {
    title: (
      <span data-testid="diameter">
        {t('Diameter')} {'(cm)'}
      </span>
    ),
    dataIndex: 'submission',
    render: item => {
      return item['treeTrunkDiameter']
        ? convertNumberFormat(item['treeTrunkDiameter'])
        : 'N/A';
    },
    width: 150,
  },
  {
    title: (
      <span data-testid="ai-diameter-detection">
        {t('AIDiameterDetection')} {'(cm)'}
      </span>
    ),
    dataIndex: 'submission',
    render: item => {
      return item['systemDetectedDiameter'] ? (
        <span
          style={{
            color:
              check10PercentDetected(
                item['treeTrunkDiameter'],
                item['systemDetectedDiameter']
              ) > 10
                ? 'rgb(255,0,0)'
                : 'unset',
          }}
        >
          {convertNumberFormat(item['systemDetectedDiameter'])}
        </span>
      ) : (
        <span style={{ color: 'rgb(255,153,0)' }}>{t('UnableToDetect')}</span>
      );
    },
    width: 150,
  },
  {
    title: (
      <span data-testid="tree-height">
        {t('TreeHeight')} {'(cm)'}
      </span>
    ),
    dataIndex: 'submission',
    render: item => {
      return item['treeHeight']
        ? convertNumberFormat(item['treeHeight'])
        : 'N/A';
    },
    width: 150,
  },
  {
    title: (
      <span data-testid="ai-tree-height-detection">
        {t('AITreeHeightDetection')} {'(cm)'}
      </span>
    ),
    dataIndex: 'submission',
    render: item => {
      return item['arTreeHeight'] ? (
        <span
          style={{
            color:
              check10PercentDetected(item['treeHeight'], item['arTreeHeight']) >
              10
                ? 'rgb(255,0,0)'
                : 'unset',
          }}
        >
          {convertNumberFormat(item['arTreeHeight'])}
        </span>
      ) : (
        <span style={{ color: 'rgb(255,153,0)' }}>{t('UnableToDetect')}</span>
      );
    },
    width: 150,
  },
  {
    title: (
      <span data-testid="accuracy">
        {t('GPSAccuracy')} {'(m)'}
      </span>
    ),
    dataIndex: 'submission',
    render: item =>
      item['accuracy']
        ? convertNumberFormat(item['accuracy'])
        : t('NotAvailable'),
    width: 150,
  },
  {
    title: (
      <span data-testid="carbonContent">
        {t('CarbonContentkgC')} {'(kg C)'}
      </span>
    ),
    dataIndex: 'submission',
    width: 200,
    render: item => {
      const carbonContent = item['carbonContent'] as number;
      return carbonContent ? (
        <span
          style={{ color: COLOR.SUPPORT, cursor: 'pointer' }}
          onClick={() => {
            onClickCarbonContent(item.submission);
          }}
        >
          {convertNumberFormat(carbonContent)}
        </span>
      ) : (
        t('NotAvailable')
      );
    },
  },
  {
    title: (
      <span data-testid="co2Offset">
        {t('CO₂OffsetkgCO₂')} {'(kg CO2)'}
      </span>
    ),
    width: 180,
    dataIndex: 'submission',
    render: item =>
      item['co2Offset']
        ? convertNumberFormat(item['co2Offset'])
        : t('NotAvailable'),
  },
  {
    title: <span data-testid="submitterName">{t('Submitter')}</span>,
    dataIndex: 'submission',
    width: 120,
    render: item => item['submitterName'],
  },
  {
    title: <span data-testid="submissionTime">{t('SubmissionTime')}</span>,
    dataIndex: 'submission',
    render: item => convertDateFormat(item['submissionTime']),
  },
  {
    title: <span data-testid="submissionStatus">{t('Status')}</span>,
    dataIndex: 'submissionStatus',
    render: item => <TagStatus isSelected={item?.isSelected} />,
    width: 120,
  },
];

const getColumnsAfterCAR2355 = (
  onTreeIdClick: (record: ISubmissionAfterCAR2355, index: number) => void,
  onClickCarbonContent: (submissionId: number) => void,
  isCAR377Enabled: boolean
): ColumnsType<ISubmissionAfterCAR2355> => [
  {
    title: <span data-testid="plotName">{t('PlotName')}</span>,
    dataIndex: 'plotName',
    key: 'plotName',
    width: 100,
  },
  {
    title: <span data-testid="treeId">{t('TreeID')}</span>,
    dataIndex: 'submissionId',
    render: (value, record, index) => (
      <a
        onClick={e => {
          e.preventDefault();
          onTreeIdClick(record, index);
        }}
        href="#/"
      >
        {t('Tree')} {value}
      </a>
    ),
    width: 100,
  },
  {
    title: (
      <span data-testid="diameter">
        {t('Diameter')} {'(cm)'}
      </span>
    ),
    dataIndex: 'treeTrunkDiameter',
    render: (value, record) => {
      return value ? (
        <Flex align="center" justify="end">
          {convertNumberFormat(value)}&nbsp;
          {record.isDiameterMeasuredByAR && isCAR377Enabled && <ARIcon />}
        </Flex>
      ) : (
        'N/A'
      );
    },
    align: 'right',
    width: 150,
  },
  {
    title: (
      <span data-testid="ai-diameter-detection">
        {t('AIDiameterDetection')} {'(cm)'}
      </span>
    ),
    dataIndex: 'systemDetectedDiameter',
    render: (value, record) => {
      if (record.isDiameterMeasuredByAR && isCAR377Enabled) return '----';
      return value ? (
        <span
          style={{
            color:
              check10PercentDetected(record.treeTrunkDiameter || 0, value) > 10
                ? 'rgb(255,0,0)'
                : 'unset',
          }}
        >
          {convertNumberFormat(value)}
        </span>
      ) : (
        <span style={{ color: 'rgb(255,153,0)' }}>{t('UnableToDetect')}</span>
      );
    },
    align: 'right',
    width: 150,
  },
  {
    title: (
      <span data-testid="tree-height">
        {t('TreeHeight')} {'(cm)'}
      </span>
    ),
    dataIndex: 'treeHeight',
    render: value => {
      return value ? convertNumberFormat(value) : 'N/A';
    },
    width: 150,
  },
  {
    title: (
      <span data-testid="ai-tree-height-detection">
        {t('AITreeHeightDetection')} {'(cm)'}
      </span>
    ),
    dataIndex: 'arTreeHeight',
    render: (value, record) => {
      return value ? (
        <span
          style={{
            color:
              check10PercentDetected(record.treeHeight || 0, value) > 10
                ? 'rgb(255,0,0)'
                : 'unset',
          }}
        >
          {convertNumberFormat(value)}
        </span>
      ) : (
        <span style={{ color: 'rgb(255,153,0)' }}>{t('UnableToDetect')}</span>
      );
    },
    width: 150,
  },
  {
    title: (
      <span data-testid="accuracy">
        {t('GPSAccuracy')} {'(m)'}
      </span>
    ),
    dataIndex: 'accuracy',
    render: value => (value ? convertNumberFormat(value) : t('NotAvailable')),
    width: 150,
  },
  {
    title: (
      <span data-testid="carbonContent">
        {t('CarbonContentkgC')} {'(kg C)'}
      </span>
    ),
    dataIndex: 'carbonContent',
    width: 200,
    render: (value, record) => {
      return value ? (
        <span
          style={{ color: COLOR.SUPPORT, cursor: 'pointer' }}
          onClick={() => {
            onClickCarbonContent(record.submissionId);
          }}
        >
          {convertNumberFormat(value)}
        </span>
      ) : (
        t('NotAvailable')
      );
    },
  },
  {
    title: (
      <span data-testid="co2Offset">
        {t('CO₂OffsetkgCO₂')} {'(kg CO2)'}
      </span>
    ),
    width: 180,
    dataIndex: 'co2Offset',
    render: value => (value ? convertNumberFormat(value) : t('NotAvailable')),
  },
  {
    title: <span data-testid="submissionTime">{t('SubmissionTime')}</span>,
    dataIndex: 'submissionTime',
    render: value => dayjs.unix(value).format(DATE_TIME_SLASH_FORMAT),
  },
  {
    title: <span data-testid="submissionStatus">{t('Status')}</span>,
    dataIndex: 'isSelected',
    render: value => <TagStatus isSelected={value} />,
    width: 120,
  },
];

interface SubmissionTableProps {
  pagination: PaginationProps;
  handleTableChange: (pager: PaginationProps) => void;
  loading: boolean;
  submissionsAfterCAR2355: ISubmissionAfterCAR2355[];
  submissions: ISubmission[];
  onClickTreeIdBeforeCAR2355: (record: ISubmission, index: number) => void;
  onClickTreeIdAfterCAR2355: (
    record: ISubmissionAfterCAR2355,
    index: number
  ) => void;
  onClickCarbonContent: (submissionId: number) => void;
}

/** Variables **/
const { getCurrentLanguage } = localization;

/** ------------------------- **/
const SubmissionTable: React.FC<SubmissionTableProps> = ({
  loading,
  pagination,
  submissionsAfterCAR2355,
  submissions,
  handleTableChange,
  onClickTreeIdAfterCAR2355,
  onClickCarbonContent,
  onClickTreeIdBeforeCAR2355,
}) => {
  const { featureFlagsData } = useFlagsupContext();
  const isCAR2355Enabled = featureFlagsData.CAR_2355?.enabled;
  const isCAR377Enabled = featureFlagsData.CAR_377?.enabled!;

  const tableCommonProps: TableProps = {
    loading: loading,
    sticky: { offsetHeader: 0 },
    bordered: true,
    pagination: {
      ...pagination,
      position: ['topRight'],
      simple: true,
      showTotal: (total, range) => {
        return (
          <>
            <h4
              style={{
                margin: '0px',
                left: '0',
                position: 'absolute',
              }}
            >
              {t('SubmissionsList')}{' '}
            </h4>
            <h4 style={{ margin: '0', display: 'inline' }}>
              {getCurrentLanguage() === 'vi'
                ? `${range[0]}-${range[1]} trên tổng ${total} bản ghi`
                : `${range[0]}-${range[1]} of ${total} items`}
            </h4>
          </>
        );
      },
    },
    onChange: handleTableChange,
    scroll: { x: 'max-content' },
  };

  return isCAR2355Enabled ? (
    <Table
      rowKey="submissionId"
      columns={getColumnsAfterCAR2355(
        onClickTreeIdAfterCAR2355,
        onClickCarbonContent,
        isCAR377Enabled
      )}
      dataSource={submissionsAfterCAR2355}
      {...tableCommonProps}
    />
  ) : (
    <Table
      rowKey="submission.submissionId"
      columns={getColumnsBeforeCAR2355(
        onClickTreeIdBeforeCAR2355,
        onClickCarbonContent
      )}
      dataSource={submissions}
      {...tableCommonProps}
    />
  );
};

export default SubmissionTable;
