import { Card, Skeleton, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import CardItem from './CardItem';
import { Waypoint } from 'react-waypoint';

interface CardListProps {
  items: { [key: string]: any }[];
  loading: boolean;
  className?: string;
  curItemId?: number;
  onLoadMore: () => void;
  onChange?: (item: { [key: string]: any }) => void;
}

const CardList: React.FC<CardListProps> = ({
  items,
  loading,
  className,
  curItemId,
  onLoadMore,
  onChange,
}) => {
  const [selectedItemId, setSelectedItemId] = useState<number | undefined>(
    curItemId
  );

  useEffect(() => {
    setSelectedItemId(curItemId);
  }, [curItemId]);

  return (
    <Card className={className}>
      <Space direction="vertical" className={'w-100'}>
        {items.map(item => {
          return (
            <CardItem
              key={item.id}
              properties={item.properties}
              onClick={() => {
                setSelectedItemId(item.id);
                onChange?.(item);
              }}
              isSelected={item.id === selectedItemId}
              title={item.title}
            />
          );
        })}
        <Waypoint onEnter={onLoadMore} />
        {loading ? (
          <Card>
            <Skeleton loading={true} active title paragraph={{ rows: 1 }} />
          </Card>
        ) : null}
      </Space>
    </Card>
  );
};

export { CardList };
export default CardList;
