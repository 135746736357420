import React, { Dispatch, SetStateAction, useState } from 'react';
import { selectionServices } from 'services';
import {
  ISubmission,
  ISubmissionAfterCAR2355,
  ISubmissionFilters,
  ISubmissionStatus,
} from 'interfaces';
import {
  Modal,
  Spin,
  Button,
  Form,
  Divider,
  notification,
  Row,
  Col,
  Flex,
} from 'antd';
import { renderImages } from 'components/shared/GalleryTwoColumn';
import trackSubmissionDetail from 'helpers/tracker/trackSubmissionDetail';
import { t } from 'helpers/i18n';
import { COLOR } from 'constants/styles/color';
import SubmissionFormula from '../SubmissionFormula/SubmissionFormula';
import SubmissionInfoCard from './SubmissionInfoCard';
import { buttonStyle } from '../../shared/Button/index';
import RejectModal from './RejectModal';
import SubmissionMap from './SubmissionMap';
import { useFlagsupContext } from 'contexts';
import './SubmissionInfo.scss';
import { isNil } from 'lodash';

interface ISubmissionInfoAfterCAR2355Props {
  submissionsAfterCAR2355?: ISubmissionAfterCAR2355[];
  setSubmissionsAfterCAR2355: Dispatch<
    SetStateAction<ISubmissionAfterCAR2355[]>
  >;
  setInfoAfterCAR2355: Dispatch<
    SetStateAction<ISubmissionAfterCAR2355 | undefined>
  >;
  currentSubmissionAfterCAR2355: ISubmissionAfterCAR2355;
}

interface SubmissionInfoProps extends ISubmissionInfoAfterCAR2355Props {
  submissions?: ISubmission[];
  currentSubmission: ISubmission;
  setInfo: Dispatch<SetStateAction<ISubmission | undefined>>;
  setSubmissions: Dispatch<SetStateAction<ISubmission[]>>;
  currentIndex: number;
  setIndex: Dispatch<SetStateAction<number>>;
  filters: ISubmissionFilters;
  isInfoModalOpen: boolean;
  setIsInfoModalOpen: Dispatch<SetStateAction<boolean>>;
}

const { selectSingleTree } = selectionServices;
const { trackTreeAction } = trackSubmissionDetail;

const SubmissionInfo: React.FC<SubmissionInfoProps> = ({
  submissions,
  currentSubmission: currentSubmissionBeforeCAR2355,
  submissionsAfterCAR2355,
  currentSubmissionAfterCAR2355,
  currentIndex,
  filters,
  isInfoModalOpen,
  setIndex,
  setInfo,
  setInfoAfterCAR2355,
  setIsInfoModalOpen,
  setSubmissions,
  setSubmissionsAfterCAR2355,
}) => {
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [rejectForm] = Form.useForm();
  const { featureFlagsData } = useFlagsupContext();

  const isCAR2355Enabled = featureFlagsData.CAR_2355?.enabled;
  const submissionDetail = isCAR2355Enabled
    ? currentSubmissionAfterCAR2355
    : currentSubmissionBeforeCAR2355.submission;
  let submissionStatus = isCAR2355Enabled
    ? currentSubmissionAfterCAR2355
    : currentSubmissionBeforeCAR2355.submissionStatus;
  const submissionLength =
    submissions?.length || submissionsAfterCAR2355?.length || 0;
  const imageCnt =
    submissionDetail.checksumTreeImages?.length ||
    submissionDetail.treeImages.length;
  const imageText = t('Image', { count: imageCnt });

  const previousTree = () => {
    if (currentIndex > 0) {
      isCAR2355Enabled
        ? setInfoAfterCAR2355(submissionsAfterCAR2355![currentIndex - 1])
        : setInfo(submissions![currentIndex - 1]);
      setIndex(currentIndex - 1);
      trackTreeAction('previous', currentIndex);
    }
  };

  const nextTree = () => {
    if (currentIndex < submissionLength - 1) {
      isCAR2355Enabled
        ? setInfoAfterCAR2355(submissionsAfterCAR2355![currentIndex + 1])
        : setInfo(submissions![currentIndex + 1]);
      setIndex(currentIndex + 1);
      trackTreeAction('next', currentIndex);
    } else {
      setIsInfoModalOpen(false);
    }
  };

  const afterAction = (params: Omit<ISubmissionStatus, 'selectedByUserId'>) => {
    if (isCAR2355Enabled) {
      const updatedSubmissions = [...(submissionsAfterCAR2355 || [])];
      updatedSubmissions![currentIndex] = {
        ...updatedSubmissions![currentIndex],
        ...params,
        isEditable: false,
        selectedByUserId: '',
      };
      setSubmissionsAfterCAR2355(updatedSubmissions);
    } else {
      const updatedSubmissions = [...(submissions || [])];
      updatedSubmissions![currentIndex]['submissionStatus'] = {
        ...params,
        selectedByUserId: '',
      };
      updatedSubmissions![currentIndex]['submission']['isEditable'] = false;
      setSubmissions(updatedSubmissions);
    }
    handleRejectOk();
    nextTree();
  };

  const selectTree = async () => {
    trackTreeAction('select', currentIndex);
    try {
      setModalLoading(true);
      const params = {
        projectId: filters.projectId!,
        submissionId: submissionDetail.submissionId,
        isSelected: true,
      };
      await selectSingleTree(params);
      notification.success({
        message: t('Success'),
        description: t('SuccessSelect'),
      });
      afterAction(params);
    } finally {
      setModalLoading(false);
    }
  };

  const onReject = async (values: { rejectReason: string }) => {
    trackTreeAction('reject', currentIndex);
    setModalLoading(true);
    try {
      const params = {
        projectId: filters.projectId!,
        submissionId: submissionDetail.submissionId,
        isSelected: false,
        rejectReason: values.rejectReason,
      };
      await selectSingleTree(params);
      notification.success({
        message: t('Success'),
        description: t('SuccessReject'),
      });
      afterAction(params);
    } finally {
      setModalLoading(false);
    }
  };

  const handleInfoCancel = () => {
    setIsInfoModalOpen(false);
  };

  const showRejectModal = () => {
    rejectForm.resetFields();
    setIsRejectModalOpen(true);
  };

  const handleRejectOk = () => {
    setIsRejectModalOpen(false);
  };

  const handleRejectCancel = () => {
    setIsRejectModalOpen(false);
  };

  if (
    !isInfoModalOpen ||
    submissionLength === 0 ||
    currentIndex === -1 ||
    currentIndex > submissionLength
  )
    return null;

  const FooterDetail = () => {
    const isDisableAction =
      !isNil(submissionStatus?.isSelected) ||
      submissionDetail.carbonContent === null;

    return (
      <Flex className="mt-base" justify="end" gap="small">
        <Button
          key="1"
          style={{
            ...buttonStyle,
            backgroundColor: currentIndex === 0 ? COLOR.NEUTRAL : COLOR.SUPPORT,
          }}
          disabled={currentIndex === 0}
          onClick={previousTree}
        >
          {t('PreviousTree')}
        </Button>
        <Button
          key="2"
          style={{
            ...buttonStyle,
            backgroundColor: isDisableAction ? COLOR.NEUTRAL : COLOR.ERROR,
          }}
          onClick={showRejectModal}
          disabled={isDisableAction}
        >
          {t('Reject')}
        </Button>
        <Button
          key="3"
          data-testid="btn-selected"
          style={{
            ...buttonStyle,
            backgroundColor: isDisableAction ? COLOR.NEUTRAL : COLOR.PRIMARY,
          }}
          disabled={isDisableAction}
          onClick={selectTree}
        >
          {t('Select')}
        </Button>
        <Button
          key="4"
          style={{
            ...buttonStyle,
            backgroundColor:
              currentIndex === submissionLength - 1
                ? COLOR.NEUTRAL
                : COLOR.SUPPORT,
          }}
          disabled={currentIndex === submissionLength - 1}
          onClick={nextTree}
        >
          {t('NextTree')}
        </Button>
      </Flex>
    );
  };

  return (
    <>
      <Modal
        title={
          <>
            <h2 style={{ display: 'inline' }}>
              {t('Tree')} {submissionDetail.submissionId}
              {' - '}
              {submissionDetail.plotName}
              {' - '}
              {imageCnt} {imageText}
            </h2>
          </>
        }
        onCancel={handleInfoCancel}
        open={isInfoModalOpen}
        width={'75%'}
        wrapClassName={isCAR2355Enabled ? 'submission-modal' : ''}
        centered={true}
        footer={null}
      >
        <Divider />
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={isCAR2355Enabled ? 18 : 24}>
            <Spin spinning={modalLoading} delay={500}>
              <div
                style={{
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  height: 500,
                  padding: '0 6px',
                }}
              >
                {renderImages(
                  submissionDetail.treeImages.map(item => {
                    return item.image;
                  })
                )}
              </div>
            </Spin>
            <SubmissionInfoCard
              currentSubmission={currentSubmissionBeforeCAR2355}
              setSubmissions={setSubmissions}
              currentSubmissionAfterCAR2355={currentSubmissionAfterCAR2355}
              setSubmissionsAfterCAR2355={setSubmissionsAfterCAR2355}
            />
            <FooterDetail />
          </Col>
          {isCAR2355Enabled && (
            <Col xs={24} xl={6}>
              <SubmissionMap
                currentSubmission={currentSubmissionAfterCAR2355}
              />
            </Col>
          )}
        </Row>
      </Modal>
      <RejectModal
        open={isRejectModalOpen}
        onCancel={handleRejectCancel}
        onFinish={onReject}
        form={rejectForm}
      />
      <SubmissionFormula />
    </>
  );
};

export default SubmissionInfo;
