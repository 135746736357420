/** Third party libs **/
import { Card, Space, Typography } from 'antd';
import classNames from 'classnames';
import React from 'react';
import dayjs from 'dayjs';
import {
  CalendarOutlined,
  PhoneOutlined,
  TeamOutlined,
} from '@ant-design/icons';

/** Local libs **/
import { IUserAppointment } from 'interfaces';
import { DATE_TIME_SLASH_FORMAT } from 'constants/dateTime';

/** Styles **/

/** Interfaces, enum... **/
interface IAppointmentCardProps {
  item: IUserAppointment;
  onClick: () => void;
  isSelected?: boolean;
}

/** Variables **/
const { Text } = Typography;

/** ------------------------- **/
const AppointmentCard: React.FC<IAppointmentCardProps> = ({
  item,
  onClick,
  isSelected,
}) => {
  /** States **/

  /** Hooks **/

  /** Variables **/

  /** Functions, Events, Actions... **/

  /** Effects **/

  /** Elements **/
  return (
    <Card
      className={classNames('card-wrapper', 'w-100', isSelected && 'selected')}
      onClick={onClick}
    >
      <Space className="d-flex justify-content-between ">
        <div>
          <Text strong className="d-block">
            {item.appointeeName}
          </Text>

          <Text>
            <CalendarOutlined style={{ color: '#00AA5B' }} />{' '}
            {dayjs.unix(item?.startTime).format(DATE_TIME_SLASH_FORMAT)}
          </Text>

          <Text className="d-block">
            <PhoneOutlined style={{ color: '#00AA5B' }} /> {item.appointeePhone}
          </Text>
          <Text className="d-block">
            <TeamOutlined style={{ color: '#00AA5B' }} />{' '}
            {item.appointeeCommunity}
          </Text>
        </div>
      </Space>
    </Card>
  );
};

export { AppointmentCard };
export default AppointmentCard;
