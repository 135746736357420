import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  Circle,
  MapContainer,
  Marker,
  TileLayer,
  Tooltip,
  useMap,
} from 'react-leaflet';
import { ZOOM_OPTIONS } from 'components/shared/Map/constants';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { plotHooks } from 'hooks';
import { ISubmissionAfterCAR2355 } from 'interfaces';
import { t } from 'helpers/i18n';
import { Empty, Flex, Typography } from 'antd';
import { MapLoading } from 'components/shared/Map/MapLoading';
import { latLng, Circle as CircleLeaflet, divIcon } from 'leaflet';
import { COLOR } from 'constants/styles/color';
import { isNil } from 'lodash';

interface SubmissionMapProps {
  currentSubmission: ISubmissionAfterCAR2355;
}

const { Text } = Typography;
const LEAFLET = {
  ATTRIBUTION:
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  URL: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
};

const LeafletContainer = styled.div`
  height: calc(100% - 48px);
  min-height: 500px;
  width: 100%;
  margin-bottom: 16px;
  .leaflet-container {
    width: 100%;
    height: 100%;
  }
  .leaflet-div-icon {
    background: none;
    border: none;
  }
`;

const SubmissionMap: React.FC<SubmissionMapProps> = ({ currentSubmission }) => {
  const { plotDetails, gettingDetails, getPlotDetails } = plotHooks.usePlots();
  const circleRef = useRef<CircleLeaflet>(null);
  const isInvalidSubmission =
    isNil(currentSubmission?.location?.latitude) ||
    isNil(currentSubmission?.location?.longitude);
  const submissionLat = currentSubmission.location?.latitude || 0;
  const submissionLng = currentSubmission.location?.longitude || 0;

  const isSubmissionOutsidePlot = () => {
    if (!plotDetails || !plotDetails.plotRadius || isInvalidSubmission)
      return false;
    const submissionLocation = latLng([submissionLat, submissionLng]);
    const plotLocation = latLng([plotDetails.latitude, plotDetails.longitude]);
    return (
      submissionLocation.distanceTo(plotLocation) >
      (plotDetails.plotRadius || 0)
    );
  };

  useEffect(() => {
    getPlotDetails(currentSubmission.plotId);
  }, [currentSubmission.plotId]);

  useEffect(() => {
    window.dispatchEvent(new Event('resize')); // NOTE: this is a workaround for leaflet correctly renders on webpage
  }, []);

  if (gettingDetails) {
    return <MapLoading loading={gettingDetails} />;
  }

  const getDefaultCenterMap: () => [number, number] = () => {
    if (!isInvalidSubmission) {
      return [submissionLat, submissionLng];
    }
    if (plotDetails) {
      return [plotDetails.latitude, plotDetails.longitude];
    }
    return [0, 0];
  };

  return !isInvalidSubmission || plotDetails ? (
    <>
      <LeafletContainer>
        <MapContainer
          center={getDefaultCenterMap()}
          zoom={13}
          scrollWheelZoom={true}
          fadeAnimation={true}
          markerZoomAnimation={true}
          style={{ borderRadius: 10 }}
        >
          <TileLayer
            attribution={LEAFLET.ATTRIBUTION}
            url={LEAFLET.URL}
            maxNativeZoom={ZOOM_OPTIONS.MAX_NATIVE_ZOOM}
            maxZoom={ZOOM_OPTIONS.MAX_ZOOM}
          />
          {!isInvalidSubmission && (
            <Marker
              position={[submissionLat, submissionLng]}
              icon={divIcon({
                iconAnchor: [6, 6],
                html: `<span class="marker-circle custom-marker-active" />`,
              })}
              zIndexOffset={1}
            >
              <Tooltip
                direction="top"
                offset={[0, -6]}
                permanent
              >{`${submissionLat}, ${submissionLng}`}</Tooltip>
            </Marker>
          )}

          {plotDetails && (
            <>
              <Circle
                ref={circleRef}
                center={[plotDetails.latitude, plotDetails.longitude]}
                pathOptions={{
                  color: COLOR.PLOT,
                }}
                radius={plotDetails?.plotRadius || 0}
              />
              <Marker
                position={[plotDetails.latitude, plotDetails.longitude]}
                icon={divIcon({
                  iconAnchor: [4, 4],
                  html: `<span class="custom-marker-plot" />`,
                })}
              />
            </>
          )}
          <AutoCenter
            circleRef={circleRef}
            submissionLocation={currentSubmission.location}
          />
        </MapContainer>
      </LeafletContainer>
      {isSubmissionOutsidePlot() && (
        <Flex align="center" style={{ height: 32 }}>
          <Text type="warning">
            <ExclamationCircleFilled className="mr-half mb-0" />
            {t('TheLocationOutsideThePlot')}
          </Text>
        </Flex>
      )}
    </>
  ) : (
    <Flex align="center" justify="center" className="h-100">
      <Empty description={t('LocationNotFound')} />
    </Flex>
  );
};

function AutoCenter({
  circleRef,
  submissionLocation,
}: {
  circleRef: React.RefObject<CircleLeaflet>;
  submissionLocation?: { latitude?: number; longitude?: number };
}) {
  const map = useMap();

  useEffect(() => {
    const bounds = [] as any[];
    if (circleRef.current) {
      bounds.push(circleRef.current.getBounds());
    }
    if (submissionLocation?.latitude && submissionLocation?.longitude) {
      bounds.push([submissionLocation.latitude, submissionLocation.longitude]);
    }
    if (bounds.length) map.fitBounds(bounds, { padding: [10, 10] });
  }, [circleRef, submissionLocation, map]);

  return null;
}

export default SubmissionMap;
