import React from 'react';
import { Typography } from 'antd';
import classnames from 'classnames';

const { Title } = Typography;

interface AppContainerProps {
  title?: React.ReactNode;
  head?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  classNames?: {
    container?: string;
    head?: string;
    body?: string;
  };
}

const AppContainer: React.FC<AppContainerProps> = props => {
  const { title, head, className, children, style, classNames } = props;

  return (
    <div
      className={classnames('app-container', classNames?.container)}
      style={style}
    >
      <div className={classnames('app-container-head', classNames?.head)}>
        {title && (
          <Title className="app-title flex-1" level={4}>
            {title}
          </Title>
        )}
        {head}
      </div>

      {children && (
        <div
          className={classnames(
            'app-container-body',
            className,
            classNames?.body
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default AppContainer;
