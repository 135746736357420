/** Third party libs * */
import React, { useEffect, useState } from 'react';
import { Image } from 'antd';

/** Local libs * */
import { t } from 'helpers/i18n';
import { BaseItem, BaseItemProps } from '../BaseItem';
import { fileHelpers } from 'helpers';
import { useFlagsupContext } from 'contexts';

/** Components * */

/** Styles * */

/** Interfaces, enum... * */
interface SignatureProps extends BaseItemProps {}

/** Variables * */
const { applyGoogleImageSizing } = fileHelpers;

/** ------------------------- * */
const Signature: React.FC<SignatureProps> = ({
  item,
  disabled,
  form,
  ...props
}) => {
  /** States * */
  const [images, setImages] = useState<string[]>([]);

  /** Hooks * */
  const { featureFlagsData } = useFlagsupContext();

  /** Variables * */
  const isCarsa919Enable = featureFlagsData.CARSA_919?.enabled;

  /** Functions, Events, Actions... * */

  /** Effects * */
  useEffect(() => {
    if (item.fieldName) {
      form.setFieldValue(item.fieldName, item.value);
    }
  }, [item.value]);

  useEffect(() => {
    if (item.fieldName) {
      form.setFieldValue(item.fieldName, item.value);
    }

    if (item.value) {
      try {
        const values = JSON.parse(item.value);
        setImages(values);
      } catch (e) {}
    }
  }, [item.value, item.fieldName]);

  /** Elements * */
  return (
    <BaseItem
      item={item}
      disabled={disabled}
      form={form}
      validator={(_, value) => {
        if (item.isRequired && (!value || !value.trim())) {
          return Promise.reject(t('NotBeEmpty'));
        }

        return Promise.resolve();
      }}
      {...props}
    >
      <Image.PreviewGroup>
        {images.map((image, index) => (
          <span style={{ marginRight: 8 }}>
            <Image
              width={200}
              key={index}
              src={
                isCarsa919Enable
                  ? applyGoogleImageSizing(image, { width: 200 })
                  : image
              }
            />
          </span>
        ))}
      </Image.PreviewGroup>
    </BaseItem>
  );
};

export default Signature;
