import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { mpiConstants } from 'constants/index';
import { t } from 'helpers/i18n';

const { MPIPOIName } = mpiConstants;

const options: ChartOptions<'bar'> = {
  responsive: true,
  hover: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    y: {
      ticks: {
        stepSize: 1,
      },
    },
  },
  plugins: {
    tooltip: {
      mode: 'index',
      intersect: false,
    },
    legend: {
      display: true,
    },
    title: {
      display: true,
      text: t('MPIIndicators'),
    },
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IMPIIndicatorsProps {
  numPoiIndicators: { [key: string]: number };
}

const MPIIndicators: React.FC<IMPIIndicatorsProps> = ({ numPoiIndicators }) => {
  const labels = ['2020', '2022', '2023'];
  const data = {
    labels,
    datasets: [
      {
        label: MPIPOIName.Well,
        data: [
          numPoiIndicators[MPIPOIName.Well],
          numPoiIndicators[MPIPOIName.Well],
          numPoiIndicators[MPIPOIName.Well],
        ],
        backgroundColor: '#1990ff',
      },
      {
        label: MPIPOIName.ElectricPole,
        data: [
          0,
          numPoiIndicators[MPIPOIName.ElectricPole],
          numPoiIndicators[MPIPOIName.ElectricPole],
        ],
        backgroundColor: '#ffe040',
      },
      {
        label: MPIPOIName.NewRoad,
        data: [0, 0, numPoiIndicators[MPIPOIName.NewRoad]],
        backgroundColor: '#bcbfd6',
      },
    ],
  };
  return <Bar options={options} data={data} />;
};

export { MPIIndicators };
