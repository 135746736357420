import { Button, Form, Input, Modal, ModalProps } from 'antd';
import { t } from 'helpers/i18n';
import React from 'react';
import { buttonStyle } from '../../shared/Button/index';
import { COLOR } from 'constants/styles/color';
import { FormInstance } from 'antd/lib/form';

interface RejectModalProps extends ModalProps {
  onFinish: (values: { rejectReason: string }) => void;
  form: FormInstance;
}

const RejectModal: React.FC<RejectModalProps> = ({
  open,
  onCancel,
  onFinish,
  form,
}) => {
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={
        <div>
          <span style={{ color: 'red' }}>* </span>
          {t('ReasonForRejection')}
        </div>
      }
      footer={[
        <Button
          key="1"
          style={{
            ...buttonStyle,
            backgroundColor: COLOR.NEUTRAL,
          }}
          onClick={onCancel}
        >
          {t('Cancel')}
        </Button>,
        <Button
          key="2"
          style={{
            ...buttonStyle,
            backgroundColor: COLOR.ERROR,
          }}
          htmlType="submit"
          form="rejectForm"
        >
          {t('Reject')}
        </Button>,
      ]}
    >
      <Form form={form} name="rejectForm" onFinish={onFinish}>
        <Form.Item
          name="rejectReason"
          rules={[{ required: true, message: t('RequiredMessage') }]}
        >
          <Input maxLength={150} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RejectModal;
