import {
  IPaginationOffsetParams,
  IPaginationOffsetResponse,
  IPaginationParams,
  IPaginationResponse,
} from './common';

export interface IGetUserListParams extends IPaginationOffsetParams {
  keyword?: string;
  projectId: number;
  isCreatedByMe?: boolean;
}

export interface IGetUserListReponseData {
  users: IUser[];
  pagination: IPaginationOffsetResponse & { offset: number | null };
}

export enum UserProfileStatus {
  Verfied = 'VERIFIED',
  NotVerified = 'NOT_VERIFIED',
}

export enum UserOrganizationRole {
  ProvicialFund = 'PROVINCE_FUND',
  ForestOwner = 'FOREST_OWNER',
  CommunePeopleCommittee = 'COMMUNE_PEOPLE_COMMITTEE',
  CommunityBoardMember = 'COMMUNITY_BOARD_MEMBER',
  CommunityMember = 'COMMUMNITY_MEMBER',
}

export enum OrganizationType {
  Province = 'PROVINCE',
  Forest = 'FOREST',
  Commune = 'COMMUNE',
  Community = 'COMMNUNITY',
}

export interface IUser {
  id: number;
  phoneNumber: string;
  userOrganizationRoles: {
    roleName: string;
    organizationName: string;
  }[];
  profileStatus: UserProfileStatus;
  isAccountActive: boolean;
}

export interface IOrganizationRole {
  roleCode: UserOrganizationRole;
  organizationType: OrganizationType;
}

export interface IUpdateAccountStatusPayload {
  userId: number;
  projectId: number;
  isActive: boolean;
}

export interface OperationRole {
  id: number;
  name: string;
  description: string;
  updatedAt: number;
  updatedBy: string;
  projectNames: string[];
  communityNames: string[];
}

export interface IGetListOperationRolesParams extends IPaginationParams {
  keyword?: string;
  projectIds?: number[];
}

export interface IGetListOperationRolesData {
  operationRoles: OperationRole[];
  pagination: IPaginationResponse;
}

export type OperationPermissionType =
  | 'CREATE'
  | 'READ'
  | 'EDIT_OWN'
  | 'EDIT_ALL'
  | 'DELETE_OWN'
  | 'DELETE_ALL'
  | 'APPROVAL'
  | 'DOWNLOAD';

export interface IOperationPermission {
  id: number;
  type: OperationPermissionType;
}

export interface IOperationObject {
  id: number;
  name: string;
  permissions: IOperationPermission[];
}

export interface IGetOperationPermissionsResponse {
  operationObjects: IOperationObject[];
}

export interface ICreateOperationRolePayload {
  operationRoleName: string;
  description?: string;
  projectIds?: number[];
  communityIds?: number[];
  permissionIds?: number[];
}

export interface ICreateOperationRoleResponse {
  operationRoleId: number;
}

export interface IUpdateOperationRolePayload {
  roleId: number;
  operationRoleName: string;
  description?: string;
  projectIds?: number[];
  communityIds?: number[];
  permissionIds?: number[];
}

export interface IUpdateOperationRoleResponse {}

export interface IGetOperationRoleResponse {
  operationRoleId: number;
  operationRoleName: string;
  description: string;
  projectIds: number[];
  communityIds: number[];
  permissionIds: number[];
  projects: {
    id: number;
    name: string;
  }[];
  communities: {
    id: number;
    name: string;
  }[];
}

export interface IGetListAccountsParams extends IPaginationOffsetParams {
  email?: string;
  phoneNumber?: string;
  fullName?: string;
  operationRoleIds?: number[];
}

export interface IGetListAccountsResponse {
  accounts: IAccount[];
  pagination: IPaginationOffsetResponse;
}

export enum AccountStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export interface IAccount {
  id: number;
  fullName: string;
  email?: string;
  phoneNumber?: string;
  status: AccountStatus;
}

export interface IUpdateAccountPayload {
  accountId: number;
  fullName: string;
  jobTitle?: string;
  email?: string;
  phoneNumber?: string;
  status: AccountStatus;
  projectIds?: number[];
  communityIds?: number[];
  operationRoleIds?: number[];
}

export interface IUpdateAccountResponse {}

export interface ICreateAccountPayload {
  fullName: string;
  email?: string;
  jobTitle?: string;
  phoneNumber?: string;
  status: AccountStatus;
  projectIds?: number[];
  communityIds?: number[];
  operationRoleIds?: number[];
}

export interface ICreateAccountResponse {
  accountId: number;
}

export interface IGetAccountDetailsParams {
  accountId: number;
}

export interface IGetAccountDetailsResponse {
  id: number;
  fullName: string;
  jobTitle: string;
  email: string;
  phoneNumber: string;
  status: AccountStatus;
  operationRoleIds: number[];
  projects: {
    id: number;
    name: string;
  }[];
  communities: {
    id: number;
    name: string;
  }[];
}
