import { PAGING_PAGE_SIZE_5 } from 'constants/pagination';
import { useEffect, useRef, useState } from 'react';
import { activityServices } from 'services';
import {
  ActivityStatus,
  IActivitiesStatus,
  IActivity,
  IActivityCategory,
  IGetListActivitiesParams,
  IPaginationResponse,
} from 'interfaces';
import { useProjectContext } from 'contexts';
import { activityConstants } from 'constants/index';

const { ACTIVITY_ALL_STATUS } = activityConstants;

const useActivities = () => {
  const { projectId } = useProjectContext();
  const [activities, setActivities] = useState<IActivity[]>([]);
  const [pagination, setPagination] = useState<IPaginationResponse>({
    ...PAGING_PAGE_SIZE_5,
    total: 0,
  });
  const [activitiesStatus, setActivitiesStatus] = useState<IActivitiesStatus>({
    total: 0,
    [ActivityStatus.IN_REVIEW]: 0,
    [ActivityStatus.APPROVED]: 0,
    [ActivityStatus.NEED_TO_UPDATE]: 0,
  });

  const [queryParams, setQueryParams] = useState<
    Partial<IGetListActivitiesParams>
  >();
  const previousQueryParams = useRef(queryParams);
  const [loading, setLoading] = useState(false);

  const fetchActivities = async () => {
    try {
      setLoading(true);
      // Fetch activities with all status
      const filterStatuses = [
        ACTIVITY_ALL_STATUS,
        ActivityStatus.IN_REVIEW,
        ActivityStatus.NEED_TO_UPDATE,
        ActivityStatus.APPROVED,
      ];
      const response = await Promise.all(
        filterStatuses.map(status =>
          activityServices.getListActivities({
            ...queryParams,
            ...PAGING_PAGE_SIZE_5,
            projectId,
            statuses: status,
          })
        )
      );

      const [
        allActivities,
        inReviewActivities,
        needToUpdateActivities,
        approvedActivities,
      ] = response;
      // Update activities status
      setActivitiesStatus({
        [ActivityStatus.IN_REVIEW]: inReviewActivities.pagination.total,
        [ActivityStatus.NEED_TO_UPDATE]:
          needToUpdateActivities.pagination.total,
        [ActivityStatus.APPROVED]: approvedActivities.pagination.total,
        total: allActivities.pagination.total,
      });

      // Update activities
      switch (queryParams?.statuses) {
        case ActivityStatus.IN_REVIEW:
          setActivities(inReviewActivities.activities || []);
          setPagination(inReviewActivities.pagination);
          break;
        case ActivityStatus.NEED_TO_UPDATE:
          setActivities(needToUpdateActivities.activities || []);
          setPagination(needToUpdateActivities.pagination);
          break;
        case ActivityStatus.APPROVED:
          setActivities(approvedActivities.activities || []);
          setPagination(approvedActivities.pagination);
          break;
        case ACTIVITY_ALL_STATUS:
          setActivities(allActivities.activities || []);
          setPagination(allActivities.pagination);
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!queryParams?.organizationId) {
      return;
    }

    // Fetch activities with all status

    // Fetch activities with selected status
    const changeActivityStatus = async () => {
      try {
        setLoading(true);
        const {
          activities,
          pagination,
        } = await activityServices.getListActivities({
          ...queryParams,
          ...PAGING_PAGE_SIZE_5,
          projectId,
        });
        setActivities(activities || []);
        setPagination(pagination);
      } finally {
        setLoading(false);
      }
    };

    // Check if query params only change status or not
    if (
      queryParams.statuses !== previousQueryParams.current?.statuses &&
      queryParams.organizationId ===
        previousQueryParams.current?.organizationId &&
      queryParams.categoryId === previousQueryParams.current?.categoryId &&
      queryParams.year === previousQueryParams.current?.year
    ) {
      changeActivityStatus();
    } else {
      fetchActivities();
    }
    previousQueryParams.current = queryParams;
  }, [queryParams]);

  const getMoreActivities = async () => {
    try {
      setLoading(true);
      const {
        activities,
        pagination: paginationRes,
      } = await activityServices.getListActivities({
        ...queryParams,
        pageSize: pagination.pageSize,
        page: pagination.page + 1,
        projectId,
      });

      setActivities(prev => [...prev, ...activities]);
      setPagination(paginationRes);
    } finally {
      setLoading(false);
    }
  };

  return {
    activities,
    queryParams,
    setQueryParams,
    loading,
    getMoreActivities,
    activitiesStatus,
    pagination,
    fetchActivities,
  };
};

const useActivityCategories = () => {
  const [activityCategories, setActivityCategories] = useState<
    IActivityCategory[]
  >([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchActivityCategories = async () => {
      try {
        setLoading(true);
        const {
          categories,
        } = await activityServices.getListActivityCategories();
        setActivityCategories(categories);
      } finally {
        setLoading(false);
      }
    };

    fetchActivityCategories();
  }, []);

  return { activityCategories, loading };
};

export default {
  useActivities,
  useActivityCategories,
};
