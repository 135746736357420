import { t } from 'helpers/i18n';
import React, { Dispatch, useEffect, useState } from 'react';
import { ColumnsType, TableProps } from 'antd/es/table';
import { Button, Flex, Table, Tag } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import AddRoleGroupModal from './AddRoleGroupModal';
import { IGetListOperationRolesParams, OperationRole } from 'interfaces';
import { userManagementServices } from 'services';
import { PAGING_PAGE_SIZE_ALL } from 'constants/pagination';

interface IRoleGroupTableProps {
  tableProps?: TableProps<OperationRole>;
  value?: number[];
  onChange?: Dispatch<number[]>;
}

const RoleGroupTable: React.FC<IRoleGroupTableProps> = ({
  value,
  onChange,
  tableProps,
}) => {
  const [addRoleGroupModal, setAddRoleGroupModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roleGroups, setRoleGroups] = useState<OperationRole[]>([]);

  useEffect(() => {
    const getListOperationRoles = async (
      params: IGetListOperationRolesParams
    ) => {
      setLoading(true);
      try {
        const {
          operationRoles,
        } = await userManagementServices.getListOperationRoles(params);
        setRoleGroups(operationRoles);
      } finally {
        setLoading(false);
      }
    };

    getListOperationRoles({ ...PAGING_PAGE_SIZE_ALL });
  }, []);

  const dataSource = roleGroups.filter(role => value?.includes(role.id));

  const columns: ColumnsType<OperationRole> = [
    {
      title: t('RoleGroupName'),
      dataIndex: 'name',
    },
    {
      title: t('Project'),
      dataIndex: 'projectNames',
      render: (projectNames: string[]) => (
        <Flex wrap gap={8}>
          {projectNames.map(name => (
            <Tag color="magenta" className="mr-0" key={name}>
              {name}
            </Tag>
          ))}
        </Flex>
      ),
    },
    {
      title: t('Community'),
      dataIndex: 'communityNames',
      render: (communityNames: string[]) => (
        <Flex wrap gap={8}>
          {communityNames.map(name => (
            <Tag color="magenta" className="mr-0" key={name}>
              {name}
            </Tag>
          ))}
        </Flex>
      ),
    },
    {
      title: t('Action'),
      render: (_, rowData) => (
        <Button
          icon={<DeleteOutlined />}
          type="text"
          danger
          onClick={() =>
            onChange?.(value?.filter(id => id !== rowData.id) || [])
          }
        />
      ),
    },
  ];

  return (
    <>
      <Flex justify="end" className="mb-base">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setAddRoleGroupModal(true)}
        >
          {t('Add')}
        </Button>
      </Flex>
      <Table
        rowKey="id"
        dataSource={dataSource}
        columns={columns}
        loading={loading}
        {...tableProps}
      />
      <AddRoleGroupModal
        roleGroups={roleGroups}
        open={addRoleGroupModal}
        onCancel={() => setAddRoleGroupModal(false)}
        onAddRole={roleIds => {
          onChange?.([...(value || []), ...roleIds]);
          setAddRoleGroupModal(false);
        }}
      />
    </>
  );
};

export default RoleGroupTable;
