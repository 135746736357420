import crypto from 'crypto';

export function removeUndefinedKey(obj: Record<string, any>) {
  for (const prop in obj) {
    if (typeof obj[prop] === 'undefined') {
      delete obj[prop];
    }
  }
  return obj;
}

export function normalizeStringValue(obj: Record<string, any>) {
  for (const prop in obj) {
    if (typeof obj[prop] === 'string') {
      const trimmedString = obj[prop].trim();
      if (trimmedString === '') {
        obj[prop] = null;
      } else {
        obj[prop] = trimmedString;
      }
    }
  }
  return obj;
}

export function downloadURL(href: string, fileName: string) {
  const a = document.createElement('a');
  a.href = href;
  a.setAttribute('style', 'display: none');
  a.setAttribute('download', fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export const randomHexArr = (lenHex: number, lenArr: number) => {
  const hexArr: string[] = [];
  for (let i = 0; i < lenArr; i++) {
    hexArr.push(crypto.randomBytes(lenHex).toString('hex'));
  }
  return hexArr;
};
