import { FormInstance } from 'antd';
import { IPolygonFilter } from 'components/ProjectList/ProjectDetailMapV2';
import { LayerType } from 'components/shared/Map';
import { DrawState } from 'constants/map';
import {
  IDrawPolygonRef,
  IPolygonTekbone,
  SamplingPlotsType,
} from 'interfaces';
import { createContext, Dispatch, useContext } from 'react';

export interface IStatusMapHandler {
  callAPI: boolean;
  isCenter: boolean;
}

interface IProjectDetailV2 {
  showAddPolygon: boolean;
  setShowAddPolygon: React.Dispatch<React.SetStateAction<boolean>>;
  setDrawState?: Dispatch<React.SetStateAction<DrawState>>;
  drawState?: DrawState;
  drawRef?: React.MutableRefObject<IDrawPolygonRef>;
  isAddProjectBoundary: boolean;
  setIsAddProjectBoundary: React.Dispatch<React.SetStateAction<boolean>>;
  currentPolygonId: number;
  setCurrentPolygonId: Dispatch<number>;
  selectedLayerTypes: LayerType[];
  setSelectedLayerTypes: React.Dispatch<React.SetStateAction<LayerType[]>>;
  statusAfterMapChanges: IStatusMapHandler;
  setStatusAfterMapChanges: React.Dispatch<
    React.SetStateAction<IStatusMapHandler>
  >;
  canLoadMore: boolean;
  loadMore: () => void;
  gettingPolygons: boolean;
  showAddPolygonScreen: boolean;
  setShowAddPolygonScreen: React.Dispatch<React.SetStateAction<boolean>>;
  setTriggered: React.Dispatch<React.SetStateAction<boolean>>;
  form?: FormInstance<IPolygonFilter>;
  isPlotMode: boolean;
  setIsPlotMode: React.Dispatch<React.SetStateAction<boolean>>;
  polygons: IPolygonTekbone[];
  setPolygons: React.Dispatch<React.SetStateAction<IPolygonTekbone[]>>;
  viewARREligible: boolean;
  setViewARREligible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProjectDetailV2Context = createContext<IProjectDetailV2>({
  showAddPolygon: false,
  setShowAddPolygon: () => {},
  setDrawState: () => {},
  drawState: DrawState.NONE,
  drawRef: {} as React.MutableRefObject<IDrawPolygonRef>,
  isAddProjectBoundary: false,
  setIsAddProjectBoundary: () => {},
  currentPolygonId: -1,
  setCurrentPolygonId: () => {},
  selectedLayerTypes: ['Default'],
  setSelectedLayerTypes: () => {},
  statusAfterMapChanges: { callAPI: false, isCenter: true },
  setStatusAfterMapChanges: () => {},
  canLoadMore: false,
  loadMore: () => {},
  gettingPolygons: false,
  showAddPolygonScreen: false,
  setShowAddPolygonScreen: () => {},
  setTriggered: () => {},
  isPlotMode: false,
  setIsPlotMode: () => {},
  polygons: [],
  setPolygons: () => {},
  viewARREligible: false,
  setViewARREligible: () => {},
});

export const useProjectDetailV2Context = () =>
  useContext(ProjectDetailV2Context);

interface IProjectDrawers {
  showGenerateReport: boolean;
  setShowGenerateReport: React.Dispatch<React.SetStateAction<boolean>>;
  isDetailProjectDrawerOpen: boolean;
  setIsDetailProjectDrawerOpen: Dispatch<React.SetStateAction<boolean>>;
  openList: boolean;
  setOpenList: Dispatch<React.SetStateAction<boolean>>;
  samplingPlotsDrawerType?: SamplingPlotsType;
  setSamplingPlotsDrawerType: Dispatch<
    React.SetStateAction<SamplingPlotsType | undefined>
  >;
}

export const ProjectDrawersContext = createContext<IProjectDrawers>({
  showGenerateReport: false,
  setShowGenerateReport: () => {},
  isDetailProjectDrawerOpen: false,
  setIsDetailProjectDrawerOpen: () => {},
  openList: false,
  setOpenList: () => {},
  samplingPlotsDrawerType: undefined,
  setSamplingPlotsDrawerType: () => {},
});

export const useProjectDrawersContext = () => useContext(ProjectDrawersContext);
