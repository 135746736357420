import { Alert, Badge, Button, Space } from 'antd';
import { Dispatch, DispatchWithoutAction, useState } from 'react';
import { CommunityTab } from './CommunityTab';
import {
  EditOutlined,
  FileTextOutlined,
  ProfileOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { DocumentationsTab } from './DocumentationsTab';
import AddDocumentation from './AddDocumentation';
import { cremaHooks } from 'hooks';
import { useHistory } from 'react-router-dom';
import { CollapsibleType } from 'antd/es/collapse/CollapsePanel';
import { CremaDrawer, ICommunity } from 'interfaces';
import OrganizationDetail from '../OrganizationDetail';
import CECMember from './CECMember';
import { UpdateCrema } from './UpdateCrema';
import { userHooks } from 'hooks';
import './CremaDetail.scss';
import withDrawerManager from 'hocs/withDrawerManager';
import { useCremaFormationContext } from 'contexts/cremaFormation';
import { useTranslation } from 'react-i18next';

const { useUserPermissions } = userHooks;
interface CremaDetailProps {
  open?: boolean;
  cremaInfo: { id: number };
  onClose?: DispatchWithoutAction;
  onSelectCommunity?: Dispatch<ICommunity['communityId']>;
  onRefreshCremas: DispatchWithoutAction;
  hideMeetingRecords?: boolean;
}

const UpdateCremaDrawer = withDrawerManager(
  UpdateCrema,
  CremaDrawer.UpdateCrema
);
const CECMemberDrawer = withDrawerManager(CECMember, CremaDrawer.CECMember);
const AddDocumentationDrawer = withDrawerManager(
  AddDocumentation,
  CremaDrawer.AddDocumentation
);

const CremaDetail = ({
  open,
  cremaInfo,
  onClose,
  onSelectCommunity,
  onRefreshCremas,
  hideMeetingRecords,
}: CremaDetailProps) => {
  const { t } = useTranslation(window.appConfig?.appName);
  const history = useHistory();
  const { toggleDrawer } = useCremaFormationContext();

  const [triggerDocumentationTimes, setTriggerDocumentationTimes] = useState<
    number
  >(1);
  const {
    crema,
    getCremaDetail,
    loading,
    warnings,
    validateCrema,
  } = cremaHooks.useCremaDetail(cremaInfo);
  const { canUpdateCrema } = useUserPermissions();

  const items = [
    {
      key: 'Community',
      label: (
        <Space className="text-medium">
          <TeamOutlined />
          {t('Community')}
          <Badge count={crema?.totalCommunities} showZero />
        </Space>
      ),
      children: (
        <CommunityTab
          cremaId={cremaInfo.id}
          setShowDetailCommunity={communityId =>
            onSelectCommunity?.(communityId)
          }
          getCremaDetail={getCremaDetail}
        />
      ),
    },
    {
      key: 'CECMember',
      label: (
        <Space
          className="text-medium clickable"
          onClick={() => toggleDrawer(CremaDrawer.CECMember)}
        >
          <UserOutlined />
          {t('CECMember')}
          <Badge count={crema?.totalCECMembers} showZero />
        </Space>
      ),
      showArrow: false,
      collapsible: 'icon' as CollapsibleType,
    },
    {
      key: 'MeetingRecords',
      label: (
        <Space
          className="text-medium clickable"
          onClick={() =>
            history.push(
              `/crema-formation/meeting-records?cremaId=${cremaInfo.id}`
            )
          }
        >
          <ProfileOutlined />
          {t('MeetingRecords')}
          <Badge count={crema?.totalMeetingNotes} showZero />
        </Space>
      ),
      showArrow: false,
      collapsible: 'icon' as CollapsibleType,
    },
    {
      key: 'Documentations',
      label: (
        <Space className="text-medium">
          <FileTextOutlined />
          {t('Documentations')}
          <Badge count={crema?.totalDocuments} showZero />
        </Space>
      ),
      children: (
        <DocumentationsTab
          cremaId={cremaInfo.id}
          triggerDocumentationTimes={triggerDocumentationTimes}
          setShowCreationDocumentationModal={() =>
            toggleDrawer(CremaDrawer.AddDocumentation)
          }
        />
      ),
    },
  ];

  if (hideMeetingRecords) {
    items.splice(2, 1);
  }

  const onUpdateCremaSuccess = () => {
    getCremaDetail();
    validateCrema();
    onRefreshCremas();
  };

  return (
    <OrganizationDetail
      organizationName={
        <Space>
          {crema?.cremaName}
          {canUpdateCrema && crema?.cremaId && (
            <Button
              icon={<EditOutlined />}
              type="text"
              onClick={() => toggleDrawer(CremaDrawer.UpdateCrema)}
            />
          )}
        </Space>
      }
      alert={
        !!warnings.length && (
          <Alert
            type="warning"
            showIcon
            description={
              warnings.length > 1 ? (
                <ul className="ml-base">
                  {warnings.map((warning, index) => (
                    <li key={index}>{t(warning.error)}</li>
                  ))}
                </ul>
              ) : (
                t(warnings[0]?.error)
              )
            }
            className="align-items-center p-half mb-half"
          />
        )
      }
      organizationImageUrl={crema?.cremaImageUrl}
      loading={loading}
      open={open}
      items={items}
      onClose={onClose}
    >
      <CECMemberDrawer cremaId={cremaInfo.id} getCremaDetail={getCremaDetail} />
      <AddDocumentationDrawer
        cremaId={cremaInfo.id}
        setTriggerDocumentationTimes={setTriggerDocumentationTimes}
      />
      {canUpdateCrema && crema?.cremaId && (
        <UpdateCremaDrawer
          key={crema?.cremaId}
          initialValues={{
            cremaId: cremaInfo.id,
            cremaName: crema?.cremaName,
            cremaImageFileUrl: crema?.cremaImageUrl
              ? [crema?.cremaImageUrl]
              : undefined,
            cremaPolygonFileUrl: crema?.cremaPolygonFileUrl || undefined,
          }}
          onSuccess={onUpdateCremaSuccess}
        />
      )}
    </OrganizationDetail>
  );
};

export default CremaDetail;
