import { useEffect, useState } from 'react';
import {
  ActivityStatus,
  IGetCommunityDashboardFundingResponse,
} from 'interfaces';
import { Button, Card, Modal, Row, Spin } from 'antd';
import { activityHooks, userHooks } from 'hooks';
import { convertNumberFormat } from 'helpers/common';
import { CheckOutlined, PrinterOutlined } from '@ant-design/icons';
import { t } from 'helpers/i18n';
import { activityServices } from 'services';
import { useProjectContext } from 'contexts';
import FinancialPlanFilter from './FinancialPlanFilter';
import FinancialPlanList from './FinancialPlanList';
import './FinancialPlan.scss';
import { ID_DEFAULT, MODAL_WIDTH } from 'constants/common';
import { fileHelpers } from 'helpers';
import { renderAsync } from 'docx-preview/dist/docx-preview';
const { useActivities } = activityHooks;
const { useUserPermissions } = userHooks;

const FinancialPlan = () => {
  const { projectId } = useProjectContext();
  const { canApproveActivity, canPrintActivity } = useUserPermissions();
  const {
    loading,
    activities: financialPlans,
    setQueryParams,
    getMoreActivities,
    queryParams,
    activitiesStatus,
    pagination,
    fetchActivities,
  } = useActivities();

  const [communityFunding, setCommunityFunding] = useState<
    IGetCommunityDashboardFundingResponse
  >();
  const [approveAllLoading, setApproveAllLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [fileData, setFileData] = useState<Blob>(new Blob());

  const financialYear = queryParams?.year as number;
  const communityId = queryParams?.organizationId as number;
  const showApproveAll =
    canApproveActivity &&
    queryParams?.statuses === ActivityStatus.IN_REVIEW &&
    activitiesStatus[ActivityStatus.IN_REVIEW] > 0;

  useEffect(() => {
    if (communityId && communityId !== ID_DEFAULT) {
      getCommunityFunding();
    }
  }, [communityId]);

  const getCommunityFunding = async () => {
    const communityFunding = await activityServices.getCommunityDashboardFunding(
      { projectId, communityId }
    );
    setCommunityFunding(communityFunding);
  };

  const approveAll = async () => {
    try {
      setApproveAllLoading(true);
      await activityServices.approveAllActivities({
        projectId,
        organizationId: communityId,
      });
      refreshData();
    } finally {
      setApproveAllLoading(false);
    }
  };

  const refreshData = () => {
    fetchActivities();
    getCommunityFunding();
  };

  const handlePreview = async () => {
    try {
      setPrintLoading(true);
      const data = await activityServices.downloadCommunityFinancialPlan({
        projectId,
        communityId,
        financialYear,
      });

      setFileData(data);
      setIsPreviewModalOpen(true);
      await renderAsync(
        data,
        document.getElementById('preview-financial-plan') as HTMLElement,
        undefined,
        {
          renderFooters: false,
        }
      );
    } finally {
      setPrintLoading(false);
    }
  };

  const handleDownload = () => {
    fileHelpers.downloadFile(
      fileData,
      `${t('FinancialPlan')} - ${
        communityFunding?.communityName
      } - ${financialYear}.docx`
    );
  };

  return (
    <Spin spinning={loading}>
      <Card
        className="mb-base"
        styles={{
          body: {
            padding: 16,
          },
        }}
      >
        <FinancialPlanFilter
          onSearch={setQueryParams}
          activitiesStatus={activitiesStatus}
        />
      </Card>

      <Row justify={'space-between'} align={'bottom'}>
        <div className="community-dashboard-funding">
          {communityFunding && (
            <>
              <Row justify={'space-between'}>
                <span>{t('TotalAgreedAmount')}</span>
                <b>
                  {convertNumberFormat(communityFunding.totalAgreedAmount)} VND
                </b>
              </Row>
              <Row justify={'space-between'}>
                <span>{t('TotalPlannedAmount')}</span>
                <b>
                  {convertNumberFormat(communityFunding.totalPlannedAmount)} VND
                </b>
              </Row>
            </>
          )}
        </div>
        <Row>
          {canPrintActivity && (
            <Button
              type="link"
              onClick={handlePreview}
              loading={printLoading}
              icon={<PrinterOutlined style={{ fontSize: 20 }} />}
            />
          )}
          {showApproveAll && (
            <Button
              type="link"
              icon={<CheckOutlined />}
              onClick={approveAll}
              loading={approveAllLoading}
            >
              {t('ApproveAll')}
            </Button>
          )}
        </Row>
      </Row>

      <FinancialPlanList
        disabledApproval={approveAllLoading}
        dataSource={financialPlans}
        showLoadMore={pagination.total > pagination.page * pagination.pageSize}
        loadMore={getMoreActivities}
        refresh={refreshData}
      />

      <Modal
        closeIcon={null}
        open={isPreviewModalOpen}
        maskClosable={false}
        width={MODAL_WIDTH.LARGE}
        className="preview-financial-plan-modal"
        footer={[
          <Button key="close" onClick={() => setIsPreviewModalOpen(false)}>
            {t('Close')}
          </Button>,
          <Button key="download" type="primary" onClick={handleDownload}>
            {t('Download')}
          </Button>,
        ]}
      >
        <div id="preview-financial-plan"></div>
      </Modal>
    </Spin>
  );
};

export default FinancialPlan;
