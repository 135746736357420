export const isScrollEnded = (event: React.UIEvent<HTMLDivElement>) => {
  const { scrollTop, offsetHeight, scrollHeight }: any = event.target;
  return Math.abs(scrollTop + offsetHeight - scrollHeight) <= 1;
};

export const getNextPage = (
  page: number,
  pageSize: number,
  total?: number
): number | null => {
  if (typeof total === 'number' && page * pageSize >= total) return null;
  return page + 1;
};

export default {
  isScrollEnded,
  getNextPage,
};
