import { ActivityStatus } from 'interfaces';
import { t } from 'helpers/i18n';
import { PaymentMethod } from 'interfaces';

const ACTIVITY_ALL_STATUS = [
  ActivityStatus.IN_REVIEW,
  ActivityStatus.NEED_TO_UPDATE,
  ActivityStatus.APPROVED,
].join(',');

const PAYMENT_LABEL_MAP: { [key in PaymentMethod]: string } = {
  BANK_TRANSFER: t('BankTransfer'),
  CASH: t('Cash'),
};

export default {
  ACTIVITY_ALL_STATUS,
  PAYMENT_LABEL_MAP,
};
