import { requestServices } from 'services';
import {
  IApiResponse,
  IFPicFormGetParams,
  IFPicFormGetResult,
  IFPicFormSubmissionsGetParams,
  IFPicFormSubmissionsGetResult,
  IFPicFormSubmissionUpsertBody,
  IFPicFormTemplateGetResult,
  IFPicFormTemplatesGetParams,
} from 'interfaces';
import { AxiosRequestConfig } from 'axios';

const { projectManagerClient } = requestServices;

const getFPicForm = (
  params?: IFPicFormGetParams
): Promise<IApiResponse<IFPicFormGetResult>> => {
  return projectManagerClient.get('/api/v1/fpic-forms', {
    params,
  });
};

const getFPicFormSubmissions = (
  params?: IFPicFormSubmissionsGetParams,
  config?: AxiosRequestConfig
): Promise<IApiResponse<IFPicFormSubmissionsGetResult>> => {
  return projectManagerClient.get('/api/v1/fpic-form-submissions', {
    ...config,
    params,
  });
};

const getFPicFormTemplates = (
  params: IFPicFormTemplatesGetParams
): Promise<IApiResponse<IFPicFormTemplateGetResult>> => {
  return projectManagerClient.get('/api/v1/get-list-template-form', {
    params,
  });
};

const upsertFPicFormSubmission = (body: IFPicFormSubmissionUpsertBody) => {
  return projectManagerClient.post('/api/v1/fpic-form-submissions', body);
};

const autoFill = (formSubmissionId: number) => {
  return projectManagerClient.post(
    `/api/v1/fpic-form-submissions/${formSubmissionId}/auto-fill`
  );
};

const getFPicFormTemplatesTekbone = (
  params: IFPicFormTemplatesGetParams
): Promise<IApiResponse<IFPicFormTemplateGetResult>> => {
  return projectManagerClient.get('/tekbone/api/v1/get-list-template-form', {
    params,
  });
};

const getFPicFormSubmissionsTekbone = (
  params?: IFPicFormSubmissionsGetParams,
  config?: AxiosRequestConfig
): Promise<IApiResponse<IFPicFormSubmissionsGetResult>> => {
  return projectManagerClient.get('tekbone/api/v1/fpic-form-submissions', {
    ...config,
    params,
  });
};

const upsertFPicFormSubmissionTekbone = (
  body: IFPicFormSubmissionUpsertBody
) => {
  return projectManagerClient.post(
    '/tekbone/api/v1/fpic-form-submissions',
    body
  );
};

export default {
  autoFill,
  getFPicForm,
  getFPicFormTemplates,
  getFPicFormSubmissions,
  upsertFPicFormSubmission,
  getFPicFormTemplatesTekbone,
  getFPicFormSubmissionsTekbone,
  upsertFPicFormSubmissionTekbone,
};
